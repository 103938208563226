import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment';
import './map.scss'
import styles from './mapStyles.json'
import { GoogleMap, InfoBox, Marker, Polygon, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { ServerBaseAddress } from '../../../../services/config/serverAddresses';
import { getMultiDeviceHistory, getResource } from '../../../../services/utilities/events';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { Sort, getRandomNumber, hslToHex, idGenerator, todayDate, utcDate } from '../../../../functions/functions';
import DefaultFooter from '../../../default-layout/defaultFooter'
import Card, { CardBody, CardHeader, Heading } from '../../../../components/card/card';
import AQIMarker from '../../../../components/aqiMarker/aqiMarker';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { Button, ButtonContainer } from '../../../../components/arraybutton/arraybutton';
import Input from '../../../../components/input/input';
import Select from '../../../../components/select/select';

import { compileString } from 'sass';
import CardLoader from '../../../../components/loader/cardLoader';

const containerStyle = {
    width: '100%',
    minHeight: '100px',
    height: '100%',
    // borderRadius: '15px',
};
function QuettaMapOverview(props) {
    const [theme, setTheme] = useState(props.themeMode.isLight)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA9osJ2QBSR6f9oBEZULavTAJzZxVccQMo"
    })

    const quettaCoordinates = [
        { lat: 30.292689, lng: 66.898526 },
        { lat: 30.332997, lng: 66.908825 },
        { lat: 30.332033, lng: 66.949292 },
        { lat: 30.330034, lng: 66.950711 },
        { lat: 30.306658, lng: 66.947659 },
        { lat: 30.303485, lng: 66.950721 },
        { lat: 30.298550, lng: 66.950313 },
        { lat: 30.298550, lng: 66.950313 },
        { lat: 30.293791, lng: 66.955825 },
        { lat: 30.287974, lng: 66.983996 },
        { lat: 30.295377442058975, lng: 67.00890115998311 },
        { lat: 30.292290, lng: 67.024855 },
        { lat: 30.297033, lng: 67.060561 },
        { lat: 30.291437, lng: 67.069144 },
        { lat: 30.271462, lng: 67.081289 },
        { lat: 30.269312, lng: 67.108926 },
        { lat: 30.216407, lng: 67.155790 },
        { lat: 30.213292, lng: 67.138795 },
        { lat: 30.215962, lng: 67.096266 },
        { lat: 30.194488, lng: 67.074852 },
        { lat: 30.198011, lng: 67.056484 },
        { lat: 30.168556, lng: 67.043824 },
        { lat: 30.159095, lng: 67.044811 },
        { lat: 30.156089, lng: 67.044210 },
        { lat: 30.147406, lng: 67.035670 },
        { lat: 30.147072, lng: 67.032237 },
        { lat: 30.129591, lng: 67.021594 },
        { lat: 30.119532, lng: 67.020993 },
        { lat: 30.116599, lng: 67.021551 },
        { lat: 30.113815, lng: 67.021079 },
        { lat: 30.107170, lng: 67.015543 },
        { lat: 30.107170, lng: 67.015543 },
        { lat: 30.107244, lng: 67.015671 },
        { lat: 30.106390, lng: 67.014212 },
        { lat: 30.050170, lng: 66.999472 },
        { lat: 30.035459, lng: 67.001746 },
        { lat: 30.014057, lng: 66.999429 },
        { lat: 30.005733, lng: 66.977370 },
        { lat: 30.011530, lng: 66.941965 },
        { lat: 30.011344, lng: 66.922053 },
        { lat: 30.014986, lng: 66.898106 },
        { lat: 30.042184, lng: 66.901754 },
        { lat: 30.052251, lng: 66.908749 },
        { lat: 30.103128, lng: 66.917675 },
        { lat: 30.117755, lng: 66.932996 },
        { lat: 30.117588, lng: 66.932938 },
        { lat: 30.127165, lng: 66.935985 },
        { lat: 30.128204, lng: 66.936500 },
        { lat: 30.129689, lng: 66.937916 },
        { lat: 30.205304, lng: 66.946499 },
        { lat: 30.209829, lng: 66.944568 },
        { lat: 30.217098, lng: 66.941864 },
        { lat: 30.243503, lng: 66.897697 },
        { lat: 30.255426, lng: 66.890170 },
        { lat: 30.257030, lng: 66.887189 },
        { lat: 30.263156, lng: 66.883976 },
        { lat: 30.268986, lng: 66.882549 },
        { lat: 30.276357, lng: 66.881538 },
        { lat: 30.287337, lng: 66.885741 },
        { lat: 30.289954, lng: 66.888032 },
        { lat: 30.289876, lng: 66.887924 },
        { lat: 30.292689, lng: 66.898526 },

        // {lat: 30.276357, lng: 66.881538},
        // { lat: 30.186805, lng: 66.998806 }, // Quetta Airport
        // { lat: 30.1798, lng: 66.9750 },    // Center of Quetta
        // { lat: 30.1632, lng: 66.9922 },    // Example coordinate
        // Add more coordinates as needed to define the polyline
        // Example: { lat: ..., lng: ... },
    ];
    const history1 = useHistory()
    const mapId = idGenerator() + "_map"
    const [map, setMap] = useState(null)
    const [isLoad, setIsLoad] = useState(false)
    const [isThemeLoad, setIsThemeLoad] = useState(false)
    const [mapConfig, setMapConfig] = useState({
        coordinates: { lat: 30.1798, lng: 66.9750 }, // lahore
        // coordinates: { lat: 24.880934, lng: 67.055983 }, // karachi
        label: '',
        zoom: 15,
        autoTrack: false,
        live: false,
        history: false,
        filter: false,
        type: [],
    })


    const [markers, setMarkers] = useState([])
    const [filter, setFilter] = useState('all')
    const [isLive, setIsLive] = useState(true)
    const [startDate, setStartDate] = useState(todayDate('start'))
    const [endDate, setEndDate] = useState(todayDate('end'))
    const [rangeDate, setRangeDate] = useState(new Date(todayDate('start')))
    const [history, setHistory] = useState([])
    const [polylineData, setPolylineData] = useState([])
    const [isPlay, setIsPlay] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [markerId, setMarkerId] = useState([])
    const [markerLevel, setMarkerLevel] = useState(0)
    const [showingInfoWindow, setShowingInfoWindow] = useState(false)
    const [detailHub, setDetailHub] = useState(null)
    const [state, setState] = useState({
        length: 1,
    })



    const onLoad = useCallback(function callback(theme1, map) {
        map.setOptions({
            styles: theme1 ? styles.light : styles.dark,
            // mapTypeControl: false,
            streetViewControl: false,
        })
        setMap(map)
    }, [])
    useEffect(() => {
        setIsThemeLoad(true)
        setTimeout(() => {
            setIsThemeLoad(false)
        }, 1000)
        console.log("props", props.themeMode.isLight)
        setTheme(props.themeMode.isLight)
    }, [props.themeMode])
    // const onUnmount = useCallback(function callback(map) {
    //     setMap(null)
    // }, [])
    useEffect(() => {
        document.addEventListener('fullscreenchange', onFullScreenChange);
        let state1 = state;
        if ("length" in props) {
            state1.length = state.length
        }
        setState(state1)
        return () => {
            document.removeEventListener("fullscreenchange", onFullScreenChange);
        }
    }, [])

    const onFullScreenChange = () => {
        if (document.fullscreenElement) {
            // setFullScreen(true)
        }
        else {
            setFullScreen(false)
        }
    }

    const onMarkerClick = (marker, mapConfig, e) => {
        let markerId1 = [...markerId]
        // setMarkerId(marker._id)
        map.setCenter(marker.position)
        if (markerLevel === 0) {
            map.setZoom(13)
            setMarkerLevel(1)
        } else if (markerLevel === 1) {
            map.setZoom(16)
            setMarkerLevel(2)
        }
        markerId1.push(marker._id)
        setMarkerId(markerId1)
    }

    // const handleZoomChanged = (e) => {
    //     console.log("e", e)
    // }
    // useEffect(() => {
    //     if (map) {
    //         map.addListener('zoom_changed', handleZoomChanged);
    //     }
    //     // return () => {
    //     //     map.removeLEventistener('zoom_changed', handleZoomChanged);
    //     // }
    // }, [map]);
    // const handleZoomChanged = () => {
    //     if (map) {
    //         const newZoom = map.getZoom();
    //         if (newZoom < 12) {
    //             setMarkerLevel(0)
    //         }
    //         else if (newZoom < 15) {
    //             setMarkerLevel(1)
    //         }
    //         else {
    //             setMarkerLevel(2)
    //         }

    //         setZoom(newZoom);
    //     }
    // };
    const fullScreens = () => {
        var elem = document.getElementById(mapId)
        if (!fullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }
    useEffect(() => {
        let config = props?.config
        let mapConfig1 = { ...mapConfig }
        mapConfig1.label = config?.label || ""
        if (config?.mapSetting) {
            if (config.mapSetting?.mapCenter) {
                if ((config.mapSetting.mapCenter?.lat) && (config.mapSetting.mapCenter?.lng)) {
                    mapConfig1.coordinates = {
                        lat: parseFloat(config.mapSetting.mapCenter?.lat),
                        lng: parseFloat(config.mapSetting.mapCenter?.lng),
                    }
                }
                if (config.mapSetting.mapCenter?.zoom) {
                    mapConfig1.zoom = parseInt(config.mapSetting.mapCenter?.zoom)
                }
            }

            mapConfig1.autoTrack = config.mapSetting?.autoTrack || false
        }
        setMapConfig(mapConfig1)

    }, [
        props?.config?.label,
        props?.config?.deviceId,
        props?.config?.mapSetting,
        props?.config?.dataMode,
    ])

    useEffect(async () => {
        setIsLoad(true)
        let markers1 = []
        const levelMarkers1 = []
        const subLevelMarkers1 = []
        const hubMarker1 = []
        if (props.lastEvent && props.organization) {
            const { organization, config, configration, lastEvent } = props || {};
            const levels = organization?.organization?.levels || [];
            // console.log(organization?.organization)
            const hubs = organization?.organization?.allhubs || [];
            const configLevels = configration?.configration?.configuration?.Level || [];
            const configHubs = configration?.configration?.configuration?.Hub || [];
            const eventOrg = lastEvent?.lastEvent;

            function getDeviceEvents(deviceId) {
                return eventOrg?.devices?.find(device => device._id.match(deviceId))?.events || [];
            }
            async function processMarkerIcon(marker) {
                if (marker.icon && marker.icon.match('/files')) {
                    const image = await getResource(marker.icon);
                    if (marker.icon.match(".svg")) {
                        const filterImage = image.match('svg11.dtd">') && image.split('svg11.dtd">')[1] || image
                        marker.icon = filterImage;
                    } else if (marker.icon.match(".js")) {
                        const filterImage = eval("(" + image + ")")
                        marker.icon = filterImage
                    }
                } else {
                    if (marker.alias.length) {
                        const iconsObj = [];
                        for (const alias of marker.alias) {
                            if (alias.icon && alias.icon.match('/files')) {
                                const image = await getResource(alias.icon);
                                if (alias.icon.match(".svg")) {
                                    const filterImage = image.match('svg11.dtd">') && image.split('svg11.dtd">')[1] || image
                                    iconsObj.push({ ...alias, icon: filterImage });
                                }
                            } else {
                                iconsObj.push(alias);
                            }
                        }
                        marker.alias = iconsObj
                    }
                }
            }

            for (const level of levels) {
                const markerData = {
                    _id: level._id,
                    name: level.locationName,
                    type: level.type,
                    events: getDeviceEvents(level._id)
                };
                if ('metadata' in level) {
                    markerData.position = {
                        lat: parseFloat(level.metadata.coordinates.lat),
                        lng: parseFloat(level.metadata.coordinates.lng),
                    };
                }
                const matchingConfigDevice = configLevels.find(
                    configDevice => configDevice.type === level.type
                );
                if (matchingConfigDevice) {
                    markerData.icon = matchingConfigDevice.icon;
                    // markerData.alias = [...(
                    //     matchingConfigDevice.parameter.find(param => param.type === "Status" && 'alias' in param)?.alias || []
                    // )];
                }
                levelMarkers1.push(markerData);
                // for (const hubs of level.hubs) {
                //     console.log(hubs)
                // }
                //     }

                for (const subLevel of level?.levels) {
                    const markerData = {
                        _id: subLevel._id,
                        name: subLevel.locationName,
                        type: subLevel.type,
                        levelId: subLevel.levelId,
                        events: getDeviceEvents(subLevel._id)
                    };
                    if ('metadata' in subLevel) {
                        markerData.position = {
                            lat: parseFloat(subLevel.metadata.coordinates.lat),
                            lng: parseFloat(subLevel.metadata.coordinates.lng),
                        };
                    }
                    const matchingConfigDevice = configLevels.find(
                        configDevice => configDevice.type === subLevel.type
                    );
                    if (matchingConfigDevice) {
                        markerData.icon = matchingConfigDevice.icon;
                        // markerData.alias = [...(
                        //     matchingConfigDevice.parameter.find(param => param.type === "Status" && 'alias' in param)?.alias || []
                        // )];
                    }
                    subLevelMarkers1.push(markerData);
                }

            }

            const hubs1 = Sort(hubs)

            let newMarker = null
            for (const [key, hub] of Object.entries(hubs1)) {
                hub["events"] = getDeviceEvents(hub._id).find((hubEvent) => hubEvent?.type?.endsWith("Connection"))
                if (newMarker?.levelId === hub.levelId) {
                    newMarker.hubList.push(hub)
                    if (key === newMarker.hubList.length - 1) {
                        hubMarker1.push(newMarker)
                    }
                } else {
                    if (newMarker !== null) {
                        hubMarker1.push(newMarker)
                    }
                    newMarker = {
                        _id: hub._id,
                        name: hub.name,
                        levelId: hub.levelId,
                        type: hub.type,
                        hubList: [hub]
                    }
                    if ('metadata' in hub) {
                        newMarker.position = {
                            lat: parseFloat(hub.metadata.coordinates.lat),
                            lng: parseFloat(hub.metadata.coordinates.lng),
                        };
                    }
                    const matchingConfigDevice = configHubs.find(
                        configDevice => configDevice.type === hub.type
                    );
                    if (matchingConfigDevice) {
                        newMarker.icon = matchingConfigDevice.icon;
                        // markerData.alias = [...(
                        //     matchingConfigDevice.parameter.find(param => param.type === "Status" && 'alias' in param)?.alias || []
                        // )];
                    }
                }
                // const markerData = {
                //     _id: hub._id,
                //     name: hub.name,
                //     levelId: hub.levelId,
                //     type: hub.type,
                //     events: getDeviceEvents(hub._id)
                // };
                // if ('metadata' in hub) {
                //     markerData.position = {
                //         lat: parseFloat(hub.metadata.coordinates.lat),
                //         lng: parseFloat(hub.metadata.coordinates.lng),
                //     };
                // }
                // const matchingConfigDevice = configHubs.find(
                //     configDevice => configDevice.type === hub.type
                // );
                // if (matchingConfigDevice) {
                //     markerData.icon = matchingConfigDevice.icon;
                //     // markerData.alias = [...(
                //     //     matchingConfigDevice.parameter.find(param => param.type === "Status" && 'alias' in param)?.alias || []
                //     // )];
                // }
                // hubMarker1.push(markerData);
            }
            markers1 = [levelMarkers1, subLevelMarkers1, hubMarker1]

            // if (!markerLevel) {
            //     const markerPromises = markers1[0].map(processMarkerIcon);
            //     await Promise.allSettled(markerPromises);
            // }
        }
        markerUpdate(markers1)
        setMarkers(markers1)
        setIsLoad(false)
    }, [props.levels])

    const markerUpdate = (markers) => {
        if (markers.length) {
            let marker1 = [...markers]
            const eventOrg = props.lastEvent?.lastEvent;
            for (const [key, hubs] of marker1[2].entries()) {
                let markerEventHub = true
                for (const [key1, hub] of hubs.hubList.entries()) {
                    const filterEvent = eventOrg?.devices?.find(device => device._id.match(hub._id))?.events || [];
                    // if (filterEvent[0].created || hub._id === "QSCP_0837") {
                    //     console.log(filterEvent)
                    // }
                    // if (hub._id === hubs.hubList[key1]._id) {
                    // console.log(marker1[2][key])
                    // if (filterEvent !== undefined) {
                    hub["events"] = filterEvent.find((hubEvent) => hubEvent?.type?.endsWith("Connection"))
                    // }
                    // }
                    if (!(hub?.events?.value)) {
                        markerEventHub = false
                    }
                }
                if (markerEventHub) {
                    marker1[2][key]["isAlert"] = true
                } else {
                    marker1[2][key]["isAlert"] = false
                }
                marker1[1].map((sublevel, key2) => {
                    if (sublevel._id === marker1[2][key].levelId) {
                        if (markerEventHub) {
                            marker1[1][key2]["isAlert"] = true
                        } else {
                            marker1[1][key2]["isAlert"] = false
                        }
                    }
                    marker1[0].map((topLevel, key3) => {
                        if (topLevel._id === marker1[1][key2].levelId) {
                            if (markerEventHub) {
                                marker1[0][key3]["isAlert"] = true
                            } else {
                                marker1[0][key3]["isAlert"] = false
                            }
                        }
                    })
                })
            }
            // marker1[1].map((sublevel, key) => {
            //     let markerEventSubLevel = true
            //     marker1[2].map(hub => {
            //         if (hub.levelId === sublevel._id) {
            //             if (!hub.isAlert) {
            //                 markerEventSubLevel = false
            //             }
            //         }
            //     })
            //     if (markerEventSubLevel) {
            //         marker1[1][key]["isAlert"] = true
            //     } else {
            //         marker1[1][key]["isAlert"] = false
            //     }
            // })
            // console.log(marker1[2])
            setMarkers(marker1)
        }
    }
    useEffect(() => {
        markerUpdate(markers)
        // if (isLive) {
        //     let events = props?.lastEvent?.lastEvent?.devices
        //     if (markers.length && events) {
        //         let markers1 = markers.map(marker => {
        //             if (marker.gpsId) {
        //                 let gpsEvent = events.find(eve => eve._id === marker.gpsId)?.events || []
        //                 let [lat, lng] = (typeof gpsEvent[0]?.latlng === 'string') ? gpsEvent[0]?.latlng?.split(',').map(parseFloat) : [gpsEvent[0]?.lat || 0, gpsEvent[0]?.lng || 0]
        //                 marker.position = { lat, lng }
        //             }
        //             let deviceEvents = events.find(eve => eve._id === marker._id)?.events || []
        //             marker.events = deviceEvents
        //             return marker
        //         })
        //         setMarkers(markers1)
        //     }
        // }
    }, [props.lastEvent])

    const markerIcon = (marker) => {
        let renderIcon = ""
        let value = 0
        if (marker.events.length && marker.events?.[0].value) {
            value = marker.events?.[0].value
        }
        if (marker.type === "AQI" || marker.type === "Dust") {
            renderIcon = AQIMarker({ value })
        } else {
            if (marker.icon) {
                if (typeof marker.icon === 'function') {
                    renderIcon = marker.icon({ value });
                }
                else if (typeof marker.icon === 'string') {
                    renderIcon = marker.icon
                }
            } else {
                marker?.alias.map(icon => {
                    if (marker.events.length) {
                        if (icon.key === marker.events?.[0].value) {
                            renderIcon = icon.icon
                        }
                    } else {
                        if (icon.key === 0) {
                            renderIcon = icon.icon
                        }
                    }
                })
            }
            renderIcon = '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 16746 24233" xmlns:xlink="http://www.w3.org/1999/xlink">' +
                "<defs>" +
                '<linearGradient id="marker_id0" x1="0" y1="0" x2="100%" y2="100%">' +
                '<stop offset="0" style="stop-opacity:1; stop-color:#1b75bc"/>' +
                '<stop offset="1" style="stop-opacity:1; stop-color:#0d3a5d"/>' +
                "</linearGradient>" +
                "</defs>" +
                "<g>" +
                '<path fill="url(#marker_id0)" d="M8373 0c4624,0 8373,3749 8373,8373 0,3614 -2289,6693 -5497,7866l-2876 7994 -2876 -7994c-3208,-1173 -5497,-4252 -5497,-7866 0,-4624 3749,-8373 8373,-8373z"/>' +
                '<circle fill="white" cx="8373" cy="8373" r="6418"/>' +
                "<svg x='20%' y='12%' width='60%' height='46%'>" +
                (renderIcon || (value !== null && '<text style="font-size: 10px;">' + value + '</text>')
                ) +
                "</svg>" +
                "</g>" +
                "</svg>"
        }
        renderIcon = "data:image/svg+xml;base64," + btoa(renderIcon)
        return renderIcon
    }
    useEffect(() => {
        if (!isLive) {
            setStartDate(todayDate('start'))
            setEndDate(todayDate('end'))
        }
    }, [isLive])



    const backButton = () => {
        setDetailHub(null)
        setShowingInfoWindow(false)
        let markerId1 = [...markerId]
        markerId1.splice(markerId.length - 1, 1)
        if (!markerId1.length) {
            setMarkerLevel(0)
            map.setZoom(10)
        } else if (markerId1.length === 1) {
            setMarkerLevel(1)
            map.setZoom(13)
        } else {
            setMarkerLevel(2)
            map.setZoom(16)
        }
        setMarkerId(markerId1)
    }

    const devicesInMap = (id, name) => {
        const { organization } = props || {};
        const hubs = organization?.organization?.alldevices || [];
        const device1 = hubs.filter(device => device.hubId === id)
        setDetailHub({ name: name, devices: device1 })
    }
    const markerInfo = (marker) => {
        setShowingInfoWindow(!showingInfoWindow)
    }
    const gotoDevice = (hubId, levelId) => {
        const win = window.open("/#/hub/" + levelId + "/" + hubId, "_blank");
        win.focus();
        // window.open()
        // history1.push({ pathname: "/hub/" + levelId + "/" + hubId })
    }

    return isLoaded && !isLoad ? (
        <Card id={mapId} className="d-flex flex-column h-100 w-100 overflow-hidden">
            {(mapConfig.label || mapConfig.autoTrack || mapConfig.live || mapConfig.history || mapConfig.filter) &&
                <CardHeader>
                    {mapConfig.label &&
                        <Heading align="center" size={2}>{mapConfig.label}</Heading>
                    }
                </CardHeader>
            }
            <CardBody loader={isLoad} loaderHeight="100%" className="flex-fill position-relative ">
                <div className='position-absolute' style={{ top: "10px", zIndex: "1", left: "10px" }}>
                    {markerId.length > 0 && <button className='btn bg-white rounded-2 ' style={{ width: "40px", height: "40px" }} onClick={() => backButton()}>
                        <i className="fas fa-arrow-left "></i>
                    </button>}
                </div>
                <div className='position-absolute' style={{ top: "10px", zIndex: "1", right: "10px" }}>
                    <button className='btn bg-white rounded-2 ' style={{ width: "40px", height: "40px" }} onClick={() => { setFullScreen(!fullScreen); fullScreens() }}>
                        {fullScreen ? <i class="fas fa-compress"></i> :
                            <i class="fas fa-expand"></i>}
                    </button>
                </div>
                {!isThemeLoad ?
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={mapConfig.coordinates}
                        // zoom={mapConfig.zoom}
                        // zoom={mapConfig.zoom}
                        zoom={11}
                        onLoad={(e) => onLoad(theme, e)}
                    >
                        <Polygon
                            path={quettaCoordinates}
                            options={{
                                strokeColor: '#F15A29',
                                icons: [
                                    {
                                        icon: {
                                            path: 'M 0,1 0,1',
                                            strokeOpacity: 1,
                                            scale: 3,
                                        },
                                        offset: '0',
                                        repeat: '8px', // Adjust the repeat value to control the spacing of the dots
                                    },
                                ],
                                fillColor: '#505955',
                                strokeOpacity: 0,
                                strokeWeight: 0,
                                zIndex: 1
                            }}
                        />
                        <Polyline
                            path={quettaCoordinates}
                            options={{
                                strokeColor: '#F15A29',
                                icons: [
                                    {
                                        icon: {
                                            path: 'M 0,1 0,1',
                                            strokeOpacity: 1,
                                            scale: 3,
                                        },
                                        offset: '0',
                                        repeat: '8px', // Adjust the repeat value to control the spacing of the dots
                                    },
                                ],
                                fillColor: '#505955',
                                strokeOpacity: 0,
                                strokeWeight: 0,
                                zIndex: 1
                            }}
                        />
                        {/* .filter(marker => (filter !== 'all' ? markerLevel && marker.levelId === markerId ? marker : null : marker)) */}
                        {markers[markerLevel].map((marker, key) =>
                            (!markerLevel || markerLevel && marker.levelId === markerId[markerId.length - 1]) &&
                            marker.position &&
                            <React.Fragment key={key}>
                                {/* {marker._id === "QSCP_0837" ? console.log(marker.events) : null} */}
                                <Marker
                                    // key={key}
                                    position={marker.position}
                                    title={marker.name}
                                    onClick={() => markerLevel < 2 ? onMarkerClick(marker, mapConfig) : markerInfo(marker.hubList)}
                                    markerData={marker}
                                    icon={{
                                        url: 'https://maps.google.com/mapfiles/ms/icons/' + (marker?.isAlert ? 'green' : 'red') + '-dot.png',
                                        // url: markerLevel ? 'https://maps.google.com/mapfiles/ms/icons/' + (getRandomNumber(0, 1) ? 'green' : 'red') + '-dot.png' : markerIcon(marker),
                                        scaledSize: fullScreen ? new window.google.maps.Size(70, 70) : new window.google.maps.Size(50, 50),
                                    }}
                                // onClick={() => onClickHandler(key)}
                                />
                                {showingInfoWindow ? <InfoBox

                                    visible={showingInfoWindow}
                                    onCloseClick={() => {
                                        setShowingInfoWindow(false);
                                    }}
                                    // defaultPosition={new window.google.maps.LatLng(props.center.lat, props.center.lng)}
                                    position={marker.position}
                                // options={{ closeBoxURL: ``, enableEventPropagation: true }}
                                >
                                    <Card className="p-0 overflow-hidden">
                                        {detailHub ? <div className='d-flex flex-column'>
                                            <div className='d-flex p-1 align-items-center'>
                                                <button className='btn btn-light rounded-circle-px' onClick={() => setDetailHub(null)}><i className='fa fa-angle-left'></i></button>
                                                {/* <div className='d-flex flex-column col'> */}
                                                {/* <div className='m-0 text-truncate'> */}
                                                <span className='w-100 m-1 text-center' style={{ fontSize: "16px" }}><b>{detailHub.name}</b></span>
                                                {/* </div> */}
                                                {/* </div> */}
                                            </div>

                                            {detailHub.devices?.map(device =>
                                                <>
                                                    <button title={device.name} className='w-100 btn btn-light text-left d-flex align-items-center' style={{ fontSize: `16px` }} onClick={() => gotoDevice(device.hubId, device.levelId)}>
                                                        {/* <i className={'p-2 my-wifi on'}></i> */}
                                                        <div className='d-flex flex-column col'>
                                                            <div className='m-0 text-truncate'>{device.name}</div>
                                                        </div>
                                                        <i className='fa fa-angle-right'></i>
                                                    </button>
                                                    {/* <small className='mb-2 text-muted'>{events.Status ? "Last Update:" + moment(events.Status.created).format("LL LTS") : "Last Update: 00:00:00 AM 00-00-0000"}</small> */}
                                                </>
                                            )}
                                        </div> :
                                            <div className='d-flex flex-column'>
                                                {marker.hubList?.map(hub =>

                                                    <>
                                                        <button title={hub.name} className='w-100 btn btn-light text-left d-flex align-items-center' style={{ fontSize: `16px` }} onClick={() => devicesInMap(hub._id, hub.name)} >
                                                            {/* <i className={'p-2 my-wifi on'}></i> */}
                                                            <i className={'p-2 my-wifi  ' + (hub.events ? hub.events.value ? 'on' : 'off' : 'off')}></i>

                                                            <div className='d-flex flex-column col'>
                                                                <div className='m-0 text-truncate'>{hub.name}</div>
                                                                <small className='mb-2 text-muted'>{hub.events ? "Last Update:" + moment(hub.events.created).format("LL LTS") : "Last Update: 00:00:00 AM 00-00-0000"}</small>
                                                                {/* <small style={{ fontSize: `12px` }} className='m-0 flex-column text-muted'>{"Last Connected: 00:00:00 AM 00-00-0000"}</small> */}
                                                            </div>
                                                            <i className='fa fa-angle-right'></i>
                                                        </button>
                                                    </>
                                                )}
                                            </div>}
                                    </Card>
                                </InfoBox> : null}
                                {/* {(!isLive && polylineData.map((polyData, polyIndex) =>
                                'gpsId' in marker && polyData._id === marker.gpsId ?
                                    // console.log("gps", polyData.path)
                                    <Polyline
                                        key={polyIndex}
                                        path={polyData.path}
                                        options={{
                                            strokeColor: polyData.color,
                                            // strokeColor: "#F15A29",
                                            strokeOpacity: 0.8,
                                            strokeWeight: 4,
                                        }}
                                    />
                                    : null)
                            )} */}
                            </React.Fragment>
                        )}

                        {/* {markers !== null && props?.path?.length > 0 && (
                        <Polyline
                            path={props?.path}
                            options={{
                                strokeColor: "#F15A29",
                                strokeOpacity: 1.0,
                                strokeWeight: 4,
                            }}
                        />
                    )} */}

                    </GoogleMap>
                    : <CardLoader />}
                {/* {mapConfig.history &&
                    <div className='mapHistoryBar fade-in-bottom-onload' style={{ display: (!isLive ? 'block' : 'none') }}>
                        <div>
                            <button className='btn btn-light' onClick={() => setIsPlay(!isPlay)}><i className={'fa fa-' + (isPlay ? 'pause' : 'play')}></i></button>
                            <button className='btn btn-light' onClick={() => playHandler(-1000)}><i className='fa fa-step-backward'></i></button>
                            <div className='w-100'>
                                <Input name='dateRange' valueDisplay={false} label="History" onChange={onChangeHandler} defaultValue={rangeDate.getTime()} type="range" min={new Date(startDate).getTime()} max={new Date(endDate).getTime()} />
                            </div>
                            <button className='btn btn-light' onClick={() => playHandler(1000)}><i className='fa fa-step-forward'></i></button>
                        </div>
                    </div>
                } */}
            </CardBody>
            {fullScreen ?
                <div>
                    <DefaultFooter />
                </div>
                : null}
        </Card>
    ) : null;
}

const mapStateToProps = (state) => ({
    lastEvent: state.lastEvent,
    organization: state.organization,
    configration: state.configration,
    themeMode: state.themeMode
});
export default connect(mapStateToProps, null)(QuettaMapOverview)