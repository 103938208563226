import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card'
import Select from '../../components/select/select'
import FullLoader from '../../components/loader/fullPageLoader'
import Mypopup from '../../components/popup/mypopup'
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton'
import { getDevicesList } from '../../functions/functions'
import InlineLoader from '../../components/loader/inlineLoader'
import { deviceDelete } from '../../services/utilities/events'
import AddDeviceForm from '../../components/addDeviceForm/addDeviceForm'


const DeviceManager = (props) => {

    let [deviceList, setDeviceList] = useState([])
    let [confirmPopup, setConfirmPopup] = useState(false)
    let [inlineLoader, setInlineLoader] = useState(false)
    let [loader, setLoader] = useState(false)
    let [deviceData, setDeviceData] = useState(null)
    let [addDevice, setAddDevice] = useState(false)
    useEffect(() => {
        setLoader(true)
        let devices = getDevicesList(props?.organization?.organization?.alldevices, props.userInfo.varification?.hierarchy)
        setDeviceList(devices)
        setLoader(false)
    }, [])
    const DeviceGet = async () => {
        setAddDevice(null)
        setLoader(true)
        let devices = getDevicesList(props?.organization?.organization?.alldevices, props.userInfo.varification?.hierarchy)
        setDeviceList(devices)
        setLoader(false)
    }
    // const editDevice = (data, key) => {
    //     setDevice(data)
    //     setEdit(true)
    // }
    const addEditDevice = (data = null) => {
        setAddDevice(true)
        if (data) {
            setDeviceData(data)
        } else {
            setDeviceData(null)
        }
    }

    const removeDevice = async (data, key) => {
        setInlineLoader(true)
        let devices1 = [...deviceList]
        if (true) {
            await deviceDelete(data._id).then(() => {
            })
            devices1.splice(key, 1)
            setDeviceList(devices1)
            setInlineLoader(false)
        }
        setConfirmPopup(null)
    }
    return (
        <div className='mt-2 px-3'>
            <Card>
                <CardHeader>
                    <div className='row'>
                        <div className='col'>
                            <Heading size={1} className="mr-3" >Devices</Heading>
                        </div>
                        {/* {manageDevice ? */}
                        <div>
                            <div className='d-flex mr-1 '>
                                <ButtonContainer>
                                    <Button buttonResult={() => addEditDevice()} >Add Device</Button>
                                </ButtonContainer>
                            </div>
                        </div>
                        {/* : null} */}
                    </div>
                </CardHeader>
            </Card>
            <Card className='overflow-hidden mt-1'>
                <CardHeader className='row m-0 align-items-center'>
                    <div className='col'><b>ID</b></div>
                    <div className='col'><b>Name</b></div>
                    <div className='col-12 col-sm-4 col-lg-1'><b>Type</b></div>
                    <div className='col-12 col-sm-4 col-lg-3'><b>LevelId</b></div>
                    <div className='col-2 d-flex justify-content-end' style={{ width: "100px" }}><b>Action</b></div>
                </CardHeader>
            </Card>
            {/* <CardBody className="p-3 overflow-auto" style={{ height: "calc(100vh - 280px)" }}  > */}
            {loader ? <FullLoader /> : deviceList.length > 0 ?
                deviceList.map((device, key) =>
                    <Card className="overflow-hidden mt-1">
                        <CardHeader className='row m-0 align-items-center'>
                            <div className='col' >{device._id}</div>
                            <div className='col' >{device.name}</div>
                            <div className='col-12 col-sm-4 col-lg-1' >{device.type}</div>
                            <div className='col-12 col-sm-4 col-lg-3' >{device.levelId}</div>
                            <div className='col-2 d-flex justify-content-end' style={{ width: "100px" }}>
                                <ButtonContainer className="p-2" flat="light">
                                    <Button color="text-primary" buttonResult={() => addEditDevice(device)}>
                                        <i className="fas fa-pen"></i>
                                    </Button>
                                    <Button color="text-danger" buttonResult={() => setConfirmPopup({ device, key })}>
                                        <i className="fas fa-trash"></i>
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardHeader>
                    </Card>
                )
                : null}
            {/* </CardBody> */}

            {addDevice ? <Mypopup title={"Add Device"} onClose={() => { setAddDevice(null) }}>
                <AddDeviceForm data={deviceData} org={props.organization.organization._id} type={"direct"} onClose={() => setAddDevice(null)} reloadDevices={() => DeviceGet()} />
            </Mypopup> :
                null
            }
            {confirmPopup ? <Mypopup title={"Confirmation"} onClose={(e) => {
                setConfirmPopup(null);
            }}>
                <p className="p-3" > Are You Sure!</p>
                <div className="d-flex justify-content-center p-3" style={{ gap: 10 }}>
                    {inlineLoader ? <InlineLoader width="70px" /> :
                        <>
                            <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                                removeDevice(confirmPopup.device, confirmPopup.key);
                            }}>Yes</button>
                            <button style={{ width: 100 }} className="btn btn-light" onClick={(e) => {
                                setConfirmPopup(null);
                            }}>No</button>
                        </>
                    }
                </div>
            </Mypopup >
                : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    organization: state.organization,
    configration: state.configration,
    userInfo: state.userInfo
});
export default connect(mapStateToProps, null)(DeviceManager)
