import React, { useEffect, useState } from 'react'
import Input from '../../input/input'
import Card from '../../card/card'
import { Button, ButtonContainer } from '../../arraybutton/arraybutton'

const Range = (props) => {

    const [range, setRange] = useState([])
    const onChangeHandler = (e) => {
        let value = e.target.value, name = e.name, data = [...range]
        if(name === 'addRange'){
            data.push({
                value: 0,
                endValue: -1000,
                strokeColor: "#FF0000",
                rangeLine: false,
                rangeLineLabel: false,
            })
        }else if(name === 'remove'){
            data.splice(value, 1)
        }else{
            const breakName = name.split("_")
            data[breakName[1]][breakName[0]] = (
                breakName[0] === 'value'
                || breakName[0] === 'endValue'
            )? parseFloat(value) : value
        }
        setRange(data)
        if ("onChange" in props) {
            props.onChange({ name: "range", target: { value: data } })
        }

    }

    useEffect(() => {
        setRange([])
        if (props?.defaultValue) {
            if ("range" in props?.defaultValue) {
                if (props?.defaultValue.range) {
                    setRange(props.defaultValue.range)
                }
            }
        }
    }, [props.defaultValue])
    return (
        <>
            <div className='propertiesLabels mt-2 d-flex justify-content-between align-items-center'><span>Graph Range</span> <button onClick={()=> onChangeHandler({ name: "addRange", target: {value: null}})} className='btn btn-light text-success rounded-circle-px'><i className='fa fa-plus fa-sm'></i> Range</button></div>
            {range.map((rangeList , key) => 
                <Card key={key} className='row p-1'>
                    <div className='col-12 px-1 mb-2 d-flex justify-content-end'>
                        <button className='btn btn-sm btn-danger rounded-circle-px' onClick={() => onChangeHandler({name: 'remove', target: {value: key}})}><i className='fa fa-times'></i></button>
                    </div>
                    <div className='col-6 px-1 mb-2'>
                        <Input
                            name={"value_"+key}
                            label="Start Value"
                            type="number"
                            defaultValue={rangeList.value}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className='col-6 px-1 mb-2'>
                        <Input
                            name={"endValue_"+key}
                            label="End Value"
                            type="number"
                            defaultValue={rangeList.endValue}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className='col-12 px-1 mb-2'>
                        <Input
                            name={"strokeColor_"+key}
                            label="Stroke Color"
                            type="color"
                            defaultValue={rangeList.color}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className='col-6 px-1 mb-2'>
                        <label className='d-flex m-0'>
                            <input
                                className='mr-1'
                                name={"rangeLine_"+key}
                                label="Range Line"
                                type="checkbox"
                                checked={rangeList.rangeLine}
                                onChange={(e) => onChangeHandler({name:"rangeLine_"+key,target: {value: !rangeList.rangeLine}})}
                            />
                            Range Line
                        </label>
                    </div>
                    {rangeList.rangeLine?
                    <div className='col-6 px-1 mb-2'>
                        <label className='d-flex m-0'>
                            <input
                                className='mr-1'
                                name={"rangeLineLabel_"+key}
                                label="Range Label"
                                type="checkbox"
                                checked={rangeList.rangeLineLabel}
                                onChange={(e) => onChangeHandler({name:"rangeLineLabel_"+key,target: {value: !rangeList.rangeLineLabel}})}
                            />
                            Range Label
                        </label>
                    </div>
                    :null}
                    {rangeList.rangeLine && rangeList.rangeLineLabel? 
                    <>
                        <div className='col-6 px-1 mb-2'>
                            <Input
                                name={"prefix_"+key}
                                label="prefix"
                                type="text"
                                defaultValue={rangeList?.prefix}
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                        <div className='col-6 px-1 mb-2'>
                            <Input
                                name={"suffix_"+key}
                                label="suffix"
                                type="text"
                                defaultValue={rangeList?.suffix}
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                        <div className='col-12 px-1 mb-2'>
                            <ButtonContainer>
                                <Button active={rangeList?.labelAlign === 'left'? true: false} buttonResult={e=>onChangeHandler({name: 'labelAlign_'+key, target: {value: 'left'}})}>Left</Button>
                                <Button active={rangeList?.labelAlign === 'center'? true: false} buttonResult={e=>onChangeHandler({name: 'labelAlign_'+key, target: {value: 'center'}})}>Center</Button>
                                <Button active={rangeList?.labelAlign === 'right'? true: false} buttonResult={e=>onChangeHandler({name: 'labelAlign_'+key, target: {value: 'right'}})}>Right</Button>
                            </ButtonContainer>
                        </div>
                        <div className='col-12 px-1 mb-2'>
                            <ButtonContainer>
                                <Button active={rangeList?.labelVerticalAlign === 'top'? true: false} buttonResult={e=>onChangeHandler({name: 'labelVerticalAlign_'+key, target: {value: 'top'}})}>Top</Button>
                                <Button active={rangeList?.labelVerticalAlign === 'middle'? true: false} buttonResult={e=>onChangeHandler({name: 'labelVerticalAlign_'+key, target: {value: 'middle'}})}>Middle</Button>
                                <Button active={rangeList?.labelVerticalAlign === 'bottom'? true: false} buttonResult={e=>onChangeHandler({name: 'labelVerticalAlign_'+key, target: {value: 'bottom'}})}>Bottom</Button>
                            </ButtonContainer>
                        </div>
                    </>
                    :null}
                </Card>
            )}
        </>
    )
}

export default Range