import React, { useEffect, useState } from 'react'
import { hexToHsl, hslToHex } from '../../../functions/functions'
import './pipline.scss'
export function PipeI(props) {
  const [night, setNight] = useState(false)
  const [joinColor, setJoinColor] = useState("#014070")
    const [config, setConfig] = useState({
        cssColor: "#0471C4",
        value: 70,
        label: "Label",
    })
    const [style, setStyle] = useState({
        rotateStep: 0,
        thickness: 20,
    })
    useEffect(() => {
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                    let hsl = hexToHsl(config1.cssColor)
                    if(hsl){
                        hsl = hslToHex(hsl.h,hsl.s,30);
                    }
                    setJoinColor(hsl)
                }
            }
            setConfig(config1)
        }
    }, [props.config])
    useEffect(() => {
        if (props?.style) {
            let style1 = { ...style }
            if ("rotateStep" in props.style) {
                  style1.rotateStep = parseInt(props.style.rotateStep);
            }
            if ("thickness" in props.style) {
                  style1.thickness = 10*parseInt(props.style.thickness);
            }
            setStyle(style1)
        }
    }, [props.style])
  return (
    <div className="position-relative h-100 w-100" style={{minHeight: "50px"}}>
        <div className='position-absolute' style={
          style.rotateStep === 0 || style.rotateStep === 2 ?{
            transition: "all 0.5s", backgroundColor: config.cssColor, width: style.thickness, height: "100%", top: "50%", left: "50%", transform: "translate(-50%,-50%)"
          }:{
            transition: "all 0.5s", backgroundColor: config.cssColor, width: "100%", height: style.thickness, top: "50%", left: "50%", transform: "translate(-50%,-50%)"
          }
        }></div>
        
        <div className='position-absolute' style={
            style.rotateStep === 0 || style.rotateStep === 2 ?
            { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness*1.5, 
            height: style.thickness/2, 
            top: "0%", 
            left: "50%", 
            borderRadius: '0 0 10px 10px',
            transform: "translate(-50%,0%)" }
            : { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness/2, 
            height: style.thickness*1.5, 
            top: "50%", 
            left: "100%", 
            borderRadius: '10px 0 0 10px',
            transform: "translate(-100%,-50%)" }
        }></div>
        <div className='position-absolute' style={
            style.rotateStep === 0 || style.rotateStep === 2 ?
            { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness*1.5, 
            height: style.thickness/2, 
            top: "100%", 
            left: "50%", 
            borderRadius: '10px 10px 0 0',
            transform: "translate(-50%,-100%)" }
            : { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness/2, 
            height: style.thickness*1.5, 
            top: "50%", 
            left: "0%", 
            borderRadius: '0 10px 10px 0',
            transform: "translate(0%,-50%)" }
        }></div>
    </div >
  )
}
export function PipeL(props) {
  const [night, setNight] = useState(false)
  const [joinColor, setJoinColor] = useState("#014070")
    const [config, setConfig] = useState({
        cssColor: "#0471C4",
        value: 70,
        label: "Label",
    })
    const [style, setStyle] = useState({
        rotateStep: 0,
        thickness: 20,
    })
    useEffect(() => {
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                    let hsl = hexToHsl(config1.cssColor)
                    if(hsl){
                        hsl = hslToHex(hsl.h,hsl.s,30);
                    }
                    setJoinColor(hsl)
                }
            }
            setConfig(config1)
        }
    }, [props.config])
    useEffect(() => {
        if (props?.style) {
            let style1 = { ...style }
            if ("rotateStep" in props.style) {
                  style1.rotateStep = parseInt(props.style.rotateStep);
            }
            if ("thickness" in props.style) {
                  style1.thickness = 10*parseInt(props.style.thickness);
            }
            setStyle(style1)
        }
    }, [props.style])
  return (
    <div className="position-relative h-100 w-100" style={{minHeight: "50px"}}>
        <div className='position-absolute' style={{
            transition: "all 0.5s",
            backgroundColor: config.cssColor,
            width: style.thickness,
            height: "calc(50% + "+(style.thickness/2)+"px)", 
            transform: "translate(-50%,0%)", 
            left: "50%", 
            borderRadius: (
              style.rotateStep === 0?
              '0 0 0 50px':
              style.rotateStep === 1?
              '50px 0 0 0':
              style.rotateStep === 2?
              '0 50px 0 0':
              '0 0 50px 0'
            ),
            top: 
              style.rotateStep === 0 
              || style.rotateStep === 3 ? 
                  "0%" : "calc(50% - "+style.thickness/2+"px)", 
              bottom: 
                style.rotateStep === 1 
                || style.rotateStep === 2 ? 
                    "0%" : "calc(50% - "+style.thickness/2+"px)"
        }}></div>

        <div className='position-absolute' style={{
            transition: "all 0.5s",
            backgroundColor: config.cssColor,
             width: "calc(50% + "+(style.thickness/2)+"px)", 
             height: style.thickness, 
             top: "50%", 
            borderRadius: (
              style.rotateStep === 0?
              '0 0 0 50px':
              style.rotateStep === 1?
              '50px 0 0 0':
              style.rotateStep === 2?
              '0 50px 0 0':
              '0 0 50px 0'
            ),
             right: style.rotateStep === 0 || style.rotateStep === 1 ?
              "0%" : "calc(50% - "+style.thickness/2+"px)", 
             left: style.rotateStep === 2 || style.rotateStep === 3 ?
              "0%" : "calc(50% - "+style.thickness/2+"px)", 
              transform: "translate(0%,-50%)"
        }}></div>
        
        <div className='position-absolute' style={
            style.rotateStep === 0 || style.rotateStep === 3 ?
            { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness*1.5, 
            height: style.thickness/2, 
            top: "0%", 
            left: "50%", 
            borderRadius: '0 0 10px 10px',
            transform: "translate(-50%,0%)" }
            : { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness*1.5, 
            height: style.thickness/2, 
            top: "100%", 
            left: "50%", 
            borderRadius: '10px 10px 0 0',
            transform: "translate(-50%,-100%)" }
        }></div>
        <div className='position-absolute' style={
            style.rotateStep === 0 || style.rotateStep === 1 ?
            { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness/2, 
            height: style.thickness*1.5, 
            top: "50%", 
            left: "100%", 
            borderRadius: '10px 0 0 10px',
            transform: "translate(-100%,-50%)" }
            : { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness/2, 
            height: style.thickness*1.5, 
            top: "50%", 
            left: "0%", 
            borderRadius: '0 10px 10px 0',
            transform: "translate(0%,-50%)" }
        }></div>
    </div >
  )
}
export function PipeT(props) {
  const [night, setNight] = useState(false)
  const [joinColor, setJoinColor] = useState("#014070")
    const [config, setConfig] = useState({
        cssColor: "#0471C4",
        value: 70,
        label: "Label",
    })
    const [style, setStyle] = useState({
        rotateStep: 0,
        thickness: 20,
    })
    useEffect(() => {
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                    let hsl = hexToHsl(config1.cssColor)
                    if(hsl){
                        hsl = hslToHex(hsl.h,hsl.s,30);
                    }
                    setJoinColor(hsl)
                }
            }
            setConfig(config1)
        }
    }, [props.config])
    useEffect(() => {
        if (props?.style) {
            let style1 = { ...style }
            if ("rotateStep" in props.style) {
                  style1.rotateStep = parseInt(props.style.rotateStep);
            }
            if ("thickness" in props.style) {
                  style1.thickness = 10*parseInt(props.style.thickness);
            }
            setStyle(style1)
        }
    }, [props.style])
  return (
    <div className="position-relative h-100 w-100" style={{minHeight: "50px"}}>
        <div className='position-absolute' style={
          style.rotateStep === 0 || style.rotateStep === 3?
          {
            transition: "all 0.5s",
            backgroundColor: config.cssColor,
            width: style.thickness,
            height: "50%", 
            left: "50%", 
            borderRadius: '50px 50px 0 0',
            top: "50%", 
            bottom: "0%",
            transform: "translate(-50%,0%)", 
          }
          :{
            transition: "all 0.5s",
            backgroundColor: config.cssColor,
            width: "50%", 
            height: style.thickness,
            left: "0%", 
            borderRadius: '0 50px 50px 0',
            top: "50%", 
            bottom: "0%",
            transform: "translate(0%,-50%)", 
          }
        }></div>
        <div className='position-absolute' style={
          style.rotateStep === 0?
          {
            transition: "all 0.5s",
            backgroundColor: config.cssColor,
            width: "50%",
            height:  style.thickness,
            left: "0%", 
            top: "calc(50% - "+style.thickness/2+"px)", 
            bottom: "0%",   
            borderRadius: '0 50px 50px 0',
          }
          :{
            transition: "all 0.5s",
            backgroundColor: config.cssColor,
            width: style.thickness,
            height:  "50%",
            left: "50%", 
            top: "0%", 
            bottom: "0%",   
            borderRadius: '0 0 50px 50px',
            transform: "translate(-50%,0%)"
        }}></div>
        <div className='position-absolute' style={
          style.rotateStep === 0 || style.rotateStep === 2 || style.rotateStep === 3?
          {
            transition: "all 0.5s",
            backgroundColor: config.cssColor,
            width: "50%", 
            height: style.thickness, 
            top: "50%", 
            borderRadius: '50px 0 0 50px',
            right: "0%", 
            left: "50%",
            transform: "translate(0%,-50%)"
          }
          :{
            transition: "all 0.5s",
            backgroundColor: config.cssColor,
            width: style.thickness, 
            height: "50%", 
            top: "50%", 
            borderRadius: '50px 50px 0 0',
            right: "0%", 
            left: "calc(50% - "+style.thickness/2+"px)",
            transform: "translate(0%, 0%)"
          }
        }></div>
        

        <div className='position-absolute' style={
            style.rotateStep === 0 || style.rotateStep === 3?
            { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness*1.5, 
            height: style.thickness/2, 
            top: "100%", 
            left: "50%", 
            borderRadius: '10px 10px 0 0',
            transform: "translate(-50%,-100%)" }
            : { transition: "all 0.5s",
            backgroundColor: joinColor, 
            height: style.thickness*1.5, 
            width: style.thickness/2, 
            top: "50%", 
            left: "0%", 
            borderRadius: '0 10px 10px 0',
            transform: "translate(0%,-50%)" }
        }></div>
        <div className='position-absolute' style={
            style.rotateStep === 0?
            { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness/2, 
            height: style.thickness*1.5, 
            top: "50%", 
            left: "0%", 
            borderRadius: '0 10px 10px 0',
            transform: "translate(0%,-50%)" }
            : { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness*1.5, 
            height: style.thickness/2, 
            top: "0%", 
            left: "50%", 
            borderRadius: '0 0 10px 10px',
            transform: "translate(-50%,0%)" }
        }></div>
        <div className='position-absolute' style={
            style.rotateStep !== 1 ?
            { transition: "all 0.5s",
            backgroundColor: joinColor, 
            width: style.thickness/2, 
            height: style.thickness*1.5, 
            top: "50%", 
            left: "100%", 
            borderRadius: '10px 0 0 10px',
            transform: "translate(-100%,-50%)" }
            : { transition: "all 0.5s",
            backgroundColor: joinColor, 
            height: style.thickness/2, 
            width: style.thickness*1.5, 
            top: "100%", 
            left: "50%", 
            borderRadius: '10px 10px 0 0',
            transform: "translate(-50%,-100%)" }
        }></div>
    </div >
  )
}