import React, { useState, useEffect } from 'react'
// Import Resources
import {
  signIn,
  checkSignIn,
  resendCode,
  setPassword,
  requestForgotPasswordEmail,
  ForgotPassword
} from '../../services/utilities/users'
import "./users.scss"
import InlineLoader from "../../components/loader/inlineLoader"
import LtLogo from "./logo.svg";
import Logo from "../../assets/svgs/otto.svg";
import Mypopup from '../../components/popup/mypopup';
import { Link, useParams } from 'react-router-dom';
import { signUp } from '../../services/utilities/users';
import { getVerification } from '../../services/utilities/users';
import { useHistory } from "react-router-dom";
import MyDropDown, { MyDropDownBody } from '../../components/dropdown/dropdown';
import { parseJwt } from '../../functions/functions';
import { EmailRevoke } from '../../services/config/generic';
import cookies from '../../services/utilities/cookies';
import {
  EmailRegex,
  WhiteSpace,
  CapitalLetters,
  SmallLetters,
  Numbers,
  SpecialCharacters2
} from '../../services/config/regex';
import CardLoader from '../../components/loader/cardLoader';
import { dataHandler } from '../../functions/functions';

import videoBack from '../../assets/video/welcome.mp4'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getVerifyUserToken } from '../../services/utilities/events';
import FullLoader from '../../components/loader/fullPageLoader';
import UnAuthorized from '../unAuthorized/unAuthorized';



export default function Login(props) {
  const [emailId, setEmailId] = useState({})
  const { id } = useParams()
  const location = useLocation()
  const query = new URLSearchParams(location?.search)
  const userid = props?.id ? props.id : query?.get("token")
  const UnAuthorize = query?.get("unauthorize")


  const pageName = window.location.hash.split("/")[1]
  const [popup, setPopup] = useState(null)
  const [popup1, setPopup1] = useState(null)
  const [state, setState] = useState({
    loginData: {},
    modal: false,
    redirect: false,
    loginLoader: false,
    user: "",
    pass: "",
    renderSignup: false,
  })
  let history = useHistory();
  //All States
  //SignIn States
  const [loginId, setLoginId] = useState(false)
  const [signinPassword, setSigninPassword] = useState(false)
  //SignUp States
  const [visiblePass, setVisiblePass] = useState(false)
  const [visiblePassCnfrm, setVisiblePassCnfrm] = useState(false)
  const [inputPassword, setInputPassword] = useState(false)
  const [TokenValidation, setTokenValidation] = useState(false)
  const [email_recovery_password_validation, setEmail_recovery_password_validation] = useState(false)
  const [email_recovery_password, setEmail_recovery_password] = useState("")
  const [emailValidation, setEmailValidation] = useState(false)
  const [nameValidation, setNameValidation] = useState(false)
  const [companynameValidation, setCompanynameValidation] = useState(false)
  const [lastNameValidation, setLastNameValidation] = useState(false)
  const [designationValidation, setDesignationValidation] = useState(false)
  const [pass, setPass] = useState("")
  const [passValidation, setPassValidation] = useState(true)
  const [passcnfrm, setPassCnfrm] = useState("")
  const [passValidate, setPassValidate] = useState(true)
  const [localStorageids, setLocalStorageIds] = useState(false)
  const [timer, setTimer] = useState(0)
  const [verifyCode, setVerifyCode] = useState("")
  const [signupbody, setSignupbody] = useState({})
  const [specialCase, setSpecialCase] = useState(false)
  const [eightChar, setEightChar] = useState(false)
  const [whiteSpace, setWhiteSpace] = useState(true)
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [verifictionToken, setVerificationToken] = useState("")
  const [loader, setLoader] = useState(false)
  const [authPopup, setAuthPopup] = useState(false)



  const NotSupoortedEmail = [...new Set(EmailRevoke)];

  const isFound = () => {
    let result = NotSupoortedEmail.some(element => element === signupbody.email.split('@')[1].split('.')[0])
    return result
  }
  useEffect(() => {
    if (UnAuthorize) {
      if (cookies.getCookie('token')) {
        window.location.reload()
      } else {
        setAuthPopup(true)
      }
    } else {
      if (cookies.getCookie('token')) {
        window.location.href = "#/"
      }
    }
  }, [])

  useEffect(async () => {
    setLoader(true)
    if (id) {
      let EmailId = parseJwt(id)
      setEmailId(EmailId)
    } else if (userid) {
      let response = await getVerifyUserToken(userid)
      if (response) {
        if (response.status) {
          let EmailId = parseJwt(userid)
          setVerificationToken(userid)
          setEmailId(EmailId)
        } else {
          props.history.push("/login")
        }
      }

    }
    setLoader(false)
  }, [])

  // Check SignIn Cookies 
  useEffect(() => {
    let check = checkSignIn()
    if (check) {
      state.redirect = true;
      setState(e => e = state);
    }
    if (localStorage.getItem("veriPass")) {
      setLocalStorageIds(true)
    }
  }, [])
  // <!----- Login Functions Start ->
  // Check Login ID Input
  const checkLoginId = () => {
    if (document.getElementById("_id").value) {
      setLoginId(false)
      return false
    }
    else {
      setLoginId(true)
      return true
    }
  }

  // Check Login Password Input
  const checkLoginpass = () => {
    if (document.getElementById("pass").value) {
      setSigninPassword(false)
      return false
    }
    else {
      setSigninPassword(true)
      return true
    }
  }

  // User LogIn Function 
  const login = async (username, password) => {
    if (!checkLoginId()) {
      if (!checkLoginpass()) {
        let state1 = { ...state }
        state1.loginLoader = true;
        setState(state1)
        if (username, password) {
          let response = await signIn({
            email: username,
            password: password
          });
          // if (response.status === true) {
          //   let state2 = state;
          //   state2.loginLoader = false;
          //   // setPopup(["Success!", response.message, "success"])
          //   // setState(state2)
          // }
          if (response.statusCode) {
            let state2 = state;
            state2.loginLoader = false;
            setPopup(["Failure!", response.message, "danger"])
            setState(state2)
          }
        }
      }
    } else {
      setSigninPassword(false)
    }
  }
  // <!----- Login Functions End ->

  const loginOnKeyPress = (e) => {
    if (e.key === "Enter") {
      login(document.getElementById("_id").value,
        document.getElementById("pass").value)
    }
  }
  const signUpOnKeyPress = (e) => {
    if (e.key === "Enter") {
      SubmitEvent()
    }
  }
  const verificationOnKeyPress = (e) => {
    if (e.key === "Enter") {
      verifyEmailCode()
    }
  }
  const passwordOnKeyPress = (e) => {
    if (e.key === "Enter") {
      password()
    }
  }
  // <!----- SignUp Page -->

  // All SignUp Input OnChangehandler 
  const onChangeHandler = (e) => {
    let data = signupbody
    let name = e.target.id
    data[name] = e.target.value
    delete signupbody["pass"]

    delete signupbody["_id"]

    signupbody.role = "admin";

    if (e.target.id === "firstName") {
      setNameValidation(false)
    }
    if (e.target.id === "lastName") {
      setLastNameValidation(false)
    }
    if (e.target.id === "companyName") {
      setCompanynameValidation(false)
    }
    if (e.target.id === "designation") {
      setDesignationValidation(false)
    }
    setSignupbody(data)
    if (e.target.id === "email") {
      setEmailValidation(false)
    }
  }

  //Check Email  Validate or not  
  const ValidateEmail = () => {
    if (signupbody.email) {
      var mailformat = EmailRegex;
      if (signupbody.email.match(mailformat) && !isFound()) {
        setEmailValidation(false)
        return false
      }
      else {
        setEmailValidation(true)
        return true
      }
    }
    else {
      setEmailValidation(true)
      return true
    }
  }
  //Check Name is null or not
  const checkNameValidation = () => {
    if (signupbody.firstName === "" || signupbody.firstName === null || signupbody.firstName === undefined) {
      setNameValidation(true)
      return true
    }
    else {
      setNameValidation(false)
      return false
    }
  }
  //Check Last Name is null or not
  const checkLastNameValidation = () => {

    if (signupbody.lastName === "" || signupbody.lastName === null || signupbody.lastName === undefined) {
      setLastNameValidation(true)
      return true
    }
    else {
      setLastNameValidation(false)
      return false
    }
  }
  //Check Company Email is null or not
  const checkCompanyNameValidation = () => {

    if (signupbody.companyName === "" || signupbody.companyName === null || signupbody.companyName === undefined) {
      setCompanynameValidation(true)
      return true
    }
    else {
      setCompanynameValidation(false)
      return false
    }
  }
  //CheckEmail is  null or not
  const checkDesignationValidation = () => {

    if (signupbody.designation === "" || signupbody.designation === null || signupbody.designation === undefined) {
      setDesignationValidation(true)
      return true
    }
    else {
      setDesignationValidation(false)
      return false
    }
  }

  //SignUp Form Submit Function 
  const SubmitEvent = () => {
    if (!checkNameValidation()) {
      if (!checkLastNameValidation()) {
        if (!ValidateEmail()) {
          if (!checkCompanyNameValidation()) {
            if (!checkDesignationValidation()) {
              let state1 = { ...state }
              state1.loginLoader = true;
              setState(state1)
              return signUp(signupbody).then(async res => {
                let result = res
                if (result) {
                  let message = result.message
                  if (result.status === "OK") {
                    let Email = result.message.split(" ")[res.message.split(" ").length - 1];
                    localStorage.setItem("email", Email.toString())
                    setPopup(["Success!", message, "success"])

                    localStorage.setItem("information", true)
                    state1.loginLoader = false;
                    setState(state1)
                    history.push('/verify-email')
                    // timeInterval()
                  }
                  else {
                    setPopup(["Failure!", message, "danger"])
                    state1.loginLoader = false;
                    setState(state1)
                  }
                }
              })
            }
          }
          else {
            setDesignationValidation(false)
          }
        }
        else {
          setCompanynameValidation(false)
          setDesignationValidation(false)
        }
      }

      else {
        setEmailValidation(false)
        setCompanynameValidation(false)
        setDesignationValidation(false)
      }
    }
    else {
      setLastNameValidation(false)
      setEmailValidation(false)
      setCompanynameValidation(false)
      setDesignationValidation(false)
    }
  }
  // <!----- SignUp Page -->





  const checkEmail_recovery_password = () => {
    if (email_recovery_password) {
      var mailformat = EmailRegex;
      if (email_recovery_password.match(mailformat) && !isFound()) {
        setEmail_recovery_password_validation(false)
        return false
      }
      else {
        setEmail_recovery_password_validation(true)
        return true
      }
    }
    else {
      setEmail_recovery_password_validation(true)
      return true
    }
  }

  const ForgotPasswordEmail = () => {
    if (!checkEmail_recovery_password()) {
      let state1 = { ...state }
      state1.loginLoader = true;
      setState(state1)
      let body = {
        email: email_recovery_password
      }
      return requestForgotPasswordEmail(body).then(res => {
        let result = res
        if (result) {
          let message = result.message
          if (result.status === "OK") {
            let localStorageid = result.user.id;
            localStorage.setItem("veriPass", localStorageid.toString())
            state1.loginLoader = false;
            setState(state1)
            setPopup1(["Success!", "The password reset email has been sent. Please check your inbox.", "success"])
          }
          else {
            setPopup(["Failure!", message, "danger"])
            state1.loginLoader = false;
            setState(state1)
          }
        }
      })
    }
  }

  //<!-----Email Verification Code Page -->
  //check Token  Input null
  const checkToken = () => {
    if (verifyCode === "" || verifyCode === null || verifyCode === undefined) {
      setTokenValidation(true)
      return true
    }
    else {
      setTokenValidation(false)
      return false
    }
  }
  //verification Token
  const verifyEmailCode = () => {
    if (!checkToken()) {
      let state1 = { ...state }
      state1.loginLoader = true;
      setState(state1)
      return getVerification(verifyCode).then(res => {
        let result = res
        if (result) {
          let message = result.message
          if (result.status === "OK") {
            let localStorageid = result.user.id;
            localStorage.setItem("veriPass", localStorageid.toString())
            // setPopup(message
            state1.loginLoader = false;
            setState(state1)
            setPopup(["Success!", message, "success"])
            localStorage.setItem("verify", true)
            history.push("/create-password")
          }
          else {
            setPopup(["Failure!", message, "danger"])
            state1.loginLoader = false;
            setState(state1)
          }
        }
      })
    }

  }
  //  Verify Token resend in your Email 
  const resendCodes = () => {
    timeInterval()
    let Email = localStorage.getItem("email");
    let body = { email: Email }
    return resendCode(body).then(res => {
      console.log("resendCode")
    })
  }
  //when you hit resend Code button 1 minute time interval
  const timeInterval = () => {
    let time = 60
    let Timer = setInterval(() => {
      time = time - 1
      if (time === 0) {
        clearInterval(Timer)
      }

      setTimer(time)
    }, 1000)
  }
  //<!-----Email Verification Code Page -->

  // user Validation DropDown
  const dropDown = (state = true, message, type) => {
    return (
      <MyDropDown openToggle={(e) => setHover(e)}>

        {state ? !type ?
          <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px 0 calc(1rem + 10px) -55px" }}></span>
          : <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px -36px calc(1rem + 10px) -194px" }}></span>
          : null}
        <MyDropDownBody open={hover ? hover : focus ? focus : false} right>
          <div className='py-3 text-left p-3 ' style={{ width: "300px" }}>
            <small>{message}</small>
            {type === "password" ?
              <div style={{
                padding: 0,
                margin: 0,
                listStyleType: "none",
              }}>
                {eightChar ?
                  <li><i className="far fa-check-circle mr-1 text-success"></i><small>Be a minimum of 8 characters</small></li>
                  :
                  <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Be a minimum of 8 characters</small></li>
                }
                {specialCase ?
                  <li><i className="far fa-check-circle mr-1 text-success"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>
                  : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>

                }
                {whiteSpace ?
                  <li><i className="far fa-check-circle mr-1 text-success"></i><small>Contain not be spaces</small></li>
                  : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Contain not be spaces</small></li>
                }
              </div>
              : null}
          </div>
        </MyDropDownBody>
      </MyDropDown>)
  }

  //<!---Create Password Page-->
  // Check Password and Confirm Password Same or not 
  const cnfrmPassvalidate = (passcnfrm) => {
    if (pass === passcnfrm) {
      setPassValidate(true)
      return true
    } else {
      setPassValidate(false)
      return false
    }
  }
  // password OnChange Function
  const passChangeHandler = (e) => {
    setPass(e.target.value)
    Passwordvalidate(e.target.value)
    setPassValidation(true)
    return true
  }
  // confirm Password OnChange Function
  const passCnfrmHandler = (e) => {
    setPassCnfrm(e.target.value)
    cnfrmPassvalidate(e.target.value)
    return true
  }
  // Check Password  pass all conditions
  const Passwordvalidate = (pass) => {
    var whiteSpace1 = WhiteSpace
    if (whiteSpace1.test(pass)) {
      setWhiteSpace(false)
    } else {
      setWhiteSpace(true)
    }
    if (pass.length >= 8) {
      setEightChar(true)
    } else {
      setEightChar(false)
    }
    var passwordformat = [
      CapitalLetters,
      SmallLetters,
      Numbers,
      SpecialCharacters2]

    let count = 0
    for (let index = 0; index < passwordformat.length; index++) {
      const element = passwordformat[index];
      if (element.test(pass))
        count++
    }
    if (count >= 3) {
      setSpecialCase(true)

    }
    else {
      setSpecialCase(false)
    }
    if (specialCase && eightChar && whiteSpace) {
      setPassValidation(true)
      return true
    }
    else {
      setPassValidation(false)
      return false
    }
  }

  //Reset Password Function when password Return true
  const resetPassword = () => {
    if (Passwordvalidate(pass)) {
      if (cnfrmPassvalidate(passcnfrm)) {
        let state1 = { ...state }
        state1.loginLoader = true;
        setState(state1)

        let body = {
          password: pass,
          forgotPasswordPin: id
        }
        return ForgotPassword(body).then(res => {
          if (res.status) {
            state1.loginLoader = false;
            setState(state1)

            setPopup(["Success!", res.message, "success"])
            localStorage.removeItem("information");
            localStorage.removeItem("verify");
            history.push("/login")
            // window.location.reload()
          }
          else {
            state1.loginLoader = false;
            setState(state1)
            setPopup(["Failure!", res.message, "danger"])
          }
        })
      }
    }
  }
  // password Created Function whan password Return true
  const password = () => {
    if (Passwordvalidate(pass)) {
      if (cnfrmPassvalidate(passcnfrm)) {
        let state1 = { ...state }
        state1.loginLoader = true;
        setState(state1)
        let body = null
        if (userid) {
          body = {
            password: pass,
            _id: emailId.id,
            token: verifictionToken
          }
        } else {
          let localStorageid = localStorage.getItem("veriPass");
          body = {
            password: pass,
            _id: localStorageid
          }
        }
        return setPassword(body).then(res => {
          if (res.status) {
            state1.loginLoader = false;
            setState(state1)

            setPopup(["Success!", res.message, "success"])
            localStorage.removeItem("information");
            localStorage.removeItem("verify");
            history.push("/login")
            // window.location.reload()
          }
          else {
            state1.loginLoader = false;
            setState(state1)
            setPopup(["Failure!", res.message, "danger"])
          }
        })
      }
    }
  }

  //This Function return SignUp Page
  const newAccount = () => {
    localStorage.removeItem("information");
    localStorage.removeItem("verify");
    // window.location.pathname = "/#/signup"
    history.push("/signup")
    window.location.reload()
  }
  //This Function return SignIn Page 
  const AccountLogin = () => {
    localStorage.removeItem("information");
    localStorage.removeItem("verify");
    // window.location.pathname = "/#/signup"
    history.push("/login")
    window.location.reload()
  }
  return (
    <>
      {!loader ?
        <div className='backGifAnimaton'>
          <video className='m-0 w-100 h-100 position-absolute' style={{ objectFit: 'cover', zIndex: "-1", }} onLoadStart={(e) => { e.target.playbackRate = 0.8; e.target.play() }} muted loop>
            <source src={videoBack} type="video/mp4" />
          </video>
          <div className="w-100 h-100" style={{ background: 'linear-gradient(90deg, rgba(0,0,0,0.8), transparent)', zIndex: "2" }}>
            <div
              className="d-flex align-items-center justify-content-md-between justify-content-center p-3"
              style={{ minHeight: "100%", }}
            >
              <div className='d-md-flex d-none flex-column text-white pl-5 fade-in-left-onload' style={{ maxWidth: "600px", gap: "100px" }}>
                <div>
                  <h1 className='font-weight-bold' style={{ fontSize: 50, color: '#59b7ff' }}>Digital Twin Platform</h1>
                  <h1 className='font-weight-bold' style={{ fontSize: 40, color: '#ff5034' }}>Unlocking the Power of IoT</h1>
                </div>
                <div>
                  <p>Our Digital Twin feature lets you create virtual representations of your devices, providing you with greater insight and control over your physical devices.</p>
                  <p>If you have any questions or need help signing in, our support team is always here to help.</p>
                  <p>Thank you for choosing Linked Things as your IoT services provider! We're excited to bring you the best in Digital Twin Platform, and to help you experience the full potential of the Internet of Things.</p>
                </div>
              </div>
              <div className="dropshadow d-flex rounded-4 p-3 pt-5 " style={{ maxWidth: 500, backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255,255,255,0.8)' }}>
                {/* <div className="col-6 pt-5 d-none d-md-flex primary-gradient dropshadow position-relative">
              <div className='backGifAnimaton'></div>
              <div className="row justify-content-center align-self-end py-3" style={{ backdropFilter: "blur(15px) brightness(0.5)" }}>

                <h4 className="col-12 text-center text-light fade-in-bottom-onload"
                  style={{ animationDelay: "0.25s" }}>
                  Metaverse Of Things
                </h4>
                <p className="col-10 text-center text-light fade-in-bottom-onload" style={{ animationDelay: "0.5s" }}>
                  SAVINGS - PRODUCTIVITY - INNOVATION
                </p>
                <div className="col-12 d-flex justify-content-center fade-in-bottom-onload" style={{ animationDelay: "0.8s" }}>
                  <button
                    className="btn btn-light px-3 py-2 rounded-3 border-0 m-1 dropshadow"
                    onClick={
                      () => window.open("https://www.linkedthings.ai", "_blank")
                    }
                  >
                    LEARN MORE
                  </button>
                </div>
              </div>
            </div> */}
                {/* <div className="col-md-6 col-12 pt-5 d-flex"> */}

                {/* Sign Up */}
                {/* <div className="row justify-content-center align-self-center fade-in-right-onload" style={{ display: pageName === "signup" ? "flex" : "none" }}>

                  <div className="col-12">
                    <center className="text-center m-0 my-3"
                      style={{
                        color: "black",
                      }}>
                      <img
                        width="100px"
                        src={Logo}
                      />
                      <h4 className="text-center m-0 my-3">Sign up</h4></center>



                  </div>
                  <div className="col-12 px-md-5 px-3">
                    <div className='row justify-content-center'>
                      <div className='col-6 pr-md-3 pr-1 d-flex'>

                        <input
                          className="form-control rounded-4 py-4 px-4 mb-3"
                          placeholder="First Name*"
                          type="text"
                          style={{
                            borderColor: !nameValidation ? " #1B75BC" : "#FF0000",
                          }}
                          id="firstName"
                          onChange={(e) => onChangeHandler(e)}
                          onKeyDown={(e) => signUpOnKeyPress(e)}
                        />
                        {nameValidation ?
                          dropDown(nameValidation, "Please enter your first name ")
                          : null}
                      </div>
                      <div className='col-6 pl-md-3 pl-1 d-flex'>

                        <input
                          className="form-control rounded-4 py-4 px-4 mb-3"
                          placeholder="Last Name*"
                          type="text"
                          style={{
                            borderColor: !lastNameValidation ? " #1B75BC" : "#FF0000",
                          }}
                          id="lastName"
                          onChange={(e) => onChangeHandler(e)}
                          onKeyDown={(e) => signUpOnKeyPress(e)}

                        />
                        {lastNameValidation ?
                          dropDown(lastNameValidation, "Please enter your last name ")
                          : null}
                      </div>

                      <div className='col-12 d-flex'>

                        <input
                          className="form-control rounded-4 py-4 px-4 mb-3"
                          placeholder="Email*"
                          type="email"
                          style={{
                            borderColor: !emailValidation ? " #1B75BC" : "#FF0000"
                          }}
                          id="email"
                          onChange={(e) => onChangeHandler(e)}
                          onKeyDown={(e) => signUpOnKeyPress(e)}
                        />
                        {emailValidation ?
                          dropDown(emailValidation, "Please enter your company email") : null}
                      </div>

                      <div className='col-12 d-flex'>

                        <input
                          className="form-control rounded-4 py-4 px-4 mb-3"
                          placeholder="Company Name*"
                          type="text"
                          style={{
                            borderColor: !companynameValidation ? "#1B75BC" : "#FF0000",
                          }}
                          id="companyName"
                          onChange={(e) => onChangeHandler(e)}
                          onKeyDown={(e) => signUpOnKeyPress(e)}
                        />
                        {companynameValidation ?
                          dropDown(companynameValidation, "Please enter your company name ")
                          : null}
                      </div>
                      <div className='col-12 d-flex'>

                        <input
                          className="form-control rounded-4 py-4 px-4 mb-3"
                          placeholder="Designation*"
                          type="text"
                          style={{
                            borderColor: !designationValidation ? "#1B75BC" : "#FF0000",
                          }}
                          id="designation"
                          onChange={(e) => onChangeHandler(e)}
                          onKeyDown={(e) => signUpOnKeyPress(e)}
                        />
                        {designationValidation ?
                          dropDown(designationValidation, "Please enter your designation ")
                          : null}
                      </div>
                      {state.loginLoader ?
                        <div style={{ height: "80px" }}>
                          <InlineLoader />
                        </div> :
                        <button
                          className="btn-lg border-0 btn-dark my-3 primary-gradient-hv dropshadow rounded-5"
                          onClick={() => SubmitEvent()}
                        >
                          Verify email address
                        </button>

                      }
                      <div className="col-12 mt-2 text-center"  >
                        <Link to={"/login"}>
                          Already have an account?
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-center ">
                    <div className="mt-3 mb-2 p-3 rounded">
                      <img src={LtLogo} height={75} />
                    </div>
                  </div>
                </div> */}

                {/* Sign In Page */}
                <div className="row justify-content-center align-self-center fade-in-right-onload" style={{ display: pageName === "login" ? " flex " : "none" }}>
                  <div className="col-12">
                    <center>
                      <img
                        width="100px"
                        src={Logo}
                      />
                      <h3
                        className="text-center m-0 my-3"
                        style={{
                          color: "black",
                        }}
                      >
                        {/* Enter into Digital Twin Platform */}
                        Sign in
                      </h3>
                    </center>
                  </div>
                  <div className="col-12 px-5">

                    <center>
                      <div className='d-flex'>
                        <input
                          className="form-control rounded-4 py-4 px-4 mb-3"
                          placeholder="Email"
                          type="email"
                          style={{
                            borderColor: !loginId ? "#1B75BC" : "#FF0000",
                          }}
                          id="_id"
                          onChange={(e) => onChangeHandler(e)}
                          onKeyDown={(e) => loginOnKeyPress(e)}
                        />{loginId ?
                          dropDown(loginId, "Please enter your email")
                          : null}
                      </div>
                      <div className='d-flex'>
                        <input
                          className="form-control rounded-4 py-4 px-4 mb-3"
                          placeholder="Password"
                          type={inputPassword ? "text" : "password"}
                          style={{
                            borderColor: !signinPassword ? "#1B75BC" : "#FF0000",
                          }}
                          id="pass"
                          onChange={(e) => onChangeHandler(e)}
                          onKeyDown={(e) => loginOnKeyPress(e)}
                        />
                        {signinPassword ?
                          dropDown(signinPassword, "Please enter your password", "signinPassword")
                          : null}
                        <button className={inputPassword ? 'btn btn-light  fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setInputPassword(!inputPassword)} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                      </div>
                      {/* <div className='d-flex justify-content-end'>
                        <Link to={'/forgot-password'}>Forgot your password?</Link>
                      </div> */}

                      {state.loginLoader ?
                        <div style={{ height: "80px" }}>
                          <InlineLoader />
                        </div> :

                        <button
                          className="col-8 btn-lg border-0 btn-dark my-3 primary-gradient-hv dropshadow rounded-5"
                          onClick={() =>
                            login(
                              document.getElementById("_id").value,
                              document.getElementById("pass").value
                            )
                          }
                        >
                          Sign in
                        </button>
                      }
                      {/* <div className="col-12 mt-2"  >
                        <Link to={"/signup"}> Create a new account</Link>
                      </div> */}
                    </center>
                  </div>
                  <div className="col-12 d-flex justify-content-center ">
                    <div className="mt-3 mb-2 p-3 rounded">
                      <img src={LtLogo} height={75} />
                    </div>
                  </div>
                </div>

                {/* Verify-Email Page */}
                <div className="row justify-content-center align-self-center fade-in-right-onload" style={{ display: pageName === "verify-email" ? "flex" : "none" }}>
                  <div className="col-12">
                    <center>
                      <img
                        width="100px"
                        src={Logo}
                      />
                      <h4 className="text-center m-0 my-3">Sign up</h4>
                    </center>
                  </div>
                  <div className="col-12 px-5">
                    <div>
                      <p><b>Confirm you are you</b></p>
                      <center>
                        <div className='d-flex'>
                          <input
                            className="form-control rounded-4 py-4 px-4 mb-3"

                            placeholder="Code"
                            type="text"
                            style={{
                              borderColor: !TokenValidation ? " #1B75BC" : "#FF0000",
                            }}
                            id="verifyCode"
                            value={verifyCode}
                            onChange={(e) => { setVerifyCode(e.target.value) }}
                            onKeyDown={(e) => verificationOnKeyPress(e)}
                          />
                          {TokenValidation ?
                            dropDown(designationValidation, "Verification code must be 6 letters long")
                            : null}</div>
                        {state.loginLoader ?
                          <div style={{ height: "80px" }}>
                            <InlineLoader />
                          </div> :
                          <>
                            <button
                              className="col-8 btn-lg border-0 btn-dark my-3 primary-gradient-hv dropshadow rounded-5"
                              onClick={() => verifyEmailCode()}
                            >
                              Verify
                            </button>

                            {timer === 0 ?
                              <button
                                className=" col-8 btn-lg mt-4 border-0 btn-dark dropshadow"
                                style={{
                                  borderRadius: "50px",
                                  backgroundColor:
                                    "gray",
                                }}
                                onClick={() => resendCodes()}
                              >
                                Resend Code
                              </button> : <button
                                className=" col-8 btn-lg mt-4 border-0 btn-dark dropshadow"
                                style={{
                                  borderRadius: "50px",
                                  backgroundColor:
                                    "gray",
                                }}
                              >
                                00:{timer < 10 ? ("0" + timer) : timer}
                              </button>} </>}
                        <div className="col-12 mt-2"  >
                          <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={() => newAccount()}>
                            Sign Up with Another Account
                          </div>
                        </div>
                        <div className="col-12 mt-2"  >
                          <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={() => AccountLogin()}>
                            Already have an account?
                          </div>
                        </div>
                      </center></div>


                  </div>
                  <div className="col-12 d-flex justify-content-center ">
                    <div className="mt-3 mb-2 p-3 rounded">
                      <img src={LtLogo} height={75} />
                    </div>
                  </div>

                </div>
                {/* Create Password Page */}
                <div className="row justify-content-center align-self-center fade-in-right-onload" style={{ display: pageName === "create-password" || (pageName === "forgot-password" && id || userid) ? "flex" : "none" }}>
                  <div className="col-12">
                    <center>
                      <img
                        width="100px"
                        src={Logo}
                      />
                      {
                        id ? null :

                          <h4 className="text-center m-0 my-3">Sign up</h4>
                      }
                    </center>
                  </div>
                  <div className='col-12 px-5'>
                    <div>
                      {
                        id ? <p><b> Change your password</b></p> :
                          <p><b > Create your password</b></p>
                      }
                      <center>
                        {id ?
                          <div>
                            <p>Set a new password for</p>
                            <p>{emailId.email}</p>
                          </div> : null}
                        <div className='d-flex'>
                          <input
                            className="form-control rounded-4 py-4 px-4 mb-3"
                            autocomplete="off"
                            placeholder="Password"
                            value={pass}
                            pattern=".*\S+.*"
                            name="newPassword"
                            type={visiblePass ? "text" : "password"}
                            style={{
                              borderColor: passValidation || pass === "" ? "#1B75BC" : "#FF0000",
                            }}
                            onFocus={() => { setFocus(true); Passwordvalidate(pass); }}
                            onBlur={() => setFocus(false)}
                            id="newpass"
                            onChange={(e) => passChangeHandler(e)}
                            onKeyDown={(e) => passwordOnKeyPress(e)}
                          />
                          {focus ?
                            dropDown(false, ``, "password")
                            : null}
                          {!passValidation && !(pass === "") ?
                            <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px -36px calc(1rem + 10px) -55px" }}></span>
                            : null}
                          <button className={visiblePass ? 'btn btn-light fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setVisiblePass(!visiblePass)} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                        </div>
                        <div className='d-flex'>
                          <input
                            className="form-control rounded-4 py-4 px-4 mb-3"
                            placeholder="Confirm Password"
                            type={visiblePassCnfrm ? "text" : "password"}
                            style={{
                              borderColor: passValidate ? "#1B75BC" : "#FF0000",
                            }}
                            id="passCnfrm"
                            onChange={(e) => passCnfrmHandler(e)}
                            onKeyDown={(e) => passwordOnKeyPress(e)}
                          />

                          <button className={visiblePassCnfrm ? 'btn btn-light fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setVisiblePassCnfrm(!visiblePassCnfrm)} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                        </div>
                        {!passValidate ? <p style={{ marginRight: "120px", color: "red" }}>Please make sure your password match</p> : null}
                        {state.loginLoader ?
                          <div style={{ height: "80px" }}>
                            <InlineLoader />
                          </div> :
                          passValidate ?
                            <button
                              className="col-8 btn-lg border-0 btn-dark my-3 primary-gradient-hv dropshadow rounded-5"
                              onClick={() => id ? resetPassword() : password()}
                            >
                              {id ? "Reset Password" :
                                "Create"
                              }
                            </button>
                            :
                            <button
                              className="col-8 btn-lg border-0 btn-dark my-3 primary-gradient-hv dropshadow rounded-5 disabled"
                            >
                              {id ? "Reset Password" :
                                "Create"
                              }
                            </button>
                        }
                        <div className="col-12 mt-2"  >
                          <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={() => newAccount()}>
                            Sign Up with Another Account
                          </div>
                        </div>
                        <div className="col-12 mt-2"  >
                          <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={() => AccountLogin()}>
                            Already have an account?
                          </div>
                        </div>
                      </center>
                    </div></div>
                  <div className="col-12 d-flex justify-content-center ">
                    <div className="mt-3 mb-2 p-3 rounded">
                      <img src={LtLogo} height={75} />
                    </div>
                  </div>
                </div>
                {/* Recovery password send Email */}
                <div className="row justify-content-center align-self-center fade-in-right-onload" style={{ display: pageName === "forgot-password" && !id ? "flex" : "none" }}>
                  <div className="col-12">
                    <center>
                      <img
                        width="100px"
                        src={Logo}
                      />
                      <h4 className="text-center m-0 my-3">Forgot Password</h4>
                    </center>
                  </div>
                  <div className="col-12 px-5">
                    <div>
                      <center>
                        <div className='d-flex'>
                          <input
                            className="form-control rounded-4 py-4 px-4 mb-3"

                            placeholder="abc@xyz.com"
                            type="text"
                            style={{
                              borderColor: !email_recovery_password_validation ? " #1B75BC" : "#FF0000",
                            }}
                            id="resetEmail"
                            value={email_recovery_password}
                            onChange={(e) => { setEmail_recovery_password(e.target.value) }}
                          />
                          {email_recovery_password_validation ?
                            dropDown(email_recovery_password_validation, "Please Enter Valid Email")
                            : null}</div>

                        {state.loginLoader ?
                          <div style={{ height: "80px" }}>
                            <InlineLoader />
                          </div> :
                          <>
                            <button
                              className="col-8 btn-lg border-0 btn-dark my-3 primary-gradient-hv dropshadow rounded-5"
                              onClick={() => ForgotPasswordEmail()}
                            >
                              Send Email
                            </button>
                          </>}

                        <div className="col-12 mt-2"  >
                          <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={() => newAccount()}>
                            Sign Up with Another Account
                          </div>
                        </div>
                        <div className="col-12 mt-2"  >
                          <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={() => AccountLogin()}>
                            Already have an account?
                          </div>
                        </div>
                      </center></div>


                  </div>
                  <div className="col-12 d-flex justify-content-center ">
                    <div className="mt-3 mb-2 p-3 rounded">
                      <img src={LtLogo} height={75} />
                    </div>
                  </div>

                </div>
                {/* </div> */}
              </div >
            </div >

            {
              popup1 ? <Mypopup title={popup1[0]} type={popup1[2]} noClose={"noClose"} >
                <div className="d-flex  justify-content-end p-3">
                  <Link className='mr-3' to={"/login"} onClick={() => { setPopup1(false) }}>
                    SignIn
                  </Link>
                  <Link to={"/signup"} onClick={() => { setPopup1(false) }} >
                    SignUp
                  </Link>
                </div>
                <p className="p-3 fa-lg"  > {popup1[1]}</p>
              </Mypopup >
                : null}
            {
              popup ? <Mypopup title={popup[0]} type={popup[2]} onClose={(e) => {
                setPopup(null);
              }
              }>
                <p className="p-3" > {popup[1]}</p>
                <div className="d-flex justify-content-center p-3">
                  <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                    setPopup(null);
                  }}>Ok</button>
                </div>
              </Mypopup >
                : null
            }
            {authPopup ? <UnAuthorized onChange={() => setAuthPopup(false)} /> : null}
          </div>
        </div> : <FullLoader />}
    </>
  )
}


