import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { hexToHsl, hslToHex } from '../../../functions/functions'
import "./tank.scss"

function Tank(props) {
    const [night, setNight] = useState(false)
    const [backColor, setBackColor] = useState("#f7eed4")
    const [secondaryColor, setSecondaryColor] = useState("#816518")
    const [config, setConfig] = useState({
        cssColor: "#DBB240",
        value: 70,
        label: "Label",
    })
    const [events, setEvents] = useState({
        Status: {},
        Connection: {}
    })

    useEffect(() => {
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                    let hsl = hexToHsl(config1.cssColor)
                    let back = "#000000"
                    if(hsl){
                        back = hslToHex(hsl.h,hsl.s,90);
                        hsl = hslToHex(hsl.h,hsl.s,30);
                    }
                    setSecondaryColor(hsl)
                    setBackColor(back)
                }
            }
            setConfig(config1)
        }
    }, [props.config])

    useEffect(async () => {
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")
        if (hubId) {
            hubId = hubId[0] + "_" + hubId[1]
        }
        let Events = props?.eventReducer?.deviceEvents

        if (Events.deviceId === props?.config?.deviceId) {
            events1.Status = Events
            setEvents(events1)
        } else if (Events.deviceId === hubId) {
            events1.Connection = Events
            setEvents(events1)
        }
    }, [props.eventReducer.deviceEvents])

    useEffect(() => {
        if (props?.config?.param in events?.Status) {
            let config1 = { ...config }
            if (events.Status[props.config.param] !== null) {
                config1.value = events.Status[props.config.param]
            } else {
                config1.value = 0
            }
            setConfig(config1)
        }
    }, [events.Status])
    useEffect(() => {
        setNight(props?.night)
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                    config1.classColor = "";
                } else {
                    config1.classColor = props.config.color;
                    config1.cssColor = "";
                }
            }
            if ("label" in props.config && props.config?.label) {
                config1.label = props.config.label
            } else {
                config1.label = "Label"

            }
            setConfig(config1)
        }
    }, [props])
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
        // preserveAspectRatio="none" 
        xmlSpace="preserve" 
        version="1.1"
        width="100%"
        height="100%"
        viewBox="0 0 78909 121519"
        xmlnsXlink="http://www.w3.org/1999/xlink" >

        <defs>
            <linearGradient id="id0" gradientUnits="userSpaceOnUse" x1="9416.58" y1="-24420.1" x2="108743" y2="-24420.4">
                <stop offset="0" style={{ stopOpacity: 1, stopColor: "#fde4a3" }} />
                <stop offset="1" style={{ stopOpacity: 1, stopColor: "#faf4e5" }} />
            </linearGradient>
        </defs>
        <g>
            <rect fill={backColor} x="2656" y="5674" width="73597" height="111323" />
            <g className="tank_wave" style={{transition: "all 0.5s", transform: 'translateY(-' + config.value + '%)' }}>
                <path fill={config.cssColor} className= {"wave1"} d="M2656 63635c20021,-12355 30087,7721 73597,0l0 6888 -73597 0 0 -6888z" />
                <path fill={config.cssColor} className={"wave2"} d="M76253 63635c-20021,-12355 -30087,7721 -73597,0l0 6888 73597 0 0 -6888z" />
            </g>
            <rect fill={config.cssColor} x="2656" y="0%" width="73597" height={config.value + "%"} style={{transition: "all 0.5s", transform: 'rotate(180deg)', transformOrigin: 'center' }} />
            <path className="tank_fil4" d="M7880 8719l3073 0c644,0 1171,527 1171,1172l0 102888c0,644 -527,1172 -1171,1172l-3073 0c-644,0 -1171,-528 -1171,-1172l0 -102888c0,-645 527,-1172 1171,-1172z" />
            <path className="tank_fil4" d="M41123 8719l29228 0c917,0 1668,751 1668,1669l0 101894c0,918 -751,1669 -1668,1669l-29228 0c-918,0 -1669,-751 -1669,-1669l0 -101894c0,-918 751,-1669 1669,-1669z" />
            <path fill={secondaryColor} d="M39454 40063c5893,9546 12860,21240 12860,29193 0,8631 -6827,13351 -12860,13351 -6032,0 -12859,-4720 -12859,-13351 0,-7953 7014,-19722 12859,-29193z" />
            <path fill={secondaryColor} d="M2781 115957l73347 0c1530,0 2781,1252 2781,2781l0 0c0,1530 -1251,2781 -2781,2781l-73347 0c-1530,0 -2781,-1251 -2781,-2781l0 0c0,-1529 1251,-2781 2781,-2781zm0 -115957l73347 0c1530,0 2781,1251 2781,2781l0 0c0,1529 -1251,2781 -2781,2781l-73347 0c-1530,0 -2781,-1252 -2781,-2781l0 0c0,-1530 1251,-2781 2781,-2781zm0 23644l73347 0c1530,0 2781,1251 2781,2781l0 0c0,1529 -1251,2781 -2781,2781l-73347 0c-1530,0 -2781,-1252 -2781,-2781l0 0c0,-1530 1251,-2781 2781,-2781zm0 68669l73347 0c1530,0 2781,1252 2781,2781l0 0c0,1530 -1251,2781 -2781,2781l-73347 0c-1530,0 -2781,-1251 -2781,-2781l0 0c0,-1529 1251,-2781 2781,-2781z" />
            <text x="50%" y="57%" dominant-baseline="middle" text-anchor="middle" className="tank_fnt0">{config.value + "%"}</text>
        </g>
    </svg >
  )
}

const mapStateToProps = (state) => ({
    eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(Tank)