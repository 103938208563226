import React, { useState } from 'react'
//Import Libraries
import { useHistory } from 'react-router-dom'
// Import Resources
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card'
import Input from '../../components/input/input'
import InlineLoader from '../../components/loader/inlineLoader'
import Mypopup from '../../components/popup/mypopup'
import Select from '../../components/select/select'
import { organizationCreatedSingle } from '../../services/utilities/events';
import { BusinessCategory } from '../../services/config/generic'
import { ExcludeAlphabets, WhiteSpace, SpecialCharacters, CompanyUrl } from '../../services/config/regex'
import { getCookie, refreshToken } from '../../services/utilities/users'
import cookies from '../../services/utilities/cookies'

const Welcome = () => {

    let history = useHistory()
    //All States
    const [state, setState] = useState({
        loginData: {},
        modal: false,
        redirect: false,
        loginLoader: false,
    })
    let [infoPopup, setInfoPopup] = useState(null)
    let [addOrganization, setAddOrganization] = useState(false)
    let [name, setName] = useState(true)
    let [url, setUrl] = useState(true)
    let [business, setBusiness] = useState(true)
    // let [branches, setBranches] = useState(true)
    let [address, setAddress] = useState(true)
    let superusername = getCookie('user')
    let [organizationBody, setOrganizationBody] = useState({})
    let [result, setResult] = useState(false)

    //Array of business Cateory
    let businessCategory = BusinessCategory

    //Change Handler All inputs 
    const onChangeHandler = (e) => {
        let data = organizationBody
        let name = e.name
        data[name] = e.target.value
        if (e.name === "name") {
            setName(true)
        }
        if (e.name === "companyUrl") {
            setUrl(true)
        }
        if (e.name === "organizationBusiness") {
            setBusiness(true)
        }
        // if (e.name === "noOfLevels") {
        //     setBranches(true)
        // }
        if (e.name === "addressOfHeadquater") {
            setAddress(true)
        }
        setOrganizationBody(data)
    }
    // Check Name Validation 
    const checkName = () => {
        if (!organizationBody.name) {
            setName(false)
            return false
        }

        else {
            setName(true)
            return true
        }
    }
    // Check Company Url Validation  
    const checkURL = () => {
        // let url = /^[a-zA-Z0-9\-_$]+\.[a-zA-Z]{2,5}$/
        let url2 = CompanyUrl
        if (!organizationBody.companyUrl) {
            setUrl(false)
            return false
        }
        else {
            if (organizationBody.companyUrl.match(url2)) {
                setUrl(true)
                return true
            }
            else {
                setUrl(false)
                return (false)
            }
        }
    }
    // Check Business Field Validation 
    const checkBusiness = () => {
        if (!organizationBody.organizationBusiness) {
            setBusiness(false)
            return false
        }
        else {
            setBusiness(true)
            return true
        }
    }
    // Check Number of branches  Validation 
    // const checkBranches = () => {
    //     if (!organizationBody.noOfLevels || organizationBody.noOfLevels < 1) {
    //         setBranches(false)
    //         return false
    //     }
    //     else {
    //         setBranches(true)
    //         return true
    //     }
    // }
    // Check Organization  Address(Area) Validation 
    const checkAddress = () => {
        if (!organizationBody.addressOfHeadquater) {
            setAddress(false)
            return false
        }
        else {
            setAddress(true)
            return true
        }
    }

    //Create Organization Function Check All Validations
    const createOrganization = () => {
        if (checkName()) {
            if (checkURL()) {
                if (checkBusiness()) {
                    // if (checkBranches()) {
                    if (checkAddress()) {
                        organizationBody._id = organizationBody.name.replaceAll(WhiteSpace, "");
                        organizationBody._id = organizationBody._id.replaceAll(SpecialCharacters, "");
                        organizationBody.createdBy = getCookie("email")
                        organizationBody.type = "Organization"
                        // organizationBody.access = ["admin"]
                        let state1 = { ...state }
                        state1.loginLoader = true;
                        setState(state1)
                        return organizationCreatedSingle(organizationBody).then(async res => {
                            let result = res
                            if (result) {
                                let message = result.message
                                if (result.status) {
                                    refreshToken({ "token": cookies.getCookie('token') })
                                    setResult(true)
                                    setInfoPopup(["Success!", message, "success"])
                                    state1.loginLoader = false;
                                    setState(state1)
                                }
                                else {
                                    setResult(false)
                                    setInfoPopup(["Failure!", message, "danger"])
                                    state1.loginLoader = false;
                                    setState(state1)
                                }
                            }
                        })
                    }
                } else {
                    setAddress(true)
                }
            } else {
                // setBranches(true)
                setAddress(true)
            }
        } else {
            setUrl(true)
            setBusiness(true)
            // setBranches(true)
            setAddress(true)
        }
    }
    return (
        <>
            <div className='row m-0 justify-content-center align-items-center px-md-0 px-3' style={{ height: "calc(100vh - 150px)" }} >
                <Card className={"col-lg-4 col-md-6 col-sm-8 col-12 p-md-5 py-1 px-2 fade-in-bottom-onload " + (!addOrganization ? " d-block" : " d-none")}>
                    <CardHeader>
                        <div className='h2 text-center m-0'>
                            Hi, <b>{superusername}</b>!<br />
                        </div>
                        <Heading size={2} align="center">
                            This is your super user ID
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <div className='h6 m-0'>
                            <div>to unlock all features, you need to Add <b>Organization</b> first</div>
                        </div>
                        <ButtonContainer className="mt-3">
                            <Button buttonResult={() => setAddOrganization(true)}>Add Organization</Button>
                        </ButtonContainer>
                    </CardBody>
                </Card>
                <Card className={"col-lg-8 col-md-8 col-sm-8 col-12 p-md-5 p-2 fade-in-right-onload " + (!addOrganization ? " d-none" : " d-block")}>
                    <CardHeader>
                        <Heading size={2} align="center">
                            Add your organization detail
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <div className='row my-md-5 my-3 '>
                            <div className='col-md-6 my-md-4 my-3 col-12'>
                                <Input onEnter={() => createOrganization()} name="name" label="Organization Name*" message={{ msg: "Please enter your eorganization name", status: !name }} onChange={(e) => onChangeHandler(e)} defaultValue={organizationBody.organizationname} />
                            </div>
                            <div className='col-md-6 my-md-4 my-3 col-12'>
                                <Input onEnter={() => createOrganization()} name="companyUrl" label="Company URL*" onChange={(e) => onChangeHandler(e)} message={{ msg: "Please enter your company URL", status: !url }} className="text-primary" />
                            </div>
                            <div className='col-md-6 my-md-4 my-3 col-12'>
                                <Select onEnter={() => createOrganization()} name="organizationBusiness" label="Organization Business*" onClick options={businessCategory} message={{ msg: "Please enter your Business type", status: !business }} onChange={(e) => onChangeHandler(e)} />
                            </div>
                            {/* <div className='col-md-6 my-md-4 my-3 col-12'>
                                <Input onEnter={() => createOrganization()} name="noOfLevels" label="No. of locations (Branches)*" type="number" onChange={(e) => onChangeHandler(e)} message={{ msg: "Please enter the no. of branches", status: !branches }} />
                            </div> */}
                            <div className='col-md-6 my-md-4 my-3 col-12'>
                                <Input onEnter={() => createOrganization()} name="addressOfHeadquater" label="Address*" onChange={(e) => onChangeHandler(e)} message={{ msg: "Please enter address of your Head office", status: !address }} />
                            </div>
                        </div>
                        {state.loginLoader ?
                            <div className='d-flex justify-content-center' style={{ height: "80px" }}>
                                <InlineLoader />
                            </div> :
                            <ButtonContainer className="mt-5">
                                <Button buttonResult={() => createOrganization()}>Create organization</Button>
                            </ButtonContainer>
                        }
                    </CardBody>
                </Card>
            </div>
            {
                infoPopup ? <Mypopup title={infoPopup[0]} type={infoPopup[2]} onClose={(e) => {
                    setInfoPopup(null);
                    if (result) {
                        history.push('/')
                        window.location.reload()
                    }
                }}>
                    <p className="p-3" > {infoPopup[1]}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setInfoPopup(null);
                            if (result) {
                                history.push('/')
                                window.location.reload()
                            }
                        }}>Ok</button>
                    </div>
                </Mypopup >
                    : null
            }
        </>
    )
}

export default Welcome