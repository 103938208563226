import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { configration } from '../reducers/configration';
import { organization } from '../reducers/Org';
import { lastEvent } from '../reducers/lastEvetReducer';

import eventReducer from '../reducers/eventReducer';
import notifyReducer from '../reducers/notifyReducer'
import { themeMode, userInfo, userToken } from '../reducers/userInfo';
import { sidebarReducer } from '../reducers/sidebarReducer';
import { userList } from '../reducers/UserList';


let composeEnhancers = compose
export default createStore(
    combineReducers({
        eventReducer: eventReducer,
        userInfo: userInfo,
        userToken: userToken,
        themeMode: themeMode,
        configration: configration,
        lastEvent: lastEvent,
        organization: organization,
        sidebarReducer: sidebarReducer,
        notifyReducer: notifyReducer,
        userList: userList

    }),
    composeEnhancers(applyMiddleware(thunk))
)