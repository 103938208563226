import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ButtonContainer, Button } from '../arraybutton/arraybutton'
import Input from '../input/input'
import { ConditionList } from '../../services/config/generic'
import Select from '../select/select'
import { getCookie } from '../../services/utilities/users'
import { createAlerts, getAlerts, updateAlert, getDevice, getFunctions } from '../../services/utilities/events'
import Card, { CardBody, CardHeader, InnerCard } from '../card/card'
import { EmailRegex } from '../../services/config/regex'
import { useParams } from 'react-router-dom'
import CardLoader from '../loader/cardLoader'
import Mypopup from '../popup/mypopup'

const SetAlert = (props) => {
    const excludeParam = ["name", "_id", "created", "func ", "entry"]

    let useParam = useParams()
    let id = useParam.deviceId

    let hubIds = props.device.hubId
    //Validation State
    const [name, setName] = useState(true)
    const [parameter, setParameter] = useState(true)
    const [condition, setCondition] = useState(true)
    const [value, setValue] = useState(true)
    const [msg, setMsg] = useState(true)
    const [emailName, setEmailName] = useState(true)
    const [emailValue, setEmailValue] = useState(true)
    const [smsName, setSmsName] = useState(true)
    const [smsValue, setSmsValue] = useState(true)
    //Other States
    const [loader, setLoader] = useState(false)
    let props1 = { device: props.device }
    let deviceId = props1.device._id
    let email = getCookie("email")
    const [data, setData] = useState()
    const [tab, setTab] = useState("sms")
    const [lengthAlert, setLengthAlert] = useState(1)
    const [lengthEmail, setLengthEmail] = useState(0)
    const [lengthSms, setLengthSms] = useState(1)
    const [lengthPrereq, setLengthPrereq] = useState(1)
    const [lengthAction, setLengthAction] = useState(1)
    const [lengthApis, setLengthApis] = useState(1)
    const [alerts, setAlerts] = useState([])
    const [activeAlert, setActiveAlert] = useState(0)
    const [activeEmail, setActiveEmail] = useState(0)
    const [activeSms, setActiveSms] = useState(0)
    const [activeApis, setActiveApis] = useState(0)
    const [activePrereq, setActivePrereq] = useState(0)
    const [activeAction, setActiveAction] = useState(0)
    const [deviceParameter, setDeviceParameter] = useState(props1.device.parameter)
    const [parameterList, setParamerterList] = useState([])
    const [conditionList, setConditionList] = useState([])
    const [cardLoader, setCardLoader] = useState(false)
    const [popup, setPopup] = useState(null)
    const [alertId, setAlertid] = useState("")
    const [deviceIds, setDeviceIds] = useState([])
    const [actionDeviceId, setActionDeviceId] = useState([])
    // const [config, setConfig] = useState({})
    const [devices, setDevices] = useState([])
    const [deviceParamList, setDeviceParamList] = useState([])
    const [deviceActionParamList, setDeviceActionParamList] = useState([])
    const [levelIdList, setLevelIdList] = useState([])
    const [prereqcondition, setPrereqCondition] = useState([])
    const [prereqValue, setPrereqValue] = useState([])
    const [functionX, setFunctionX] = useState(false)
    const [deviceConfig, setDeviceConfig] = useState({})
    const [functionList, setFunctionList] = useState([])
    const [configs, setConfigs] = useState(true)



    const [alert, setAlert] = useState({
        name: "",
        param: "",
        condition: "",
        value: "",
        emails: [],
        sms: [],
        apis: [],
        prerequisites: [],
        action: [],
        createdBy: ""
    })
    let ApiMethod = [{ label: "GET", value: 'GET' },
    { label: "POST", value: 'POST' },
    { label: "PUT", value: 'PUT' },
    { label: "PATCH", value: 'PATCH' },
    { label: "DELETE", value: 'DELETE' }]
    useEffect(() => {
        let alert1 = []
        setAlert({
            name: "Parameter 1",
            param: "",
            condition: "",
            value: "",
            emails: [],
            sms: [],
            apis: [],
            prerequisites: [],
            action: [],
            createdBy: ""
        })
        for (var i = 1; i <= lengthAlert; i++) {
            alert1.push({
                name: "Parameter" + i,
                param: "",
                condition: "",
                value: "",
                emails: [],
                sms: [],
                prerequisites: [],
                apis: [],
                action: [],
                createdBy: ""
            })
        }
        for (var j = 1; j <= lengthEmail; j++) {
            if ("email" in alert1) {
                alert1.map(email => email.emails.push({
                    name: "",
                    value: ""
                }))
            }
        }
        for (var k = 1; k <= lengthSms; k++) {
            if ("sms" in alert1) {
                alert1.map(sms => sms.sms.push({
                    name: "",
                    value: ""
                }))
            }
        }
        for (var l = 1; l <= lengthPrereq; l++) {
            if ("prerequisites" in alert1) {
                alert1.map(prerequisites => prerequisites.prerequisites.push({
                    levelId: "",
                    deviceId: "",
                    parameter: "",
                    condition: "",
                    value: "",
                }))
            }
        }
        for (var m = 1; m <= lengthAction; m++) {
            if ("action" in alert1) {
                alert1.map(action => action.action.push({
                    levelId: "",
                    deviceId: "",
                    parameter: "",
                    value: "",
                }))
            }
        }
        for (var n = 1; n <= lengthApis; n++) {
            if ("apis" in alert1) {
                alert1.map(apis => apis.apis.push({
                    method: "",
                    url: "",
                    body: "",
                }))
            }
        }
        setAlerts(alert1)
    }, [])

    useEffect(() => {
        let parameters = []
        deviceParameter.map(params => {
            parameters.push({ value: params.value, label: params.value })
        })
        setParamerterList(parameters)

        let conditionList = []
        ConditionList.map(condition => {
            conditionList.push({ value: condition, label: condition })
        })
        setConditionList(conditionList)
        getAlert()
        // getDevicesList()
    }, [])



    //get Alert in Alerts Array and single Array Because Single Alert object shoen First Time
    const [checked, setChecked] = useState(true)
    const getAlert = async () => {
        setLoader(true)
        let data = await getAlerts(props1.device._id)
        let data1 = data[0]
        if (data1?.alert[0].config) {
            setFunctionX(true)
        }
        setData(data1)
        if (data) {
            if (data.length) {
                setAlertid(data[0]._id)
                setAlerts(data[0].alert)
                setAlert(data[0].alert[0])
                setChecked(data[0].enabled)
            }
        }
        setLoader(false)
    }

    // const getDevicesList = async () => {
    //     let deviceIds1 = []
    //     setLoader(true)
    //     let devices = await getDevice(hubIds)
    //     setDevices(devices)
    //     devices.map((deviceId, key) => {
    //         deviceIds1.push({ label: deviceId.name, value: deviceId._id, parameter: deviceId.parameter })
    //     })
    //     setDeviceIds(deviceIds1)
    //     setLoader(false)
    // }

    useEffect(() => {
        let levelIds = []
        let branches = false

        if (props.organization.organization) {
            props.organization.organization.allhubs.map((level) => {
                if (props.userInfo.varification?.hierarchy.length) {
                    props.userInfo.varification?.hierarchy.map(levels => {
                        if (level.levelId.match(levels.levelId)) {
                            if (levels.branches.length > 0) {
                                branches = true
                                levels.branches.map(branch => {
                                    if (level.levelId.match(branch.levelId)) {
                                        if (!levelIds.includes(level.levelId)) {
                                            levelIds.push(level.levelId)
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
                if (!branches) {
                    if (!levelIds.includes(level.levelId)) {
                        levelIds.push(level.levelId)
                    }
                }
            })
            levelIds = [...new Set(levelIds.map(id => {
                let label = ""
                id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
                return ({ label, value: id })
            }))]
            // levelIds = [...new Set(props.organization.organization.allhubs.map(hub => hub.levelId))]
            // levelIds = [...new Set(levelIds.map(id => {
            //     let label = ""
            //     id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
            //     return ({ label, value: id })
            // }))]
        }
        setLevelIdList(levelIds)
    }, [])
    //Action UseEffect
    const actionFunc = () => {
        let deviceId = []
        let actionParam = []
        if (alerts[activeAlert]?.action?.[activeAction]?.levelId) {
            props?.organization?.organization?.alldevices.map(device => {
                if (alerts[activeAlert]?.action?.[activeAction].levelId === device.levelId) {
                    props?.configration?.configration?.configuration?.Device.map(deviceConfig => {
                        if (deviceConfig.isControllable && device.type === deviceConfig.type) {
                            deviceId.push({ label: device.name, value: device._id, parameter: device.parameter })
                            setActionDeviceId(deviceId)
                        }
                    })
                }
            })
        } else {
            setActionDeviceId([])
        }
        if (alerts[activeAlert]?.action?.[activeAction]?.deviceId) {
            actionDeviceId.map((deviceId) => {
                if (alerts[activeAlert].action[activeAction].deviceId === deviceId.value) {
                    deviceId.parameter.map(param => {
                        actionParam.push({ label: param.name ? param.name : param.types, value: param.value })
                    })
                    setDeviceActionParamList(actionParam)
                }
            })
        }
    }

    //Prerequisite UseEffect
    const prereqFunc = () => {
        let prereqParam = []
        let deviceId = []
        let deviceConfig = []
        if (alerts[activeAlert]?.prerequisites?.[activePrereq]?.levelId) {
            props?.organization?.organization?.alldevices.map(device => {
                if (alerts[activeAlert]?.prerequisites?.[activePrereq].levelId === device.levelId) {
                    deviceId.push({ label: device.name, value: device._id, parameter: device.parameter })
                    setDeviceIds(deviceId)
                    if (alerts[activeAlert]?.prerequisites?.[activePrereq].deviceId === device._id) {
                        let deviceType = device.type
                        props?.configration?.configration?.configuration?.Device.map(config => {
                            if (deviceType === config.type) {
                                deviceConfig = config
                            }
                        })
                    }
                }
            })
        } else {
            setDeviceIds([])
        }
        if (alerts[activeAlert]?.prerequisites?.[activePrereq]?.deviceId) {
            deviceId.map((deviceId) => {
                if (alerts[activeAlert].prerequisites[activePrereq].deviceId === deviceId.value) {
                    deviceId.parameter.map(param => {
                        prereqParam.push({ label: param.name ? param.name : param.types, value: param.value })
                    })
                    setDeviceParamList(prereqParam)
                }
            })
        }
        let condition1 = []
        let value1 = []
        if (deviceParamList.length) {
            prereqParam.map(paramlabel => {
                if (alerts[activeAlert]?.prerequisites[activePrereq]?.parameter === paramlabel.value) {
                    let label = paramlabel.label
                    if (deviceConfig?.settings?.prereq?.length) {
                        deviceConfig?.settings?.prereq?.map((deviceParam) => {
                            if (label === deviceParam.parameter) {
                                if (deviceParam.conditions.length) {
                                    deviceParam.conditions.map((condition) => {
                                        condition1.push({ label: condition, value: condition })
                                    })
                                    setPrereqCondition(condition1)
                                } if (deviceParam.values.length) {
                                    deviceParam.values.map((value) => {
                                        value1.push({ label: value.toString(), value: value })
                                    })
                                    setPrereqValue(value1)
                                } else {
                                    setPrereqValue([])
                                }
                            }
                        })
                    } else {
                        setPrereqCondition(condition1)
                    }
                }
            })
        }
    }
    useEffect(() => {
        actionFunc()
    }, [alert, activeAction, activeAlert])
    useEffect(() => {
        prereqFunc()
    }, [alert, activePrereq, activeAlert])

    useEffect(() => {
        actionFunc()
        prereqFunc()
    }, [tab])

    // props?.configration?.configration?.configuration?.Device.map(deviceConfig => {
    //     if (deviceConfig?.type === config.type) {
    // })
    //     }
    // setConfig(device)



    const checkName = () => {
        if (!alert.name) {
            setName(false)
            return false
        } else {
            setName(true)
            return true
        }
    }
    const checkParam = () => {
        if (!alert.param) {
            setParameter(false)
            return false
        } else {
            setParameter(true)
            return true
        }
    }
    const checkCondition = () => {
        if (!alert.condition) {
            setCondition(false)
            return false
        } else {
            setCondition(true)
            return true
        }
    }
    const checkValue = () => {
        if (!alert.value) {
            setValue(false)
            return false
        } else {
            setValue(true)
            return true
        }
    }
    const checkMsg = () => {
        if (!alert.msg) {
            setMsg(false)
            return false
        } else {
            setMsg(true)
            return true
        }
    }

    const CheckSmsName = () => {
        let returnVal = true;
        if (alert.sms.length > 0) {
            alert.sms.map((activeSms, key) => {
                if (!activeSms.name) {
                    ChangeSms(alert, key)
                    setSmsName(false)
                    returnVal = false
                } else {
                    setSmsName(true)
                    returnVal = true
                }
            })
        }
        else {
            setSmsName(true)
            returnVal = true
        }
        return returnVal
    }

    const CheckSmsValue = () => {
        let returnVal = true;
        if (alert.sms.length > 0) {
            alert.sms.map((activeSms, key) => {
                if (!activeSms.value) {
                    ChangeSms(alert, key)
                    setSmsValue(false)
                    returnVal = false
                } else {
                    setSmsValue(true)
                    returnVal = true
                }
            })
        } else {
            setSmsValue(true)
            returnVal = true
        }
        return returnVal
    }

    const CheckEmailName = () => {
        let returnVal = true;
        if (alert.emails.length) {

            alert.emails.map((activeEmail, key) => {
                if (!activeEmail.name) {
                    ChangeEmail(alert, key)
                    setEmailName(false)
                    returnVal = false
                } else {
                    setEmailName(true)
                    returnVal = true
                }
            })
        } else {
            setEmailName(true)
            returnVal = true
        }
        return returnVal
    }

    const CheckEmailValue = () => {
        let returnVal = true;
        if (alert.emails.length) {
            alert.emails.map((activeEmail, key) => {
                let Email = activeEmail?.value.match(EmailRegex)
                ChangeEmail(alert, key)
                if (!activeEmail.value || !Email) {
                    setEmailValue(false)
                    returnVal = false
                } else {
                    setEmailValue(true)
                    returnVal = true
                }
            })
        } else {
            setEmailName(true)
            returnVal = true
        }
        return returnVal
    }
    const onChangeHandler = (e, index) => {
        let data = { ...alert }

        if (e.target.name === "enabled") {
            setChecked(!checked)
        }
        let name = e.name
        if (name === "smsName") {
            data["sms"][activeSms]["name"] = e.target.value
            setSmsName(true)
        } else if (name === "smsValue") {
            data["sms"][activeSms]["value"] = e.target.value
            setSmsValue(true)
        }
        else if (name === "emailName") {
            data["emails"][activeEmail]["name"] = e.target.value
            setEmailName(true)
        }
        else if (name === "emailValue") {
            data["emails"][activeEmail]["value"] = e.target.value
            setEmailValue(true)
        } else if (name === "prereqlevelId") {
            data["prerequisites"][activePrereq]["levelId"] = e.target.value
        } else if (name === "prereqdeviceId") {
            data["prerequisites"][activePrereq]["deviceId"] = e.target.value
        } else if (name === "prereqparameter") {
            data["prerequisites"][activePrereq]["parameter"] = e.target.value
        } else if (name === "prereqcondition") {
            data["prerequisites"][activePrereq]["condition"] = e.target.value
        } else if (name === "prereqvalue") {
            data["prerequisites"][activePrereq]["value"] = e.target.value
        } else if (name === "actionlevelId") {
            data["action"][activeAction]["levelId"] = e.target.value
        } else if (name === "actiondeviceId") {
            data["action"][activeAction]["deviceId"] = e.target.value
            data["action"][activeAction]["hubId"] = e.target.value.split("_")[0] + "_" + e.target.value.split("_")[1]
        } else if (name === "actionparameter") {
            data["action"][activeAction]["parameter"] = e.target.value
        } else if (name === "actionvalue") {
            data["action"][activeAction]["value"] = e.target.value
        } else if (name === "method") {
            data["apis"][activeApis]["method"] = e.target.value
        } else if (name === "url") {
            data["apis"][activeApis]["url"] = e.target.value
        } else if (name === "body") {
            data["apis"][activeApis]["body"] = e.target.value
        } else if (name === "headers") {
            data["apis"][activeApis]["headers"] = e.target.value
        }
        else {
            data[name] = e.target.value
        }
        if (e.name === "name") {
            setName(true)
        } else if (e.name === "param") {
            setParameter(true)
        } else if (e.name === "condition") {
            if (e.target.value === "f(x)") {
                data["value"] = "1"
                setFunctionX(true)
                let functionArray1 = []
                if (!functionList.length) {
                    getFunctions().then((array) => {
                        array.map(array => {
                            functionArray1.push({ label: array.name, value: array })
                        }
                        )
                        setFunctionList(functionArray1)
                    })
                }
            } else {
                setFunctionX(false)
            }
            setCondition(true)
        } else if (e.name === "config") {
            setConfigs(true)
        }
        else if (e.name === "condition") {
            setCondition(true)
        } else if (e.name === "value") {
            setValue(true)
        } else if (e.name === "msg") {
            setMsg(true)
        }
        setAlert(data)
        let alerts1 = alerts
        alerts1[activeAlert] = data
        setAlerts(alerts1)
    }
    const ChangeState = (alertdata, key) => {
        // ChangeEmail(alertdata, 0, "changeState")
        if (alertdata.config) {
            setFunctionX(true)
        } else {
            setFunctionX(false)
        }
        setActiveEmail(0);
        setActiveSms(0)
        setActiveAlert(key);
        setAlert(alertdata)
    }
    const ChangeEmail = (EmailData, key, liveParam = null) => {
        setActiveEmail(key);
        // let alert1 = { ...alert }
        // alert1.emails[key] = EmailData
        setAlert(EmailData)
        // setTimeout(() => {
        // }, 100);
    }
    const ChangeSms = (SmsData, key) => {
        setActiveSms(key)
        // let alert1 = { ...alert }
        // alert1.sms[key] = SmsData
        setAlert(SmsData)
    }
    const ChangeApis = (ApiData, key) => {
        setActiveApis(key)
        // let alert1 = { ...alert }
        // alert1.sms[key] = SmsData
        setAlert(ApiData)
    }

    const ChangePrereq = (prereqData, key) => {
        setActivePrereq(key)
        setAlert(prereqData)
    }

    const ChangeAction = (actionData, key) => {
        setActiveAction(key)
        setAlert(actionData)
    }

    const addAlert = () => {
        let newlength = (lengthAlert + 1);
        let alert1 = [...alerts]
        alert1.push({
            name: "Parameter" + newlength,
            param: "",
            condition: "",
            emails: [],
            sms: [],
            apis: [],
            prerequisites: [],
            action: [],
            createdBy: ""
        })
        setAlerts(alert1);
        setLengthAlert(newlength)
    }
    const deleteAlert = (key) => {
        let remove = [...alerts]
        remove.splice(key, 1)
        setAlerts(remove)
        if (activeAlert > 0) {
            ChangeState(alerts[activeAlert - 1], activeAlert - 1)
        }
        else {
            ChangeState(alerts[activeAlert + 1], activeAlert)
        }
    }
    const addAlertEmail = (index) => {
        let newlength = (alerts[activeAlert].emails.length + 1);
        let alert1 = [...alerts]
        alert1[index].emails.push({
            name: "email" + newlength,
            value: ""
        })
        setLengthEmail(newlength)
        setAlerts(alert1);
        setAlert(alert1[index])
    }
    const deleteEmailAlert = (key) => {
        let removeEmail = [...alerts]
        removeEmail[activeAlert].emails.splice(key, 1)
        setAlerts(removeEmail)
        if (activeEmail > 0) {
            ChangeEmail(removeEmail[activeAlert], activeEmail - 1, "if deleteEmail")
        }
        else {
            ChangeEmail(removeEmail[activeAlert], activeEmail, "else deleteEmail")
        }
    }

    const addAlertSms = (index) => {
        let newlength = (alerts[activeAlert].sms.length + 1);
        let alert1 = [...alerts]
        alert1[index].sms.push({
            name: "sms" + newlength,
            value: ""
        })
        setLengthSms(newlength)
        setAlerts(alert1);
        setAlert(alert1[index])
    }

    const deleteSmsAlert = (key) => {
        let removeSms = [...alerts]
        removeSms[activeAlert].sms.splice(key, 1)
        setAlerts(removeSms)
        if (activeSms > 0) {
            ChangeSms(removeSms[activeAlert], activeSms - 1)
        }
        else {
            ChangeSms(removeSms[activeAlert], activeSms)
        }
    }

    const addAlertApis = (index) => {
        let newlength = (alerts[activeAlert].apis.length + 1);
        let alert1 = [...alerts]
        alert1[index].apis.push({
            headers: "",
            method: "",
            url: "",
            body: ""
        })
        setLengthApis(newlength)
        setAlerts(alert1);
        setAlert(alert1[index])
    }

    const deleteApisAlert = (key) => {
        let removeApis = [...alerts]
        removeApis[activeAlert].apis.splice(key, 1)
        setAlerts(removeApis)
        if (activeApis > 0) {
            ChangeApis(removeApis[activeAlert], activeApis - 1)
        }
        else {
            ChangeApis(removeApis[activeAlert], activeApis)
        }
    }
    const functionHandler = (e) => {
        let alerts1 = [...alerts]
        let func = { ...alerts1[activeAlert]?.config }
        alerts1[activeAlert].config = func
        func[e.name] = parseFloat(e.target.value)
        setAlerts(alerts1)
    }
    const addAlertPrereq = (index) => {
        let newlength = (alerts[activeAlert].prerequisites.length + 1)
        let alert1 = [...alerts]
        alert1[index].prerequisites.push({
            name: 'Condition' + newlength,
            levelId: "",
            deviceId: "",
            parameter: "",
            condition: "",
            value: "",
        })
        setLengthPrereq(newlength)
        setAlerts(alert1)
        setAlert(alert1[index])
    }
    const deleteAlertPrereq = (key) => {
        let removeprereq = [...alerts]
        removeprereq[activeAlert].prerequisites.splice(key, 1)
        setAlerts(removeprereq)
        if (activePrereq > 0) {
            ChangePrereq(removeprereq[activeAlert], activePrereq - 1)
        }
        else {
            ChangePrereq(removeprereq[activeAlert], activePrereq)
        }
    }

    const addAlertAction = (index) => {
        let newlength = (alerts[activeAlert].action.length + 1)
        let alert1 = [...alerts]
        alert1[index].action.push({
            name: "Action" + newlength,
            deviceId: "",
            parameter: "",
            value: "",
            hubId: ""
        })
        setLengthAction(newlength)
        setAlerts(alert1)
        setAlert(alert1[index])
    }
    const deleteAlertAction = (key) => {
        let removeaction = [...alerts]
        removeaction[activeAlert].action.splice(key, 1)
        setAlerts(removeaction)
        if (activeAction > 0) {
            ChangeAction(removeaction[activeAlert], activeAction - 1)
        }
        else {
            ChangeAction(removeaction[activeAlert], activeAction)
        }
    }


    const SaveAlerts = () => {
        let filledInput = true
        let alertKey = ""
        let smsKey = ""
        let emailKey = ""

        //Check Every Alert of data is full filled
        for (var i = 0; i <= (alerts.length - 1); i++) {
            if (!alerts[i].name || !alerts[i].param || !alerts[i].condition || !alerts[i].value || !alerts[i].msg) {
                filledInput = false
                alertKey = i
                smsKey = activeSms
                emailKey = activeEmail
                break
            }
            for (var k = 0; k <= (alerts[i].sms.length - 1); k++) {
                if (!alerts[i].sms[k]?.name || (!alerts[i].sms[k]?.value)) {
                    filledInput = false
                    alertKey = i
                    smsKey = k
                    emailKey = activeEmail
                    break
                }
            }
            for (var j = 0; j <= (alerts[i].emails.length - 1); j++) {
                let Email = !alerts[i][j]?.value.match(EmailRegex)
                if (!alerts[i].emails[j]?.name || (!alerts[i].emails[j]?.value || !Email)) {
                    filledInput = false
                    alertKey = i
                    smsKey = activeSms
                    emailKey = j
                    break
                }
            }

        }

        if (checkName()) {
            if (checkParam()) {
                if (checkCondition()) {
                    if (checkValue()) {
                        if (checkMsg()) {
                            if (CheckSmsName()) {
                                if (CheckSmsValue()) {
                                    if (CheckEmailName()) {
                                        if (CheckEmailValue()) {
                                            let newkey = activeAlert + 1
                                            if (alerts.length > activeAlert + 1) {
                                                ChangeState(alerts[newkey], newkey)

                                            } else {
                                                if (filledInput) {
                                                    setCardLoader(true)
                                                    let alerts1 = alerts.map(alert => { alert.createdBy = email; return alert })
                                                    setAlerts(alerts1)
                                                    let body = {
                                                        name: "Alert 1",
                                                        deviceId: deviceId,
                                                        enabled: checked,
                                                        alert: alerts
                                                    }
                                                    if (alertId) {
                                                        return updateAlert(alertId, body).then(async res => {
                                                            let result = res
                                                            let message = res.message
                                                            if (result) {
                                                                if (!result.statusCode) {
                                                                    setPopup(["Success!", "Successfully Updated", "success"])
                                                                }
                                                                else {
                                                                    setPopup(["Failure!", message, "danger"])
                                                                }
                                                            }
                                                            setCardLoader(false)
                                                        })
                                                    } else {
                                                        return createAlerts(body).then(async res => {
                                                            let result = res
                                                            let message = res.message
                                                            if (result) {
                                                                if (!result.statusCode) {
                                                                    setPopup(["Success!", "Successfully Created", "success"])
                                                                }
                                                                else {
                                                                    setPopup(["Failure!", message, "danger"])
                                                                }
                                                            }
                                                            setCardLoader(false)
                                                        })
                                                    }
                                                } else {
                                                    ChangeState(alerts[alertKey], alertKey)
                                                    ChangeSms(alerts[alertKey], smsKey)
                                                    ChangeEmail(alerts[alertKey], emailKey)
                                                }

                                            }
                                        }
                                    } else {
                                        CheckEmailValue(true)
                                    }
                                } else {
                                    CheckEmailName(true)
                                    CheckEmailValue(true)

                                }
                            } else {
                                CheckSmsValue(true)
                                CheckEmailName(true)
                                CheckEmailValue(true)

                            }
                        }
                        else {
                            CheckSmsName(true)
                            CheckSmsValue(true)
                            CheckEmailName(true)
                            CheckEmailValue(true)
                        }
                    } else {
                        setMsg(true)
                        CheckSmsName(true)
                        CheckSmsValue(true)
                        CheckEmailName(true)
                        CheckEmailValue(true)
                    }
                } else {
                    setValue(true)
                    setMsg(true)
                    CheckSmsName(true)
                    CheckSmsValue(true)
                    CheckEmailName(true)
                    CheckEmailValue(true)
                }
            }
            else {
                setCondition(true)
                setValue(true)
                setMsg(true)
                CheckSmsName(true)
                CheckSmsValue(true)
                CheckEmailName(true)
                CheckEmailValue(true)
            }
        }
        else {
            setParameter(true)
            setCondition(true)
            setValue(true)
            setMsg(true)
            CheckSmsName(true)
            CheckSmsValue(true)
            CheckEmailName(true)
            CheckEmailValue(true)
        }

    }
    return (
        <div className='container mt-3'>
            {!loader ?
                <Card>
                    <CardHeader className="p-0">
                        <div className='row m-0 pb-4'>
                            <div className='col d-flex'>
                                <div className={'tabButton d-flex active'}>
                                    <div className='inner-btn'>Alert 1</div>
                                    {/* {devices.length > 1 ?
                                    <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteDevice(key)}><i className='fas fa-trash fa-sm'></i></div>
                                : null} */}
                                </div>
                                {/* )} */}
                            </div>
                            {/* <div className='justify-content-end px-3'>
                        <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3">+</button>
                    </div> */}
                        </div>
                        <div className='m-2'>
                            <label>
                                <input type="checkbox" name='enabled' defaultChecked={checked} onChange={(e) => onChangeHandler(e)} />
                                {/* <input type="checkbox" name='enabled' onChange={(e) => onChangeHandler(e)} /> */}
                                Enabled
                            </label>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <CardHeader className="p-0">
                            <div className='row m-0 pb-4'>
                                <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }} >
                                    {alerts.map((alertData, key) =>
                                        <div className={'tabButton d-flex ' + (key === activeAlert ? "active" : "null")} style={{ minWidth: (key === activeAlert) ? "130px" : "100px" }}>
                                            <div className='inner-btn text-truncate' onClick={() => ChangeState(alertData, key)} key={key}>{alerts[key].name}</div>
                                            {alerts.length > 1 ?
                                                <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteAlert(key)}><i className='fas fa-trash fa-sm'></i></div>
                                                : null}
                                        </div>
                                    )}
                                    {/* )} */}
                                </div>
                                <div className='justify-content-end px-3'>
                                    <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlert()} >+</button>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            {
                                alerts.length > 0 ?
                                    <div>
                                        <div className='row m-0 mt-4'>
                                            <div className='col-6'>
                                                <Input name="name" label="Name*" onChange={(e) => onChangeHandler(e)} defaultValue={alerts[activeAlert].name} message={{ msg: "Please Enter Alert Name", status: !name }} />
                                            </div>
                                            <div className='col-6'>
                                                <Select dropDownHeight="100px" name="param" label="Parameter" onClick defaultValue={{ value: alerts[activeAlert].param }} options={parameterList} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Alert Parameter", status: !parameter }} />
                                            </div>
                                        </div>
                                        {/* <div className='row m-0 mt-4'>
                                            <div className='col-6'>
                                                <Select dropDownHeight="100px" name="condition" label="Condition" onClick defaultValue={{ value: alerts[activeAlert].condition }} options={conditionList} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Alert Condition", status: !condition }} />
                                            </div> */}
                                        <div className='row m-0 mt-4'>
                                            <div className='col-6 mt-'>
                                                <Select dropDownHeight="100px" name="condition" label="Condition" onClick defaultValue={{ value: alerts[activeAlert].condition }} options={conditionList} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Alert Condition", status: !condition }} />
                                            </div>
                                            {functionX ?
                                                <>
                                                    <div className='col-6 nt-3'>
                                                        <Select dropDownHeight="100px" name="config" label="Config*" onClick options={functionList} defaultValue={{ label: alerts[activeAlert].config?.name ? alerts[activeAlert].config?.name : "" }} onChange={(e) => { onChangeHandler(e) }} message={{ msg: "Please Enter Alert Value", status: !configs }} />
                                                    </div>

                                                    {alerts[activeAlert].config ?
                                                        Object.keys(alerts[activeAlert].config).map((key) =>
                                                            !excludeParam[0].match(key) && !excludeParam[1].match(key) && !excludeParam[2].match(key) && !excludeParam[3].match(key) &&
                                                            <div className='col-6 mt-4'>
                                                                <Input name={key} label={key} type="number" defaultValue={alerts[activeAlert].config[key]} onChange={(e) => functionHandler(e)} />
                                                            </div>
                                                        )
                                                        : null}

                                                </>
                                                :
                                                <div className='col-6'>
                                                    <Input name="value" label="Value*" type="number" defaultValue={alerts[activeAlert].value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Alert Value", status: !value }} />
                                                </div>
                                            }
                                        </div>
                                        {/* <div className='col-6'>
                                            <Input name="value" label="Value*" type="number" defaultValue={alerts[activeAlert].value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Alert Value", status: !value }} />
                                        </div> */}
                                        {/* </div> */}
                                        <div className='row m-0 mt-4'>
                                            <div className='col-12'>
                                                <Input name="msg" label="Message*" defaultValue={alerts[activeAlert].msg} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Alert Message", status: !msg }} />
                                            </div>
                                        </div>
                                        <div className='px-4'>
                                            <InnerCard>
                                                <CardHeader className='p-0'>
                                                    <div className='row m-0 pb-4 mt-4'>
                                                        <div className={"col d-flex"}>
                                                            <div className={'tabButton d-flex ' + (tab === "sms" ? "active" : "null")}>
                                                                <div className='inner-btn' onClick={() => setTab("sms")}>SMS</div>
                                                            </div>
                                                            <div className={'tabButton d-flex ' + (tab === "email" ? "active" : "null")}>
                                                                <div className='inner-btn' onClick={() => setTab("email")}>EMAIL</div>
                                                            </div>
                                                            <div className={'tabButton d-flex ' + (tab === "prereq" ? "active" : "null")}>
                                                                <div className='inner-btn' onClick={() => setTab("prereq")}>AND CONDITION</div>
                                                            </div>
                                                            <div className={'tabButton d-flex ' + (tab === "action" ? "active" : "null")}>
                                                                <div className='inner-btn' onClick={() => setTab("action")}>ACTION</div>
                                                            </div>
                                                            <div className={'tabButton d-flex ' + (tab === "API" ? "active" : "null")}>
                                                                <div className='inner-btn' onClick={() => setTab("API")}>API</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    {/* Tab SMS */}
                                                    {
                                                        tab === "sms" ?

                                                            <div>
                                                                <CardHeader className='p-0'>
                                                                    <div className='row m-0 pb-4'>
                                                                        <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                                            {alerts[activeAlert].sms.map((smsData, key) =>
                                                                                <div className={'tabButton d-flex ' + (key === activeSms ? "active" : "null")} style={{ minWidth: (key === activeSms) ? "130px" : "100px" }}>
                                                                                    <div className='inner-btn text-truncate' onClick={() => ChangeSms(alerts[activeAlert], key)} key={key}>{smsData.name}</div>
                                                                                    {alerts[activeAlert].sms.length > 0 ?
                                                                                        <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteSmsAlert(key)}><i className='fas fa-trash fa-sm'></i></div>
                                                                                        : null}
                                                                                </div>
                                                                            )}
                                                                            {/* )} */}
                                                                        </div>
                                                                        <div className='justify-content-end px-3'>
                                                                            <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertSms(activeAlert)}>+</button>
                                                                        </div>
                                                                    </div>
                                                                </CardHeader>
                                                                {alerts[activeAlert].sms.length > 0 ?
                                                                    <div className='row m-0 mt-4 pb-4'>
                                                                        <div className='col-6'>
                                                                            <Input name="smsName" label="Name*" defaultValue={alerts[activeAlert].sms[activeSms].name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Name", status: !smsName }} />
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <Input name="smsValue" label="Phone No.*" type="number" defaultValue={alerts[activeAlert].sms[activeSms].value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Number", status: !smsValue }} />
                                                                        </div>
                                                                    </div> : null}
                                                            </div>
                                                            : null
                                                    }
                                                    {/* Tab Email */}
                                                    {
                                                        tab === "email" ?
                                                            <div>
                                                                <CardHeader className='p-0'>
                                                                    <div className='row m-0 pb-4'  >
                                                                        <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }} >
                                                                            {alerts[activeAlert].emails.map((emailData, key) =>
                                                                                <div className={'tabButton d-flex ' + (key === activeEmail ? "active" : "null")} style={{ minWidth: (key === activeEmail) ? "130px" : "100px" }}>
                                                                                    <div className='inner-btn text-truncate' onClick={() => ChangeEmail(alerts[activeAlert], key, "onButtonClick")} key={key}>{emailData.name}</div>
                                                                                    {alerts[activeAlert].emails.length > 0 ?
                                                                                        <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteEmailAlert(key)}><i className='fas fa-trash fa-sm'></i></div>
                                                                                        : null}
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                        <div className='justify-content-end px-3'>
                                                                            <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertEmail(activeAlert)}>+</button>
                                                                        </div>
                                                                    </div>
                                                                </CardHeader>
                                                                {alerts[activeAlert].emails.length > 0 ?
                                                                    <div className='row m-0 mt-4 pb-4'>
                                                                        <div className='col-6'>
                                                                            <Input name="emailName" label="Name*" defaultValue={alerts[activeAlert].emails[activeEmail].name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Name", status: !emailName }} />
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <Input name="emailValue" label="Email.*" defaultValue={alerts[activeAlert].emails[activeEmail].value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Valid Emailadd vlidations", status: !emailValue }} />
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                            </div> : null
                                                    }
                                                    {/* Tab Prerequisite */}
                                                    {tab === "prereq" ? <div>
                                                        <CardHeader className='p-0'>
                                                            <div className='row m-0 pb-4'  >
                                                                <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                                    {alerts[activeAlert].prerequisites.map((prereqData, key) =>
                                                                        <div className={'tabButton d-flex ' + (key === activePrereq ? "active" : "null")} style={{ minWidth: (key === activePrereq) ? "130px" : "100px" }}>
                                                                            <div className='inner-btn text-truncate' onClick={() => ChangePrereq(alerts[activeAlert], key, "onButtonClick")} key={key}>{prereqData.name}</div>
                                                                            {alerts[activeAlert].prerequisites.length > 0 ?
                                                                                <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteAlertPrereq(key)}><i className='fas fa-trash fa-sm'></i></div>
                                                                                : null}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='justify-content-end px-3'>
                                                                    <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertPrereq(activeAlert)}>+</button>
                                                                </div>
                                                            </div>
                                                        </CardHeader>
                                                        {alerts[activeAlert].prerequisites.length > 0 ?
                                                            <div className='row m-0 mt-4 pb-4'>
                                                                <div className='col-6 mt-3'>
                                                                    <Select onClick dropDownHeight="100px" options={levelIdList} defaultValue={{ value: alerts[activeAlert].prerequisites[activePrereq].levelId }} onChange={(e) => onChangeHandler(e)} name="prereqlevelId" label="LevelId*" />
                                                                </div>
                                                                <div className='col-6 mt-3'>
                                                                    <Select onClick dropDownHeight="100px" options={deviceIds} defaultValue={{ value: alerts[activeAlert].prerequisites[activePrereq].deviceId }} onChange={(e) => onChangeHandler(e)} name="prereqdeviceId" label="Device*" />
                                                                </div>
                                                                <div className='col-6  mt-3'>
                                                                    <Select onClick dropDownHeight="100px" options={deviceParamList} defaultValue={{ value: alerts[activeAlert].prerequisites[activePrereq].parameter }} onChange={(e) => onChangeHandler(e)} name="prereqparameter" label="Parameter.*" />
                                                                </div>
                                                                <div className='col-6  mt-3'>
                                                                    <Select name="prereqcondition" onClick dropDownHeight="100px" label="Condition.*" defaultValue={{ value: alerts[activeAlert].prerequisites[activePrereq].condition }} options={prereqcondition} onChange={(e) => onChangeHandler(e)} />
                                                                </div>
                                                                {prereqValue.length > 0 ?
                                                                    <div className='col-6 mt-3'>
                                                                        <Select name="prereqvalue" onClick dropDownHeight="100px" label="Value.*" defaultValue={{ value: alerts[activeAlert].prerequisites[activePrereq].value }} options={prereqValue} onChange={(e) => onChangeHandler(e)} />
                                                                    </div> :
                                                                    <div className='col-6  mt-3'>
                                                                        <Input name="prereqvalue" label="Value.*" defaultValue={alerts[activeAlert].prerequisites[activePrereq].value} type="number" onChange={(e) => onChangeHandler(e)} />
                                                                    </div>}
                                                            </div>
                                                            : null}
                                                    </div> : null}

                                                    {/* Tab Action */}
                                                    {tab === "action" ?
                                                        <div>
                                                            <CardHeader className='p-0'>
                                                                <div className='row m-0 pb-4'>
                                                                    <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                                        {alerts[activeAlert].action.map((actionData, key) =>
                                                                            <div className={'tabButton d-flex ' + (key === activeAction ? "active" : "null")} style={{ minWidth: (key === activeAction) ? "130px" : "100px" }}>
                                                                                <div className='inner-btn text-truncate' onClick={() => ChangeAction(alerts[activeAlert], key, "onButtonClick")} key={key}>{actionData.name}</div>
                                                                                {/* <div className='inner-btn' >Prereq</div> */}
                                                                                {alerts[activeAlert].action.length > 0 ?
                                                                                    <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteAlertAction(key)}><i className='fas fa-trash fa-sm'></i></div>
                                                                                    : null}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div className='justify-content-end px-3'>
                                                                        {/* <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertEmail(activeAlert)}>+</button> */}
                                                                        <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertAction(activeAlert)}>+</button>
                                                                    </div>
                                                                </div>
                                                            </CardHeader>
                                                            {alerts[activeAlert].action.length > 0 ?
                                                                <div className='row m-0 mt-4 pb-4'>
                                                                    <div className='col-6 mt-3'>
                                                                        {/* <Input name="emailName" label="Name*" defaultValue={alerts[activeAlert].emails[activeEmail].name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Name", status: !emailName }} /> */}
                                                                        <Select onClick dropDownHeight="100px" options={levelIdList} defaultValue={{ value: alerts[activeAlert].action[activeAction].levelId }} onChange={(e) => onChangeHandler(e)}
                                                                            name="actionlevelId" label="LevelId*" />
                                                                    </div>
                                                                    <div className='col-6 mt-3'>
                                                                        {/* <Input name="emailName" label="Name*" defaultValue={alerts[activeAlert].emails[activeEmail].name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Name", status: !emailName }} /> */}
                                                                        <Select onClick dropDownHeight="100px" options={actionDeviceId} defaultValue={{ value: alerts[activeAlert].action[activeAction].deviceId }} onChange={(e) => onChangeHandler(e)} name="actiondeviceId" label="Device*" />
                                                                    </div>
                                                                    <div className='col-6  mt-3'>
                                                                        {/* <Input name="emailValue" label="Email.*" defaultValue={alerts[activeAlert].emails[activeEmail].value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Valid Emailadd vlidations", status: !emailValue }} /> */}
                                                                        <Select onClick dropDownHeight="100px" options={deviceActionParamList} defaultValue={{ value: alerts[activeAlert].action[activeAction].parameter }} onChange={(e) => onChangeHandler(e)} name="actionparameter" label="Parameter.*" />
                                                                    </div>
                                                                    <div className='col-6  mt-3'>
                                                                        {/* <Input name="emailValue" label="Email.*" defaultValue={alerts[activeAlert].emails[activeEmail].value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Valid Emailadd vlidations", status: !emailValue }} /> */}
                                                                        <Input name="actionvalue" label="Value.*" type="number" defaultValue={alerts[activeAlert].action[activeAction].value} onChange={(e) => onChangeHandler(e)} />
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div> : null}
                                                    {/* Tab Email */}
                                                    {
                                                        tab === "API" ?
                                                            <div>
                                                                <CardHeader className='p-0'>
                                                                    <div className='row m-0 pb-4'  >
                                                                        <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }} >
                                                                            {alerts[activeAlert].apis.map((apisData, key) =>
                                                                                <div className={'tabButton d-flex ' + (key === activeApis ? "active" : "null")} style={{ minWidth: (key === activeApis) ? "130px" : "100px" }}>
                                                                                    <div className='inner-btn text-truncate' onClick={() => ChangeApis(alerts[activeAlert], key, "onButtonClick")} key={key}>{apisData.method}</div>
                                                                                    {alerts[activeAlert].apis.length > 0 ?
                                                                                        <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteApisAlert(key)}><i className='fas fa-trash fa-sm'></i></div>
                                                                                        : null}
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                        <div className='justify-content-end px-3'>
                                                                            <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertApis(activeAlert)}>+</button>
                                                                        </div>
                                                                    </div>
                                                                </CardHeader>
                                                                {alerts[activeAlert].apis.length > 0 ?
                                                                    <div className='row m-0 mt-4 pb-4'>
                                                                        <div className='col-12 '>
                                                                            <Input name="headers" label="Headers.*" defaultValue={alerts[activeAlert].apis[activeApis].headers} onChange={(e) => onChangeHandler(e)} />
                                                                        </div>
                                                                        <div className='col-6 mt-3'>
                                                                            <Select name="method" onClick dropDownHeight="100px" label="Method*" options={ApiMethod} defaultValue={{ value: alerts[activeAlert].apis[activeApis].method }} onChange={(e) => onChangeHandler(e)} />
                                                                        </div>
                                                                        <div className='col-6 mt-3'>
                                                                            <Input name="url" label="Url.*" defaultValue={alerts[activeAlert].apis[activeApis].url} onChange={(e) => onChangeHandler(e)} />
                                                                        </div>
                                                                        <div className='col-12 mt-3'>
                                                                            <Input name="body" label="Body.*" defaultValue={alerts[activeAlert].apis[activeApis].body} onChange={(e) => onChangeHandler(e)} />
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                            : null}
                                                </CardBody>
                                            </InnerCard>
                                        </div>
                                        {!cardLoader ?
                                            <ButtonContainer className="mt-5">
                                                <Button buttonResult={() => SaveAlerts()} >{alertId ? "Update" : "Create"}</Button>
                                            </ButtonContainer>
                                            : <CardLoader />}
                                    </div> : "No Alerts"}
                        </CardBody>
                    </CardBody>
                </Card>
                : <CardLoader />}
            {
                popup ? <Mypopup title={popup[0]} type={popup[2]} onClose={(e) => {
                    setPopup(null);

                }}>
                    <p className="p-3"> {popup[1]}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setPopup(null); getAlert()
                        }}>Ok</button>
                    </div>
                </Mypopup >
                    : null
            }
        </div>
    )
}
const mapStateToProps = (state) => ({
    organization: state.organization,
    configration: state.configration,
    userInfo: state.userInfo


});
export default connect(mapStateToProps, null)(SetAlert)

