import React, { useEffect, useState } from 'react'
import Input from '../../input/input'
import { Button, ButtonContainer } from '../../arraybutton/arraybutton'
import Select from '../../select/select'

export default function BorderStyle(props){

    const [borderStyle, setBorderStyle] = useState({
        borderStyle: "solid",
        borderWidth: 3,
        borderColor: "#0471c4",
        borderRadius: 10,
    })
    const onChangeHandler = (e, type) => {
        let data = { ...borderStyle }
        data[e.name] = e.target.value
        setBorderStyle(data)
        if ("onChange" in props) {
            props.onChange({ name: "borderStyle", target: { value: data } })
        }
    }

    useEffect(() => {
        if (props?.defaultValue?.borderStyle) {
            setBorderStyle(props.defaultValue.borderStyle)
        }
    }, [props.defaultValue])
    return (
        <>
            <div className='propertiesLabels'>Border Style</div>
            <Input
                className="mb-3"
                name="borderWidth"
                label="Width"
                type="number"
                defaultValue={borderStyle?.borderWidth?parseInt(borderStyle?.borderWidth):""}
                onChange={(e) => onChangeHandler(e, "borderWidth")}
            />
            <Input
                className="mb-3"
                name="borderColor"
                label="Color"
                type="color"
                defaultValue={borderStyle?.borderColor}
                onChange={(e) => onChangeHandler(e, "borderColor")}
            />
            <Select
                className="mb-3"
                dropDownHeight="100px"
                name="borderStyle"
                label="Style"
                options={[
                    {label:'None', value: 'none'},
                    {label:'Solid', value: 'solid'},
                    {label:'Dotted', value: 'dotted'},
                    {label:'Dashed', value: 'dashed'},
                    {label:'Double', value: 'double'},
                    {label:'Groove', value: 'groove'},
                    {label:'Ridge', value: 'ridge'},
                    {label:'Inset', value: 'inset'},
                    {label:'Outset', value: 'outset'},
                ]}
                defaultValue={{value: borderStyle?.borderStyle}}
                onChange={(e) => onChangeHandler(e, "borderStyle")}
                onClick
                sort={false}
            />
            <Input
                className="mb-3"
                name="borderRadius"
                label={"Rounded"}
                type="range"
                defaultValue={borderStyle?.borderRadius?parseInt(borderStyle?.borderRadius):""}
                onChange={(e) => onChangeHandler(e, "borderRadius")}
            />
        </>
    )
}