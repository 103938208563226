import React, { useEffect, useState } from 'react'
//import Local Resources
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton';
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card';
import './departForm.scss'
import Input from '../../components/input/input';
import { organizationCreated } from '../../services/utilities/events';
import { getHub, getEventsByLevel } from '../../services/utilities/events'
import InlineLoader from '../loader/inlineLoader';
import Mypopup from '../popup/mypopup';
import { WhiteSpace, SpecialCharacters } from '../../services/config/regex';
import AddHubForm from '../addHubForm/addHubForm';
import HubComponent from '../hubComponent/hubComponent';
import FullLoader from '../loader/fullPageLoader';
import { orgAction } from '../../services/actions/orgAction';
import { connect } from 'react-redux';
import HubInfoPopup from '../hubInfoPopup/HubInfoPopup';
import AccessValidation from '../accessValidation/accessValidation';
import Analyzers from '../../containers/customDashboard/analyzers';


const LevelForm = (props) => {
    let organizationId = props.data
    //All States
    const [state, setState] = useState({
        loginData: {},
        modal: false,
        redirect: false,
        loginLoader: false,
    })
    const [popup, setPopup] = useState(false)
    const [access, setAccess] = useState(null)
    const [levelManagement, setLevelManagement] = useState(false)
    const [addHubpopup, setAddHubPopup] = useState(false)
    const [departFormPopup, setDepartFormPopup] = useState(false)
    //Single Depart Object State
    const [depart, setDepart] = useState({
        locationName: "",
        // noOfLevels: "",
        contactNumber: "",
        contactName: "",
        levelId: "",
        type: "level"
    })
    //check Access 
    useEffect(() => {
        if (access) {
            if (access?.length) {
                if (access.includes("1007") || access.includes("admin")) {
                    setLevelManagement(true)
                }
            } else {
                setLevelManagement(false)
            }
        }
    }, [access])
    const accessHandler = (e) => {
        setAccess(e)
    }
    //multiple Departs in departs State
    const [departs, setDeparts] = useState([])
    const [departName, setDepartName] = useState(true)
    // const [noOfLevels, setNoOfLevels] = useState(true)
    const [contactNumber, setContactNumber] = useState(true)
    const [contactName, setContactName] = useState(true)
    const [activeDepart, setActiveDepart] = useState(0)
    const [loader, setLoader] = useState(false)
    const [hub, setHub] = useState(false)
    const [res1, setRes1] = useState([])
    const [addHubCreatePopup, setAddHubCreatePopup] = useState(null)
    const [hubInfo, setHubInfo] = useState(false)
    const [result, setResult] = useState({})


    let org = props.data
    let orgId = org._id
    let Idlength = orgId.split("_").length
    let orgName = orgId.split("_")[0]
    let mainOrganizationID = window.location.hash.split("/")
    mainOrganizationID = mainOrganizationID[mainOrganizationID.length - 1]
    useEffect(async () => {
        setLoader(true)
        let events1 = props.lastEvent.lastEvent
        // let res = await getHub(orgId)
        let res = props?.organization?.organization?.allhubs
        let res1 = []
        if (res) {
            if (events1) {
                res.map((item, key) => {
                    if (item.levelId === orgId) {
                        res1.push(item)
                        setHub(true)
                        events1?.devices?.map((hubEvent, key) => {
                            if (!("events" in item)) {
                                let hubId = hubEvent._id.split("_")
                                hubId = hubId[0] + "_" + hubId[1]
                                if (item._id === hubId) {
                                    hubEvent.events.map(Events => {
                                        if (Events.deviceId === item._id) {
                                            item["events"] = Events
                                        }
                                    })
                                }
                            }
                        })
                    }
                    item["hover"] = false
                })
            }
            setRes1(res1)
        }
        setLoader(false)

    }, [props.data])
    // In this useEffect set the depart to departs
    // useEffect(() => {
    //     let departs1 = []
    //     // if (organization.noOfLevels === "1") {
    //     setDepart({
    //         locationName: "",
    //         // noOfLevels: "",
    //         contactNumber: "",
    //         contactName: "",
    //         levelId: "",
    //         type: "level"
    //     })
    //     setDeparts(departs1);
    // }, [])
    // This function is called when all validations is to be true

    //All inputs Change Handlers
    const popupOnchangeHandler = (e) => {

        setResult(e)
        if (!e.statusCode) {
            setAddHubPopup(null)
            setAddHubCreatePopup(["Success!", "Hub created successfully.", "success"])
        }
        else {
            setAddHubCreatePopup(["Failure!", "Hub already exist.", "danger"])
        }
        // {

    }
    // Create Depart 
    return (
        <div className='m-3'>
            <AccessValidation levelId={organizationId._id} onAccessHandler={(e) => accessHandler(e)} />
            {!loader ?
                <>
                    {props?.data?._id === "LinkedThings_EnergyMeters" ?
                        <Analyzers org={organizationId} /> : null}
                    <HubComponent res1={res1} org={organizationId} />
                </>
                : <FullLoader />}
            {
                addHubpopup ? <Mypopup title={"Add Hub"}
                    onClose={() => { setAddHubPopup(null) }}>
                    <AddHubForm org={organizationId} onChange={(e) => popupOnchangeHandler(e)}
                    />

                </Mypopup> : null}
            {addHubCreatePopup ? <Mypopup title={addHubCreatePopup[0]} type={addHubCreatePopup[2]} onClose={(e) => {
                setAddHubCreatePopup(null);
            }}>
                <p className="p-3" > {addHubCreatePopup[1]}</p>
                <div className="d-flex justify-content-center p-3">
                    {addHubCreatePopup[2] === "danger" ?
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setAddHubCreatePopup(null);
                        }}>Ok</button>
                        :
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setAddHubCreatePopup(null); setHubInfo(true)
                        }}>Ok</button>}
                </div>
            </Mypopup >
                : null}
            {hubInfo ?
                <HubInfoPopup res={result} onClose={() => window.location.reload()} />
                : null}
        </div>
    )
}
const mapStateToProps = (state) => ({
    userInfo: state.userInfo,
    configration: state.configration,
    organization: state.organization,
    lastEvent: state.lastEvent
});
export default connect(mapStateToProps, null)(LevelForm)
