import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card'
import InlineLoader from '../../components/loader/inlineLoader'
import { overviewRequest, overviewDelete, overviewAdd, overviewUpdate } from '../../services/utilities/events'
import "./manageOverview.scss"
import Input from '../../components/input/input'
import LevelAssign from '../../components/properties/levelAssign/levelAssign'
import Active from '../../components/properties/active/active'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import { connect } from 'react-redux'

const ManageOverview = (props) => {
    let id = props.location.pathname.split("/")[2]
    const [overviewList, setOverviewList] = useState([])
    const { organization } = useParams()
    let organizationId = organization.split("_")[0]
    const [loader, setLoader] = useState(false)
    const history = useHistory()
    const [selectedOverview, setSelectedOverview] = useState(null)
    useEffect(async () => {
        let filterOverview = []
        let branches = false

        setLoader(true)
        let overview = await overviewRequest(organizationId)

        if (overview) {
            overview.map(view => {
                if (props.userInfo.varification?.hierarchy.length) {
                    props.userInfo.varification?.hierarchy.map(levels => {
                        if (id.match(levels._id)) {
                            if (levels.branches.length > 0) {
                                branches = true
                                levels.branches.map(branch => {
                                    if (view.levelId.match(branch.levelId)) {
                                        filterOverview.push(view)
                                    }
                                })
                            }
                        }
                    })
                }
                if (!branches) {
                    filterOverview.push(view)
                }
            })
            setOverviewList(filterOverview)
        }
        setLoader(false)
    }, [])

    const CreateOverview = () => {
        history.push('/manage-dashboard/add/' + organization)
    }
    const EditOverview = (id) => {
        history.push('/manage-dashboard/edit/' + organization + `?id=` + id)
    }
    const deleteOverviewFunc = async (id, index) => {
        let response = await overviewDelete(id)
        if (response) {
            let overviewList1 = [...overviewList]
            overviewList1.splice(index, 1)
            setOverviewList(overviewList1)
        }
    }
    const duplicateOverview = async (overview, key) => {
        setLoader(true)
        let newOverview = {
            data: overview.data,
            levelId: overview.levelId,
            name: overview.name + " (" + overviewList.length + ")",
            thumbnail: overview.thumbnail
        }
        let response = await overviewAdd(newOverview)

        let overviewList1 = [...overviewList]
        overviewList1.push(response)
        setOverviewList(overviewList1)
        setLoader(false)
    }
    const onChangeHandler = (e) => {
        let name = e.name,
            value = e.target.value,
            overview = { ...selectedOverview }
        if (name === 'name') {
            overview.overview.name = value
        } else {
            let settings1 = { ...overview.overview['settings'] }
            settings1[name] = value
            overview.overview['settings'] = settings1
        }
        setSelectedOverview(overview)
    }
    const updateOverview = async () => {
        let overviewList1 = [...overviewList],
            selectedOv = { ...selectedOverview },
            overview = selectedOv.overview
        selectedOv.loader = true
        setSelectedOverview(selectedOv)

        overviewList1[selectedOverview.key] = { ...overview }
        let updateObject = {
            name: overview.name,
            settings: overview.settings,
        }
        let response = await overviewUpdate(overview._id, updateObject)
        setOverviewList(overviewList1)
        setTimeout(() => {
            setSelectedOverview(null)
        }, 1000)
    }
    return (
        <div className='row m-0 p-3'>
            <Card className="w-100 mb-5">
                <CardHeader>
                    <Heading>
                        Manage Dashboard
                    </Heading>
                </CardHeader>
            </Card>
            <div className='col p-0'>
                <div className='row'>
                    <div className='overviewListContainer col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
                        <Card className="mb-3 bg-white">
                            <CardBody>
                                <div className="tabs" onClick={() => { CreateOverview() }}>
                                    <i className='fa fa-plus content'></i>
                                </div>
                            </CardBody>
                        </Card>
                        <h5 className='text-center'>Add New Dashboard</h5>
                    </div>
                    {overviewList.length ?
                        overviewList.map((overview, key) =>
                            <div className={'overviewListContainer col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'} key={key} style={{ animationDelay: ((key + 1) * 0.1) + "s" }} >
                                <Card className={"overviewList mb-3 bg-white"}>
                                    <span className='ribbon'>
                                        <i className='fa fa-cog' title="Settings" onClick={() => setSelectedOverview({ overview: { ...overview }, key, loader: false })}></i>
                                        <i className='far fa-clone' title="Duplicate" onClick={() => duplicateOverview(overview, key)}></i>
                                        <i className='fa fa-trash-alt' title="Delete" onClick={() => deleteOverviewFunc(overview._id, key)}></i>
                                    </span>
                                    <CardBody>
                                        <div className="tabs" onClick={() => EditOverview(overview._id)} >
                                            <div className='content w-100 rounded-4 p-1' style={{ fontSize: "30px" }}>
                                                {overview.thumbnail ?
                                                    <img className='w-100 h-100 rounded-4' src={overview?.thumbnail ? overview?.thumbnail : null} />
                                                    : "Thumbnail"}
                                            </div>
                                            <span className={'status ' + (
                                                overview?.settings && overview?.settings.levelAssign ?
                                                    overview?.settings.live ?
                                                        'bg-success'
                                                        : 'bg-warning'
                                                    : 'bg-secondary'
                                            )}>
                                                {overview?.settings && overview?.settings.levelAssign ?
                                                    overview?.settings.live ?
                                                        <><i className="fa fa-circle" style={{ fontSize: 10 }} ></i> active</>
                                                        : <><i className="fa fa-circle" style={{ fontSize: 10 }} ></i> deactive</>
                                                    : 'unassigned'}

                                            </span>
                                        </div>
                                    </CardBody>
                                </Card>
                                <h5 className='text-center'>{overview.name}</h5>
                            </div>
                        )
                        : null}
                    {loader ?
                        <div className='overviewListContainer col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6' style={{ animationDelay: 0.1 }}>
                            <Card className="basicComponent mb-3">
                                <CardBody>
                                    <div className="tabs">
                                        <div className='content h-100 rounded-4'>
                                            <InlineLoader width="100%" />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div> : null}
                </div>
            </div>
            {selectedOverview &&
                <Card className="overflow-hidden position-sticky ml-4" style={{ width: "350px", top: '120px', height: 'fit-content' }}>
                    <CardHeader className="d-flex align-items-center justify-content-between">
                        <Heading size={2}>
                            Settings
                        </Heading>
                        {selectedOverview.loader ?
                            <InlineLoader width={"41px"} />
                            : <ButtonContainer>
                                <Button buttonResult={() => updateOverview()}>Save</Button>
                                <Button active buttonResult={() => setSelectedOverview(null)}><i className='fa fa-times text-danger'></i></Button>
                            </ButtonContainer>
                        }
                    </CardHeader>
                    <CardBody className='p-3 fade-in-right-onload overflow-auto' style={{ height: "calc(100vh - 310px)" }}>
                        <Input className='mb-3' name='name' label='Name' onChange={(e) => onChangeHandler(e)} defaultValue={selectedOverview.overview?.name} />
                        <LevelAssign onChange={(e) => onChangeHandler(e)} defaultValue={selectedOverview.overview?.settings ? selectedOverview.overview?.settings?.levelAssign : ''} />
                        <Active onChange={(e) => onChangeHandler(e)} defaultValue={selectedOverview.overview?.settings ? selectedOverview.overview?.settings?.live : false} />
                    </CardBody>
                </Card>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo
});
export default connect(mapStateToProps, null)(ManageOverview)
