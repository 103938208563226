import { set } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CardHeader, Heading } from '../../card/card'
import GraphCard from '../../graphCard/graphCard'
import GraphIMG from '../../../assets/componentThumbnails/StepGraph.png'

const StepGraphOverview = (props) => {
    const [events, setEvents] = useState({
        Status: null,
        Connection: null
    })

    const [device, setDevice] = useState(null)
    const [param, setParam] = useState("")
    const [parameter, setParameter] = useState(null)
    useEffect(() => {
        let deviceId = props?.config?.deviceId
        let param1 = props?.config?.param
        if (deviceId) {
            props?.organization?.organization?.alldevices.map(device => {
                if (deviceId === device._id) {
                    device["events"] = events
                    device["parameter"][0]["alias"] = []
                    device.parameter.map(params => {
                        if (params.value === param1) {
                            setParameter(params)
                        }
                    })
                    setDevice(device)
                }
            })
        }
        setParam(param1)
    }, [props])

    useEffect(async () => {
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")
        if (hubId) {
            hubId = hubId[0] + "_" + hubId[1]
        }
        let Events = props?.eventReducer?.deviceEvents
        if (Events.deviceId === props?.config?.deviceId) {
            events1.Status = Events
            setEvents(events1)
        } else if (Events.deviceId === hubId) {
            events1.Connection = Events
            setEvents(events1)
        }
    }, [props.eventReducer.deviceEvents])

    return (
        device && param ?
            <>
                <CardHeader>
                    <Heading>{props?.config?.label}</Heading>
                </CardHeader>
                <GraphCard events={events} device={device} config={{ parameter }} headerDisable={true} type="binary" />
            </>
            :
            <div className='position-relative'>
                <img src={GraphIMG} style={{ width: "100%", filter: "brightness(0.3)" }} />
                <h4 className='text-center position-absolute text-light' style={{ top: "50%", left: '50%', transform: "translate(-50%,-50%)" }}>Select your device first to Live Preview</h4>
            </div>

    )
}


const mapStateToProps = (state) => ({
    organization: state.organization,
    eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(StepGraphOverview)
