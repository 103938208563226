import axios from "axios";
import { dataHandler } from "../../functions/functions";
// import axiosEvent from './axiosEvent'
import {
  hierarchyUrl,
  hierarchySubUrl,
  organizationCreate,
  getHubUrl,
  getHubSubUrl,
  organizationCreatedUrlSingle,
  getHubsTypeUrl,
  HubCreatedUrl,
  deviceCreateBulk,
  getDeviceUrl,
  getDeviceSubUrl,
  getLastSubEventsUrl,
  getLastEventsUrl,
  getImgUrl,
  getLastEventsByDeviceIdURL,
  getLastSubEventDeviceIdURL,
  getAnylaticsByDeviceIdURL,
  getSubAnylaticsByDeviceIdURL,
  getStatusAnylaticsByDeviceIdURL,
  getSubStatusAnylaticsByDeviceIdURL,
  getPostAlertURL,
  getAlertUrl,
  getSubAlertUrl,
  getUpdateAlertUrl,
  hierarchy2Url,
  hierarchy2SubUrl,
  overviewById,
  overviewCRUD,
  getDeviceByDeviceIdURL,
  imageURL, hubInfoFiles, getRoles, createUser, getUser, User, VerifyUser, functionsArray, updateDevice, create_getRules, get_rules_firstHalf, get_rules_secondHalf, getMultiDeviceHistoryURL, getMultiDeviceConsumptionURL, summary, summary1
} from "../config/serverAddresses";
import { deleteRequest, getRequest, postRequest, putRequest } from "./axiosRequests";
import cookies from "./cookies";
import { acKnowledge } from "../config/serverAddresses";
export let getMultiDeviceHistory = async (id, limit, startDate = null, endDate = null) => {
  let Path = getMultiDeviceHistoryURL + "?devices=" + id + "&"
  // + "&type=" + type?.split(",")[0]
  if (startDate && endDate) {
    Path += "&startDate=" + startDate + "&endDate=" + endDate + "&"
  }
  Path += "limit=" + limit
  try {
    let response = await getRequest(Path)
    let data = await response.data
    data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getMultiDeviceConsumption = async (id, type, filter, limit, startDate = null, endDate = null) => {
  let Path = getMultiDeviceConsumptionURL("multidevice") + "?devices=" + id + "&type=" + type?.split(",")[0]
  if (startDate && endDate) {
    Path += "&startDate=" + startDate + "&endDate=" + endDate + "&"
  }
  Path += "limit=" + limit + "&filter=" + filter

  try {
    let response = await getRequest(Path)
    let data = await response.data
    data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let DeviceLastEvents = async (id, limit = null, startDate = null, endDate = null) => {
  let Path = getLastEventsByDeviceIdURL + id + getLastSubEventDeviceIdURL + "?"
  if (startDate && endDate) {
    Path += "startDate=" + startDate + "&endDate=" + endDate + "&"
  }
  if (limit) {
    Path += "limit=" + limit
  }
  try {
    let response = await getRequest(Path)
    let data = await response.data
    data = dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    // let response = dataHandler(response.data)
    // return response
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let getDeviceAnylatics = async (id, startDate, endDate, filter, parameter) => {
  let Path = getAnylaticsByDeviceIdURL + id + getSubAnylaticsByDeviceIdURL + "?startDate=" + startDate + "&endDate=" + endDate + "&filter=" + filter + "&value=" + parameter
  try {
    let response = await getRequest(Path)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let getFunctions = async () => {
  try {
    let response = await getRequest(functionsArray);
    let data = await dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let getStatusDeviceAnylatics = async (id, startDate, endDate, filter, parameter) => {
  let Path = getStatusAnylaticsByDeviceIdURL + id + getSubStatusAnylaticsByDeviceIdURL + "?startDate=" + startDate + "&endDate=" + endDate + "&filter=" + filter + "&parameter=" + parameter
  try {
    let response = await getRequest(Path)
    let data = dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let createRules = async (body) => {
  try {
    let response = await postRequest(create_getRules, body);
    let data = dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let updateRules = async (id, body) => {
  try {
    let response = await putRequest(create_getRules + "/" + id, body);
    let data = dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let createAlerts = async (body) => {
  try {
    let response = await postRequest(getPostAlertURL, body);
    let data = dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let allRulesGet = async (id) => {
  try {
    let response = await getRequest(get_rules_firstHalf + id + get_rules_secondHalf)
    let data = dataHandler(response.data)
    // if (data.status === true) {
    return data
    // }
    // else {
    // return response.data
    // }
  } catch (err) {
    return err.response.data
  }
}
export let ruleDelete = async (id) => {
  try {
    let response = await deleteRequest(create_getRules + "/" + id);
    let data = await response.data
    if (data.status === true) {
      return data;
    } else {
      return response.data
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let summaryO = async (id) => {
  try {
    let response = await getRequest(summary + id + summary1 + "/info");
    let data = await response.data
    if (data.status === true) {
      return data;
    } else {
      return dataHandler(response.data)
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let summaryOHistory = async (id) => {
  try {
    let response = await getRequest(summary + id + summary1);
    let data = await response.data
    if (data.status === true) {
      return data;
    } else {
      return dataHandler(response.data)
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let getAlerts = async (id) => {
  try {
    let response = await getRequest(getAlertUrl + id + getSubAlertUrl)
    let data = dataHandler(response.data)
    // if (data.status === true) {
    return data
    // }
    // else {
    // return response.data
    // }
  } catch (err) {
    return err.response.data
  }
}

export let updateAlert = async (id, body) => {
  try {
    let response = await putRequest(getUpdateAlertUrl + id, body)
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data
    // }
    // else {
    // return response.data
    // }
  } catch (err) {
    return err.response.data
  }
}
export let deviceDelete = async (id) => {
  try {
    let response = await deleteRequest(updateDevice + id);
    let data = await response.data
    if (data.status === true) {
      return data;
    } else {
      return response.data
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let organizationCreated = async (body) => {
  try {
    let response = await postRequest(organizationCreate, body);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let organizationCreatedSingle = async (body) => {
  try {
    let response = await postRequest(organizationCreatedUrlSingle, body);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let Hubtype = async () => {
  try {
    let response = await getRequest(getHubsTypeUrl);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let hubInfoFolder = async () => {
  try {
    let response = await getRequest(hubInfoFiles);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let hubCreated = async (body) => {
  try {
    let response = await postRequest(HubCreatedUrl, body);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let hubUpdate = async (body, id) => {
  try {
    let response = await putRequest(HubCreatedUrl + "/" + id, body);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let singleHubGet = async (id) => {

  try {
    let response = await getRequest(HubCreatedUrl + "/" + id);
    let data = dataHandler(response.data)
    return data
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }

}
export let hubDelete = async (id) => {
  try {
    let response = await deleteRequest(HubCreatedUrl + "/" + id);
    let data = await response.data
    if (data.status === true) {
      return data;
    } else {
      return response.data
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getNotification = async () => {
  // try {
  let response = await getRequest(acKnowledge)
  let data = dataHandler(response.data)
  return data
  // } catch (err) {

  // }
}
export let updateNotification = async (id, body) => {
  // try {
  let response = await putRequest(acKnowledge + "/" + id, body)
  let data = dataHandler(response.data)
  return data
  // } catch (err) {

  // }
}

export let overviewUpdate = async (id, body) => {
  try {
    let response = await putRequest(overviewCRUD + "/" + id, body)
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data
  }

}
export let overviewDelete = async (id) => {
  try {
    let response = await deleteRequest(overviewCRUD + "/" + id)
    let data = dataHandler(response.data)
    return data
  } catch (err) {
    err.response.data.error = true;
    return err.response.data
  }

}
export let overviewAdd = async (body) => {
  try {
    let response = await postRequest(overviewCRUD, body)
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data
  }

}

export let overviewRequest = async (id) => {
  try {
    let response = await getRequest(overviewById + id);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let overviewRequestById = async (id) => {
  try {
    let response = await getRequest(overviewCRUD + "/" + id);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let deviceCreatedBulk = async (body) => {
  try {
    let response = await postRequest(deviceCreateBulk, body);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let deviceUpdate = async (body, id) => {
  try {
    let response = await putRequest(updateDevice + id, body);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let CreateChildUser = async (body) => {
  try {
    let response = await postRequest(createUser, body)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let EditChildUser = async (body, id) => {
  try {
    let response = await putRequest(User + id, body)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let DeleteChildUser = async (id) => {
  try {
    let response = await deleteRequest(User + id)
    let data = dataHandler(response.data)
    return data
  } catch (err) {
    err.response.data.error = true;
    return err.response.data
  }
}

export let GetChildUser = async (levelId) => {
  try {
    let response = await getRequest(getUser);
    let data = await dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let CreateAdminRoles = async (body) => {
  try {
    let response = await postRequest(getRoles, body)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let EditAdminRoles = async (body, id) => {
  try {
    let response = await putRequest(getRoles + "/" + id, body)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let DeleteAdminRoles = async (id) => {
  try {
    let response = await deleteRequest(getRoles + "/" + id)
    let data = dataHandler(response.data)
    return data
  } catch (err) {
    err.response.data.error = true;
    return err.response.data
  }
}

export let getVerifyUserToken = async (token) => {
  try {
    let response = await getRequest(VerifyUser + token);
    let data = await dataHandler(response.data)
    return data
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let getOrganization = async (levelId) => {
  try {
    let response = await getRequest(hierarchyUrl + levelId + hierarchySubUrl);
    let data = await dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}


export let getAdminRoles = async () => {
  try {
    let response = await getRequest(getRoles)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}


export let postImage = async (fileData) => {
  try {
    const formData = new FormData();
    formData.append("file", fileData)
    let token = cookies.getCookie('token');
    let config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-Type': 'multipart/form-data'
      },
      // onUploadProgress: progressEvent => {
      //   const percentCompleted = Math.round(
      //     (progressEvent.loaded * 100) / progressEvent.total
      //   );
      // }
    }
    let response = await axios.post(imageURL, formData, config);
    let data = await response.data
    if (data.status === true) {
      return data;
    } else {
      return response.data
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getImage = async (id) => {
  try {
    // const cachedLogo = localStorage.getItem(id);
    // if (cachedLogo) {
    //   // setLogoData(cachedLogo);
    //   let data = JSON.parse(cachedLogo)
    //   const decodedData = atob(data.image);
    //   const blob = new Blob([decodedData], { type: "image/"+data.type });
    //   // let img = new Image()
    //   // img.src = data.image;
    //   // img.onload = () => {
    //   // } 
    //   // return "data:image/"+data.type+";base64"+data.image
    //   return URL.createObjectURL(blob)
    // } else {
    //   axios
    //     .get(imageURL + "/image/" + id, {
    //       responseType: "arraybuffer",
    //     })
    //     .then((response) => {
    //       const base64Data = Buffer.from(response.data, "binary").toString(
    //         "base64"
    //       );
    //       let extension = id.split(".").pop();
    //       // localStorage.setItem(id, JSON.stringify({type: extension, image: base64Data}));
    //       // const decodedData = atob(base64Data);
    //       // const blob = new Blob([decodedData], { type: "image/"+extension });
    //       // return URL.createObjectURL(blob)
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }

    // let img = new Image()
    // img.src = imageURL + "/image/" + id;
    // img.onload = () => {
    // } 
    // let url = imageURL + "/image/" + id
    // url = url.replaceAll(" ", "%20")
    // fetch(url)
    // .then(response => null)

    // let a = require(url)
    return imageURL + "/image/" + id
  } catch (err) {
    // err.response.data.error = true;
    // return err.response.data;
  }
}

export let getHierarchy = async (levelId) => {
  try {
    let response = await getRequest(hierarchy2Url + levelId + hierarchy2SubUrl);
    let data = await dataHandler(response.data)
    // if (response.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}


export let getDeviceById = async (id) => {
  try {
    let response = await getRequest(getDeviceByDeviceIdURL + id)
    let data = dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let getDevice = async (hubId) => {
  try {
    let response = await getRequest(getDeviceUrl + hubId + getDeviceSubUrl);
    let data = dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getHub = async (organizationName) => {
  try {
    let response = await getRequest(getHubUrl + organizationName + getHubSubUrl);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}


let oldImage = []
export let getResource = async (imgSrc) => {
  try {
    const oldFindImg = oldImage.find(imgData => imgData.url === imgSrc)
    if (oldImage.length && oldFindImg) {
      return oldFindImg.data
    } else {
      let response = await getRequest(getImgUrl + imgSrc);
      // let data = dataHandler(response.data)
      oldImage.push({ url: imgSrc, data: response.data })
      return response.data;
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getResource2 = async (imgSrc) => {
  try {
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    const oldFindImg = oldImage.find(imgData => imgData.url === imgSrc)
    if (oldImage.length && oldFindImg) {
      return oldFindImg.data
    } else {
      const response = await axios.get(getImgUrl + imgSrc
        , {
          responseType: 'arraybuffer',
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        }
      );
      const fileBlob = new Blob([response.data], { type: imgSrc.match('.svg') ? 'image/svg+xml' : 'application/javascript' });
      // response.headers['content-type']
      const downloadUrl = URL.createObjectURL(fileBlob);
      oldImage.push({ url: imgSrc, data: downloadUrl })
      return downloadUrl
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let getEventsByLevel = async (levelId) => {
  try {
    let response = await getRequest(getLastEventsUrl + levelId + getLastSubEventsUrl);
    response = dataHandler(response.data)
    return response;
  } catch (err) {
    // return {};
  }
};
