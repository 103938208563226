import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
//import Resources
import Select from '../select/select';
import Card, { CardBody, CardHeader } from '../card/card';
import { Button, ButtonContainer } from '../arraybutton/arraybutton';
import { idGenerator, todayDate, utcDate } from '../../functions/functions';
import ChartConfig from '../graph/chartConfig';
import { DeviceLastEvents, getDeviceAnylatics, getMultiDeviceHistory, getStatusDeviceAnylatics } from '../../services/utilities/events';

//import libraries
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';
import { param } from 'jquery';
import TableConfig from '../tableConfig/TableConfig';

const GraphCard = (props) => {
    const chartAddNode = useRef(0);
    const [anylaticsLimit, setAnylaticsLimit] = useState(0)
    const [filter, setFilter] = useState("daily")
    const [deviceParameter, setDeviceParameter] = useState(props.device.parameter)
    const [parameterList, setParameterList] = useState([])
    const [parameter, setParameter] = useState(props.device.parameter[0])
    const [graphLoader, setGraphLoader] = useState(true)
    const [startDate, setStartDate] = useState(todayDate('start'))
    const [endDate, setEndDate] = useState(todayDate('end'))
    const [live, setLive] = useState(1)
    const [response, setResponse] = useState(null)
    const [anylaticsResponse, setAnylaticsResponse] = useState(null)
    const [graphData, setGraphData] = useState([])
    const [lastUpdate, setLastUpdate] = useState('')
    const [lastUpdate2, setLastUpdate2] = useState('')
    const [table, setTable] = useState(false)

    // second parameter
    const [secondParameter, setSecondParameter] = useState(props?.device2?.parameter?.[0] || null)

    const [parameterConfig, setParameterConfig] = useState({
        id: props.device._id + idGenerator(),
        axisX: "date",
        axisY: "value",
        scrollbar: true,
        unit: parameter.unit,
        export: props?.export || false,
        series:
            [
                {
                    type: "type" in props ?
                        props.type
                        : parameter?.alias?.length ? "binary" : "line",
                    name: parameter.types,
                    // name: primaryLetters+" Intake",
                    color: "#008df2",
                    x: "Date",
                    y: parameter.types,
                },
                secondParameter ?
                    {
                        type: "type" in props ?
                            props.type
                            : secondParameter?.alias?.length ? "binary" : "line",
                        name: secondParameter.types + " 2",
                        // name: primaryLetters+" Intake",
                        color: "#0d3a5d",
                        x: "Date",
                        y: secondParameter.types + "2",
                    }
                    : null

            ]
    })

    const [anylatics, setAnalytics] = useState({
        id: props.device._id + idGenerator(),
        axisX: "date",
        axisY: "value",
        scrollbar: true,
        unit: parameter.unit,
        export: props?.export || false,
        tooltipGroup: secondParameter ? [
            {
                showOn: "Average",
                showList: ['Minimum', 'Average', 'Maximum', 'Date']
            },
            {
                showOn: "Average2",
                showList: ['Minimum2', 'Average2', 'Maximum2', 'Date']
            },
        ] : null,
        series:
            [
                {
                    type: "bar",
                    name: "Minimum",
                    color: "#00A110",
                    x: "Date",
                    y: "Minimum",
                },
                secondParameter ?
                    {
                        type: "bar",
                        name: "Minimum" + " 2",
                        color: "#00800d",
                        x: "Date",
                        y: "Minimum2",
                    } : null,
                {
                    type: "bar",
                    name: "Average",
                    color: "#FF9900",
                    x: "Date",
                    y: "Average",
                },
                secondParameter ?
                    {
                        type: "bar",
                        name: "Average" + " 2",
                        color: "#d98200",
                        x: "Date",
                        y: "Average2",
                    } : null,
                {
                    type: "bar",
                    name: "Maximum",
                    color: "#FA2D2D",
                    x: "Date",
                    y: "Maximum",
                },
                secondParameter ?
                    {
                        type: "bar",
                        name: "Maximum" + " 2",
                        color: "#b32020",
                        x: "Date",
                        y: "Maximum2",
                    } : null,
            ]
    })
    const [statusAnylatics, setStatusAnylatics] = useState({
        id: props.device._id + idGenerator(),
        axisX: "date",
        axisY: "value",
        scrollbar: true,
        unit: "hours",
        export: props?.export || false,
        series:
            [
                {
                    type: "bar",
                    name: "On Duration",
                    color: "#00A110",
                    x: "Date",
                    y: "On",
                },
                secondParameter ?
                    {
                        type: "bar",
                        name: "On Duration" + " 2",
                        color: "#00800d",
                        x: "Date",
                        y: "On2",
                    } : null,
                {
                    type: "bar",
                    name: "Off Duration",
                    color: "#FF9900",
                    x: "Date",
                    y: "Off",
                },
                secondParameter ?
                    {
                        type: "bar",
                        name: "Off Duration" + " 2",
                        color: "#cc7a00",
                        x: "Date",
                        y: "Off2",
                    } : null,
            ]
    })

    useLayoutEffect(() => {
        if ("config" in props) { console.log(props?.config); if ("param" in props?.config) { console.log(props?.config); setParameter(props.config.param) } }
    }, [])
    const handlerStartDate = (event) => {
        let startDate1 = moment(event._d).format('YYYY-MM-DDTHH:mm:ss')
        setStartDate(startDate1);
        if (live) {
            Anylatics(props.device._id, parameter.value, filter, parameter, startDate1, endDate)
        }
        else { Events(props.device._id, 200, startDate1, endDate) }
    }

    const handlerEndDate = (event) => {
        let endDate1 = moment(event._d).format('YYYY-MM-DDTHH:mm:ss')
        setEndDate(endDate1);
        if (live) {
            Anylatics(props.device._id, parameter.value, filter, parameter, startDate, endDate1)
        }
        else { Events(props.device._id, 200, startDate, endDate1) }
    }

    const Events = async (id, limit, startDate = null, endDate2 = null) => {
        let response = []
        setGraphLoader(true)
        let startDate1 = utcDate(startDate) + "Z"
        let endDate1 = utcDate(endDate) + "Z"
        if (endDate2) {
            endDate1 = utcDate(endDate2) + "Z"
        }
        if (secondParameter) {
            response = await getMultiDeviceHistory(id + "," + props.device2._id, limit, (limit === 200 ? startDate1 : null), (limit === 200 ? endDate1 : null))

        } else {
            response = await DeviceLastEvents(id, limit, (limit === 200 ? startDate1 : null), (limit === 200 ? endDate1 : null))
        }
        // response = []
        // parameter.types
        setResponse(response)
        setGraphLoader(false)
    }

    const Anylatics = async (id, parameterName, filter, parameterObj = null, startDate, endDate = null,) => {
        // {!parameter.alias.length ? 
        setGraphLoader(true)
        if (parameterObj) {
            if (!parameterObj?.alias?.length) {
                let startDate1 = utcDate(startDate) + "Z"
                let endDate1 = utcDate(endDate) + "Z"
                if (endDate) {
                    endDate1 = utcDate(endDate) + "Z"
                }
                let response = await getDeviceAnylatics(id, startDate1, endDate1, filter, parameterName)
                let secondResponse = []
                if (secondParameter) {
                    secondResponse = await getDeviceAnylatics(props.device2._id, startDate1, endDate1, filter, parameterName)

                }
                setAnylaticsResponse([response, secondResponse])
            } else {
                let startDate1 = utcDate(startDate) + "Z"
                let endDate1 = utcDate(endDate) + "Z"
                if (endDate) {
                    endDate1 = utcDate(endDate) + "Z"
                }
                let response = await getStatusDeviceAnylatics(id, startDate1, endDate1, filter, parameterName)
                let secondResponse = []
                if (secondParameter) {
                    secondResponse = await getStatusDeviceAnylatics(props.device2._id, startDate1, endDate1, filter, parameterName)

                }
                setAnylaticsResponse([response, secondResponse])
                // }
            }
        }
        setGraphLoader(false)
    }

    useEffect(() => {
        // console.log("first", graphData)
        if (live === 1
            // && graphData.length === 10
        ) {
            if (props.events && chartAddNode.current) {
                if (props.events.Status) {
                    let Status = props.events.Status
                    if (lastUpdate !== Status.created) {
                        if (secondParameter) {
                            let tempData = {
                                Date: new Date(Status.created),
                                [parameter.types]: Status[parameter.value],
                                [secondParameter.types + "2"]: null,
                            }
                            // console.log("first", tempData)
                            chartAddNode.current.check(tempData)
                        } else {
                            chartAddNode.current.check({ Date: new Date(Status.created), [parameter.types]: Status[parameter.value] })
                        }
                        setLastUpdate(Status.created)
                    }
                }
            }
        }
    }, [props.events])
    useEffect(() => {
        if (live === 1
            // && graphData.length === 10
        ) {
            if (props.device2 && chartAddNode.current) {
                if (props.device2.events) {
                    let Status = props.device2.events
                    // console.log("status", Status, props.device2.events)
                    if (lastUpdate2 !== Status.created) {
                        if (secondParameter) {
                            chartAddNode.current.check({
                                [parameter.types]: null,
                                [secondParameter.types + "2"]: Status[secondParameter.value],
                                Date: new Date(Status.created)
                            })
                        }
                        setLastUpdate2(Status.created)
                    }
                }
            }
        }
    }, [props?.device2?.events])

    useEffect(() => {
        Events(props.device._id, 10)
        let parameters = []
        deviceParameter.map(params => {
            parameters.push({ value: params.value, label: params.name ? params.name : params.types })
        })
        setParameterList(parameters)
        setGraphLoader(false)
    }, [])

    useEffect(() => {
        setGraphLoader(true)
        if (response) {
            filterData(parameter)
        }
        setTimeout(() => {
            setGraphLoader(false)
        }, 1000)
    }, [response])


    useEffect(() => {
        if (anylaticsResponse) {
            filterData(anylaticsResponse)
        }
    }, [anylaticsResponse])


    const filterData = (parameters) => {
        // setGraphLoader(true)
        let graphData1 = []
        if (live === 2) {
            if (anylaticsResponse.length) {
                if (secondParameter) {
                    graphData1 = anylaticsResponse.map((data, key) =>
                        data.map((row) => {
                            if ("min" in row) {
                                let newDate = moment(row.created)
                                newDate.set('hour', 0);
                                newDate.set('minute', 0);
                                newDate.set('second', 0);
                                newDate.set('millisecond', 0);
                                return ({
                                    Date: new Date(newDate),
                                    ["Minimum" + (key ? key + 1 : "")]: typeof row?.min === "number" ? parseFloat(row?.min.toFixed(2)) : row?.min,
                                    // "min2": key === 1 ? typeof row?.min === "number" ? parseFloat(row?.min.toFixed(2)) : row?.min : undefined,
                                    ["Maximum" + (key ? key + 1 : "")]: typeof row?.max === "number" ? parseFloat(row?.max.toFixed(2)) : row?.max,
                                    // "max2": key === 1 ? typeof row?.max === "number" ? parseFloat(row?.max.toFixed(2)) : row?.max : undefined,
                                    ["Average" + (key ? key + 1 : "")]: typeof row?.avg === "number" ? parseFloat(row?.avg.toFixed(2)) : row?.avg,
                                    // "avg2": key === 1 ? typeof row?.avg === "number" ? parseFloat(row?.avg.toFixed(2)) : row?.avg : undefined,
                                })
                            }
                        })
                    )
                } else {
                    anylaticsResponse[0].map(row => {
                        if ("min" in row) {
                            let updateUnit = { ...anylatics }
                            updateUnit.unit = parameter.unit
                            setAnalytics(updateUnit)
                            graphData1.push({ Minimum: parseFloat(row.min.toFixed(2)), Average: parseFloat(row.avg.toFixed(2)), Maximum: parseFloat(row.max.toFixed(2)), Date: new Date(row.created) })
                        } else {
                            graphData1.push({ On: row.onDuration, Off: row.offDuration, Date: new Date(row.created) })
                        }
                    })
                }
                // console.log(graphData1)
                if (!secondParameter) {
                    // console.log(graphData1)
                    // graphData1.reverse()
                } else {
                    graphData1 = graphData1.flat()
                    graphData1 = graphData1.sort((a, b) => a.Date - b.Date)
                }
            }
            // console.log(graphData1)
            let lastDate = null
            for (const [key, newData] of graphData1.entries()) {
                // console.log(key + "in", newData?.Date?.toString(), lastDate?.getTime())
                if ((newData?.Date?.getTime()) === (lastDate?.getTime())) {
                    graphData1[key - 1] = Object.assign({}, graphData1[key - 1], newData);
                    graphData1[key] = undefined
                } else {
                    graphData1[key] = newData
                }
                lastDate = newData.Date
            }
            // console.log(graphData1)
            graphData1 = graphData1.filter(data => data !== undefined)
        }
        else {
            if (response.length) {
                // console.log("check")
                if (secondParameter) {
                    graphData1 = response.map((data, key) =>
                        data.map((row) => {
                            if (parameters.value in row) {
                                return ({
                                    [parameters.types]: key === 0 ? typeof row[parameters.value] === "number" ? parseFloat(row[parameters.value].toFixed(2)) : row[parameters.value] : null,
                                    [secondParameter.types + "2"]: key === 1 ? typeof row[secondParameter.value] === "number" ? parseFloat(row[secondParameter.value].toFixed(2)) : row[secondParameter.value] : null,
                                    Date: new Date(row.created)
                                })
                            }
                        })
                        // parameter.types
                    )
                    graphData1 = graphData1.flat()
                    graphData1 = graphData1.sort((a, b) => a.Date - b.Date)
                    // console.log(graphData1)
                } else {
                    response.map(row => {
                        if (parameters.value in row) {
                            graphData1.push({
                                [parameters.types]: typeof row[parameters.value] === "number" ? parseFloat(row[parameters.value].toFixed(2)) : row[parameters.value],
                                Date: new Date(row.created)
                            })
                        }
                        // parameter.types
                    })
                }
            }
        }
        if (!secondParameter) {
            graphData1.reverse()
        }
        setGraphData(graphData1)
        if (graphData1.length) {
            let string = graphData1[0]
            if (!secondParameter) {
                const values = Object.values(string);
                if (values.length > 0 && typeof values[0] === "string") {
                    setTable(true)
                } else {
                    setTable(false)
                }
            }

        }
        // setTimeout(() => {
        //     setGraphLoader(false)
        // }, 100)
    }

    const filterAnylaticsFunc = (e) => {
        // setGraphLoader(true)
        Anylatics(props.device._id, parameter.value, e, parameter, startDate, endDate)
        setFilter(e)
        // setGraphLoader(false)
    }

    const GraphUpdate = (parameter) => {
        let parameterObj = {}
        let parameterConfig1 = parameterConfig
        deviceParameter.map((params) => {
            if (params.value === parameter) {
                parameterObj = params
                parameterConfig1.unit = params.unit
                parameterConfig1.series =
                    [
                        {
                            type: parameterObj?.alias?.length ? "binary" : "line",
                            name: params.types,
                            // name: primaryLetters+" Intake",
                            color: "#008df2",
                            x: "Date",
                            y: params.types,
                        },
                        secondParameter ?
                            {
                                type: "type" in props ?
                                    props.type
                                    : params?.alias?.length ? "binary" : "line",
                                name: params.types + " 2",
                                // name: primaryLetters+" Intake",
                                color: "#0d3a5d",
                                x: "Date",
                                y: params.types + "2",
                            }
                            : null

                    ]
            }
        })
        setParameterConfig(parameterConfig1)
        if (secondParameter) {
            setSecondParameter(parameterObj)
        }
        setParameter(parameterObj)
        filterData(parameterObj)
    }
    const onChangeParam = ((e) => {
        setGraphLoader(true)
        setLive(1);
        Events(props.device._id, 10);
        GraphUpdate(e.target.value)

        if (live === 2) {
            let parameterObj = {}
            deviceParameter.map((params) => {
                if (params.value === e.target.value) {
                    parameterObj = params
                }
            })
            Anylatics(props.device._id, e.target.value, filter, parameterObj, startDate, endDate)
            filterData(parameterObj)
            if (secondParameter) {
                setSecondParameter(parameterObj)
            }
            setParameter(parameterObj)
        } else {
            let parameterObj = {}
            let parameterConfig1 = parameterConfig
            GraphUpdate(e.target.value)
        }
        setTimeout(() => {
            setGraphLoader(false)
        }, 1000)
    })

    return (
        <Card className="w-100">
            {props?.headerDisable ? null :
                <CardHeader>
                    <div className='row mx-md-0 mt-3 justify-content-between'>
                        <div className='col-lg-2 col-6 '>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    style={{ fontSize: 16 }}
                                    inputVariant="outlined"
                                    disableFuture="true"
                                    label="Start Date"
                                    disabled={live === 1 ? true : false}
                                    // className="mr-3"
                                    value={startDate}
                                    onChange={(event) => handlerStartDate(event)}
                                // format='YYYY-MM-DDTHH:mm:ssZ'
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className='col-lg-2 col-6'>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    style={{ fontSize: 16 }}
                                    inputVariant="outlined"
                                    disableFuture="true"
                                    label="End Date"
                                    disabled={live === 1 ? true : false}
                                    value={endDate}
                                    onChange={(event) => handlerEndDate(event)}
                                // format='YYYY-MM-DDTHH:mm:ssZ'
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className='col-lg-4 col-12 mt-lg-0 mt-3'>
                            <ButtonContainer>
                                <Button buttonResult={(e) => { setLive(1); Events(props.device._id, 10); GraphUpdate(parameter.value) }} active={live === 1 ? true : false}>
                                    Live
                                </Button>
                                <Button buttonResult={(e) => { setLive(0); Events(props.device._id, 200, startDate, todayDate("end")); GraphUpdate(parameter.value); setEndDate(todayDate("end")) }} active={live === 0 ? true : false}>
                                    History
                                </Button>
                                {!table ?
                                    <Button buttonResult={(e) => { setLive(2); Anylatics(props.device._id, parameter.value, filter, parameter, startDate, todayDate("end")); setEndDate(todayDate("end")) }} active={live === 2 ? true : false}>
                                        Analytics
                                    </Button> : null}
                            </ButtonContainer>
                        </div>
                        <div className='col-lg-4 px-3  mt-lg-0 mt-3'>
                            {parameterList.length ?
                                <Select dropDownHeight="100px" name="parameter" label="Parameter*" onClick defaultValue={{ value: parameter.value }} options={parameterList} onChange={(e) => onChangeParam(e)} />
                                : null}
                        </div>
                    </div>
                    {live === 2 ?
                        <div className='d-md-flex px-md-3 mt-3' style={{ justifyContent: "right" }}>
                            <ButtonContainer>
                                <Button value={"daily"} buttonResult={(e) => { setAnylaticsLimit(0); filterAnylaticsFunc(e) }} active={anylaticsLimit === 0 ? true : false}>
                                    Daily
                                </Button>
                                <Button value={"weekly"} buttonResult={(e) => { setAnylaticsLimit(1); filterAnylaticsFunc(e) }} active={anylaticsLimit === 1 ? true : false}>
                                    Weekly
                                </Button>
                                <Button value={"monthly"} buttonResult={(e) => { setAnylaticsLimit(2); filterAnylaticsFunc(e) }} active={anylaticsLimit === 2 ? true : false}>
                                    Monthly
                                </Button>
                            </ButtonContainer>
                        </div> : null}
                </CardHeader>
            }
            <CardBody loaderHeight={368} loader={graphLoader}>
                {table ?
                    <TableConfig chart={live === 2 ? parameter.alias.length ? statusAnylatics : anylatics : parameterConfig}
                        data={graphData}
                        ref={chartAddNode} /> :
                    <ChartConfig
                        chart={live === 2 ? parameter?.alias?.length ? statusAnylatics : anylatics : parameterConfig}
                        data={graphData}
                        ref={chartAddNode}
                    />
                }
            </CardBody>
        </Card>
    )
}

export default GraphCard