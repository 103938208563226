import React, { useEffect, useState } from 'react'
//import Resource
import { ButtonContainer, Button } from '../arraybutton/arraybutton';
import './addDeviceForm.scss'
import Input from '../input/input';
import { deviceCreatedBulk, deviceUpdate } from '../../services/utilities/events';
import InlineLoader from '../loader/inlineLoader';
import Mypopup from '../popup/mypopup';
import Select from '../select/select';
import CardLoader from '../loader/cardLoader';
//import libraries
import { connect } from 'react-redux';
import { orgAction } from '../../services/actions/orgAction';
import { getLevelIds } from '../../functions/functions';

const AddDeviceForm = (props) => {
    let hubId = props.data ? props.data.hubId : props?.hubId
    let levelId = props.data ? props.data.levelId : props?.levelId
    //when device create in hub 
    let orgId = props?.levelId?.split("_")[0]
    //when public create 
    let orgId1 = props?.org
    let configration = props.configration.configration.configuration
    //All States
    // This States Loaders and redirection
    const [state, setState] = useState({
        loginData: {},
        modal: false,
        redirect: false,
        loginLoader: false,
    })
    //<!---All State --> 
    const [popup, setPopup] = useState(false)
    const [list, setList] = useState([])
    const [device, setDevice] = useState(
        props.data ? props.data :
            {
                type: "",
                name: "",
                parameter: [{ types: "", value: "", unit: "" }],
                hubId: hubId ? hubId : "",
                levelId: levelId ? levelId : "",
                _id: ""
            }
    )
    const [unit, setUnit] = useState(true)
    const [loader, setLoader] = useState(true)
    const [cardLoader, setCardLoader] = useState(true)
    const [name, setName] = useState(true)
    const [type, setType] = useState(true)
    const [selectLevelId, setSelectLevelId] = useState(true)
    const [selectHubId, setSelectHubId] = useState(true)
    const [paramType, setParamType] = useState(true)
    const [paramValue, setParamValue] = useState(true)
    const [paramUnit, setParamUnit] = useState(true)
    const [paramTypeList, setParamTypeList] = useState([])
    const [hubIdList, setHubIdList] = useState([])
    const [suggestName, setSuggestName] = useState([])
    const [devices, setDevices] = useState([])
    const [levelIdsList, setLevelIdsList] = useState([])
    //<!----Active Device and parameter-->
    const [activeDevice, setActiveDevice] = useState(0)
    const [activeParam, setActiveParam] = useState(0)
    //<!----Active Device and parameter-->

    const [deviceTypes, setDeviceTypes] = useState([])
    const [deviceUnit, setDeviceUnit] = useState(false)
    const [units, setUnits] = useState([])
    const [lengthDevice, setLengthDevice] = useState(1)
    const [lengthParam, setlengthParam] = useState(1)
    //<!---All State -->
    //<!---Device map and put the value in types nd Unit ->
    let List = []
    useEffect(() => {
        configration.Device.map((device) => {
            let Unit = [],
                Type = []
            device.parameter.map((param) => {
                Type.push(param.type)
                Unit.push(param.unit)
            })
            List.push({ deviceType: device.type, name: device.name, unit: Unit, types: Type })
        })
        setList(List)
        setCardLoader(false)
    }, [])

    useEffect(() => {
        let levelIds = getLevelIds(props.organization.organization, props.userInfo.varification?.hierarchy)
        let DeviceType = []
        List.map((deviceType, key) => {
            DeviceType.push({ value: deviceType.deviceType, label: deviceType.deviceType })
        })
        setDeviceTypes(DeviceType)
        setLevelIdsList(levelIds)
        setLoader(false)
    }, [])
    //<!---Device map and put the value in types nd Unit ->
    //<!-----Branch add and parameter add->
    useEffect(() => {
        let devices1 = []
        if (props?.data) {
            // devices1 = props.data
            setDevice(props.data)
            let devices1 = devices
            devices1[activeDevice] = props.data
            setDevices(devices1)
        } else {
            setDevice({
                type: "",
                name: "Device 1",
                parameter: [{ types: "", value: "Param1", unit: "" }],
                hubId: hubId ? hubId : "",
                levelId: levelId ? levelId : "",
                _id: ""
            })
            for (var i = 1; i <= lengthDevice; i++) {
                devices1.push({
                    type: "",
                    name: "Device" + i,
                    parameter: [{ types: "", value: "Param1", unit: "" }],
                    hubId: hubId ? hubId : "",
                    levelId: levelId ? levelId : "",
                    _id: ""
                })
            }
            for (var j = 1; j <= lengthParam; j++) {
                if ("parameter" in devices1) {
                    devices1.map(param => param.parameter.push({ types: "", value: "", unit: "" }))
                }
            }
            setDevices(devices1);
        }
    }, [])

    useEffect(() => {
        if (props.data) {
            let DeviceType = []
            let hubIds = []
            List.map((deviceType, key) => {
                DeviceType.push({ value: deviceType.deviceType, label: deviceType.deviceType })
            })
            setDeviceTypes(DeviceType)
            setActiveParam(0)
            setDevice(props.data)
            let ParamType = [],
                Name = []
            List.map((deviceType, key) => {
                if (device.type === deviceType.deviceType) {
                    deviceType.types.map((type, key) => {
                        ParamType.push({ value: type, label: type, unit: deviceType.unit[key] })
                    })
                    deviceType.name.map((name, key) => {
                        Name.push({ value: name, label: name })
                    })

                }
            })
            props.organization.organization.allhubs.map((hub, key) => {
                if (device.levelId === hub.levelId) {
                    hubIds.push({ value: hub._id, label: hub._id })
                }
            })
            setHubIdList(hubIds)
            ChangeParam(device.parameter[0], 0, ParamType)
            setParamTypeList(ParamType)
            setSuggestName(Name)
            setLoader(false)
        }
    }, [props.data])

    //<!-----Branch add and parameter add->
    //<!----Chnage state mean Change device data->
    const ChangeState = (devicedata, key) => {
        Validation()
        setActiveDevice(key);

        setActiveParam(0)
        setDevice(devicedata)
        let ParamType = []
        let hubIds = []
        let Name = []
        list.map((deviceType, key) => {
            if (devicedata.type === deviceType.deviceType) {
                deviceType.types.map((type, key) => {
                    ParamType.push({ value: type, label: type, unit: deviceType.unit[key] })
                })
                deviceType.name.map((name, key) => {
                    Name.push({ value: name, label: name })
                })

            }
        })
        ChangeParam(devicedata.parameter[0], 0, ParamType)
        setParamTypeList(ParamType)
        setHubIdList(hubIds)
        setSuggestName(Name)
    }
    //<!----Chnage state mean The data of which Device you In->

    //<!----Chnage params mean The data of which parameter you In->
    const ChangeParam = (paramData, key, liveParam = null) => {
        if (paramData.types) {
            let Unit = []
            if (liveParam) {
                liveParam.map(typelist => {
                    if (typelist.value === paramData.types) {
                        typelist.unit.map((unit) => {
                            Unit.push({ value: unit, label: unit })
                        })
                    }
                })
            }
            else {
                paramTypeList.map(typelist => {
                    if (typelist.value === paramData.types) {
                        typelist.unit.map((unit) => {
                            Unit.push({ value: unit, label: unit })
                        })
                    }
                })
            }
            setUnits(Unit)
        }
        setTimeout(() => {
            setActiveParam(key);
        }, 100);
    }
    //<!----Chnage params mean Change parameter data->

    //<!----input validations->
    const Validation = () => {
        setUnit(true)
        setName(true)
        setType(true)
        setParamType(true)
        setParamValue(true)
        setParamUnit(true)


    }
    //<!----input validations->

    //<!------Change Handler->
    const onChangeHandler = (e, index) => {
        let data = device
        if (e.name === "type") {
            let ParamType = [],
                Name = []
            list.map((deviceType, key) => {
                if (e.target.value === deviceType.deviceType) {
                    deviceType.types.map((type, key) => {
                        ParamType.push({ value: type, label: type, unit: deviceType.unit[key] })
                    })
                    deviceType.name.map((name, key) => {
                        Name.push({ value: name, label: name })
                    })
                }
            })
            data["name"] = null
            setParamTypeList(ParamType)
            setSuggestName(Name)
            data["parameter"] = [{ types: "", value: "Param1", unit: "" }]
        }
        if (e.name === "levelId") {
            data["hubId"] = null
            let hubIds = []
            props.organization.organization.allhubs.map((hub, key) => {
                if (e.target.value === hub.levelId) {
                    hubIds.push({ value: hub._id, label: hub._id })
                }
            })
            setHubIdList(hubIds)
        }
        if (e.name === "types") {
            let Unit = []
            let index = null;
            paramTypeList.map((type, key) => { return (e.target.value === type.value ? index = key : null) })
            paramTypeList[index].unit.map((unit) => {
                Unit.push({ value: unit, label: unit })
            })
            setUnits(Unit)
        }
        let name = e.name
        if (name === "types" || name === "value" || name === "unit") {
            data["parameter"][activeParam][name] = e.target.value
        }
        else {
            data[name] = e.target.value
        }
        if (e.name === "levelId") {
            setSelectLevelId(true)
        }
        if (e.name === "hubId") {
            setSelectHubId(true)
        }
        if (e.name === "parameter") {
            setUnit(true)
        }
        if (e.name === "name") {
            setName(true)
        }
        if (e.name === "type") {
            setType(true)
        }
        if (e.name === "types") {
            setParamType(true)
        }
        if (e.name === "value") {
            setParamValue(true)
        }
        if (e.name === "unit") {
            setParamUnit(true)
        }
        setDevice(data)
        let devices1 = devices
        devices1[activeDevice] = data
        setDevices(devices1)
    }
    //<!------Change Handler->

    //<!-----all input validation Functions->
    const checkNOD = () => {
        if (!device.name) {
            setName(false)
            return false
        }
        else {

            setName(true)
            return (true)
        }

    }
    const checklevelId = () => {
        if (!device.levelId) {
            setSelectLevelId(false)
            return false
        }
        else {

            setSelectLevelId(true)
            return (true)
        }

    }
    const checkHubId = () => {
        if (!device.hubId) {
            setSelectHubId(false)
            return false
        }
        else {

            setSelectHubId(true)
            return (true)
        }

    }
    const checkParameterType = () => {
        if (!device.parameter[activeParam].types) {
            setParamType(false)
            return false
        }
        else {

            setParamType(true)
            return (true)
        }
    }
    const checkParameterValue = () => {
        if (!device.parameter[activeParam].value) {
            setParamValue(false)
            return false
        }
        else {

            setParamValue(true)
            return (true)
        }
    }
    const checkParamUnit = () => {
        if (!device.parameter[activeParam].unit && units.length) {
            setParamUnit(false)
            return false
        }
        else {
            setParamUnit(true)
            return (true)
        }
    }
    const checkType = () => {
        if (!device.type) {
            setType(false)
            return false
        }
        else {

            setType(true)
            return (true)
        }
    }

    //<!-----all input validation Functions->
    //<!------Add Parameter Tab->
    const addParameter = (index) => {
        let newlength = (devices[activeDevice].parameter.length + 1);
        let devices1 = [...devices]
        devices1[index].parameter.push({
            types: "", value: "Param" + newlength, unit: ""
        })
        setlengthParam(newlength)
        setDevices(devices1);
    }
    //<!------Add Parameter Tab->
    //<!------Add Device Tab->
    const addDevice = () => {
        let newlength = (lengthDevice + 1);
        let devices1 = [...devices]
        devices1.push({
            type: "",
            name: "Device" + newlength,
            parameter: [{ types: "", value: "", unit: "" }],
            hubId: hubId,
            levelId: levelId,
            _id: ""
        })
        setDevices(devices1);
        setLengthDevice(newlength)
    }
    //<!------Add Device Tab->
    //<!------delete Device Tab->
    const deleteDevice = (key) => {
        let remove = [...devices]
        remove.splice(key, 1)
        setDevices(remove)
        if (activeDevice > 0) {
            ChangeState(devices[activeDevice - 1], activeDevice - 1)
        }
        else {
            ChangeState(devices[activeDevice + 1], activeDevice)
        }
    }
    //<!-----delete Device Tab->
    //<!------delete parameters Tab->
    const deleteParameter = (key) => {
        let removeParam = [...devices]
        removeParam[activeDevice].parameter.splice(key, 1)
        setDevices(removeParam)
        if (activeParam > 0) {
            ChangeParam(devices[activeDevice].parameter[activeParam - 1], activeParam - 1)
        }
        else {
            ChangeParam(devices[activeDevice].parameter[activeParam], activeParam)
        }
    }
    //<!------delete parameters Tab->

    //<!------check all conditions and create devices in bulk->
    const CreateDevice = () => {
        let filledInput = true
        let deviceKey = ""
        let paramKey = ""

        for (var i = 0; i <= (devices.length - 1); i++) {
            if (!devices[i].type || !devices[i].name || !devices[i].parameter) {
                filledInput = false
                deviceKey = i;
                break;
            }
            //check Every Device of data is full feild
            for (var j = 0; j <= (devices[i].parameter.length - 1); j++) {
                if (!devices[i].parameter[j].types || !devices[i].parameter[j].value || (units.langth ? !devices[i].parameter[j].unit : null)) {
                    filledInput = false
                    deviceKey = i;
                    paramKey = j
                    break;
                }
            }

        }
        //Check  Type if it is true then go to next Conditions when All conditions true Hit API "Create Device"
        if (checkType()) {
            if (checklevelId()) {
                if (checkHubId()) {
                    if (checkNOD()) {
                        if (checkParameterValue()) {
                            if (checkParameterType()) {
                                if (checkParamUnit()) {
                                    let newkey = activeDevice + 1
                                    if (devices.length > activeDevice + 1) {
                                        ChangeState(devices[newkey], newkey)
                                    } else {
                                        if (filledInput) {
                                            let state1 = { ...state }
                                            state1.loginLoader = true;
                                            setState(state1)
                                            if (props?.data?._id) {
                                                return deviceUpdate(devices[0], devices[0]._id).then(async res => {
                                                    let result = res
                                                    if (result) {
                                                        if (!result.statusCode) {
                                                            setPopup(["Success!", "Devices updated successfully.", "success"])
                                                            state1.loginLoader = false;
                                                            setState(state1)
                                                            if (orgId1) {
                                                                orgAction(orgId1)
                                                            }
                                                        }
                                                        else {
                                                            setPopup(["Failure!", "Some thing went wrong.", "danger"])
                                                            state1.loginLoader = false;
                                                        }
                                                    }
                                                })
                                            } else {
                                                devices.forEach((index) => {
                                                    let name = index.name.replace(" ", "")
                                                    index._id = device.hubId + "_" + name
                                                })
                                                return deviceCreatedBulk(devices).then(async res => {
                                                    let result = res
                                                    if (result) {
                                                        if (!result.statusCode) {
                                                            setPopup(["Success!", "Devices created successfully.", "success"])
                                                            state1.loginLoader = false;
                                                            setState(state1)
                                                            if (orgId) {
                                                                orgAction(orgId)
                                                            } else {
                                                                orgAction(orgId1)
                                                            }
                                                        }
                                                        else {
                                                            setPopup(["Failure!", "Device already exist.", "danger"])
                                                            state1.loginLoader = false;
                                                        }
                                                    }
                                                })
                                            }
                                        } else {
                                            //First device form Compelete if second device exist send to second device  
                                            ChangeState(devices[deviceKey], deviceKey)
                                            ChangeParam(devices[deviceKey].parameter[paramKey], paramKey)
                                        }
                                    }
                                } else {
                                    setParamUnit(true)
                                }
                            }
                        } else {
                            setParamType(true)
                            setParamUnit(true)
                        }
                    } else {
                        setParamValue(true)
                        setParamType(true)
                        setParamUnit(true)
                    }
                } else {
                    setName(true)
                    setParamValue(true)
                    setParamType(true)
                    setParamUnit(true)
                }
            } else {
                setSelectHubId(true)
                setName(true)
                setParamValue(true)
                setParamType(true)
                setParamUnit(true)
            }
        }
        else {
            setSelectLevelId(true)
            setSelectHubId(true)
            setName(true)
            setParamValue(true)
            setParamType(true)
            setParamUnit(true)

        }
    }
    //<!------check all conditions and create devices in bulk->

    return (

        <>
            {cardLoader ? <CardLoader /> :
                loader ? <div></div> :
                    <div style={{ minHeight: 300, maxHeight: 500 }}>
                        <div className='row m-0 pb-4'>
                            <div className='col d-flex' style={{ width: "100%" }}>
                                {devices.map((devicedata, key) =>
                                    <div className={'tabButton d-flex ' + (key === activeDevice ? "active" : "null")} style={{ minWidth: (key === activeDevice) ? "130px" : "100px" }} >
                                        <div className='inner-btn text-truncate' onClick={() => ChangeState(devicedata, key)} key={key}>{devices[key].name}</div>
                                        {devices.length > 1 ?
                                            <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteDevice(key)}><i className='fas fa-trash fa-sm'></i></div>
                                            : null}
                                    </div>
                                )}
                            </div>
                            {!props?.data?._id ?
                                <div className='justify-content-end px-3'>
                                    <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addDevice()}>+</button>
                                </div> : null}
                        </div>

                        <div className='d-flex flex-column justify-content-between px-4 pb-2'>
                            <div>
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <Select onEnter={() => CreateDevice()} dropDownHeight="150px" name="type" label="Type*" defaultValue={{ value: device.type }} onClick message={{ msg: "Please enter your device type", status: !type }} options={deviceTypes} onChange={(e) => onChangeHandler(e)} />
                                    </div>
                                </div>
                                {!levelId || props.type === "direct" ?
                                    <div className='row mt-5'>
                                        <div className='col-6'>
                                            <Select onEnter={() => CreateDevice()} dropDownHeight="150px" name="levelId" label="Level Id*" defaultValue={{ value: device.levelId }} onClick message={{ msg: "Please enter your level Id", status: !selectLevelId }} options={levelIdsList} onChange={(e) => onChangeHandler(e)} />
                                        </div>
                                        <div className='col-6'>
                                            <Select onEnter={() => CreateDevice()} dropDownHeight="150px" name="hubId" label="Hub Id*" defaultValue={{ value: device.hubId }} onClick message={{ msg: "Please enter your hub Id", status: !selectHubId }} options={hubIdList} onChange={(e) => onChangeHandler(e)} />
                                        </div>
                                    </div>
                                    : null}
                                {suggestName.length ? <div className='row mt-5'>
                                    <div className='col-12'>
                                        <Select onEnter={() => CreateDevice()} dropDownHeight="150px" textEditor name="name" label="Name*" defaultValue={{ value: device.name }} onClick message={{ msg: "Please enter your device name", status: !name }} options={suggestName} onChange={(e) => onChangeHandler(e)} />
                                    </div>
                                </div> : null}

                                {
                                    paramTypeList.length && device ?
                                        <div className='split-t mt-3'>
                                            <div className='row overflow-hidden pb-4' >
                                                <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                    {devices[activeDevice].parameter.map((paramData, key) =>
                                                        <div className={'tabButton d-flex ' + (key === activeParam ? "active" : "null")} style={{ minWidth: (key === activeParam) ? "130px" : "100px" }}>
                                                            <div className='inner-btn text-truncate' onClick={() => ChangeParam(paramData, key)} key={key}>{paramData.value}</div>
                                                            {devices[activeDevice].parameter.length > 1 ?
                                                                <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteParameter(key)}><i className='fas fa-trash fa-sm'></i></div>
                                                                : null}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='justify-content-end px-3'>
                                                    <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addParameter(activeDevice)}>+</button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    {
                                                        activeParam < device.parameter.length ?
                                                            <Input onEnter={() => CreateDevice()} name="value" label="Param Value" defaultValue={device.parameter[activeParam].value} message={{ msg: "Please enter your param value", status: !paramValue }} onChange={(e) => onChangeHandler(e)} />
                                                            : null
                                                    }
                                                </div>
                                                <div className='col-md-4 mt-md-0 mt-3'>
                                                    {activeParam < device.parameter.length ?
                                                        <Select onEnter={() => CreateDevice()} name="types" dropDownHeight="100px" label="Param Type" options={paramTypeList} defaultValue={{ value: device.parameter[activeParam].types }} onClick message={{ msg: "Please enter your param type", status: !paramType }} onChange={(e) => onChangeHandler(e)} />
                                                        : null}
                                                </div>
                                                <div className='col-md-4 mt-md-0 mt-3'>
                                                    {activeParam < device.parameter.length ?
                                                        units.length ?
                                                            <Select onEnter={() => CreateDevice()} name="unit" dropDownHeight="100px" label="Param Unit" defaultValue={{ value: device.parameter[activeParam].unit }} onClick options={units} message={{ msg: "Please enter your param unit", status: !paramUnit }} onChange={(e) => onChangeHandler(e)} />
                                                            : null// : <Select disabled name="unit" dropDownHeight="100px" label="Param Unit" defaultValue={{ value: device.parameter[activeParam].unit }} onClick options={units} message={{ msg: "Please enter your param unit", status: !paramUnit }} onChange={(e) => onChangeHandler(e)} />
                                                        : null}
                                                </div>
                                            </div></div> : null
                                }
                            </div>
                            {state.loginLoader ?
                                <div className='d-flex justify-content-center' style={{ height: "80px" }}>
                                    <InlineLoader />
                                </div> :
                                activeDevice + 1 === devices.length ?
                                    <ButtonContainer className="mt-3">

                                        <Button buttonResult={() => CreateDevice()} >{!props?.data?._id ? "Create" : "Update"}</Button>
                                    </ButtonContainer> :

                                    <ButtonContainer className="mt-3">
                                        <Button buttonResult={() => CreateDevice()} >Next</Button>
                                    </ButtonContainer>
                            }

                        </div>

                        {
                            popup ? <Mypopup title={popup[0]} type={popup[2]} onClose={(e) => {
                                setPopup(null);

                            }}>
                                <p className="p-3" > {popup[1]}</p>
                                <div className="d-flex justify-content-center p-3">
                                    <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                                        setPopup(null);
                                        if (levelId && !props.type === "direct") {
                                            props.reloadDevices()
                                        } else {
                                            props.reloadDevices()
                                        }

                                    }}>Ok</button>
                                </div>
                            </Mypopup >
                                : null
                        }
                    </div>
            }
        </>
    )
}
const mapStateToProps = (state) => ({
    organization: state.organization,
    configration: state.configration,
    userInfo: state.userInfo
});
export default connect(mapStateToProps, null)(AddDeviceForm)

