import { OrderedList } from '@amcharts/amcharts4/core'
import React, { useState } from 'react'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card'
import MyDropDown, { MyDropDownBody } from '../../components/dropdown/dropdown'
import Input from '../../components/input/input'
import InlineLoader from '../../components/loader/inlineLoader'
import Mypopup from '../../components/popup/mypopup'

import {
    WhiteSpace,
    CapitalLetters,
    SmallLetters,
    Numbers,
    SpecialCharacters
} from '../../services/config/regex';
import { ChangePass, getCookie, signOut } from '../../services/utilities/users'
const ChangePassword = () => {
    const [state, setState] = useState({
        loginData: {},
        modal: false,
        redirect: false,
        loginLoader: false,
    })
    const [oldPass, setOldPass] = useState(true)
    const [newPass, setNewPass] = useState(true)
    const [passCnfrm, setPassCnfrm] = useState(true)
    const [specialCase, setSpecialCase] = useState(false)
    const [eightChar, setEightChar] = useState(false)
    const [whiteSpace, setWhiteSpace] = useState(true)
    const [visibleOldPass, setVisibleOldPass] = useState(false)
    const [visiblePass, setVisiblePass] = useState(false)
    const [visiblePassCnfrm, setVisiblePassCnfrm] = useState(false)
    const [passValidation, setPassValidation] = useState(true)
    const [cnfrmPassValidation, setCnfrmPassValidation] = useState("")
    const [focus, setFocus] = useState(false)
    const [hover, setHover] = useState(false)
    const [popup, setPopup] = useState(false)
    const [popup1, setPopup1] = useState(false)
    const [data, setData] = useState({
        new: "",
        old: ""
    })

    const dropDown = (state = true, message, type) => {
        return (
            <MyDropDown openToggle={(e) => setHover(e)}>

                {state ? !type ?
                    <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px 0 calc(1rem + 10px) -55px" }}></span>
                    : <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px -36px calc(1rem + 10px) -100px" }}></span>
                    : null}
                <MyDropDownBody open={hover ? hover : focus ? focus : false} right>
                    <div className='py-3 text-left p-3 ' style={{ width: "300px" }}>
                        <small>{message}</small>
                        {type === "password" ?
                            <div style={{
                                padding: 0,
                                margin: 0,
                                listStyleType: "none",
                            }}>
                                {eightChar ?
                                    <li><i className="far fa-check-circle mr-1 text-success"></i><small>Be a minimum of 8 characters</small></li>
                                    :
                                    <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Be a minimum of 8 characters</small></li>
                                }
                                {specialCase ?
                                    <li><i className="far fa-check-circle mr-1 text-success"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>
                                    : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>

                                }
                                {whiteSpace ?
                                    <li><i className="far fa-check-circle mr-1 text-success"></i><small>Contain not be spaces</small></li>
                                    : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Contain not be spaces</small></li>
                                }
                            </div>
                            : null}
                    </div>
                </MyDropDownBody>
            </MyDropDown>)
    }
    const onChangeHandler = (e) => {
        let data1 = data
        let name = e.target.name
        data1[name] = e.target.value
        if (name === "new") {
            Passwordvalidate(e.target.value)
            setPassValidation(true)
            return true
        } else if (name === "old") {
            setOldPass(true)
        }
        setData(data1)
    }
    const passCnfrmHandler = (e) => {
        setPassCnfrm(true)
        setCnfrmPassValidation(e.target.value)
        cnfrmPassvalidate(e.target.value)
    }

    const cnfrmPassvalidate = (passcnfrm) => {
        if (data.new === passcnfrm) {
            setPassCnfrm(true)
            return true
        } else {
            setPassCnfrm(false)
            return false
        }
    }

    const oldPassVildate = () => {
        if (data.old === "" || data.old === null || data.old === undefined || data.new === data.old) {
            setOldPass(false)
            return false
        } else {
            setOldPass(true)
            return true
        }
    }

    const Passwordvalidate = (pass) => {
        var whiteSpace1 = WhiteSpace
        if (whiteSpace1.test(pass)) {
            setWhiteSpace(false)
        } else {
            setWhiteSpace(true)
        }
        if (pass.length >= 8) {
            setEightChar(true)
        } else {
            setEightChar(false)
        }
        var passwordformat = [
            CapitalLetters,
            SmallLetters,
            Numbers,
            SpecialCharacters]

        let count = 0
        for (let index = 0; index < passwordformat.length; index++) {
            const element = passwordformat[index];
            if (element.test(pass))
                count++
        }
        if (count >= 3) {
            setSpecialCase(true)
        }
        else {
            setSpecialCase(false)
        }
        if (specialCase && eightChar && whiteSpace) {
            setPassValidation(true)
            return true
        }
        else {
            setPassValidation(false)
            return false
        }
    }
    const ChangePassword = () => {
        if (oldPassVildate()) {
            if (Passwordvalidate(data.new)) {
                if (cnfrmPassvalidate(cnfrmPassValidation)) {
                    let state1 = { ...state }
                    state1.loginLoader = true;
                    setState(state1)
                    return ChangePass(data).then(res => {
                        let result = res
                        if (result) {
                            let message = result.message
                            if (result.statusCode === 200 || result.status) {
                                state1.loginLoader = false;
                                setState(state1)
                                setPopup1(["Success!", message, "success"])
                                setTimeout(() => {
                                    signOut()
                                }, 1000)
                            }
                            else {
                                setPopup(["Failure!", message, "danger"])
                                state1.loginLoader = false;
                                setState(state1)
                            }
                        }
                    })
                }
            } else {
                setCnfrmPassValidation(true)
            }
        } else {
            setPassValidation(true)
            setCnfrmPassValidation(true)
        }
    }
    return (
        <div className='row m-0 justify-content-center align-items-center px-md-0 px-3' style={{ height: "calc(100vh - 180px)" }} >
            <Card className={"col-lg-4 col-md-6 col-sm-8 col-11 p-md-3 p-2 fade-in-right-onload d-block"}>
                <CardHeader>
                    <Heading size={2} align="center">
                        Change Password
                    </Heading>
                    <p><i className="mr-2 fa fa-user"></i>{getCookie('email')}</p>
                </CardHeader>
                <CardBody>
                    <div className='row my-md-2 my-2 '>
                        <div className='d-flex col-md-12 my-md-3 my-3 col-12'>
                            <input className="form-control rounded-4 py-4 px-4 mb-3"
                                name="old"
                                placeholder="Old Password*"
                                defaultValue={data.old}
                                type={visibleOldPass ? "type" : "password"}
                                style={{ borderColor: oldPass ? "#1B75BC" : "#FF0000" }}
                                onChange={(e) => onChangeHandler(e)} />
                            {!oldPass ?
                                dropDown(!oldPass, "Please Enter Your Old Password and Password must be Change new Password", "oldPassword")
                                : null}
                            <button className={visibleOldPass ? 'btn btn-light fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setVisibleOldPass(!visibleOldPass)} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>

                        </div>
                        <div className='d-flex col-12'>
                            <input
                                className="form-control rounded-4 py-4 px-4 mb-3"
                                autocomplete="off"
                                placeholder="New Password"
                                defaultValue={data.new}
                                pattern=".*\S+.*"
                                name="new"
                                type={visiblePass ? "text" : "password"}
                                style={{
                                    borderColor: passValidation ? "#1B75BC" : "#FF0000",
                                }}
                                onFocus={() => { setFocus(true); Passwordvalidate(data.new); }}
                                onBlur={() => setFocus(false)}
                                id="pass"
                                onChange={(e) => onChangeHandler(e)}
                            />
                            {focus ?
                                dropDown(false, ``, "password")
                                : null}
                            <button className={visiblePass ? 'btn btn-light fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setVisiblePass(!visiblePass)} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                        </div>
                        <div className='d-flex col-md-12 my-md-3 my-3 col-12'>
                            <input className="form-control rounded-4 py-4 px-4 mb-3"
                                style={{ borderColor: passCnfrm ? "#1B75BC" : "#FF0000" }}
                                name="cnfrmpassword" placeholder="New Confirm Password*"
                                type={visiblePassCnfrm ? "text" : "password"}
                                onChange={(e) => { passCnfrmHandler(e) }} />
                            <button className={visiblePassCnfrm ? 'btn btn-light fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setVisiblePassCnfrm(!visiblePassCnfrm)} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                        </div>
                        {!passCnfrm ? <p style={{ marginRight: "120px", color: "red" }}>Please make sure your password match</p> : null}
                    </div>
                    {state.loginLoader ?
                        <div className='d-flex justify-content-center' style={{ height: "80px" }}>
                            <InlineLoader />
                        </div> :
                        <ButtonContainer className="mt-5">
                            <Button buttonResult={() => ChangePassword()}>Change Password</Button>
                        </ButtonContainer>
                    }
                </CardBody>
            </Card>
            {
                popup1 ? <Mypopup title={popup1[0]} type={popup1[2]} noClose={"noClose"}>
                    <div className="d-flex justify-content-center p-3">
                        <h6 className="p-3" > {popup1[1]}</h6>
                    </div>
                </Mypopup >
                    : null
            }
            {
                popup ? <Mypopup title={popup[0]} type={popup[2]} onClose={(e) => {
                    setPopup(null)
                }
                }>
                    <p className="p-3" > {popup[1]}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setPopup(null)
                        }}>Ok</button>
                    </div>
                </Mypopup >
                    : null
            }
        </div >
    )
}

export default ChangePassword