
// HTTP Requests Server Live
// export const ServerAddress = "https://organizations2.ottomatically.com";
// export const ServerAddress = "http://192.168.10.170:5000";
// export const ServerAddress = "http://192.168.100.21:5000";
// export const ServerAddress = "http://localhost:5000";
export const ServerAddress = "https://gateway2.s5.ottomatically.com";
// export const ServerAddress = "http://192.168.137.252:5000";
// export const ServerAddress = process.env.REACT_APP_API_URL + ":5000";
// export const ServerAddress = process.env.REACT_APP_API_URL + ":5000";
// export const ServerAddress = process.env.REACT_APP_API_URL + ":5000";
// export const ServerAddress = "https://4cb3-5-32-61-171.ngrok-free.app"
// export const ServerAddress = process.env.REACT_APP_API_URL + ":5000";
// export const ServerBaseAddress = ServerAddress + "/api/v2";
export const ServerBaseAddress = ServerAddress + "/api/v1";


// HTTP Requests Server Local
export const ServerAddressLocal = "http://192.168.10.14";
export const ServerPort = 5000;
export const ServerBaseAddressLocal = ServerAddressLocal + ":" + ServerPort + "/api/v1";

// Socket Requests Server
// export const SocketServerAddress = "http://192.168.10.170:5000";
export const SocketServerAddress = "https://events2.s5.ottomatically.com";
// export const SocketServerAddress = "https:/process.env.REACT_APP_API_URL + /:5005";
// export const SocketServerAddress = process.env.REACT_APP_API_URL + ":5005";
// export const SocketServerAddress = process.env.REACT_APP_API_URL + ":5005";
// export const SocketServerAddress = process.env.REACT_APP_API_URL + ":5005";
// export const SocketServerAddress = "http://10.10.130.205:5005";
// export const SocketServerAddress = "http://192.168.10.170:5005";
// export const SocketServerPort = 5005;
export const SocketServerBaseAddress = SocketServerAddress


// Dashboard Configurations URLs
export const getConfigrations = ServerBaseAddress + "/dashboard/configurations";

// Users URLs
export const signUpUrl = ServerBaseAddress + "/open/users/signup";
export const signInUrl = ServerBaseAddress + "/open/users/signin";
export const signOutUrl = ServerBaseAddress + "/open/users/sign-out"
export const verifyEmailUrl = ServerBaseAddress + "/open/users/verify-email/";
export const getSignedInUserUrl = ServerBaseAddress + "/me";
export const userVerificationUrl = ServerBaseAddress + "/open/users/confirmation";
export const setPasswordUrl = ServerBaseAddress + "/open/users/set-password";
export const resendEmailUrl = ServerBaseAddress + "/open/users/resend-email";
export const refreshTokenUrl = ServerBaseAddress + "/users/refresh-token";
export const ChangePassword = ServerBaseAddress + "/users/updatepassword"


//Events  URL
export const hierarchyUrl = ServerBaseAddress + '/levels/'
export const hierarchySubUrl = '/hierarchy'

//Hierarchy 2
export const hierarchy2Url = ServerBaseAddress + "/levels/"
export const hierarchy2SubUrl = "/hierarchy2"


export const organizationCreate = ServerBaseAddress + '/levels/bulk'

export const organizationCreatedUrlSingle = ServerBaseAddress + '/levels'

export const getHubsTypeUrl = ServerBaseAddress + '/hubs'

export const HubCreatedUrl = ServerBaseAddress + '/hubs'

export const getHubUrl = ServerBaseAddress + '/levels/'
export const getHubSubUrl = '/hubs'

export const deviceCreateBulk = ServerBaseAddress + "/devices/bulk"

export const getDeviceUrl = ServerBaseAddress + "/hubs/"
export const getDeviceSubUrl = "/devices"

export const getLastEventsUrl = ServerBaseAddress + "/levels/"
export const getLastSubEventsUrl = "/events"

export const getDeviceByDeviceIdURL = ServerBaseAddress + "/devices/"

export const getLastEventsByDeviceIdURL = ServerBaseAddress + "/devices/"
export const getLastSubEventDeviceIdURL = '/events'

export const getMultiDeviceHistoryURL = ServerBaseAddress + "/devices/events/compare"
export const getMultiDeviceConsumptionURL = (id) => ServerBaseAddress + "/devices/" + id + "/energy/consumption"
// export const getMultiDeviceConsumptionURL = (id) => "https://analytics2.s5.ottomatically.com/api/v1/devices/" + id + "/energy/consumption"

export const getAnylaticsByDeviceIdURL = ServerBaseAddress + "/devices/"
export const getSubAnylaticsByDeviceIdURL = '/events/temp/stats'

export const getStatusAnylaticsByDeviceIdURL = ServerBaseAddress + "/devices/"
export const getSubStatusAnylaticsByDeviceIdURL = "/duration"

export const getPostAlertURL = ServerBaseAddress + "/alerts"

export const getAlertUrl = ServerBaseAddress + "/devices/"
export const getSubAlertUrl = "/alerts"

export const getUpdateAlertUrl = ServerBaseAddress + "/alerts/"

export const getImgUrl = "https://organizations2.s5.ottomatically.com/api/v1"
// export const getImgUrl = process.env.REACT_APP_API_URL + ":5001/api/v1"
// export const getImgUrl = process.env.REACT_APP_API_URL + ":5001/api/v1"
// export const getImgUrl = process.env.REACT_APP_API_URL + ":5001/api/v1"

export const ForgotPasswordEmail = ServerBaseAddress + "/open/users/request-password"
export const ForgetPassword = ServerBaseAddress + "/open/users/forget-password"

export const overviewById = ServerBaseAddress + "/overviews/level/"

export const overviewCRUD = ServerBaseAddress + "/overviews"

export const imageURL = getImgUrl + "/files"

export const getRoles = ServerBaseAddress + "/roles"
export const createRoles = ServerBaseAddress + "/roles"

export const createUser = ServerBaseAddress + "/users/add"
export const User = ServerBaseAddress + "/users/"
export const VerifyUser = ServerBaseAddress + "/open/users/verify-password/"
export const getUser = ServerBaseAddress + "/users"
export const functionsArray = ServerBaseAddress + "/functions"

export const acKnowledge = ServerBaseAddress + "/notifications"
// notification Id
export const hubInfoFiles = "https://ottomatically.com/inventory-server/images/ottomatically.com.zip"
// export const getImgUrl = "http://192.168.10.14:5001/api/v1"

export const updateDevice = ServerBaseAddress + "/devices/"

// export const summary = "http://192.168.10.3:5001/api/v1/levels/"
// export const summary = process.env.REACT_APP_API_URL + ":5001/api/v1/levels/"
export const summary = process.env.REACT_APP_API_URL + ":5001/api/v1/levels/"
// export const summary = process.env.REACT_APP_API_URL + ":5001/api/v1/levels/"
export const summary1 = "/summary"

export const create_getRules = ServerBaseAddress + "/rules"
export const get_rules_firstHalf = ServerBaseAddress + "/levels/"
export const get_rules_secondHalf = "/rules"


// HTTP Requests Server Live
// export const ServerAddress = "https://organizations2.ottomatically.com";
// export const ServerAddress = "http://192.168.0.106:5000";
// // export const ServerAddress = "http://192.168.10.14:5000";
// export const ServerBaseAddress = ServerAddress + "/api/v1";


// // HTTP Requests Server Local
// export const ServerAddressLocal = "http://192.168.0.106";
// export const ServerPort = 5000;
// export const ServerBaseAddressLocal = ServerAddressLocal + ":" + ServerPort + "/api/v1";

// // Socket Requests Server
// export const SocketServerAddress = "http://192.168.0.106";
// export const SocketServerPort = 5005;
// export const SocketServerBaseAddress = SocketServerAddress + ":" + SocketServerPort;


// // Dashboard Configurations URLs
// export const getConfigrations = ServerBaseAddress + "/dashboard/configurations";

// // Users URLs
// export const signUpUrl = ServerBaseAddress + "/users/signup";
// export const signInUrl = ServerBaseAddress + "/users/signin";
// export const signOutUrl = ServerBaseAddress + "/users/sign-out"
// export const verifyEmailUrl = ServerBaseAddress + "/users/verify-email/";
// export const getSignedInUserUrl = ServerBaseAddress + "/users/me";
// export const userVerificationUrl = ServerBaseAddress + "/users/confirmation";
// export const setPasswordUrl = ServerBaseAddress + "/users/set-password";
// export const resendEmailUrl = ServerBaseAddress + "/users/resend-email";
// export const refreshTokenUrl = ServerBaseAddress + "/users/refresh-token";


// //Events  URL
// export const hierarchyUrl = ServerBaseAddress + '/levels/'
// export const hierarchySubUrl = '/hierarchy'


// export const organizationCreate = ServerBaseAddress + '/levels/bulk'

// export const organizationCreatedUrlSingle = ServerBaseAddress + '/levels'

// export const getHubsTypeUrl = ServerBaseAddress + '/hubs'

// export const HubCreatedUrl = ServerBaseAddress + '/hubs'

// export const getHubUrl = ServerBaseAddress + '/levels/'
// export const getHubSubUrl = '/hubs'

// export const deviceCreateBulk = ServerBaseAddress + "/devices/bulk"

// export const getDeviceUrl = ServerBaseAddress + "/hubs/"
// export const getDeviceSubUrl = "/devices"

// export const getLastEventsUrl = ServerBaseAddressLocal + "/levels/"
// export const getLastSubEventsUrl = "/events"


// export const getLastEventsByDeviceIdURL = ServerBaseAddress + "/devices/"
// export const getLastSubEventDeviceIdURL = '/events'

// export const getAnylaticsByDeviceIdURL = ServerBaseAddress + "/devices/"
// export const getSubAnylaticsByDeviceIdURL = '/events/temp/stats'

// export const getStatusAnylaticsByDeviceIdURL = ServerBaseAddress + "/devices/"
// export const getSubStatusAnylaticsByDeviceIdURL = "/duration"

// export const getPostAlertURL = ServerBaseAddress + "/alerts"

// export const getAlertUrl = ServerBaseAddress + "/devices/"
// export const getSubAlertUrl = "/alerts"

// export const getUpdateAlertUrl = ServerBaseAddress + "/alerts/"
// // export const getImgUrl = ServerBaseAddress

// export const ForgetPassword = ServerBaseAddress + "/users/forget-password"
// export const ForgotPasswordEmail = ServerBaseAddress + "/users/request-password"
// export const ChangePassword = ServerBaseAddress + "/users/updatepassword"
// // //Hierarchy 2
// export const hierarchy2Url = ServerBaseAddress + "/levels/"
// export const hierarchy2SubUrl = "/hierarchy2"
// export const getImgUrl = "http://192.168.0.106:5001/api/v1"
