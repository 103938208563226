import React, { useState } from 'react'


const DropContainer = (props) => {
    const dragAllow = (e) => {
        e.preventDefault();
        // e.style.backgroundColor = "red"
        // e.target.innerHTML = "Drop Here!"
    }
    const drop = async (e) => {
        e.preventDefault();
        var component = e.dataTransfer.getData("component");
        var pair = e.dataTransfer.getData("pair");
        // const { default: NewComponent } = await import("../componentLIst/" + component)
        let newComp = {
            type: component,
            style: {
                css: { width: "5%" },
                class: "",
            },
            config: {},
        }
        // newComp.component = <NewComponent config={newComp.config} />
        props.PageConfig(newComp, e.target)
        // e.target.innerHTML = a
    }
    return (
        <div className='w-100 h-100 d-flex flex-wrap align-content-start' onDrop={(e) => drop(e)}
            onDragOver={(e) => dragAllow(e)}>
            {props.children}
        </div>
    )
}

export default DropContainer