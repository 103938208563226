import React, { useEffect, useState } from 'react'
import Select from '../../select/select'
import { connect } from 'react-redux'
import { flattenArray } from '../../../functions/functions'

function LevelAssign(props) {
    const [levelIds, setLevelIds] = useState([])
    const [selectId, setSelectId] = useState(null)
    // useEffect(() => {
    //     let levelIds1 = []
    //     if (props.organization.organization) {
    //         levelIds1 = [...new Set(props.organization.organization.levels.map(level => level._id))]
    //         levelIds1 = [...new Set(levelIds1.map(id => {
    //             let label = ""
    //             id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
    //             return ({ label, value: id })
    //         }))]
    //     }
    //     levelIds1.unshift({label: props.organization.organization.name, value: props.organization.organization._id})
    //     // levelIds1.unshift({label: 'None', value: null})
    //     setLevelIds(levelIds1)
    // }, [])
    useEffect(() => {
        let levelIds1 = []
        let branches = false
        if (props.organization.organization) {
            let flattenedArray1 = flattenArray(props?.organization?.organization?.levels);
            flattenedArray1.map((level) => {
                if (props.userInfo.varification?.hierarchy.length) {
                    props.userInfo.varification?.hierarchy.map(levels => {
                        if (level.levelId.match(levels._id)) {
                            if (levels.branches.length > 0) {
                                branches = true
                                levels.branches.map(branch => {
                                    if (level._id.match(branch.levelId)) {
                                        levelIds1.push(level._id)
                                    }
                                })
                            }
                        }
                    })
                }
                if (!branches) {
                    levelIds1.push(level._id)
                }
            }
            )
            levelIds1 = [...new Set(levelIds1.map(id => {
                let label = ""
                id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
                return ({ label, value: id })
            }))]
        }
        if (!branches) {
            levelIds1.unshift({ label: props.organization.organization.name, value: props.organization.organization._id })
        }
        // levelIds1.unshift({ label: 'None', value: null })
        setLevelIds(levelIds1)
    }, [])
    useEffect(() => {
        if ('defaultValue' in props) {
            setSelectId(props.defaultValue)
        }
    }, [props.defaultValue])
    const onChangeHandler = (e) => {
        let value = e.target.value
        let name = e.name
        setSelectId(value)
        if ("onChange" in props) {
            props.onChange({ name: name, target: { value: value } })
        }
    }
    return (
        <>
            <div className='propertiesLabels'>Level Assign</div>
            <div className="my-3">
                <Select sort={false} name="levelAssign"
                    label="Select Level"
                    options={levelIds}
                    // dropDownHeight="100px"
                    onChange={(e) => {
                        onChangeHandler(e)
                    }}
                    onClick
                    defaultValue={{ value: selectId }}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    organization: state.organization,
    userInfo: state.userInfo
});
export default connect(mapStateToProps, null)(LevelAssign)