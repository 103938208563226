import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getDate, getTime, todayDate, utcDate } from '../../../functions/functions'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import moment from 'moment'
import Table, { TBody, THead, Td, Th, Tr } from '../../table/table'
import { DeviceLastEvents } from '../../../services/utilities/events';
import CardLoader from '../../loader/cardLoader';


function DynamicTable(props) {
    const [night, setNight] = useState(false)
    const [config, setConfig] = useState({
        value: null,
        label: "Label",
    })
    const [className, setClassName] = useState("")
    const [css, setCss] = useState({color:"#0471C4", transition: "all 0.3s"})
    const [events, setEvents] = useState([]
    // {
    //     Status: {},
    //     Connection: {}
    // }
    )
    const [startDate, setStartDate] = useState(todayDate('start'))
    const [endDate, setEndDate] = useState(todayDate('end'))
    const [loader,setLoader] = useState(true)
    
    useEffect(() => {
        setNight(props?.night)
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    let css1 = { ...css }
                    css1.color = props.config.color;
                    setCss(css1)
                }
            }
            if ("label" in props.config && props.config?.label) {
                config1.label = props.config.label
            } 
            else {
                config1.label = "Label"
            }
            if ("params" in props.config){
                config1.params = props.config.params
            }
            if ("dateFormat" in props.config){
                if(props.config.dateFormat === 0){
                    config1.dateFormat = "L LTS"
                }else
                if(props.config.dateFormat === 1){
                    config1.dateFormat = "LL LTS"
                }else
                if(props.config.dateFormat === 2){
                    config1.dateFormat = "dddd, LL LTS"
                }
            } 
            setConfig(config1)
            getData()
        }
    }, [props.config])
    useEffect(() => {
        if (props?.className) {
            setClassName(props.className)
        }
        if (props?.style) {
            if("gap" in props.style){
                let css1 = { ...css, gap: props.style.gap }
                setCss(css1)
            }
        }
    }, [props.className,props.style])

    // useEffect(() => {
    //   if(config?.param === "created"){
    //         let config1 = {...config}
    //         if("dateFormat" in config1){
    //             config1.value = moment(config1.value).format(config1.dateFormat)
    //         }else{
    //             config1.value = moment(config1.value).format("L LTS")
    //         }
    //         setConfig(config1)
    //   }
    // }, [config.value,config.dateFormat])

    const onChangeHandler = (e, type) => {
        let date = moment(e._d).format('YYYY-MM-DDTHH:mm:ss')
        if(type === 'startDate'){
            setStartDate(date);
        }
        else if(type === 'endDate'){
            setEndDate(date);
        }
    }
    useEffect(() => {
        setLoader(true)
        getData()
    }, [startDate, endDate])
    
    const getData = async () => {
        let startDate1 = utcDate(startDate) + "Z"
        let endDate1 = utcDate(endDate) + "Z"
        let id = props?.config?.deviceId
        if(id){
            let response = await DeviceLastEvents(id, 20, startDate1, endDate1)
            setEvents(response)
        }
        setLoader(false)
    } 
  return (
    <div className={'d-flex flex-column h-100 w-100'} style={css}>
        <div className='font-weight-bold text-center'>{config?.label}</div>
        <div className={"d-flex " + className} style={{gap: '10px'}}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                className='mt-1'
                style={{ fontSize: 16 }}
                inputVariant="outlined"
                disableFuture="true"
                label="Start Date"
                value={startDate}
                onChange={(event) => onChangeHandler(event, "startDate")}
                />
            </MuiPickersUtilsProvider>
            
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                className='mt-1'
                style={{ fontSize: 16 }}
                inputVariant="outlined"
                disableFuture="true"
                label="End Date"
                value={endDate}
                onChange={(event) => onChangeHandler(event, "endDate")}
                />
            </MuiPickersUtilsProvider>
        </div>
        <Table>
            <THead>
                {config?.params?.map((column, idx) => 
                    <Th key={idx}>
                        {column.name}
                    </Th>
                )}
            </THead>
            {loader? <td colSpan={config?.params?.length}><CardLoader/></td>:
            <TBody>
                {config?.params &&  events.map(rowDate => 
                    <Tr>
                        {config?.params?.map((column, idx) =>
                            column.value in rowDate &&
                            <Td>{rowDate[column.value]}</Td>
                        )}
                    </Tr>
                )}
            </TBody>}
        </Table>
    </div>
  )
}

const mapStateToProps = (state) => ({
    eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(DynamicTable)