import React from 'react'
import Card, { CardHeader, Heading } from '../card/card'

const Header = (props) => {
    const organizationId = props.data
    let mainOrganizationID = window.location.hash.split("/")
    mainOrganizationID = mainOrganizationID[mainOrganizationID.length - 1]
    return (
        <Card theme="primary-gradient mx-2 mt-3">
            <CardHeader>
                <div className='row'>
                    <div className='col-9'>
                        <Heading color="white" size={1}>{organizationId.locationName ? organizationId.locationName : organizationId.name ? organizationId.name : mainOrganizationID}</Heading>
                    </div>
                </div>
            </CardHeader>
        </Card>)
}

export default Header