import React, { useEffect, useState } from "react";
import "./lightDarkButton.scss";
import Cookies from 'js-cookie';
import store from "../../services/utilities/store";


function LightDarkButton() {
  const [isLight, setIsLight] = useState(true);
  const [CookieData, setCookieData] = useState('');
  useEffect(() => {
    const cookie = Cookies.get('theme')
    if (cookie === "undefined" || cookie === "default") {
      changeTheme("default")
    } else {
      changeTheme(cookie)
    }
  }, []);
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (event) => {
      setIsLight(!event.matches);
    };
    if (CookieData === 'default') {
      mediaQuery.addListener(handleChange);
    }
    return () => {
      mediaQuery.removeListener(handleChange);
    };
  }, [CookieData])

  useEffect(() => {
    let element = document.getElementsByTagName('body')[0]
    if (isLight) {
      element.setAttribute("class", "")
    } else {
      element.setAttribute("class", "dark-theme")
    }
    store.dispatch({
      type: "themeMode",
      payload: isLight
    })
  }, [isLight])

  const changeTheme = (value) => {
    Cookies.set('theme', value)
    setCookieData(value)
    if (value === "default") {
      setIsLight(!(window.matchMedia('(prefers-color-scheme: dark)').matches))
    } else {
      setIsLight(value === 'light')
    }
  }


  return (
    <div className="d-flex w-100">
      <div className="w-100">
        <button className="w-100 userdropdown d-flex justify-content-between" onClick={() => changeTheme('light')}><span><i className="mr-2 fa fa-sun"></i> Light</span> {isLight ? <i className="ml-2 fa fa-check"></i> : null}</button>
        <button className="w-100 userdropdown d-flex justify-content-between" onClick={() => changeTheme('dark')}><span><i className="mr-2 fa fa-moon"></i> Dark</span> {!isLight ? <i className="ml-2 fa fa-check"></i> : null}</button>
      </div>
      <button className="userdropdown split-l text-center" onClick={() => changeTheme('default')}><span><i className={"far fa-" + (CookieData === 'default' ? "check-" : "") + "square"}></i><br /> System Default</span></button>
    </div>
    // <svg
    //   onClick={() => setIsLight(!isLight)}
    //   id="lightDarkBtn"
    //   className={!isLight ? "active" : ""}
    //   height={"100%"}
    //   width={"100%"}
    //   version="1.1"
    //   style={{
    //     shapeRendering: "geometricPrecision",
    //     textRendering: "geometricPrecision",
    //     imageRendering: "optimizeQuality",
    //     fillRule: "evenodd",
    //     clipRule: "evenodd",
    //     cursor: 'default',
    //   }}
    //   viewBox="-10 -10 2445 1070"
    // //   tabIndex={0}
    // >
    //   <defs>
    //     <clipPath id="dl_button_id0">
    //       <path d="M525.13 10.28l1377.78 0c283.17,0 514.85,231.68 514.85,514.85l0 0c0,283.17 -231.68,514.85 -514.85,514.85l-1377.78 0c-283.17,0 -514.85,-231.68 -514.85,-514.85l0 -0c0,-283.17 231.68,-514.85 514.85,-514.85z" />
    //     </clipPath>
    //     <clipPath id="dl_button_id2">
    //       <path d="M525.13 10.28l1377.78 0c283.17,0 514.85,231.68 514.85,514.85l0 0c0,283.17 -231.68,514.85 -514.85,514.85l-1377.78 0c-283.17,0 -514.85,-231.68 -514.85,-514.85l0 -0c0,-283.17 231.68,-514.85 514.85,-514.85z" />
    //     </clipPath>
    //     <radialGradient
    //       id="dl_button_id4"
    //       gradientUnits="userSpaceOnUse"
    //       gradientTransform="matrix(1.0076 -0 -6.45286E-06 0.610044 -9 205)"
    //       cx="1214.02"
    //       cy="525.13"
    //       r="1203.74"
    //       fx="1214.02"
    //       fy="525.13"
    //     >
    //       <stop offset="0" style={{ stopOpacity: 1, stopColor: "#003DA6" }} />
    //       <stop offset="1" style={{ stopOpacity: 1, stopColor: "#002566" }} />
    //     </radialGradient>
    //   </defs>
    //   <g id="Layer_x0020_1">
    //     <g id="_1722798783488">
    //       <path
    //         class="dl_button0"
    //         d="M525.13 10.28l1377.78 0c283.17,0 514.85,231.68 514.85,514.85l0 0c0,283.17 -231.68,514.85 -514.85,514.85l-1377.78 0c-283.17,0 -514.85,-231.68 -514.85,-514.85l0 -0c0,-283.17 231.68,-514.85 514.85,-514.85z"
    //       />
    //       <path
    //         class="dl_button6"
    //         d="M525.13 10.28l1377.78 0c283.17,0 514.85,231.68 514.85,514.85l0 0c0,283.17 -231.68,514.85 -514.85,514.85l-1377.78 0c-283.17,0 -514.85,-231.68 -514.85,-514.85l0 -0c0,-283.17 231.68,-514.85 514.85,-514.85z"
    //       />
    //       <g style={{ clipPath: "url(#dl_button_id0)" }}>
    //         <g class="moon">
    //           <g>
    //             <path
    //               class="dl_button1"
    //               d="M1339.52 -241.41c353.3,377.86 401.23,957.14 115.22,1387.19 -201.09,302.34 -544.91,501.62 -935.25,501.62 -619.89,0 -1122.41,-502.52 -1122.41,-1122.41 0,-427.09 238.55,-798.47 589.66,-988.17 446.59,-241.26 1004.94,-150.28 1352.79,221.77z"
    //             />
    //             <path
    //               class="dl_button1"
    //               d="M1099.01 -16.74c249.75,267.11 283.63,676.61 81.45,980.61 -142.15,213.73 -385.2,354.59 -661.13,354.59 -438.2,0 -793.44,-355.23 -793.44,-793.44 0,-301.91 168.63,-564.44 416.83,-698.54 315.7,-170.54 710.39,-106.24 956.29,156.77z"
    //             />
    //             <path
    //               class="dl_button1"
    //               d="M917.98 152.36c171.81,183.76 195.12,465.46 56.03,674.6 -97.79,147.03 -264.99,243.94 -454.81,243.94 -301.46,0 -545.83,-244.38 -545.83,-545.83 0,-207.69 116,-388.3 286.75,-480.55 217.18,-117.32 488.7,-73.08 657.87,107.85z"
    //             />
    //             <path
    //               class="dl_button2"
    //               d="M817.74 245.99c128.65,137.6 146.11,348.54 41.96,505.14 -73.23,110.1 -198.43,182.66 -340.57,182.66 -225.73,0 -408.72,-182.99 -408.72,-408.72 0,-155.52 86.87,-290.76 214.72,-359.84 162.62,-87.85 365.94,-54.73 492.62,80.76z"
    //             />
    //             <g class="moonDots">
    //               <path
    //                 class="dl_button3"
    //                 d="M382.53 312.15c26.46,28.3 30.05,71.69 8.63,103.9 -15.06,22.64 -40.81,37.57 -70.05,37.57 -46.43,0 -84.07,-37.64 -84.07,-84.07 0,-31.99 17.87,-59.8 44.16,-74.01 33.45,-18.07 75.27,-11.26 101.32,16.61z"
    //               />
    //               <path
    //                 class="dl_button3"
    //                 d="M429.83 386.62c19.65,21.02 22.32,53.24 6.41,77.16 -11.19,16.82 -30.31,27.9 -52.02,27.9 -34.48,0 -62.43,-27.95 -62.43,-62.43 0,-23.76 13.27,-44.41 32.8,-54.96 24.84,-13.42 55.9,-8.36 75.24,12.33z"
    //               />
    //               <path
    //                 class="dl_button3"
    //                 d="M354.7 416.06c30.98,33.13 35.18,83.92 10.1,121.63 -17.63,26.51 -47.78,43.98 -82,43.98 -54.35,0 -98.41,-44.06 -98.41,-98.41 0,-37.45 20.92,-70.01 51.7,-86.64 39.16,-21.15 88.11,-13.18 118.61,19.44z"
    //               />
    //               <path
    //                 class="dl_button3"
    //                 d="M823.81 442.27c19.65,21.02 22.32,53.24 6.41,77.16 -11.19,16.82 -30.31,27.9 -52.02,27.9 -34.48,0 -62.43,-27.95 -62.43,-62.43 0,-23.76 13.27,-44.41 32.8,-54.96 24.84,-13.42 55.9,-8.36 75.24,12.33z"
    //               />
    //               <path
    //                 class="dl_button3"
    //                 d="M830.42 521.54c13.95,14.92 15.84,37.79 4.55,54.77 -7.94,11.94 -21.51,19.81 -36.93,19.81 -24.48,0 -44.32,-19.84 -44.32,-44.32 0,-16.86 9.42,-31.53 23.28,-39.02 17.63,-9.52 39.68,-5.93 53.41,8.76z"
    //               />
    //               <path
    //                 class="dl_button3"
    //                 d="M427.73 768.78c8,8.56 9.09,21.67 2.61,31.41 -4.55,6.85 -12.34,11.36 -21.18,11.36 -14.04,0 -25.41,-11.38 -25.41,-25.41 0,-9.67 5.4,-18.08 13.35,-22.37 10.11,-5.46 22.76,-3.4 30.63,5.02z"
    //               />
    //               <path
    //                 class="dl_button3"
    //                 d="M472.96 781.15c11.96,12.79 13.58,32.4 3.9,46.96 -6.81,10.23 -18.44,16.98 -31.66,16.98 -20.98,0 -37.99,-17.01 -37.99,-37.99 0,-14.46 8.07,-27.03 19.96,-33.45 15.12,-8.17 34.02,-5.09 45.79,7.51z"
    //               />
    //               <path
    //                 class="dl_button3"
    //                 d="M771.43 526.48c5.73,6.14 6.51,15.54 1.87,22.52 -3.26,4.91 -8.85,8.14 -15.19,8.14 -10.06,0 -18.22,-8.16 -18.22,-18.22 0,-6.93 3.87,-12.96 9.57,-16.04 7.25,-3.92 16.32,-2.44 21.96,3.6z"
    //               />
    //             </g>
    //           </g>
    //         </g>
    //       </g>
    //       <path
    //         class="dl_button_str0"
    //         d="M525.13 10.28l1377.78 0c283.17,0 514.85,231.68 514.85,514.85l0 0c0,283.17 -231.68,514.85 -514.85,514.85l-1377.78 0c-283.17,0 -514.85,-231.68 -514.85,-514.85l0 -0c0,-283.17 231.68,-514.85 514.85,-514.85z"
    //       />
    //       <text x="1133.07" y="642.3" class="dl_button4 dl_button_fnt">
    //         DARK
    //       </text>
    //       <g class="stars">
    //         <polygon
    //           class="dl_button5"
    //           points="1931.59,122.2 1929,145.22 1913.73,147.66 1928.96,150.1 1931.59,177.19 1934.21,150.1 1949.44,147.66 1934.17,145.22 "
    //         />
    //         <polygon
    //           class="dl_button5"
    //           points="2245.6,404.09 2241.29,442.44 2215.85,446.51 2241.23,450.57 2245.6,495.71 2249.97,450.57 2275.34,446.51 2249.9,442.44 "
    //         />
    //         <polygon
    //           class="dl_button5"
    //           points="1489.2,219.31 1486.62,242.33 1471.35,244.77 1486.58,247.21 1489.2,274.3 1491.82,247.21 1507.05,244.77 1491.78,242.33 "
    //         />
    //         <polygon
    //           class="dl_button5"
    //           points="1444.31,763.19 1439.78,803.56 1413,807.85 1439.71,812.12 1444.31,859.63 1448.9,812.12 1475.61,807.85 1448.84,803.56 "
    //         />
    //         <polygon
    //           class="dl_button5"
    //           points="1835.19,767.91 1832.61,790.93 1817.34,793.38 1832.57,795.81 1835.19,822.91 1837.82,795.81 1853.05,793.38 1837.78,790.93 "
    //         />
    //         <polygon
    //           class="dl_button5"
    //           points="1139.3,107.4 1136.72,130.42 1121.45,132.86 1136.68,135.3 1139.3,162.39 1141.92,135.3 1157.15,132.86 1141.88,130.42 "
    //         />
    //         <polygon
    //           class="dl_button5"
    //           points="2165.45,822.17 2162.87,845.19 2147.6,847.63 2162.83,850.07 2165.45,877.16 2168.07,850.07 2183.31,847.63 2168.03,845.19 "
    //         />
    //         <polygon
    //           class="dl_button5"
    //           points="997.76,871.71 995.18,894.73 979.91,897.17 995.14,899.61 997.76,926.7 1000.38,899.61 1015.62,897.17 1000.35,894.73 "
    //         />
    //       </g>
    //       <g class="clouds">
    //         <path
    //           class="dl_button5"
    //           d="M553.32 293.18l-5.52 0 -182.73 0 0 -0.15c-4.73,0.32 -9.6,-0.44 -14.26,-2.41 -15.94,-6.73 -23.4,-25.11 -16.67,-41.05 4.12,-9.75 12.59,-16.33 22.2,-18.43 0.34,-6.7 1.83,-13.45 4.59,-19.98 7.99,-18.91 24.67,-31.49 43.43,-35.05 21.3,-4.04 42.86,4.18 56.33,20.3 13.5,-0.59 26.41,7.17 32.03,19.78 0.37,0.84 0.71,1.69 1.01,2.54 1.03,-0.35 2.08,-0.63 3.15,-0.83 7.17,-1.36 14.42,0.84 19.64,5.51 6.22,-7.04 14.6,-11.71 23.65,-13.43 18.96,-3.6 38.21,6.48 46.08,24.13 7.75,17.38 2.77,37.93 -12.03,49.8 -6.02,4.82 -13.27,7.91 -20.9,8.9l0 0.35z"
    //         />
    //         <path
    //           class="dl_button5"
    //           d="M1066.93 226.65l-4.23 0 -139.8 0 0 -0.11c-3.62,0.24 -7.34,-0.33 -10.91,-1.84 -12.2,-5.15 -17.9,-19.21 -12.75,-31.4 3.15,-7.46 9.63,-12.49 16.99,-14.1 0.26,-5.12 1.4,-10.29 3.51,-15.29 6.11,-14.47 18.88,-24.09 33.23,-26.81 16.3,-3.09 32.79,3.2 43.09,15.53 10.33,-0.45 20.2,5.48 24.5,15.13 0.29,0.64 0.54,1.29 0.77,1.95 0.79,-0.27 1.59,-0.48 2.41,-0.64 5.49,-1.04 11.03,0.64 15.03,4.22 4.76,-5.38 11.17,-8.96 18.09,-10.27 14.51,-2.75 29.23,4.96 35.26,18.46 5.93,13.3 2.11,29.02 -9.2,38.1 -4.6,3.69 -10.15,6.05 -15.99,6.81l0 0.27z"
    //         />
    //         <path
    //           class="dl_button5"
    //           d="M1070.47 874.63l-3.86 0 -127.69 0 0 -0.1c-3.3,0.22 -6.71,-0.31 -9.96,-1.68 -11.14,-4.7 -16.35,-17.54 -11.65,-28.68 2.88,-6.81 8.8,-11.41 15.51,-12.88 0.24,-4.68 1.28,-9.4 3.21,-13.96 5.58,-13.21 17.24,-22.01 30.35,-24.49 14.89,-2.82 29.95,2.92 39.36,14.19 9.44,-0.41 18.45,5.01 22.38,13.82 0.26,0.58 0.5,1.18 0.71,1.78 0.72,-0.24 1.45,-0.44 2.2,-0.58 5.01,-0.95 10.07,0.59 13.73,3.85 4.35,-4.92 10.2,-8.18 16.53,-9.38 13.25,-2.51 26.7,4.53 32.2,16.86 5.42,12.15 1.93,26.5 -8.41,34.8 -4.2,3.37 -9.27,5.52 -14.6,6.22l0 0.24z"
    //         />
    //         <path
    //           class="dl_button5"
    //           d="M434.58 703.12l-7.96 0 -263.23 0 0 -0.21c-6.81,0.46 -13.83,-0.63 -20.54,-3.47 -22.96,-9.69 -33.71,-36.17 -24.02,-59.12 5.93,-14.04 18.14,-23.52 31.98,-26.55 0.49,-9.65 2.64,-19.37 6.61,-28.78 11.5,-27.24 35.54,-45.36 62.57,-50.48 30.69,-5.82 61.74,6.02 81.14,29.24 19.45,-0.84 38.04,10.33 46.14,28.5 0.54,1.21 1.03,2.43 1.46,3.66 1.48,-0.5 3,-0.9 4.54,-1.2 10.33,-1.96 20.77,1.21 28.3,7.94 8.96,-10.13 21.03,-16.87 34.07,-19.34 27.32,-5.18 55.04,9.33 66.38,34.76 11.17,25.04 3.98,54.64 -17.33,71.73 -8.66,6.95 -19.11,11.39 -30.1,12.82l0 0.5z"
    //         />
    //       </g>
    //       <text x="300" y="642.3" class="dl_button9 dl_button_fnt">
    //         LIGHT
    //       </text>
    //       <path
    //         class="clouds dl_button5"
    //         d="M1691.84 448.59l-11.45 0 -378.74 0 0 -0.3c-9.8,0.65 -19.89,-0.91 -29.55,-4.99 -33.04,-13.95 -48.5,-52.04 -34.55,-85.07 8.53,-20.21 26.1,-33.85 46.02,-38.2 0.7,-13.88 3.79,-27.87 9.51,-41.41 16.55,-39.2 51.14,-65.27 90.02,-72.64 44.16,-8.37 88.84,8.66 116.75,42.07 27.98,-1.22 54.73,14.86 66.39,41 0.78,1.74 1.47,3.5 2.1,5.27 2.13,-0.72 4.31,-1.3 6.53,-1.73 14.86,-2.82 29.88,1.74 40.71,11.43 12.89,-14.58 30.25,-24.28 49.02,-27.83 39.31,-7.45 79.19,13.43 95.51,50.01 16.07,36.03 5.73,78.61 -24.93,103.21 -12.47,10 -27.5,16.39 -43.32,18.44l0 0.72z"
    //       />
    //     </g>
    //   </g>
    // </svg>
  );
}

export default LightDarkButton;
