import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './RadialProgress.scss'

const RadialProgress = (props) => {
    const [night, setNight] = useState(false)
    const [config, setConfig] = useState({
        classColor: "primary-gradient",
        cssColor: "#0471C4",
        value: 70,
        label: "Label"
    })
    const [events, setEvents] = useState({
        Status: {},
        Connection: {}
    })

    useEffect(async () => {
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")
        if (hubId) {
            hubId = hubId[0] + "_" + hubId[1]
        }
        let Events = props?.eventReducer?.deviceEvents

        if (Events.deviceId === props?.config?.deviceId) {
            events1.Status = Events
            setEvents(events1)
        } else if (Events.deviceId === hubId) {
            events1.Connection = Events
            setEvents(events1)
        }
    }, [props.eventReducer.deviceEvents])

    useEffect(() => {
        if (props?.config?.param in events?.Status) {
            let config1 = { ...config }
            if (events.Status[props.config.param] !== null) {
                config1.value = events.Status[props.config.param]
            } else {
                config1.value = 0
            }
            setConfig(config1)
        }
    }, [events.Status])
    useEffect(() => {
        setNight(props?.night)
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                    config1.classColor = "";
                } else {
                    config1.classColor = props.config.color;
                    config1.cssColor = "";
                }
            }
            if ("label" in props.config && props.config?.label) {
                config1.label = props.config.label
            } else {
                config1.label = "Label"

            }
            setConfig(config1)
        }
    }, [props])
    useEffect(() => {
        setNight(props?.night)
    }, [props])

    return (
        <svg viewBox="0 0 100 100 " width="100%" height="100%">
            <g className={night ? "active2" : ""}>
                <circle className="progressRing" r="40" cx="50" cy="50" fill="transparent"></circle>
                <circle className="progressRingInner" r="40" cx="50" cy="50" fill="transparent" stroke={config.cssColor} strokeDasharray="250" strokeLinecap="round" style={{ strokeDashoffset: 250 * (100 - config.value) / 100 }}></circle>
                {/* <text className="progressPer" x="50%" y="50.7%" textAnchor="middle">{this.state.levelList[this.state.levelIndex].eff.GE <= 100 ? this.state.levelList[this.state.levelIndex].eff.GE.toFixed(1) : 100}%</text> */}
                <text fill={config.cssColor} className="progressPer" x="50%" y="50.7%" textAnchor="middle">{config.value}%</text>
                <text fill={config.cssColor} className="progressText" x="50%" y="60%" textAnchor="middle">{config.label}</text>
            </g>
        </svg>
    )
}

const mapStateToProps = (state) => ({
    eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(RadialProgress)
