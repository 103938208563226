import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { Tab } from "@material-ui/core";
import "./table.scss"
export default function Table(props) {
    let style = null
    if("height" in props){
        style = {...style,height: props.height}
    }
    if("style" in props){
        style = {...style, ...props.style}
    }
    return (
        <div className="myTable" style={style}>
            <table>
                {props.children}
            </table>
        </div>
    );
}
export function THead(props) {
    return (
        <thead>
            <tr>
                {props.children}
            </tr>
        </thead>
    );
}
export function TBody(props) {
    return (
        <tbody>
            {props.children}
        </tbody>
    );
}
export function Th(props) {
    let style = {};
    if ("width" in props) {
        style["width"] = props.width + "px";
    }
    if ("maxWidth" in props) {
        style["maxWidth"] = props.maxWidth + "px";
    }

    return (
        <th className={"className" in props?"p-1 "+props.className:"p-1"} style={style}>
            <div className="py-1 px-2">
                {props.children}
            </div>
        </th>
    );
}
export function Tr(props) {
    return (
        <tr>
            {props.children}
        </tr>
    );
}
export function Td(props) {
    let style = {};
    if ("width" in props) {
        style["width"] = props.width + "px";
    }
    if ("maxWidth" in props) {
        style["maxWidth"] = props.maxWidth + "px";
    }
    return (
        <td  className={"className" in props?props.className:""} style={style}>
            {"label" in props ?
                <div className="row">
                    <div className="px-3 label">
                        {props.label}
                    </div>
                    <div className="col">
                        {props.children}
                    </div>
                </div>
                : props.children
            }
        </td>
    );
}