import React, { useEffect, useState } from 'react'
import AddHubForm from '../../components/addHubForm/addHubForm'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../components/card/card'
import FullLoader from '../../components/loader/fullPageLoader'
import Mypopup from '../../components/popup/mypopup'
import { useHistory } from "react-router-dom";
import MyDropDown, { MyDropDownBody } from '../../components/dropdown/dropdown'
import HubInfoPopup from '../../components/hubInfoPopup/HubInfoPopup'

import { connect } from 'react-redux'
import moment from 'moment'
import { lastEvent } from '../../services/reducers/lastEvetReducer'
import store from '../../services/utilities/store'
import AccessValidation from '../accessValidation/accessValidation'
import Input from '../input/input'
import { getResource, getResource2 } from '../../services/utilities/events'
import CardLoader from '../loader/cardLoader'



const HubContainer = (props) => {

    const [showInfo, setShowInfo] = useState(null)
    // const [hover, setHover] = useState(false)
    const history = useHistory()
    const [popup, setPopup] = useState(null)
    const [res1, setRes1] = useState([])
    const [filterList, setFilterList] = useState([])

    const [isLiveEvent, setIsLiveEvent] = useState(false)
    const [addHubCreatePopup, setAddHubCreatePopup] = useState(null)
    const [hubInfo, setHubInfo] = useState(false)
    const [access, setAccess] = useState(null)
    const [levelManagement, setLevelManagement] = useState(false)
    const [addHubpopup, setAddHubPopup] = useState(false)
    const [result, setResult] = useState()
    const [imageLoader, setImageLaoder] = useState(true)

    const [pagination, setPagination] = useState({
        pages: 0,
        currentPage: 1,
        limit: 12
    })

    const [loader, setLoader] = useState(false)

    const org1 = props.org
    const orgId = org1._id
    const organizationId = props.org
    const Idlength = orgId.split("_").length
    const orgName = orgId.split("_")[0]
    useEffect(() => {
        if (access) {
            if (access?.length) {
                if (access.includes("1007") || access.includes("admin")) {
                    setLevelManagement(true)
                }
            } else {
                setLevelManagement(false)
            }
        }
    }, [access])
    const accessHandler = (e) => {
        setAccess(e)
    }
    useEffect(async () => {
        setImageLaoder(true)
        let res2 = [...res1]
        // if (props.res1.length > 0) {
        const org = props?.res1.length && props?.res1[0]?.levelId.split("_")[0]
        let branch = null
        if ("varification" in props.userInfo) {
            props.userInfo.varification.hierarchy.map(level => {
                if (org && org.match(level._id)) {
                    if (level.branches.length) {
                        branch = true
                        level.branches.map(branch => {
                            props.res1.map((res) => {
                                if (res.levelId.match(branch.levelId)) {
                                    res2.push(res)
                                }
                            })
                        })
                    }
                }
            })
            if (!branch) {
                res2 = [...props.res1]

                // props.res1.map((res) => {
                //     res2.push(res)
                // })
            }
        }
        // }
        let image = null
        await Promise.all(res2.map(async (defaultHub, key) => {
            await Promise.all(props?.configration.configration.configuration?.Hub.map(async hub => {
                if (defaultHub.type === hub.type) {
                    if ("icon" in hub) {
                        if (hub.icon.endsWith(".svg")) {
                            image = await getResource(hub.icon)
                            image = { icon: image, type: 'svg' }
                        }
                        res2[key]["icon"] = image
                    } else {
                        res2[key]["icon"] = null
                    }
                }
            }))
        })
        )
        setRes1(res2)
        setFilterList(res2)
        let pages = res2.length / pagination.limit
        let pagesFix = Math.ceil(pages)
        setPagination({
            ...pagination,
            pages: pagesFix
        })
        setIsLiveEvent(true)
        setImageLaoder(false)
    }, [props.res1])

    const eventHandler = () => {
        let res = [...res1]
        if (res1.length > 0) {
            let Events = props?.lastEvent?.lastEvent?.devices
            res1.map((item, index) => {
                Events?.map((events) => {
                    if (events?.events[1]?.deviceId === item._id) {
                        if (events.events[1].value === 1) {
                            item["events"] = events.events[1]
                            setFilterList(res)
                        } else {
                            item["events"] = events.events[1]
                            setFilterList(res)
                        }
                    }
                })
            })
        }
    }

    useEffect(() => {
        eventHandler()
    }, [props.lastEvent, isLiveEvent])
    const popupOnchangeHandler = (e) => {

        setResult(e)
        if (!e.statusCode) {
            setPopup(null)
            setAddHubCreatePopup(["Success!", "Hub created successfully.", "success"])
        }
        else {
            setAddHubCreatePopup(["Failure!", "Hub already exist.", "danger"])
        }
    }
    const onChangeHandler1 = (e) => {
        let filterDevices = []
        let search1 = e.target.value
        if (res1.length) {
            filterDevices = res1.filter(item => {
                return search1 !== "" ? item.name.toLowerCase().includes(search1.toLowerCase()) : item;
            })
        }
        let pages = filterDevices.length / pagination.limit
        let pagesFix = Math.ceil(pages)
        setPagination({
            ...pagination,
            pages: pagesFix,
            currentPage: 1
        })
        setFilterList(filterDevices)
    }

    const imgReturnFunc = (data, value) => {
        if (data) {
            let img = data
            if (img.type === "svg") {
                return "data:image/svg+xml;base64," + btoa(img.icon)
                // return img.icon
            }
            else {
                return data.icon
            }
        }
    }
    // useEffect(async () => {
    //     let newEvents = props?.eventReducer?.deviceEvents
    //     let res1 = { ...props.lastEvent.lastEvent }
    //     if (res1?.devices?.length > 0) {
    //         res1.devices.map((item, index) => {
    //             if (newEvents?.deviceId === item.events[1]?.deviceId) {
    //                 item.events[1] = newEvents
    //                 return
    //             }
    //         })
    //         store.dispatch({
    //             type: "Get Events",
    //             payload: res1
    //         })
    //     }
    // }, [props.eventReducer.deviceEvents])

    // useEffect(async () => {
    //     let Events = props?.eventReducer?.deviceEvents
    //     let res = [...res1]
    //     if (res1.length > 0) {
    //         res1.map((item, index) => {
    //             if (Events.deviceId === item._id) {
    //                 item["events"] = Events
    //                 setRes1(res)
    //             }
    //         })
    //     }
    // }, [props.eventReducer.deviceEvents])

    return (
        <>
            <AccessValidation levelId={organizationId._id} onAccessHandler={(e) => accessHandler(e)} />
            {/* {res1.length ? */}
            {Idlength !== 1 ?
                <Card className={(res1.length ? "" : "overflow-hidden ") + "mt-3"}>
                    {imageLoader ? <CardLoader /> :
                        res1.length ?
                            <>
                                <CardHeader>
                                    <div className='row'>
                                        <div className='col-md col-12 d-flex  align-items-center'>
                                            <Heading size={1} className="mr-3" >Hubs</Heading>
                                        </div>
                                        <div className='col'>
                                            <div className='d-flex justify-content-end'>
                                                <div className='pt-md-0 pt-3 px-2'>
                                                    <Input name="search" label="Search Hub" onChange={(e) => onChangeHandler1(e)} />
                                                </div>
                                                {levelManagement ?

                                                    Idlength === 1 ?
                                                        null
                                                        : <>
                                                            <ButtonContainer>
                                                                <Button buttonResult={() => setPopup("Hello")}>Add Hub</Button>
                                                            </ButtonContainer>
                                                        </>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="row m-0 p-2" >
                                    {imageLoader ? <CardLoader /> :
                                        filterList.length ? pagination && filterList.map((item, index) =>
                                            (pagination.currentPage * pagination.limit) >= (index + 1) && ((pagination.currentPage - 1) * pagination.limit) <= (index) ?
                                                <div className="col-12 col-md-6 col-lg-4 p-1" key={index} id={item._id}>
                                                    <InnerCard>
                                                        <CardBody>
                                                            <div className='p-3 d-flex'>
                                                                {item.icon !== null ?
                                                                    <img className={"rounded-circle-px p-1  primary-gradient"} style={{ objectFit: 'contain', width: "50px" }} src={imgReturnFunc(item.icon)} />
                                                                    :
                                                                    <i className="fa fa-university primary-gradient rounded-circle-px text-white p-3 mr-3" onClick={() => history.push({ pathname: '/hub/' + orgId + '/' + item._id, data: item })}></i>
                                                                }
                                                                <div className='col' onClick={() => history.push({ pathname: '/hub/' + orgId + '/' + item._id, data: item })}>
                                                                    <div className="h5 mb-0 text-primary mt-0">{item.name}</div>
                                                                    <div className="text-muted text-uppercase font-weight-bold font-xs">{item.type}</div>
                                                                </div>
                                                                <MyDropDown openToggle={(e) => { let res = [...res1]; res[index].hover = e; setFilterList(res) }}>
                                                                    <button className='btn rounded-circle-pxl deviceBtn1' style={{ height: 40, borderRadius: "60%" }}><i className={'my-wifi  ' + (item.events ? item.events.value ? 'on' : 'off' : 'off')}></i></button>
                                                                    <MyDropDownBody open={item.hover} right style={{ width: "230px", padding: "5px" }}>
                                                                        {item.events ? item.events.value ? ("Connected Since: " + moment(item.events.created).format("LL LTS")) : ("Disconnected at: " + moment(item.events.created).format("LL LTS")) : "Disconnected at:  00:00:00 AM 00-00-0000"}
                                                                    </MyDropDownBody>
                                                                </MyDropDown>
                                                                <i className="fa fa-info bg-light rounded-circle-px text-info px-3 py-3  align-self-center" onClick={() => { setShowInfo(index) }}> </i>
                                                            </div>
                                                        </CardBody>
                                                    </InnerCard>
                                                </div> : null
                                        ) :
                                            <div className="px-2">
                                                not found
                                            </div>
                                        // : <Heading size={2} className="mr-3" >There is no hub</Heading>
                                    }
                                    {pagination &&
                                        <div className='d-flex w-100 justify-content-end'>
                                            <nav aria-label="Page navigation">
                                                <ul className="pagination pagination-sm justify-content-center px-2 pt-3 ">
                                                    {pagination.currentPage > 1 ?
                                                        <li className={"page-item px-1"}>
                                                            <button className="btn btn-light rounded-circle-px" onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>Previous</button>
                                                        </li>
                                                        : <li className={"page-item px-1 disabled"}>
                                                            <button className="btn btn-light rounded-circle-px" tabIndex="-1">Previous</button>
                                                        </li>
                                                    }
                                                    <li className="page-item px-1">
                                                        <button onClick={() => setPagination({ ...pagination, currentPage: 1 })} className={"btn btn-light rounded-circle-px" + (pagination.currentPage === 1 ? " active" : "")}>{1}</button>
                                                    </li>
                                                    {pagination.currentPage > 3 && <div className='px-2'>...</div>}
                                                    {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo =>
                                                        (
                                                            (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                                                            || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                                                            || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                                                        )
                                                            ?
                                                            <li key={pageNo} className="page-item px-1">
                                                                <button onClick={() => setPagination({ ...pagination, currentPage: pageNo })} className={"btn btn-light rounded-circle-px" + (pagination.currentPage === pageNo ? " active" : "")}>{pageNo}</button>
                                                            </li>
                                                            : null
                                                    )}
                                                    {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}
                                                    {pagination.pages > 1 &&
                                                        <li className="page-item px-1">
                                                            <button onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} className={"btn btn-light rounded-circle-px" + (pagination.currentPage === pagination.pages ? " active" : "")}>{pagination.pages}</button>
                                                        </li>
                                                    }
                                                    {pagination.currentPage < pagination.pages ?
                                                        <li className="page-item px-1">
                                                            <button className="btn btn-light rounded-circle-px" onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>Next</button>
                                                        </li>
                                                        : <li className="page-item px-1 disabled">
                                                            <button className="btn btn-light rounded-circle-px" tabIndex="-1">Next</button>
                                                        </li>
                                                    }
                                                </ul>
                                            </nav>
                                        </div>

                                    }






                                </CardBody>
                            </>
                            :
                            levelManagement ?
                                Idlength === 1 ?
                                    null : <button className='p-3 btn btn-light btn-lg w-100' style={{ color: "#1b75bc" }} onClick={() => setPopup("Hello")}><i className='fa fa-plus'></i> Add Hub</button>
                                : null
                    }
                </Card> : null}
            {/* : null} */}
            {
                popup ? <Mypopup title={"Add Hub"} onClose={() => { setPopup(null) }}>
                    <AddHubForm org={org1} onChange={(e) => popupOnchangeHandler(e)} />
                </Mypopup> : null}
            {showInfo !== null ?
                <HubInfoPopup res={result ? result : res1[showInfo]} onClose={() => result ? window.location.reload() : setShowInfo(null)} />
                : null}
            {addHubCreatePopup ? <Mypopup title={addHubCreatePopup[0]} type={addHubCreatePopup[2]} onClose={(e) => {
                setAddHubCreatePopup(null);
            }}>
                <p className="p-3" > {addHubCreatePopup[1]}</p>
                <div className="d-flex justify-content-center p-3">
                    {addHubCreatePopup[2] === "danger" ?
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setAddHubCreatePopup(null);
                        }}>Ok</button>
                        :
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setAddHubCreatePopup(null); setShowInfo(true)
                        }}>Ok</button>}
                </div>
            </Mypopup >
                : null}

        </>

    )
}
const mapStateToProps = (state) => ({
    eventReducer: state.eventReducer,
    lastEvent: state.lastEvent,
    userInfo: state.userInfo,
    configration: state.configration

});
export default connect(mapStateToProps, null)(HubContainer)
