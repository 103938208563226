import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import "./signal.scss"

function Signal(props) {
    const [night, setNight] = useState(false)
    const [config, setConfig] = useState({
        cssColor: "#DBB240",
        value: 0,
        strengthLevel: [1,21,41,61,81]
    })
    const [events, setEvents] = useState({
        Status: {},
        Connection: {}
    })

    useEffect(() => {
        if (props?.config) {
            let config1 = { ...config }
            if ("strengthLevel" in props.config && props.config?.strengthLevel) {
                config1.strengthLevel = [...props.config?.strengthLevel]
            }
            setConfig(config1)
        }
    }, [props.config])

    useEffect(async () => {
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")
        if (hubId) {
            hubId = hubId[0] + "_" + hubId[1]
        }
        let Events = props?.eventReducer?.deviceEvents

        if (Events.deviceId === props?.config?.deviceId) {
            events1.Status = Events
            setEvents(events1)
        } else if (Events.deviceId === hubId) {
            events1.Connection = Events
            setEvents(events1)
        }
    }, [props.eventReducer.deviceEvents])

    useEffect(() => {
        if (props?.config?.param in events?.Status) {
            let config1 = { ...config }
            if (events.Status[props.config.param] !== null) {
                config1.value = events.Status[props.config.param]
            } else {
                config1.value = 0
            }
            setConfig(config1)
        }
    }, [events.Status])
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
        // preserveAspectRatio="none" 
        xmlSpace="preserve" 
        version="1.1"
        width="100%"
        height="100%"
        viewBox="0 0 120 120"
        xmlnsXlink="http://www.w3.org/1999/xlink" >
        <g>
            {/* <rect className={'signal_strength'+(config.value === 0?' offline':'')} rx="5" x="10" y="90" width="13" height="20"/>
            <rect className={'signal_strength'+(config.value === 0?' offline':'')} rx="5" x="31.75" y="70" width="13" height="40"/>
            <rect className={'signal_strength'+(config.value === 0?' offline':'')} rx="5" x="53.5" y="50" width="13" height="60"/>
            <rect className={'signal_strength'+(config.value === 0?' offline':'')} rx="5" x="75.25" y="30" width="13" height="80"/>
            <rect className={'signal_strength'+(config.value === 0?' offline':'')} rx="5" x="97" y="10" width="13" height="100"/> */}
            <rect className={'signal_strength'+((config.value >= config.strengthLevel[0])?' active':config.value === 0 || config.value === null?' offline': '')} rx="5" x="10" y="90" width="13" height="20"/>
            <rect className={'signal_strength'+((config.value >= config.strengthLevel[1])?' active':config.value === 0 || config.value === null?' offline': '')} rx="5" x="31.75" y="70" width="13" height="40"/>
            <rect className={'signal_strength'+((config.value >= config.strengthLevel[2])?' active':config.value === 0 || config.value === null?' offline': '')} rx="5" x="53.5" y="50" width="13" height="60"/>
            <rect className={'signal_strength'+((config.value >= config.strengthLevel[3])?' active':config.value === 0 || config.value === null?' offline': '')} rx="5" x="75.25" y="30" width="13" height="80"/>
            <rect className={'signal_strength'+((config.value >= config.strengthLevel[4])?' active':config.value === 0 || config.value === null?' offline': '')} rx="5" x="97" y="10" width="13" height="100"/>
            {config.value === 0 || config.value === null?
                <rect className={'signal_strength'} rx="5" x="0" y="-15" width="13" height="155"/>
            :null}
        </g>
    </svg>
  )
}

const mapStateToProps = (state) => ({
    eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(Signal)