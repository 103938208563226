
const INITIAL_STATE = {}

const notifyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_NOTIFICATION":
            return { ...state, notifyEvents: action.payload };
        default:
            return state
    }
}
export default notifyReducer