import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Mypopup from '../../components/popup/mypopup'

const UnAuthorized = (props) => {
    let history = useHistory()

    return (
        <Mypopup title={"Alert"} noClose={'noClose'}>
            <div className='d-flex py-3 flex-column align-items-center'
                style={{
                    gap: "10px"
                }}
            >
                <h3 className='text-center'>your session has been expired, <br />
                    <b>Please Signin Again!</b></h3>

                <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={() => {
                    props.onChange(false);
                    history.push("/login")
                }
                }>OK</button>
            </div>
        </Mypopup>
    )
}

export default UnAuthorized