// Import Libraries
import { Redirect, HashRouter, Route, Switch } from 'react-router-dom';

// Import Resources
import { checkSignIn } from './services/utilities/users'
import "./_App.scss"
import Login from "./containers/users/users";
import DefaultLayout from "./containers/default-layout/defaultLayout";
import Notfound from './Noofound';
function App() {

  // let levelId = useLocation()
  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('./serviceWorker.js')
  //       .then(registration => {
  //       })
  //       .catch(error => {
  //       });
  //   }else{
  //   }
  // }, []);
  //   useEffect(() => {
  // let use
  //   },[])

  var information = localStorage.getItem("information");
  var verify = localStorage.getItem("verify")
  return (
    <>
      <HashRouter>
        <Switch>
          <Route exact
            path="/login"
            name="Login Page"
            component={Login}>
            {checkSignIn() === true ?
              <Redirect to='/' />
              : null}
          </Route>
          <Route
            exact
            path="/forgot-password"
            name="forgot"
            component={Login} />
          <Route
            exact
            path="/forgot-password/:id"
            name="forgot"
            component={Login} />
          <Route exact
            path="/signup"
            name="Signup Page"
            component={Login}>
            {information ? <Redirect to="/verify-email" /> : null}
          </Route>
          <Route exact
            path="/verify-email"
            name="verification Page"
            component={Login}>
            {information && verify ? <Redirect to="/create-password" /> : null}
          </Route>
          <Route exact
            path="/create-password"
            name="password page"
            component={Login}></Route>
          <Route path="/error_500"
            name="error"
            exact={true}
            component={Notfound}
          ></Route>
          <Route
            path="/"
            // exact={true}
            name="Dashboard"
            component={DefaultLayout}>
            {!checkSignIn() === true ? <Redirect to='/login' /> : null}

          </Route>
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
