import React, { useEffect, useState } from 'react'
import Input from '../input/input'
import Card, { CardBody } from '../card/card'
import { Button, ButtonContainer } from '../arraybutton/arraybutton'
import { RoleList } from '../../services/config/generic'
import { getCookie } from '../../services/utilities/users'
import { CreateAdminRoles, EditAdminRoles } from '../../services/utilities/events'
import CardLoader from '../loader/cardLoader'
import { Popup } from '@amcharts/amcharts4/core'
import Mypopup from '../popup/mypopup'
import { connect } from 'react-redux'


const AddRolePopup = (props) => {
    const [checkRoleName, setCheckRoleName] = useState(true)
    const [checkRoleAccess, setCheckRoleAccess] = useState(true)
    const [rolesLoader, setRolesLoader] = useState(false)
    const [popup, setPopup] = useState(null)

    let Array = props?.configration?.configration?.configuration?.access
    const [rules, setRules] = useState({
        name: "",
        createdBy: "",
        access: []
    })
    useEffect(() => {
        if (props?.data?._id) {
            // if ("role" in props.data) {
            setRules({
                name: props.data.name,
                access: props.data.access,
                _id: props.data._id
            })
            // }
        }
    }, [])
    const onChangeHandler = (e) => {
        let roles1 = { ...rules }
        if ("name" in e) {
            roles1[e.name] = e.target.value
            setCheckRoleName(true)
        } else {
            if (e.target.checked) {
                roles1.access.push(e.target.name)
            } else {
                roles1.access.map((acc, key) => {
                    if (e.target.name === acc) {
                        roles1.access.splice(key, 1)
                    }
                })
            }
            setCheckRoleAccess(true)
        }
        setRules(roles1)
    }
    const checkRole = () => {
        if (!rules.name) {
            setCheckRoleName(false)
            return false
        } else {
            setCheckRoleName(true)
            return true
        }
    }
    const checkAccess = () => {
        if (!rules.access.length) {
            setCheckRoleAccess(false)
            return false
        } else {
            setCheckRoleAccess(true)
            return true
        }
    }
    const AddRules = async () => {
        if (checkRole()) {
            if (checkAccess()) {
                setRolesLoader(true)
                let rules1 = { ...rules }
                rules1["createdBy"] = getCookie("email")
                if (rules1?._id) {
                    return EditAdminRoles(rules1, rules1?._id).then(async res => {
                        let result = res
                        if (result) {
                            if (!result.statusCode) {
                                props.onResPopup(["Success!", "Role Updated successfully.", "success"])
                                setRolesLoader(false)
                                props.onAddRole()
                            }
                            else {
                                props.onResPopup(["Failure!", "Role already exist.", "danger"])
                                setRolesLoader(false)
                                props.onAddRole()
                            }
                        }

                    })
                } else {
                    return CreateAdminRoles(rules1).then(async res => {
                        let result = res
                        if (result) {
                            if (!result.statusCode) {
                                props.onResPopup(["Success!", "Role created successfully.", "success"])
                                setRolesLoader(false)
                                props.onAddRole()
                            }
                            else {
                                props.onResPopup(["Failure!", "Role already exist.", "danger"])
                                setRolesLoader(false)
                                props.onAddRole()
                            }
                        }

                    })
                }

            }
        } else {
            setCheckRoleAccess(true)
        }
    }

    return (
        <div className='m-3'>
            {/* <Card> */}
            <Input type="text" name="name" label="Role Name" defaultValue={rules?.name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Role Name", status: !checkRoleName }} />

            <div className='row mt-3 px-3'>
                {Array.map((role) =>
                    <div className='col-6 '>
                        <label>
                            <input className='mr-1' type="checkbox" name={role._id} checked={rules.access.includes(role._id)} onChange={(e) => { onChangeHandler(e) }} />
                            {/* {rules.access.length ?
                                rules.access.map(access =>
                                    access === role.label ?
                                        <input className='mr-1' type="checkbox" checked={access} name={role.value} onChange={(e) => { onChangeHandler(e) }} />

                                )
                                :
                                <input className='mr-1' type="checkbox" name={role.value} onChange={(e) => { onChangeHandler(e) }} />
                            } */}
                            {role.label}
                        </label>
                    </div>
                )}
            </div>
            {!checkRoleAccess ? <p style={{ color: "red" }}> please select at least 1 tag </p> : null}
            {/* </Card> */}
            {!rolesLoader ? <ButtonContainer>
                <Button buttonResult={() => AddRules()}>{rules._id ? "Edit" : "Add"}</Button>
            </ButtonContainer> : <CardLoader />}
        </div>
    )
}

const mapStateToProps = (state) => ({
    configration: state.configration,
});
export default connect(mapStateToProps, null)(AddRolePopup)
// export default AddRolePopup