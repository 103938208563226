import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import './map.scss';
import styles from './mapStyles.json';
import { GoogleMap, Marker, Polygon, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { ServerBaseAddress } from '../../../../services/config/serverAddresses';
import { getMultiDeviceHistory, getResource } from '../../../../services/utilities/events';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getRandomNumber, hslToHex, idGenerator, todayDate, utcDate } from '../../../../functions/functions';
import DefaultFooter from '../../../default-layout/defaultFooter';
import Card, { CardBody, CardHeader, Heading } from '../../../../components/card/card';
import AQIMarker from '../../../../components/aqiMarker/aqiMarker';
import { Button, ButtonContainer } from '../../../../components/arraybutton/arraybutton';
import Input from '../../../../components/input/input';
import Select from '../../../../components/select/select';
import CardLoader from '../../../../components/loader/cardLoader';

const containerStyle = {
    width: '100%',
    minHeight: '100px',
    height: '100%',
    // borderRadius: '15px',
};

function QuettaMap(props) {
    const [theme, setTheme] = useState(props.themeMode.isLight)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA9osJ2QBSR6f9oBEZULavTAJzZxVccQMo"
    });

    const quettaCoordinates = [
        { lat: 30.292689, lng: 66.898526 },
        { lat: 30.332997, lng: 66.908825 },
        { lat: 30.332033, lng: 66.949292 },
        { lat: 30.330034, lng: 66.950711 },
        { lat: 30.306658, lng: 66.947659 },
        { lat: 30.303485, lng: 66.950721 },
        { lat: 30.298550, lng: 66.950313 },
        { lat: 30.298550, lng: 66.950313 },
        { lat: 30.293791, lng: 66.955825 },
        { lat: 30.287974, lng: 66.983996 },
        { lat: 30.295377442058975, lng: 67.00890115998311 },
        { lat: 30.292290, lng: 67.024855 },
        { lat: 30.297033, lng: 67.060561 },
        { lat: 30.291437, lng: 67.069144 },
        { lat: 30.271462, lng: 67.081289 },
        { lat: 30.269312, lng: 67.108926 },
        { lat: 30.216407, lng: 67.155790 },
        { lat: 30.213292, lng: 67.138795 },
        { lat: 30.215962, lng: 67.096266 },
        { lat: 30.194488, lng: 67.074852 },
        { lat: 30.198011, lng: 67.056484 },
        { lat: 30.168556, lng: 67.043824 },
        { lat: 30.159095, lng: 67.044811 },
        { lat: 30.156089, lng: 67.044210 },
        { lat: 30.147406, lng: 67.035670 },
        { lat: 30.147072, lng: 67.032237 },
        { lat: 30.129591, lng: 67.021594 },
        { lat: 30.119532, lng: 67.020993 },
        { lat: 30.116599, lng: 67.021551 },
        { lat: 30.113815, lng: 67.021079 },
        { lat: 30.107170, lng: 67.015543 },
        { lat: 30.107170, lng: 67.015543 },
        { lat: 30.107244, lng: 67.015671 },
        { lat: 30.106390, lng: 67.014212 },
        { lat: 30.050170, lng: 66.999472 },
        { lat: 30.035459, lng: 67.001746 },
        { lat: 30.014057, lng: 66.999429 },
        { lat: 30.005733, lng: 66.977370 },
        { lat: 30.011530, lng: 66.941965 },
        { lat: 30.011344, lng: 66.922053 },
        { lat: 30.014986, lng: 66.898106 },
        { lat: 30.042184, lng: 66.901754 },
        { lat: 30.052251, lng: 66.908749 },
        { lat: 30.103128, lng: 66.917675 },
        { lat: 30.117755, lng: 66.932996 },
        { lat: 30.117588, lng: 66.932938 },
        { lat: 30.127165, lng: 66.935985 },
        { lat: 30.128204, lng: 66.936500 },
        { lat: 30.129689, lng: 66.937916 },
        { lat: 30.205304, lng: 66.946499 },
        { lat: 30.209829, lng: 66.944568 },
        { lat: 30.217098, lng: 66.941864 },
        { lat: 30.243503, lng: 66.897697 },
        { lat: 30.255426, lng: 66.890170 },
        { lat: 30.257030, lng: 66.887189 },
        { lat: 30.263156, lng: 66.883976 },
        { lat: 30.268986, lng: 66.882549 },
        { lat: 30.276357, lng: 66.881538 },
        { lat: 30.287337, lng: 66.885741 },
        { lat: 30.289954, lng: 66.888032 },
        { lat: 30.289876, lng: 66.887924 },
        { lat: 30.292689, lng: 66.898526 },

        // {lat: 30.276357, lng: 66.881538},
        // { lat: 30.186805, lng: 66.998806 }, // Quetta Airport
        // { lat: 30.1798, lng: 66.9750 },    // Center of Quetta
        // { lat: 30.1632, lng: 66.9922 },    // Example coordinate
        // Add more coordinates as needed to define the polyline
        // Example: { lat: ..., lng: ... },
    ];
    const mapId = idGenerator() + '_map';
    const [map, setMap] = useState(null);
    const [isThemeLoad, setIsThemeLoad] = useState(false)
    const [isLoad, setIsLoad] = useState(false);
    const [mapConfig, setMapConfig] = useState({
        coordinates: { lat: 30.1798, lng: 66.9750 }, // Default coordinates for Lahore
        label: '',
        zoom: 12,
        autoTrack: false,
        live: false,
        history: false,
        filter: false,
        type: [],
    });

    const [markers, setMarkers] = useState([]);
    const [filter, setFilter] = useState('all');
    const [isLive, setIsLive] = useState(true);
    const [fullScreen, setFullScreen] = useState(false)
    const [state, setState] = useState({
        length: 1,
    })


    const onLoad = useCallback(function callback(theme1, map) {
        map.setOptions({
            styles: theme1 ? styles.light : styles.dark,
            // mapTypeControl: false,
            streetViewControl: false,
        })
        setMap(map)
    }, [])

    useEffect(() => {
        setIsThemeLoad(true)
        setTimeout(() => {
            setIsThemeLoad(false)
        }, 1000)
        setTheme(props.themeMode.isLight)
    }, [props.themeMode])
    useEffect(() => {
        document.addEventListener('fullscreenchange', onFullScreenChange);
        let state1 = state;
        if ('length' in props) {
            state1.length = state.length;
        }
        setState(state1);
        return () => {
            document.removeEventListener('fullscreenchange', onFullScreenChange);
        };
    }, []);

    const onFullScreenChange = () => {
        if (document.fullscreenElement) {
            // setFullScreen(true)
        } else {
            setFullScreen(false);
        }
    };

    const fullScreens = () => {
        var elem = document.getElementById(mapId);
        if (!fullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    useEffect(() => {
        let config = props?.config;
        let mapConfig1 = { ...mapConfig };
        mapConfig1.label = config?.label || '';
        if (config?.mapSetting) {
            if (config.mapSetting?.mapCenter) {
                if (config.mapSetting.mapCenter?.lat && config.mapSetting.mapCenter?.lng) {
                    mapConfig1.coordinates = {
                        lat: parseFloat(config.mapSetting.mapCenter?.lat),
                        lng: parseFloat(config.mapSetting.mapCenter?.lng),
                    };
                }
                if (config.mapSetting.mapCenter?.zoom) {
                    mapConfig1.zoom = parseInt(config.mapSetting.mapCenter?.zoom);
                }
            }
        }
        setMapConfig(mapConfig1);
    }, [
        props?.config?.label,
        props?.config?.deviceId,
        props?.config?.mapSetting,
        props?.config?.dataMode,
    ]);

    useEffect(() => {
        markerUpdate(markers)
    }, [props.lastEvent])

    useEffect(async () => {
        setIsLoad(true);
        const markers1 = [];
        if (props.lastEvent && props.organization && 'hubId' in props.config) {
            const { organization, config, configration, lastEvent } = props || {};
            const levels = organization?.organization?.allhubs || [];
            const configDevices = configration?.configration?.configuration?.Device || [];
            const eventOrg = lastEvent?.lastEvent;

            function getDeviceEvents(deviceId) {
                return eventOrg?.devices?.find((device) => device._id === deviceId)?.events || [];
            }

            async function processMarkerIcon(marker) {
                if (marker.icon && marker.icon.match('/files')) {
                    const image = await getResource(marker.icon);
                    if (marker.icon.match('.svg')) {
                        const filterImage = image.match('svg11.dtd">') && image.split('svg11.dtd">')[1] || image;
                        marker.icon = filterImage;
                    } else if (marker.icon.match('.js')) {
                        const filterImage = eval('(' + image + ')');
                        marker.icon = filterImage;
                    }
                } else {
                    if (marker.alias.length) {
                        const iconsObj = [];
                        for (const alias of marker.alias) {
                            if (alias.icon && alias.icon.match('/files')) {
                                const image = await getResource(alias.icon);
                                if (alias.icon.match('.svg')) {
                                    const filterImage = image.match('svg11.dtd">') && image.split('svg11.dtd">')[1] || image;
                                    iconsObj.push({ ...alias, icon: filterImage });
                                }
                            } else {
                                iconsObj.push(alias);
                            }
                        }
                        marker.alias = iconsObj;
                    }
                }
            }
            for (const hub of levels) {
                for (const hubId of config.hubId) {
                    if (hub._id === hubId.hubId && hub.devices) {
                        for (const device of hub.devices) {
                            if (config.type === device.type) {
                                if (device.type === "Analyzer" && (device.name === "Output" || device.name === "Acc")) {
                                    const markerData = {
                                        _id: device._id,
                                        name: device.hubId + '_' + device.name,
                                        type: device.type,
                                        events: getDeviceEvents(device._id),
                                    };
                                    if ('coordinates' in hubId) {
                                        markerData.position = {
                                            lat: parseFloat(hubId?.coordinates?.lat),
                                            lng: parseFloat(hubId?.coordinates?.lng),
                                        };
                                    }
                                    markers1.push(markerData);
                                }
                                if (device.type !== "Analyzer") {
                                    const markerData = {
                                        _id: device._id,
                                        name: device.hubId + '_' + device.name,
                                        type: device.type,
                                        events: getDeviceEvents(device._id),
                                    };
                                    if ('coordinates' in hubId) {
                                        markerData.position = {
                                            lat: parseFloat(hubId?.coordinates?.lat),
                                            lng: parseFloat(hubId?.coordinates?.lng),
                                        };
                                    }
                                    markers1.push(markerData);
                                }
                            }
                        }
                    }
                }
            }

            // const markerPromises = markers1.map(processMarkerIcon);
            // await Promise.allSettled(markerPromises);
            const markerPushLoop = Math.ceil(markers1.length / 10);
            let updatedMarker = [];

            for (let incr = 1; incr <= markerPushLoop; incr++) {
                const newMarker = markers1.slice((incr - 1) * 10, incr * 10);
                await new Promise((resolve) => {
                    setTimeout(() => {
                        updatedMarker.push(...newMarker);
                        setMarkers([...markers, ...updatedMarker]);
                        resolve();
                    }, incr * 100);
                });
            }
        }
        markerUpdate(markers1);
        setIsLoad(false);
    }, [
        props?.config?.hubId,
        props?.config?.deviceId,
    ]);

    // const onMarkerClick = (marker) => {
    //     if (mapConfig.autoTrack) {
    //         if (!mapConfig.history) {
    //             trackDeviceHistory(marker.name);
    //         } else {
    //             stopHistory();
    //         }
    //     }
    // };
    const markerUpdate = (markers) => {
        if (markers.length) {
            let marker1 = [...markers]
            const eventOrg = props.lastEvent?.lastEvent;
            for (const [key1, hub] of marker1.entries()) {
                const filterEvent = eventOrg?.devices?.find(device => device._id.match(hub._id))?.events || [];
                // if (filterEvent[0].created || hub._id === "QSCP_0837") {
                //     console.log(filterEvent)
                // }
                // if (hub._id === hubs.hubList[key1]._id) {
                // console.log(marker1[2][key])
                // if (filterEvent !== undefined) {
                hub["events"] = filterEvent.find((hubEvent) => hubEvent?.type?.endsWith("Connection"))
                // }
                // }
            }
            setMarkers(marker1)

        }
    }


    return isLoaded && !isLoad ? (
        <Card id={mapId} className="d-flex flex-column h-100 w-100 overflow-hidden">
            {mapConfig.label &&
                <CardHeader>
                    {mapConfig.label &&
                        <Heading align="center" size={2}>{mapConfig.label}</Heading>
                    }
                    <div className='row justify-content-between flex-nowrap mt-1'>
                        <div className='row m-0'>
                        </div>
                        {/* <div className='d-flex' style={{ gap: 10 }}>
                            {mapConfig.autoTrack ?
                                <button className={'fade-in-left-onload btn btn-light px-2 rounded-circle-px dropshadow mr-1' + (false ? ' danger-gradient text-white' : ' text-danger')} style={{ display: (isLive ? 'block' : 'none') }}>
                                    <svg viewBox="0 0 3.55 3.55" style={{ width: 20 }}>
                                        <g>
                                            <path fill={false ? 'white' : '#DC3545'} d="M3.55 1.92l-0.43 0c-0.03,0.32 -0.18,0.6 -0.39,0.82 -0.21,0.21 -0.5,0.36 -0.82,0.39l0 0.43 -0.28 0 0 -0.43c-0.32,-0.03 -0.6,-0.18 -0.82,-0.39l-0 -0c-0.21,-0.21 -0.36,-0.5 -0.39,-0.82l-0.43 0 0 -0.28 0.43 0c0.03,-0.32 0.18,-0.6 0.39,-0.82l0.01 -0.01c0.21,-0.21 0.5,-0.35 0.81,-0.38l0 -0.43 0.28 0 0 0.43c0.32,0.03 0.6,0.18 0.82,0.39 0.21,0.21 0.36,0.5 0.39,0.82l0.43 0 0 0.28zm-1.02 -0.9c-0.19,-0.19 -0.46,-0.31 -0.76,-0.31 -0.29,0 -0.56,0.12 -0.75,0.31l-0.01 0.01c-0.19,0.19 -0.31,0.46 -0.31,0.76 0,0.3 0.12,0.57 0.31,0.76l0 0c0.19,0.19 0.46,0.31 0.76,0.31 0.3,0 0.57,-0.12 0.76,-0.31l0 0c0.19,-0.19 0.31,-0.46 0.31,-0.76 0,-0.3 -0.12,-0.57 -0.31,-0.76z" />
                                        </g>
                                    </svg>
                                </button>
                                : null}
                        </div> */}
                    </div>
                </CardHeader>
            }
            <CardBody loader={isLoad} loaderHeight="100%" className="flex-fill position-relative ">
                <div className='position-absolute justify-content-end' style={{ top: "10px", zIndex: "1", right: "10px" }}>
                    <button className='btn bg-white rounded-2 ' style={{ width: "40px", height: "40px" }} onClick={() => { setFullScreen(!fullScreen); fullScreens() }}>
                        {fullScreen ? <i class="fas fa-compress"></i> :
                            <i class="fas fa-expand"></i>}
                    </button>
                </div>
                {!isThemeLoad ?
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{ lat: 30.1798, lng: 66.9750 }}
                        zoom={10}
                        onLoad={(e) => onLoad(theme, e)}                >
                        <Polygon
                            path={quettaCoordinates}
                            options={{
                                strokeColor: '#F15A29',
                                icons: [
                                    {
                                        icon: {
                                            path: 'M 0,1 0,1',
                                            strokeOpacity: 1,
                                            scale: 3,
                                        },
                                        offset: '0',
                                        repeat: '8px', // Adjust the repeat value to control the spacing of the dots
                                    },
                                ],
                                fillColor: '#505955',
                                strokeOpacity: 0,
                                strokeWeight: 0,
                                zIndex: 1
                            }}
                        />
                        <Polyline
                            path={quettaCoordinates}
                            options={{
                                strokeColor: '#F15A29',
                                icons: [
                                    {
                                        icon: {
                                            path: 'M 0,1 0,1',
                                            strokeOpacity: 1,
                                            scale: 3,
                                        },
                                        offset: '0',
                                        repeat: '8px', // Adjust the repeat value to control the spacing of the dots
                                    },
                                ],
                                fillColor: '#505955',
                                strokeOpacity: 0,
                                strokeWeight: 0,
                                zIndex: 1
                            }} />
                        {markers.filter(marker => (filter !== 'all' ? marker.type === filter ? marker : null : marker)).map((marker, key) =>
                            marker.position &&
                            <React.Fragment key={key}>
                                <Marker
                                    // key={key}
                                    position={marker.position}
                                    title={marker.name}
                                    icon={{
                                        // url: markerIcon(marker),
                                        url: 'https://maps.google.com/mapfiles/ms/icons/' + (marker?.events?.value ? 'green' : 'red') + '-dot.png',
                                        scaledSize: fullScreen ? new window.google.maps.Size(50, 50) : new window.google.maps.Size(15, 15),
                                    }}
                                // onClick={() => onClickHandler(key)}
                                />
                                {/* <InfoBox
                            // defaultPosition={new window.google.maps.LatLng(props.center.lat, props.center.lng)}
                            position={marker.position}
                            // options={{ closeBoxURL: ``, enableEventPropagation: true }}
                            >
                            <div style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `12px` }}>
                                <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
                                Hello, Taipei!
                                </div>
                            </div>
                            </InfoBox> */}
                                {/* {(!isLive && polylineData.map((polyData, polyIndex) =>
                                'gpsId' in marker && polyData._id === marker.gpsId ?
                                    // console.log("gps", polyData.path)
                                    <Polyline
                                        key={polyIndex}
                                        path={polyData.path}
                                        options={{
                                            strokeColor: polyData.color,
                                            // strokeColor: "#F15A29",
                                            strokeOpacity: 0.8,
                                            strokeWeight: 4,
                                        }}
                                    />
                                    : null)
                            )} */}
                            </React.Fragment>
                        )}

                        {/* {markers !== null && props?.path?.length > 0 && (
                        <Polyline
                            path={props?.path}
                            options={{
                                strokeColor: "#F15A29",
                                strokeOpacity: 1.0,
                                strokeWeight: 4,
                            }}
                        />
                    )} */}
                    </GoogleMap> : <CardLoader />}
                {/* {mapConfig.history &&
                    <div className='mapHistoryBar fade-in-bottom-onload' style={{ display: (!isLive ? 'block' : 'none') }}>
                        <div>
                            <button className='btn btn-light' onClick={() => setIsPlay(!isPlay)}><i className={'fa fa-' + (isPlay ? 'pause' : 'play')}></i></button>
                            <button className='btn btn-light' onClick={() => playHandler(-1000)}><i className='fa fa-step-backward'></i></button>
                            <div className='w-100'>
                                <Input name='dateRange' valueDisplay={false} label="History" onChange={onChangeHandler} defaultValue={rangeDate.getTime()} type="range" min={new Date(startDate).getTime()} max={new Date(endDate).getTime()} />
                            </div>
                            <button className='btn btn-light' onClick={() => playHandler(1000)}><i className='fa fa-step-forward'></i></button>
                        </div>
                    </div>
                } */}
            </CardBody>
            {fullScreen ?
                <div>
                    <DefaultFooter />
                </div>
                : null}
        </Card>
        // <h1>Hello World</h1>
    ) : <CardLoader />;
}

const mapStateToProps = (state) => ({
    lastEvent: state.lastEvent,
    organization: state.organization,
    configration: state.configration,
    themeMode: state.themeMode
});
export default connect(mapStateToProps, null)(QuettaMap)