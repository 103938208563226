import React, { useEffect, useState } from 'react'
import "./CustomImage.scss"
import clickImg from '../../../assets/svgs/upload image.svg'
import { getImage } from '../../../services/utilities/events'
import InlineLoader from '../../loader/inlineLoader'

function CustomImage(props) {
    const [imgFile, setImgFile] = useState(null)
    const [logoLoader, setLogoLoader] = useState(false)
    useEffect(async () => {
        if('imageId' in props.config){
            setLogoLoader(true)
            let img = await getImage(props.config.imageId)
            setImgFile(img)
            setLogoLoader(false)
        }
    }, [props.config])
    
    // const checkImageProperties = async (file) => {
    //     setLogoLoader(true)
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onload = async (event) => {
    //           const image = new Image();
    //           image.src = event.target.result;
    //           image.onload = async () => {
    //             const width = image.width;
    //             const height = image.height;
    //             const size = file.size;
    //             const fileType = file.type;
    //             // check if the image meets your criteria for size, width, height, and type
    //             if (size > 1024 * 1024) { // max size 1MB
    //                 setLogoLoader(false)
    //               reject("File size too large. Please select a smaller image.");
    //             // } else if (width !== 200 || height !== 100) { // required dimensions 300x100
    //             //   reject("Invalid image dimensions. Please select an image with dimensions of 300x100 pixels.");
    //             } else if (!fileType.startsWith("image/")) { // check if the file type is an image
    //                 setLogoLoader(false)
    //               reject("Invalid file type. Please select an image file.");
    //             } else {
    //                 let res = await postImage(file)
    //                 if('onAction' in props){
    //                     props.onAction({name: 'imageUpload', value: res?.filename})
    //                 }
    //                 setLogoLoader(false)
    //               resolve();
    //             }
    //           };
              
    //         //   let res = await postImage(event.target.result)
    //         };
    //         reader.readAsDataURL(file);
    //       });
    // }
    return (
        <div className='w-100 h-100' style={{mouse:"pointer"}}>
            {logoLoader?
            <InlineLoader width="100%"/>
            :
            <img width="100%" height="100%" src={imgFile?imgFile:clickImg} alt='image'/>
            }
        </div>
    )
}

export default CustomImage