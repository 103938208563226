import React from 'react'
import "./generator.scss"

function Generator() {
  return (
      <svg  
      xmlns="http://www.w3.org/2000/svg"
      // preserveAspectRatio="none" 
      xmlSpace="preserve" 
      version="1.1"
      width="100%"
      height="100%"
      viewBox="0 0 6199.05 3590.41"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g>
       <path className="gen_fil0" d="M5781.12 359.7c0,55.15 -44.72,99.87 -99.87,99.87 -55.15,0 -99.87,-44.72 -99.87,-99.87 0,-105.7 34.36,-191.76 90.19,-253.62 62.27,-69 149.12,-106.08 244.18,-106.08l116.36 0c100.83,0 43.68,199.73 -31.29,199.73l-85.07 0c-38.47,0 -72.63,13.9 -95.95,39.75 -23.95,26.53 -38.69,67.02 -38.69,120.22z"/>
       <polygon className="gen_fil1" points="1794.41,376.48 3412.52,376.48 3547.83,523.76 3547.83,3012.3 1929.71,3012.3 1929.71,523.76 "/>
       <polygon className="gen_fil2" points="53.06,3012.3 53.06,528.9 205.49,376.48 1794.41,376.48 1929.71,523.76 1929.71,3012.3 "/>
       <polygon className="gen_fil1" points="4384.13,376.48 6002.24,376.48 6137.54,523.76 6137.54,3012.3 4519.43,3012.3 4519.43,523.76 "/>
       <polygon className="gen_fil2" points="3412.52,376.48 4384.13,376.48 4519.43,523.76 4519.43,3012.3 3547.83,3012.3 3547.83,523.76 "/>
       <rect className="gen_fil3" x="329.48" y="941.69" width="1362.23" height="2070.61"/>
       <rect className="gen_fil4" x="444.95" y="1050.15" width="1131.29" height="1962.15"/>
       <polygon className="gen_fil5" points="584.57,1080.91 1576.24,1080.91 1576.24,1187.1 444.96,1187.1 "/>
       <g>
        <path className="gen_fil5" d="M584.57 1231.92c330.55,0 661.11,0 991.67,0 0,35.4 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 1382.92c330.55,0 661.11,0 991.67,0 0,35.4 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 1533.93c330.55,0 661.11,0 991.67,0 0,35.4 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 1684.94c330.55,0 661.11,0 991.67,0 0,35.39 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 1835.94c330.55,0 661.11,0 991.67,0 0,35.4 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 1986.94c330.55,0 661.11,0 991.67,0 0,35.4 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 2137.95c330.55,0 661.11,0 991.67,0 0,35.39 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 2288.95c330.55,0 661.11,0 991.67,0 0,35.4 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 2439.97c330.55,0 661.11,0 991.67,0 0,35.39 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 2590.97c330.55,0 661.11,0 991.67,0 0,35.39 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
        <path className="gen_fil5" d="M584.57 2741.98c330.55,0 661.11,0 991.67,0 0,35.39 0,70.79 0,106.19 -377.1,0 -754.19,0 -1131.28,0 46.54,-35.4 93.08,-70.79 139.62,-106.19z"/>
       </g>
       <polygon className="gen_fil5" points="584.57,2892.98 1576.24,2892.98 1576.24,2999.17 444.96,2999.17 "/>
       <path className="gen_fil6 gen_str0" d="M1929.71 669.17l1618.11 0m971.61 0l1618.11 0"/>
       <rect className="gen_fil1 gen_str0" x="2241.22" y="822.18" width="1060.02" height="1883.47"/>
       <path className="gen_fil7" d="M2137.18 1080.91l98 0 0 81.46 -28.1 0.77c-4.5,0.13 -8.06,3.82 -8.09,8.29l0 90.52 -61.8 0c-3.99,0 -7.24,-3.25 -7.24,-7.24l0 -166.56c0,-3.99 3.25,-7.25 7.24,-7.25zm114.64 0l61.81 0c3.99,0 7.24,3.25 7.24,7.25l0 166.56c0,3.99 -3.25,7.24 -7.24,7.24l-98.01 0 0 -82.46 27.87 -0.75c4.6,0 8.32,-3.73 8.32,-8.32l0 -89.52z"/>
       <path className="gen_fil7" d="M2137.18 2450.68l98 0 0 -81.45 -28.1 -0.78c-4.5,-0.12 -8.06,-3.82 -8.09,-8.29l0 -90.52 -61.8 0c-3.99,0 -7.24,3.25 -7.24,7.24l0 166.56c0,3.99 3.25,7.24 7.24,7.24zm114.64 0l61.81 0c3.99,0 7.24,-3.25 7.24,-7.24l0 -166.56c0,-3.99 -3.25,-7.24 -7.24,-7.24l-98.01 0 0 82.46 27.87 0.74c4.6,0 8.32,3.73 8.32,8.32l0 89.52z"/>
       <rect className="gen_fil7" x="3048.63" y="1682.6" width="190.95" height="181.05" rx="10.65" ry="10.65"/>
       <rect className="gen_fil2" x="2449.03" y="1014.35" width="622.5" height="445.06" rx="34.73" ry="26.19"/>
       <rect className="gen_fil6 gen_str0" x="4805.58" y="822.18" width="1060.02" height="1883.47"/>
       <path className="gen_fil7" d="M5793.18 1080.91l98 0 0 81.46 -28.1 0.77c-4.5,0.13 -8.07,3.82 -8.1,8.29l0 90.52 -61.8 0c-3.99,0 -7.25,-3.25 -7.25,-7.24l0 -166.56c0,-3.99 3.25,-7.25 7.25,-7.25zm114.64 0l61.81 0c3.99,0 7.25,3.25 7.25,7.25l0 166.56c0,3.99 -3.25,7.24 -7.25,7.24l-98 0 0 -82.46 27.87 -0.75c4.59,0 8.32,-3.73 8.32,-8.32l0 -89.52z"/>
       <path className="gen_fil7" d="M5793.18 2450.68l98 0 0 -81.45 -28.1 -0.78c-4.5,-0.12 -8.07,-3.82 -8.1,-8.29l0 -90.52 -61.8 0c-3.99,0 -7.25,3.25 -7.25,7.24l0 166.56c0,3.99 3.25,7.24 7.25,7.24zm114.64 0l61.81 0c3.99,0 7.25,-3.25 7.25,-7.24l0 -166.56c0,-3.99 -3.25,-7.24 -7.25,-7.24l-98 0 0 82.46 27.87 0.74c4.59,0 8.32,3.73 8.32,8.32l0 89.52z"/>
       <rect className="gen_fil7" x="4867.25" y="1682.6" width="190.95" height="181.05" rx="10.65" ry="10.65"/>
       <rect className="gen_fil8" x="5335.59" y="1207.97" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5446.07" y="1215.72" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5544.57" y="1223.48" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5631.08" y="1231.23" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5335.59" y="1305.91" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5335.59" y="1403.85" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5335.59" y="1501.79" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5335.59" y="1599.73" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5335.59" y="1697.67" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5335.59" y="1795.61" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5335.59" y="1893.55" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5335.59" y="1991.49" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5335.59" y="2089.42" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5335.59" y="2187.36" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5446.07" y="1313.66" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5446.07" y="1411.6" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5446.07" y="1509.54" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5446.07" y="1607.48" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5446.07" y="1705.42" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5446.07" y="1803.36" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5446.07" y="1901.3" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5446.07" y="1999.24" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5446.07" y="2097.18" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5446.07" y="2195.12" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5544.57" y="1321.42" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5544.57" y="1419.36" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5544.57" y="1517.29" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5544.57" y="1615.23" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5544.57" y="1713.17" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5544.57" y="1811.11" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5544.57" y="1909.05" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5544.57" y="2006.99" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5544.57" y="2104.93" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5544.57" y="2202.87" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5631.08" y="1329.17" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5631.08" y="1427.11" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5631.08" y="1525.05" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5631.08" y="1622.99" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5631.08" y="1720.93" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5631.08" y="1818.86" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5631.08" y="1916.8" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5631.08" y="2014.74" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5631.08" y="2112.68" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5335.59" y="2282.45" width="74.18" height="65.96" rx="13.79" ry="13.79"/>
       <rect className="gen_fil8" x="5446.07" y="2290.2" width="56.74" height="50.46" rx="10.55" ry="10.55"/>
       <rect className="gen_fil8" x="5544.57" y="2297.95" width="39.31" height="34.95" rx="7.31" ry="7.31"/>
       <rect className="gen_fil8" x="5631.08" y="2207.77" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <rect className="gen_fil8" x="5631.08" y="2305.7" width="21.87" height="19.45" rx="4.07" ry="4.07"/>
       <polygon className="gen_fil9" points="3464.86,433.45 4340.6,433.45 4423.57,524.2 4423.57,3024.7 3547.83,3024.7 3547.83,524.2 "/>
       <rect className="gen_fil10 gen_str1" x="3783.48" y="777.51" width="443.69" height="430.47" rx="40.2" ry="40.2"/>
       <ellipse className="gen_fil11" cx="4041.77" cy="981.5" rx="97.56" ry="120.6"/>
       <path className="gen_fil12" d="M4132.78 912.97c41.53,0 51.22,28.45 51.22,74.49 0,46.05 -4.37,74.93 -45.9,74.93 -41.53,0 -111.87,-41.45 -111.87,-74.93 0,-33.48 65.02,-74.49 106.54,-74.49z"/>
       <rect className="gen_fil10 gen_str1" x="3795.46" y="2122.41" width="326.68" height="467.8" rx="20.86" ry="58.87"/>
       <polygon className="gen_fil9" points="20.73,3286.33 6199.05,3286.33 5957.17,3590.41 255.7,3590.41 "/>
       <rect className="gen_fil9" x="131.3" y="3024.7" width="5915.7" height="274.02"/>
       <rect className="gen_fil13" y="3012.3" width="6178.31" height="24.8" rx="151.57" ry="12.4"/>
       <polygon className="gen_fil13" points="45.51,3318.4 6173.54,3318.4 6199.05,3286.33 6047.01,3286.33 131.3,3286.33 20.73,3286.33 "/>
      </g>
     </svg>
  )
}

export default Generator