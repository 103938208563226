//import Libraries
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
//import Resources
import { getResource, getResource2 } from '../../services/utilities/events'
import './deviceCard.scss'
import CardLoader from '../loader/cardLoader'
import GraphCard from '../graphCard/graphCard';
import moment from 'moment';
import SetAlert from '../setAlertForm/setAlert';
import { useHistory } from 'react-router-dom';
import DeviceInfoPopup from '../deviceInfoPopup/DeviceInfoPopup';
import { getCookie } from '../../services/utilities/users';
import { publishDeviceData } from '../../services/utilities/socket';
import powerImg from './power.svg'
import Card from '../card/card'
const DeviceCard = (props) => {
    const [alert, setAlert] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [ruleEngine, setRuleEngine] = useState(false)
    const [anylatics, setAnalytics] = useState(false)
    const [controllDevice, setControllDevice] = useState(false)

    const history = useHistory()

    const [device, setDevice] = useState(null)

    //Alert Form State
    //Chart DropDown
    const [dropdown, setDropdown] = useState(false)

    //Events State
    const [events, setEvents] = useState({
        Status: null,
        Connection: null
    })

    useEffect(async () => {
        //set user Authorities
        if (props?.access) {
            let access = props?.access
            if (access) {
                if (access?.length) {
                    if (access.includes("1003") || access.includes("admin")) {
                        setRuleEngine(true)
                    } else {
                        setRuleEngine(false)
                    }
                    if (access.includes("1004") || access.includes("admin")) {
                        setAnalytics(true)
                    } else {
                        setAnalytics(false)
                    }
                    if (access.includes("1009") || access.includes("admin")) {
                        setControllDevice(true)
                    } else {
                        setControllDevice(false)
                    }
                } else {
                    setRuleEngine(false)
                    setAnalytics(false)
                    setControllDevice(false)
                }
            }
            // if (access?.length) {
            //     access.map(acc => {
            //         if (acc === "1003" || acc === "admin") {
            //             setRuleEngine(true)
            //         }
            //         if (acc === "1004" || acc === "admin") {
            //             setAnalytics(true)
            //         }
            //         if (acc === "1009" || acc === "admin") {
            //             setControllDevice(true)
            //         }
            //     })
            // } else {
            //     setRuleEngine(false)
            //     setAnalytics(false)
            //     setControllDevice(false)
            // }
        }
        //
        if (props.device) {
            let paramIndexes = [
                'Status',
                'Temperature',
                'Mode',
                'Speed',
                'Swing',
            ]
            let filterParameter = []
            let unfilterParameter = []
            let device = props.device
            //duplicateDevice is without config
            let duplicateDevice = { ...props.device }
            //delete config In duplicateDevice  
            delete duplicateDevice.config
            //use Device config 
            if (device.config) {
                let alias = []
                let paramKey = null
                let icon = await imgRenderFunc(device.config.icon);
                let defaultIcon = props.randomImage;
                let renderIcon = [];
                if (device?.config?.parameter) {
                    await Promise.all(device?.config?.parameter.map(async (configParam) => {
                        await Promise.all(device?.parameter?.map(async (param, key) => {
                            if (configParam.type.toLowerCase() === param.types.toLowerCase()) {
                                duplicateDevice.parameter[key]["alias"] = configParam.alias
                                alias = configParam.alias
                                paramKey = param.value

                                if (alias?.length) {
                                    let imageNull = true;
                                    alias.map((row) => {
                                        if (row.icon && row.icon !== "renderFunction") {
                                            imageNull = false
                                        }
                                    })
                                    await Promise.all(
                                        alias.map(async (row) => {
                                            if (!imageNull) {
                                                let icon = defaultIcon
                                                if (row.icon) {
                                                    icon = await imgRenderFunc(row.icon)
                                                }
                                                renderIcon.push({ paramKey: param.value, key: row.key, ...icon })
                                            } else if (device.config.icon.endsWith(".js")) {
                                                if (row.icon === "renderFunction") {
                                                    renderIcon.push({ paramKey: param.value, key: row.key, ...icon })
                                                }
                                            }
                                        })
                                    )
                                }
                                else if (icon && icon?.type !== "js") {
                                    renderIcon.push(icon)
                                }
                            }
                        }))
                    }))
                }
                let events = device?.events;
                // device?.events?.events.map(data => {
                //     let deviceId = data.deviceId.split("_")
                //     if (deviceId.length === 3) {
                //         events.Status = data
                //     } else if (deviceId.length === 2) {
                //         events.Connection = data
                //     }
                // })
                setEvents(events)

                paramIndexes.map(ind => {
                    duplicateDevice.parameter.map((param) => {
                        if (param.types === ind) {
                            filterParameter.push(param)
                        }
                    })
                })
                duplicateDevice.parameter.map((param) => {
                    if (!paramIndexes.includes(param.types)) {
                        unfilterParameter.push(param)
                    }
                })


                duplicateDevice.parameter = [...filterParameter, ...unfilterParameter]
                duplicateDevice['icon'] = renderIcon
                setDevice(duplicateDevice)
            }
        }

        liveEventHandler();
    }, [])

    useEffect(() => {
        liveEventHandler();
    }, [props.lastEvent])
    const liveEventHandler = () => {
        let Events = props?.lastEvent?.lastEvent?.devices
        let events1 = { ...events }
        for (const item of Events) {
            if (item.events[0].deviceId === props.device._id) {
                events1.Status = item.events[0]
                setEvents(events1)
            }
        }
    }
    // MQTT Broker
    // useEffect(async () => {
    //     let Events = props?.eventReducer?.deviceEvents
    //     let events1 = { ...props.lastEvent.lastEvent }
    //     events1?.devices?.map((item, index) => {
    //         if (Events.deviceId === props.device._id) {
    //             item.events[0] = Events
    //             return
    //         }
    //     })
    //     store.dispatch({
    //         type: "Get Events",
    //         payload: { type: "last", data: events1 }
    //     })
    // }, [props.eventReducer.deviceEvents])


    const imgRenderFunc = async (src) => {
        let image = null
        if (src.endsWith(".svg")) {
            image = await getResource(src)
            image = { icon: image, type: 'svg' }
        }
        else if (src.endsWith(".js")) {
            let imagecheck = await getResource(src)
            image = eval("(" + imagecheck + ")")
            image = { icon: image, type: 'js' }
        }
        else {
            // image = await fetch("http://192.168.10.22:3000/api/v1/files/image/" + src)
            // image = await image.blob();
            // image = URL.createObjectURL(image);
            // image = { icon: image, type: null }
        }
        return image;
    }
    // image return function It will run when the image is there
    const imgReturnFunc = (data, value) => {
        var length = data.length
        if (length) {
            if (length === 1) {
                let img = data[0]
                if (img.type === "svg") {
                    return "data:image/svg+xml;base64," + btoa(img.icon)
                    // return img.icon
                }
                else {
                    return data.icon
                }
            } else {
                let icon = null
                data.map((row, key) => {
                    if (row.type === "svg") {
                        if (row.key === value?.value) {
                            // icon = row.icon
                            icon = "data:image/svg+xml;base64," + btoa(row.icon)
                        } else if ((value?.created === null && row.key === 0) || (!("paramKey" in row) && key === 0)) {
                            // icon = row.icon
                            icon = "data:image/svg+xml;base64," + btoa(row.icon)
                        }
                    } else if (row.type === "js") {
                        icon = row.icon({ value: value ? value[row.paramKey] : 0 });
                        icon = "data:image/svg+xml;base64," + btoa(icon)
                    }
                    else {
                        icon = data.icon
                    }
                })
                return icon;
            }
        }
    }

    const sentEvent = (event, type, value = null) => {
        if (controllDevice) {
            let event1 = { ...event }
            if (value !== null) {
                event1[type] = value
            } else {
                event1[type] = event1[type] ? 0 : 1
            }
            event1["createdBy"] = getCookie("email")
            delete event1["_id"]
            delete event1["created"]
            publishDeviceData(event1)
        } else {
            console.log("You Are not able to Controll device")
        }
    }


    return !alert ?

        <div className='deviceCard' style={dropdown ? { width: "100%" } : null}>
            <div className='deviceCardinner'>
                <Card className="devCard">
                    {device ?
                        <div className='deviceArea'>
                            <div className='row justify-content-between m-0 p-2'>
                                {ruleEngine ?
                                    <button className='deviceBtn deviceRoundBtn btn' onClick={() => history.push({ pathname: '/alerts/' + props.device._id, data: device })}><i className='far fa-bell'></i></button>
                                    : <button className='deviceBtn deviceRoundBtn btn disabled' ></button>}
                                <div className='deviceImage'>
                                    <div className='d-flex h-100 w-100'>
                                        <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={imgReturnFunc(device.icon, events.Status)} />
                                    </div>
                                </div>
                                <button className='deviceBtn deviceRoundBtn btn' onClick={() => setShowInfo(true)}><i className='fa fa-info' ></i></button>
                            </div>
                            <div className='d-flex flex-column my-4 align-items-center justify-content-center'>
                                <table>
                                    {device.parameter.map((params, index) =>
                                        index <= (showMore ? device.parameter.length - 1 : 5) ?
                                            <React.Fragment key={index}>
                                                {params.alias?.length ?
                                                    <div className='col-12 d-flex justify-content-center p-0' style={{ gap: "5px" }}>
                                                        {events.Status ?
                                                            props.device.config.isControllable ?
                                                                params.types === "Status" ?
                                                                    <button className='col h6 px-3 text-center btn btn-light rounded-3 dropshadow' style={{ color: "#00BBFF", fontSize: 30 }} onClick={() => sentEvent(events.Status, params.value)}>
                                                                        {params.alias.map((alias) =>
                                                                            events.Status[params.value] === alias.key ? alias.value : null
                                                                        )}
                                                                    </button>
                                                                    :
                                                                    params.types === "Mode" ?
                                                                        params.alias.map((alias, key) =>
                                                                            <button className={'col h6 p-2 text-center btn btn-light rounded-2 dropshadow ' + (events.Status[params.value] === alias.key ? "bg-primary" : "")} style={{ color: events.Status[params.value] === alias.key ? "#ffffff" : "#00BBFF", fontSize: 20, lineHeight: "18px" }} onClick={() => sentEvent(events.Status, params.value, alias.key)}>
                                                                                {alias.value}
                                                                            </button>
                                                                        )
                                                                        : params.types === "Swing" ?
                                                                            <div className='d-flex col  btn btn-light rounded-circle-px dropshadow p-0 overflow-hidden'>
                                                                                {params.alias.map((alias) =>
                                                                                    <button className={'col h6 p-2 text-center btn btn-light m-0  rounded-circle-px ' + (events.Status[params.value] === alias.key ? "bg-primary" : "")} style={{ color: events.Status[params.value] === alias.key ? "#ffffff" : "#00BBFF", fontSize: 20, lineHeight: "18px" }} onClick={() => sentEvent(events.Status, params.value, alias.key)}>
                                                                                        {alias.value === "ON" ? "Swing" : "Direct"}
                                                                                    </button>
                                                                                )}
                                                                            </div> :
                                                                            params.types === "Speed" ?
                                                                                params.alias.map((alias) =>
                                                                                    <button className={'col h6 p-2 text-center btn btn-light rounded-2 dropshadow ' + (events.Status[params.value] === alias.key ? "bg-primary" : "")} style={{ color: events.Status[params.value] === alias.key ? "#ffffff" : "#00BBFF", fontSize: 20, lineHeight: "18px" }} onClick={() => sentEvent(events.Status, params.value, alias.key)}>
                                                                                        {alias.value}
                                                                                    </button>

                                                                                ) : null
                                                                :
                                                                <div className='h2 py-3 text-center' style={{ color: "#00BBFF", fontSize: 30 }}>
                                                                    {params.alias.map((alias) =>
                                                                        events.Status[params.value] === alias.key ? alias.value : null
                                                                    )}
                                                                </div>
                                                            :
                                                            props.device.config.isControllable ?
                                                                params.types === "Status" ?
                                                                    <button className=' col h6 px-3 text-center btn btn-light rounded-3 dropshadow' style={{ color: "#00BBFF", fontSize: 30 }} onClick={() => sentEvent(events.Status, params.value)}>
                                                                        OFF
                                                                    </button>
                                                                    :
                                                                    params.types === "Mode" ?
                                                                        params.alias.map((alias) =>
                                                                            <button className='col h6 p-2 text-center btn btn-light rounded-2 dropshadow' style={{ color: "#00BBFF", fontSize: 20, lineHeight: "18px" }} onClick={() => sentEvent(events.Status, params.value, alias.key)}>
                                                                                {alias.value}
                                                                            </button>
                                                                        )
                                                                        :
                                                                        params.types === "Swing" ?
                                                                            params.alias.map((alias) =>
                                                                                <button className='col h6 p-2 text-center btn btn-light rounded-2 dropshadow' style={{ color: "#00BBFF", fontSize: 20, lineHeight: "18px" }} onClick={() => sentEvent(events.Status, params.value, alias.key)}>
                                                                                    {alias.value}
                                                                                </button>
                                                                            ) :
                                                                            params.types === "Speed" ?
                                                                                params.alias.map((alias) =>
                                                                                    <button className='col h6 p-2 text-center btn btn-light rounded-2 dropshadow' style={{ color: "#00BBFF", fontSize: 20, lineHeight: "18px" }} onClick={() => sentEvent(events.Status, params.value, alias.key)}>
                                                                                        {alias.value}
                                                                                    </button>
                                                                                ) : null
                                                                : params.alias.map(alias =>
                                                                    alias.key === 0 ?
                                                                        <div className='h2 px-3 text-center' style={{ color: "#00BBFF", fontSize: 30 }}>
                                                                            {alias.value}
                                                                        </div> : null
                                                                )
                                                        }
                                                    </div>
                                                    :
                                                    events?.Status ?
                                                        params.value in events.Status ?
                                                            props.device.config.isControllable ?
                                                                params.types === "Temperature" ?
                                                                    <div className='d-flex justify-content-center p-0' style={{ gap: "10px" }}>
                                                                        <button className={'col h6 p-2 text-center btn btn-light rounded-2 dropshadow ' + (events.Status[params.value] <= 16 ? "disabled" : "")} style={{ color: "#00BBFF", fontSize: 20, lineHeight: "18px" }} onClick={() => sentEvent(events.Status, params.value, (events.Status[params.value] - 1))} >
                                                                            <i className='fa fa-angle-left'></i>
                                                                        </button>
                                                                        <h1> {events.Status[params.value]}<sup style={{ fontSize: "20px", marginTop: "-10px" }}>{params.unit}</sup></h1>
                                                                        <button className={'col h6 p-2 text-center btn btn-light rounded-2 dropshadow ' + (events.Status[params.value] >= 30 ? "disabled" : "")} style={{ color: "#00BBFF", fontSize: 20, lineHeight: "18px" }} onClick={() => sentEvent(events.Status, params.value, (events.Status[params.value] + 1))}>
                                                                            <i className='fa fa-angle-right'></i>
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    null
                                                                :
                                                                <tr className='deviceDetail' title={params.types}>
                                                                    <td className="text-truncate">{params.name ? params.name : params.types}</td><td>: </td>
                                                                    <th className='pl-2'>{(typeof (events.Status[params.value]) === "string" ? events.Status[params.value] : events.Status[params.value].toFixed(2)) + " " + params.unit}</th>
                                                                </tr>
                                                            :
                                                            <tr className='deviceDetail' title={params.types}>
                                                                <td className="text-truncate">{params.name ? params.name : params.types}</td><td>: </td>
                                                                <th className='pl-2'>0</th>
                                                            </tr>
                                                        :
                                                        props.device.config.isControllable ?
                                                            params.types === "Temperature" ?
                                                                <div className='d-flex justify-content-center p-0' style={{ gap: "10px" }}>
                                                                    <button className='col h6 p-2 text-center btn btn-light rounded-2 dropshadow' style={{ color: "#00BBFF", fontSize: 20, lineHeight: "18px" }}>
                                                                        <i className='fa fa-angle-left'></i>
                                                                    </button>
                                                                    <h1>22<sup style={{ fontSize: "20px", marginTop: "-10px" }}>{params.unit}</sup></h1>
                                                                    <button className='col h6 p-2 text-center btn btn-light rounded-2 dropshadow' style={{ color: "#00BBFF", fontSize: 20, lineHeight: "18px" }}>
                                                                        <i className='fa fa-angle-right'></i>
                                                                    </button>
                                                                </div> :
                                                                null
                                                            :
                                                            <tr title={params.types}>
                                                                <td className="pl-3 text-truncate" style={{ maxWidth: 150, width: 150, textAlign: "right" }}>{params.name ? params.name : params.types + ": "}</td>
                                                                <th className='pl-2' style={{ width: 150 }} >N/A</th>
                                                            </tr>
                                                }
                                            </React.Fragment>
                                            : null
                                    )}
                                </table>
                                {device.parameter.length > 6 ?
                                    <div className='text-center'>
                                        <div className='text-link' style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => setShowMore(!showMore)}>
                                            {!showMore ? " show more" : "show less"}
                                        </div>
                                    </div>
                                    : null}
                            </div>
                            <div className='d-flex flex-column m-0 align-items-center'>
                                <div className='deviceTitle'>{device.name}</div>
                                <small className='deviceUpdate'>{events.Status ? "Last Update: " + moment(events.Status.created).format("ll LTS") : "Last Update: 00:00:00 AM 00-00-0000"}</small>
                                {anylatics ?
                                    <button onClick={() => setDropdown(!dropdown)} className='expandBtn btn deviceBtn'><i className={'fa fa-angle-' + (dropdown ? 'up' : 'down') + ' fa-lg'}></i></button>
                                    : null}
                            </div>
                        </div>
                        : <div className='d-flex flex-column position-absolute w-100 h-100' style={{ top: 0, justifyContent: "space-between" }}>
                            <CardLoader />
                        </div>}
                </Card>
            </div>
            {
                dropdown && events ?
                    <GraphCard events={events} device={device} />
                    : null
            }
            {/* {alert ? <Mypopup title={"Add Alert"} onClose={() => { setAlert(null) }}>
            <SetAlert events={events} device={device} />
        </Mypopup> : null} */}
            {showInfo ? <DeviceInfoPopup events={events.Status} res={props.device} onClose={() => setShowInfo(null)} /> : null}
        </div>

        : <SetAlert device={props.device} />
}
const mapStateToProps = (state) => ({
    lastEvent: state.lastEvent,
    userInfo: state.userInfo
});
export default connect(mapStateToProps, null)(DeviceCard)

// export default DeviceCard
