import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment';
import './map.scss'
import styles from './mapStyles.json'
import { GoogleMap, InfoBox, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { ServerBaseAddress } from '../../../services/config/serverAddresses';
import { getMultiDeviceHistory, getResource } from '../../../services/utilities/events';
import Card, { CardBody, CardHeader, Heading } from '../../card/card';
import Select from '../../select/select';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import Input from '../../input/input';
import { Button, ButtonContainer } from '../../arraybutton/arraybutton';
import { getRandomNumber, hslToHex, idGenerator, todayDate, utcDate } from '../../../functions/functions';
// import DefaultFooter from '../default-layout/defaultFooter'
import DefaultFooter from '../../../containers/default-layout/defaultFooter'
import AQIMarker from '../../aqiMarker/aqiMarker';

const containerStyle = {
    width: '100%',
    minHeight: '100px',
    height: '100%',
    // borderRadius: '15px',
};
function Map(props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA9osJ2QBSR6f9oBEZULavTAJzZxVccQMo"
    })
    const mapId = idGenerator() + "_map"
    const [map, setMap] = useState(null)
    const [isLoad, setIsLoad] = useState(false)
    const [mapConfig, setMapConfig] = useState({
        coordinates: { lat: 33.6844, lng: 73.0479 }, // lahore
        // coordinates: { lat: 24.880934, lng: 67.055983 }, // karachi
        label: '',
        zoom: 12,
        autoTrack: false,
        live: false,
        history: false,
        filter: false,
        type: [],
    })


    const [markers, setMarkers] = useState([])
    const [filter, setFilter] = useState('all')
    const [isLive, setIsLive] = useState(true)
    const [startDate, setStartDate] = useState(todayDate('start'))
    const [endDate, setEndDate] = useState(todayDate('end'))
    const [rangeDate, setRangeDate] = useState(new Date(todayDate('start')))
    const [history, setHistory] = useState([])
    const [polylineData, setPolylineData] = useState([])
    const [isPlay, setIsPlay] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [state, setState] = useState({
        length: 1,
    })

    const onLoad = useCallback(function callback(map) {
        map.setOptions({
            styles: styles.dark,
            // mapTypeControl: false,
            streetViewControl: false,
        })
        setMap(map)
    }, [])
    // const onUnmount = useCallback(function callback(map) {
    //     setMap(null)
    // }, [])
    useEffect(() => {
        document.addEventListener('fullscreenchange', onFullScreenChange);
        let state1 = state;
        if ("length" in props) {
            state1.length = state.length
        }
        setState(state1)
        return () => {
            document.removeEventListener("fullscreenchange", onFullScreenChange);
        }
    }, [])

    const onFullScreenChange = () => {
        if (document.fullscreenElement) {
            // setFullScreen(true)
        }
        else {
            setFullScreen(false)
        }
    }

    const fullScreens = () => {
        var elem = document.getElementById(mapId)
        if (!fullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }
    useEffect(() => {
        let config = props?.config
        let mapConfig1 = { ...mapConfig }
        mapConfig1.label = config?.label || ""
        if (config?.mapSetting) {
            if (config.mapSetting?.mapCenter) {
                if ((config.mapSetting.mapCenter?.lat) && (config.mapSetting.mapCenter?.lng)) {
                    mapConfig1.coordinates = {
                        lat: parseFloat(config.mapSetting.mapCenter?.lat),
                        lng: parseFloat(config.mapSetting.mapCenter?.lng),
                    }
                }
                if (config.mapSetting.mapCenter?.zoom) {
                    mapConfig1.zoom = parseInt(config.mapSetting.mapCenter?.zoom)
                }
            }

            mapConfig1.autoTrack = config.mapSetting?.autoTrack || false
        }
        if (config?.dataMode) {
            mapConfig1.live = config.dataMode?.live || false
            mapConfig1.history = config.dataMode?.history || false
            mapConfig1.filter = config.dataMode?.filter || false
            let typeList = []
            if (config.dataMode?.filter) {
                config?.deviceId?.map(id => {
                    if (id === "all") {
                        typeList.push({ label: "All", value: id })
                    } else {
                        typeList.push({ label: id, value: id })
                    }
                })
                mapConfig1.type = typeList
            }
        }
        setMapConfig(mapConfig1)

    }, [
        props?.config?.label,
        props?.config?.deviceId,
        props?.config?.mapSetting,
        props?.config?.dataMode,
    ])


    useEffect(async () => {
        setIsLoad(true)
        const markers1 = []
        if (props.lastEvent && props.organization && props?.config?.levelId && "hubId" in props.config) {
            const { organization, config, configration, lastEvent } = props || {};
            const levels = organization?.organization?.levels || [];
            const configDevices = configration?.configration?.configuration?.Device || [];
            const eventOrg = lastEvent?.lastEvent;

            function getDeviceEvents(deviceId) {
                return eventOrg?.devices?.find(device => device._id === deviceId)?.events || [];
            }

            async function processMarkerIcon(marker) {
                if (marker.icon && marker.icon.match('/files')) {
                    const image = await getResource(marker.icon);
                    if (marker.icon.match(".svg")) {
                        const filterImage = image.match('svg11.dtd">') && image.split('svg11.dtd">')[1] || image
                        marker.icon = filterImage;
                    } else if (marker.icon.match(".js")) {
                        const filterImage = eval("(" + image + ")")
                        marker.icon = filterImage
                    }
                } else {
                    if (marker.alias.length) {
                        const iconsObj = [];
                        for (const alias of marker.alias) {
                            if (alias.icon && alias.icon.match('/files')) {
                                const image = await getResource(alias.icon);
                                if (alias.icon.match(".svg")) {
                                    const filterImage = image.match('svg11.dtd">') && image.split('svg11.dtd">')[1] || image
                                    iconsObj.push({ ...alias, icon: filterImage });
                                }
                            } else {
                                iconsObj.push(alias);
                            }
                        }
                        marker.alias = iconsObj
                    }
                }
            }

            for (const level of levels) {
                const levelMatch = level._id === config.levelId;
                if (levelMatch && level.hubs) {
                    for (const hub of level.hubs) {
                        for (const hubId of config.hubId) {
                            if (hub._id === hubId.hubId && hub.devices) {
                                for (const device of hub.devices) {
                                    if (config.deviceId.includes(device.type)) {
                                        const markerData = {
                                            _id: device._id,
                                            name: device.name,
                                            type: device.type,
                                            events: getDeviceEvents(device._id)
                                        };
                                        if (hubId.isDynamic && hubId?.deviceId) {
                                            const gpsEvent = getDeviceEvents(hubId.deviceId);
                                            if (gpsEvent.length) {
                                                const [lat, lng] = (typeof gpsEvent[0]?.latlng === 'string') ? gpsEvent[0]?.latlng?.split(',').map(parseFloat) : [gpsEvent[0]?.lat || 0, gpsEvent[0]?.lng || 0];
                                                markerData.position = { lat, lng };
                                                markerData.gpsId = hubId.deviceId;
                                            } else {
                                                markerData.position = { lat: 0, lng: 0 };
                                            }
                                        } else if ('coordinates' in hubId) {
                                            markerData.position = {
                                                lat: parseFloat(hubId.coordinates.lat),
                                                lng: parseFloat(hubId.coordinates.lng),
                                            };
                                        }
                                        const matchingConfigDevice = configDevices.find(
                                            configDevice => configDevice.type === device.type
                                        );
                                        if (matchingConfigDevice) {
                                            markerData.icon = matchingConfigDevice.icon;
                                            markerData.alias = [...(
                                                matchingConfigDevice.parameter.find(param => param.type === "Status" && 'alias' in param)?.alias || []
                                            )];
                                        }
                                        markers1.push(markerData);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            const markerPromises = markers1.map(processMarkerIcon);
            await Promise.allSettled(markerPromises);
            setMarkers(markers1)
        }
        setIsLoad(false)
    }, [
        props?.config?.levelId,
        props?.config?.hubId,
        props?.config?.deviceId,
    ])
    useEffect(() => {
        if (isLive) {
            let events = props?.lastEvent?.lastEvent?.devices
            if (markers.length && events) {
                let markers1 = markers.map(marker => {
                    if (marker.gpsId) {
                        let gpsEvent = events.find(eve => eve._id === marker.gpsId)?.events || []
                        let [lat, lng] = (typeof gpsEvent[0]?.latlng === 'string') ? gpsEvent[0]?.latlng?.split(',').map(parseFloat) : [gpsEvent[0]?.lat || 0, gpsEvent[0]?.lng || 0]
                        marker.position = { lat, lng }
                    }
                    let deviceEvents = events.find(eve => eve._id === marker._id)?.events || []
                    marker.events = deviceEvents
                    return marker
                })
                setMarkers(markers1)
            }
        }
    }, [props.lastEvent])

    const markerIcon = (marker) => {
        let renderIcon = ""
        let value = 0
        if (marker.events.length && marker.events?.[0].value) {
            value = marker.events?.[0].value
        }
        if (marker.type === "AQI" || marker.type === "Dust") {
            renderIcon = AQIMarker({ value })
        } else {
            if (marker.icon) {
                if (typeof marker.icon === 'function') {
                    renderIcon = marker.icon({ value });
                }
                else if (typeof marker.icon === 'string') {
                    renderIcon = marker.icon
                }
            } else {
                marker?.alias.map(icon => {
                    if (marker.events.length) {
                        if (icon.key === marker.events?.[0].value) {
                            renderIcon = icon.icon
                        }
                    } else {
                        if (icon.key === 0) {
                            renderIcon = icon.icon
                        }
                    }
                })
            }
            renderIcon = '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 16746 24233" xmlns:xlink="http://www.w3.org/1999/xlink">' +
                "<defs>" +
                '<linearGradient id="marker_id0" x1="0" y1="0" x2="100%" y2="100%">' +
                '<stop offset="0" style="stop-opacity:1; stop-color:#1b75bc"/>' +
                '<stop offset="1" style="stop-opacity:1; stop-color:#0d3a5d"/>' +
                "</linearGradient>" +
                "</defs>" +
                "<g>" +
                '<path fill="url(#marker_id0)" d="M8373 0c4624,0 8373,3749 8373,8373 0,3614 -2289,6693 -5497,7866l-2876 7994 -2876 -7994c-3208,-1173 -5497,-4252 -5497,-7866 0,-4624 3749,-8373 8373,-8373z"/>' +
                '<circle fill="white" cx="8373" cy="8373" r="6418"/>' +
                "<svg x='20%' y='12%' width='60%' height='46%'>" +
                (renderIcon || (value !== null && '<text style="font-size: 10px;">' + value + '</text>')
                ) +
                "</svg>" +
                "</g>" +
                "</svg>"
        }
        renderIcon = "data:image/svg+xml;base64," + btoa(renderIcon)
        return renderIcon
    }
    const onChangeHandler = (e) => {
        const name = e.name,
            value = e.target.value
        setIsPlay(false)
        if (name === 'startDate') {
            setStartDate(value)
            setRangeDate(new Date(value))
        } else if (name === 'endDate') {
            setEndDate(value)
        } else if (name === 'dateRange') {
            const date = new Date(parseInt(value))
            rangeDataHandler(date)
            setRangeDate(date)
        }
    }
    const rangeDataHandler = (date) => {
        const findData = history.map(rowData => {
            if (rowData.length) {
                const filterData = rowData.filter(data => {
                    if (new Date(data.created) <= new Date(date)) {
                        return data
                    }
                })
                if (filterData.length) {
                    return filterData
                }
            }
        }).filter(rowData => rowData)
        let markers1 = markers.map(marker => {
            const events = findData.find(data => data.length && data[0].deviceId === marker._id) || []
            if (events.length) {
                // console.log(events[0])
                marker.events[0] = events[0]
            }
            if ('gpsId' in marker) {
                const gpsEvent = findData.find(data => data.length && data[0].deviceId === marker.gpsId) || []
                if (gpsEvent.length) {
                    let [lat, lng] = (typeof gpsEvent[0]?.latlng === 'string') ? gpsEvent[0]?.latlng?.split(',').map(parseFloat) : [gpsEvent[0]?.lat || 0, gpsEvent[0]?.lng || 0]
                    // console.log(gpsEvent)
                    marker.position = { lat, lng }
                }
            }
            return marker
        })
        setMarkers(markers1)
    }
    useEffect(() => {
        if (!isLive) {
            setStartDate(todayDate('start'))
            setEndDate(todayDate('end'))
        }
    }, [isLive])

    useEffect(async () => {
        let gpsDuplicate = []
        let deviceIds = markers.map(marker => {
            let data = [marker._id];
            if (marker.gpsId) {
                if (!gpsDuplicate.includes(marker.gpsId)) {
                    gpsDuplicate.push(marker.gpsId)
                    data.push(marker.gpsId);
                }
            }
            return data
        })
        deviceIds = deviceIds.toString()
        const response = await getMultiDeviceHistory(deviceIds, 100, utcDate(startDate) + "Z", utcDate(endDate) + "Z")
        if (response && response.length) {
            setPolylineData(
                response.map(data => {
                    const filterData = data.map(rowData => {
                        if (rowData.deviceId.match("GPS")) {
                            const [lat, lng] = (typeof rowData?.latlng === 'string') ? rowData?.latlng?.split(',').map(parseFloat) : [rowData?.lat || 0, rowData?.lng || 0]
                            return { lat, lng }
                        }
                    }).filter(data => data)
                    if (data.length && filterData.length) {
                        return { _id: data[0].deviceId, path: filterData, color: hslToHex(getRandomNumber(0, 360), 80, 60) }
                    }
                }).filter(data => data)
            )
        }
        setHistory(response)
    }, [startDate, endDate])

    const playHandler = (seek) => {
        setRangeDate((prevDate) => {
            let newDate = prevDate.getTime()
            if ((newDate + seek) > new Date(endDate).getTime()) {
                setIsPlay(false)
            } else {
                newDate = newDate + seek
                rangeDataHandler(newDate)
            }
            return new Date(newDate)
        })
    }

    useEffect(() => {
        let interFunction = null
        if (isPlay) {
            interFunction = setInterval(() => playHandler(1000), 500);
        } else {
            clearInterval(interFunction);
        }
        return () => {
            if (interFunction) {
                clearInterval(interFunction);
            }
        }
    }, [isPlay])

    return isLoaded && !isLoad ? (
        <Card id={mapId} className="d-flex flex-column h-100 w-100 overflow-hidden">
            {(mapConfig.label || mapConfig.autoTrack || mapConfig.live || mapConfig.history || mapConfig.filter) &&
                <CardHeader>
                    {mapConfig.label &&
                        <Heading align="center" size={2}>{mapConfig.label}</Heading>
                    }
                    <div className='row justify-content-between flex-nowrap mt-1'>
                        <div className='row m-0'>
                            {mapConfig.history ?
                                <>
                                    <div className='col-lg-6 col-6 px-1 fade-in-right-onload' style={{ display: (!isLive ? 'block' : 'none') }}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DateTimePicker
                                                style={{ fontSize: 16 }}
                                                className='w-100'
                                                inputVariant="outlined"
                                                // disableFuture="true"
                                                label="Start Date"
                                                value={startDate}
                                                onChange={(event) => onChangeHandler({ name: 'startDate', target: { value: moment(event._d).format('YYYY-MM-DDTHH:mm:ss') } }, null)}

                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className='col-lg-6 col-6 px-1 fade-in-right-onload' style={{ display: (!isLive ? 'block' : 'none') }}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DateTimePicker
                                                style={{ fontSize: 16 }}
                                                className='w-100'
                                                inputVariant="outlined"
                                                // disableFuture="true"
                                                label="End Date"
                                                value={endDate}
                                                onChange={(event) => onChangeHandler({ name: 'endDate', target: { value: moment(event._d).format('YYYY-MM-DDTHH:mm:ss') } }, null)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </>
                                : null}
                        </div>
                        <div className='d-flex' style={{ gap: 10 }}>
                            {mapConfig.autoTrack ?
                                <button className={'fade-in-left-onload btn btn-light px-2 rounded-circle-px dropshadow mr-1' + (false ? ' danger-gradient text-white' : ' text-danger')} style={{ display: (isLive ? 'block' : 'none') }}>
                                    <svg viewBox="0 0 3.55 3.55" style={{ width: 20 }}>
                                        <g>
                                            <path fill={false ? 'white' : '#DC3545'} d="M3.55 1.92l-0.43 0c-0.03,0.32 -0.18,0.6 -0.39,0.82 -0.21,0.21 -0.5,0.36 -0.82,0.39l0 0.43 -0.28 0 0 -0.43c-0.32,-0.03 -0.6,-0.18 -0.82,-0.39l-0 -0c-0.21,-0.21 -0.36,-0.5 -0.39,-0.82l-0.43 0 0 -0.28 0.43 0c0.03,-0.32 0.18,-0.6 0.39,-0.82l0.01 -0.01c0.21,-0.21 0.5,-0.35 0.81,-0.38l0 -0.43 0.28 0 0 0.43c0.32,0.03 0.6,0.18 0.82,0.39 0.21,0.21 0.36,0.5 0.39,0.82l0.43 0 0 0.28zm-1.02 -0.9c-0.19,-0.19 -0.46,-0.31 -0.76,-0.31 -0.29,0 -0.56,0.12 -0.75,0.31l-0.01 0.01c-0.19,0.19 -0.31,0.46 -0.31,0.76 0,0.3 0.12,0.57 0.31,0.76l0 0c0.19,0.19 0.46,0.31 0.76,0.31 0.3,0 0.57,-0.12 0.76,-0.31l0 0c0.19,-0.19 0.31,-0.46 0.31,-0.76 0,-0.3 -0.12,-0.57 -0.31,-0.76z" />
                                        </g>
                                    </svg>
                                </button>
                                : null}
                            {mapConfig.filter ?
                                <div style={{ width: 150 }}>
                                    <Select label="Filter Device" onClick options={mapConfig.type} onChange={(e) => setFilter(e.target.value)} defaultValue={{ value: filter }} />
                                </div>
                                : null}
                            {mapConfig.history &&
                                <ButtonContainer>
                                    <Button buttonResult={() => setIsLive(true)} active={isLive}>Live</Button>
                                    <Button buttonResult={() => setIsLive(false)} active={!isLive}>History</Button>
                                </ButtonContainer>
                            }
                        </div>
                    </div>
                </CardHeader>
            }
            <CardBody loader={isLoad} loaderHeight="100%" className="flex-fill position-relative ">
                <div className='position-absolute justify-content-end' style={{ top: "10px", zIndex: "1", right: "10px" }}>
                    <button className='btn bg-white rounded-2 ' style={{ width: "40px", height: "40px" }} onClick={() => { setFullScreen(!fullScreen); fullScreens() }}>
                        {fullScreen ? <i className="fas fa-compress"></i> :
                            <i className="fas fa-expand"></i>}
                    </button>
                </div>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={mapConfig.coordinates}
                    zoom={mapConfig.zoom}
                    onLoad={onLoad}
                >
                    {markers.filter(marker => (filter !== 'all' ? marker.type === filter ? marker : null : marker)).map((marker, key) =>
                        marker.position &&
                        <React.Fragment key={key}>
                            <Marker
                                // key={key}
                                position={marker.position}
                                title={marker.name}
                                icon={{
                                    url: markerIcon(marker),
                                    scaledSize: new window.google.maps.Size(80, 80),
                                }}
                            // onClick={()=> onClickHandler(key)}
                            />
                            {/* <InfoBox
                            // defaultPosition={new window.google.maps.LatLng(props.center.lat, props.center.lng)}
                            position={marker.position}
                            // options={{ closeBoxURL: ``, enableEventPropagation: true }}
                            >
                            <div style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `12px` }}>
                                <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
                                Hello, Taipei!
                                </div>
                            </div>
                            </InfoBox> */}
                            {(!isLive && polylineData.map((polyData, polyIndex) =>
                                'gpsId' in marker && polyData._id === marker.gpsId ?
                                    // console.log("gps", polyData.path)
                                    <Polyline
                                        key={polyIndex}
                                        path={polyData.path}
                                        options={{
                                            strokeColor: polyData.color,
                                            // strokeColor: "#F15A29",
                                            strokeOpacity: 0.8,
                                            strokeWeight: 4,
                                        }}
                                    />
                                    : null)
                            )}
                        </React.Fragment>
                    )}

                    {/* {markers !== null && props?.path?.length > 0 && (
                        <Polyline
                            path={props?.path}
                            options={{
                                strokeColor: "#F15A29",
                                strokeOpacity: 1.0,
                                strokeWeight: 4,
                            }}
                        />
                    )} */}
                </GoogleMap>
                {mapConfig.history &&
                    <div className='mapHistoryBar fade-in-bottom-onload' style={{ display: (!isLive ? 'block' : 'none') }}>
                        <div>
                            <button className='btn btn-light' onClick={() => setIsPlay(!isPlay)}><i className={'fa fa-' + (isPlay ? 'pause' : 'play')}></i></button>
                            <button className='btn btn-light' onClick={() => playHandler(-1000)}><i className='fa fa-step-backward'></i></button>
                            <div className='w-100'>
                                <Input name='dateRange' valueDisplay={false} label="History" onChange={onChangeHandler} defaultValue={rangeDate.getTime()} type="range" min={new Date(startDate).getTime()} max={new Date(endDate).getTime()} />
                            </div>
                            <button className='btn btn-light' onClick={() => playHandler(1000)}><i className='fa fa-step-forward'></i></button>
                        </div>
                    </div>
                }
            </CardBody>
            {fullScreen ?
                <div>
                    <DefaultFooter />
                </div>
                : null}
        </Card>
    ) : null;
}

const mapStateToProps = (state) => ({
    lastEvent: state.lastEvent,
    organization: state.organization,
    configration: state.configration
});
export default connect(mapStateToProps, null)(Map)