import React from 'react'

function Notfound() {
    return (
        <div>
            <p>Network error!</p>
        </div>
    )
}

export default Notfound
