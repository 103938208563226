import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './motor.scss'

function Motor(props) {
  let vibration = [0, 0, 0]
  // let value = false
  // if ("vibration" in props) {
  //   vibration = props.vibration
  // }
  // if ("value" in props) {
  //   value = props.value
  // }
  let [value, setValue] = useState(false)
  const [events, setEvents] = useState({
    Status: {},
    Connection: {}
  })
  useEffect(async () => {
    let events1 = { ...events }
    let hubId = props?.config?.deviceId?.split("_")
    if (hubId) {
      hubId = hubId[0] + "_" + hubId[1]
    }
    let Events = props?.eventReducer?.deviceEvents

    if (Events.deviceId === props?.config?.deviceId) {
      events1.Status = Events
      setEvents(events1)
    } else if (Events.deviceId === hubId) {
      events1.Connection = Events
      setEvents(events1)
    }
  }, [props.eventReducer.deviceEvents])

  useEffect(() => {
    if (props?.config?.param in events?.Status) {
      if (events.Status[props.config.param]) {
        setValue(true)
      } else {
        setValue(false)
      }
    }
  }, [events.Status])

  let nightMode = false
  if ("nightMode" in props) {
    nightMode = props.nightMode;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" version="1.1"
      viewBox="-500 0 2432.95 1426.05"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={"location" in props ? props.location.x : "0%"}
      y={"location" in props ? props.location.y : "0%"}
      width={"size" in props ? props.size.w : "100%"}
      height={"size" in props ? props.size.h : "100%"}
    >
      <g>
        <g className={value ? "motorVibrate" : ""}>
          <path className="motorAnime0" d="M297.9 893.67c-67.16,-63.45 -66.58,-120.05 -67.11,-232.83l2.66 -10.08 5.74 -43.89 2.81 -9.26c17.54,9.53 37.94,22.59 56.01,32.65l394.32 227.36 47.18 -24.36 25.16 -69.78 -9.53 2.74c-0.05,0.02 4.66,6.79 -23.18,-9.88 -53.64,-32.11 -367.46,-216.4 -385.75,-220.49 -8.26,8.08 -89.18,53.51 -103.3,59.66 31.54,-125.27 68.95,-217.11 218.05,-365.31 62.92,-49.52 153.47,-119.77 270.9,-87.52 -8.97,-12.25 -138.92,-83.66 -159.61,-95.73 -65.31,-38.99 -108.77,-64.36 -201.6,-32.56 -219.33,75.11 -420.44,419.07 -359.65,648.64 21.68,81.87 59.55,100.24 121.12,136.48 27.58,16.24 147.17,88.37 165.79,94.15z" />
          <path className="motorAnime0" d="M812.87 1182.97c10.76,12.51 30.21,16.87 43.25,26.31 11.92,1.43 21.8,5.62 34.09,6.91 6.69,0.7 12.8,0.53 19.4,0.69l12.15 0.73c0.88,0.15 1.82,0.44 2.58,0.61 0.73,0.16 1.85,0.62 2.42,0.78 0.56,0.15 1.6,0.46 2.33,0.86 18.25,-8.38 84.17,-8.04 111.41,-9.79 -19.5,-12.32 -57.28,-6.08 -90.63,-63.33 -20.1,-34.51 -27.57,-81.94 -25.19,-130.2 8.72,-176.69 170.12,-418.54 338.66,-431.75 71.16,-5.58 90.97,33.56 110.26,43.25 -4.59,-16.06 -18.81,-41.22 -26.06,-55.99 -8.7,-17.72 -17.21,-36.4 -26.7,-53.78 -16.9,-30.97 -59.2,-69.48 -85.42,-75.06 -29.36,-1.18 -41.51,-8.25 -76.68,-4.21 -133.74,15.34 -251.68,144.6 -317.75,251.76 -41.63,67.53 -76.3,146.25 -90.8,237.76 -15.57,98.28 2.23,209.22 62.66,254.46z" />
          <path className="motorAnime1" d="M1076.29 1074.71c5.65,-17.23 18.76,-33.87 27.16,-50.81 20.66,1.32 34.08,3.29 52.02,-5.51l1.17 46.54c-21.97,12 -52.21,16.63 -80.36,9.78zm-50.42 -83.78c13.53,-7.75 26.45,-16.46 41.71,-22.91 3.39,26.51 9.67,30.87 20.71,46.2 -4.92,11.55 -20.5,37.84 -28.37,49.91 -18.84,-11.85 -32.03,-40.23 -34.05,-73.2zm0.41 -26.67c0.44,-38.09 17.23,-81.52 32.53,-110.35 10.44,4.62 18.76,10.43 28.78,14 -4.54,19.67 -12.65,38.11 -18.68,63.32 -3.95,16.5 -18.83,22.05 -42.64,33.03zm50.47 -137.56c5.18,-22.87 58.23,-74.95 78.35,-83.91 1.13,70.37 -0.16,35.84 -39.28,84.2l-14.06 15.04 -25.01 -15.34zm169.59 -48.28c8.47,-18.43 18.91,-30.45 26.25,-48.56 21.2,10.96 35.54,42.43 34.98,73.26l-39.62 22.12c-9.91,-26.04 -7.39,-25.72 -21.62,-46.81zm-68.56 -50.43c21.79,-9.84 55.22,-21.32 79.86,-7.03l-26.41 48.52c-32,-2.43 -29.48,1.58 -53.62,4.94l0.17 -46.44zm-137.26 482.07l17.11 -0.75c73.07,-1.23 132.8,-43.1 180.29,-86.28l44.99 -45.42c6.12,-11.42 12.79,-14.47 19.43,-28.83l-74.1 -43.68c-4.44,12.17 -11.38,17.64 -21.22,25.78 -8.83,7.31 -15.84,14.63 -28.61,19.84l-0.02 -48.15 23.92 -12.96c-4.82,-2.61 -11.51,-5.13 -16.88,-7.44 -5.25,0.81 -35.47,28.28 -63.45,-2.36 -42.12,-7.36 -20.45,-80.38 -4.84,-106.28 12.06,-20.02 52.71,-73.93 90.74,-46.92 32.46,11.21 33.53,26.76 35.01,64.47l14.81 9.27c0.95,-45.17 13.84,-57.53 50.7,-71.38 -1.39,16.52 -4.07,31.98 -8.22,47.41 -9.91,36.83 -7.54,35.17 -19.25,38.18l40.11 22.02c13.87,7.75 24.43,14.81 37.67,20.74 30.17,-52.91 45.38,-116.74 50.2,-175.26 4.92,-59.73 -7.54,-114.74 -35.31,-154.01 -19.29,-9.69 -39.1,-48.83 -110.26,-43.25 -168.54,13.2 -329.94,255.06 -338.66,431.75 -2.38,48.26 5.08,95.69 25.19,130.2 33.35,57.25 71.13,51.01 90.63,63.33z" />
          <path className="motorAnime1" d="M652.76 611.06c-7.46,-73.97 76.95,-30.1 82.98,32.47 6.65,68.98 -76.69,29.89 -82.98,-32.47zm-118.7 -67.59c-5.98,-34.1 18.16,-50.22 45.98,-31.04 13.8,9.51 33.48,33.71 36.53,60.23 8.28,71.96 -71.24,35.04 -82.51,-29.19zm-141.36 -152.74c6.33,8.3 53.57,33.26 65.94,40.35 38.92,22.29 58.19,26.32 58.03,48.77l-165 -98.33c-0.33,0.81 -0.54,1.91 -0.65,2.52l-4.78 151.81c18.29,4.09 332.11,188.38 385.75,220.49 27.84,16.66 23.13,9.9 23.18,9.88 -4.57,-20.95 -3.63,-189.56 -0.06,-214.12 -14.05,-3.45 -99.36,-54.69 -117.06,-65.53 -38.85,-23.79 -81.64,-44.14 -118.02,-68.11 -1.5,-7.97 1.81,-0.72 7.06,-12.97 -22.77,-8.39 -99.04,-58.58 -132.53,-75.31l-1.84 60.55z" />
          <path className="motorAnime1" d="M1088.68 430.62l-32.57 11.53c-15.31,9.56 -32.06,16.22 -47.77,26.39 -16.89,10.93 -31.66,20.96 -45.37,31.9 -8.84,7.05 -14.58,10.31 -21.55,17.19 -5,4.94 -4.36,5.16 -9.28,9.8l-29.61 28.54c-48.81,57.25 -74.11,85.09 -112.59,157.68 -8.72,16.45 -17.25,38.91 -25.27,49.83l-25.16 69.78c-1.2,11.74 -1.38,10.21 -7.64,18.58 1.09,11.48 -0.06,13.39 -2.52,23.42l-3.55 12.1c-0.2,0.58 -0.68,1.71 -0.85,2.17 -0.17,0.45 -0.77,2.55 -0.89,2.1 -0.12,-0.44 -0.62,1.37 -0.94,2.05 0.58,9.9 -0.36,10.64 -4.37,16.88 2.48,3.66 1.85,0.86 2.2,7.73 0.07,1.46 -0.82,9.54 -1.06,11.3 -1.02,7.42 -2.31,13.96 -3.04,19.04 -0.28,11.01 -0.15,9.74 -4.54,16.25l2.36 37.05 -4.2 13.75c8.83,6.16 3.97,-10.3 9.07,14.64 1.16,5.67 0.36,13.09 0,18.94l3.97 13.19c3.54,6.72 9.11,21.17 6.05,28.71 -11.23,3.62 -45.41,-20.88 -56.91,-27.67l-282.97 -164.42c-19.2,-11.1 -36.63,-21.03 -57.22,-32.86 -14.08,-8.08 -46.11,-23.03 -53.54,-34.98 -4.25,8.45 -4.37,-10.8 -2.51,10.97 0.99,11.6 9.83,10.6 20.84,17.24 22.16,13.37 -2.36,12.96 23,28.64 13.6,8.41 55.21,28.88 63.71,39.43 21.01,7.1 45.07,23.98 64.51,35.11 22.3,12.76 40.2,23.85 60.96,35.59 42.91,24.26 82.75,46.89 123.81,71.54l49.26 29.86c21.29,7.43 46.22,27.09 67.5,37.72 27.94,13.95 28.15,34.41 62.84,45.64 -60.43,-45.24 -78.23,-156.18 -62.66,-254.46 14.5,-91.51 49.16,-170.23 90.8,-237.76 66.07,-107.16 184.01,-236.42 317.75,-251.76 35.17,-4.03 47.32,3.03 76.68,4.21 -32.6,-30.12 -101.53,-23.98 -146.76,-12.59z" />
          <path className="motorAnime2" d="M755.08 552.1c-3.58,24.55 -4.51,193.16 0.06,214.12l9.53 -2.74c8.02,-10.93 16.55,-33.39 25.27,-49.83 38.49,-72.59 63.78,-100.43 112.59,-157.68l29.61 -28.54c4.92,-4.65 4.28,-4.86 9.28,-9.8 6.97,-6.88 12.71,-10.14 21.55,-17.19 13.71,-10.94 28.48,-20.96 45.37,-31.9 15.71,-10.17 32.46,-16.83 47.77,-26.39l0.31 -26.37c1.67,-12.69 2.74,-30.53 -1,-40.31l-300.33 176.64z" />
          <path className="motorAnime2" d="M242.91 595.51c14.12,-6.15 95.04,-51.58 103.3,-59.66l4.78 -151.81c0.11,-0.62 0.33,-1.72 0.65,-2.52 23.38,-9.44 16.69,2.83 41.04,9.22l1.84 -60.55c7.75,-6 8.81,-9.36 19.11,-5.56 10.88,4.02 11.44,8.65 23.91,11.4l1.56 -47.89c11.99,-17.73 23.4,-2.17 38.73,4.48 7.66,-7.72 1.06,3.62 4.1,-7.23 1.27,-4.53 1.13,-7.46 1.2,-12.23 0.18,-12.53 -2.62,-13.36 2.61,-22.61 13.05,-12.76 26.8,3.83 41.21,9.34 0.05,-5.96 -0.41,-13.42 -0.22,-19.13 0.1,-2.88 0.23,-6.36 0.74,-9.16 1.24,-6.84 0.53,-4.32 2.54,-7.97 20.91,-10.15 21.34,3.8 41.53,10.89 0.03,-4.34 -0.49,-12.45 -0.2,-16.26 0.29,-3.82 0.02,-2.33 0.67,-5.73 1.37,-7.12 -0.03,-3.2 2.53,-7.2 15.57,-6.81 24.13,2.12 37.21,10.24 3.61,-4.29 4.29,4.66 4.51,-13.54 17.94,-15.99 21.96,-2.89 42.14,6.72 6.56,-5.8 6.12,-6.5 16.09,-9.74 20.29,10.65 40.08,23.14 60.02,33.84 10.95,-1.3 57.65,-30.08 58.55,-30.95 7.14,-6.87 4.39,-1.12 7.44,-12.46 -15.5,-5.81 -40.61,-33.97 -68.62,-46.76 -225.58,-67.2 -461.34,256.61 -488.95,452.83z" />
          <path className="motorAnime0" d="M485.73 250.56c5.39,8.32 103.56,63.63 113.28,66.26l-1.93 44.84c-12.04,-5.49 -112.32,-62.54 -119.25,-69.04 -15.33,-6.64 -26.74,-22.21 -38.73,-4.48l74.91 42.45c24.5,14.34 49.96,28.33 73.39,41.88 -3.79,4.8 -30.09,21.28 -37.68,24.03 -15.48,5.61 -109.96,-57.93 -112.18,-60.47 -12.46,-2.75 -13.03,-7.38 -23.91,-11.4 -10.3,-3.81 -11.36,-0.44 -19.11,5.56 33.5,16.74 109.76,66.92 132.53,75.31 -5.26,12.24 -8.57,4.99 -7.06,12.97 36.38,23.97 79.17,44.32 118.02,68.11 17.7,10.83 103.01,62.08 117.06,65.53l300.33 -176.64 -75.17 -45.19c-10.3,10.85 -4.76,41.73 -6.14,69.04l-205.53 119.28c-10.76,3.93 -32.03,-12.29 -43.65,-19.09 -24.83,-14.54 -110.64,-66.37 -126.12,-70.62l0.43 -113.56c8.11,-5.99 20.57,-1.03 7.73,-8.11 -24.92,-15.87 -55.2,-29.46 -80.02,-47.31 -14.41,-5.51 -28.17,-22.1 -41.21,-9.34z" />
          <path className="motorAnime3" d="M1178.37 977.98c-17.2,20.71 -51.77,13.11 -56.46,-19.66 -11.87,-83.07 118.17,-187.16 115.71,-70.41 -14.58,-5.95 -26.95,-19.04 -44.22,-15.91 -20.69,3.75 -53.21,42.28 -46.23,76.85 3.17,15.68 18.85,20.7 31.21,29.12zm-56.37 1.83c22.13,29.29 59.23,5.97 63.45,2.36 6.22,2.84 11.51,4.52 16.88,7.44l25.92 15.5 74.1 43.68c-6.64,14.37 -13.31,17.42 -19.43,28.83l160.89 -90.46 -74.42 -49.18 -10.65 19.33c-13.24,-5.93 -23.8,-12.98 -37.67,-20.74l-40.11 -22.02 -23.23 -14.2 -14.81 -9.27c-1.48,-37.71 -2.55,-53.26 -35.01,-64.47 -33.45,10.44 -54.43,20.5 -73.61,57.71 -15.27,29.62 -27.84,71.29 -12.29,95.49z" />
          <path className="motorAnime4" d="M1282.91 1077.62l-44.99 45.42c-47.49,43.18 -107.22,85.05 -180.29,86.28 -10.04,10.6 -43.76,27.43 -58.22,35.66 -20.61,11.72 -39.05,22.7 -59.04,34.3 -20.18,11.71 -39.07,23.85 -58.81,34.48 -20.94,11.28 -42.65,27.02 -63.47,34.8l1.15 45.78c6.62,-2.66 601,-344.74 623.7,-361.65l0.86 -45.54 -160.89 90.46z" />
          <path className="motorAnime4" d="M762.39 408.84c2.9,18.6 1.08,63.35 0.98,86.49 -0.11,25.13 2.52,20.2 5.2,23.26l205.53 -119.28c2.83,-55.94 -1.35,-67.92 -1.38,-110.75 -24.44,15.22 -195.72,115.53 -210.32,120.27z" />
          <path className="motorAnime0" d="M235.98 649.7c8.89,16.13 95.32,61.02 110.96,70.07l224.05 129.98c22.24,12.58 97.49,59.16 115.13,64.55 11.81,-7.49 23.85,-14.87 37.01,-20.6 0.31,-0.68 0.81,-2.49 0.94,-2.05 0.12,0.44 0.73,-1.65 0.89,-2.1 0.17,-0.46 0.64,-1.59 0.85,-2.17l3.55 -12.1c2.46,-10.04 3.62,-11.95 2.52,-23.42 -13.51,7.41 -24.5,14.67 -38.59,20.62l-314.82 -180.89c-19.96,-12.05 -89.68,-55.35 -107.42,-60.47 -6.77,4.51 -26.2,16.83 -35.07,18.61z" />
          <path className="motorAnime5" d="M768.56 518.6c-2.67,-3.07 -5.31,1.87 -5.2,-23.26 0.1,-23.15 1.92,-67.89 -0.98,-86.49 -27.19,-15.05 -53.71,-30.23 -80.71,-46.37 -25.81,-15.42 -57.02,-34.76 -82.45,-47.15l-0.43 113.56c15.47,4.25 101.29,56.08 126.12,70.62 11.62,6.81 32.89,23.02 43.65,19.09z" />
          <path className="motorAnime0" d="M233.69 706.08c15.91,5.74 41.87,23.33 58.34,33.18 36.44,21.8 378.91,221.88 392.94,226.04l31.89 -16.67c0.73,-5.08 2.02,-11.62 3.04,-19.04 0.24,-1.76 1.14,-9.84 1.06,-11.3 -0.35,-6.87 0.28,-4.07 -2.2,-7.73l-34.26 19.09c-56.99,-26.19 -396.43,-236.79 -422.24,-241.34 -10.65,4.66 -22.13,9.9 -28.58,17.76z" />
          <path className="motorAnime6" d="M606.97 1145.37c10.19,10.2 70.12,42.1 88.58,52.97 28.84,16.98 62.43,34.93 90.24,52.27l64.3 -36.23c7.8,-5.92 1.7,-1.11 6.02,-5.1 -13.04,-9.43 -32.49,-13.8 -43.25,-26.31 -34.69,-11.23 -34.89,-31.69 -62.84,-45.64 -21.28,-10.62 -46.21,-30.29 -67.5,-37.72 -18.7,12.97 -60.97,31.99 -75.56,45.76z" />
          <path className="motorAnime5" d="M819.24 1394.34l-1.15 -45.78 -42.44 -24.18c-2.86,-1.8 -3.66,-2.24 -6.09,-4.23 -4.32,-3.54 -2.46,-1.66 -5.5,-5.76 -14.1,-0.2 -34.83,-16.49 -47.7,-24.24 -16.56,-9.98 -31.17,-17.95 -47.06,-27.01 -30.16,-17.19 -62.75,-35.73 -91.56,-53.72 -21.08,-11.46 -71.57,-37.89 -86.99,-50.74 -5.33,9.67 -3.14,31.16 -2.26,44.58 10.87,9.69 29.24,17.88 41.78,25.17 13.71,7.97 26.01,15.35 40.7,23.63 28.1,15.84 230.85,135.86 248.26,142.27z" />
          <path className="motorAnime0" d="M241.72 748.45c5.52,12.25 1.26,5.68 25.07,19.26l85.37 49.81c38.36,21.66 75.05,42.4 111.79,65.14l225.98 130.11 24.76 -10.84 -2.36 -37.05 -23.5 13.85c-2.5,0.9 -1.45,1.52 -6.78,0.62 -2.18,-0.37 -4.46,-1.21 -6.47,-2.13 -1.77,-0.81 -11.1,-6.72 -14.21,-8.68l-343.79 -200.18c-12.41,-7.47 -43.46,-29.85 -56.08,-26.81l-19.78 6.91z" />
          <path className="motorAnime3" d="M800.33 1308.23c14.8,-9.97 36.87,-13.9 37.62,7.7 -7.23,6.76 -9.44,8.02 -21.74,6.82 -12.26,-1.19 -13.48,-4.52 -15.88,-14.52zm128.77 -88.37c-24.39,10.48 -58.18,32.72 -82.3,46.35 -17.71,10.01 -68.58,42.59 -82.72,45.4l-0.02 2.79c3.04,4.1 1.18,2.23 5.5,5.76 2.44,1.99 3.23,2.43 6.09,4.23l42.44 24.18c20.82,-7.78 42.53,-23.52 63.47,-34.8 19.73,-10.63 38.62,-22.77 58.81,-34.48 19.99,-11.6 38.43,-22.57 59.04,-34.3 14.46,-8.23 48.18,-25.05 58.22,-35.66l-17.11 0.75c-27.25,1.75 -93.16,1.41 -111.41,9.79z" />
          <path className="motorAnime7" d="M577.91 1193.07c30.3,16.86 59.36,35.12 89.91,52.3 14.96,8.41 29,16.68 44.96,25.83 14.81,8.49 30.38,19.94 45.51,24.81 8.46,-3.67 9.33,-9.38 15,-19.76 5.43,-9.93 9.27,-16.13 12.51,-25.66 -27.81,-17.34 -61.41,-35.29 -90.24,-52.27 -18.46,-10.86 -78.39,-42.76 -88.58,-52.97 -11.79,11.65 -20.06,33.31 -29.07,47.71z" />
          <path className="motorAnime0" d="M256.64 794.17c8.84,11.54 205.46,122.11 222.3,131.52 30.83,17.22 211.29,126.84 226.55,128.88l14.07 -5.31c0.36,-5.84 1.16,-13.27 -0,-18.94 -5.1,-24.94 -0.24,-8.49 -9.07,-14.64l-17.66 9.81c-6.74,5.01 -43.35,-20.14 -50.83,-24.64l-264.51 -153.84c-18.69,-10.44 -98.92,-59.15 -109.55,-61.53l-11.3 8.7z" />
          <path className="motorAnime3" d="M545.67 1142.18c11.82,0.42 15.39,4.07 18.74,12.82 -10.45,14.19 -29.71,13.47 -38.47,0 2.84,-9.63 6.14,-13.3 19.73,-12.82zm-54.91 16.51c15.42,12.85 65.91,39.27 86.99,50.74l0.16 -16.35c9,-14.4 17.28,-36.05 29.07,-47.71 14.59,-13.77 56.86,-32.78 75.56,-45.76l-49.26 -29.86c-7.97,13.02 -58.31,38.14 -72.09,45.96 -14.8,8.4 -62.02,34.38 -70.42,42.97z" />
          <path className="motorAnime8" d="M267.94 785.47c10.62,2.39 90.85,51.09 109.55,61.53l264.51 153.84c7.48,4.51 44.08,29.65 50.83,24.64l-2.9 -12.72 -225.98 -130.11c-36.73,-22.73 -73.43,-43.48 -111.79,-65.14l-85.37 -49.81c-23.82,-13.58 -19.55,-7.01 -25.07,-19.26 -11.61,23.64 15.69,23.39 26.22,37.02z" />
          <path className="motorAnime1" d="M437.54 336.03c2.22,2.54 96.7,66.08 112.18,60.47 7.59,-2.75 33.89,-19.23 37.68,-24.03 -23.43,-13.55 -48.89,-27.54 -73.39,-41.88l-74.91 -42.45 -1.56 47.89z" />
          <path className="motorAnime8" d="M230.79 660.84c5.68,10.4 4.24,8.08 15.73,14.44 10.49,5.81 11,3.24 15.75,13.04 25.81,4.55 365.25,215.15 422.24,241.34l1.61 -15.36c-17.64,-5.39 -92.89,-51.97 -115.13,-64.55l-224.05 -129.98c-15.63,-9.05 -102.07,-53.94 -110.96,-70.07l-2.53 1.06 -2.66 10.08z" />
          <path className="motorAnime8" d="M239.19 606.88l5.4 5.45c16.14,13.77 -9.33,-6.31 3.84,3.01 7.22,5.11 14.99,6.68 22.62,15.75 17.73,5.12 87.45,48.42 107.42,60.47l314.82 180.89 -0.95 -14.84 -394.32 -227.36c-18.07,-10.06 -38.48,-23.12 -56.01,-32.65l-2.81 9.26z" />
          <path className="motorAnime8" d="M282 830.18c14.89,0.53 89.46,48.31 108.33,58.68 45.21,24.86 295.48,176.2 316.28,181.31l-1.11 -15.6c-15.26,-2.04 -195.72,-111.66 -226.55,-128.88 -16.84,-9.4 -213.45,-119.98 -222.3,-131.52 -0.42,0.65 -8.12,1.2 -1.54,13.95 2.85,5.53 5.01,4.89 9.07,7.15 9.86,5.51 13.24,4.4 17.83,14.91z" />
          <path className="motorAnime8" d="M261.5 741.54c12.61,-3.04 43.67,19.34 56.08,26.81l343.79 200.18c3.11,1.96 12.44,7.88 14.21,8.68 2.02,0.92 4.29,1.76 6.47,2.13 5.33,0.9 4.29,0.28 6.78,-0.62 -9.75,0.67 -5.68,-4.08 -3.86,-13.43 -14.03,-4.16 -356.5,-204.24 -392.94,-226.04 -16.47,-9.86 -42.43,-27.45 -58.34,-33.18l0.18 14.1c5.06,2.11 9.12,4.05 14.51,7.53 13.06,8.43 7.63,2.25 13.11,13.84z" />
          <path className="motorAnime9" d="M652.76 611.06c6.29,62.36 89.63,101.46 82.98,32.47 -6.03,-62.58 -90.44,-106.45 -82.98,-32.47z" />
          <path className="motorAnime9" d="M534.05 543.47c11.27,64.23 90.79,101.15 82.51,29.19 -3.05,-26.52 -22.73,-50.72 -36.53,-60.23 -27.82,-19.17 -51.96,-3.06 -45.98,31.04z" />
          <path className="motorAnime9" d="M785.8 1250.6c-3.24,9.52 -7.08,15.72 -12.51,25.66 -5.67,10.37 -6.55,16.09 -15,19.76 2.55,9.29 -0.04,8.66 5.81,15.58 14.14,-2.8 65,-35.39 82.72,-45.4 24.12,-13.63 57.91,-35.87 82.3,-46.35 -0.73,-0.4 -1.78,-0.71 -2.33,-0.86 -0.57,-0.15 -1.69,-0.61 -2.42,-0.78 -0.76,-0.17 -1.7,-0.46 -2.58,-0.61l-12.15 -0.73c-6.6,-0.15 -12.72,0.01 -19.4,-0.69 -12.3,-1.29 -22.17,-5.48 -34.09,-6.91 -4.32,3.99 1.77,-0.82 -6.02,5.1l-64.3 36.23z" />
          <path className="motorAnime6" d="M1178.37 977.98c-12.36,-8.42 -28.05,-13.44 -31.21,-29.12 -6.98,-34.57 25.54,-73.1 46.23,-76.85 17.28,-3.13 29.65,9.95 44.22,15.91 2.46,-116.75 -127.58,-12.66 -115.71,70.41 4.68,32.77 39.26,40.37 56.46,19.66z" />
          <path className="motorAnime2" d="M297.9 893.67c15.94,17.92 55.8,30.8 86.07,33.85 -8.5,-10.55 -50.1,-31.03 -63.71,-39.43 -25.36,-15.68 -0.84,-15.27 -23,-28.64 -11.01,-6.64 -19.84,-5.64 -20.84,-17.24 -1.86,-21.77 -1.74,-2.52 2.51,-10.97l3.05 -1.05c-4.59,-10.52 -7.97,-9.41 -17.83,-14.91 -4.05,-2.26 -6.21,-1.62 -9.07,-7.15 -6.57,-12.74 1.12,-13.29 1.54,-13.95l11.3 -8.7c-10.53,-13.63 -37.83,-13.38 -26.22,-37.02l19.78 -6.91c-5.49,-11.59 -0.05,-5.41 -13.11,-13.84 -5.39,-3.48 -9.45,-5.42 -14.51,-7.53l-0.18 -14.1c6.45,-7.86 17.93,-13.1 28.58,-17.76 -4.75,-9.8 -5.25,-7.23 -15.75,-13.04 -11.49,-6.36 -10.05,-4.04 -15.73,-14.44 0.53,112.78 -0.05,169.38 67.11,232.83z" />
          <path className="motorAnime1" d="M477.82 292.62c6.94,6.51 107.22,63.55 119.25,69.04l1.93 -44.84c-9.72,-2.63 -107.89,-57.94 -113.28,-66.26 -5.24,9.24 -2.43,10.08 -2.61,22.61 -0.07,4.76 0.07,7.7 -1.2,12.23 -3.04,10.84 3.56,-0.49 -4.1,7.23z" />
          <path className="motorAnime0" d="M723.53 1062.45l-16.93 7.72c-20.8,-5.11 -271.07,-156.44 -316.28,-181.31 -18.87,-10.38 -93.44,-58.15 -108.33,-58.68l-3.05 1.05c7.43,11.95 39.46,26.9 53.54,34.98 20.59,11.83 38.02,21.75 57.22,32.86l282.97 164.42c11.5,6.79 45.67,31.29 56.91,27.67 3.07,-7.55 -2.51,-21.99 -6.05,-28.71z" />
          <path className="motorAnime10" d="M1121.99 979.81c-15.55,-24.2 -2.98,-65.87 12.29,-95.49 22.06,-36.85 42.32,-48.71 73.61,-57.71 -38.03,-27.02 -78.68,26.9 -90.74,46.92 -15.61,25.9 -37.28,98.93 4.84,106.28z" />
          <path className="motorAnime9" d="M1026.28 964.26c23.81,-10.99 38.69,-16.53 42.64,-33.03 6.03,-25.21 14.14,-43.64 18.68,-63.32 -10.02,-3.57 -18.34,-9.38 -28.78,-14 -15.3,28.83 -32.09,72.25 -32.53,110.35z" />
          <path className="motorAnime9" d="M1177.78 728l-0.17 46.44c24.15,-3.36 21.63,-7.37 53.62,-4.94l26.41 -48.52c-24.65,-14.29 -58.08,-2.81 -79.86,7.03z" />
          <path className="motorAnime9" d="M1025.87 990.93c2.02,32.97 15.21,61.35 34.05,73.2 7.88,-12.07 23.45,-38.36 28.37,-49.91 -11.04,-15.33 -17.32,-19.7 -20.71,-46.2 -15.25,6.46 -28.18,15.17 -41.71,22.91z" />
          <path className="motorAnime9" d="M1246.34 778.42c14.22,21.1 11.7,20.77 21.62,46.81l39.62 -22.12c0.55,-30.83 -13.78,-62.3 -34.98,-73.26 -7.34,18.11 -17.78,30.13 -26.25,48.56z" />
          <path className="motorAnime9" d="M1076.74 826.7l25.01 15.34 14.06 -15.04c39.12,-48.36 40.42,-13.83 39.28,-84.2 -20.12,8.95 -73.18,61.03 -78.35,83.91z" />
          <path className="motorAnime9" d="M1076.29 1074.71c28.15,6.85 58.38,2.21 80.36,-9.78l-1.17 -46.54c-17.95,8.8 -31.36,6.83 -52.02,5.51 -8.4,16.94 -21.51,33.58 -27.16,50.81z" />
          <path className="motorAnime1" d="M526.94 259.9c24.82,17.85 55.09,31.44 80.02,47.31 12.56,9.12 43.67,-8.99 32.72,-17.52 -9.03,-3.53 -101.83,-53.68 -109.67,-66.05 -2.01,3.64 -1.29,1.13 -2.54,7.97 -0.51,2.81 -0.65,6.28 -0.74,9.16 -0.19,5.72 0.27,13.17 0.22,19.13z" />
          <path className="motorAnime6" d="M577.74 1209.42c28.81,17.99 61.4,36.52 91.56,53.72 15.89,9.06 30.51,17.03 47.06,27.01 12.86,7.75 33.6,24.04 47.7,24.24l0.02 -2.79c-5.85,-6.93 -3.26,-6.3 -5.81,-15.58 -15.13,-4.87 -30.69,-16.32 -45.51,-24.81 -15.96,-9.15 -30,-17.42 -44.96,-25.83 -30.54,-17.18 -59.6,-35.44 -89.91,-52.3l-0.16 16.35z" />
          <path className="motorAnime11" d="M616.25 202.04c20.48,10.67 72.81,39.27 86.37,52.09 11.36,-6.64 23.84,-10.69 31.89,-21.27 -19.94,-10.7 -39.72,-23.19 -60.02,-33.84 -9.97,3.24 -9.53,3.94 -16.09,9.74 -20.18,-9.61 -24.21,-22.71 -42.14,-6.72z" />
          <path className="motorAnime9" d="M1257.72 900.35l23.23 14.2c11.7,-3.01 9.33,-1.35 19.25,-38.18 4.15,-15.43 6.83,-30.89 8.22,-47.41 -36.86,13.86 -49.75,26.22 -50.7,71.38z" />
          <path className="motorAnime0" d="M351.65 381.52l165 98.33c0.17,-22.45 -19.11,-26.48 -58.03,-48.77 -12.37,-7.08 -59.61,-32.05 -65.94,-40.35 -24.35,-6.39 -17.66,-18.65 -41.04,-9.22z" />
          <path className="motorAnime1" d="M571.54 234.53c21.79,16.72 55.22,32.17 79.52,46.62 6.45,5.28 13.59,-3.65 22.91,-11.27 -7.04,-9.44 -38.64,-24.78 -51.27,-31.89 -9.83,-5.53 -16.56,-9.39 -26.5,-15.28 -10.25,-6.07 -17.15,-6.3 -21.68,-17.38 -2.56,4 -1.16,0.08 -2.53,7.2 -0.65,3.4 -0.38,1.91 -0.67,5.73 -0.29,3.81 0.23,11.92 0.2,16.26z" />
          <path className="motorAnime9" d="M1228.24 1005.1l-25.92 -15.5 -23.92 12.96 0.02 48.15c12.77,-5.21 19.78,-12.53 28.61,-19.84 9.83,-8.14 16.78,-13.61 21.22,-25.78z" />
          <path className="motorAnime0" d="M530.01 223.63c7.85,12.37 100.64,62.52 109.67,66.05 5.65,5.15 25.68,-2.98 11.38,-8.54 -24.3,-14.45 -57.73,-29.91 -79.52,-46.62 -20.19,-7.1 -20.62,-21.04 -41.53,-10.89z" />
          <path className="motorAnime0" d="M574.53 205.33c4.53,11.08 11.43,11.31 21.68,17.38 9.94,5.89 16.67,9.75 26.5,15.28 12.63,7.1 44.23,22.44 51.27,31.89l15.83 -10.36 -78.06 -43.95c-13.08,-8.12 -21.64,-17.05 -37.21,-10.24z" />
          <path className="motorAnime8" d="M611.74 215.58l78.06 43.95 12.82 -5.39c-13.55,-12.82 -65.89,-41.43 -86.37,-52.09 -0.23,18.2 -0.9,9.25 -4.51,13.54z" />
          <path className="motorAnime12" d="M233.46 650.76l2.53 -1.06c8.86,-1.78 28.3,-14.1 35.07,-18.61 -7.63,-9.07 -15.4,-10.65 -22.62,-15.75 -13.17,-9.31 12.3,10.76 -3.84,-3.01l-5.4 -5.45 -5.74 43.89z" />
          <path className="motorAnime13" d="M692.33 857.62l0.95 14.84c14.09,-5.95 25.08,-13.21 38.59,-20.62 6.25,-8.37 6.44,-6.84 7.64,-18.58l-47.18 24.36z" />
          <path className="motorAnime14" d="M525.94 1155c8.76,13.47 28.02,14.19 38.47,0 -3.36,-8.75 -6.93,-12.39 -18.74,-12.82 -13.59,-0.49 -16.88,3.18 -19.73,12.82z" />
          <path className="motorAnime14" d="M800.33 1308.23c2.4,10 3.62,13.33 15.88,14.52 12.3,1.2 14.51,-0.06 21.74,-6.82 -0.75,-21.6 -22.82,-17.67 -37.62,-7.7z" />
          <path className="motorAnime2" d="M686.12 914.29l-1.61 15.36 34.26 -19.09c4,-6.23 4.95,-6.98 4.37,-16.88 -13.16,5.74 -25.2,13.12 -37.01,20.6z" />
          <path className="motorAnime2" d="M684.97 965.3c-1.82,9.35 -5.89,14.1 3.86,13.43l23.5 -13.85c4.39,-6.51 4.25,-5.24 4.54,-16.25l-31.89 16.67z" />
          <path className="motorAnime15" d="M1056.1 442.15l32.57 -11.53c-12.79,-1.69 -19.61,-10.37 -32.27,-14.85l-0.31 26.37z" />
          <polygon className="motorAnime13" points="689.93,1012.77 692.83,1025.49 710.48,1015.68 714.69,1001.93 " />
          <polygon className="motorAnime16" points="705.49,1054.57 706.6,1070.17 723.53,1062.45 719.56,1049.26 " />
          <path className="motorAnime14" d="M1403.35 989.31c2.7,-6.44 -1.79,-14.3 -11.95,-15.09 -10.17,-0.8 -18.12,3.13 -21.74,8 -6.08,8.17 -0.26,14.89 8.97,16.48 9.11,1.56 21.58,-1.9 24.72,-9.38z" />
          <circle className="motorAnime6" transform="matrix(0.67087 -0.0382541 -0.279501 0.96024 1332.47 1003.46)" r="51.31" />


          {/* <!-- <path className="motorAnime17" d="M1312.08 1003.55l41.19 0c-5.53,17.33 -19.23,31.38 -30.6,31.38 -11.37,0 -16.11,-14.05 -10.58,-31.38zm33.73 -41.18c-14.93,0 -32.91,18.44 -40.17,41.18 -7.26,22.74 -1.04,41.18 13.89,41.18 14.93,0 32.91,-18.44 40.17,-41.18 7.26,-22.74 1.04,-41.18 -13.89,-41.18z"/> --> */}
          <g className="rodeRotate1">
            <path className="motorAnime17 rodeRotate" style={{ animationPlayState: value ? "running" : "paused" }} d="M1306.64 1339.8l74.85 0c0,20.67 -16.75,37.42 -37.42,37.42 -20.67,0 -37.42,-16.76 -37.42,-37.42zm37.42 -49.12c-27.13,0 -49.12,21.99 -49.12,49.12 0,27.13 21.99,49.12 49.12,49.12 27.13,0 49.12,-21.99 49.12,-49.12 0,-27.13 -21.99,-49.12 -49.12,-49.12z" />
          </g>
          <path className="motorAnime3" d="M599.23 315.32c25.44,12.39 56.64,31.73 82.45,47.15 27,16.13 53.52,31.32 80.71,46.37 14.6,-4.74 185.88,-105.05 210.32,-120.27l-172.22 -99.12 -201.26 125.87z" />
          <rect className="motorAnime6 rodeCut" style={{ animationPlayState: value ? "running" : "paused" }} width="104.66" height="9.5" rx="4.75" ry="4.75" />
        </g>
        {/* <line className="motorAnime18 MotorStr0" x1="1645.74" y1="1183.22" x2="1334.53" y2="1003.55" />
        <line className="motorAnime18 MotorStr0" x1="1516" y1="1424.18" x2="1516" y2="1105.6" />
        <line className="motorAnime18 MotorStr0" x1="1193.17" y1="1293.19" x2="1516" y2="1105.6" />
        <path className="motorAnime19" style={{ filter: nightMode ? "brightness(3)" : "" }} d="M1465.57 828.65l50.43 -91.68 50.43 91.68 -32.1 0 0 276.95c0,10.12 -8.21,18.33 -18.33,18.33 -10.12,0 -18.33,-8.21 -18.33,-18.33l0 -276.95 -32.1 0z" />
        <path className="motorAnime20" style={{ filter: nightMode ? "brightness(2)" : "" }} d="M1726.17 925.14l108.55 -4.75 -57.87 91.96 -16.13 -27.76 -233.92 135.93 -0.05 -0.08c-0.49,0.36 -1.01,0.69 -1.55,1.01 -8.75,5.09 -19.97,2.11 -25.06,-6.64 -5.09,-8.75 -2.11,-19.97 6.64,-25.06 0.54,-0.31 1.09,-0.59 1.64,-0.85l-0.05 -0.08 233.92 -135.93 -16.13 -27.76z" />
        <path className="motorAnime21" style={{ filter: nightMode ? "brightness(3)" : "" }} d="M1781.06 1200.39l54.18 89.52 -104.62 -2.17 16.05 -27.8 -239.6 -138.33c-5.61,-3.13 -9.41,-9.13 -9.41,-16.01 0,-10.12 8.21,-18.33 18.33,-18.33 3.44,0 6.66,0.95 9.41,2.6l239.6 138.33 16.05 -27.8z" />
        <text x="65.2%" y="98.9%" style={{ filter: nightMode ? "brightness(3)" : "" }} className="textSet motorAnime22">Vibration</text>
        <text x="61%" y="50%" className="textSet motorAnime19" style={{ filter: nightMode ? "brightness(3)" : "" }}>Z = {value ? (vibration[2] / 1000).toFixed(3) : 0} meter/sec</text>
        <text x="76%" y="70%" className="textSet motorAnime20" style={{ filter: nightMode ? "brightness(2)" : "" }}>X = {value ? (vibration[0] / 1000).toFixed(3) : 0} meter/sec</text>
        <text x="76%" y="90%" className="textSet motorAnime21" style={{ filter: nightMode ? "brightness(3)" : "" }}>Y = {value ? (vibration[1] / 1000).toFixed(3) : 0} meter/sec</text> */}
      </g>
    </svg>
  )
}
const mapStateToProps = (state) => ({
  eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(Motor)
