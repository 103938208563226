import React, { useState, useEffect } from 'react'
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import { connect } from 'react-redux'
import AddHubForm from '../../components/addHubForm/addHubForm'
import Mypopup from '../../components/popup/mypopup'
import InlineLoader from '../../components/loader/inlineLoader'
import FullLoader from '../../components/loader/fullPageLoader'
import { getHubsList } from '../../functions/functions'
import HubInfoPopup from '../../components/hubInfoPopup/HubInfoPopup'
import { orgAction } from '../../services/actions/orgAction'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { hubDelete } from '../../services/utilities/events'
const HubManager = (props) => {
    let { levelId } = useParams()
    let [confirmPopup, setConfirmPopup] = useState(false)
    let [addHubCreatePopup, setAddHubCreatePopup] = useState(false)
    let [result, setResult] = useState(false)
    let [addHub, setAddHub] = useState(false)
    let [inlineLoader, setInlineLoader] = useState(false)
    let [loader, setLoader] = useState(false)
    let [hubData, setHubData] = useState(null)
    let [hubList, setHubList] = useState([])
    const [hubInfo, setHubInfo] = useState(false)



    useEffect(() => {
        setLoader(true)
        let hubs = getHubsList(props?.organization?.organization?.allhubs, props.userInfo.varification?.hierarchy)
        setHubList(hubs)
        setLoader(false)
    }, [])
    const HubGet = async () => {
        setAddHub(null)
        setLoader(true)
        let devices = getHubsList(props?.organization?.organization?.allhubs, props.userInfo.varification?.hierarchy)
        setHubList(devices)
        setLoader(false)
    }
    const popupOnchangeHandler = (e, f) => {
        setResult(e)
        if (!e.statusCode) {
            let orgId = e.levelId.split("_")[0]
            setAddHub(null)
            setAddHubCreatePopup(["Success!", f === "update" ? "Hub Updated successfully." : "Hub created successfully.", "success"])
            orgAction(orgId)
        }
        else {
            setAddHubCreatePopup(["Failure!", "Hub already exist.", "danger"])
        }
        // {
    }
    const addEditHub = (data = null) => {
        setAddHub(true)
        if (data) {
            setHubData(data)
        } else {
            setHubData(null)
        }
    }

    const removeHub = async (data, key) => {
        setInlineLoader(true)
        let hubs = [...hubList]
        if (true) {
            await hubDelete(data._id).then(() => {
            })
            hubs.splice(key, 1)
            setHubList(hubs)
            setInlineLoader(false)
        }
        setConfirmPopup(null)
    }
    return (
        <div className='mt-2 px-3'>
            <Card>
                <CardHeader>
                    <div className='row'>
                        <div className='col'>
                            <Heading size={1} className="mr-3" >Hubs</Heading>
                        </div>
                        {/* {manageDevice ? */}
                        <div>
                            <div className='d-flex mr-1 '>
                                <ButtonContainer>
                                    <Button buttonResult={() => addEditHub()} >Add Hub</Button>
                                </ButtonContainer>
                            </div>
                        </div>
                        {/* : null} */}
                    </div>
                </CardHeader>
            </Card>
            {/* <CardBody className="p-3 overflow-auto" style={{ height: "calc(100vh - 280px)" }}  > */}
            <Card className='overflow-hidden mt-1'>
                <CardHeader className='row m-0 align-items-center'>
                    <div className='col'><b>ID</b></div>
                    <div className='col'><b>Name</b></div>
                    <div className='col-12 col-sm-4 col-lg-1'><b>Type</b></div>
                    <div className='col-12 col-sm-4 col-lg-3'><b>LevelId</b></div>
                    <div className='col-2 d-flex justify-content-end' style={{ width: "100px" }}><b>Action</b></div>
                </CardHeader>
            </Card>
            {loader ? <FullLoader /> : hubList.length > 0 ?
                hubList.map((hub, key) =>
                    <Card className="overflow-hidden mt-1">
                        <CardHeader className="row m-0 align-items-center">
                            <div className='col' >
                                {hub._id}
                            </div>
                            <div className='col' >{hub.name}</div>
                            <div className='col-12 col-sm-4 col-lg-1' >{hub.type}</div>
                            <div className='col-12 col-sm-4 col-lg-3' >{hub.levelId}</div>
                            <div className='col-2 d-flex justify-content-end' style={{ width: "100px" }}>
                                <ButtonContainer className="p-2" flat="light">
                                    <Button color="text-primary" buttonResult={() => addEditHub(hub)}>
                                        <i className="fas fa-pen"></i>
                                    </Button>
                                    <Button color="text-danger" buttonResult={() => setConfirmPopup({ hub, key })}>
                                        <i className="fas fa-trash"></i>
                                    </Button>
                                </ButtonContainer>
                                {/* <ButtonContainer color='primary'>
                                    <Button buttonResult={() => addEditHub(hub)}><i className="far fa-edit"></i></Button>
                                    <Button buttonResult={() => setConfirmPopup({ hub, key })} ><i className="fas fa-trash"></i></Button>
                                </ButtonContainer> */}
                            </div>
                        </CardHeader>
                        {/* <div className='row p-3'> */}
                        {/* </div> */}
                    </Card>
                )
                : null}
            {/* </CardBody> */}
            {addHubCreatePopup ? <Mypopup title={addHubCreatePopup[0]} type={addHubCreatePopup[2]} onClose={(e) => {
                setAddHubCreatePopup(null);
            }}>
                <p className="p-3" > {addHubCreatePopup[1]}</p>
                <div className="d-flex justify-content-center p-3">
                    {addHubCreatePopup[2] === "danger" ?
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setAddHubCreatePopup(null);
                        }}>Ok</button>
                        :
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setAddHubCreatePopup(null); setHubInfo(true)
                        }}>Ok</button>}
                </div>
            </Mypopup >
                : null}
            {addHub ? <Mypopup title={"Add Hub"} onClose={() => { setAddHub(null) }}>
                <AddHubForm data={hubData} org={props.organization.organization._id} type={"direct"} onClose={() => setAddHub(null)} onChange={(e, f) => popupOnchangeHandler(e, f)} />
            </Mypopup> :
                null
            }
            {confirmPopup ? <Mypopup title={"Confirmation"} onClose={(e) => {
                setConfirmPopup(null);
            }}>
                <p className="p-3" > Are You Sure!</p>
                <div className="d-flex justify-content-center p-3" style={{ gap: 10 }}>
                    {inlineLoader ? <InlineLoader width="70px" /> :
                        <>
                            <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                                removeHub(confirmPopup.hub, confirmPopup.key);
                            }}>Yes</button>
                            <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => {
                                setConfirmPopup(null);
                            }}>No</button>
                        </>
                    }
                </div>
            </Mypopup >
                : null}
            {hubInfo ?
                <HubInfoPopup res={result} onClose={() => { setHubInfo(false); HubGet() }} />
                : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    organization: state.organization,
    configration: state.configration,
    userInfo: state.userInfo
});
export default connect(mapStateToProps, null)(HubManager)
