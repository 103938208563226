import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './lineProgress.scss'

const LinearProgress = (props) => {
    const [night, setNight] = useState(false)
    const [config, setConfig] = useState({
        classColor: "primary-gradient",
        cssColor: "",
        value: 70,
        label: "Label"
    })

    const [events, setEvents] = useState({
        Status: {},
        Connection: {}
    })

    useEffect(async () => {
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")
        if (hubId) {
            hubId = hubId[0] + "_" + hubId[1]
        }
        let Events = props?.eventReducer?.deviceEvents

        if (Events.deviceId === props?.config?.deviceId) {
            events1.Status = Events
            setEvents(events1)
        } else if (Events.deviceId === hubId) {
            events1.Connection = Events
            setEvents(events1)
        }
    }, [props.eventReducer.deviceEvents])

    useEffect(() => {
        if (props?.config?.param in events?.Status) {
            let config1 = { ...config }
            if (events.Status[props.config.param] !== null) {
                config1.value = events.Status[props.config.param]
            } else {
                config1.value = 0
            }
            setConfig(config1)
        }
    }, [events.Status])

    useEffect(() => {
        setNight(props?.night)
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                    config1.classColor = "";
                } else {
                    config1.classColor = props.config.color;
                    config1.cssColor = "";
                }
            }
            if ("label" in props.config && props.config?.label) {
                config1.label = props.config.label
            } else {
                config1.label = "Label"

            }
            setConfig(config1)
        }
    }, [props])
    useEffect(() => {
        setNight(props?.night)
    }, [props])
    return (
        <div className={'linearProgress ' + (night ? "active2" : "")}>
            <div className='d-flex justify-content-between'>
                <b>{config.label}</b>
                <div>{config.value}%</div>
            </div>
            <div className='progress'>
                <div className={'innerProgress ' + config.classColor} style={config.cssColor ? { backgroundColor: config.cssColor, width: (config.value + "%") } : { width: (config.value + "%") }}></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(LinearProgress)
