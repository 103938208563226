import { useState, useEffect } from "react";
import MyDropDown, { MyDropDownBody } from "../dropdown/dropdown";
import "./input.scss"

export default function Input(props) {
    const [state, setState] = useState({
        defaultValue: "",
    })
    const [inputPassword, setInputPassword] = useState(false)
    const [inputInfo, setInputInfo] = useState(false)
    useEffect(() => {
        if ("message" in props) {
            if ("status" in props.message) {
                if (props.message.status) {
                    setInputInfo(true)
                    return false
                }
                setInputInfo(false)
                return false

            }
            setInputInfo(false)
            return false

        }
        setInputInfo(false)
        return false

    }, [props.message])
    const [hover, setHover] = useState(false)
    const dropDown = (state, message, type) => {
        return (
            <MyDropDown openToggle={(e) => setHover(e)}>
                <button className='btn btn-light fas fa-info-circle rounded-circle-px text-danger m-1' style={{ zIndex: 1 }}></button>
                <MyDropDownBody open={hover} right>
                    <div className='py-3 text-left p-3 ' style={{ width: "300px" }}>
                        <small>{message}</small>
                    </div>
                </MyDropDownBody>
            </MyDropDown>)
    }
    useEffect(() => {
        let state1 = { ...state }
        if ("defaultValue" in props) {
            state1.defaultValue = props.defaultValue
        }
        setState(state1)
        // return () => {
        // }
    }, [props])

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            props.onEnter(e)
        }
    }

    const onChangeHandler = (e) => {
        let state1 = { ...state }
        state1.defaultValue = e.target.value;
        setState(state1);
        if ("onChange" in props) {
            props.onChange({
                name: "name" in props ? props.name : "please add name attr.",
                target: { value: e.target.value },
            })
        } else {
            console.error("please add onChange attr. to get event")
        }
    }
    return (
        <div className={"myInput text-unselectable " + (props?.className)}>
            <div className={"myInputContainer"} >
                <fieldset className={inputInfo ? " danger" : ""}>
                    <legend className={inputInfo ? " danger" : ""}>{"label" in props ? props.label : "Add Label Attr."}</legend>
                </fieldset>
                {props?.type === "textarea" ?
                    <textarea
                        value={state.defaultValue}
                        className={"ml-3 px-0 border-0 bg-transparent w-100"}
                        style={{ minHeight: 100 }}
                        onKeyDown={(e) => "onEnter" in props ? onKeyPress(e) : null}
                        onChange={(e) => onChangeHandler(e)}
                        placeholder="type here..."
                    ></textarea>
                    : <input
                        value={state.defaultValue}
                        className={
                            "type" in props ?
                                props.type === "number" ? "inpNumber" :
                                    props.type === "range"? ('valueDisplay' in props && props.valueDisplay)?
                                     "ml-3 px-0" :
                                     "mx-3 px-0" :
                                        null :
                                         null
                        }
                        style={
                            "type" in props ?
                                props.type === "color" ? { height: "40px" } :
                                    null : null
                        }
                        min={"min" in props ? props.min ? props.min : 0 : 0}
                        max={"max" in props ? props.max ? props.max : 100 : 100}
                        onKeyDown={(e) => "onEnter" in props ? onKeyPress(e) : null}
                        onChange={(e) => onChangeHandler(e)} type={"type" in props ? !inputPassword ? props.type : "text" : "text"}
                        placeholder="type here..."
                    />}

                {props?.type === "range" && (('valueDisplay' in props && props.valueDisplay) || !('valueDisplay' in props)) ?
                    <div className="text-center btn btn-light rounded-circle-px m-1 p-1" style={{ minWidth: 40 }}>
                        {state.defaultValue}
                    </div>
                    : null}
                {inputInfo ?
                    <MyDropDown openToggle={(e) => setHover(e)}>
                        <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger m-1' style={{ zIndex: 1 }}></span>
                        <MyDropDownBody open={hover} right>
                            <div className='py-3 text-left p-3 ' style={{ width: "300px" }}>
                                <small>{props.message.msg}</small>
                            </div>
                        </MyDropDownBody>
                    </MyDropDown>
                    : null}
                {"type" in props ? props.type === "password" ?
                    <button className={inputPassword ? 'btn btn-light fa fa-eye-slash rounded-circle-px m-1 ' : 'btn btn-light fa fa-eye rounded-circle-px m-1'} onClick={() => setInputPassword(!inputPassword)}></button>
                    : null : null}
            </div>
        </div>
    )
}