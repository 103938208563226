import React, { useEffect, useState } from 'react'
import { postImage } from '../../../services/utilities/events'
import InlineLoader from '../../loader/inlineLoader'
const ImageUploader = (props) => {
    const [imgName, setImgName] = useState(null)
    const [logoLoader, setLogoLoader] = useState(false)

    useEffect(() => {
        if (props?.defaultValue) {
            setImgName(props.defaultValue)
        } else {
            setImgName("upload image")
        }
    }, [props.defaultValue])
    
    const onChangeHandler = async (file) => {
        setLogoLoader(true)
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
              const image = new Image();
              image.src = event.target.result;
              image.onload = async () => {
                const width = image.width;
                const height = image.height;
                const size = file.size;
                const fileType = file.type;
                // check if the image meets your criteria for size, width, height, and type
                if (size > 1024 * 1024) { // max size 1MB
                    setLogoLoader(false)
                  reject("File size too large. Please select a smaller image.");
                // } else if (width !== 200 || height !== 100) { // required dimensions 300x100
                //   reject("Invalid image dimensions. Please select an image with dimensions of 300x100 pixels.");
                } else if (!fileType.startsWith("image/")) { // check if the file type is an image
                    setLogoLoader(false)
                  reject("Invalid file type. Please select an image file.");
                } else {
                    setImgName('uploading...')
                    let res = await postImage(file)
                    if('onChange' in props){
                        props.onChange({name: 'imageId', target: { value: res?.filename }})
                    }
                    setImgName(res?.filename)
                    setLogoLoader(false)
                  resolve();
                }
              };
              
            //   let res = await postImage(event.target.result)
            };
            reader.readAsDataURL(file);
          });
    }
    return (
        <label style={{border: '1px solid #1b75bc', color: 'grey', cursor: 'pointer', gap: '10px'}} className='w-100 m-0 px-2 rounded-circle-px d-flex align-items-center justify-content-between'>
            <span className='text-truncate p-2'>{imgName?imgName:'upload image'}</span>
            {logoLoader? 
                <InlineLoader width={"30px"}/>
            :
                <>
                    <i className='mr-2 fa fa-upload'></i>
                    <input type='file' accept="image/*" hidden onChange={(e) => 
                    {
                        const file = e.target.files[0];
                        if(file){
                            onChangeHandler(file)
                            .then(() => {
                                // setImgFile(file)
                                })
                                .catch((error) => {
                                alert(error);
                                });
                        }
                        }
                    }/>
                </>
            }
        </label>
    )
}

export default ImageUploader