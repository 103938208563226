import store from '../../services/utilities/store'
import { getEventsByLevel } from '../../services/utilities/events';


let response = null
export const LastEventAction = async (id) => {
    if (id) {
        response = await getEventsByLevel(id)
    }
    store.dispatch({
        type: "Get Events",
        // payload: response
        payload: { type: "last", data: response }
    });
};