import React, { useEffect, useState } from 'react'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../components/card/card'
import ReactReducerAction from '../../services/actions/ReactReducerAction'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import { connect } from 'react-redux'
import './userDetail.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getDate, getRandomNumber, hslToHex } from '../../functions/functions'

const UserDetail = (props) => {
    const history = useHistory();
    let organization = props.organization.organization
    let totalUser = []
    const [users, setUsers] = useState([])
    const [logs, setLogs] = useState([])
    const [update, setUpdate] = useState(false)
    let [me, setMe] = useState(props?.location?.state)
    const getUsers = (e) => {
        setUpdate(false)
        let filterdata = [...e]
        let filterdata1 = []
        if (props?.location?.state) {
            setMe(props?.location?.state)
            let propsData = props?.location?.state
            filterdata.map(user => {
                if (user.createdBy === propsData.email) {
                    filterdata1.push(user)
                }
            })
        } else {
            let id = props?.location?.search
            let users = {}
            if (id) {
                id = id.split("=")[1]
                filterdata.map(userMatch => {
                    if (userMatch._id === parseInt(id)) {
                        users = userMatch
                    }
                })
                setMe(users)
                if (users) {
                    filterdata.map(user => {
                        if (user.createdBy === users.email) {
                            filterdata1.push(user)
                        }
                    })
                }
            }
        }
        setUsers(filterdata1)
    }
    useEffect(() => {
        setUpdate(true)
    }, [props?.location?.state,])

    const userDetail = (data) => {
        // getUsers(totalUser)
        history.replace({
            pathname: '/userDetail/' + organization._id,
            search: '?id=' + data._id,
            state: data
        })
    }


    return (
        <div className='m-3'>
            <ReactReducerAction update={update} onUserUpdate={(e) => getUsers(e)} />
            <Card>
                <CardHeader>
                    {me ?
                        <div className='d-flex'>
                            <InnerCard className="col-1 p-0" style={{ backgroundColor: hslToHex(getRandomNumber(0, 360), 50, 50) + "" }}>
                                <div className='box'>
                                    <h1 className='heading'>{me.firstName.charAt(0).toUpperCase() + me.lastName.charAt(0).toUpperCase()}</h1>
                                </div>
                            </InnerCard>
                            <div className='col p-2 ml-2'>
                                <Heading>{me.firstName + " " + me.lastName}</Heading>
                                <p><b>{me.designation}</b></p>
                                <b>Assign Levels:</b>
                                {me.levels.map(level =>
                                    <span className='bg-light rounded-circle-px p-2 px-3'>{level.levelId}</span>
                                )}
                            </div>
                        </div>
                        : <Heading></Heading>
                    }
                </CardHeader>
            </Card>
            <div className='d-flex justify-content-center '>
                <div className='col-md-6 p-2'>
                    <Card className="mt-2">
                        <CardHeader>
                            <Heading size={2}>Logs</Heading>
                        </CardHeader>
                        <CardBody className="p-3 ">
                            {logs.length ?
                                logs?.map(() =>
                                    <InnerCard className="p-3 mb-1">
                                        <Heading size={3}> Hello</Heading>
                                    </InnerCard>
                                ) : <p>logs not found</p>
                            }
                        </CardBody>
                    </Card>
                </div>
                <div className='col-md-6 p-2'>
                    <Card className="mt-2">
                        <CardHeader>
                            <Heading size={2}>Employees</Heading>
                        </CardHeader>
                        <CardBody className="p-3 ">
                            {users.length ?
                                users?.map((user) =>
                                    <InnerCard className="d-flex p-3 mb-1 align-items-center">
                                        <div className='col'>
                                            <Heading size={3}> {user.firstName + " " + user.lastName}</Heading>
                                        </div>
                                        <div className='col'>
                                            <Heading size={3}><b>{user.designation}</b></Heading>
                                        </div>
                                        {user.levels.map(level =>
                                            <span className='bg-light rounded-circle-px p-2 px-3'>{level.levelId}</span>
                                        )}
                                        <ButtonContainer color="success" className="mr-1 justify-content-end">
                                            <Button buttonResult={() => userDetail(user)}>Detail</Button>
                                        </ButtonContainer>
                                    </InnerCard>
                                )
                                : <p>user not available</p>}
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    organization: state.organization,
    userInfo: state.userInfo,
})
export default connect(mapStateToProps, null)(UserDetail)
