import moment from 'moment'
import React, { useState } from 'react'
import Card, { CardHeader, Heading, InnerCard } from '../card/card'

const TableConfig = (props) => {
    let [data, setData] = useState({
        list: props.data,
        id: props.chart._id,
        param: props?.chart?.series[0].y,
        unit: props.chart._id
    })

    return (
        <div >
            <CardHeader className="row m-0 align-items-center">
                <div className='col'>
                    <b>
                        {data.param}
                    </b>
                </div>
                <div className='col'>
                    <b>
                        Date
                    </b>
                </div>
            </CardHeader>
            <InnerCard className="overflow-auto mt-1" style={{ maxHeight: "300px" }}>
                {data?.list?.length > 0 ?
                    data?.list.map(param =>
                        <CardHeader className='row mx-3'>
                            <div className='col'>
                                {param[data.param]}
                            </div>
                            <div className='col'>
                                {moment(param.date).format("LLL")}
                            </div>
                        </CardHeader>
                    )
                    : <p>data is not Available</p>}
            </InnerCard>
        </div>
    )
}

export default TableConfig