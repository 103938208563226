import React, { useEffect, useState } from 'react'
import Select from '../../select/select'
import { connect } from 'react-redux';
import { Button, ButtonContainer } from '../../arraybutton/arraybutton';
import Input from '../../input/input';
import { getLevelIds, get_Devices_Selected_HubIds_WithoutMatchType } from '../../../functions/functions';
import { CardHeader, Heading } from '../../card/card';


const DataMode = (props) => {
    const [data, setData] = useState({})




    useEffect(() => {
        if (props?.defaultValue) {
            setData(props.defaultValue)
        }
    }, [props.defaultValue])




    const onChangeHandler = (e, type, index = null) => {
        let data1 = { ...data }

        if (type !== "addParam" && type !== "deleteParam" && type !== "params" && type !== "addHub" && type !== "coordinates" && type !== "deviceSelection" && type !== "live" && type !== "history" && type !== "filter") {
            data1[type] = e.target.value
        }
        if ("onChange" in props) {
            if (type === "live" || type === "history" || type === "filter") {
                let dataMode = { ...data["dataMode"] }
                data1["dataMode"] = dataMode
                if (data1.dataMode) {
                    if (type === "live") {
                        if (data?.dataMode?.live) {
                            data1["dataMode"][type] = !data1?.dataMode?.live
                        } else {
                            data1.dataMode[type] = true
                        }
                    } else if (type === "history") {
                        if (data?.dataMode?.history) {
                            data1["dataMode"][type] = !data1?.dataMode.history
                        } else {
                            data1["dataMode"][type] = true
                        }
                    }
                    else if (type === "filter") {
                        if (data?.dataMode?.filter) {
                            data1["dataMode"][type] = !data1?.dataMode.filter
                        } else {
                            data1["dataMode"][type] = true
                        }
                    }
                }
                props.onChange({ name: 'dataMode', target: { value: dataMode } })
            }
            else {
                props.onChange({ name: type, target: { value: data1[type] } })
            }
        }
        setData(data1)
    }


    return (
        <>
            <div className='propertiesLabels'>Data Mode</div>
            <CardHeader className="row m-0">
                <label className='d-flex m-0 col-6' style={{ gap: 10 }}>
                    <input type="checkbox" checked={data?.dataMode?.live} name='live' onChange={(e) => onChangeHandler(e, "live")} />
                    <Heading size={3}> Live </Heading>
                </label>
                <label className='d-flex m-0 col-6' style={{ gap: 10 }}>
                    <input type="checkbox" checked={data?.dataMode?.history} name='history' onChange={(e) => onChangeHandler(e, "history")} />
                    <Heading size={3}> History </Heading>
                </label>
                <label className='d-flex m-0 col-6' style={{ gap: 10 }}>
                    <input type="checkbox" checked={data?.dataMode?.filter} name='filter' onChange={(e) => onChangeHandler(e, "filter")} />
                    <Heading size={3}> Filter </Heading>
                </label>
            </CardHeader>
        </>
    )
}
const mapStateToProps = (state) => ({
    organization: state.organization,
    configration: state.configration,
    userInfo: state.userInfo

});
export default connect(mapStateToProps, null)(DataMode)
