import React, { useEffect, useState } from 'react'
import Input from '../../input/input'

const Gap = (props) => {

    const [gap, setGap] = useState("0")
    const onChangeHandler = (e) => {
        let data = e.target.value
        setGap(data)
        if ("onChange" in props) {
            props.onChange({ name: "gap", target: { value: data } })
        }

    }

    useEffect(() => {
        if (props?.defaultValue) {
            if ("gap" in props?.defaultValue) {
                if (props?.defaultValue.gap) {
                    setGap(props.defaultValue.gap)
                }
            }
        }
    }, [props.defaultValue])
    return (
        props?.styleTag.includes("gap")?
        <>
            <div className='propertiesLabels'>Gaping</div>
            <Input
                className="mb-3"
                name="gap"
                label="Gap"
                type="range"
                defaultValue={gap?parseInt(gap):""}
                onChange={(e) => onChangeHandler(e)}
            />
        </>
        :null
    )
}

export default Gap