import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { DeviceLastEvents } from '../../../services/utilities/events';
import { Button, ButtonContainer } from '../../../components/arraybutton/arraybutton';
import Card, { CardBody, CardHeader, Heading } from '../../../components/card/card';
import Map from '../../../components/map/map'
import GPS from "../../../assets/svgs/GPS.svg";
import Car from "../../../assets/svgs/car.svg";
import Fuel from "../../../assets/svgs/Fuel.svg";
import Battery from "../../../assets/svgs/Battery.svg";
import Trip from "../../../assets/svgs/Trip.svg";
import GSM from "../../../assets/svgs/GSM.svg";

//import libraries
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';
import { utcDate } from '../../../functions/functions';
import CardLoader from '../../../components/loader/cardLoader';
import InlineLoader from '../../../components/loader/inlineLoader';

const MapView = (props) => {
    const apiKey = "AIzaSyA9osJ2QBSR6f9oBEZULavTAJzZxVccQMo"
    const [markers, setMarkers] = useState([])
    const [selectMarker, setSelectMarker] = useState(null)
    const [history, setHistory] = useState([])
    const [path, setPath] = useState([])
    const [live, setLive] = useState(true)
    const [startDate, setStartDate] = useState(moment().subtract(1, 'hour'))
    const [endDate, setEndDate] = useState(moment())
    const [autoTrack, setAutoTrack] = useState(false)
    const [selectMarkerEvent, setSelectMarkerEvent] = useState(null)
    const [inlineloader, setInlineloader] = useState(false)
    useEffect(() => {
        let markers1 = []
        let lastEvent = props.lastEvent.lastEvent.devices
        props.organization?.organization?.alldevices?.map(device => {
            let device1 = { ...device }
            device1.history = []
            lastEvent.map(eve => {
                let events1 = {
                    Status: null,
                    Connection: null
                }
                if (eve._id === device1._id) {
                    eve.events.map(ev => {
                        if (ev.deviceId === device1._id) {
                            events1.Status = ev
                            if ("latlng" in ev) {
                                device1.position = latlngConverter(ev.latlng)
                            }
                        } else if (ev.deviceId === device.hubId) {
                            events1.Connection = ev
                        }
                    })
                    device1.events = events1
                }
            })
            markers1.push(device1)
        })
        setMarkers(markers1)
    }, []);


    useEffect(() => {
        if (markers.length) {
            let markers1 = [...markers]
            markers1.map((marker, key) => {
                if (marker._id.match(props?.eventReducer?.deviceEvents.deviceId)) {
                    markers1[key].events = EventReducer(marker._id, marker.events);
                    if ("latlng" in markers1[key].events.Status) {
                        if (selectMarker && marker._id.match(selectMarker._id) && !live) {
                            let mar = { _id: marker._id, events: EventReducer(marker._id, marker.events), position: latlngConverter(markers1[key].events.Status.latlng) }
                            setSelectMarkerEvent(mar);
                        } else {
                            markers1[key].position = latlngConverter(markers1[key].events.Status.latlng)
                        }
                    }
                    if (selectMarker && selectMarker._id.match(props?.eventReducer?.deviceEvents.deviceId)) {
                        let selectMarker1 = { ...selectMarker }
                        selectMarker1.events = markers1[key].events
                        setSelectMarker(selectMarker1)
                    }
                }
            })
            setMarkers(markers1)
        }
    }, [props.eventReducer.deviceEvents])
    const EventReducer = (_id, events) => {
        let events1 = { ...events }
        let hubId = _id
        if (hubId) {
            hubId = hubId[0] + "_" + hubId[1]
        }
        let Events = props?.eventReducer?.deviceEvents
        if (Events.deviceId === _id) {
            events1.Status = Events
        } else if (Events.deviceId === hubId) {
            events1.Connection = Events
        }
        return events1
    }
    const onChangeHandler = (e, type) => {
        if (type === 'marker') {
            let marker = { ...markers[e] }
            let keys = {}
            marker?.parameter?.map(param => {
                keys[param.value] = { name: param.name, unit: param.unit }
            })
            marker.keys = keys
            setSelectMarker(marker)
            setAutoTrack(true)
        } else if (type === 'startDate') {
            let startDate1 = moment(e._d).format('YYYY-MM-DDTHH:mm:ss')
            setStartDate(startDate1);
        } else if (type === 'endDate') {
            let endDate1 = moment(e._d).format('YYYY-MM-DDTHH:mm:ss')
            setEndDate(endDate1);
        }
    }
    useEffect(() => {
        if (selectMarker) {
            if (selectMarkerEvent && selectMarker !== selectMarkerEvent._id) {
                let markers1 = [...markers]
                markers1.map((marker, key) => {
                    if (marker._id === selectMarkerEvent._id) {
                        markers1[key].events = selectMarkerEvent.events
                        markers1[key].position = selectMarkerEvent.position
                    }
                })
                setMarkers(markers1)
            }
            if (!live) {
                getDeviceHistory()
            } else {
                setPath([])
            }
        } else {
            setPath([])
        }
    }, [live, startDate, endDate, selectMarker])

    const getDeviceHistory = async () => {
        setInlineloader(true)
        let startDate1 = utcDate(startDate) + "Z"
        let endDate1 = utcDate(endDate) + "Z"
        let response = await DeviceLastEvents(selectMarker?._id, null, startDate1, endDate1)
        setHistory(response)
        let path1 = [];
        response.map((row, key) => {
            if (parseInt(row.latlng)) {
                path1.push(latlngConverter(row.latlng))
            }
        })
        let markers1 = [...markers]
        markers1.map((marker, key) => {
            if (marker._id.match(selectMarker._id)) {
                let mar = { _id: marker._id, events: EventReducer(marker._id, marker.events), position: latlngConverter(markers1[key].events.Status.latlng) }
                setSelectMarkerEvent(mar);
                markers1[key].position = path1[0]
            }
        })
        setMarkers(markers1)
        setPath(path1)
        setInlineloader(false)
    }
    const latlngConverter = (latlng) => {
        let latlng1 = latlng
        latlng1 = latlng1.split(",")
        latlng1 = { lat: parseFloat(latlng1[0]), lng: parseFloat(latlng1[1]) }
        return latlng1
    }

    const onCloseHandler = () => {
        if (selectMarkerEvent) {
            let markers1 = [...markers]
            markers1.map((marker, key) => {
                if (marker._id === selectMarkerEvent._id) {
                    markers1[key].events = selectMarkerEvent.events
                    markers1[key].position = selectMarkerEvent.position
                }
            })
            setMarkers(markers1)
        }
        setPath([])
        setSelectMarker(null);
        setAutoTrack(false)
        setSelectMarkerEvent(null)
    }
    return (
        <div className='row m-0'>
            <div className='col-12'>
                <Card>
                    <CardHeader>
                        <div className='d-flex align-items-center justify-content-between px-5 pt-3 pb-3'>
                            <div className='d-flex align-items-center'>
                                <div className='mr-3'>
                                    <i className="fas fa-car" style={{ fontSize: 100, color: 'rgba(170,170,170)' }}></i>
                                </div>
                                <div className='d-flex align-items-end justify-content-center px-3'>
                                    <div className='text-right' style={{ marginBottom: '-15px' }}>
                                        <div style={{ fontSize: 60, lineHeight: '35px' }}>
                                            {markers.filter(marker => marker.events?.Connection?.value).length < 9 ? '0' : ''}{markers.filter(marker => marker.events?.Connection?.value).length}
                                        </div>
                                        <div>
                                            online
                                        </div>
                                    </div>
                                    <div className='d-flex text-left align-items-center' style={{ color: 'rgba(170,170,170)', margin: '20px 0 -30px 0 ' }}>
                                        <div style={{ margin: '0 10px 0 20px', borderLeft: '2px solid rgba(170,170,170)', transform: 'skew(-30deg)', height: '40px' }}>&nbsp;</div>
                                        <div>
                                            <div style={{ fontSize: 30, lineHeight: '20px', marginTop: '10px' }}>
                                                {markers.length < 9 ? '0' : ''}{markers.length}
                                            </div>
                                            <div style={{ fontSize: 12 }}>total vehicales</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <img className='mr-3' width="70px" src={Trip} />
                                <div className='text-center' style={{ marginBottom: '-15px' }}>
                                    <div style={{ fontSize: 60, lineHeight: '35px' }}>
                                        {markers.filter(marker => marker.events?.Status?.a250).length < 9 ? '0' : ''}{markers.filter(marker => marker.events?.Status?.a250).length}
                                    </div>
                                    <div>
                                        on trip
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='mr-3'>
                                    <i className="far fa-bell" style={{ fontSize: 60, color: 'rgba(170,170,170)' }}></i>
                                </div>
                                <div className='d-flex align-items-end justify-content-center px-3'>
                                    <div className='text-center' style={{ marginBottom: '-15px' }}>
                                        <div style={{ fontSize: 60, lineHeight: '35px' }}>
                                            {markers.filter(marker => marker.events?.Status?.pr).length < 9 ? '0' : ''}{markers.filter(marker => marker.events?.Status?.pr).length}
                                        </div>
                                        <div>
                                            priority
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='px-5 pt-3'>
                                <div className='text-center'>
                                    <div style={{ fontSize: 60, lineHeight: '35px' }}>
                                        {markers.filter(marker => marker.events?.Status?.a240).length < 9 ? '0' : ''}{markers.filter(marker => marker.events?.Status?.a240).length}
                                    </div>
                                    <div>
                                        running
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                    {selectMarker &&
                        <CardBody className="row align-items-center m-2">
                            <div className='col p-0'>
                                {/* {!live &&  */}
                                <>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DateTimePicker
                                            style={{ fontSize: 16 }}
                                            inputVariant="outlined"
                                            disableFuture="true"
                                            label="Start Date"
                                            className="mt-1 mr-1"
                                            disabled={live ? true : false}
                                            value={startDate}
                                            onChange={(event) => onChangeHandler(event, 'startDate')}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DateTimePicker
                                            style={{ fontSize: 16 }}
                                            inputVariant="outlined"
                                            disableFuture="true"
                                            label="End Date"
                                            className="mt-1"
                                            disabled={live ? true : false}
                                            value={endDate}
                                            onChange={(event) => onChangeHandler(event, 'endDate')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </>
                                {/* } */}
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <button onClick={() => setAutoTrack(!autoTrack)} className={'btn btn-light px-2 rounded-circle-px dropshadow mr-1' + (autoTrack ? ' danger-gradient text-white' : ' text-danger')}>
                                    <svg viewBox="0 0 3.55 3.55" style={{ width: 20 }}>
                                        <g>
                                            <path fill={autoTrack ? 'white' : '#dc3545'} d="M3.55 1.92l-0.43 0c-0.03,0.32 -0.18,0.6 -0.39,0.82 -0.21,0.21 -0.5,0.36 -0.82,0.39l0 0.43 -0.28 0 0 -0.43c-0.32,-0.03 -0.6,-0.18 -0.82,-0.39l-0 -0c-0.21,-0.21 -0.36,-0.5 -0.39,-0.82l-0.43 0 0 -0.28 0.43 0c0.03,-0.32 0.18,-0.6 0.39,-0.82l0.01 -0.01c0.21,-0.21 0.5,-0.35 0.81,-0.38l0 -0.43 0.28 0 0 0.43c0.32,0.03 0.6,0.18 0.82,0.39 0.21,0.21 0.36,0.5 0.39,0.82l0.43 0 0 0.28zm-1.02 -0.9c-0.19,-0.19 -0.46,-0.31 -0.76,-0.31 -0.29,0 -0.56,0.12 -0.75,0.31l-0.01 0.01c-0.19,0.19 -0.31,0.46 -0.31,0.76 0,0.3 0.12,0.57 0.31,0.76l0 0c0.19,0.19 0.46,0.31 0.76,0.31 0.3,0 0.57,-0.12 0.76,-0.31l0 0c0.19,-0.19 0.31,-0.46 0.31,-0.76 0,-0.3 -0.12,-0.57 -0.31,-0.76z" />
                                        </g>
                                    </svg>
                                </button>
                                <div style={{ fontSize: 10, color: 'grey', margin: '-3px 0 -14px' }}>Auto Track</div>
                            </div>
                            <ButtonContainer>
                                <Button buttonResult={() => setLive(true)} active={live && true}>Live</Button>
                                <Button buttonResult={() => setLive(false)} active={!live && true}>History</Button>
                            </ButtonContainer>
                            {inlineloader ?
                                <InlineLoader width='50px' />
                                : null}
                        </CardBody>
                    }
                </Card>
            </div>
            <div className={'mt-3' + (selectMarker ? ' pl-3' : '')} style={{ width: selectMarker ? 310 : 1, transition: 'width 0.5s cubic-bezier(0.1, 0.9, 0.2, 1.5)', transitionDelay: selectMarker ? '0.5s' : '0s', height: selectMarker ? 'calc(100vh - 295px)' : '0' }}>
                {selectMarker &&
                    <>
                        <Card className="mb-3 mr-2">
                            <CardBody className='d-flex pl-3 justify-content-between align-items-center'>
                                <Heading size={2}>
                                    <div style={{ textTransform: 'capitalize' }}>
                                        {selectMarker.name}
                                    </div>
                                    <div className='small' style={{ margin: '-8px 0 -5px 0', fontSize: 12 }}>Last Update: {moment(selectMarker?.events?.Status?.created).format('L LT')}</div>
                                </Heading>
                                <button className='btn btn-light text-danger rounded-circle-px m-1' onClick={() => onCloseHandler()}><i className='fa fa-times'></i></button>
                            </CardBody>
                        </Card>
                        <div className='overflow-auto h-100 pr-2'>
                            {selectMarker?.events?.Status &&
                                <>
                                    <Card className="mb-3">
                                        <CardBody style={{ width: '100%', paddingTop: '50%', position: 'relative' }}>
                                            <div className='d-flex align-items-center' style={{ width: '100%', height: '100%', top: 0, position: 'absolute' }}>
                                                <img width={"100px"} height={"100px"} src={GPS} />
                                                <table style={{ fontSize: '12px' }} cellPadding={0}>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>Latitude</td>:
                                                        <td className='pl-1'> {selectMarker?.events?.Status?.latlng.split(',')[0]}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>Longitude</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.latlng.split(',')[1]}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.alt.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.alt} {selectMarker?.keys?.alt.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.ts.name}</td>:
                                                        <td className='pl-1'>{moment(selectMarker?.events?.Status?.ts).format("L LT")} {selectMarker?.keys?.ts.unit}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="mb-3">
                                        <CardBody style={{ width: '100%', paddingTop: '50%', position: 'relative' }}>
                                            <div className='d-flex align-items-center' style={{ width: '100%', height: '100%', top: 0, position: 'absolute' }}>
                                                <img width={"100px"} height={"100px"} src={Car} />
                                                <table style={{ fontSize: '12px' }} cellPadding={0}>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.ang.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.ang}{selectMarker?.keys?.ang.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a240.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a240 ? 'moving' : 'idle'}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a24.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a24} {selectMarker?.keys?.a24.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.sp.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.sp} {selectMarker?.keys?.sp.unit}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="mb-3">
                                        <CardBody style={{ width: '100%', paddingTop: '50%', position: 'relative' }}>
                                            <div className='d-flex align-items-center' style={{ width: '100%', height: '100%', top: 0, position: 'absolute' }}>
                                                <img className='p-3' width={"100px"} height={"100px"} src={Fuel} />
                                                <table style={{ fontSize: '12px' }} cellPadding={0}>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a13.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a13}{selectMarker?.keys?.a13.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a12.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a12}{selectMarker?.keys?.a12.unit}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="mb-3">
                                        <CardBody style={{ width: '100%', paddingTop: '50%', position: 'relative' }}>
                                            <div className='d-flex align-items-center' style={{ width: '100%', height: '100%', top: 0, position: 'absolute' }}>
                                                <img width={"100px"} height={"100px"} src={Battery} />
                                                <table style={{ fontSize: '12px' }} cellPadding={0}>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a113.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a113}{selectMarker?.keys?.a113.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a263.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a263}{selectMarker?.keys?.a263.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a67.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a67}{selectMarker?.keys?.a67.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a68.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a68}{selectMarker?.keys?.a68.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a66.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a66}{selectMarker?.keys?.a66.unit}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="mb-3">
                                        <CardBody style={{ width: '100%', paddingTop: '50%', position: 'relative' }}>
                                            <div className='d-flex align-items-center' style={{ width: '100%', height: '100%', top: 0, position: 'absolute' }}>
                                                <img className='p-3' width={"100px"} height={"100px"} src={Trip} />
                                                <table style={{ fontSize: '12px' }} cellPadding={0}>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a250.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a250}{selectMarker?.keys?.a250.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a199.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a199}{selectMarker?.keys?.a199.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a16.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a16}{selectMarker?.keys?.a16.unit}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="mb-3">
                                        <CardBody style={{ width: '100%', paddingTop: '50%', position: 'relative' }}>
                                            <div className='d-flex align-items-center' style={{ width: '100%', height: '100%', top: 0, position: 'absolute' }}>
                                                <img className='p-3' width={"100px"} height={"100px"} src={GSM} />
                                                <table style={{ fontSize: '12px' }} cellPadding={0}>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a69.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a69}{selectMarker?.keys?.a69.unit}</td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className='text-capitalize text-truncate' style={{ maxWidth: 90 }}>{selectMarker?.keys?.a21.name}</td>:
                                                        <td className='pl-1'>{selectMarker?.events?.Status?.a21}{selectMarker?.keys?.a21.unit}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>
                            }
                        </div>
                    </>
                }
            </div>
            <div className={selectMarker ? 'col mt-3 pl-2 pr-3' : 'col mt-3'} style={{ height: selectMarker ? 'calc(100vh - 230px)' : 'calc(100vh - 165px)' }}>
                <Card className="overflow-hidden h-100">
                    <CardBody className="h-100">
                        {markers.length &&
                            <Map apiKey={apiKey} markers={markers} onChange={onChangeHandler} path={path} autoTrack={autoTrack} />
                        }
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    organization: state.organization,
    eventReducer: state.eventReducer,
    lastEvent: state.lastEvent
});
export default connect(mapStateToProps, null)(MapView)