import React, { useEffect, useState } from 'react'
import { Button, ButtonContainer } from '../../arraybutton/arraybutton'

const Rotate = (props) => {

    const [rotate, setRotate] = useState({})
    const onChangeHandler = (e, type) => {
        let data = { ...rotate }
        if(type === "rotateStep"){
            if(data[type]){
                data[type] = parseInt(data[type])
            }else{
                data[type] = 0
            }
            if(e === "increase"){
                if(data[type] === 3){
                    data[type] = 0
                }else{
                    data[type] += 1
                }
            }else{
                if(data[type] === 0){
                    data[type] = 3
                }else{
                    data[type] -= 1
                }
            }
        }
        setRotate(data)
        if ("onChange" in props) {
            props.onChange({name: type, target: { value: data[type] } })
        }

    }

    useEffect(() => {
        if (props?.defaultValue) {
            setRotate(props.defaultValue)
        }
    }, [props.defaultValue])
    return (
        <>
            <div className='propertiesLabels'>Rotate</div>
            {
                props.styleTag.includes("rotate-step") ?
                    <ButtonContainer className="mb-3">
                        <Button buttonResult={() => onChangeHandler("decrease","rotateStep")}><i className="fas fa-undo mr-1"></i></Button>
                        <Button buttonResult={() => onChangeHandler("increase","rotateStep")}><i className="fas fa-redo mr-1"></i></Button>
                    </ButtonContainer>
                : null
            }
        </>
    )
}

export default Rotate