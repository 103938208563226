import React, { useEffect, useState } from 'react'
import DropContainer from '../drag&drop/dropContainer';

function Flat(props) {
    const [tabSelection, setTabSelection] = useState(1)
    const [pageConfig, setPageConfig] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [ComponentList, setComponentList] = useState([])
    const [selectiveIndex, setSelectiveIndex] = useState(null)
    const [selectiveId, setSelectiveId] = useState(null)
    const [mouseDown, setMouseDown] = useState("")
    const [selectiveComponentIndex, setSelectiveComponentIndex] = useState(null)

    useEffect(() => {
        setComponentList(props.ComponentList)
        window.addEventListener("mouseup", mouseUpHandler)
        return () => {
            window.removeEventListener("mouseup", mouseUpHandler)
        }
    }, [])
    useEffect(() => {
        setPageConfig(props.pageConfig)
    }, [props.pageConfig])
    useEffect(() => {
        setEditMode(props.editMode)
    }, [props.editMode])
    
    // Handle height,width and style and css  
    useEffect(() => {
        let moveWidth = 0, moveHeight = 0
        const handleMove = (event) => {
            if (mouseDown === "width" || mouseDown === "size" || mouseDown === "width-height") {
                // const sketchBoard = document.getElementById("sketchBoard")
                // const { width } = sketchBoard.getBoundingClientRect()
                const element = document.getElementById(selectiveId).parentNode
                const { left } = element.getBoundingClientRect()
                const sketchBoard = element.parentNode
                const { width } = sketchBoard.getBoundingClientRect()
                let newWidth = (event.clientX - left) / width * 100
                if (newWidth > 100) {
                    newWidth = 100
                } else if (newWidth < 0) {
                    newWidth = 0
                }
                newWidth = parseInt(newWidth.toFixed(0))
                if (moveWidth !== newWidth) {
                    moveWidth = newWidth
                    onChangeHandler({
                        target: { value: newWidth },
                        name: "width"
                    }, "style")
                }
            }
            if (mouseDown === "height" || mouseDown === "width-height") {
                const sketchBoard = document.getElementById(selectiveId)
                const { top, height } = sketchBoard.getBoundingClientRect()
                let newHeight = (event.clientY - top)
                if (newHeight < 0) {
                    newHeight = 0
                }
                if (moveHeight !== newHeight) {
                    moveHeight = newHeight
                    onChangeHandler({
                        target: { value: newHeight },
                        name: "height"
                    }, "style")
                }
            }
        }
        if (mouseDown) {
            window.addEventListener("mousemove", handleMove)
            if (mouseDown === "auto-height") {
                onChangeHandler({
                    target: { value: '' },
                    name: "height"
                }, "style")
            }
        } else {
            window.removeEventListener("mousemove", handleMove)
        }

        return () => {
            window.removeEventListener("mousemove", handleMove)
        }
    }, [mouseDown])

    //All onChange store and set Functoionality
    const onChangeHandler = (e, type) => {
        if (type === "component") {
            let pageConfig1 = [...pageConfig]
            pageConfig1[selectiveIndex] = e
            setPageConfig(pageConfig1)
        }
        else if (type === "isSelected") {
            setSelectiveIndex(null)
            setSelectiveId(null)
        }
        else if (type === "tab") {
            setTabSelection(e)
        }
        else if (type === "style") {
            let pageConfig1 = [...pageConfig]
            let componentData1 = pageConfig1[selectiveIndex]
            let unit = ""
            if (e.name === "width"
                || e.name === "marginLeft"
                || e.name === "marginRight"
                || e.name === "marginTop"
                || e.name === "marginBottom") {
                unit = "%"
            }
            else if (e.name === "height") {
                unit = "px"
            }
            if (e.name === "height" && e.target.value === "") {
                let css = { ...componentData1.style.css }
                if (e.name in css) {
                    delete css[e.name]
                    componentData1.style.css = css
                }
            } else {
                componentData1.style.css = {
                    ...componentData1.style.css, [e.name]: e.target.value + unit
                };
            }
            pageConfig1[selectiveIndex] = componentData1
            setPageConfig(pageConfig1)
            if('onChange' in props){
                props.onChange(e, "style")
            }
        }
    }

    
    //Selection of Component
    const selectComponent = (e, target) => {
        let pageConfig1 = [...pageConfig]
        let data = e
        let container = false
        let classFilter = target.className;
        if (classFilter?.constructor === String) {
            if (classFilter.match(" ")) {
                classFilter = classFilter.split(" ")
                classFilter = classFilter[0]
            }
        }
        if (target.tagName === "DIV" && classFilter === "component-area" && data.type !== "Container") {
            container = true;
        }

        if (container) {
            data.config["index"] = target.parentNode.parentNode.id + "_" + (pageConfig1.length + 1)
        } else {
            data.config["index"] = "" + (pageConfig1.length + 1)
        }
        pageConfig1.push(data)
        setSelectiveIndex(null)
        setSelectiveId(null)
        setPageConfig(pageConfig1)
    }
    //delete Component Function 
    const deleteComponent = (index) => {
        let pageConfig1 = [...pageConfig]
        let getId = pageConfig1[index].config?.index
        if (getId) {
            if (!getId.match("_")) {
                pageConfig1.map((comp, ind) => {
                    let getId2 = comp?.config?.index
                    if (getId2) {
                        if (getId2.match("_")) {
                            getId2 = getId2.split("_")[0]
                            if (getId === getId2) {
                                pageConfig1.splice(ind, 1)
                            }
                        }
                    }
                })
            }
        }
        pageConfig1.splice(index, 1)
        setPageConfig(pageConfig1)
        if (selectiveIndex === index) {
            setSelectiveIndex(null)
            setSelectiveId(null)
            setTabSelection(1)
        }
    }

    // set Component Index and Selective Index
    const selectiveIndexFunc = (index, index2) => {
        setSelectiveComponentIndex(index2)
        setSelectiveIndex(index)
    }

    //Mouse Up Stop Functions
    const mouseUpHandler = (e) => {
        setMouseDown(null)
    }

    // Mouse Down Functions
    const mouseDownHandler = (index, index2, type, id) => {
        selectiveIndexFunc(index, index2)
        setSelectiveId(id)
        setMouseDown(type)
    }

    //drag Effect 
    const dragEffect = (id) => {
        let e = document.getElementById(id).parentNode
        e.classList.add("dragEffect")
    }
    // drag Effect Remove
    const dragEffectRemove = (id) => {
        let e = document.getElementById(id).parentNode
        e.classList.remove("dragEffect")
    }
    // Replace Effect
    const replaceEffect = (id) => {
        // let e = document.getElementById(id).parentNode
        // e.classList.add("replaceEffect")
    }
    // Replace Effect Remove
    const replaceEffectRemove = (id) => {
        // let e = document.getElementById(id).parentNode
        // e.classList.remove("replaceEffect")
    }
  return (
    <div className='p-2' id={"sketchBoard"} style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}>
        {!editMode ?
            <div className='w-100 h-100 d-flex flex-wrap align-content-start'>
                {pageConfig.map((compo, index) =>
                    ComponentList.map((elem, index2) =>
                        compo.type === elem.type ?
                            elem.type === "Container" ?
                                <div key={index} className={"basicComponent"} style={compo.style.css}>
                                    <div className='w-100 h-100' id={compo?.config?.index}>
                                        <elem.component className={compo.style.class} style={compo.style.css} config={compo.config}>
                                            {pageConfig.map((compo1, index) =>
                                                ComponentList.map((elem1, index2) =>
                                                    compo1.type === elem1.type && compo1?.config?.index?.match("_") && compo?.config?.index === compo1?.config?.index.split("_")[0] ?
                                                        <div key={index} className={"basicComponent " + compo1.style.class} style={compo1.style.css}>
                                                            <div className='w-100 h-100' id={compo1?.config?.index}>
                                                                <elem1.component config={compo1.config} className={compo1.style.class} style={compo1.style.css} />
                                                            </div>
                                                        </div> : null).filter(el => el)).filter(el => el)
                                            }
                                        </elem.component>
                                    </div>
                                </div>
                                :
                                !(compo?.config?.index?.match("_")) ?
                                    <div key={index} className={"basicComponent " + compo.style.class} style={compo.style.css}>
                                        <div className='w-100 h-100' id={compo?.config?.index}>
                                            <elem.component config={compo.config} className={compo.style.class} style={compo.style.css} />
                                        </div>
                                    </div>
                                    : null

                            : null
                    ).filter(el => el)
                ).filter(el => el)}
            </div>
            : <DropContainer PageConfig={(e, target) => selectComponent(e, target)}>
                <div className='w-100' style={{ height: 20 }}></div>
                {pageConfig.map((compo, index) =>
                    ComponentList.map((elem, index2) =>
                        compo.type === elem.type ?
                            elem.type === "Container" ?
                                <div onDragOver={() => dragEffect(compo?.config?.index)} onDragLeave={() => dragEffectRemove(compo?.config?.index)} onDrop={() => dragEffectRemove(compo?.config?.index)} key={index} className={"basicComponent editMode mt-3" + (selectiveIndex === index ? " active " : " ")} style={compo.style.css}>
                                    <span className='ribbon' style={{ left: "-2px" }}>
                                        <i className="fas fa-pen" onClick={() => { selectiveIndexFunc(index, index2); setTabSelection(3) }}></i>
                                        <i className='fa fa-trash' onClick={() => deleteComponent(index)}></i>
                                        <div className='col position-relative' onClick={(() => selectiveIndexFunc(index, index2))} onDoubleClick={() => setTabSelection(3)}></div>
                                    </span>
                                    {elem.styleTag.includes("width") ?
                                        <button className='stretch-width' onMouseDown={() => mouseDownHandler(index, index2, "width", compo?.config?.index)}>
                                            <i className='fa fa-angle-right'></i>
                                        </button>
                                        : null}
                                    {elem.styleTag.includes("height") ?
                                        <>
                                            {"height" in compo.style.css ?
                                                <button className='stretch-auto-height' onMouseDown={() => mouseDownHandler(index, index2, "auto-height", compo?.config?.index)}>
                                                    Auto
                                                </button>
                                                : null}
                                            <button className='stretch-height' onMouseDown={() => mouseDownHandler(index, index2, "height", compo?.config?.index)}>
                                                <i className='fa fa-angle-down'></i>
                                            </button>
                                        </>
                                        : null}
                                    {elem.styleTag.includes("size") || (elem.styleTag.includes("width") && elem.styleTag.includes("height")) ?
                                        <button className='stretch-size' onMouseDown={() => mouseDownHandler(index, index2, elem.styleTag.includes("size") ? "size" : "width-height", compo?.config?.index)}>
                                            <i className='fa fa-angle-down'></i>
                                        </button>
                                        : null}
                                    <div className='w-100 h-100 p-3' id={compo?.config?.index}>
                                        <elem.component className={compo.style.class} style={compo.style.css} config={compo.config}>
                                            {pageConfig.map((compo1, index) =>
                                                ComponentList.map((elem1, index2) =>
                                                    compo1.type === elem1.type && compo1?.config?.index?.match("_") && compo?.config?.index === compo1?.config?.index.split("_")[0] ?
                                                        <div
                                                            key={index}
                                                            className={"basicComponent editMode" + (selectiveIndex === index ? " active " : " ") + (elem1.type !== "DynamicLabel" ? compo1.style.class : "")}
                                                            style={compo1.style.css}
                                                            onDragOver={() => replaceEffect(compo1?.config?.index)}
                                                            onDragLeave={() => replaceEffectRemove(compo1?.config?.index)}
                                                            onDrop={() => replaceEffectRemove(compo1?.config?.index)}
                                                        >
                                                            <span className='ribbon'>
                                                                <i className="fas fa-pen" onClick={() => { selectiveIndexFunc(index, index2); setTabSelection(3) }}></i>
                                                                <i className='fa fa-trash' onClick={() => deleteComponent(index)}></i>
                                                            </span>
                                                            {elem1.styleTag.includes("width") ?
                                                                <button className='stretch-width' onMouseDown={() => mouseDownHandler(index, index2, "width", compo1?.config?.index)}>
                                                                    <i className='fa fa-angle-right'></i>
                                                                </button>
                                                                : null}
                                                            {elem1.styleTag.includes("height") ?
                                                                <>
                                                                    {"height" in compo1.style.css ?
                                                                        <button className='stretch-auto-height' onMouseDown={() => mouseDownHandler(index, index2, "auto-height", compo1?.config?.index)}>
                                                                            Auto
                                                                        </button>
                                                                        : null}
                                                                    <button className='stretch-height' onMouseDown={() => mouseDownHandler(index, index2, "height", compo1?.config?.index)}>
                                                                        <i className='fa fa-angle-down'></i>
                                                                    </button>
                                                                </>
                                                                : null}
                                                            {elem1.styleTag.includes("size") || (elem1.styleTag.includes("width") && elem1.styleTag.includes("height")) ?
                                                                <button className='stretch-size' onMouseDown={() => mouseDownHandler(index, index2, elem1.styleTag.includes("size") ? "size" : "width-height", compo1?.config?.index)}>
                                                                    <i className='fa fa-angle-down'></i>
                                                                </button>
                                                                : null}
                                                            <div className='w-100 h-100' id={compo1?.config?.index} onClick={(() => selectiveIndexFunc(index, index2))} onDoubleClick={() => setTabSelection(3)}>
                                                                <elem1.component config={compo1.config} className={compo1.style.class} style={compo1.style.css} />
                                                            </div>
                                                        </div> : null).filter(el => el)).filter(el => el)
                                            }
                                        </elem.component>
                                    </div>
                                </div>
                                :
                                !(compo?.config?.index?.match("_")) ?
                                    <div
                                        key={index}
                                        className={"basicComponent editMode mt-3" + (selectiveIndex === index ? " active " : " ") + (elem.type !== "DynamicLabel" ? compo.style.class : "")}
                                        style={compo.style.css}
                                        onDragOver={() => replaceEffect(compo?.config?.index)}
                                        onDragLeave={() => replaceEffectRemove(compo?.config?.index)}
                                        onDrop={() => replaceEffectRemove(compo?.config?.index)}
                                    >
                                        <span className='ribbon'>
                                            <i className="fas fa-pen" onClick={() => { selectiveIndexFunc(index, index2); setTabSelection(3) }}></i>
                                            <i className='fa fa-trash' onClick={() => deleteComponent(index)}></i>
                                        </span>
                                        {elem.styleTag.includes("width") ?
                                            <button className='stretch-width' onMouseDown={() => mouseDownHandler(index, index2, "width", compo?.config?.index)}>
                                                <i className='fa fa-angle-right'></i>
                                            </button>
                                            : null}
                                        {elem.styleTag.includes("height") ?
                                            <>
                                                {"height" in compo.style.css ?
                                                    <button className='stretch-auto-height' onMouseDown={() => mouseDownHandler(index, index2, "auto-height", compo?.config?.index)}>
                                                        Auto
                                                    </button>
                                                    : null}
                                                <button className='stretch-height' onMouseDown={() => mouseDownHandler(index, index2, "height", compo?.config?.index)}>
                                                    <i className='fa fa-angle-down'></i>
                                                </button>
                                            </>
                                            : null}
                                        {elem.styleTag.includes("size") || (elem.styleTag.includes("width") && elem.styleTag.includes("height")) ?
                                            <button className='stretch-size' onMouseDown={() => mouseDownHandler(index, index2, elem.styleTag.includes("size") ? "size" : "width-height", compo?.config?.index)}>
                                                <i className='fa fa-angle-down'></i>
                                            </button>
                                            : null}
                                        <div className='w-100 h-100' id={compo?.config?.index} onClick={(() => selectiveIndexFunc(index, index2))} onDoubleClick={() => setTabSelection(3)}>
                                            <elem.component config={compo.config} className={compo.style.class} style={compo.style.css} />
                                        </div>
                                    </div>
                                    : null

                            : null
                    ).filter(el => el)
                ).filter(el => el)}
            </DropContainer>
        }
    </div>
  )
}

export default Flat