import { useState, useEffect } from "react";
import { idGenerator } from "../../functions/functions";
import MyDropDown, { MyDropDownBody } from "../dropdown/dropdown";
import "./select.scss"
export default function Select(props) {
    const [randomId, setRandomId] = useState(idGenerator())
    let [popup, setPopup] = useState(false)
    let [filterBy, setFilterBy] = useState(false)
    const [state, setState] = useState({
        hover: false,
        popup: false,
        options: [],
        filteredData: [],
        selectedText: "",
        selectedValue: null,
    })
    const [hover, setHover] = useState(false)
    const [inputInfo, setInputInfo] = useState(false)
    useEffect(() => {
        if ("message" in props) {
            if ("status" in props.message) {
                if (props.message.status) {
                    setInputInfo(true)
                    return false
                }
                setInputInfo(false)
                return false

            }
            setInputInfo(false)
            return false

        }
        setInputInfo(false)
        return false

    }, [props.message])
    useEffect(() => {
        let state1 = { ...state };
        state1.options = props.options;
        if ((('sort' in props && props.sort) || !('sort' in props)) && state1.options) {
            state1.options.sort((a, b) => {
                let fa = a.label.toLowerCase()
                let fb = b.label.toLowerCase()
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
        }
        state1.filteredData = state1.options;
        if ("multiSelect" in props) {
            state1.options = []
            if ("selectAll" in props) {
                if (props.selectAll) {
                    state1.options.push({ label: props.selectAll, value: "all", select: false })
                }
            }
        }
        if ("defaultValue" in props && state1.options.length) {

            if (props.defaultValue) {

                if ("row" in props.defaultValue) {
                    if ("multiSelect" in props) {
                        state1.selectedText = ""
                        state1.selectedValue = []
                        if (props.defaultValue.row === 0) {
                            state1.options[0].select = true
                        }
                        state.options.map((e, key) => {
                            key = key + 1
                            state1.options.push({ ...e, select: false })
                            if ("selectAll" in props) {
                                if (props.selectAll) {
                                    if (props.defaultValue.row === 0) {
                                        state1.options[key].select = true
                                        if (e.value !== "all") {
                                            state1.selectedText = state1.selectedText + (state1.selectedText ? ", " : "") + e.label
                                            state1.selectedValue.push(e.value)
                                        }
                                    } else if (props.defaultValue.row === key) {
                                        state1.options[props.defaultValue.row].select = true
                                        if (e.value !== "all") {
                                            state1.selectedText = state1.selectedText + (state1.selectedText ? ", " : "") + e.label
                                            state1.selectedValue.push(e.value)
                                        }
                                    } else {
                                        state1.options[key].select = false
                                    }
                                }
                            }
                        })
                    } else {
                        state1.selectedText = state1.options[props.defaultValue.row].label
                        state1.selectedValue = state1.options[props.defaultValue.row].value
                    }
                }
                else if ("value" in props.defaultValue) {
                    if (props.defaultValue.value !== null && props.defaultValue.value !== "") {
                        state1.options.map(opt => {
                            if (opt.value === props.defaultValue.value) {
                                state1.selectedText = opt.label
                                state1.selectedValue = opt.value
                            }
                            if ("multiSelect" in props) {
                                opt.select = true
                            }
                        })
                    } else {
                        state1.selectedText = ""
                        state1.selectedValue = ""
                    }
                }
                else if ("label" in props.defaultValue) {
                    if (props.defaultValue.label) {
                        state1.selectedText = props.defaultValue.label
                        state1.options.map(opt => {
                            if (opt.label === props.defaultValue.label) {
                                state1.selectedText = opt.label
                                state1.selectedValue = opt.value
                            }
                            if ("multiSelect" in props) {
                                opt.select = true
                            }
                        })
                    } else {
                        state1.selectedText = ""
                        state1.selectedValue = ""
                    }
                }
            }
            // state2 = state1.options.sort((a, b) => {
            //     let fa = a.label.toLowerCase()
            //     let fb = b.label.toLowerCase()
            //     if (fa < fb) {
            //         return -1;
            //     }
            //     if (fa > fb) {
            //         return 1;
            //     }
            //     return 0;
            // })
        } else {
            state1.selectedText = ""
            state1.selectedValue = null
        }
        setState(state1)
        if ("multiSelect" in props) {
            if ("selectAll" in props) {
                if (props.selectAll) {
                    props.onChange({
                        name: "name" in props ? props.name : "please add name attr.",
                        target: state1.selectedValue,
                    })
                }
            }
        }
    }, [props.defaultValue, props.options])
    useEffect(() => {
        if ("filterBy" in props) {
            setFilterBy(props.filterBy)
        }
    }, [props.filterBy])

    useEffect(() => {
        let myInput = document.querySelector("#select_" + randomId + ">.mySelect>input")
        myInput.addEventListener('focus', (e) => {
            setPopup(true);
        })
        myInput.addEventListener('blur', (e) => {
            if ("multiSelect" in props) {
            }
            else {
                setTimeout(() => {
                    setPopup(false);
                }, 100)
            }
        })
    }, [])

    const showPopup = (value) => {
        setPopup(value)
    }
    const onHover = (value) => {
        let state1 = { ...state };
        state1.hover = value;
        setState(state1);
    }
    const onChangeHandler = (e) => {
        let value = e.target.value;
        let state1 = { ...state };
        setPopup(true)
        state1.selectedText = value;
        if ("textEditor" in props && "onChange" in props) {
            props.onChange({
                name: "name" in props ? props.name : "please add name attr.",
                target: { value },
            })
        }
        // state1.selectedValue = null;
        if (state1.options.length) {
            state1.filteredData = state.options.filter(e => value ? e.label.toLowerCase().includes(value.toLowerCase()) : e)
        }
        setState(state1)
    }
    const multiSelectFunc = (param, state1) => {
        state1.selectedValue = []
        state1.selectedText = "";
        state1.options.map(e => {
            if (e.value === param.value) {
                e.select = !e.select
            }
            if (param.value === "all") {
                e.select = state1.options[0].select
            }
            if (e.select && e.value !== "all") {
                state1.selectedText += (state1.selectedText ? ", " : "") + e.label;
                state1.selectedValue.push(e.value);
            }
        })
        return state1
    }
    //Press Enter call the Function
    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            props.onEnter(e)
        }
    }
    const onSelect = (e, key) => {
        let state1 = { ...state };
        if ("onChange" in props) {

            if ("multiSelect" in props) {
                state1 = multiSelectFunc(e, state1)
                props.onChange({
                    name: "name" in props ? props.name : "please add name attr.",
                    target: state1.selectedValue,
                })
            } else {
                state1.selectedText = e.label;
                state1.selectedValue = e.value;
                props.onChange({
                    name: "name" in props ? props.name : "please add name attr.",
                    target: { ...e, ...{ index: key } },
                })
            }
        } else {
            console.error("please add onChange attr. to get event")
        }
        setState(state1);
    }
    return (
        <MyDropDown
            id={"select_" + randomId}
            openToggle={(eve) => "onClick" in props ? onHover(eve) : showPopup(eve)}
            className={'className' in props && props.className}
        >
            <div className="mySelect">
                <fieldset className={inputInfo ? " danger" : ""}>
                    <legend className={inputInfo ? " danger" : ""}>{"label" in props ? props.label : "Add Label Attr."}</legend>
                </fieldset>

                <input id={"input_" + randomId} value={state.selectedText} onChange={(e) => onChangeHandler(e)} onKeyDown={(e) => "onEnter" in props ? onKeyPress(e) : null} type="text" placeholder="select here..." />

                <div className="fas fa-angle-down align-self-center px-3 " style={{ zIndex: 1 }} onClick={() => document.getElementById("input_" + randomId).focus()}></div>

                {inputInfo ?
                    <MyDropDown openToggle={(e) => setHover(e)}>
                        <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger m-1' style={{ zIndex: 1 }}></span>
                        <MyDropDownBody open={hover} right>
                            <div className='py-3 text-left p-3 ' style={{ width: "300px" }}>
                                <small>{props.message.msg}</small>
                            </div>
                        </MyDropDownBody>
                    </MyDropDown>
                    : null}
            </div>
            <MyDropDownBody className="mySelect-list" open={popup} left>
                {
                    "options" in props ?
                        props.options.length > 0 ?
                            state.filteredData.length > 0 ?
                                <div className="list-container" style={"dropDownHeight" in props ? { maxHeight: props.dropDownHeight } : null}>
                                    {state.filteredData.map((e, key) =>
                                        e?.filter && filterBy ? e?.filter === filterBy ?
                                            <div onClick={() => onSelect(e, key)} key={key} className="item">
                                                {"select" in e ?
                                                    <label>
                                                        <input type="checkbox" defaultChecked={e.select} />
                                                        {e.label}
                                                    </label>
                                                    :
                                                    e.label}
                                            </div> : null
                                            : <div onClick={() => onSelect(e, key)} key={key} className="item">
                                                {"select" in e ?
                                                    <label>
                                                        <input type="checkbox" defaultChecked={e.select} />
                                                        {e.label}
                                                    </label>
                                                    :
                                                    e.label}
                                            </div>

                                    )}
                                </div>
                                : <div className="list-container"><div className="item">Not Match</div></div>
                            : <div className="list-container"><div className="item">No Items</div></div>
                        : <pre className="px-3 m-0">
                            please add 'options' attribute<br />
                            {"options = ["}<br />
                            {"    {value: 123, label: 'abc'}"}<br />
                            {"]"}<br />
                            for default selected<br />
                            defaultValue = {'{{row: num}}'}<br />
                            -OR-<br />
                            defaultValue = {'{{label: "text"}}'}<br />
                            -OR-<br />
                            defaultValue = {'{{value: num or "text"}}'}
                        </pre>
                }
            </MyDropDownBody>
        </MyDropDown>
    )
}