import React, { useEffect, useState } from 'react'
import './componentBar.scss';
import { drag } from '../drag&drop/dragItem';

import Card, { CardHeader, CardBody, Heading } from '../card/card'
import { ButtonContainer, Button } from '../arraybutton/arraybutton'
import Alignment from '../properties/alignment/alignment';
import ColorPanel from '../properties/colorPanel/colorPanel';
import Size from '../properties/size/size';
import Margin from '../properties/margin/margin';
import Label from '../properties/label/label';
import DeviceConnection from '../properties/deviceConnection/deviceConnection';
import Gap from '../properties/gap/gap';
import Rotate from '../properties/rotate/rotate';
import DateFormat from '../properties/dateFormat/dateFormat';
import Input from '../input/input';
import Select from '../select/select';
import LevelAssign from '../properties/levelAssign/levelAssign';
import Active from '../properties/active/active';
import ImageUploader from '../properties/imageUploader/imageUploader';
import e from 'cors';
import DataMode from '../properties/dataMode/dataMode';
import MapSetting from '../properties/mapSetting/mapSetting';
import BorderStyle from '../properties/borderStyle/borderStyle';
import StrengthLevel from '../properties/strengthLevel/strengthLevel';
import Range from '../properties/range/range';



const ComponentBar = (props) => {

    //Component Static List

    const [componentList, setComponentList] = useState([])
    const [componentSelect, setComponentSelect] = useState(null)
    const [componentType, setComponentType] = useState([])
    const [propTabs, setPropTabs] = useState([true, true])
    const [tabSelection, setTabSelection] = useState(1)
    const [componentData, setComponentData] = useState(null)
    const [settings, setSettings] = useState(null)
    const [viewMode, setViewMode] = useState(props.viewMode)

    //All onChange store and set Functoionality
    const onChangeHandler = (e, type) => {
        if (type === "settings") {
            let settings1 = {
                ...settings,
                [e.name]: e.target.value
            }
            if ("onChange" in props) {
                props.onChange(settings1, "settings")
            }
            setSettings(settings1)
        } else {
            let componentData1 = { ...componentData }
            if (type === "style") {
                let unit = ""
                if (e.name === "width"
                    || e.name === "gap"
                    || e.name === "marginLeft"
                    || e.name === "marginRight"
                    || e.name === "marginTop"
                    || e.name === "marginBottom") {
                    unit = "%"
                }
                else if (e.name === "height" || e.name === "fontSize") {
                    unit = "px"
                }
                if ((e.name === "height" || e.name === "fontSize") && e.target.value === "") {
                    let css = { ...componentData1.style.css }
                    if (e.name in css) {
                        delete css[e.name]
                        componentData1.style.css = css
                    }
                } else {
                    if((typeof e.target.value) === "object"){
                        componentData1.style.css = {
                            ...componentData1.style.css, [e.name]: e.target.value
                        };
                    }else{
                        componentData1.style.css = {
                            ...componentData1.style.css, [e.name]: e.target.value + unit
                        };
                    }
                }
            }
            else if (type === "class") {
                componentData1.style.class = e
            }
            else if (type === "config") {
                if (e.name === "config") {
                    componentData1.config = e.target.value
                } else {
                    componentData1.config = {
                        ...componentData1.config,
                        [e.name]: e.target.value
                    }
                }
                if ("unit" in e) {
                    componentData1.config = {
                        ...componentData1.config,
                        unit: e.unit
                    }
                }
                if (e.name === "levelId") {
                    componentData1.config.deviceId = null
                    componentData1.config.param = null
                    if ("unit" in componentData1.config) {
                        componentData1.config.unit = null
                    }
                }
                if (e.name === "deviceId") {
                    componentData1.config.param = null
                    if ("unit" in componentData1.config) {
                        componentData1.config.unit = null
                    }
                }

            }
            if ("onChange" in props) {
                props.onChange(componentData1, "component")
            }
            setComponentData(componentData1)
        }
    }

    //set Tabs Function
    const propTabsFunction = (index) => {
        let propTabs1 = [...propTabs]
        propTabs1[index] = !(propTabs1[index]);
        setPropTabs(propTabs1)
    }

    const reinitialize = () => {
        setComponentData(null);
        props?.onChange(1, "tab")
        getCounts()
        if ("onChange" in props) {
            props.onChange(false, "isSelected")
        }
    }

    useEffect(() => {
        setViewMode(props.viewMode)
    }, [props.viewMode])
    useEffect(() => {
        if ("components" in props) {
            getCounts()
            setComponentList(props.components)
        }
        if ("settings" in props) {
            setSettings(props.settings)
        }
    }, [])
    useEffect(() => {
        if ("componentSelect" in props) {
            setComponentSelect(props.componentSelect)
        }
    }, [props.componentSelect])
    useEffect(() => {
        if ("componentData" in props) {
            setComponentData(props.componentData)
        }
    }, [props.componentData])
    useEffect(() => {
        if ("tabSelection" in props) {
            setTabSelection(props.tabSelection)
        }
    }, [props.tabSelection])

    const getCounts = (filter = null) => {
        let componentType1 = []
        props.components.map((comp, key) => {
            let duplicatIndex = null;
            componentType1.map((compType, compKey) => {
                if (comp.groupBy === compType.groupBy) {
                    duplicatIndex = compKey
                }
            })
            if (filter) {
                if (comp.groupBy.toLowerCase().match(filter) || comp.name.toLowerCase().match(filter)) {
                    if (duplicatIndex !== null) {
                        componentType1[duplicatIndex].component.push(comp);
                    } else {
                        componentType1.push({ groupBy: comp.groupBy, collapse: componentType1.length ? false : true, component: [comp] })
                    }
                }
            } else {
                if (duplicatIndex !== null) {
                    componentType1[duplicatIndex].component.push(comp);
                } else {
                    componentType1.push({ groupBy: comp.groupBy, collapse: componentType1.length ? false : true, component: [comp] })
                }
            }
        })
        setComponentType(componentType1)
    }

    return (
        <Card className="d-flex flex-column overflow-hidden h-100">
        {/* <Card className="d-flex flex-column overflow-hidden" style={{height: props.fullscreen? 'calc(100vh - 15px)' : 'calc(100vh - 115px)'}}> */}
            <CardHeader className="componentBarTabs">
                <div className='componentBarTabsInner'>
                    <div className={'vertTabButton'}  title="Collapse">
                        <button className='inner-btn mr-1 bg-white text-unselectable rounded-circle-px' onClick={() => props.onBarShow()}><i className={'fa '+(props.barIsShow? 'fa-angle-up': 'fa-angle-down')}></i></button>
                    </div>
                    <div className={'vertTabButton ' + (tabSelection === 0 ? "active" : "")}  title="Settings">
                        <div className='inner-btn text-unselectable' onClick={() => {props?.onChange(0, "tab"); if(!props.barIsShow){props.onBarShow()}}}><i className='fa fa-cog'></i></div>
                    </div>
                    <div className={'vertTabButton ' + (tabSelection === 1 ? "active" : "")}>
                        <div className='inner-btn text-unselectable' onClick={() => { props?.onChange(1, "tab"); if(!props.barIsShow){props.onBarShow()} getCounts(); }}>Widgets</div>
                    </div>
                    {componentData ? <div className={'vertTabButton vertTabButton-warning ' + (tabSelection === 3 ? "active" : "")}>
                        <div className='inner-btn text-unselectable' onClick={() => { props?.onChange(3, "tab"); if(!props.barIsShow){props.onBarShow()} getCounts(); }}>Properties</div>
                        <div onClick={() => reinitialize()}><i className='fas fa-times'></i></div>
                    </div> : null}
                </div>
            </CardHeader>
            {props.barIsShow?
            <CardBody style={{overflowX: 'hidden', overflowY: 'auto'}}>
                {tabSelection === 0 ?
                    <div className='p-3 d-flex flex-column h-100 fade-in-right-onload'>
                        <LevelAssign
                            onChange={(e) => { onChangeHandler(e, "settings") }}
                            defaultValue={settings?.levelAssign}
                        />
                        <Active
                            onChange={(e) => { onChangeHandler(e, "settings") }}
                            defaultValue={settings?.live}
                        />
                    </div>
                    : null}
                {tabSelection === 1 ?
                    <div className='toolPanel h-100 w-100 m-0 p-0 fade-in-right-onload position-relative'>
                        <div className='px-3 pt-3 pb-1 position-sticky bg-white' style={{top:0,zIndex:1}}>
                            <Input
                                onChange={(e) => getCounts(e.target.value)}
                                label="Widget Search"
                            />
                        </div>
                        {componentType.map((comp, typeKey) =>
                            <React.Fragment key={typeKey}>
                                <div className='btn btn-light d-flex justify-content-between w-100' onClick={
                                    () => {
                                        let group = [...componentType];
                                        group[typeKey].collapse = !comp.collapse;
                                        setComponentType(group);
                                    }
                                }>{comp.groupBy} ({comp.component.length}) <i className={'fa fa-angle-up fade-in-bottom-onload ' + (comp.collapse ? 'd-none' : '')}></i> <i className={'fa fa-angle-down fade-in-top-onload ' + (!comp.collapse ? 'd-none' : '')}></i></div>
                                <div className='component-list fade-in-right-onload' style={{ display: comp.collapse ? "flex" : "none" }}>
                                    {comp.component.map((elem, key) =>
                                        <div id={"element_" + typeKey + "_" + key} key={key} draggable onDragStart={(e) => drag(e, elem)} className='component-item'>
                                            {elem.type === "Container" ?
                                                <i className='fas fa-th'></i>
                                                : elem.type === "DynamicLabel" ?
                                                    <i className='fas fa-tag'></i>
                                                    : elem.type === "DynamicTable" ?
                                                        <i className='fas fa-table'></i>
                                                        : elem.type === "MapView" ?
                                                            <i className='fas fa-map-marked-alt'></i>
                                                            : elem.type === "CustomImage" ?
                                                                <i className='fas fa-image'></i>
                                                                :
                                                                <img src={require("../../assets/componentThumbnails/" + elem.type + ".png").default} style={{ maxWidth: "100%", objectFit: "contain", height: "50px" }} />
                                            }
                                            <h5 className='component-label'>{elem.name}</h5>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        )
                        }
                    </div>
                    : null}
                {tabSelection === 3 ?
                    <div className='d-flex flex-column h-100 fade-in-right-onload'>
                        {componentSelect !== null ? componentList[componentSelect]?.configTag?.length ?
                            <>
                                <div className='btn btn-light d-flex justify-content-between w-100' onClick={() => propTabsFunction(1)}>Configuration <i className={'fa fa-angle-' + (propTabs[1] ? 'up' : 'down')}></i></div>
                                <div className='p-3' style={{ display: propTabs[1] ? "unset" : "none" }}>
                                    {componentList[componentSelect].configTag.includes("label") ?
                                        <Label onChange={(e) => { onChangeHandler(e, "config") }} type={"label"} defaultValue={componentData?.config?.label ? componentData?.config?.label : ""} />
                                        : null}
                                    {componentList[componentSelect].configTag.includes("dataMode") &&
                                        <DataMode onChange={(e) => { onChangeHandler(e, "config") }} defaultValue={componentData?.config ? componentData?.config : ""} />
                                    }
                                    {componentList[componentSelect].configTag.includes("mapSetting") &&
                                        <MapSetting onChange={(e) => { onChangeHandler(e, "config") }} defaultValue={componentData?.config ? componentData?.config : ""} />
                                    }
                                    {componentList[componentSelect].configTag.map(e => e.match("deviceConnection")) &&
                                        <DeviceConnection configTag={componentList[componentSelect].configTag} onChange={(e) => { onChangeHandler(e, "config"); }} defaultValue={componentData?.config ? componentData?.config : ""} />
                                    }
                                    {componentList[componentSelect].configTag.includes("strengthLevel") ?
                                        <StrengthLevel configTag={componentList[componentSelect].configTag} onChange={(e) => { onChangeHandler(e, "config"); }} defaultValue={componentData?.config ? componentData?.config : ""} />
                                    :null}
                                    {componentList[componentSelect].configTag.includes("imageUploader") &&
                                        <ImageUploader onChange={(e) => { onChangeHandler(e, "config") }} defaultValue={componentData?.config?.imageId ? componentData?.config?.imageId : ""} />
                                    }
                                    {componentData?.config?.param === "created" ?
                                        <DateFormat onChange={(e) => { onChangeHandler(e, "config") }} defaultValue={componentData?.config} configTag={componentList[componentSelect].configTag} />
                                        : null}
                                    {componentList[componentSelect].configTag.includes("min-max") ?
                                        <>
                                            <div className='propertiesLabels mt-3'>Min & Max</div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <Label onChange={(e) => { onChangeHandler(e, "config") }} type={"min"} defaultValue={componentData?.config?.min ? componentData?.config?.min : ""} />
                                                </div>
                                                <div className='col'>
                                                    <Label onChange={(e) => { onChangeHandler(e, "config") }} type={"max"} defaultValue={componentData?.config?.max ? componentData?.config?.max : ""} />
                                                </div>
                                            </div>
                                        </>
                                        : null}
                                    {componentList[componentSelect].configTag.includes("lowest") ?
                                        <Label onChange={(e) => { onChangeHandler(e, "config") }} type={"lowest"} defaultValue={componentData?.config?.lowest ? componentData?.config?.lowest : ""} />
                                        : null}
                                    {componentList[componentSelect].configTag.includes("highest") ?
                                        <Label onChange={(e) => { onChangeHandler(e, "config") }} type={"highest"} defaultValue={componentData?.config?.highest ? componentData?.config?.highest : ""} />
                                        : null}
                                    {componentList[componentSelect].configTag.includes("color") || componentList[componentSelect].configTag.includes("customColor") ?
                                        <ColorPanel onChange={(e) => { onChangeHandler(e, "config") }} color={componentList[componentSelect].configTag.includes("color")} costumColor={componentList[componentSelect].configTag.includes("customColor")} defaultValue={(componentData?.config?.color ? componentData?.config?.color : "")} />
                                        : null}
                                    {componentList[componentSelect].configTag.includes("pre-suffix") ?
                                        <>
                                            <div className='propertiesLabels mt-3'>Prefix & Suffix</div>
                                            <Label onChange={(e) => { onChangeHandler(e, "config") }} type={"prefix"} defaultValue={componentData?.config?.prefix ? componentData?.config?.prefix : ""} />
                                            <Label onChange={(e) => { onChangeHandler(e, "config") }} type={"suffix"} defaultValue={componentData?.config?.suffix ? componentData?.config?.suffix : ""} />
                                        </>
                                        : null}
                                    {componentList[componentSelect].configTag.includes("range") ?
                                        <Range onChange={(e) => { onChangeHandler(e, "config") }} defaultValue={componentData?.config} configTag={componentList[componentSelect].configTag} />
                                    : null}
                                </div>
                            </>
                            : null : null}
                        <div className='btn btn-light d-flex justify-content-between w-100' onClick={() => propTabsFunction(0)}>Style <i className={'fa fa-angle-' + (propTabs[0] ? 'up' : 'down')}></i></div>
                        <div className='p-3' style={{ display: propTabs[0] ? "block" : "none" }}  >
                            {componentSelect !== null ? <>
                                <Size onChange={(e) => { onChangeHandler(e, "style") }} defaultValue={componentData?.style.css} styleTag={componentList[componentSelect].styleTag} />
                                {componentList[componentSelect].styleTag.includes("borderStyle")? 
                                    <BorderStyle onChange={(e) => { onChangeHandler(e, "style") }} defaultValue={componentData?.style.css} styleTag={componentList[componentSelect].styleTag} />
                                :null}
                                <Rotate onChange={(e) => { onChangeHandler(e, "style") }} defaultValue={componentData?.style.css} styleTag={componentList[componentSelect].styleTag} />
                                <Gap onChange={(e) => { onChangeHandler(e, "style") }} defaultValue={componentData?.style.css} styleTag={componentList[componentSelect].styleTag} />

                                <Alignment onChange={(e) => { onChangeHandler(e, "class") }}
                                    defaultValue={(componentData?.style?.class ? componentData.style.class : "non-class")}
                                    type={
                                        componentList[componentSelect].styleTag.includes("alignment") ?
                                            "alignment"
                                            : componentList[componentSelect].styleTag.includes("alignmentXY") ?
                                                "alignmentXY" : null
                                    } />
                            </> : null}
                            {/* <Margin onChange={(e) => { onChangeHandler(e, "style") }} defaultValue={componentData?.style.css} /> */}
                        </div>
                        <div className='position-sticky' style={{bottom: 0}}>
                            <ButtonContainer>
                                <Button buttonResult={() => reinitialize()}>
                                    OK
                                </Button>
                            </ButtonContainer>
                        </div>
                    </div>
                    : null}
            </CardBody>
            :null}
        </Card>
    )
}
export default ComponentBar
