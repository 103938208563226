import React, { useEffect, useLayoutEffect, useState } from 'react'
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import Mypopup from '../../components/popup/mypopup'
import AddRolePopup from '../../components/addRolePopup/addRolePopup'
import { ResponseMessageLocal, RoleList } from '../../services/config/generic'
import { DeleteAdminRoles, getAdminRoles } from '../../services/utilities/events'
import CardLoader from '../../components/loader/cardLoader'
import { connect } from 'react-redux'
// import { Table } from '@material-ui/core'

const RoleManagement = (props) => {
    let [data, setData] = useState([])
    const [addRole, setAddRole] = useState(false)
    const [loader, setLoader] = useState(true)
    const [popup, setPopup] = useState(null)
    const [confirmPopup, setConfirmPopup] = useState(null)
    const AddRole = () => {
        setAddRole(true)
    }
    // let Array = RoleList
    // useEffect(async () => {

    // }, [])
    let Array = props?.configration?.configration?.configuration?.access
    useEffect(async () => {
        await getRoles()
    }, [])
    const getRoles = async () => {
        setLoader(true)
        let data1 = await getAdminRoles()
        data1.map((data, key1) => {
            data1[key1]['label'] = []
            Array.map((arr, key3) => {
                data.access.map((access, key2) => {
                    if (access === arr._id) {
                        data1[key1]['label'].push(arr.label)
                    }
                })
            })
        })
        setLoader(false)
        setData(data1)
    }
    const edit = (data) => {
        setAddRole(data)
    }
    const deleteRole = async () => {
        let key = confirmPopup.key
        let id = confirmPopup.data
        let data1 = [...data]
        let result = await DeleteAdminRoles(id)
        if (result.status === "200") {
            data1.splice(key, 1)
            setData(data1)
        }
        setConfirmPopup(null)
    }
    return (
        <>
            <div className='m-3'>
                <Card className="mb-3">
                    <CardHeader className='d-flex justify-content-between'>
                        <Heading>Role Management</Heading>
                        <ButtonContainer>
                            <Button buttonResult={() => { AddRole() }} >Add Role</Button>
                        </ButtonContainer>
                    </CardHeader>

                </Card>
                {!loader ?
                    data.length > 0 ?
                        data?.map((data, key) =>
                            <Card className="mb-1">
                                <CardHeader className="row m-0 align-items-center">
                                    <div className='col-md-4'>
                                        <Heading size="2">{data.name}</Heading>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='row m-0 align-items-center' style={{ gap: 10 }}>
                                            <Heading size="2">Access</Heading>
                                            {data?.label?.map(access =>
                                                <span className='bg-light rounded-circle-px p-2 px-3'>{access}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-md-4 d-flex justify-content-end'>
                                        <ButtonContainer className="p-2" flat="light">
                                            <Button color="text-primary" buttonResult={() => edit(data)}>
                                                <i className="fas fa-pen"></i>
                                            </Button>
                                            <Button color="text-danger" buttonResult={() => setConfirmPopup({ key, data: data._id })}>
                                                <i className="fas fa-trash"></i>
                                            </Button>
                                        </ButtonContainer>
                                    </div>
                                </CardHeader>
                            </Card>
                        ) : <p>Roles not Found</p> : <CardLoader />}
            </div>
            {addRole ? <Mypopup title={addRole?.name ? "Edit Role" : "Add Role"} onClose={() => { setAddRole(null) }}>
                <AddRolePopup data={addRole} onResPopup={(e) => setPopup(e)} onAddRole={() => setAddRole(null)} />
            </Mypopup> : null}
            {popup ? <Mypopup title={popup[0]} onClose={() => { setPopup(null) }}>
                <div className='text-center mt-3 '>
                    <p>{popup[1]}</p>
                    <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                        setPopup(null);
                        getRoles();

                    }}>Ok</button>
                </div>
            </Mypopup> : null
            }
            {confirmPopup ? <Mypopup title={"Confirm!"} onClose={() => { setConfirmPopup(null) }}>
                <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                <div className="d-flex justify-content-center p-3">
                    <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={(e) => deleteRole(confirmPopup)}>Yes</button>
                    <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => { setConfirmPopup(null) }}>No</button>
                </div>
            </Mypopup> : null}
        </>
    )
}
const mapStateToProps = (state) => ({
    configration: state.configration,
});
export default connect(mapStateToProps, null)(RoleManagement)
// export default RoleManagement