import React, { useEffect, useState } from 'react'
import ReactReducerAction from '../../services/actions/ReactReducerAction'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../components/card/card'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import './ruleEngine.scss'
import Input from '../../components/input/input'
import Select from '../../components/select/select'
import { connect } from 'react-redux'
import Mypopup from '../../components/popup/mypopup'
import { Create_Condition_List, getLevelIds, get_All_Devices_Selected_HubIds, get_All_HubIds_selected_LevelIds, get_All_LevelIds_match_with_deviceType, get_All_Parameters_Selected_Devices, todayDate } from '../../functions/functions'
import { number } from '@amcharts/amcharts4/core'
import { DateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import moment from 'moment'
import { EmailRegex } from '../../services/config/regex'
import InlineLoader from '../../components/loader/inlineLoader'
import { createRules, updateRules } from '../../services/utilities/events'



const RuleEngine = (props) => {
    let data = {}
    if (props.history.location.state) {
        data = props.history.location.state
    }
    let levelId = window.location.hash
    levelId = levelId.split("/")
    levelId = levelId[levelId.length - 1]
    let orgId = levelId
    if (orgId.match("_")) {
        let orgIdList = orgId.split("_")
        orgId = orgIdList[0]
    }
    const interval = [{
        label: "Once", value: 10000000
    }, {
        label: "Always", value: 0
    }, {
        label: "Paused", value: "paused"
    },
    ]
    // let serviceOption = [
    //     { label: "Email", value: "email" },
    //     { label: "Event", value: "event" },
    //     { label: "Webhook", value: "webhook" },
    //     { label: "Set Resource", value: "setResource" }
    // ]
    const [startDate, setStartDate] = useState(todayDate('start'))
    const [endDate, setEndDate] = useState(todayDate('end'))
    const [ruleData, setRuleData] = useState(
        !props.history.location.state ? {
            enabled: true,
            orgId: "",
            name: "",
            rule:
                [
                    {
                        description: "",
                        levelIds: [],
                        deviceIds: [],
                        hubIds: [],
                        emails: [],
                        sms: [],
                        apis: [],
                        deviceType: "",
                        _id: Math.floor(Math.random() * 10000000),
                        name: "Rule 1",
                        param: "",
                        condition: "",
                        prerequisites: [],
                        value: 0,
                        startDate: "",
                        endDate: "",
                        action: [],
                        msg: "",
                        createdBy: "",
                        threshold: {
                            count: 1,
                            interval: null
                        }
                    }]
        } : data
    )
    //validation List 
    const [descriptionName, setDescriptionName] = useState(true)
    const [name, setName] = useState(true)
    const [deviceType, setDeviceType] = useState(true)
    const [parameter, setParameter] = useState(true)
    const [condition, setCondition] = useState(true)
    const [value, setValue] = useState(true)
    const [msg, setMsg] = useState(true)
    const [emailName, setEmailName] = useState(true)
    const [emailValue, setEmailValue] = useState(true)
    const [smsName, setSmsName] = useState(true)
    const [smsValue, setSmsValue] = useState(true)

    const [activeAlert, setActiveAlert] = useState(0)
    const [activeSms, setActiveSms] = useState(0)
    const [activeEmail, setActiveEmail] = useState(0)
    const [activeApis, setActiveApis] = useState(0)
    const [activePrereq, setActivePrereq] = useState(0)
    const [activeAction, setActiveAction] = useState(0)
    const [tab, setTab] = useState("prereq")

    const [ruleDataLength, setRuleDataLength] = useState(1)
    const [lengthSms, setLengthSms] = useState(1)
    const [lengthEmail, setLengthEmail] = useState(1)
    const [lengthPrereq, setLengthPrereq] = useState(1)
    const [lengthAction, setLengthAction] = useState(1)
    const [lengthApis, setLengthApis] = useState(1)
    const [deviceIds, setDeviceIds] = useState([])
    const [levelIdList, setLevelIdList] = useState([])
    const [deviceParamList, setDeviceParamList] = useState([])
    const [prereqValue, setPrereqValue] = useState([])
    const [prereqcondition, setPrereqCondition] = useState([])
    const [actionDeviceId, setActionDeviceId] = useState([])
    const [deviceActionParamList, setDeviceActionParamList] = useState([])
    const [apiHeaderPopup, setApiHeaderPopup] = useState(null)
    const [headerApi, setHeaderApi] = useState([
        { key: "", value: "" }
    ])
    const [deviceTypes, setDeviceTypes] = useState([])
    const [levelIdsList, setLevelIdsList] = useState([])
    const [hubIdsList, setHubIdsList] = useState([])
    const [seletedLevelIds, setSelectedLevelIds] = useState([])
    const [allLevelIds, setAllLevelIds] = useState([])
    const [selectedHubIds, setSelectedHubIds] = useState([])
    const [allHubIds, setAllHUbIds] = useState([])
    const [seletedDeviceIds, setSelectedDeviceIds] = useState([])
    const [alldeviceIds, setAllDeviceIds] = useState([])
    const [deviceIdsList, setDeviceIdsList] = useState([])
    const [paramList, setParamList] = useState([])
    const [inSecond, setInSecond] = useState(false)
    const [paused, setpaused] = useState(false)
    const [intervalType, setIntervalType] = useState()
    const [dateChecked, setDateChecked] = useState(false)
    const [cardLoader, setCardLoader] = useState(false)
    const [popup, setPopup] = useState(false)
    const [noToUpdateDate, setNoToUpdateDate] = useState(true)
    const [conListCreate, setConListCreate] = useState("")
    const [conditionList, setConditionList] = useState("")
    //validation functions

    const checkName = () => {
        let returnVal = true;
        if (ruleData.rule.length > 0) {
            ruleData.rule.map((activeRules, key) => {
                if (!activeRules.name) {
                    ChangeRule(ruleData, key)
                    setName(false)
                    returnVal = false
                } else {
                    setName(true)
                    returnVal = true
                }
            })
        }
        return returnVal

    }
    const checkDevice = () => {
        let returnVal = true;
        if (ruleData.rule.length > 0) {
            ruleData.rule.map((activeRules, key) => {
                if (!activeRules.deviceType) {
                    ChangeRule(ruleData, key)
                    setDeviceType(false)
                    return false
                } else {
                    setDeviceType(true)
                    returnVal = true
                }
            })
        }
        return returnVal

    }
    const checkParam = () => {
        let returnVal = true;
        if (ruleData.rule.length > 0) {
            ruleData.rule.map((activeRules, key) => {
                if (!activeRules.param) {
                    ChangeRule(ruleData, key)
                    setParameter(false)
                    returnVal = false
                } else {
                    setParameter(true)
                    returnVal = true
                }
            })
        }
        return returnVal
    }
    const checkCondition = () => {
        let returnVal = true;
        if (ruleData.rule.length > 0) {
            ruleData.rule.map((activeRules, key) => {
                if (!activeRules.condition) {
                    ChangeRule(ruleData, key)
                    setCondition(false)
                    returnVal = false
                } else {
                    setCondition(true)
                    returnVal = true
                }
            })
        }
        return returnVal
    }
    const checkValue = () => {
        let returnVal = true;
        if (ruleData.rule.length > 0) {
            ruleData.rule.map((activeRules, key) => {
                if (!activeRules.value) {
                    ChangeRule(ruleData, key)
                    setValue(false)
                    returnVal = false
                } else {
                    setValue(true)
                    returnVal = true
                }
            })
        }
        return true
    }
    const checkMsg = () => {
        let returnVal = true;
        if (ruleData.rule.length > 0) {
            ruleData.rule.map((activeRules, key) => {
                if (!activeRules.msg) {
                    ChangeRule(ruleData, key)
                    setMsg(false)
                    returnVal = false
                } else {
                    setMsg(true)
                    returnVal = true
                }
            })
        }
        return returnVal
    }

    const CheckSmsName = () => {
        let returnVal = true;
        if (ruleData.rule[activeAlert].sms.length > 0) {
            ruleData.rule[activeAlert].sms.map((activeSms, key) => {
                if (!activeSms.name) {
                    ChangeStates(ruleData.rule[activeAlert], key, "sms")
                    setSmsName(false)
                    returnVal = false
                } else {
                    setSmsName(true)
                    returnVal = true
                }
            })
        }
        else {
            setSmsName(true)
            returnVal = true
        }
        return returnVal
    }

    const CheckSmsValue = () => {
        let returnVal = true;
        if (ruleData.rule[activeAlert].sms.length > 0) {
            ruleData.rule[activeAlert].sms.map((activeSms, key) => {
                if (!activeSms.value) {
                    ChangeStates(ruleData.rule[activeAlert], key, "sms")
                    setSmsValue(false)
                    returnVal = false
                } else {
                    setSmsValue(true)
                    returnVal = true
                }
            })
        } else {
            setSmsValue(true)
            returnVal = true
        }
        return returnVal
    }

    const CheckEmailName = () => {
        let returnVal = true;
        if (ruleData.rule[activeAlert].emails.length) {

            ruleData.rule[activeAlert].emails.map((activeEmail, key) => {
                if (!activeEmail.name) {
                    ChangeStates(ruleData.rule[activeAlert], key, "email")
                    setEmailName(false)
                    returnVal = false
                } else {
                    setEmailName(true)
                    returnVal = true
                }
            })
        } else {
            setEmailName(true)
            returnVal = true
        }
        return returnVal
    }

    const CheckEmailValue = () => {
        let returnVal = true;
        if (ruleData.rule[activeAlert].emails.length) {
            ruleData.rule[activeAlert].emails.map((activeEmail, key) => {
                let Email = activeEmail?.value.match(EmailRegex)
                ChangeStates(ruleData.rule[activeAlert], key, "email")
                if (!activeEmail.value || !Email) {
                    setEmailValue(false)
                    returnVal = false
                } else {
                    setEmailValue(true)
                    returnVal = true
                }
            })
        } else {
            setEmailName(true)
            returnVal = true
        }
        return returnVal
    }
    //Cerate a List 
    let configration = props.configration.configration.configuration
    useEffect(() => {
        let DeviceType = []
        configration.Device.map((device) => {
            DeviceType.push({ value: device.type, label: device.type })

        })
        setDeviceTypes(DeviceType)
    }, [])
    const onChangeHandlerApiHandler = (key, e) => {
        let headerApi1 = [...headerApi]
        let name = e.name
        headerApi1[key][name] = e.target.value
        setHeaderApi(headerApi1)
    }
    // const [] = useState(1)
    let ApiMethod = [{ label: "GET", value: 'GET' },
    { label: "POST", value: 'POST' },
    { label: "PUT", value: 'PUT' },
    { label: "PATCH", value: 'PATCH' },
    { label: "DELETE", value: 'DELETE' }]

    const apiHeaderPopupOpen = () => {
        let list = [...ruleData.rule[activeAlert].apis[activeApis].headers]
        setHeaderApi(list)
        setApiHeaderPopup(true)

    }
    const AddHeaderInApi = () => {
        let list = [...headerApi]
        list.push({
            key: "", value: ""
        })
        setHeaderApi(list)
    }
    const DeleteHeaderInApi = (key) => {
        // setLevel({ valid: true, key: 0 })
        // setRole({ valid: true, key: 0 })
        let list = [...headerApi]
        list.splice(key, 1)
        setHeaderApi(list)
    }
    const setApi = () => {
        let e = { name: "headers", target: { value: headerApi } }
        onChangeHandler(e)
    }

    useEffect(() => {
        if (!noToUpdateDate) {
            if (ruleData.rule[activeAlert].startDate !== "" && ruleData.rule[activeAlert].startDate !== null) {
                onChangeHandler({ target: { name: "dateAccept", type: "Date" } })
                // setStartDate(ruleData.rule[activeAlert].startDate)
                // setEndDate(ruleData.rule[activeAlert].endDate)
            }
        } else {
            setNoToUpdateDate(false)
        }

    }, [startDate, endDate])


    const onChangeHandler = (e, index) => {
        let data = { ...ruleData }

        if (e.target.name === "enabled") {
            data.enabled = !data.enabled
        }
        let name = e.name
        if (name === "name") {
            data["name"] = e.target.value
            setDescriptionName(true)
        }
        if (name === "ruleName") {
            data.rule[activeAlert]["name"] = e.target.value
            setName(true)
        }
        if (name === "description") {
            data.rule[activeAlert]["description"] = e.target.value
            // setName(true)
        }
        else if (name === "deviceType") {
            data.rule[activeAlert]["deviceType"] = e.target.value
            data.rule[activeAlert]["param"] = ""
            data.rule[activeAlert]["levelIds"] = []
            data.rule[activeAlert]["hubIds"] = []
            data.rule[activeAlert]["deviceIds"] = []
            setDeviceType(true)
        }
        else if (name === "levelIds") {
            let levelIds1 = [...data.rule[activeAlert]["levelIds"]]
            if (!e.target.remove) {
                if (e.target.value === "all") {
                    levelIds1 = []
                    levelIdsList.map(id => {
                        levelIds1.push(id.value)
                    })
                    levelIds1.push("all")
                } else {
                    if (levelIds1.includes("all")) {
                        levelIds1 = []
                    }
                    levelIds1.push(e.target.value)
                }
                setSelectedLevelIds(levelIds1)
            }
            data.rule[activeAlert]["levelIds"] = levelIds1
        }
        else if (name === "hubIds") {
            let hubIds1 = [...data.rule[activeAlert]["hubIds"]]
            if (!e.target.remove) {
                if (e.target.value === "all") {
                    hubIds1 = []
                    hubIdsList.map(id => {
                        hubIds1.push(id.value)
                    })
                    hubIds1.push("all")
                } else {
                    if (hubIds1.includes("all")) {
                        hubIds1 = []
                    }
                    hubIds1.push(e.target.value)
                }
                setSelectedHubIds(hubIds1)
            }
            data.rule[activeAlert]["hubIds"] = hubIds1
        }
        else if (name === "deviceIds") {
            let deviceIds1 = [...data.rule[activeAlert]["deviceIds"]]
            if (!e.target.remove) {
                if (e.target.value === "all") {
                    deviceIds1 = []
                    deviceIdsList.map(id => {
                        deviceIds1.push(id.value)
                    })
                    deviceIds1.push("all")
                } else {
                    if (deviceIds1.includes("all")) {
                        deviceIds1 = []
                    }
                    deviceIds1.push(e.target.value)
                }
                setSelectedDeviceIds(deviceIds1)
            }
            data.rule[activeAlert]["deviceIds"] = deviceIds1
        }
        else if (name === "param") {
            data.rule[activeAlert]["param"] = e.target.value
            setConListCreate(e.target.label)
            data.rule[activeAlert]["condition"] = ""
            setParameter(true)
        }
        else if (name === "value") {
            data.rule[activeAlert]["value"] = e.target.value
            setValue(true)
        }
        else if (name === "condition") {
            data.rule[activeAlert]["condition"] = e.target.value
            setCondition(true)
        }
        else if (name === "msg") {
            data.rule[activeAlert]["msg"] = e.target.value
            setMsg(true)
        }
        else if (name === "count") {
            data.rule[activeAlert]["threshold"]["count"] = (Math.floor(e.target.value))
        }
        else if (name === "selectInterval") {
            setIntervalType(e.target.value)
            if (e.target.value === 'paused') {
                data.rule[activeAlert]["threshold"]["interval"] = null
                setpaused(true)
            }
            else {
                setpaused(false)
                data.rule[activeAlert]["threshold"]["interval"] = e.target.value
            }
        }
        else if (name === "interval") {
            data.rule[activeAlert]["threshold"]["interval"] = (Math.floor(e.target.value))
        }
        else if (e.target.name === "dateAccept") {
            if (e.target?.type === "Date") {
                data.rule[activeAlert]["startDate"] = startDate
                data.rule[activeAlert]["endDate"] = endDate
            } else {
                setDateChecked(!dateChecked)
                if (!dateChecked) {
                    data.rule[activeAlert]["startDate"] = startDate
                    data.rule[activeAlert]["endDate"] = endDate
                } else {
                    data.rule[activeAlert]["startDate"] = ""
                    data.rule[activeAlert]["endDate"] = ""
                }
            }
        }
        else if (name === "smsName") {
            data.rule[activeAlert]["sms"][activeSms]["name"] = e.target.value
            setSmsName(true)
        } else if (name === "smsValue") {
            data.rule[activeAlert]["sms"][activeSms]["value"] = e.target.value
            setSmsValue(true)
        } else if (name === "emailName") {
            data.rule[activeAlert]["emails"][activeSms]["name"] = e.target.value
            setEmailName(true)
        } else if (name === "emailValue") {
            data.rule[activeAlert]["emails"][activeSms]["value"] = e.target.value
            setEmailValue(true)
        } else if (name === "prereqlevelId") {
            data.rule[activeAlert]["prerequisites"][activePrereq]["levelId"] = e.target.value
        } else if (name === "prereqdeviceId") {
            data.rule[activeAlert]["prerequisites"][activePrereq]["deviceId"] = e.target.value
        } else if (name === "prereqparameter") {
            data.rule[activeAlert]["prerequisites"][activePrereq]["parameter"] = e.target.value
        } else if (name === "prereqcondition") {
            data.rule[activeAlert]["prerequisites"][activePrereq]["condition"] = e.target.value
        } else if (name === "prereqvalue") {
            data.rule[activeAlert]["prerequisites"][activePrereq]["value"] = e.target.value
        } else if (name === "actionlevelId") {
            data.rule[activeAction]["action"][activeAction]["levelId"] = e.target.value
        } else if (name === "actiondeviceId") {
            data.rule[activeAction]["action"][activeAction]["deviceId"] = e.target.value
            data.rule[activeAction]["action"][activeAction]["hubId"] = e.target.value.split("_")[0] + "_" + e.target.value.split("_")[1]
        } else if (name === "actionparameter") {
            data.rule[activeAction]["action"][activeAction]["parameter"] = e.target.value
        } else if (name === "actionvalue") {
            data.rule[activeAction]["action"][activeAction]["value"] = e.target.value
        } else if (name === "method") {
            data.rule[activeAlert]["apis"][activeApis]["method"] = e.target.value
        } else if (name === "url") {
            data.rule[activeAlert]["apis"][activeApis]["url"] = e.target.value
        } else if (name === "body") {
            data.rule[activeAlert]["apis"][activeApis]["body"] = e.target.value
        } else if (name === "headers") {
            data.rule[activeAlert]["apis"][activeApis]["headers"] = e.target.value
        }
        else {
            data[name] = e.target.value
        }
        setRuleData(data)
    }
    const handlerStartDate = (e) => {
        let startDate1 = moment(e._d).format('YYYY-MM-DDTHH:mm:ss')
        setStartDate(startDate1)
    }
    const handlerEndDate = (e) => {
        let endDate1 = moment(e._d).format('YYYY-MM-DDTHH:mm:ss')
        setEndDate(endDate1)
    }
    const removeSelectedLevelId = (key) => {
        let levelIds1 = [...seletedLevelIds]
        levelIds1.splice(key, 1)
        setSelectedLevelIds(levelIds1)
        ruleData.rule[activeAlert]["levelIds"] = levelIds1
        onChangeHandler({ name: "levelIds", target: { value: levelIds1, remove: true } })
    }
    const removeSelectedDeviceId = (key) => {
        let deviceIds1 = [...seletedDeviceIds]
        deviceIds1.splice(key, 1)
        setSelectedDeviceIds(deviceIds1)
        ruleData.rule[activeAlert]["deviceIds"] = deviceIds1
        onChangeHandler({ name: "deviceIds", target: { value: deviceIds1, remove: true } })
    }
    const removeSelectedHubId = (key) => {
        let hubIds1 = [...selectedHubIds]
        hubIds1.splice(key, 1)
        setSelectedHubIds(hubIds1)
        ruleData.rule[activeAlert]["hubIds"] = hubIds1
        onChangeHandler({ name: "hubIds", target: { value: hubIds1, remove: true } })
    }

    const addRule = () => {
        let filledInput = true
        let alertKey = ""
        let smsKey = ""
        let emailKey = ""
        let prereqKey = ""
        let actionKey = ""
        let apisKey = ""

        //Check Every Alert of data is full filled
        for (var i = 0; i <= (ruleData.rule.length - 1); i++) {
            if (!ruleData.rule[i].name || !ruleData.rule[i].deviceType || !ruleData.rule[i].param || !ruleData.rule[i].condition || !ruleData.rule[i].value || !ruleData.rule[i].msg) {
                filledInput = false
                alertKey = i
                smsKey = activeSms
                emailKey = activeEmail
                actionKey = activeAction
                prereqKey = activePrereq
                apisKey = activeApis

                break
            }
            for (var k = 0; k <= (ruleData.rule[i].sms.length - 1); k++) {
                if (!ruleData.rule[i].sms[k]?.name || (!ruleData.rule[i].sms[k]?.value)) {
                    filledInput = false
                    alertKey = i
                    smsKey = k
                    prereqKey = activePrereq
                    emailKey = activeEmail
                    actionKey = activeAction
                    apisKey = activeApis

                    break
                }
            }
            for (var j = 0; j <= (ruleData.rule[i].emails.length - 1); j++) {
                let Email = !ruleData.rule[i][j]?.value.match(EmailRegex)
                if (!ruleData.rule[i].emails[j]?.name || (!ruleData.rule[i].emails[j]?.value || !Email)) {
                    filledInput = false
                    alertKey = i
                    smsKey = activeSms
                    emailKey = j
                    prereqKey = activePrereq
                    actionKey = activeAction
                    apisKey = activeApis

                    break
                }
            }
            for (var l = 0; l <= (ruleData.rule[i].prerequisites.length - 1); l++) {
                if (!ruleData.rule[i].prerequisites[l]?.levelId || !ruleData.rule[i].prerequisites[l]?.deviceId || !ruleData.rule[i].prerequisites[l]?.parameter || !ruleData.rule[i].prerequisites[l]?.condition || !ruleData.rule[i].prerequisites[l]?.value) {
                    filledInput = false
                    alertKey = i
                    prereqKey = l
                    emailKey = activeEmail
                    smsKey = activeSms
                    actionKey = activeAction
                    apisKey = activeApis

                    break
                }
            }
            for (var m = 0; m <= (ruleData.rule[i].action.length - 1); m++) {
                if (!ruleData.rule[i].action[m]?.levelId || !ruleData.rule[i].action[m]?.deviceId || !ruleData.rule[i].action[m]?.parameter || !ruleData.rule[i].action[m]?.value) {
                    filledInput = false
                    alertKey = i
                    prereqKey = activePrereq
                    emailKey = activeEmail
                    smsKey = activeSms
                    actionKey = m
                    apisKey = activeApis
                    break
                }
            }
            for (var n = 0; n <= (ruleData.rule[i].apis.length - 1); n++) {
                if (!ruleData.rule[i].apis[n]?.method || !ruleData.rule[i].apis[n]?.url || !ruleData.rule[i].apis[n]?.body) {
                    filledInput = false
                    alertKey = i
                    prereqKey = activePrereq
                    emailKey = activeEmail
                    smsKey = activeSms
                    actionKey = activeAction
                    apisKey = n
                    break
                }
            }
        }
        // if (checkRuleName()) {
        if (checkName()) {
            if (checkDevice()) {
                if (checkParam()) {
                    if (checkCondition()) {
                        if (checkValue()) {
                            if (checkMsg()) {
                                if (CheckSmsName()) {
                                    if (CheckSmsValue()) {
                                        if (CheckEmailName()) {
                                            if (CheckEmailValue()) {
                                                if (filledInput) {
                                                    setCardLoader(true)
                                                    let body = {
                                                        orgId: orgId,
                                                        enabled: ruleData.enabled,
                                                        name: ruleData.rule[0].name,
                                                        // description: ruleData.description,
                                                        rule: ruleData.rule
                                                    }
                                                    if (ruleData._id) {
                                                        return updateRules(ruleData._id, body).then(async res => {
                                                            let result = res
                                                            let message = res.message
                                                            if (result) {
                                                                if (!result.statusCode) {
                                                                    setPopup(["Success!", "Successfully Updated", "success"])
                                                                }
                                                                else {
                                                                    setPopup(["Failure!", message, "danger"])
                                                                }
                                                            }
                                                            setCardLoader(false)
                                                        })
                                                    } else {
                                                        return createRules(body).then(async res => {
                                                            let result = res
                                                            let message = res.message
                                                            if (result) {
                                                                if (!result.statusCode) {
                                                                    setPopup(["Success!", "Successfully Created", "success"])
                                                                }
                                                                else {
                                                                    setPopup(["Failure!", message, "danger"])
                                                                }
                                                            }
                                                            setCardLoader(false)
                                                        })
                                                    }
                                                } else {
                                                    ChangeRule(ruleData.rule[alertKey], alertKey)
                                                    ChangeStates(ruleData.rule[alertKey], smsKey, "sms")
                                                    ChangeStates(ruleData.rule[alertKey], emailKey, "email")
                                                    ChangeStates(ruleData.rule[alertKey], prereqKey, "prereq")
                                                    ChangeStates(ruleData.rule[alertKey], actionKey, "action")
                                                    ChangeStates(ruleData.rule[alertKey], apisKey, "apis")

                                                }
                                            }
                                        }
                                        else {
                                            CheckEmailValue(true)
                                        }
                                    }
                                    else {
                                        CheckEmailName(true)
                                        CheckEmailValue(true)

                                    }
                                }
                                else {
                                    CheckSmsValue(true)
                                    CheckEmailName(true)
                                    CheckEmailValue(true)

                                }
                            }
                            else {
                                CheckSmsName(true)
                                CheckSmsValue(true)
                                CheckEmailName(true)
                                CheckEmailValue(true)
                            }
                        }
                        else {
                            setMsg(true)
                            CheckSmsName(true)
                            CheckSmsValue(true)
                            CheckEmailName(true)
                            CheckEmailValue(true)
                        }
                    }
                    else {
                        setValue(true)
                        setMsg(true)
                        CheckSmsName(true)
                        CheckSmsValue(true)
                        CheckEmailName(true)
                        CheckEmailValue(true)
                    }
                }
                else {
                    setCondition(true)
                    setValue(true)
                    setMsg(true)
                    CheckSmsName(true)
                    CheckSmsValue(true)
                    CheckEmailName(true)
                    CheckEmailValue(true)
                }
            }
            else {
                setParameter(true)
                setCondition(true)
                setValue(true)
                setMsg(true)
                CheckSmsName(true)
                CheckSmsValue(true)
                CheckEmailName(true)
                CheckEmailValue(true)
            }

        }
        else {
            setDeviceType(true)
            setParameter(true)
            setCondition(true)
            setValue(true)
            setMsg(true)
            CheckSmsName(true)
            CheckSmsValue(true)
            CheckEmailName(true)
            CheckEmailValue(true)
        }
        // } else {
        //     setName(true)
        //     setDeviceType(true)
        //     setParameter(true)
        //     setCondition(true)
        //     setValue(true)
        //     setMsg(true)
        //     CheckSmsName(true)
        //     CheckSmsValue(true)
        //     CheckEmailName(true)
        //     CheckEmailValue(true)
        // }
    }



    useEffect(() => {
        let levelIds1 = []
        let allLevelIds1 = []
        let levelIds = get_All_LevelIds_match_with_deviceType(props.organization.organization, props.userInfo.varification?.hierarchy, ruleData.rule[activeAlert].deviceType)
        console.log(levelIds)
        levelIds?.map((level) => {
            levelIds1.push({ label: level._id + " > " + level.locationName, value: level._id })
            allLevelIds1.push(level._id)
        })
        if (levelIds.length > 0) {
            levelIds1.push({ label: "All", value: "all" })
        }
        if (ruleData.rule[activeAlert].levelIds.length < 1 && ruleData.rule[activeAlert].deviceType !== "") {
            let levelId1 = allLevelIds1
            levelId1.push("all")
            ruleData.rule[activeAlert].levelIds = levelId1
            setSelectedLevelIds(levelId1)
        }
        setAllLevelIds(allLevelIds1)
        setLevelIdsList(levelIds1)

    }, [ruleData?.rule[activeAlert].deviceType, ruleData?.rule[activeAlert].levelIds])


    useEffect(() => {
        let hubids1 = []
        let allHubIds1 = []
        let hubIds = get_All_HubIds_selected_LevelIds(props.organization.organization, props.userInfo.varification?.hierarchy, ruleData.rule[activeAlert].deviceType, ruleData.rule[activeAlert].levelIds.length > 0 ? ruleData.rule[activeAlert].levelIds : allLevelIds)
        hubIds?.map((hub) => {
            hubids1.push({ label: hub.value + " > " + hub.name, value: hub.value })
            allHubIds1.push(hub.value)
        })
        if (hubIds.length > 0) {
            hubids1.push({ label: "All", value: "all" })
        }
        if (ruleData.rule[activeAlert].hubIds?.length < 1 && ruleData.rule[activeAlert].deviceType !== "") {
            let hubId1 = allHubIds1
            hubId1.push("all")
            ruleData.rule[activeAlert].hubIds = hubId1
            setSelectedHubIds(hubId1)
        }
        setHubIdsList(hubids1)
        setAllHUbIds(allHubIds1)
    }, [ruleData?.rule[activeAlert].levelIds, ruleData?.rule[activeAlert].hubIds, allLevelIds])


    useEffect(() => {
        let deviceIds1 = []
        let allDeviceIds1 = []
        let deviceIds = get_All_Devices_Selected_HubIds(props.organization.organization, ruleData.rule[activeAlert].deviceType, ruleData.rule[activeAlert].hubIds?.length > 0 ? ruleData.rule[activeAlert].hubIds : allHubIds)
        deviceIds?.map((level) => {
            deviceIds1.push({ label: level._id + " > " + level.name, value: level._id })
            allDeviceIds1.push(level._id)
        })
        if (deviceIds.length > 0) {
            deviceIds1.push({ label: "All", value: "all" })
        }
        if (ruleData.rule[activeAlert].deviceIds?.length < 1 && ruleData.rule[activeAlert].deviceType !== "") {
            let deviceId1 = allDeviceIds1
            deviceId1.push("all")
            ruleData.rule[activeAlert].deviceIds = deviceId1
            setSelectedDeviceIds(deviceId1)
        }
        setAllDeviceIds(allDeviceIds1)
        setDeviceIdsList(deviceIds1)
    }, [ruleData.rule[activeAlert].hubIds, ruleData.rule[activeAlert].deviceIds, allHubIds])
    useEffect(() => {
        let param = []
        let parameters = get_All_Parameters_Selected_Devices(props.organization.organization.alldevices, ruleData.rule[activeAlert].deviceIds?.length > 0 ? ruleData.rule[activeAlert].deviceIds : alldeviceIds)
        parameters?.map((parameter) => {
            param.push({ label: parameter.value + " > " + parameter.type, value: parameter.value })
        })
        setParamList(param)
    }, [ruleData.rule[activeAlert].deviceIds, alldeviceIds, tab])

    //this useEffect set a LevelId List
    useEffect(() => {
        let levelIds = []
        let branches = false
        if (props.organization.organization) {
            props.organization.organization.allhubs.map((level) => {
                if (props.userInfo.varification?.hierarchy.length) {
                    props.userInfo.varification?.hierarchy.map(levels => {
                        if (level.levelId.match(levels._id)) {
                            if (levels.branches.length > 0) {
                                branches = true
                                levels.branches.map(branch => {
                                    if (level.levelId.match(branch.levelId)) {

                                        if (!levelIds.includes(level.levelId)) {
                                            levelIds.push(level.levelId)
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
                if (!branches) {
                    if (!levelIds.includes(level.levelId)) {
                        levelIds.push(level.levelId)
                    }
                }
            })
            levelIds = [...new Set(levelIds.map(id => {
                let label = ""
                id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
                return ({ label, value: id })
            }))]
            // levelIds = [...new Set(props.organization.organization.allhubs.map(hub => hub.levelId))]
            // levelIds = [...new Set(levelIds.map(id => {
            //     let label = ""
            //     id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
            //     return ({ label, value: id })
            // }))]
        }
        setLevelIdList(levelIds)
    }, [])
    // useEffect(() => {
    //     let alert1 = { ...ruleData }
    //     // setRuleData({
    //     //     name: "",
    //     //     orgId: "",
    //     //     description: "",
    //     //     rule:
    //     //         [
    //     //             {
    //     //                 levelIds: [],
    //     //                 deviceIds: [],
    //     //                 emails: [],
    //     //                 sms: [],
    //     //                 apis: [],
    //     //                 deviceType: "",
    //     //                 name: "Rule 1",
    //     //                 param: "",
    //     //                 condition: "",
    //     //                 prerequisites: [],
    //     //                 value: 0,
    //     //                 startDate: "",
    //     //                 endDate: "",
    //     //                 action: [],
    //     //                 msg: "",
    //     //                 createdBy: "",
    //     //                 threshold: {
    //     //                     count: 0,
    //     //                     interval: 1
    //     //                 },
    //     //                 ackTill: ""

    //     //             }
    //     //         ]
    //     // })
    //     for (var i = 1; i <= ruleDataLength; i++) {
    //         // if ("rule" in alert1) {
    //         alert1.rule.push({
    //             levelIds: [],
    //             deviceIds: [],
    //             emails: [],
    //             sms: [],
    //             apis: [],
    //             deviceType: "",
    //             name: "Rule 1",
    //             param: "",
    //             condition: "",
    //             prerequisites: [],
    //             value: 0,
    //             startDate: "",
    //             endDate: "",
    //             action: [],
    //             msg: "",
    //             createdBy: "",
    //             threshold: {
    //                 count: 0,
    //                 interval: 1
    //             },
    //             ackTill: ""

    //         })
    //         // }
    //     }
    //     for (var j = 1; j <= lengthEmail; j++) {
    //         if ("emails" in alert1.rule) {
    //             alert1.rule.map(email => email.emails.push({
    //                 name: "",
    //                 value: ""
    //             }))
    //         }
    //     }
    //     for (var k = 1; k <= lengthSms; k++) {
    //         if ("sms" in alert1.rule) {
    //             alert1.rule.map(sms => sms.sms.push({
    //                 name: "",
    //                 value: ""
    //             }))
    //         }
    //     }
    //     for (var l = 1; l <= lengthPrereq; l++) {
    //         if ("prerequisites" in alert1.rule) {
    //             alert1.rule.map(prerequisites => prerequisites.prerequisites.push({
    //                 levelId: "",
    //                 deviceId: "",
    //                 parameter: "",
    //                 condition: "",
    //                 value: "",
    //             }))
    //         }
    //     }
    //     for (var m = 1; m <= lengthAction; m++) {
    //         if ("action" in alert1.rule) {
    //             alert1.rule.map(action => action.action.push({
    //                 levelId: "",
    //                 deviceId: "",
    //                 parameter: "",
    //                 value: "",
    //             }))
    //         }
    //     }
    //     for (var n = 1; n <= lengthApis; n++) {
    //         if ("apis" in alert1.rule) {
    //             alert1.rule.map(apis => apis.apis.push({
    //                 header: "",
    //                 method: "",
    //                 url: "",
    //                 body: "",
    //             }))
    //         }
    //     }
    //     setRuleData(alert1)
    // }, [])
    //this useEffect set a new List
    useEffect(() => {
        prereqFunc()
    }, [ruleData, activePrereq, activeAlert])

    useEffect(() => {
        rulesFunc()
    }, [activeAlert])
    useEffect(() => {
        // rulesFunc()
        actionFunc()
        prereqFunc()
    }, [tab])

    useEffect(() => {
        let List1 = []
        if (ruleData.rule[activeAlert].param !== "") {
            if (conListCreate !== "") {
                let conListCreate1 = conListCreate.split("> ")[1]
                let List = Create_Condition_List(ruleData.rule[activeAlert].deviceType, conListCreate1, props?.configration?.configration?.configuration?.Device)
                List.map(value => {
                    List1.push({ label: value, value: value })
                })
                setConditionList(List1)
            } else {
                let paramType = ""
                let types = get_All_Parameters_Selected_Devices(props.organization.organization.alldevices, ruleData.rule[activeAlert].deviceIds?.length > 0 ? ruleData.rule[activeAlert].deviceIds : alldeviceIds)
                // props.organization.organization.alldevices.map(device => {
                types.map(type => {
                    if (type.value === ruleData.rule[activeAlert].param) {
                        paramType = type.type
                    }
                })
                let List = Create_Condition_List(ruleData.rule[activeAlert].deviceType, paramType, props?.configration?.configration?.configuration?.Device)
                List.map(value => {
                    List1.push({ label: value, value: value })
                })
                setConditionList(List1)
                // })
            }
        }
    }, [ruleData.rule[activeAlert].param])
    //Action UseEffect
    const actionFunc = () => {
        let deviceId = []
        let actionParam = []
        if (ruleData.rule[activeAlert]?.action?.[activeAction]?.levelId) {
            props?.organization?.organization?.alldevices.map(device => {
                if (ruleData.rule[activeAlert]?.action?.[activeAction].levelId === device.levelId) {
                    props?.configration?.configration?.configuration?.Device.map(deviceConfig => {
                        if (deviceConfig.isControllable && device.type === deviceConfig.type) {
                            deviceId.push({ label: device.hubId + " > " + device.name, value: device._id, parameter: device.parameter })
                            setActionDeviceId(deviceId)
                        }
                    })
                }
            })
        } else {
            setActionDeviceId([])
        }
        if (ruleData.rule[activeAlert]?.action?.[activeAction]?.deviceId) {
            actionDeviceId.map((deviceId) => {
                if (ruleData.rule[activeAlert].action[activeAction].deviceId === deviceId.value) {
                    deviceId.parameter.map(param => {
                        actionParam.push({ label: param.name ? param.name : param.types, value: param.value })
                    })
                    setDeviceActionParamList(actionParam)
                }
            })
        }
    }
    const rulesFunc = () => {
        let deviceIds = []
        let levelIds = []
        let hubIds = []
        if (ruleData.rule[activeAlert].levelIds) {
            ruleData.rule[activeAlert].levelIds.map(id => {
                levelIds.push(id)
            })
            setSelectedLevelIds(levelIds)
        } else {
            setSelectedLevelIds([])
        }
        if (ruleData.rule[activeAlert].hubIds) {
            ruleData.rule[activeAlert].hubIds.map(id => {
                hubIds.push(id)
            })
            setSelectedHubIds(hubIds)
        } else {
            setSelectedHubIds([])
        }
        if (ruleData.rule[activeAlert].deviceIds) {
            ruleData.rule[activeAlert].deviceIds.map(id => {
                deviceIds.push(id)
            })
            setSelectedDeviceIds(deviceIds)
        } else {
            setSelectedDeviceIds([])
        }
        if (ruleData.rule[activeAlert].threshold.interval !== null) {
            if (ruleData.rule[activeAlert].threshold.interval === 10000000 || ruleData.rule[activeAlert].threshold.interval === 0) {
                setIntervalType(ruleData.rule[activeAlert].threshold.interval)
                setpaused(false)
            } else {
                setpaused(true)
                setIntervalType("paused")
            }
        } else {
            setIntervalType("")
            setpaused(false)
        }
        if (ruleData.rule[activeAlert].startDate !== "" && ruleData.rule[activeAlert].startDate !== null) {
            setDateChecked(true)
            setStartDate(ruleData.rule[activeAlert].startDate)
            setEndDate(ruleData.rule[activeAlert].endDate)
        } else {
            setDateChecked(false)
        }
    }
    //Prerequisite UseEffect
    const prereqFunc = () => {
        let prereqParam = []
        let deviceId = []
        let deviceConfig = []
        if (ruleData.rule[activeAlert]?.prerequisites?.[activePrereq]?.levelId) {
            props?.organization?.organization?.alldevices.map(device => {
                if (ruleData.rule[activeAlert]?.prerequisites?.[activePrereq].levelId === device.levelId) {
                    deviceId.push({ label: device.hubId + " > " + device.name, value: device._id, parameter: device.parameter })
                    setDeviceIds(deviceId)
                    if (ruleData.rule[activeAlert]?.prerequisites?.[activePrereq].deviceId === device._id) {
                        let deviceType = device.type
                        props?.configration?.configration?.configuration?.Device.map(config => {
                            if (deviceType === config.type) {
                                deviceConfig = config
                            }
                        })
                    }
                }
            })
        } else {
            setDeviceIds([])
        }
        if (ruleData.rule[activeAlert]?.prerequisites?.[activePrereq]?.deviceId) {
            deviceId.map((deviceId) => {
                if (ruleData.rule[activeAlert].prerequisites[activePrereq].deviceId === deviceId.value) {
                    deviceId.parameter.map(param => {
                        prereqParam.push({ label: param.name ? param.name : param.types, value: param.value })
                    })
                    setDeviceParamList(prereqParam)
                }
            })
        }
        let condition1 = []
        let value1 = []
        if (deviceParamList.length) {
            prereqParam.map(paramlabel => {
                if (ruleData.rule[activeAlert]?.prerequisites[activePrereq]?.parameter === paramlabel.value) {
                    let label = paramlabel.label
                    if (deviceConfig?.settings?.prereq?.length) {
                        deviceConfig?.settings?.prereq?.map((deviceParam) => {
                            if (label === deviceParam.parameter) {
                                if (deviceParam.conditions.length) {
                                    deviceParam.conditions.map((condition) => {
                                        condition1.push({ label: condition, value: condition })
                                    })
                                    setPrereqCondition(condition1)
                                } if (deviceParam.values.length) {
                                    deviceParam.values.map((value) => {
                                        value1.push({ label: value.toString(), value: value })
                                    })
                                    setPrereqValue(value1)
                                } else {
                                    setPrereqValue([])
                                }
                            }
                        })
                    } else {
                        setPrereqCondition(condition1)
                    }
                }
            })
        }
    }
    // const AddRule = () => {
    //     let newlength = (ruleDataLength + 1);
    //     let data = { ...ruleData }
    //     data.rule.push(
    //         {
    //             levelIds: [],
    //             deviceIds: [],
    //             emails: [],
    //             sms: [],
    //             apis: [],
    //             deviceType: "",
    //             _id: Math.floor(Math.random() * 10000000),
    //             name: "Rule" + newlength,
    //             param: "",
    //             condition: "",
    //             prerequisites: [],
    //             value: 0,
    //             startDate: "",
    //             endDate: "",
    //             action: [],
    //             msg: "",
    //             createdBy: "",
    //             threshold: {
    //                 count: 1,
    //                 interval: null
    //             },
    //         }
    //     )
    //     setRuleDataLength(newlength)
    //     setRuleData(data)
    // }
    // const DeleteRule = (key) => {
    //     let remove = { ...ruleData }
    //     remove.rule.splice(key, 1)
    //     if (activeAlert > 0) {
    //         ChangeRule(ruleData.rule[activeAlert - 1], activeAlert - 1)
    //     }
    //     else {
    //         ChangeRule(ruleData.rule[activeAlert], activeAlert)
    //     }
    //     setRuleData(remove)
    // }

    const addAlertMsgEmail = (index, type) => {
        let newlengthSms = (ruleData.rule[activeAlert].sms.length + 1);
        let newlengthEmail = (ruleData.rule[activeAlert].emails.length + 1);
        let newlengthpPrereq = (ruleData.rule[activeAlert].prerequisites.length + 1);
        let newlengthAction = (ruleData.rule[activeAlert].action.length + 1);
        let newlengthApis = (ruleData.rule[activeAlert].apis.length + 1);

        let alert1 = { ...ruleData }
        if (type === "sms") {
            alert1.rule[index].sms.push({
                name: "sms" + newlengthSms,
                value: ""
            })
            setLengthSms(newlengthSms)
        }
        if (type === "email") {
            alert1.rule[index].emails.push({
                name: "email" + newlengthEmail,
                value: ""
            })
            setLengthEmail(newlengthEmail)
        }
        if (type === "prereq") {
            alert1.rule[index].prerequisites.push({
                name: 'Condition' + newlengthpPrereq,
                levelId: "",
                deviceId: "",
                parameter: "",
                condition: "",
                value: "",
            })
            setLengthPrereq(newlengthpPrereq)
        }
        if (type === "action") {
            alert1.rule[index].action.push({
                name: "Action" + newlengthAction,
                deviceId: "",
                parameter: "",
                value: "",
                hubId: ""
            })
            setLengthAction(newlengthAction)
        }
        if (type === "apis") {
            alert1.rule[index].apis.push({
                name: 'API' + newlengthApis,
                headers: "",
                method: "",
                url: "",
                body: ""
            })
            setLengthApis(newlengthApis)
        }
        setRuleData(alert1)
    }

    const deleteMSGEMAIL = (key, type) => {
        let removeSms = { ...ruleData }

        if (type === "sms") {
            removeSms.rule[activeAlert].sms.splice(key, 1)
            if (activeSms > 0) {
                ChangeStates(removeSms.rule[activeAlert], activeSms - 1, tab)
            }
            else {
                ChangeStates(removeSms.rule[activeAlert], activeSms, tab)
            }
        }
        if (type === "email") {
            removeSms.rule[activeAlert].emails.splice(key, 1)
            if (activeEmail > 0) {
                ChangeStates(removeSms.rule[activeAlert], activeEmail - 1, tab)
            }
            else {
                ChangeStates(removeSms.rule[activeAlert], activeEmail, tab)
            }
        }
        if (type === "prereq") {
            removeSms.rule[activeAlert].prerequisites.splice(key, 1)
            if (activePrereq > 0) {
                ChangeStates(removeSms.rule[activeAlert], activePrereq - 1, tab)
            }
            else {
                ChangeStates(removeSms.rule[activeAlert], activePrereq, tab)
            }
        }
        if (type === "action") {
            removeSms.rule[activeAlert].action.splice(key, 1)
            if (activeAction > 0) {
                ChangeStates(removeSms.rule[activeAlert], activeAction - 1, tab)
            }
            else {
                ChangeStates(removeSms.rule[activeAlert], activeAction, tab)
            }
        }
        if (type === "apis") {
            removeSms.rule[activeAlert].apis.splice(key, 1)
            if (activeApis > 0) {
                ChangeStates(removeSms.rule[activeAlert], activeApis - 1, tab)
            }
            else {
                ChangeStates(removeSms.rule[activeAlert], activeApis, tab)
            }
        }
        setRuleData(removeSms)
    }

    const ChangeRule = (alertdata, key) => {
        let data = { ...ruleData }
        data[key] = alertdata
        setActiveAlert(key);
        setRuleData(data)
    }
    const ChangeStates = (data, key, type) => {
        let data1 = { ...ruleData }
        data1[activeAlert] = data
        if (type === "sms") {
            setActiveSms(key)
        } else if (type === "email") {
            setActiveEmail(key)
        }
        else if (type === "prereq") {
            setActivePrereq(key)
        }
        else if (type === "action") {
            setActiveAction(key)
        }
        else if (type === "apis") {
            setActiveApis(key)
        }
        setRuleData(data1)
        // let alert1 = { ...alert }
        // alert1.sms[key] = SmsData
    }
    return (
        <>
            <div className='p-3' style={{ display: "block" }}>
                <Card blur className="mb-3 position-sticky" style={{ top: 70, zIndex: '2' }}>
                    <CardHeader className="d-flex justify-content-between">
                        <Heading>
                            Create a new rule
                        </Heading>
                        {cardLoader ? <div style={{ height: 40 }}><InlineLoader /></div>
                            :
                            <div className='d-flex'>
                                <ButtonContainer>
                                    <Button buttonResult={() => props.history.push("/rule-engine/" + levelId)}>Cancel</Button>
                                </ButtonContainer>

                                <ButtonContainer>
                                    <Button buttonResult={() => addRule()}>{ruleData._id ? "Save" : "Add"}</Button>
                                </ButtonContainer>
                            </div>
                        }
                    </CardHeader>
                </Card>
                <div className='mt-3'>
                    {/* <Card> */}
                    <div>

                        <Card className="pb-3">
                            <CardHeader className="d-flex">
                                <label className='d-flex m-0' style={{ gap: 10 }}>
                                    <input type="checkbox" checked={ruleData.enabled} name='enabled' onChange={(e) => onChangeHandler(e)} />
                                    <Heading size={3}> Enabled </Heading>
                                </label>
                            </CardHeader>
                            {/* <CardHeader className="p-0"> */}
                            {/* <div className='row m-0 pb-4'>
                                    <div className='col d-flex pb-3' style={{ width: "100%", overflowX: "auto", overflowY: "none" }} >
                                        {ruleData.rule.map((alertData, key) =>
                                            <div className={'tabButton d-flex ' + (key === activeAlert ? "active" : "null")} style={{ minWidth: (key === activeAlert) ? "130px" : "100px" }}>
                                                <div className='inner-btn text-truncate' onClick={() => ChangeRule(alertData, key)} key={key}>{alertData.name}</div>
                                                {ruleData.rule.length > 1 ?
                                                    <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => DeleteRule(key)}><i className='fas fa-trash fa-sm'></i></div>
                                                    : null}
                                            </div>
                                        )}
                                    </div>
                                    <div className='justify-content-end px-3'>
                                        <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => AddRule()} >+</button>
                                    </div>
                                </div> */}
                            {/* </CardHeader> */}
                            <div className='m-3'>
                                <div className='d-flex px-0'>
                                    <div className='col-md-4 col-sm-12 mb-3'>
                                        <Input name="ruleName" label="Name*" defaultValue={ruleData.rule[activeAlert]?.name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Rule Name", status: !name }} />
                                    </div>
                                    <div className='col-md-8 col-sm-12 mb-3'>
                                        <Input name="description" label="Description" defaultValue={ruleData.rule[activeAlert]?.description} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Rule Name", status: !name }} />
                                    </div>
                                </div>

                                <CardBody className="row mx-0 mt-3">
                                    <div className='col-md-4 col-sm-12 mb-3'>
                                        <Select name="deviceType" label="Device Type*" onClick options={deviceTypes} defaultValue={{ value: ruleData.rule[activeAlert]?.deviceType }} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add deviceType", status: !deviceType }} />
                                    </div>
                                    <div className='col-md-8 col-sm-12 mb-3'>
                                        <Select name="levelIds" label="Levels" onClick options={levelIdsList} defaultValue={{ value: ruleData.rule[activeAlert]?.levelIds.length > 0 ? ruleData.rule[activeAlert]?.levelIds[ruleData.rule[activeAlert]?.levelIds.length - 1] : "all" }} onChange={(e) => onChangeHandler(e)} />
                                        {ruleData.rule[activeAction].deviceType ?
                                            <div className='mt-3'>
                                                <div className='row m-0 align-items-center' style={{ gap: 10 }}>
                                                    {!seletedLevelIds.includes('all') ? seletedLevelIds.map((id, key) =>
                                                        <div className='bg-light rounded-circle-px p-2 px-3'>
                                                            <span>{id}</span>
                                                            <span className='btn btn-light rounded-circle' style={{ background: "" }} onClick={() => removeSelectedLevelId(key)}><i className='fa fa-times'></i></span>                                                    </div>
                                                    ) : <div className='bg-light rounded-circle-px p-2 px-3'>
                                                        <span>All</span>
                                                    </div>
                                                    }
                                                </div>
                                            </div> : null}
                                    </div>

                                    <div className='col-md-4 col-sm-12 mb-3'>
                                        <Select name="hubIds" label="Hubs" onClick options={hubIdsList} defaultValue={{ value: ruleData.rule[activeAlert]?.hubIds?.length > 0 ? ruleData.rule[activeAlert]?.hubIds[ruleData.rule[activeAlert]?.hubIds.length - 1] : "all" }} onChange={(e) => onChangeHandler(e)} />
                                        {ruleData.rule[activeAction].deviceType ?
                                            <div className='mt-3'>
                                                <div className='row m-0 align-items-center' style={{ gap: 10 }}>
                                                    {!selectedHubIds.includes('all') ? selectedHubIds.map((id, key) =>
                                                        <div className='bg-light rounded-circle-px p-2 px-3'>
                                                            <span>{id}</span>
                                                            <span className='btn btn-light rounded-circle' style={{ background: "" }} onClick={() => removeSelectedHubId(key)}><i className='fa fa-times'></i></span>                                                    </div>
                                                    ) : <div className='bg-light rounded-circle-px p-2 px-3'>
                                                        <span>All</span>
                                                    </div>
                                                    }
                                                </div>
                                            </div> : null}
                                    </div>

                                    <div className='col-md-4 col-sm-12 mb-3'>
                                        <Select name="deviceIds" label="Devices" onClick options={deviceIdsList} defaultValue={{ value: ruleData.rule[activeAlert]?.deviceIds.length > 0 ? ruleData.rule[activeAlert]?.deviceIds[ruleData.rule[activeAlert]?.deviceIds.length - 1] : "all" }} onChange={(e) => onChangeHandler(e)} />
                                        {ruleData.rule[activeAction].deviceType ?
                                            <div className='mt-3'>
                                                <div className='row m-0 align-items-center' style={{ gap: 10 }}>
                                                    {!seletedDeviceIds.includes('all') ? seletedDeviceIds.map((id, key) =>
                                                        <div className='bg-light rounded-circle-px p-2 px-3'>
                                                            <span>{id}</span>
                                                            <span className='btn btn-light rounded-circle' style={{ background: "" }} onClick={() => removeSelectedDeviceId(key)}><i className='fa fa-times'></i></span>                                                    </div>
                                                    ) : <div className='bg-light rounded-circle-px p-2 px-3'>
                                                        <span>All</span>
                                                    </div>}
                                                </div>
                                            </div> : null}
                                    </div>

                                    <div className='col-md-4 col-sm-12 mb-3'>
                                        <Select dropDownHeight="100px" name="param" label="Parameter*" onClick options={paramList} defaultValue={{ value: ruleData.rule[activeAlert]?.param }} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please add parameter", status: !parameter }} />
                                    </div>
                                    <div className='col-md-3 col-sm-12 mb-3'>
                                        <Select name="condition" label="Condition*" onClick options={conditionList} defaultValue={{ value: ruleData.rule[activeAlert]?.condition }} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please add condition ", status: !condition }} />
                                    </div>
                                    <div className='col-md-9 col-sm-12 mb-3'>
                                        <Input name="value" label="Enter value or ${expression}*" type="number" inputmode="Numeric" defaultValue={ruleData.rule[activeAlert]?.value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please add value ", status: !value }} />
                                    </div>
                                    <div className='col-md-12 col-sm-12 mb-3'>
                                        <Input name="msg" label="Message" type="textarea" defaultValue={ruleData.rule[activeAlert]?.msg} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Message", status: !msg }} />
                                    </div>
                                    <div className='row m-0 mt-3 w-100'>
                                        <div className='col-6'>
                                            <Card className='h-100'>
                                                <CardHeader>
                                                    <Heading size={3}> Frequency </Heading>

                                                </CardHeader>
                                                <CardBody className="mt-3">
                                                    <div className='row m-0'>
                                                        <div className='col-md-6 col-sm-12 mb-3'>
                                                            <Input name="count" type="number" label="Observed Time*" defaultValue={ruleData.rule[activeAlert]?.threshold.count} onChange={(e) => onChangeHandler(e)} />
                                                        </div>
                                                        <div className='col-md-6 col-sm-12 mb-3'>
                                                            <Select name="selectInterval" label="Interval*" options={interval} defaultValue={{
                                                                value:
                                                                    intervalType ? intervalType : 0
                                                            }} onClick onChange={(e) => onChangeHandler(e)} />
                                                        </div>
                                                        {paused ?
                                                            <>
                                                                <div className='col-md-12 col-sm-12 mb-3'>
                                                                    <Input name="interval" type="number" label="Seconds" defaultValue={ruleData.rule[activeAlert]?.threshold.interval} onChange={(e) => onChangeHandler(e)} />
                                                                </div>
                                                            </>

                                                            : null}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className='col-6'>
                                            <Card className='h-100'>
                                                <CardHeader>
                                                    <label className='d-flex m-0' style={{ gap: 10 }}>
                                                        <input type="checkbox" checked={dateChecked} name='dateAccept' onChange={(e) => onChangeHandler(e)} />
                                                        <Heading size={3}> Response Time </Heading>
                                                    </label>
                                                </CardHeader>
                                                <CardBody className="row mx-0 mt-3">
                                                    <div className='col-lg-6 col-6 '>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <DateTimePicker
                                                                style={{ fontSize: 16 }}
                                                                disabled={!dateChecked ? true : false}
                                                                className='w-100'
                                                                inputVariant="outlined"
                                                                // disableFuture="true"
                                                                label="Start Date"
                                                                // className="mr-3"
                                                                value={startDate}
                                                                onChange={(event) => handlerStartDate(event)}
                                                            // onChange={(event) => onChangeHandler({ name: 'startDate', target: { value: moment(event._d).format('YYYY-MM-DDTHH:mm:ss') } }, null)}
                                                            // format='YYYY-MM-DDTHH:mm:ssZ'
                                                            // format='YYYY-MM-DDTHH:mm:ss'

                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <div className='col-lg-6 col-6'>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <DateTimePicker
                                                                disabled={!dateChecked ? true : false}
                                                                style={{ fontSize: 16 }}
                                                                className='w-100'
                                                                inputVariant="outlined"
                                                                // disableFuture="true"
                                                                label="End Date"
                                                                value={endDate}
                                                                onChange={(event) => handlerEndDate(event)}
                                                            // format='YYYY-MM-DDTHH:mm:ss'
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>


                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>

                                </CardBody>
                            </div>
                            <div className='px-4'>
                                <InnerCard>
                                    <CardHeader className='p-0'>
                                        <div className='row m-0 pb-4 mt-4'>
                                            <div className={"col d-flex"}>
                                                <div className={'tabButton d-flex ' + (tab === "prereq" ? "active" : "null")}>
                                                    <div className='inner-btn' onClick={() => setTab("prereq")}>AND CONDITION</div>
                                                </div>
                                                <div className={'tabButton d-flex ' + (tab === "action" ? "active" : "null")}>
                                                    <div className='inner-btn' onClick={() => setTab("action")}>ACTION</div>
                                                </div>
                                                <div className={'tabButton d-flex ' + (tab === "sms" ? "active" : "null")}>
                                                    <div className='inner-btn' onClick={() => setTab("sms")}>SMS</div>
                                                </div>
                                                <div className={'tabButton d-flex ' + (tab === "email" ? "active" : "null")}>
                                                    <div className='inner-btn' onClick={() => setTab("email")}>EMAIL</div>
                                                </div>
                                                <div className={'tabButton d-flex ' + (tab === "apis" ? "active" : "null")}>
                                                    <div className='inner-btn' onClick={() => setTab("apis")}>WEBHOOK</div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {/* Tab SMS */}
                                        {
                                            tab === "sms" ?

                                                <div>
                                                    <CardHeader className='p-0'>
                                                        <div className='row m-0 pb-4'>
                                                            <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                                {ruleData.rule[activeAlert].sms.map((smsData, key) =>
                                                                    <div className={'tabButton d-flex ' + (key === activeSms ? "active" : "null")} style={{ minWidth: (key === activeSms) ? "130px" : "100px" }}>
                                                                        <div className='inner-btn text-truncate' onClick={() => ChangeStates(ruleData.rule[activeAlert], key, tab)} key={key}>{smsData.name}</div>
                                                                        {ruleData.rule[activeAlert].sms.length > 0 ?
                                                                            <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteMSGEMAIL(key, tab)}><i className='fas fa-trash fa-sm'></i></div>
                                                                            : null}
                                                                    </div>
                                                                )}
                                                                {/* )} */}
                                                            </div>
                                                            <div className='justify-content-end px-3'>
                                                                <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertMsgEmail(activeAlert, tab)}>+</button>
                                                            </div>
                                                        </div>
                                                    </CardHeader>
                                                    {ruleData.rule[activeAlert].sms.length > 0 ?
                                                        <div className='row m-0 mt-4 pb-4'>
                                                            <div className='col-6'>
                                                                <Input name="smsName" label="Name*" defaultValue={ruleData.rule[activeAlert].sms[activeSms]?.name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Name", status: !smsName }} />
                                                            </div>
                                                            <div className='col-6'>
                                                                <Input name="smsValue" label="Phone No.*" type="number" defaultValue={ruleData.rule[activeAlert].sms[activeSms]?.value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add number", status: !smsValue }} />
                                                            </div>
                                                        </div> : null}
                                                </div>
                                                : null
                                        }
                                        {/* Tab Email */}
                                        {
                                            tab === "email" ?
                                                <div>
                                                    <CardHeader className='p-0'>
                                                        <div className='row m-0 pb-4'  >
                                                            <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }} >
                                                                {ruleData.rule[activeAlert].emails.map((emailData, key) =>
                                                                    <div className={'tabButton d-flex ' + (key === activeEmail ? "active" : "null")} style={{ minWidth: (key === activeEmail) ? "130px" : "100px" }}>
                                                                        <div className='inner-btn text-truncate' onClick={() => ChangeStates(ruleData.rule[activeAlert], key, tab, "onButtonClick")} key={key}>{emailData.name}</div>
                                                                        {ruleData.rule[activeAlert].emails.length > 0 ?
                                                                            <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteMSGEMAIL(key, tab)}><i className='fas fa-trash fa-sm'></i></div>
                                                                            : null}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className='justify-content-end px-3'>
                                                                <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertMsgEmail(activeAlert, tab)}>+</button>
                                                            </div>
                                                        </div>
                                                    </CardHeader>
                                                    {ruleData.rule[activeAlert].emails.length > 0 ?
                                                        <div className='row m-0 mt-4 pb-4'>
                                                            <div className='col-6'>
                                                                <Input name="emailName" label="Name*" defaultValue={ruleData.rule[activeAlert].emails[activeEmail].name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Name", status: !emailName }} />
                                                            </div>
                                                            <div className='col-6'>
                                                                <Input name="emailValue" label="Email.*" defaultValue={ruleData.rule[activeAlert].emails[activeEmail].value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Email", status: !emailValue }} />
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div> : null
                                        }
                                        {/* Tab prereq */}
                                        {tab === "prereq" ? <div>
                                            <CardHeader className='p-0'>
                                                <div className='row m-0 pb-4'  >
                                                    <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                        {ruleData.rule[activeAlert].prerequisites.map((prereqData, key) =>
                                                            <div className={'tabButton d-flex ' + (key === activePrereq ? "active" : "null")} style={{ minWidth: (key === activePrereq) ? "130px" : "100px" }}>
                                                                <div className='inner-btn text-truncate' onClick={() => ChangeStates(ruleData.rule[activeAlert], key, tab, "onButtonClick")} key={key}>{prereqData.name}</div>
                                                                {ruleData.rule[activeAlert].prerequisites.length > 0 ?
                                                                    <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteMSGEMAIL(key, tab)}><i className='fas fa-trash fa-sm'></i></div>
                                                                    : null}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className='justify-content-end px-3'>
                                                        <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertMsgEmail(activeAlert, tab)}>+</button>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            {ruleData.rule[activeAlert].prerequisites.length > 0 ?
                                                <div className='row m-0 mt-4 pb-4'>
                                                    <div className='col-6 mt-3'>
                                                        <Select onClick dropDownHeight="100px" options={levelIdList} defaultValue={{ value: ruleData.rule[activeAlert].prerequisites[activePrereq].levelId }} onChange={(e) => onChangeHandler(e)} name="prereqlevelId" label="LevelId*" />
                                                    </div>
                                                    <div className='col-6 mt-3'>
                                                        <Select onClick dropDownHeight="100px" options={deviceIds} defaultValue={{ value: ruleData.rule[activeAlert].prerequisites[activePrereq].deviceId }} onChange={(e) => onChangeHandler(e)} name="prereqdeviceId" label="Device*" />
                                                    </div>
                                                    <div className='col-6  mt-3'>
                                                        <Select onClick dropDownHeight="100px" options={deviceParamList} defaultValue={{ value: ruleData.rule[activeAlert].prerequisites[activePrereq].parameter }} onChange={(e) => onChangeHandler(e)} name="prereqparameter" label="Parameter.*" />
                                                    </div>
                                                    <div className='col-6  mt-3'>
                                                        <Select name="prereqcondition" onClick dropDownHeight="100px" label="Condition.*" defaultValue={{ value: ruleData.rule[activeAlert].prerequisites[activePrereq].condition }} options={prereqcondition} onChange={(e) => onChangeHandler(e)} />
                                                    </div>
                                                    {prereqValue.length > 0 ?
                                                        <div className='col-6 mt-3'>
                                                            <Select name="prereqvalue" onClick dropDownHeight="100px" label="Value.*" defaultValue={{ value: ruleData.rule[activeAlert].prerequisites[activePrereq].value }} options={prereqValue} onChange={(e) => onChangeHandler(e)} />
                                                        </div> :
                                                        <div className='col-6  mt-3'>
                                                            <Input name="prereqvalue" label="Value.*" defaultValue={ruleData.rule[activeAlert].prerequisites[activePrereq].value} type="number" onChange={(e) => onChangeHandler(e)} />
                                                        </div>}
                                                </div>
                                                : null}
                                        </div> : null}

                                        {/* Tab Action */}
                                        {tab === "action" ?
                                            <div>
                                                <CardHeader className='p-0'>
                                                    <div className='row m-0 pb-4'>
                                                        <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                            {ruleData.rule[activeAlert].action.map((actionData, key) =>
                                                                <div className={'tabButton d-flex ' + (key === activeAction ? "active" : "null")} style={{ minWidth: (key === activeAction) ? "130px" : "100px" }}>
                                                                    <div className='inner-btn text-truncate' onClick={() => ChangeStates(ruleData.rule[activeAlert], key, tab, "onButtonClick")} key={key}>{actionData.name}</div>
                                                                    {/* <div className='inner-btn' >Prereq</div> */}
                                                                    {ruleData.rule[activeAlert].action.length > 0 ?
                                                                        <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteMSGEMAIL(key, tab)}><i className='fas fa-trash fa-sm'></i></div>
                                                                        : null}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className='justify-content-end px-3'>
                                                            {/* <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertEmail(activeAlert)}>+</button> */}
                                                            <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertMsgEmail(activeAlert, tab)}>+</button>
                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                {ruleData.rule[activeAlert].action.length > 0 ?
                                                    <div className='row m-0 mt-4 pb-4'>
                                                        <div className='col-6 mt-3'>
                                                            {/* <Input name="emailName" label="Name*" defaultValue={ruleData.rule[activeAlert].emails[activeEmail].name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Name", status: !emailName }} /> */}
                                                            <Select onClick dropDownHeight="100px" options={levelIdList} defaultValue={{ value: ruleData.rule[activeAlert].action[activeAction].levelId }} onChange={(e) => onChangeHandler(e)}
                                                                name="actionlevelId" label="LevelId*" />
                                                        </div>
                                                        <div className='col-6 mt-3'>
                                                            {/* <Input name="emailName" label="Name*" defaultValue={ruleData.rule[activeAlert].emails[activeEmail].name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Name", status: !emailName }} /> */}
                                                            <Select onClick dropDownHeight="100px" options={actionDeviceId} defaultValue={{ value: ruleData.rule[activeAlert].action[activeAction].deviceId }} onChange={(e) => onChangeHandler(e)} name="actiondeviceId" label="Device*" />
                                                        </div>
                                                        <div className='col-6  mt-3'>
                                                            {/* <Input name="emailValue" label="Email.*" defaultValue={ruleData.rule[activeAlert].emails[activeEmail].value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Valid Emailadd vlidations", status: !emailValue }} /> */}
                                                            <Select onClick dropDownHeight="100px" options={deviceActionParamList} defaultValue={{ value: ruleData.rule[activeAlert].action[activeAction].parameter }} onChange={(e) => onChangeHandler(e)} name="actionparameter" label="Parameter.*" />
                                                        </div>
                                                        <div className='col-6  mt-3'>
                                                            {/* <Input name="emailValue" label="Email.*" defaultValue={ruleData.rule[activeAlert].emails[activeEmail].value} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Add Valid Emailadd vlidations", status: !emailValue }} /> */}
                                                            <Input name="actionvalue" label="Value.*" type="number" defaultValue={ruleData.rule[activeAlert].action[activeAction].value} onChange={(e) => onChangeHandler(e)} />
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div> : null}

                                        {
                                            tab === "apis" ?
                                                <div>
                                                    <CardHeader className='p-0'>
                                                        <div className='row m-0 pb-4'  >
                                                            <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }} >
                                                                {ruleData.rule[activeAlert].apis.map((apisData, key) =>
                                                                    <div className={'tabButton d-flex ' + (key === activeApis ? "active" : "null")} style={{ minWidth: (key === activeApis) ? "130px" : "100px" }}>
                                                                        <div className='inner-btn text-truncate' onClick={() => ChangeStates(ruleData.rule[activeAlert], key, tab, "onButtonClick")} key={key}>{apisData.name}</div>
                                                                        {ruleData.rule[activeAlert].apis.length > 0 ?
                                                                            <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteMSGEMAIL(key, tab)}><i className='fas fa-trash fa-sm'></i></div>
                                                                            : null}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className='justify-content-end px-3'>
                                                                <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertMsgEmail(activeAlert, tab)}>+</button>
                                                            </div>
                                                        </div>
                                                    </CardHeader>
                                                    {ruleData.rule[activeAlert].apis.length > 0 ?
                                                        <div className='row m-0 mt-4 pb-4'>
                                                            {/* <div className='col-12 '>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <Input name="key header" label="key*" defaultValue={ruleData.rule[activeAlert].apis[activeApis].headers} onChange={(e) => onChangeHandler(e)} />
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <Input name="value headers" label="value*" defaultValue={ruleData.rule[activeAlert].apis[activeApis].headers} onChange={(e) => onChangeHandler(e)} />
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className='px-3'>
                                                                <ButtonContainer>
                                                                    <Button buttonResult={() => apiHeaderPopupOpen()}>Add Header</Button>
                                                                </ButtonContainer>
                                                            </div>
                                                            <div className='col-12 mt-3'>
                                                                <Select name="method" onClick dropDownHeight="100px" label="Method*" options={ApiMethod} defaultValue={{ value: ruleData.rule[activeAlert].apis[activeApis].method }} onChange={(e) => onChangeHandler(e)} />
                                                            </div>
                                                            <div className='col-12 mt-3'>
                                                                <Input name="url" label="Url.*" defaultValue={ruleData.rule[activeAlert].apis[activeApis].url} onChange={(e) => onChangeHandler(e)} />
                                                            </div>
                                                            <div className='col-12 mt-3'>
                                                                <Input name="body" label="Body.*" type="textarea" defaultValue={ruleData.rule[activeAlert].apis[activeApis].body} onChange={(e) => onChangeHandler(e)} />
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                                : null}
                                    </CardBody>
                                </InnerCard>
                            </div>
                        </Card>
                    </div>
                </div>
                {/* </Card> */}

            </div>
            {apiHeaderPopup ?
                <Mypopup title={"Add Header"} onClose={() => setApiHeaderPopup(null)}>
                    <div className='d-flex justify-content-end px-3'>
                        <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => AddHeaderInApi()}>+</button>
                    </div>
                    {headerApi.length ? headerApi.map((row, key) =>
                        <div className='row mx-0 mt-2'>
                            <div className='col'>
                                <Input name="key" label="key*" defaultValue={row.key} onChange={(e) => onChangeHandlerApiHandler(key, e)} />
                            </div>
                            <div className='col'>
                                <Input name="value" label="value*" defaultValue={row.value} onChange={(e) => onChangeHandlerApiHandler(key, e)} />
                            </div>
                            <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => DeleteHeaderInApi(key, headerApi)}><i className='fas fa-trash fa-sm'></i></div>
                        </div>
                    ) : null}
                    <div className='mt-3 d-flex justify-content-center'>
                        <ButtonContainer>
                            <Button buttonResult={() => { setApi(); setApiHeaderPopup(false) }}>
                                Set
                            </Button>
                        </ButtonContainer>
                    </div>
                </Mypopup> : null}
            {
                popup ? <Mypopup title={popup[0]} type={popup[2]} onClose={(e) => {
                    setPopup(null);

                }}>
                    <p className="p-3"> {popup[1]}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setPopup(null)
                            if (popup[0] === "Success") {
                                props.history.push("/rule-engine/" + levelId)
                            }
                        }}>Ok</button>
                    </div>
                </Mypopup >
                    : null
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    organization: state.organization,
    configration: state.configration,
    userInfo: state.userInfo


});
export default connect(mapStateToProps, null)(RuleEngine)
