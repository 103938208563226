import React, { useEffect, useLayoutEffect, useState } from 'react'
import Card, { CardBody, Heading, CardHeader, InnerCard } from '../card/card'
import Input from '../input/input'
import AccessValidation from '../accessValidation/accessValidation'
import { Button, ButtonContainer } from '../arraybutton/arraybutton'
import { SpecialCharacters, WhiteSpace } from '../../services/config/regex'
import { getResource, organizationCreated } from '../../services/utilities/events'
import { orgAction } from '../../services/actions/orgAction'
import InlineLoader from '../loader/inlineLoader'
import Mypopup from '../popup/mypopup'
import { connect } from 'react-redux'
import CardLoader from '../loader/cardLoader'

function LevelList(props) {
    let organizationId;
    let orgId;
    let orgName
    if (props.levelId && props.orgId) {
        organizationId = props?.data
        orgId = organizationId?._id
        orgName = orgId?.split("_")[0]
    }
    let Idlength = orgId?.split("_").length
    const [depart, setDepart] = useState({
        locationName: "",
        // noOfLevels: "",
        contactNumber: "",
        contactName: "",
        levelId: "",
        type: "level"
    })
    const [state, setState] = useState({
        loginData: {},
        modal: false,
        redirect: false,
        loginLoader: false,
    })
    const [popup, setPopup] = useState(false)
    const [access, setAccess] = useState(null)
    const [search, setSearch] = useState("")
    const [levelManagement, setLevelManagement] = useState(false)
    const [filterList, setFilterList] = useState([])
    const [levelwithImage, setLevelwithImage] = useState([])
    const [list, setList] = useState([])
    const [departFormPopup, setDepartFormPopup] = useState(false)
    const [departs, setDeparts] = useState([])
    const [departName, setDepartName] = useState(true)
    const [imageLoader, setImageLaoder] = useState(true)
    // const [noOfLevels, setNoOfLevels] = useState(true)
    const [contactNumber, setContactNumber] = useState(true)
    const [contactName, setContactName] = useState(true)
    const [activeDepart, setActiveDepart] = useState(0)

    useEffect(async () => {
        // setImageLaoder(true)
        let res = [...props.list]
        // let image = null
        // await Promise.all(res.map(async (defaultLevel, key) => {
        //     await Promise.all(props?.configration.configration.configuration?.Level.map(async level => {
        //         if (defaultLevel.type === level.type) {
        //             if ("icon" in level) {
        //                 if (level.icon.endsWith(".svg")) {
        //                     image = await getResource(level.icon)
        //                     image = { icon: image, type: 'svg' }
        //                 }
        //                 res[key]["icon"] = image
        //             } else {
        //                 res[key]["icon"] = null
        //             }
        //         }
        //     }))
        // })
        // )
        // // setRes1(res2)
        // setLevelwithImage(res)
        setFilterList(res)
        setImageLaoder(false)
    }, [props])
    const onChangeHandler = (e, index) => {
        let data = depart
        let name = e.name
        data[name] = e.target.value

        if (e.name === "locationName") {
            setDepartName(true)
        }
        // if (e.name === "noOfLevels") {
        //     setNoOfLevels(true)
        // }
        if (e.name === "contactName") {
            setContactName(true)
        }
        if (e.name === "contactNumber") {
            setContactNumber(true)
        }
        if (e.name === "address") {
        }
        setDepart(data)
        let departs1 = departs
        departs1[activeDepart] = data
        setDeparts(departs1)


    }

    // Depart Name validation
    const checkLocationName = () => {
        if (!depart.locationName) {
            setDepartName(false)
            return false
        }

        else {
            setDepartName(true)
            return true
        }
    }
    // NUmber of Room Validation
    // const checkNumberOfRoom = () => {
    //     if (!depart.noOfLevels || depart.noOfLevels < 1) {
    //         setNoOfLevels(false)
    //         return false
    //     }
    //     else {

    //         setNoOfLevels(true)
    //         return (true)
    //     }

    // }
    // Contact Person Name Validation
    const checkContactPersonName = () => {
        if (!depart.contactName) {
            setContactName(false)
            return false
        }
        else {
            setContactName(true)
            return true
        }
    }
    // Contact person Number Validation
    const checkContactPersonNumber = () => {
        if (!depart.contactNumber) {
            setContactNumber(false)
            return false
        }
        else {
            setContactNumber(true)
            return true
        }
    }

    const createDepart = async () => {
        // let filledInput = true
        // let departKey = ""
        // let paramKey = ""
        //check Every depart of data is full feild
        // for (var i = 0; i <= (departs.length - 1); i++) {
        //     if (!departs[i].locationName || !departs[i].noOfLevels || !departs[i].contactName || !departs[i].contactNumber) {
        //         filledInput = false
        //         departKey = i;
        //         break;
        //     }
        // }
        //Check Type if it is true then go to next Conditions when All conditions true Hit API "Create Depart"
        if (checkLocationName()) {
            if (checkContactPersonName()) {
                if (checkContactPersonNumber()) {
                    // let newkey = activeDepart + 1
                    // if (departs.length > activeDepart + 1) {
                    //     ChangeState(departs[newkey], newkey)
                    // }
                    // else {
                    // if (filledInput) {
                    let state1 = { ...state }
                    state1.loginLoader = true;
                    setState(state1)

                    departs.forEach((index) => {
                        index._id = index.locationName.replaceAll(WhiteSpace, "")
                        index._id = index._id.replaceAll(SpecialCharacters, "")
                    })
                    departs[0]["levelId"] = organizationId._id
                    return organizationCreated(departs).then(async res => {
                        let result = res
                        let message = res.message
                        if (result) {
                            if (result.status) {
                                setPopup(["Success!", "Level created successfully.", "success"])
                                state1.loginLoader = false;
                                setState(state1)
                            }
                            else {
                                setPopup(["Failure!", message, "danger"])
                                state1.loginLoader = false;
                            }
                        }
                    })
                }
                // else {
                //     //First depart form Compelete if second depart exist send to second depart  
                //     ChangeState(departs[departKey], departKey)
                // }
                // }
                // }g
            } else {
                setContactNumber(true)
            }
        } else {
            // setNoOfLevels(true)
            setContactName(true)
            setContactNumber(true)
        }
    }
    const removePopup = async () => {
        setPopup(null)
        setDepartFormPopup(null);
        await orgAction(orgName)
        setDepart({
            locationName: "",
            // noOfLevels: "",
            contactNumber: "",
            contactName: "",
            levelId: organizationId._id,
            type: "level"
        })
    }

    useEffect(() => {
        if (access) {
            if (access?.length) {
                if (access.includes("1007") || access.includes("admin")) {
                    setLevelManagement(true)
                }
            } else {
                setLevelManagement(false)
            }
        }
    }, [access])
    const accessHandler = (e) => {
        setAccess(e)
    }


    const onChangeHandler1 = (e) => {
        let filterDevices = []
        let search1 = e.target.value
        setSearch(search1)
        if (props.list.length) {
            filterDevices = levelwithImage.filter(item => {
                return search1 !== "" ? item.name.toLowerCase().includes(search1.toLowerCase()) : item;
            })
        }
        setFilterList(filterDevices)
    }

    const imgReturnFunc = (data, value) => {
        if (data) {
            let img = data
            if (img.type === "svg") {
                // console.log("in", img.icon.toString())
                return "data:image/svg+xml;base64," + btoa(img.icon)
            }
            else {
                return data.icon
            }
        }
    }

    return (
        <>
            <div className='m-3'>
                {props.levelId && props.orgId &&
                    <AccessValidation levelId={organizationId._id} onAccessHandler={(e) => accessHandler(e)} />
                }
                {!props.levelId && !props.orgId ? imageLoader ? <CardLoader /> :
                    <Card className={(props.list.length ? "" : "overflow-hidden ")} >
                        <>
                            {props.levelId && props.orgId ?
                                <CardHeader>
                                    {/* <AccessValidation levelId={props?.levelId} onAccessHandler={(e) => accessHandler(e)} /> */}
                                    <div className='row py-1'>
                                        <div className='col-md col-12 d-flex align-items-center '>
                                            <Heading size={1} className="mr-3" >Levels</Heading>
                                        </div>

                                        <div className='col'>
                                            <div className='d-flex justify-content-end'>
                                                <div className='pt-md-0 pt-3 px-2'>
                                                    <Input name="search" label="Search Level" onChange={(e) => onChangeHandler1(e)} />
                                                </div>
                                                {levelManagement ?
                                                    // Idlength === 1 ?
                                                    // <ButtonContainer>
                                                    //     <Button buttonResult={() => setDepartFormPopup("Hello")}>Add Level</Button>
                                                    // </ButtonContainer>
                                                    // :
                                                    <>
                                                        {/* <ButtonContainer>
                                                        <Button buttonResult={() => setAddHubPopup("Hello")}>Add Hub</Button>
                                                    </ButtonContainer> */}
                                                        <button className='p-3 btn btn-light btn-lg w-100' style={{ color: "#1b75bc" }} onClick={() => setDepartFormPopup("Hello")}><i className='fa fa-plus'></i> Add Level</button>
                                                        {/* <ButtonContainer>
                                                                <Button buttonResult={() => setDepartFormPopup("Hello")}>Add Level</Button>
                                                            </ButtonContainer> */}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                : <CardHeader>
                                    <div className='row m-0 p-2'>
                                        <div className='col-md col-12'>
                                            <Heading size={1} className="mr-3" >Organizations</Heading>
                                        </div>
                                        {/* <div className='pt-md-0 pt-3 px-2'>
                                            <Input name="search" label="Search Organization" onChange={(e) => onChangeHandler1(e)} />
                                        </div> */}
                                    </div>
                                </CardHeader>}
                            <CardBody className="row m-0 p-2">
                                {
                                    filterList.length ? filterList?.map((item, index) =>
                                        <div className="col-12 col-md-6 col-lg-4 p-1" key={index} id={item._id}>
                                            <InnerCard>
                                                <CardBody>
                                                    <div className='p-3 d-flex' onClick={() => props.history.push({ pathname: item.url, data: item.data })}>
                                                        {"icon" in item && item.icon !== null ?
                                                            <img className={"rounded-circle-px p-1  primary-gradient"} style={{ objectFit: 'contain', width: "50px" }} src={imgReturnFunc(item.icon)} />
                                                            :
                                                            // <i className="fa fa-university primary-gradient rounded-circle-px text-white p-3 mr-3" onClick={() => history.push({ pathname: '/hub/' + orgId + '/' + item._id, data: item })}></i>
                                                            <i className="fa fa-university primary-gradient rounded-circle-px text-white p-3 mr-3 float-left"></i>
                                                        }
                                                        {/* <i className="fa fa-university primary-gradient rounded-circle-px text-white p-3 mr-3 float-left"></i> */}
                                                        <div className='col'>
                                                            <div className="h5 mb-0 text-primary mt-0">{item.name}</div>
                                                            <div className="text-muted text-uppercase font-weight-bold font-xs">{item.type}</div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </InnerCard>
                                        </div>
                                    )
                                        : <div className='px-2'>
                                            not found
                                        </div>

                                }
                            </CardBody>
                        </>
                    </Card> : <button className='p-3 btn btn-light btn-lg w-100 rounded-circle-px' style={{ color: "#1b75bc" }} onClick={() => setDepartFormPopup("Hello")}><i className='fa fa-plus'></i> Add Level</button>
                }
            </div>
            {departFormPopup ? <Mypopup title={"Add your level detail"} onClose={() => { setDepartFormPopup(null) }}>
                <div className='row mt-2 px-md-5 px-3'>
                    <div className='col-md-12 col-12  mt-4'>
                        <Input onEnter={() => { createDepart() }} name="locationName" label="Level name*" defaultValue={depart.locationName} message={{ msg: "Please enter your depart name", status: !departName }} onChange={(e) => onChangeHandler(e)} />
                    </div>
                    {/* <div className='col-md-6 col-12  mt-4'>
                        <Input onEnter={() => { createDepart() }} name="noOfLevels" label="Number of InnerLevels*" type="number" defaultValue={depart.noOfLevels} message={{ msg: "Please enter number of Rooms in this depart", status: !noOfLevels }} onChange={(e) => onChangeHandler(e)} />
                    </div> */}

                    <div className='col-md-6 col-12  mt-4'>
                        <Input onEnter={() => { createDepart() }} name="contactName" label="Contact person name*" defaultValue={depart.contactName} message={{ msg: "Please enter contact person name", status: !contactName }} onChange={(e) => onChangeHandler(e)} />
                    </div>
                    <div className='col-md-6 col-12  mt-4'>
                        <Input onEnter={() => { createDepart() }} name="contactNumber" label="Contact person number*" type="number" defaultValue={depart.contactNumber} message={{ msg: "Please enter contact person number", status: !contactNumber }} onChange={(e) => onChangeHandler(e)} />
                    </div>
                    <div className='col-12'>
                        {state.loginLoader ?
                            <div className='d-flex justify-content-center' style={{ height: "80px" }}>
                                <InlineLoader />
                            </div> :
                            <ButtonContainer className="my-3">
                                <Button buttonResult={() => createDepart()} >Save</Button>
                            </ButtonContainer>
                        }
                    </div>
                </div>
            </Mypopup > : null}
            {
                popup ? <Mypopup title={popup[0]} type={popup[2]} onClose={(e) => {
                    setPopup(null);
                }}>
                    <p className="p-3" > {popup[1]}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            removePopup()
                        }}>Ok</button>
                    </div>
                </Mypopup >
                    : null
            }
        </>
    )
}
const mapStateToProps = (state) => ({
    configration: state.configration

});
export default connect(mapStateToProps, null)(LevelList)
