import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

import LevelList from '../../components/levelList/levelList'
import FullLoader from '../../components/loader/fullPageLoader'
import { overviewRequest } from '../../services/utilities/events'
import store from '../../services/utilities/store'
import LevelForm from '../../components/level/levelForm'
import Overview from '../overview/overview'
import Mapview from '../customDashboard/mapview/mapview'
import Header from '../../components/header/header'
import Analyzer from '../../components/componentLIst/analyzer/analyzer'
import Analyzers from '../customDashboard/analyzers'
import Maps from '../customDashboard/QSCP/summary'
import Maps2 from '../customDashboard/Oman/summary'
const Dashboard = (props) => {

  const hash = window.location.hash.split("/")
  const pageName = hash[1]
  let { levelId } = useParams();
  let orgId = levelId?.split("_")[0]
  let lengthLevelId = levelId?.split("_").length

  const [levelsListState, setLevelsListState] = useState([])
  const [loader, setLoader] = useState("true")
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [overviewList, setOverviewList] = useState([])

  useEffect(() => {
    store.dispatch({
      type: "Get Sidebar",
      payload: levelId
    })
  }, [levelId])

  useEffect(async () => {
    let levelsList = null;
    if (!pageName && props.userInfo) {
      setLoader(true)
      if ("varification" in props.userInfo) {
        levelsList = [];
        props.userInfo.varification.hierarchy.map(level => {
          levelsList.push({
            name: level.name,
            id: level._id,
            url: "/organizations/" + level._id,
            type: level.type,
            noOfLevel: level.nooflevels,
            roles: level?.access,
            data: null,
          })
        })
      }
      setLevelsListState(levelsList);
      setLoader(false)
    } else if (pageName) {
      setLoader(true)
      let overview = await overviewRequest(orgId)
      if (overview) {
        setOverviewList(overview)
      }
      levelsList = [];
      let res = null;
      let result = props?.organization?.organization
      if (levelId) {
        if (levelId.match("_")) {
          res = levelIdSpliter(levelId, result)
        } else {
          res = result
        }
      }
      if (res && "levels" in res) {
        let branches = false
        res.levels.map((level, key) => {
          if (props?.userInfo?.varification?.hierarchy.length) {
            props.userInfo.varification?.hierarchy.map((levels, key1) => {
              if (level.levelId.match(levels._id)) {
                if (levels.branches.length > 0) {
                  branches = true
                  levels.branches.map(branch => {
                    let branchSplit = branch.levelId.split("_")
                    let idJoin = ""
                    if (level._id.match(branch.levelId)) {
                      levelsList.push({
                        name: level.locationName,
                        id: level._id,
                        url: "/organizations/" + level._id,
                        type: level.type,
                        data: level,
                      })
                    } else {
                      branchSplit.map((splitId, index) => {
                        if (idJoin) {
                          idJoin += "_"
                        }
                        idJoin += splitId
                        if (level._id.match(idJoin) && lengthLevelId - 1 < index) {
                          let sameLevel = false
                          sameLevel = levelsList.some(a => a.id === idJoin)
                          if (!sameLevel) {
                            levelsList.push({
                              name: level.locationName,
                              id: level._id,
                              url: "/organizations/" + level._id,
                              type: level.type,
                              data: level,
                            })
                          }
                        }
                      })
                    }
                  })
                }
              }
            })
            if (!branches) {
              levelsList.push({
                name: level.locationName,
                id: level._id,
                url: "/organizations/" + level._id,
                type: level.type,
                data: level
              })
            }
          }
          // levelsList.push({
          //   name: level.locationName,
          //   id: level._id,
          //   url: "/organizations/" + level._id,
          //   type: level.type,
          //   data: level
          // })
        })
      }
      if (res && "levels" in res) {
        res.levels.map((level, key) => {
          if (props?.userInfo?.varification?.hierarchy.length) {
            props.userInfo.varification?.hierarchy.map((levels, key1) => {
              levels.branches.map(branch => {
                if (levelsList.length === 1 && branch.levelId.match(level._id)) {
                  props.history.push({ pathname: '/organizations/' + levelsList[0].id, data: props.location.data })
                }
              })
            }
            )
          }
        })
      }
      setSelectedLevel(res)
      setLevelsListState(levelsList);
      setLoader(false)
    }
    if (levelsList.length === 1) {
      if (!pageName) {
        props.history.push({ pathname: '/organizations/' + levelsList[0].id, data: props.location.data })
      }
    }
  }, [props.userInfo, props.location])

  const levelIdSpliter = (levelId, data) => {
    let idIndex = levelId.split("_")
    let concatId = ''
    let filteredData = data
    idIndex.map((idx, key) => {
      concatId += idx
      if (key > 0 && filteredData) {
        filteredData = levelMappingFunc(concatId, filteredData)
      }
      if ((idIndex.length - 1) > key) {
        concatId += "_"
      }
    })
    return filteredData
  }
  const levelMappingFunc = (levelId, data) => {
    let result = data
    data?.levels?.map(row => {
      if (row._id === levelId) {
        result = row
        return
      }
    })
    return result
  }

  let UI = <div className='w-100' style={{ height: 'calc(100vh - 100px)' }}><FullLoader /></div>
  if (!loader) {
    if (levelsListState.length > 0 || orgId) {
      UI =
        <>
          {lengthLevelId > 0 && <Header data={selectedLevel} />}
          {/* {levelId === "QSCP" ?
            <Maps /> : null} */}
          {levelId === "Oman" ?
            <Maps2 /> : null}
          {overviewList.map((item, key) =>
            item?.settings &&
            item?.settings?.levelAssign === levelId &&
            item?.settings?.live &&
            <Overview key={key} id={item._id} />
          )}
          {lengthLevelId > 0 ?
            <LevelForm data={selectedLevel} />
            : null}
          {levelId === "TP" ?
            <Mapview />
            : null}
          <LevelList data={selectedLevel} list={levelsListState} history={props.history} levelId={levelId} orgId={orgId} pageName={pageName} />
        </>
    } else {
      UI = <Redirect to='/welcome' />
    }
  }
  return UI
}
const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  configration: state.configration,
  organization: state.organization
});
export default connect(mapStateToProps, null)(Dashboard)
