import React, { useState, useEffect } from 'react'
import "./alignment.scss"
import { ButtonContainer, Button } from '../../arraybutton/arraybutton'

const Alignment = (props) => {
    const [alignTrue, setAlignTrue] = useState(false)
    const alignment = {
        column: [
            { key: 0, class: "justify-content-start align-items-start " },
            { key: 1, class: "justify-content-start align-items-center " },
            { key: 2, class: "justify-content-start align-items-end " },
            { key: 3, class: "justify-content-center align-items-start " },
            { key: 4, class: "justify-content-center align-items-center " },
            { key: 5, class: "justify-content-center align-items-end " },
            { key: 6, class: "justify-content-end align-items-start " },
            { key: 7, class: "justify-content-end align-items-center " },
            { key: 8, class: "justify-content-end align-items-end " },
            { key: 9, class: "align-self-start " },
            { key: 10, class: "align-self-center " },
            { key: 11, class: "align-self-end " },

        ], row: [
            { key: 0, class: "justify-content-start align-items-start " },
            { key: 1, class: "justify-content-center align-items-start " },
            { key: 2, class: "justify-content-end align-items-start " },
            { key: 3, class: "justify-content-start align-items-center " },
            { key: 4, class: "justify-content-center align-items-center " },
            { key: 5, class: "justify-content-end align-items-center " },
            { key: 6, class: "justify-content-start align-items-end " },
            { key: 7, class: "justify-content-center align-items-end " },
            { key: 8, class: "justify-content-end align-items-end " },
            { key: 9, class: "align-self-start " },
            { key: 10, class: "align-self-center " },
            { key: 11, class: "align-self-end " },

        ]
    }
    const [align, setAlign] = useState(0)
    const [listType, setListType] = useState(0)
    useEffect(() => {
        let align = false
        let className = props.defaultValue
        let isColumn = className?.match("flex-column")
        alignment?.[(isColumn ? "column" : "row")]?.map((item, key) => {
            if (isColumn) {
                setListType(1)
            } else {
                setListType(0)
            }
            if (className?.match(item.class)) {
                align = true
                let key = item.key
                setAlign(key)
            }
        })
        if (!align) {
            setAlign(null)
        }
    }, [props.defaultValue])

    const onChangeHandler = (align1, listType1) => {
        // let pageConfig1 = [...pageConfig]
        setAlign(align1)
        setListType(listType1)
        let className = ""
        if (align1 === 0) {
            className += "justify-content-start align-items-start ";
        }
        else if (align1 === 1) {
            className += (listType1 ? "justify-content-start align-items-center " : "justify-content-center align-items-start ");
        }
        else if (align1 === 2) {
            className += (listType1 ? "justify-content-start align-items-end " : "justify-content-end align-items-start ");
        }
        else if (align1 === 3) {
            className += (listType1 ? "justify-content-center align-items-start " : "justify-content-start align-items-center ");
        }
        else if (align1 === 4) {
            className += "justify-content-center align-items-center ";
        }
        else if (align1 === 5) {
            className += (listType1 ? "justify-content-center align-items-end " : "justify-content-end align-items-center ");
        }
        else if (align1 === 6) {
            className += (listType1 ? "justify-content-end align-items-start " : "justify-content-start align-items-end ");
        }
        else if (align1 === 7) {
            className += (listType1 ? "justify-content-end align-items-center " : "justify-content-center align-items-end ");
        }
        else if (align1 === 8) {
            className += (listType1 ? "justify-content-end align-items-end " : "justify-content-end align-items-end ");
        }
        else if (align1 === 9) {
            className += "align-self-start ";
        }
        else if (align1 === 10) {
            className += "align-self-center ";
        }
        else if (align1 === 11) {
            className += "align-self-end ";
        }
        if (listType1 === 0) {
            className += "flex-row ";
        }
        else if (listType1 === 1) {
            className += "flex-column ";
        }
        if ("onChange" in props) {
            props.onChange(className)
        }
        // pageConfig1[selectiveIndex].style.class = className
        // setPageConfig(pageConfig1)
    }
    return (
        "type" in props ?
            <>
                <div className='propertiesLabels'>Alignment</div>
                {props.type === "alignment" ?
                    <ButtonContainer>
                        <Button buttonResult={() => onChangeHandler(9, listType)} active={align === 9}>Top</Button>
                        <Button buttonResult={() => onChangeHandler(10, listType)} active={align === 10}>Middle</Button>
                        <Button buttonResult={() => onChangeHandler(11, listType)} active={align === 11}>Bottom</Button>
                    </ButtonContainer>
                    : null}
                {props.type === "alignmentXY" ?
                    <div className='d-flex flex-column align-items-center'>
                        <ButtonContainer className="mb-3">
                            <Button buttonResult={() => onChangeHandler(align, 0)} active={listType === 0}><i className="fa fa-bars mr-1"></i> Row</Button>
                            <Button buttonResult={() => onChangeHandler(align, 1)} active={listType === 1}><i className="fa fa-bars mr-1" style={{ transform: "rotate(90deg)" }}></i> Column </Button>
                        </ButtonContainer>
                        <svg viewBox="0 0 125 125" width="40%">
                            <g>
                                <rect className="setAlign0 setAlignStr0" x="8.97" y="8.97" width="104.61" height="104.61" />
                                <rect onClick={() => onChangeHandler(0, listType)} className={"setAlign2 " + (align === 0 ? "active" : "")} x="4.41" y="4.41" width="9.13" height="9.13" />
                                <rect onClick={() => onChangeHandler(1, listType)} className={"setAlign2 " + (align === 1 ? "active" : "")} x="56.71" y="4.41" width="9.13" height="9.13" />
                                <rect onClick={() => onChangeHandler(2, listType)} className={"setAlign2 " + (align === 2 ? "active" : "")} x="109.02" y="4.41" width="9.13" height="9.13" />
                                <rect onClick={() => onChangeHandler(3, listType)} className={"setAlign2 " + (align === 3 ? "active" : "")} x="4.41" y="56.71" width="9.13" height="9.13" />
                                <rect onClick={() => onChangeHandler(4, listType)} className={"setAlign2 " + (align === 4 ? "active" : "")} x="56.71" y="56.71" width="9.13" height="9.13" />
                                <rect onClick={() => onChangeHandler(5, listType)} className={"setAlign2 " + (align === 5 ? "active" : "")} x="109.02" y="56.71" width="9.13" height="9.13" />
                                <rect onClick={() => onChangeHandler(6, listType)} className={"setAlign2 " + (align === 6 ? "active" : "")} x="4.41" y="109.02" width="9.13" height="9.13" />
                                <rect onClick={() => onChangeHandler(7, listType)} className={"setAlign2 " + (align === 7 ? "active" : "")} x="56.71" y="109.02" width="9.13" height="9.13" />
                                <rect onClick={() => onChangeHandler(8, listType)} className={"setAlign2 " + (align === 8 ? "active" : "")} x="109.02" y="109.02" width="9.13" height="9.13" />
                            </g>
                        </svg>

                    </div>
                    : null}
            </>
            : null
    )
}

export default Alignment