import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { overviewUpdate, overviewRequestById, overviewAdd, postImage, getImage } from '../../services/utilities/events';
import './overview.scss';
import Card, { CardHeader, CardBody, Heading } from '../../components/card/card'
import DropContainer from '../../components/drag&drop/dropContainer';
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton'
import ComponentBar from '../../components/componentBar/componentBar';
import GetThumbnail from '../../components/getThumbnail/getThumbnail';
import InlineLoader from '../../components/loader/inlineLoader';
import Flat from '../../components/overviewMode/Flat';
import FreeHand from '../../components/overviewMode/FreeHand';
import DefaultFooter from '../default-layout/defaultFooter'
import { idGenerator } from '../../functions/functions';
import AccessValidation from '../../components/accessValidation/accessValidation';


// Components 
import Container from '../../components/componentLIst/container/container';
import DynamicLabel from '../../components/componentLIst/dynamicLabel/dynamicLabel';
import CustomImage from '../../components/componentLIst/CustomImage/CustomImage';
import PackMachine from '../../components/componentLIst/packMachine/packMachine';
import Motor from '../../components/componentLIst/motor/motor';
import Tank from '../../components/componentLIst/tank/tank';
import Analyzer from '../../components/componentLIst/analyzer/analyzer';
import Guage from '../../components/componentLIst/guage/guage';
import threePhaseGuage from '../../components/componentLIst/guage/threePhaseGuage';
import { PipeI, PipeL, PipeT } from '../../components/componentLIst/pipeline/pipeline';
import { WireStraight, WireCurve } from '../../components/componentLIst/wire/wire';
import LinearProgress from '../../components/componentLIst/lineProgress/lineProgress';
import RadialProgress from '../../components/componentLIst/Radial/RadialProgress';
import AqiScale from '../../components/componentLIst/aqiScale/aqiScale';
import Generator from '../../components/componentLIst/generator/generator';
import lineGraphOverview from '../../components/componentLIst/graphOverview/lineGraphOverview';
import barGraphOverview from '../../components/componentLIst/graphOverview/barGraphOverview';
import stepGraphOverview from '../../components/componentLIst/graphOverview/stepGraphOverview';
import MachineATM from '../../components/componentLIst/machineATM/machineATM';
import DynamicTable from '../../components/componentLIst/DynamicTable/DynamicTable';
import Map from '../../components/componentLIst/map/map';
import defaultHeader from '../default-layout/defaultHeader';
import WindDirection from '../../components/componentLIst/windDirection/windDirection';
import { Circle, Sqaure } from '../../components/componentLIst/shapes/shapes';
import minilineGraph from '../../components/componentLIst/graphOverview/minilineGraph';
import signal from '../../components/componentLIst/signal/signal';
import connectionIdentifier from '../../components/componentLIst/connectionIdentifier/connectionIdentifier';


//Component Static List
const ComponentList = [
    // { component: Container, groupBy: "Basics", elementType: true, name: 'Container', type: "Container", pair: true, styleTag: ["width", "height", "gap", "alignmentXY"], configTag: [] },
    { component: DynamicLabel, groupBy: "Basics", elementType: true, name: "Dynamic Label", type: "DynamicLabel", pair: false, styleTag: ["fontSize", "width", "gap", "alignmentXY"], configTag: ["label", "pre-suffix", "dateFormat", "deviceConnection", "customColor"] },
    { component: DynamicTable, groupBy: "Basics", elementType: true, name: "Dynamic Table", type: "DynamicTable", pair: false, styleTag: ["fontSize", "width", "height", "gap", "alignmentXY"], configTag: ["label", "deviceConnectionWithTable"] },
    { component: Map, groupBy: "Basics", elementType: true, name: "Map View", type: "MapView", pair: false, styleTag: ["width", "height"], configTag: ["label", "deviceConnectionWithMap", "dataMode", "mapSetting", "filter"] },
    { component: CustomImage, groupBy: "Basics", elementType: true, name: "Image", type: "CustomImage", pair: false, styleTag: ["width", "height"], configTag: ["imageUploader"] },
    { component: PipeI, groupBy: "Shapes", elementType: true, name: "Pipe I", type: "PipeI", pair: false, styleTag: ["width", "height", "thickness", "rotate-step"], configTag: ["customColor"], },
    { component: PipeL, groupBy: "Shapes", elementType: true, name: "Pipe L", type: "PipeL", pair: false, styleTag: ["width", "height", "thickness", "rotate-step"], configTag: ["customColor"], },
    { component: PipeT, groupBy: "Shapes", elementType: true, name: "Pipe T", type: "PipeT", pair: false, styleTag: ["width", "height", "thickness", "rotate-step"], configTag: ["customColor"], },
    { component: WireStraight, groupBy: "Shapes", elementType: true, name: "Wire Straight", type: "WireStraight", pair: false, styleTag: ["width", "height", "thickness", "rotate-step"], configTag: ["customColor"], },
    { component: WireCurve, groupBy: "Shapes", elementType: true, name: "Wire Curve", type: "WireCurve", pair: false, styleTag: ["width", "height", "thickness", "rotate-step"], configTag: ["customColor"], },
    { component: Sqaure, groupBy: "Shapes", elementType: true, name: "Square", type: "Square", pair: false, styleTag: ["width", "height", "borderStyle"], configTag: ["customColor"], },
    { component: Circle, groupBy: "Shapes", elementType: true, name: "Circle", type: "Circle", pair: false, styleTag: ["width", "height", "borderStyle"], configTag: ["customColor"], },
    // { component: WireT,             groupBy: "Connectivity",    elementType: true,  name: "Wire T",             type: "WireT",          pair: false, styleTag: ["width","height","thickness","rotate-step"],    configTag: ["customColor"], },
    { component: PackMachine, groupBy: "Machines", elementType: true, name: 'Packaging Machine', type: "PackMachine", pair: false, styleTag: ["size", "alignment"], configTag: ["deviceConnection"] },
    { component: Motor, groupBy: "Machines", elementType: true, name: "Motor", type: "motor", pair: false, styleTag: ["size"], configTag: ["deviceConnection"] },
    { component: MachineATM, groupBy: "Machines", elementType: true, name: "ATM", type: "MachineATM", pair: false, styleTag: ["size"], configTag: ["deviceConnection"] },
    { component: Generator, groupBy: "Machines", elementType: true, name: "Generator", type: "Generator", pair: false, styleTag: ["size"], configTag: [], },
    { component: Tank, groupBy: "Devices", elementType: true, name: "Tank", type: "Tank", pair: false, styleTag: ["width", "height",], configTag: ["customColor", "deviceConnection"], },
    { component: signal, groupBy: "Devices", elementType: true, name: "Signal", type: "Signal", pair: false, styleTag: ["width", "height",], configTag: ["deviceConnection", "strengthLevel"], },
    { component: connectionIdentifier, groupBy: "Devices", elementType: true, name: "Connection Identifier", type: "ConnectionIdentifier", pair: false, styleTag: ["width", "height",], configTag: ["deviceConnection"], },
    { component: Analyzer, groupBy: "Devices", elementType: true, name: "EMpro Analyzer", type: "EMproAnalyzer", pair: false, styleTag: ["size"], configTag: [], },
    { component: Guage, groupBy: "Devices", elementType: false, name: "Guage", type: "Guage", pair: false, styleTag: ['size'], configTag: ["label", "deviceConnection", "min-max", "lowest", "highest"], },
    { component: threePhaseGuage, groupBy: "Devices", elementType: false, name: "Three Phase Guage", type: "threePhaseGuage", pair: false, styleTag: ['size'], configTag: ["label", "deviceConnection&Accumulative&Phase-1&Phase-2&Phase-3", "min-max", "lowest", "highest"], },
    { component: WindDirection, groupBy: "Devices", elementType: false, name: "Wind Direction", type: "WindDirection", pair: false, styleTag: ['size'], configTag: ["label", "deviceConnection"], },
    { component: lineGraphOverview, groupBy: "Graphs", elementType: false, name: "Line Graph", type: "LineGraph", pair: false, styleTag: ["width"], configTag: ["label", "deviceConnection"], },
    { component: minilineGraph, groupBy: "Graphs", elementType: false, name: "Mini Line Graph", type: "MiniLineGraph", pair: false, styleTag: ["width", "height"], configTag: ["label", "customColor", "deviceConnection", "range"], },
    { component: barGraphOverview, groupBy: "Graphs", elementType: false, name: "Bar Graph", type: "BarGraph", pair: false, styleTag: ["width"], configTag: ["label", "deviceConnection"], },
    { component: stepGraphOverview, groupBy: "Graphs", elementType: false, name: "Step Graph", type: "StepGraph", pair: false, styleTag: ["width"], configTag: ["label", "deviceConnection"], },
    { component: LinearProgress, groupBy: "Graphs", elementType: false, name: "Linear Progress", type: "LinearProgress", pair: false, styleTag: ["width", "alignment"], configTag: ["label", "deviceConnection", "color", "customColor"], },
    { component: RadialProgress, groupBy: "Graphs", elementType: false, name: 'Radial Progress', type: "RadialProgress", pair: false, styleTag: ["size", "alignment"], configTag: ["label", "deviceConnection", "customColor"], },
    { component: AqiScale, groupBy: "Graphs", elementType: true, name: "AQI Scale", type: "AQIScale", pair: false, styleTag: ["width"], configTag: ["deviceConnection"], },
]

function Overview(props) {
    const { organization } = useParams()
    const history = useHistory()
    const location = useLocation()
    const query = new URLSearchParams(location?.search)
    const overviewId = props?.id ? props.id : query?.get("id")


    const [loader, setLoader] = useState(false)
    const [logoLoader, setLogoLoader] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [viewMode, setviewMode] = useState(0)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [editOverview, setEditOverview] = useState(null)
    const [selectiveIndex, setSelectiveIndex] = useState(null)
    const [selectiveId, setSelectiveId] = useState(null)
    const [selectiveComponentIndex, setSelectiveComponentIndex] = useState(null)
    const [pageConfig, setPageConfig] = useState([])
    const [tabSelection, setTabSelection] = useState(1)
    const [overviewName, setOverviewName] = useState("")
    const [settings, setSettings] = useState(null)
    const [imgFile, setImgFile] = useState(null)
    const [imgURL, setImgURL] = useState(null)
    const [dashboardDesign, setDashboardDesign] = useState(false)
    const [access, setAccess] = useState(null)
    const [propertiesBar, setPropertiesBar] = useState(0)
    const [fullScreen, setFullScreen] = useState(false)
    const [barIsShow, setBarIsShow] = useState(true)
    const [state, setState] = useState({
        length: 1,
    })
    //OverView
    useEffect(async () => {
        await overviewLoad();
    }, [])

    useEffect(() => {
        if (access) {
            if (access?.length) {
                if (access.includes("1001") || access.includes("admin")) {
                    setDashboardDesign(true)
                }
            } else {
                setDashboardDesign(false)
            }
        }
    }, [access])
    const accessHandler = (e) => {
        setAccess(e)
    }
    // useEffect(async () => {
    //     const keyPressHandler = (event) => {
    //         if (event.ctrlKey && event.key === "c") {
    //             if(selectiveIndex !== null){
    //             }
    //         } else if (event.ctrlKey && event.key === "v") {
    //           // handle paste action here
    //         }
    //     };
    //     window.removeEventListener("keydown", keyPressHandler)
    //     window.addEventListener("keydown", keyPressHandler);
    //     // if(!document.hasEventListener("keydown", keyPressHandler)){
    //     // }else{
    //     // }

    //       return () => {
    //         window.removeEventListener("keydown", keyPressHandler);
    //       };
    // }, [selectiveIndex])

    //Old Overview
    const overviewLoad = async () => {
        setLoader(true)
        if (overviewId) {
            let overviewById = await overviewRequestById(overviewId)
            if (overviewById) {
                let viewMode1 = 0
                if (overviewById?.settings?.viewMode) {
                    viewMode1 = overviewById.settings.viewMode
                }
                setviewMode(viewMode1)
                setEditOverview(overviewById)
                setPageConfig(overviewById?.data)
                let name = overviewById?.name
                setOverviewName(name)
                setSettings(overviewById?.settings)
                if (overviewById?.settings?.imageId) {
                    let imgid = overviewById?.settings?.imageId
                    setImgURL(imgid)
                    let img = await getImage(imgid)
                    setImgFile(img)
                }
            }
        } else {
            setEditMode(true)
            setOverviewName("New Dashboard")
        }
        setLoader(false)
    }

    //Add Components
    const addComponent = (e, target, component = true) => {
        let pageConfig1 = [...pageConfig]
        let data = e
        let container = false
        if (component) {
            let classFilter = target.className;
            if (classFilter?.constructor === String) {
                if (classFilter.match(" ")) {
                    classFilter = classFilter.split(" ")
                    classFilter = classFilter[0]
                }
            }
            if (target.tagName === "DIV" && classFilter === "component-area" && data.type !== "Container") {
                container = true;
            }
        }
        data.config["index"] = ""
        if (container) {
            data.config.index = target.parentNode.parentNode.id + "_" + idGenerator()
        } else {
            data.config.index = "" + idGenerator()
        }
        pageConfig1.push(data)
        setSelectiveIndex(null)
        setSelectiveId(null)
        setPageConfig(pageConfig1)
    }
    //delete Component Function 
    const deleteComponent = (index) => {
        let pageConfig1 = [...pageConfig]
        let getId = pageConfig1[index].config?.index
        if (getId) {
            if (!getId.match("_")) {
                pageConfig1.map((comp, ind) => {
                    let getId2 = comp?.config?.index
                    if (getId2) {
                        if (getId2.match("_")) {
                            getId2 = getId2.split("_")[0]
                            if (getId === getId2) {
                                pageConfig1.splice(ind, 1)
                            }
                        }
                    }
                })
            }
        }
        pageConfig1.splice(index, 1)
        setPageConfig(pageConfig1)
        if (selectiveIndex === index) {
            setSelectiveIndex(null)
            setSelectiveId(null)
            setTabSelection(1)
        }
    }
    // select compomemt
    const selectComponents = (idx1, idx2) => {
        selectiveIndexFunc(idx1, idx2);
        setTabSelection(3)
    }
    // set Component Index and Selective Index
    const selectiveIndexFunc = (index, index2) => {
        setSelectiveComponentIndex(index2)
        setSelectiveIndex(index)
    }


    //All onChange store and set Functoionality
    const onChangeHandler = (e, type) => {
        if (type === "component") {
            let pageConfig1 = [...pageConfig]
            pageConfig1[selectiveIndex] = e
            setPageConfig(pageConfig1)
        }
        if (type === "settings") {
            setSettings(e)
        }
        else if (type === "isSelected") {
            setSelectiveIndex(null)
            setSelectiveId(null)
        }
        else if (type === "tab") {
            setTabSelection(e)
        }
        else if (type === "style") {
            let pageConfig1 = [...pageConfig]
            let componentData1 = { ...pageConfig1[selectiveIndex] }
            let unit = ""
            if (
                e.name === "width" ||
                e.name === "height" ||
                e.name === "marginLeft" ||
                e.name === "marginRight" ||
                e.name === "marginTop" ||
                e.name === "marginBottom" ||
                e.name === "top" ||
                e.name === "left") {
                unit = "%"
            }
            // else if (e.name === "height") {
            //     unit = "px"
            // }
            if (e.name === "height" && e.target.value === "") {
                let css = { ...componentData1.style.css }
                if (e.name in css) {
                    delete css[e.name]
                    componentData1.style.css = css
                }
            } else {
                componentData1.style.css = {
                    ...componentData1.style.css, [e.name]: e.target.value + unit
                };
            }
            pageConfig1[selectiveIndex] = componentData1
            setPageConfig(pageConfig1)
        }
        else if (type === "overviewName") {
            setOverviewName(e.target.value)
        }
        else if (type === "setIndex") {
            if (e.index >= 0 && e.index2 >= 0) {
                selectiveIndexFunc(e.index, e.index2)
            }
        }
        else if (type === "arrayUpdate") {
            setPageConfig(e)
        }
    }

    //Add and edit overView Function
    const saveOverview = async () => {
        setButtonLoader(true)
        setEditMode(false)
        setTimeout(async () => {
            let settings1 = { ...settings }
            settings1['viewMode'] = viewMode
            if (imgURL) {
                settings1['imageId'] = imgURL
            }
            let thumbnail = null
            if (viewMode === 0) {
                thumbnail = await GetThumbnail(document.getElementById("sketchBoard"), organization)
            }
            if (editOverview) {
                let editOverview1 = { ...editOverview }
                editOverview1.data = pageConfig
                editOverview1.name = overviewName
                editOverview1['thumbnail'] = thumbnail
                editOverview1['settings'] = settings1
                let response = await overviewUpdate(editOverview1._id, editOverview1)
                setEditOverview(editOverview1)
            } else {
                let newOverview = {
                    data: pageConfig,
                    levelId: organization,
                    name: overviewName,
                    thumbnail,
                    settings: settings1,
                }

                let response = await overviewAdd(newOverview)
                setEditOverview(response)
            }
            setButtonLoader(false)
        }, 2000)
    }
    useEffect(() => {
        document.addEventListener('fullscreenchange', onFullScreenChange);
        let state1 = state;
        if ("length" in props) {
            state1.length = state.length
        }
        setState(state1)
        return () => {
            document.removeEventListener("fullscreenchange", onFullScreenChange);
        }
    }, [])

    const onFullScreenChange = () => {
        if (document.fullscreenElement) {
            setFullScreen(true)
        }
        else {
            setFullScreen(false)
        }
    }

    const fullScreens = () => {
        var elem = document.getElementById("graphicalDash");
        if (!fullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }

    const checkImageProperties = async (file) => {
        setLogoLoader(true)
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const image = new Image();
                image.src = event.target.result;
                image.onload = async () => {
                    const width = image.width;
                    const height = image.height;
                    const size = file.size;
                    const fileType = file.type;
                    // check if the image meets your criteria for size, width, height, and type
                    if (size > 1024 * 1024) { // max size 1MB
                        setLogoLoader(false)
                        reject("File size too large. Please select a smaller image.");
                        // } else if (width !== 200 || height !== 100) { // required dimensions 300x100
                        //   reject("Invalid image dimensions. Please select an image with dimensions of 300x100 pixels.");
                    } else if (!fileType.startsWith("image/")) { // check if the file type is an image
                        setLogoLoader(false)
                        reject("Invalid file type. Please select an image file.");
                    } else {
                        let res = await postImage(file)
                        setImgURL(res?.filename)
                        setLogoLoader(false)
                        resolve();
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    }
    return (

        <>
            <AccessValidation levelId={props?.settings?.levelAssign} onAccessHandler={(e) => accessHandler(e)} />
            <div className={'d-flex align-items-stretch pt-2 w-100 overflow-hidden position-relative'+(fullScreen? ' bg-light' : '')} id={"graphicalDash"} style={{ maxWidth: '100%', height: 'calc(100vh - 100px)' }}>
                {/* <div className={(editMode ? 'col-xl-10 col-lg-9 col-md-8' : 'col-12') + ' mt-2 h-100' + (fullScreen ? " pb-3" : "")} style={{ transition: "all 0.5s" }}> */}
                <div className={('col-12') + ' h-100 px-2' + (fullScreen ? " pb-3" : "")} style={{ transition: "all 0.5s" }}>
                    <Card className="d-flex flex-column m-0" style={{height: fullScreen? '100%' : 'calc(100% - 15px)'}}>
                        <CardHeader>
                            <div className='d-flex justify-content-between' style={{ gap: "10px" }}>
                                {editMode ?
                                    <div className='d-flex'>
                                        {!logoLoader ?
                                            <label className='overviewHeading mr-2 mb-0 pr-3' style={{ cursor: 'pointer' }} title="Upload Logo">
                                                <i className='fa fa-upload'></i>
                                                {imgFile ?
                                                    <img height="30px" src={imgFile ? imgFile : null} alt='logo' />
                                                    : "Logo"}
                                                <input type='file' accept="image/*" hidden onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    if (file) {
                                                        checkImageProperties(file)
                                                            .then(() => {
                                                                setImgFile(URL.createObjectURL(file))
                                                            })
                                                            .catch((error) => {
                                                                alert(error);
                                                            });
                                                    }
                                                }
                                                } />
                                            </label>
                                            : <InlineLoader width={"41px"} />}
                                        <label className='overviewHeading mr-2 mb-0'>
                                            <i className='fa fa-pen'></i>
                                            <input defaultValue={overviewName} type="text" onChange={(e) => onChangeHandler(e, "overviewName")} />
                                        </label>
                                        {/* <ButtonContainer color="success">
                                            <Button buttonResult={() => console.log("duplicate overview")}>Duplicate Overview</Button>
                                        </ButtonContainer> */}

                                    </div>
                                    :
                                    <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                        {imgFile &&
                                            <img height="40px" src={imgFile ? imgFile : null} alt='logo' />
                                        }
                                        <Heading> {overviewName} </Heading>
                                    </div>
                                }

                                <div className='d-flex align-items-end' style={{ gap: "10px" }}>
                                    {loader ? null :
                                        buttonLoader ? <InlineLoader width={"41px"} /> :
                                            editMode ?
                                                <>
                                                    {/* Mode: 
                                                    <ButtonContainer>
                                                        <Button buttonResult={() => {setviewMode(0);setTabSelection(1);}} active={viewMode === 0? true: false}>Free Hand</Button>
                                                        <Button buttonResult={() => {setviewMode(1);setTabSelection(1);}} active={viewMode === 1? true: false}>Map</Button>
                                                    </ButtonContainer> */}
                                                    {overviewId ?
                                                        <ButtonContainer color="light">
                                                            <Button buttonResult={async () => { setEditMode(false); setSelectiveIndex(null); await overviewLoad(); setTabSelection(1); }}>Cancel</Button>
                                                        </ButtonContainer>
                                                        :
                                                        <ButtonContainer color="light">
                                                            <Button buttonResult={() => { history.goBack(); setTabSelection(1); }}>Back</Button>
                                                        </ButtonContainer>
                                                    }
                                                    <ButtonContainer>
                                                        <Button buttonResult={() => !logoLoader && saveOverview()}>Save</Button>
                                                    </ButtonContainer>


                                                </>
                                                :
                                                props?.id ?
                                                    dashboardDesign ?
                                                        <ButtonContainer>
                                                            <Button buttonResult={() => history.push('/manage-dashboard/edit/' + organization + `?id=` + overviewId)}>Edit</Button>
                                                        </ButtonContainer>
                                                        : null
                                                    :
                                                    <>
                                                        <ButtonContainer color="light">
                                                            <Button buttonResult={() => history.goBack()}>Back</Button>
                                                        </ButtonContainer>
                                                        <ButtonContainer>
                                                            <Button buttonResult={() => setEditMode(true)}>Edit</Button>
                                                        </ButtonContainer>
                                                    </>
                                    }
                                    <div className='justify-content-end'>
                                        <button className='btn btn-light rounded-circle-px ' onClick={() => fullScreens()}>
                                            {fullScreen ? <i className="fas fa-compress"></i> :
                                                <i className="fas fa-expand"></i>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody
                            loader={loader}
                            className='p-2'
                            style={{height: fullScreen ? 'calc(100% - 95px)' :'calc(100% - 68px)'}}
                            loaderHeight="100%"
                        >
                            <div style={{ height: '100%' }}>
                                {/* <Flat
                                pageConfig={pageConfig}
                                editMode={editMode}
                                ComponentList={ComponentList}
                                onChange={(e, type)=> onChangeHandler(e, type)}
                                /> */}
                                {
                                    viewMode === 0 ?
                                        <FreeHand
                                            pageConfig={pageConfig}
                                            editMode={editMode}
                                            ComponentList={ComponentList}
                                            selectiveIndex={selectiveIndex}
                                            onChange={onChangeHandler}
                                            addComponent={addComponent}
                                            deleteComponent={deleteComponent}
                                            selectComponents={selectComponents}
                                        />
                                        : viewMode === 1 &&
                                        null
                                }
                            </div>
                            {fullScreen ?
                                <div>
                                    <DefaultFooter />
                                </div>
                                : null}
                        </CardBody>
                    </Card>

                </div>
                {editMode ?
                    <div className="position-absolute py-2" style={{ width: (barIsShow?"300px":"0px"), transition: 'all 0.1s', right: '0', top: '70px', height: 'calc(100% - 85px)', zIndex: 1, }}>
                    {/* <div className="col mt-2 pl-0" style={{ width: "350px", right: '0' }}> */}
                    <ComponentBar
                        fullscreen={fullScreen}
                        viewMode={viewMode}
                        settings={settings}
                        components={ComponentList}
                        componentSelect={selectiveComponentIndex}
                        componentData={pageConfig[selectiveIndex]}
                        tabSelection={tabSelection}
                        barIsShow={barIsShow}
                        onBarShow={()=>setBarIsShow(!barIsShow)}
                        onChange={(e, type) => onChangeHandler(e, type)}
                    />
                    </div>
                    : null}
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo,
});
export default connect(mapStateToProps, null)(Overview)
