import React, { useEffect, useState } from 'react'
import Select from '../../select/select'
import { connect } from 'react-redux';
import { Button, ButtonContainer } from '../../arraybutton/arraybutton';
import Input from '../../input/input';
import { getLevelIds, get_Devices_Selected_HubIds_WithoutMatchType } from '../../../functions/functions';
import Card, { InnerCard } from '../../card/card';


const DeviceConnection = (props) => {
    const [data, setData] = useState({})
    const [deviceIdList, setDeviceIdList] = useState([])
    const [paramList, setParamerterList] = useState([])
    const [selectedHubIds, setSelectedHubIds] = useState([])
    const [hubList, setHubList] = useState([])
    const [levelIds, setLevelIds] = useState([])
    const [hubIds, setHubIds] = useState([])
    const [getNum, setNum] = useState([])
    const [mapSelectedHubId, setMapSelectedHubId] = useState([])
    const [markerdeviceList, setMarkerdeviceList] = useState([])
    const [selectedDeviceIds, setSelectedDeviceIds] = useState([])
    const [mapHubIds, setMapHubIds] = useState(null)

    useEffect(() => {
        let levelIds1 = getLevelIds(props.organization.organization, props.userInfo.varification?.hierarchy)
        setLevelIds(levelIds1)
    }, [])


    useEffect(() => {
        let deviceIdList1 = []
        let allDeviceSelected = []
        if (Array.isArray(data?.hubId) && data?.hubId?.length > 0) {
            let deviceIdList = get_Devices_Selected_HubIds_WithoutMatchType(props.organization.organization, mapSelectedHubId)
            deviceIdList.map(deviceId => {
                // if (deviceId.label !== "GPS") {
                deviceIdList1.push({ label: deviceId.label, value: deviceId.value })
                allDeviceSelected.push(deviceId.value)
                // }
            })
            if (deviceIdList.length > 0) {
                deviceIdList1.push({ label: "All", value: "all" })
            }
            // if (data?.deviceId?.length < 1) {
            let deviceIds = allDeviceSelected
            deviceIds.push("all")
            data.deviceId = deviceIds
            props.onChange({ name: 'deviceId', target: { value: deviceIds } })
            setSelectedDeviceIds(deviceIds)
            // }
            setMarkerdeviceList(deviceIdList1)
        }
    }, [data.hubId, mapSelectedHubId])

    useEffect(() => {
        if (Array.isArray(data?.hubId) && data?.hubId?.length > 0) {
            let mapDeviceIdList = []
            data.hubId.map(hub => {
                mapDeviceIdList.push(hub.hubId)
            })
            setMapSelectedHubId(mapDeviceIdList)
        }
    }, [data.hubId, mapHubIds])

    useEffect(() => {
        props.configTag.map(e => {
            if (e.match("deviceConnection")) {
                if (e.match("&")) {
                    let deviceName = e.split("&")
                    let getnm = []
                    deviceName.map((name, key) => {
                        if (key) {
                            let createName = name.replace("-", " ")
                            getnm.push(createName)
                        }
                    })
                    setNum(getnm)
                } else {
                    setNum(['deviceId'])
                }
            } else if (e.match("deviceConnectionWithMap")) {
            }
            return
        })
    }, [props.configTag])


    useEffect(() => {
        if (props?.defaultValue) {
            setData(props.defaultValue)
        }
    }, [props.defaultValue])

    // useEffect(() => {
    //     props?.organization?.organization?.alldevices.map()
    // }, [data["hubId"][index]["hubId"]])

    useEffect(() => {
        let deviceId = []
        let hubId = []
        let deviceParam = []
        let deviceConfig = []
        if (data.levelId) {
            props?.organization?.organization?.allhubs.map(hub => {
                if (data.levelId === hub.levelId) {
                    hubId.push({ label: hub.name, value: hub._id })
                }
            })
            setHubIds(hubId)
            setDeviceIdList([])
            setParamerterList([])
        } else {
            setHubIds([])
            setDeviceIdList([])
            setParamerterList([])
        }
        if (data.hubId) {
            props?.organization?.organization?.alldevices.map(device => {
                // if (data.hubId === device.hubId) {
                deviceId.push({ label: device.name, value: device._id, filter: device.hubId, parameter: device.parameter })
                if (data.deviceId === device._id) {
                    let deviceType = device.type
                    props?.configration?.configration?.configuration?.Device.map(config => {
                        if (deviceType === config.type) {
                            deviceConfig = config
                        }
                    })
                }
                // }
            })
            setDeviceIdList(deviceId)
            setParamerterList([])
        } else {
            setDeviceIdList([])
            setParamerterList([])
        }
        let filterId = []
        if (data) {
            filterId = Object.keys(data).filter(e => e.match('deviceId'))
        }
        if (filterId.length) {
            deviceId.map((deviceId) => {
                if (data[filterId[filterId.length - 1]] === deviceId.value) {
                    deviceId.parameter.map(param => {
                        let data = { label: param.name ? param.name : param.types, value: param.value }
                        if ("unit" in param) {
                            data['unit'] = param.unit
                        }
                        deviceParam.push(data)
                    })
                    deviceParam.push({ label: "Last Update", value: "created" })
                    setParamerterList(deviceParam)
                }
            })
        }
    }, [data])


    const removeSelectedDeviceId = (key) => {
        let deviceIds1 = [...selectedDeviceIds]
        deviceIds1.splice(key, 1)
        setSelectedDeviceIds(deviceIds1)
        data["deviceId"] = deviceIds1
        onChangeHandler({ name: "deviceId", type: "mapDeviceIds", target: { value: deviceIds1, remove: true } })
    }
    const onChangeHandler = (e, type, index = null) => {
        let data1 = { ...data }
        // if (type === "mapHubId") {
        //     let hubId = []
        //     hubId.push(e.target.value)
        //     setSelectedHubIds(hubId)
        // }

        if (
            type !== "addParam"
            && type !== "deleteParam"
            && type !== "params"
            && type !== "addHub"
            && type !== "mapHubId"
            && type !== "deleteMarker"
            && type !== "deviceSelection"
            && type !== "coordinates"
            && type !== "lat"
            && type !== "lng"
        ) {
            data1[type] = e.target.value
        }
        if ("onChange" in props) {
            if (type === "levelId") {
                data1['hubId'] = []
                props.onChange({ name: 'config', target: { value: data1 } })
            } else if (type === "param") {
                paramList.map(row => {
                    if (row.value === data1[type]) {
                        let sendData = { name: type, target: { value: row.value } }
                        if ("unit" in row) {
                            sendData['unit'] = row.unit
                        }
                        props.onChange(sendData)
                    }
                })
            } else if (type === "addParam") {
                let sendData = []
                if ('params' in data1) {
                    sendData = [...data1['params']]
                }
                sendData.push({ value: null, name: '' })
                data1['params'] = sendData
                props.onChange({ name: 'params', target: { value: sendData } })
            } else if (type === "deleteParam") {
                let sendData = [...data1['params']]
                sendData.splice(index, 1)
                data1['params'] = sendData
                props.onChange({ name: 'params', target: { value: sendData } })
            } else if (type === "params") {
                let sendData = [...data1['params']]
                sendData[index][e.name] = e.target.value
                if (e.name === 'name') {
                    paramList.map(row => {
                        if (row.value === e.target.value) {
                            if ("unit" in row) {
                                sendData[index]['unit'] = row.unit
                            }
                            props.onChange(sendData)
                        }
                    })
                }
                data1['params'] = sendData
                props.onChange({ name: 'params', target: { value: sendData } })
            } else if (type === "mapHubId") {
                if (e.name === "hubId") {
                    data1.hubId[index]["hubId"] = e.target.value
                    setMapHubIds(e.target.value)
                    props.onChange({ name: 'hubId', target: { value: data1.hubId } })
                }
            } else if (type === "deleteMarker") {
                let sendData = [...data1['hubId']]
                sendData.splice(index, 1)
                data1['hubId'] = sendData
                props.onChange({ name: 'hubId', target: { value: sendData } })
            } else if (type === "addHub") {
                let hubId = []
                if (data1?.hubId) {
                    hubId = [...data1?.hubId]
                }
                hubId.push({ hubId: "", coordinates: {}, deviceId: "", isDynamic: false })
                data1["hubId"] = hubId
                props.onChange({ name: 'hubId', target: { value: hubId } })
            } else if (type === "coordinates") {
                data1["hubId"][index]["isDynamic"] = false
                props.onChange({ name: 'hubId', target: { value: data1["hubId"] } })
            } else if (type === "deviceSelection") {
                data1["hubId"][index]["isDynamic"] = true
                props.onChange({ name: 'hubId', target: { value: data1["hubId"] } })
            } else if (type === "lng" || type === "lat") {
                data1["hubId"][index]["coordinates"][type] = e.target.value
                props.onChange({ name: 'hubId', target: { value: data1["hubId"] } })
            } else if (type === "mapDeviceId") {
                data1["hubId"][index]["deviceId"] = e.target.value
                props.onChange({ name: "hubId", target: { value: data1["hubId"] } })
            } else if (type === "mapDeviceIds") {
                let deviceIds = []
                if (data1?.deviceId?.length) {
                    deviceIds = [...data1?.deviceId]
                }
                if (!e.target.remove) {
                    if (e.target.value === "all") {
                        deviceIds = []
                        markerdeviceList.map(id => {
                            deviceIds.push(id.value)
                        })
                        deviceIds.push("all")
                    } else {
                        if (deviceIds.includes("all")) {
                            deviceIds = []
                        }
                        deviceIds.push(e.target.value)
                    }
                    setSelectedDeviceIds(deviceIds)
                }
                data["deviceId"] = deviceIds
                props.onChange({ name: 'deviceId', target: { value: deviceIds } })
            }
            else {
                props.onChange({ name: type, target: { value: data1[type] } })
            }
        }
        setData(data1)
    }


    return (
        getNum.length ?
            <>
                <div className='propertiesLabels mt-2'>Device Connection</div>
                <div className="my-3">
                    <Select name="levelId"
                        label="Select Level"
                        options={levelIds}
                        // dropDownHeight="100px"
                        onChange={(e) => {
                            onChangeHandler(e, "levelId")
                        }}
                        onClick
                        defaultValue={{ value: data.levelId ? data.levelId : null }}

                    />
                </div>

                {!props.configTag.includes("deviceConnectionWithMap") ?

                    <div className="my-3">
                        <Select name="hubId"
                            label="Select Hub"
                            options={hubIds}
                            // dropDownHeight="100px"
                            onChange={(e) => {
                                onChangeHandler(e, "hubId")
                            }}
                            onClick
                            defaultValue={{ value: data.hubId ? data.hubId : null }}
                        />
                    </div>
                    :
                    <>
                        {
                            data?.hubId?.length ?
                                <div>
                                    {data?.hubId.map((row, key) =>
                                        <InnerCard key={key} className="p-3 mt-3">
                                            <div className='d-flex'>
                                                <Select label="HubId"
                                                    options={hubIds}
                                                    dropDownHeight="100px"
                                                    onClick
                                                    name="hubId" defaultValue={{ value: row.hubId }}
                                                    onChange={(e) => {
                                                        onChangeHandler(e, "mapHubId", key)
                                                    }} />
                                                <button onClick={() => onChangeHandler(null, "deleteMarker", key)} className='btn btn-light text-danger rounded-circle-px ml-1'><i className='fa fa-trash'></i></button>

                                            </div>
                                            <div className='mt-2 '>
                                                <ButtonContainer>
                                                    <Button active={!row?.isDynamic} buttonResult={() => onChangeHandler(null, "coordinates", key)} >Static</Button>
                                                    <Button active={row?.isDynamic} buttonResult={() => onChangeHandler(null, "deviceSelection", key)}>Dynamic</Button>
                                                </ButtonContainer>
                                            </div>
                                            {!row?.isDynamic ?
                                                <div>
                                                    <div className='d-flex mt-2' style={{ gap: 5 }}>
                                                        <Input name="lat"
                                                            label="Lat"
                                                            // dropDownHeight="100px"
                                                            onChange={(e) => {
                                                                onChangeHandler(e, "lat", key)
                                                            }}
                                                            onClick
                                                            defaultValue={row?.coordinates?.lat ? row.coordinates.lat : ""}

                                                        />
                                                        <Input name="lng"
                                                            label="Lng"
                                                            onChange={(e) => {
                                                                onChangeHandler(e, "lng", key)
                                                            }}
                                                            onClick
                                                            defaultValue={row?.coordinates?.lng ? row.coordinates.lng : ""}
                                                        />
                                                    </div>
                                                </div>
                                                : <div className="mt-3" key={key}>
                                                    <Select name="deviceId"
                                                        label={"Select Device"}
                                                        filterBy={row.hubId}
                                                        options={deviceIdList}
                                                        onChange={(e) => {
                                                            onChangeHandler(e, "mapDeviceId", key)
                                                        }}
                                                        onClick
                                                        defaultValue={{ value: row.deviceId }}
                                                    />
                                                </div>}
                                        </InnerCard>
                                    )}
                                </div>
                                : null
                        }

                        <div className='mt-2 split-t pt-2'>
                            <ButtonContainer>
                                <Button
                                    buttonResult={() => onChangeHandler(null, "addHub")}
                                ><i className='fa fa-plus'></i> Add Marker</Button>
                            </ButtonContainer >
                        </div>
                    </>
                    // <div className="my-3">
                    //     <Select name="hubId"
                    //         label="Select Hub (Multiple)"
                    //         options={hubIds}
                    //         // dropDownHeight="100px"
                    //         onChange={(e) => {
                    //             onChangeHandler(e, "mapHubId")
                    //         }}
                    //         onClick
                    //         defaultValue={{ value: data.hubId ? data.hubId : null }}

                    //     />
                    // </div>

                }

                {!markerdeviceList.length ? getNum.map((row, key) =>
                    <div className="mt-3" key={key}>
                        <Select name="deviceId"
                            label={getNum.length === 1 ? "Select Device" : "Select " + row}
                            options={deviceIdList}
                            filterBy={data.hubId}
                            onChange={(e) => {
                                onChangeHandler(e, (getNum.length === 1 ? "deviceId" : "deviceId&" + row))
                            }}
                            onClick
                            defaultValue={{ value: data['deviceId' + (getNum.length === 1 ? "" : ("&" + row))] ? data['deviceId' + (getNum.length === 1 ? "" : ("&" + row))] : null }}
                        />
                    </div>
                ) :
                    <>
                        <div className="mt-3">
                            <Select name="deviceId"
                                label={"Select Devices"}
                                options={markerdeviceList}
                                // filterBy={data.hubId}
                                onChange={(e) => {
                                    onChangeHandler(e, "mapDeviceIds")
                                }}
                                onClick
                                defaultValue={{ value: data?.deviceId?.length > 0 ? data.deviceId[data.deviceId.length - 1] : "all" }}
                            />
                        </div>
                        <div className='mt-3'>
                            <div className='row m-0 align-items-center' style={{ gap: 10 }}>
                                {!selectedDeviceIds.includes('all') ? selectedDeviceIds.map((id, key) =>
                                    <div className='bg-light rounded-circle-px p-2 px-3'>
                                        <span>{id}</span>
                                        <span className='btn btn-light rounded-circle' onClick={() => removeSelectedDeviceId(key)} style={{ background: "" }}><i className='fa fa-times'></i></span>                                                    </div>
                                ) : <div className='bg-light rounded-circle-px p-2 px-3'>
                                    <span>All</span>
                                </div>
                                }
                            </div>
                        </div>
                    </>
                }
                {props.configTag.includes("deviceConnection") ?
                    <div className="mt-3">
                        <Select name="param"
                            label="Parameter"
                            options={paramList}
                            onChange={(e) => {
                                onChangeHandler(e, "param")
                            }}
                            onClick
                            defaultValue={{ value: data.param ? data.param : null }}
                        />
                    </div>
                    :
                    props.configTag.includes("deviceConnectionWithTable") ?
                        paramList.length ?
                            <>
                                {'params' in data ?
                                    data.params.map((row, idx) =>
                                        <div className='mt-2 split-t pt-3' key={idx}>
                                            <div className=''>
                                                <Input label="Name" name="name" defaultValue={row.name}
                                                    onChange={(e) => {
                                                        onChangeHandler(e, "params", idx)
                                                    }} />
                                            </div>
                                            <div className='mt-3 d-flex'>
                                                <Select name="value"
                                                    label="Parameter"
                                                    options={paramList}
                                                    onChange={(e) => {
                                                        onChangeHandler(e, "params", idx)
                                                    }}
                                                    onClick
                                                    defaultValue={{ value: row.value ? row.value : null }}
                                                />
                                                <button onClick={() => onChangeHandler(null, "deleteParam", idx)} className='btn btn-light text-danger rounded-circle-px ml-1'><i className='fa fa-trash'></i></button>
                                            </div>
                                        </div>
                                    )
                                    : null}

                                <ButtonContainer>
                                    <Button
                                        buttonResult={() => onChangeHandler(null, "addParam")}
                                    ><i className='fa fa-plus'></i> Column</Button>
                                </ButtonContainer>
                            </>
                            : null : null}
            </> : null
    )
}
const mapStateToProps = (state) => ({
    organization: state.organization,
    configration: state.configration,
    userInfo: state.userInfo

});
export default connect(mapStateToProps, null)(DeviceConnection)
