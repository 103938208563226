// All rights to the entire source code, it's associated documentation, and design are the property of Linked Things (Pvt) Ltd.
// No part of the above can be copied, saved, or used, in any manner whatsoever by any entity or individual unless explicitly authorized to do so.
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import "./guage.scss"


function ThreePhaseGuage(props){
    const [state,setState] = useState({
      color: {
        green:  "rgb(0, 150, 0)",
        yellow: "rgb(230, 230, 0)",
        orange: "rgb(220, 102, 0)",
        red:    "rgb(200, 0, 0)",
        purple: "rgb(100, 0, 100)",
      },
      config: {
        colorScale: [{color:'yellow',position:0},{color:'green',position:0},{color:'red',position:100}],
        gradient:   false,
        minValue:   0,
        maxValue:   100,
        value_0: 0,
        value_1: 0,
        value_2: 0,
        value_3: 0,
        label: '',
        deviceIds: [null,null,null,null]
      },
      finalConfig: {color:null,linearColor:null},
      data: null,
    })
    const [events, setEvents] = useState([
        {
        Status: {},
        Connection: {}
    },
        {
        Status: {},
        Connection: {}
    },
        {
        Status: {},
        Connection: {}
    },
        {
        Status: {},
        Connection: {}
    },
])
    useEffect(()=>{
        let state1 = {...state}, conicColor = "", linearColor = "";
        if("config" in props){
            if(props.config){
                // if("colorScale" in props.config){
                //     state1.config.colorScale = props.config.colorScale;
                // }
                // if("gradient" in props.config){
                //     state1.config.gradient = props.config.gradient;
                // }
                let cnfg = Object.keys(props.config)
                cnfg.map((row,key) => {
                    if(row.match("Accumulative")){
                        state1.config.deviceIds[0] = props.config[row];
                    }
                    else if(row.match("Phase 1")){
                        state1.config.deviceIds[1] = props.config[row];
                    }
                    else if(row.match("Phase 2")){
                        state1.config.deviceIds[2] = props.config[row];
                    }
                    else if(row.match("Phase 3")){
                        state1.config.deviceIds[3] = props.config[row];
                    }
                })
                if("label" in props.config){
                    state1.config.label = props.config.label;
                }
                if("min" in props.config){
                    state1.config.minValue = parseInt(props.config.min);
                }
                if("max" in props.config){
                    state1.config.maxValue = parseInt(props.config.max);
                }
                if("lowest" in props.config){
                    state1.config["lowestValue"] = parseInt(props.config.lowest);
                    state1.config.colorScale[1].position = state1.config["lowestValue"]
                }
                if("highest" in props.config){
                    state1.config["highestValue"] = parseInt(props.config.highest);
                    state1.config.colorScale[2].position = state1.config["highestValue"]
                }else{
                    state1.config.colorScale[2].position = state1.config.maxValue
                }
            }
        }
        if(state1.config.colorScale){
            state1.config.colorScale.map((e,key)=>{
                if(key > 0){
                    if(!state1.config.gradient){
                        conicColor += ", " + state1.color[state1.config.colorScale[key-1].color] + " " + (((e.position / state1.config.maxValue) * 100) * 180) / 100+"deg";
                        linearColor += ", " + state1.color[state1.config.colorScale[key-1].color] + " " + ((e.position / state1.config.maxValue) * 100)+"%";
                    }
                    conicColor += ", "
                    linearColor += ", "
                }
                if("color" in e){
                    conicColor += state1.color[e.color];
                    linearColor += state1.color[e.color];
                }
                if("position" in e){
                    conicColor += " "+(((e.position / state1.config.maxValue) * 100) * 180) / 100+"deg";
                    linearColor += " "+((e.position / state1.config.maxValue) * 100)+"%";
                }
            })
            conicColor = "conic-gradient(from -90deg at 50% 100%," + conicColor + ")"
            state1.finalConfig["color"] = conicColor;
            linearColor = "linear-gradient(90deg," + linearColor + ")"
            state1.finalConfig["linearColor"] = linearColor;
        }
        setState(state1);
    },[props.config])
    let nightMode = false
    if("nightMode" in props){
        nightMode = props.nightMode;
    }

    useEffect(async () => {
        let events1 = [ ...events ]
        let Events = props?.eventReducer?.deviceEvents
        state?.config?.deviceIds?.map((id,key)=>{
            if(id){
                let hubId = id.split("_")
                if (hubId) {
                    hubId = hubId[0] + "_" + hubId[1]
                }
                if (Events.deviceId === id) {
                    events1[key].Status = Events
                } else if (Events.deviceId === hubId) {
                    events1[key].Connection = Events
                }
            }
        })
        setEvents(events1)
    }, [props.eventReducer.deviceEvents])

    useEffect(() => {
        events.map((eve, key)=>{
            if (props?.config?.param in eve?.Status) {
                let state1 = { ...state }
                if (eve.Status[props.config.param] !== null) {
                    state1.config['value_'+key] = eve.Status[props.config.param]
                } else {
                    state1.config['value_'+key] = 0
                }
                setState(state1)
            }
        })
    }, [events])

    return state.finalConfig?(
        <>
          <div
          className="graph-guage"
            style={{
              backgroundImage: state.finalConfig.color,
              filter: nightMode?"brightness(3)":""
            }}
          >
            <div className="mask"
            style={nightMode ? "maskBg" in props.config? {backgroundColor: props.config.maskBg}:{backgroundColor: "rgb(3,3,3)"}: "maskBg" in props.config? {backgroundColor: props.config.maskBg}:null}
            >
                <svg 
                className="guageArrow" 
                style={{ transform: "translateX(-50%) rotate(calc("+ ((((state.config.value_0>state.config.maxValue?state.config.maxValue: state.config.value_0) / state.config.maxValue) * 100) * 180) / 100 +"deg - 90deg))"}}
                xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" version="1.1"
                viewBox="0 0 12.96 110.84"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <g>
                    <polygon fill={nightMode?"white":"black"} style={{transition:"all 0.3s"}} points="0,96.95 6.48,102.26 12.96,96.95 6.48,-0 "/>
                    <circle fill="red" cx="6.48" cy="104.36" r="6.48"/>
                </g>
                </svg>
                <div className="label text-left" style={{color: nightMode?"white":"black", left: "1%", right: "unset", top: "90%", }}>{state.config.minValue}</div>
                <div className="label text-right" style={{color: nightMode?"white":"black", left: "unset", right: "1%", top: "90%", }}>{state.config.maxValue}</div>
                <div className="label" style={{color: nightMode?"white":"black"}}>Accumulative: {state.config.value_0} {"unit" in props.config? props.config.unit: " "}</div>
            </div>
          </div>
            <div className='row mx-0 pt-3 justify-content-center '>
                {/* {props.config.threePhase.map((e, key) => */}
                    <div className='col-xl-4 col-md-4 col-12 p-1 mt-2'>
                        <div
                        className={"label" in props?"graph-range-label":"graph-range"}
                            style={{
                            backgroundImage: state.finalConfig.linearColor,
                            height: "10px",
                            }}
                        >
                            <h6 className="label">Phase 1</h6>
                            <i style={{left: (((state.config.value_1>state.config.maxValue?state.config.maxValue:state.config.value_1) / state.config.maxValue) * 100)+"%"}} className="indicator fa fa-caret-down"></i>
                            <div className="text-value">{state.config.value_1} {"unit" in props.config? props.config.unit: " "}</div>
                        </div>
                    </div>
                    <div className='col-xl-4 col-md-4 col-12 p-1 mt-2'>
                        <div
                        className={"label" in props?"graph-range-label":"graph-range"}
                            style={{
                            backgroundImage: state.finalConfig.linearColor,
                            height: "10px",
                            }}
                        >
                            <h6 className="label">Phase 2</h6>
                            <i style={{left: (((state.config.value_2>state.config.maxValue?state.config.maxValue:state.config.value_2) / state.config.maxValue) * 100)+"%"}} className="indicator fa fa-caret-down"></i>
                            <div className="text-value">{state.config.value_2} {"unit" in props.config? props.config.unit: " "}</div>
                        </div>
                    </div>
                    <div className='col-xl-4 col-md-4 col-12 p-1 mt-2'>
                        <div
                        className={"label" in props?"graph-range-label":"graph-range"}
                            style={{
                            backgroundImage: state.finalConfig.linearColor,
                            height: "10px",
                            }}
                        >
                            <h6 className="label">Phase 3</h6>
                            <i style={{left: (((state.config.value_3>state.config.maxValue?state.config.maxValue:state.config.value_3) / state.config.maxValue) * 100)+"%"}} className="indicator fa fa-caret-down"></i>
                            <div className="text-value">{state.config.value_3} {"unit" in props.config? props.config.unit: " "}</div>
                        </div>
                    </div>
                {/* )} */}
                <h5>{state.config.label}</h5>
            </div>

        </>
      
    ):null;
}
const mapStateToProps = (state) => ({
    eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(ThreePhaseGuage)