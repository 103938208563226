import React, { useEffect, useState } from 'react'
import { hexToHsl, hslToHex } from '../../../functions/functions'
import './shapes.scss'
export function Sqaure(props) {
  const [night, setNight] = useState(false)
    const [config, setConfig] = useState({
        cssColor: "white",
        value: 70,
        label: "Label",
    })
    const [style, setStyle] = useState({
      borderStyle: "solid",
      borderWidth: 3,
      borderColor: "#0471c4",
      borderRadius: 10,
    })
    useEffect(() => {
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                }
            }
            setConfig(config1)
        }
    }, [props.config])
    useEffect(() => {
        if (props?.style) {
          let style1 = { ...style }
          if ("borderStyle" in props.style) {
            style1 = {...style1,...props.style.borderStyle};
          }
            setStyle(style1)
        }
    }, [props.style])
  return (
    <div style={{
      backgroundColor: config.cssColor, 
      height: style.borderWidth?"calc(100% - "+(style.borderWidth/2)+"px)":"100%",
      width: style.borderWidth?"calc(100% - "+(style.borderWidth/2)+"px)":"100%",
      margin: style.borderWidth?-(style.borderWidth/2)+"px 0 0 "+-(style.borderWidth/2)+"px":"0",
      borderWidth: style.borderWidth+"px", 
      borderColor: style.borderColor, 
      borderStyle: style.borderStyle, 
      borderRadius: style.borderRadius+"px", 
      boxSizing: "content-box",
      transition: 'all 0.3s'
    }}></div>
  )
}
export function Circle(props) {
  const [night, setNight] = useState(false)
  const [config, setConfig] = useState({
      cssColor: "white",
      value: 70,
      label: "Label",
  })
  const [style, setStyle] = useState({
    borderStyle: "solid",
    borderWidth: 3,
    borderColor: "#0471c4",
  })
  useEffect(() => {
      if (props?.config) {
          let config1 = { ...config }
          if ("color" in props.config && props.config?.color) {
              if (props.config.color.match("#")) {
                  config1.cssColor = props.config.color;
              }
          }
          setConfig(config1)
      }
  }, [props.config])
  useEffect(() => {
      if (props?.style) {
        let style1 = { ...style }
        if ("borderStyle" in props.style) {
          style1 = {...style1,...props.style.borderStyle};
        }
          setStyle(style1)
      }
  }, [props.style])
  return (
    <div style={{
      backgroundColor: config.cssColor, 
      height: style.borderWidth?"calc(100% - "+(style.borderWidth/2)+"px)":"100%",
      width: style.borderWidth?"calc(100% - "+(style.borderWidth/2)+"px)":"100%",
      margin: style.borderWidth?-(style.borderWidth/2)+"px 0 0 "+-(style.borderWidth/2)+"px":"0",
      borderWidth: style.borderWidth+"px", 
      borderColor: style.borderColor, 
      borderStyle: style.borderStyle, 
      borderRadius: "100%", 
      boxSizing: "content-box",
      transition: 'all 0.3s'
    }}></div>
  )
}