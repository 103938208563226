import store from '../../services/utilities/store'
import { getConfigration } from '../../services/utilities/users';


export const configrationAction = async () => {
    let response = await getConfigration()
    store.dispatch({
        type: "Configration",
        payload: response
    });
};
