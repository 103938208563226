export const lastEvent = (state = [], action) => {

    switch (action.type) {
        case "Get Events":
            // return { ...state, lastEvent: action.payload };
            // let state1 = [...state]
            if (action.payload.type === "last") {
                return { ...state, lastEvent: action.payload.data };
            } else if (action.payload.type === "live") {
                let data = { ...state }
                if (data?.lastEvent?.devices) {
                    data.lastEvent.devices.map(device => {
                        let hubId = device._id.split("_")
                        hubId = hubId[0] + "_" + hubId[1]
                        if (device._id === action.payload.data.deviceId) {
                            device.events[0] = action.payload.data
                        }
                        else if (hubId === action.payload.data.deviceId) {
                            device.events[1] = action.payload.data
                        }
                        return device
                    })
                }
                return data
            }
            break
        default:
            return state
    }
}