import React, { useState, useEffect } from 'react'
import { Heading } from '../card/card'
import Input from '../input/input'
import Select from '../select/select'
import { Button, ButtonContainer } from '../arraybutton/arraybutton'
import { assign, set } from 'lodash'
import { connect } from 'react-redux'
import { EmailRevoke, RoleList } from '../../services/config/generic'
import { EmailRegex } from '../../services/config/regex'
import { CreateChildUser, EditChildUser, getAdminRoles } from '../../services/utilities/events'
import { createUser } from '../../services/config/serverAddresses'
import CardLoader from '../loader/cardLoader'
import { flattenArray } from '../../functions/functions'

const AddUserPopup = (props) => {
    // let levelId = window.location.hash
    // levelId = levelId.split("/")
    // levelId = levelId[levelId.length - 1]
    // let orgId = levelId
    // if (orgId.match("_")) {
    //     let orgIdList = orgId.split("_")
    //     orgId = orgIdList[0]
    // }
    let organization = props.organization.organization
    const [roleArray, setRoleArray] = useState([])
    const [levelIds, setLevelIds] = useState([])
    const [userLoader, setUserLoader] = useState(false)
    // const [selectId, setSelectId] = useState(null)
    const [form, setForm] = useState(1)
    // const [levelAssign, setLevelAssign] = useState([""])
    const [firstName, setFirstName] = useState(true)
    const [lastName, setLastName] = useState(true)
    const [email, setEmail] = useState(true)
    const [designation, setDesignation] = useState(true)
    const [alreadyAssign, setAlredyAssign] = useState({ valid: true, key: 0 })
    const [level, setLevel] = useState({ valid: true, key: 0 })
    const [role, setRole] = useState({ valid: true, key: 0 })
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        createdBy: props?.userInfo?.varification.email,
        role: "Manager",
        levels: [{ levelId: "", roleId: "" }],
    })

    useEffect(() => {
        if (props?.data?._id) {
            setUserData({
                firstName: props.data.firstName,
                lastName: props.data.lastName,
                email: props.data.email,
                createdBy: props.data.createdBy,
                designation: props.data.designation,
                role: "Manager",
                _id: props.data._id,
                levels: props.data.levels,
            })
        }
    }, [])
    const onChangeHandler = (e, key = null) => {
        let data1 = userData
        let name = e.name
        let alreadyAssign1 = false
        if (name === "levelId" || name === "roleId") {
            // data1["levels"][key][name] = e.target.value
            let update = false
            let excludeChecking = key
            if (data1["levels"][key][name]) {
                for (let i = 0; i < data1.levels; i++) {
                    if (i !== excludeChecking && data1.levels[i] === excludeChecking + 1) {
                        update = true // Element found in the array except for the excluded index
                    }
                }
            }
            data1.levels.map((level, key1) => {
                if (level.levelId.match(e.target.value) || e.target.value.match(level.levelId)) {
                    if (key1 !== key && !update) {
                        alreadyAssign1 = true
                    }
                }
            })
            if (alreadyAssign1) {
                setAlredyAssign({ valid: false, key: key })
            } else {
                setAlredyAssign({ valid: true, key: key })
            }
            data1["levels"][key][name] = e.target.value
        } else {
            data1[name] = e.target.value
        }
        setUserData(data1)
    }
    useEffect(async () => {
        // setLoader(true)
        let data = await getAdminRoles()
        let data1 = []
        data.map((data, key1) => {
            data1.push({ label: data.name, value: data._id })
        })
        // setLoader(false)
        setRoleArray(data1)
    }, [])
    const checkFirstName = () => {
        if (!userData.firstName) {
            setFirstName(false)
            return false
        } else {
            setFirstName(true)
            return true
        }
    }
    const checkLastName = () => {
        if (!userData.lastName) {
            setLastName(false)
            return false
        } else {
            setLastName(true)
            return true
        }
    }
    const NotSupoortedEmail = [...new Set(EmailRevoke)];

    const isFound = () => {
        let result = NotSupoortedEmail.some(element => element === userData.email.split('@')[1].split('.')[0])
        return result
    }

    const checkEmail = () => {
        if (userData.email) {
            var mailformat = EmailRegex;
            if (userData.email.match(mailformat) && !isFound()) {
                setEmail(true)
                return true
            }
            else {
                setEmail(false)
                return false
            }
        }
        else {
            setEmail(false)
            return false
        }
    }
    const checkDesignation = () => {
        if (!userData.designation) {
            setDesignation(false)
            return false
        } else {
            setDesignation(true)
            return true
        }
    }
    // const checkRole = () => {
    //     if (!rules.role) {
    //         setCheckRoleName(false)
    //         return false
    //     } else {
    //         setCheckRoleName(true)
    //         return true
    //     }
    // }
    // const checkAccess = () => {
    //     if (!rules.access.length) {
    //         setCheckRoleAccess(false)
    //         return false
    //     } else {
    //         setCheckRoleAccess(true)
    //         return true
    //     }
    // }

    const functionFilterLevelID = () => {
        let levelIds1 = []
        let branches = false
        let useLevelIds = []
        if (userData.levels) {
            userData.levels.map(level => {
                useLevelIds.push(level.levelId)
            })
        }
        if (props.organization.organization) {
            let flattenedArray1 = flattenArray(props?.organization?.organization?.levels);
            flattenedArray1.map((level) => {
                if (props.userInfo.varification?.hierarchy.length) {
                    props.userInfo.varification?.hierarchy.map(levels => {
                        if (level.levelId.match(levels._id)) {
                            if (levels.branches.length > 0) {
                                branches = true
                                levels.branches.map(branch => {
                                    if (level._id.match(branch.levelId)) {
                                        levelIds1.push(level._id)
                                    }
                                })
                            }
                        }
                    })
                }
                if (!branches) {
                    if (!useLevelIds.includes(data => data.match(level._id))) {
                        levelIds1.push(level._id)
                    }
                }
            })

            levelIds1 = [...new Set(levelIds1.map(id => {
                let label = ""
                id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
                return ({ label, value: id })
            }))]
        }
        if (!branches) {
            levelIds1.unshift({ label: props.organization.organization.name, value: props.organization.organization._id })
        }
        return levelIds1
    }
    useEffect(() => {
        let levelIds1 = functionFilterLevelID()
        // levelIds1.unshift({ label: 'None', value: null })
        setLevelIds(levelIds1)
    }, [])

    // useEffect(() => {
    //     let levelIds1 = functionFilterLevelID()
    //     let filteredIds = []
    //     levelIds1.map(ids => {
    //         if (ids.includes(data => !data.match(ids))) {
    //         }
    //     })
    //     levelIds1 = [...filteredIds]
    // }, [userData])

    const addLevel = (data) => {
        let levelAssign1 = { ...data }
        levelAssign1.levels.push({ levelId: "", roleId: "" })
        // setLevelAssign(levelAssign1)
        setUserData(levelAssign1)
    }
    const deleteLevel = (key, data) => {
        setLevel({ valid: true, key: 0 })
        setRole({ valid: true, key: 0 })
        let levelAssign1 = { ...data }
        levelAssign1.levels.splice(key, 1)
        setUserData(levelAssign1)
        // setLevelAssign(levelAssign1)

    }
    const next = () => {
        if (checkFirstName()) {
            if (checkLastName()) {
                if (checkEmail()) {
                    if (checkDesignation()) {
                        setForm(2)
                    }
                } else {
                    setDesignation(true)
                }
            } else {
                setEmail(true)
                setDesignation(true)
            }
        } else {
            setLastName(true)
            setEmail(true)
            setDesignation(true)
        }


    }
    const checkLevelRoles = () => {
        let result = null
        if (userData.levels.length) {
            setLevel({ valid: true, key: 0 })
            setRole({ valid: true, key: 0 })
            userData.levels.map((row, key) => {
                if (!row.levelId && !result) {
                    let level1 = { ...level }
                    level1.valid = false
                    level1.key = key
                    setLevel(level1)
                    result = level1
                    return
                }
                else if (row.levelId && !alreadyAssign.valid) {
                    result = true
                    return
                }
                else if (!row.roleId && !result) {
                    let role1 = { ...role }
                    role1.valid = false
                    role1.key = key
                    setRole(role1)
                    result = role1
                    return
                }
            })
        }
        return result
    }
    const addUser = () => {
        setUserLoader(true)
        let userData1 = { ...userData }
        if (!checkLevelRoles()) {
            userData1["organizationId"] = organization._id
            userData1["organizationName"] = organization.name
            if (userData1._id) {
                return EditChildUser(userData1, userData1._id).then(async res => {
                    let result = res
                    if (result) {
                        if (!result.statusCode) {
                            props.onResPopup(["Success!", "User update successfully.", "success"])
                            setUserLoader(false)
                            props.onAddUser()
                        }
                        else {
                            props.onResPopup(["Failure!", "User already exist.", "danger"])
                            setUserLoader(false)
                            props.onAddUser()
                        }
                    }

                })
            } else {
                return CreateChildUser(userData1).then(async res => {
                    let result = res
                    if (result) {
                        if (!result.statusCode) {
                            props.onResPopup(["Success!", "User created successfully.", "success"])
                            setUserLoader(false)
                            props.onAddUser()
                        }
                        else {
                            props.onResPopup(["Failure!", "User already exist.", "danger"])
                            setUserLoader(false)
                            props.onAddUser()
                        }
                    }

                })
            }
        } else {
            setUserLoader(false)
        }
    }
    return (
        <div className='px-3 pb-3'>
            <div className="fade-in-right-onload" style={{ display: form === 1 ? "" : "none" }}>
                <div className='d-flex justify-content-center'>
                    <Heading size={2}>User Information</Heading>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <Input label="First Name" name="firstName" defaultValue={userData?.firstName} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Your First Name", status: !firstName }} />
                    </div>
                    <div className='col-md-6'>
                        <Input label="Last Name" name="lastName" defaultValue={userData?.lastName} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Your Last Name", status: !lastName }} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        <Input label="Email" name="email" defaultValue={userData?.email} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Valid Email", status: !email }} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        <Input label="Designation" defaultValue={userData?.designation} name="designation" onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Designation", status: !designation }} />
                    </div>
                </div>
                {/* <div className='row mt-3'>
                    <div className='col-md-12'>
                        <Select label="Role" onClick options={roleArray} name="role" onChange={(e) => onChangeHandler(e)} />
                    </div>
                </div> */}
                <div className='mt-3'>
                    <ButtonContainer>
                        <Button buttonResult={() => next()}>
                            Next
                        </Button>
                    </ButtonContainer>
                </div>
            </div>
            <div className=" fade-in-right-onload" style={{ display: form === 2 ? "" : "none" }}>
                <div className='d-flex justify-content-center'>
                    <Heading size={2}>Assign Level</Heading>
                </div>
                <div
                // style={{maxHeight: "30vh", overflow: 'auto'}}
                >
                    {userData.levels.length ?
                        userData.levels.map((row, key) =>
                            <div className='row mx-0 mt-2' >
                                <div className='col'>
                                    <Select
                                        label="Level"
                                        name="levelId"
                                        onClick
                                        options={levelIds}
                                        defaultValue={{ value: row.levelId ? row.levelId : "" }}
                                        onChange={(e) => onChangeHandler(e, key)}
                                        message={alreadyAssign.valid ? { msg: "Please Enter LevelId", status: key === level.key && !level.valid } : { msg: "Already Assign", status: key === alreadyAssign.key && !alreadyAssign.valid }}
                                    />
                                </div>
                                <div className='col'>
                                    <Select
                                        label="Role"
                                        name="roleId"
                                        onClick
                                        options={roleArray}
                                        defaultValue={{ value: row.roleId ? row.roleId : "" }}
                                        onChange={(e) => onChangeHandler(e, key)}
                                        message={{ msg: "Please Enter RoleId", status: key === role.key && !role.valid }}
                                    />
                                </div>

                                {userData.levels.length > 1 ?
                                    <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteLevel(key, userData)}><i className='fas fa-trash fa-sm'></i></div>
                                    : null}
                            </div>
                        )
                        : null}
                </div>
                <div className='d-flex justify-content-end px-3'>
                    <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addLevel(userData)}>+</button>
                </div>
                {!userLoader ?
                    <div className='mt-3 d-flex justify-content-center'>
                        <ButtonContainer>
                            <Button buttonResult={() => setForm(1)}>
                                Back
                            </Button>

                        </ButtonContainer>
                        <ButtonContainer>
                            <Button buttonResult={() => addUser()}>
                                {userData._id ? "Edit" : "Add"}
                            </Button>
                        </ButtonContainer>
                    </div>
                    : <CardLoader />}
            </div>

        </div >
    )
}
const mapStateToProps = (state) => ({
    organization: state.organization,
    userInfo: state.userInfo

})
export default connect(mapStateToProps, null)(AddUserPopup)