import React, { useEffect, useState } from 'react'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../components/card/card'
import { getRandomNumber, hslToHex, hsvToHex, idGenerator, todayDate, utcDate } from '../../functions/functions'
import Guage from '../../components/componentLIst/guage/guage'
import ChartConfig from '../../components/graph/chartConfig'
import { connect } from 'react-redux'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import "./analyzer.scss";
import moment from 'moment'
import { getMultiDeviceConsumption } from '../../services/utilities/events'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import GraphCard from '../../components/graphCard/graphCard'
import Select from '../../components/select/select'

function Analyzers(props) {
  const [isLoad, setIsLoad] = useState(false)
  const [devices, setDevices] = useState([])
  const [consumptionLimit, setConsumptionLimit] = useState("hourly")
  const [startDate, setStartDate] = useState(todayDate('start'))
  const [endDate, setEndDate] = useState("")
  const [chartConfig, setChartConfig] = useState({
    id: "energyGraph_" + idGenerator(),
    axisX: "date",
    axisY: "value",
    scrollbar: true,
    unit: 'kwh',
    export: true,
    series: []
  })
  const [parameters, setParameters] = useState([])
  const [graphData, setGraphData] = useState([])
  const [detailViewId, setDetailViewId] = useState(null)
  const [deviceList, setDeviceList] = useState([])
  const [deviceSelect, setDeviceSelect] = useState(null)
  const [secondDeviceSelect, setSecondDeviceSelect] = useState(null)
  const [graphLoad, setGraphLoad] = useState(false)

  useEffect(() => {
    liveEventHandler();
  }, [props.lastEvent])
  const liveEventHandler = () => {
    let Events = props?.lastEvent?.lastEvent?.devices
    const devices1 = devices.map(item => {
      const events = Events.find((events) => events._id === item._id) || null
      if (events) {
        item['events'] = events?.events[0]
      }
      return item
    })
    setDevices(devices1)
  }

  useEffect(() => {
    const filterDeviceList = props.org.hubs.map((hubList) =>
      hubList.devices.map((device) => {
        if (device.type === "Analyzer" || device.type === "Accmeter" || device.type === "Meter") {
          return { ...device, hubName: hubList.name }
        }
      }).filter(device => device !== undefined)
    ).flat(1)
    let chartConfig1 = { ...chartConfig }
    chartConfig1.series = []
    let parameters1 = []
    const deviceList = filterDeviceList.map((device, key) => {
      const events = props.lastEvent.lastEvent?.devices.find((events) => events._id === device._id) || null
      device['events'] = null
      // for (const item of events1) {
      //   if (item.events[0].deviceId === device._id) {
      if (events) {
        device['events'] = events?.events[0]
      }
      //   }
      // }
      // device['themeColor'] = color
      parameters1 = [...device.parameter]
      const energyParam = device.parameter.find(param => param.types === "Energy")
      if (energyParam && device.type === "Accmeter") {
        chartConfig1.series.push({
          type: "bar",
          name: device.hubName,
          color: "",
          x: "Date",
          y: device.hubName,
        })
      }
      return device
    })
    chartConfig1.series = chartConfig1.series.map((serial, index) => {
      const colorValue = ((index + 1) / chartConfig1.series.length * 80) + 190
      const color = hslToHex(colorValue, 100, 50)
      console.log(colorValue, color)
      serial.color = color
      return serial
    })
    setChartConfig(chartConfig1)
    console.log(props.org, props.org.hubs, filterDeviceList)
    setParameters(parameters1)
    setDevices(deviceList)
    setEndDate(todayDate('end'))
    // console.log("props",filterDeviceList,deviceList, props.lastEvent.lastEvent?.devices)
  }, [])
  useEffect(async () => {
    if (devices.length) {
      setIsLoad(true)
      let device = devices.filter((ACdevice) => ACdevice.type === "Accmeter")
      const _id = device.map(device => (device.parameter.find(param => param.types === "Energy")) ? device._id : null).filter(device => device !== null)
      const type = device.map(device => (device.parameter.find(param => param.types === "Energy")) ? (device.parameter.find(param => param.types === "Energy"))?.value : null).filter(device => device !== null)
      let _id1 = _id.toString(),
        type1 = type.toString()
      const response = await getMultiDeviceConsumption(_id1.toString(), type1.toString(), consumptionLimit, 100, utcDate(startDate) + "Z", utcDate(endDate) + "Z")
      const data = response?.map(data => {
        let date = data.created.split("T")
        let splitDate = date[1].split(":")
        splitDate = date[0] + "T" + splitDate[0] + ":00:00Z"
        let originalDate = new Date(splitDate);

        // Add 5 hours to the Date object
        // originalDate.setHours(originalDate.getHours() + 5);
        let objectData = { Date: originalDate };

        for (const events1 of data.events) {
          const deviceDetail = device.find(device => device._id === events1.deviceId)
          objectData[deviceDetail.hubName] = events1.ae
        }
        return objectData
      })
      setGraphData(data.reverse())
      setIsLoad(false)
    }
  }, [startDate, endDate, consumptionLimit])
  useEffect(() => {
    let deviceList1 = []
    if (devices.length) {
      for (const device of devices) {
        if (detailViewId === device.hubId) {
          deviceList1.push({ label: device.name, value: device._id })
        }
      }
    }
    if (deviceList1.length) {
      setDeviceSelect(devices[0])
    }
    setDeviceList(deviceList1)
  }, [detailViewId])



  return devices.length ? (
    <Card className='mt-3'>
      <CardHeader className={"d-flex" + (detailViewId ? " px-2" : "")}>
        {detailViewId && <button onClick={() => setDetailViewId(null)} className='btn btn-light text-primary-0 rounded-circle-px mr-1'><i className='fa fa-arrow-left'></i></button>}
        <Heading>
          Electrical Dashboard
        </Heading>
      </CardHeader>
      {!detailViewId ?
        <CardBody className="row m-0 g-1 pt-3">
          <div className='col-12 pb-3'>
            <InnerCard>
              <CardHeader className="d-flex justify-content-between">
                <Heading size="2" align="center">
                  Electricity Consumption
                </Heading>
                <div className='d-flex justify-content-end' style={{ gap: '10px' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      style={{ fontSize: 16 }}
                      className='w-100'
                      inputVariant="outlined"
                      // disableFuture="true"
                      label="Start Date"
                      value={startDate}
                      onChange={(event) => setStartDate(moment(event._d).format('YYYY-MM-DDTHH:mm:ss'))}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      style={{ fontSize: 16 }}
                      className='w-100'
                      inputVariant="outlined"
                      // disableFuture="true"
                      label="End Date"
                      value={endDate}
                      onChange={(event) => setEndDate(moment(event._d).format('YYYY-MM-DDTHH:mm:ss'))}
                    />
                  </MuiPickersUtilsProvider>
                  <div>
                    <ButtonContainer>
                      <Button value={"hourly"} buttonResult={(e) => { setConsumptionLimit("hourly"); }} active={consumptionLimit === "hourly" ? true : false}>
                        Hourly
                      </Button>
                      <Button value={"daily"} buttonResult={(e) => { setConsumptionLimit("daily"); }} active={consumptionLimit === "daily" ? true : false}>
                        Daily
                      </Button>
                      <Button value={"weekly"} buttonResult={(e) => { setConsumptionLimit("weekly"); }} active={consumptionLimit === "weekly" ? true : false}>
                        Weekly
                      </Button>
                      <Button value={"monthly"} buttonResult={(e) => { setConsumptionLimit("monthly"); }} active={consumptionLimit === "monthly" ? true : false}>
                        Monthly
                      </Button>
                    </ButtonContainer>
                  </div>
                </div>
              </CardHeader>
              <CardBody loader={isLoad} loaderHeight={"350px"}>
                <ChartConfig height={"380px"} data={graphData} chart={chartConfig} />
              </CardBody>
            </InnerCard>
          </div>
          <div className='col-12 pb-3 overflow-auto' style={{ gap: 10, maxHeight: '75vh' }}>
            <div className='row justify-content-center'>
              {devices.map((meter, key) =>
                meter.parameter.find(param => param.value === "ap") && meter.type === "Accmeter" &&
                <div className='col-xl-2 col-lg-3 col-md-4 col-6'>
                  <InnerCard key={key} className="p-1">
                    <button onClick={() => setDetailViewId(meter.hubId)} className='btn btn-sm btn-light rounded-2-t rounded-2-r w-100 d-flex justify-content-between px-1 pb-0 pt-1 mb-1 cursor-pointer'>
                      <small className='text-truncate'><strong>{meter.hubName}</strong></small>
                      <i className="fa fa-sm fa-external-link-alt"></i>
                    </button>
                    <div className='px-2 pt-3 pb-1'>
                      <Guage config={{ maskBg: '#ffffff', deviceId: meter._id, param: 'ap', max: 440, label: 'Power' }} />
                    </div>
                    <div className='text-center bg-light'>
                      Energy: {meter?.events ? meter.events[meter.parameter.find(param => param.types === "Energy")?.value] : 0} {meter.parameter.find(param => param.types === "Energy")?.unit}
                    </div>
                    <div className='text-center text-dark' style={{ lineHeight: "12px", fontSize: '10px' }}>Last Update: {moment(meter?.events?.created).format("YYYY-MM-DD hh:mm:ss A")}</div>
                  </InnerCard>
                </div>
              )}
            </div>
          </div>
          {/* <div className='col-2 d-flex flex-column overflow-auto pb-3' style={{gap: 10, maxHeight: '75vh'}}>
            {devices.map((meter, key) => meter.parameter.find(param => param.types === "Energy") && meter.type === "Accmeter" &&
              <div>
                <InnerCard key={key} className="p-1">
                  <button onClick={()=>setDetailViewId(meter.hubId)} className='btn btn-sm btn-light rounded-2-t rounded-2-r w-100 d-flex justify-content-between px-1 pb-0 pt-1 mb-1 cursor-pointer'>
                    <small className='text-truncate'><strong>{meter.hubName}</strong> - Energy</small>
                    <i className="fa fa-sm fa-external-link-alt"></i>
                  </button>
                  <div className='rounded-1 pt-2 pb-1 text-center d-flex flex-column' style={{backgroundColor: meter.themeColor, fontSize: 20, lineHeight: "20px"}}>
                    <h3 className='m-0'>{ meter?.events? meter.events[meter.parameter.find(param => param.types === "Energy")?.value]: 0}</h3>
                    <small style={{lineHeight: "12px", fontSize: '12px'}}>{meter.parameter.find(param => param.types === "Energy")?.unit}</small>
                  </div>
                  <div className='text-center text-dark' style={{lineHeight: "12px", fontSize: '10px'}}>Last Update: {moment(meter?.events?.created).format("YYYY-MM-DD hh:mm:ss A")}</div>
                </InnerCard>
              </div>
            )}
          </div> */}
        </CardBody>
        : <CardBody className="row m-0 g-1 pt-3">
          <div className='col-2 pb-3' style={{ height: '75vh', maxHeight: '75vh' }}>
            <InnerCard className="p-1 pt-0">
              <CardHeader>
                <Heading size={2}>Hub list</Heading>
              </CardHeader>
              <div className='overflow-auto'>
                {props?.org?.hubs.map((hub, index) =>
                  <button onClick={() => setDetailViewId(hub._id)} key={index} className={"btn btn-light text-truncate p-1 mt-1 w-100 rounded-2 " + (hub._id === detailViewId ? " text-primary-1 font-weight-bold" : "")}>
                    {hub.name}
                  </button>
                )}
              </div>
            </InnerCard>
          </div>
          <div className='col-10 overflow-auto pb-3' style={{ height: '75vh', maxHeight: '75vh' }}>
            <div className='d-flex flex-column' style={{ gap: 10 }}>
              <InnerCard className="overflow-auto">
                <CardHeader className="d-flex m-0 px-0 w-100">
                  <b className='col-3'>Parameters</b>
                  {devices.map((device, index) => device.hubId === detailViewId && (device.type === "Analyzer" || device.type === "Accmeter") &&
                    <b key={index} className='col-2'>{device.name}</b>
                  )}
                  <b className='col-1'>Unit</b>
                </CardHeader>
                <CardBody className="w-100">
                  {parameters.map((param, key) =>
                    <div key={key} className={"d-flex m-0 py-1" + (key % 2 === 0 ? " bg-light" : "")}>
                      <div className='col-3'>{param.types}</div>
                      {devices.map((device, index) => device.hubId === detailViewId && (device.type === "Analyzer" || device.type === "Accmeter") &&
                        <div key={index} className='col-2'>{device?.events?.[param.value] || "N/A"}</div>
                      )}
                      <div className='col-1'>{param.unit}</div>
                    </div>
                  )}
                </CardBody>
              </InnerCard>
              {deviceList.length && <div className=''>
                <div className='d-flex'>
                  <div className='col-6'>
                    <Select onClick className="mt-3" label="Device 1" options={deviceList} defaultValue={{ value: deviceSelect._id }} onChange={(e) => { setGraphLoad(true); setDeviceSelect(devices.find(device => device._id === e.target.value)); setTimeout(() => { setGraphLoad(false); }, 20) }} />
                  </div>
                  <div className='col-6'>
                    <Select sort={false} onClick className="mt-3" label="Device 2" options={[{ label: "None", value: "" }, ...deviceList]} defaultValue={{ value: secondDeviceSelect?._id }} onChange={(e) => { setGraphLoad(true); setSecondDeviceSelect(devices.find(device => device._id === e.target.value) || null); setTimeout(() => { setGraphLoad(false); }, 20) }} />
                  </div>
                </div>
              </div>
              }
              {deviceSelect && !graphLoad &&
                <GraphCard
                  events={{ Status: deviceSelect.events, Connection: null }}
                  device={deviceSelect}
                  device2={secondDeviceSelect}
                  export={true}
                />
              }
            </div>
          </div>
        </CardBody>}
    </Card>
  ) : null
}

const mapStateToProps = (state) => ({
  lastEvent: state.lastEvent,
});
export default connect(mapStateToProps, null)(Analyzers)