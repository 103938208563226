import getdata from "../utilities/socket";
import store from "../utilities/store";
import { getCookie } from "../utilities/users";

export function SocketAction() {
    let eventsName = ["deviceEvent", getCookie("email")]
    let client = getdata();
    eventsName.forEach(eventsName => {
        client.on(eventsName, body => {
            if (eventsName === "deviceEvent") {
                store.dispatch({
                    type: "ADD_EVENT",
                    payload: body,
                });
                store.dispatch({
                    type: "Get Events",
                    payload: { type: 'live', data: body },
                });
            } else {
                store.dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: body,
                });
            }
        })
    })
    // client.on("logs", (body) => {
    //     let { eventType, ...payload } = body;
    //     store.dispatch({
    //         type: "ADD_LOGS",
    //         payload: payload,
    //     });
    // })
    return client
}

// export function NotificationAction() {
//     let client = getdata();
//     client.on(getCookie("email"), (body) => {
//         store.dispatch({
//             type: "ADD_NOTIFICATION",
//             payload: body,
//         });
//     })
//     return client
// } 
