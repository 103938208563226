import React, { useEffect, useState } from 'react'
// import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
import cookies from '../../services/utilities/cookies';
import Select from '../select/select';
import Card, { CardBody, CardHeader, InnerCard } from '../card/card';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { todayDate } from '../../functions/functions';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const Report = () => {
    const [content, setContent] = useState("")
    let token = cookies.getCookie('token');
    const [startDate, setStartDate] = useState(todayDate('start'))
    const [endDate, setEndDate] = useState(todayDate('end'))
    const [activeReport, setActiveReport] = useState(0)
    const [filter, setFilter] = useState([])
    const [devicesList, setDeviceList] = useState([])
    const [paramList, setParamList] = useState([])
    const [locationsList, setLocationsList] = useState([])
    const [typesList, setTypesList] = useState([])
    const [urlData, setUrlData] = useState({})

    const handlerStartDate = (event) => {
        let startDate1 = moment(event._d).format('YYYY-MM-DDTHH:mm:ss')
        setStartDate(startDate1);
    }

    const handlerEndDate = (event) => {
        let endDate1 = moment(event._d).format('YYYY-MM-DDTHH:mm:ss')
        setEndDate(endDate1);
    }
    const reportForm = (key, data) => {
        setActiveReport(key)
        setUrlData({})
    }
    const onChangeHandler = (e) => {
        let url = { ...urlData }
        url[e.name] = e.target.value
        // if (e.target.value === "all") {
        //     hubIds1 = []
        //     hubIdsList.map(id => {
        //         hubIds1.push(id.value)
        //     })
        //     hubIds1.push("all")
        // } else {
        //     if (hubIds1.includes("all")) {
        //         hubIds1 = []
        //     }
        //     hubIds1.push(e.target.value)
        // }
        // setSelectedHubIds(hubIds1)
        // }
        setUrlData(url)
    }

    useEffect(() => {
        let filterList = []
        let deviceList = []
        let paramList = []
        let locationList = []
        let typeList = []

        reports[activeReport].filter.map(filter => {
            filterList.push({ label: filter, value: filter })
        })
        reports[activeReport].devices.map(device => {
            deviceList.push({ label: device, value: device })
        })
        reports[activeReport].parameters.map(param => {
            paramList.push({ label: param, value: param })
        })
        reports[activeReport]?.locations?.map(location => {
            locationList.push({ label: location, value: location })
        })
        reports[activeReport]?.types?.map(type => {
            typeList.push({ label: type, value: type })
        })

        setFilter(filterList)
        setDeviceList(deviceList)
        setParamList(paramList)
        setLocationsList(locationList)
        setTypesList(typeList)
    }, [activeReport])

    const reports = [
        { name: "Report1", startDate: true, endDate: true, filter: ["daily", "Weekly"], locations: ["QSCP_City", "QSCP_Pashtoonabad"], types: ["Acc", "Phase1"], devices: ["Abc_Abc", "Abc_def"], parameters: ["voltage", "c"], url: "www.abc.com" },
        { name: "Report2", filter: ["daily", "Weeekly"], devices: ["Abc_def"], parameters: ["c"], url: "www.def.com" },
        { name: "Report3", filter: ["daily", "Weeekly"], devices: ["Abc_def"], parameters: ["c"], url: "www.def.com", locations: ["QSCP_City", "QSCP_Pashtoonabad"], types: ["Acc", "Phase1"] }]

    const callFunction = async () => {
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        try {
            const response = await axios.get('http://192.168.0.107:5000/api/v1/open/bridge/pentaho/api/repos/%3Apublic%3ASteel%20Wheels%3AReports%3AIncome%20Statement%20(report).prpt/generatedContent?output-target=pageable/X-AWT-Graphics;image-type=png', {
                // responseType: 'arraybuffer'
            });
            const decode = response.data
            setContent(decode)
        }
        catch (error) {
            console.log(error);
        }
        // let config = {
        //     method: 'get',
        //     // maxBodyLength: Infinity,
        //     url: 'http://192.168.100.9:8080/pentaho/api/repos/%3Apublic%3ASteel%20Wheels%3AReports%3AIncome%20Statement%20(report).prpt/generatedContent?output-target=pageable/pdf',
        //     headers: {
        //         'Authorization': 'Basic YWRtaW46cGFzc3dvcmQ=',
        //         "Access-Control-Allow-Origin": "*",
        //     }
        // };
        // await axios(config)
        //     .then((response) => {
        //         console.log(JSON.stringify(response.data));
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        // const url = 'http://192.168.100.9:8080/pentaho/api/repos/%3Apublic%3ASteel%20Wheels%3AReports%3AIncome%20Statement%20(report).prpt/generatedContent?output-target=pageable/pdf'; // Replace with the actual resource URL
        // const headers = new Headers();
        // headers.append('Accept', 'application/json');
        // headers.append('Authorization', 'Basic YWRtaW46cGFzc3dvcmQ=');

        // fetch(url, {
        //     method: 'GET', // Use the appropriate HTTP method (GET, POST, etc.)
        //     headers: headers, // Include the custom headers
        //     // mode: 'cors', // Use 'cors' mode for CORS requests
        //     credentials: 'include', // Include credentials (cookies, HTTP authentication)
        // })
        //     .then(response => {
        //         if (response.ok) {
        //             return response.json(); // Parse the response as JSON (or use response.text() for other data types)
        //         } else {
        //             throw new Error(`Request failed with status: ${response.status}`);
        //         }
        //     })
        //     .then(data => {
        //         // Handle the response data
        //         console.log(data);
        //     })
        //     .catch(error => {
        //         // Handle any errors
        //         console.error('Error:', error);
        //     });

    }
    useEffect(() => {
        callFunction()
    }, [])
    //     // const [numPages, setNumPages] = useState(null);
    //     // const [pageNumber, setPageNumber] = useState(1);
    return (
        <div className='p-2 pt-0'>
            <Card className="w-100">
                <CardHeader>
                    <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }} >
                        {reports.map((report, key) =>
                            <div className={'tabButton d-flex ' + (key === activeReport ? "active" : "null")} onClick={() => reportForm(key, report)} style={{ minWidth: "100px" }}>
                                <div className='inner-btn text-truncate' key={key}>{report.name}</div>
                            </div>
                        )}
                    </div>
                    <div className='d-flex mx-md-0 mt-3 justify-content-between'>
                        {reports[activeReport].startDate && reports[activeReport].endDate ?
                            <div className='w-100 d-flex'>
                                <div className='col-lg-4 col-6 '>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DateTimePicker
                                            style={{ fontSize: 16 }}
                                            inputVariant="outlined"
                                            disableFuture="true"
                                            label="Start Date"
                                            // disabled={live === 1 ? true : false}
                                            // className="mr-3"
                                            value={startDate}
                                            onChange={(event) => handlerStartDate(event)}
                                        // format='YYYY-MM-DDTHH:mm:ssZ'
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className='col-lg-4 col-6'>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DateTimePicker
                                            style={{ fontSize: 16 }}
                                            inputVariant="outlined"
                                            disableFuture="true"
                                            label="End Date"
                                            // disabled={live === 1 ? true : false}
                                            value={endDate}
                                            onChange={(event) => handlerEndDate(event)}
                                        // format='YYYY-MM-DDTHH:mm:ssZ'
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div> : <div className='w-100 d-flex'>
                                <div className='col-lg-4 col-6 '>

                                </div>
                                <div className='col-lg-4 col-6'>

                                </div>
                            </div>}
                        <div className='w-100 d-flex justify-content-end'>
                            {reports[activeReport].locations ? <div className='col-lg-4 col-6'>
                                <Select dropDownHeight="100px" name="locationName" label="Location Name*" onChange={(e) => onChangeHandler(e)} onClick options={locationsList} />
                            </div> : null}
                            {reports[activeReport].types ? <div className='col-lg-4 col-6'>
                                <Select dropDownHeight="100px" name="type" label="Type*" onChange={(e) => onChangeHandler(e)} onClick options={typesList} />
                            </div> : null}
                        </div>
                    </div>
                </CardHeader>
                <CardHeader>
                    <div className='w-100 row m-0 justify-content-between'>
                        <div className='col-lg-4 col-6'>
                            <Select dropDownHeight="100px" name="deviceName" label="Device Name*" onChange={(e) => onChangeHandler(e)} onClick options={devicesList} />
                        </div>
                        <div className='col-lg-4'>
                            <div className='row '>
                                <div className='col-lg-6 col-12'>
                                    <Select dropDownHeight="100px" name="parameter" label="Parameter*" onChange={(e) => onChangeHandler(e)} onClick options={paramList} />
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <Select dropDownHeight="100px" name="filter" label="Filter*" onChange={(e) => onChangeHandler(e)} onClick options={filter} />
                                </div>
                            </div>
                        </div>
                    </div>
                </CardHeader>
                {/* <CardBody>
                </CardBody> */}
                <CardBody className='d-flex justify-content-center'>
                    <img src={content} />
                    {/* <Document file={content}>
                        <Page pageNumber={1} />
                    </Document> */}
                </CardBody>
            </Card>

            {/* <Document file={content}>
                <Page
                    pageNumber={pageNumber}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                />
                <p>
                    Page {pageNumber} of {numPages}
                </p>
            </Document> */}
        </div>
    )
}

export default Report