import React, { useEffect, useState, useCallback } from 'react'
import Car from "../../assets/svgs/carMarker.svg";
import { GoogleMap, InfoBox, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';


const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = { lat: 33.6844, lng: 73.0479 };
const Map = (props) => {
    const [markerIndex, setMarkerIndex] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: props.apiKey
    })

    const [map, setMap] = useState(null)

    // useEffect(() => {
    //     if(props.markers.length){
    //         const bounds = new window.google.maps.LatLngBounds();
    //         props.markers.forEach((coordinate) => {
    //             const latLng = new window.google.maps.LatLng(coordinate.lat, coordinate.lng);
    //             bounds.extend(latLng);
    //         });
    //         map.fitBounds(bounds);
    //     }
    // }, [props.markers])

    const onLoad = useCallback(function callback(map) {
        setMap(map)
        // map.addListener('center_changed', function() {
        //     // Get the new center coordinates of the map
        //     var newCenter = map.getCenter();

        //     // Pan the map to the new center coordinates with animation
        //     map.panTo(newCenter, 1000, window.google.maps.Animation.easeInOut);
        // });
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        // props.markers.forEach((coordinate) => {
        //     const latLng = new window.google.maps.LatLng(coordinate.lat, coordinate.lng);
        //     bounds.extend(latLng);
        // });
        // map.fitBounds(bounds);

        // setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])
    const onClickHandler = (e) => {
        setMarkerIndex(e);
        props.onChange(e, 'marker')
    }
    useEffect(() => {
        if (props.autoTrack && map) {
            map.panTo(props.markers[markerIndex]?.position, 1000, window.google.maps.Animation.easeInOut)
        }
    }, [props.markers])


    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={
                // props.autoTrack? props.markers[markerIndex]?.position :
                center}
            // defaultZoom={12}
            zoom={12}
            onLoad={onLoad}
        // onUnmount={onUnmount}
        >
            {props.markers.map((marker, key) =>
                marker.position &&
                <>
                    <Marker
                        key={key}
                        position={marker.position}
                        title={marker.name}
                        icon={{
                            url: Car,
                            scaledSize: new window.google.maps.Size(60, 60),
                        }}
                        onClick={() => onClickHandler(key)}
                    />
                    {/* <InfoBox
                        // defaultPosition={new window.google.maps.LatLng(props.center.lat, props.center.lng)}
                        position={marker.position}
                        // options={{ closeBoxURL: ``, enableEventPropagation: true }}
                        >
                        <div style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `12px` }}>
                            <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
                            Hello, Taipei!
                            </div>
                        </div>
                        </InfoBox> */}
                </>
            )}

            {markerIndex !== null && props?.path?.length > 0 && (
                <Polyline
                    path={props?.path}
                    options={{
                        strokeColor: "#F15A29",
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                    }}
                />
            )}
        </GoogleMap>
    ) : null;
}

export default Map