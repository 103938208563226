import React, { useEffect, useState } from 'react'
//import Resources
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton';
import './addHubForm.scss'
import Input from '../../components/input/input';
import { hubCreated, Hubtype, hubUpdate } from '../../services/utilities/events';
import InlineLoader from '../loader/inlineLoader';
import Mypopup from '../popup/mypopup';
import Select from '../select/select';
//import libraries
import { connect } from 'react-redux';
import HubInfoPopup from '../hubInfoPopup/HubInfoPopup';
import { getLevelIds, getLevelIdsAll } from '../../functions/functions';

const AddHubForm = (props) => {
    const [levelIdsList, setLevelIdsList] = useState([])
    //Organization Get to props
    let organization = props.org
    let levelId = props?.data ? props.data?.levelId : organization._id
    //All Configrations 
    let configration = props.configration.configration.configuration
    const [state, setState] = useState({
        loginData: {},
        modal: false,
        redirect: false,
        loginLoader: false,
    })

    //All States
    const [popup, setPopup] = useState(false)
    const [typeList, setTypeList] = useState([])
    const [branch, setBranch] = useState(
        props?.data ? props?.data :
            {
                _id: "",
                name: "",
                levelId: organization._id,
                version: "0.0.0",
                type: "",
                authToken: ""
            }
    )
    const [_id, set_id] = useState(true)
    const [name, setName] = useState(true)
    const [type, setType] = useState(true)
    const [levelIdvalidation, setlevelIdValidation] = useState(true)
    const [response, setResponse] = useState()
    // Create Hub List 
    useEffect(() => {
        let List = []
        configration.Hub.map((HubType) => {
            List.push({ value: HubType.type, label: HubType.type })
        })
        setTypeList(List)
    }, [])
    useEffect(() => {
        let levelIds = getLevelIdsAll(props?.organization?.organization, props.userInfo.varification?.hierarchy)
        setLevelIdsList(levelIds)
    }, [])
    //All Inputs Change Handler
    const onChangeHandler = (e, index) => {
        let data = branch
        let name = e.name
        data[name] = e.target.value
        if (e.name === "_id") {
            data["authToken"] = e.target.value
        }
        if (e.name === "_id") {
            set_id(true)
        }
        if (e.name === "name") {
            setName(true)
        }
        if (e.type === "type") {
            setType(true)
        }
        if (e.name === "levelId") {
            setlevelIdValidation(true)
        }
        setBranch(data)
    }
    // Validation MacAddress Field
    const MacAddress = () => {
        if (!branch._id || branch._id.length >= 40) {
            set_id(false)
            return false
        }

        else {
            set_id(true)
            return true
        }
    }


    // Validation Name Field
    const checkName = () => {
        if (!branch.name) {
            setName(false)
            return false
        }
        else {

            setName(true)
            return (true)
        }

    }
    // Validation Type Field
    const checkType = () => {
        if (!branch.type) {
            setType(false)
            return false
        }
        else {

            setType(true)
            return (true)
        }
    }

    const checkLevelIdValidation = () => {
        if (props.type) {
            if (!branch.levelId) {
                setlevelIdValidation(false)
                return false
            }
            else {
                setlevelIdValidation(true)
                return (true)
            }
        } else {
            setlevelIdValidation(true)
            return true
        }
    }

    // Hub Create Function 
    const createHub = () => {
        //Check Type if it is true then go to next Conditions when All conditions true Hit API "Create HUb"
        if (checkType()) {
            if (checkLevelIdValidation()) {
                if (MacAddress()) {
                    if (checkName()) {
                        let state1 = { ...state }
                        state1.loginLoader = true;
                        setState(state1)
                        if (props.data && props?.type) {
                            return hubUpdate(branch, branch._id).then(async res => {
                                let result = res
                                if (result) {
                                    props.onChange(result, "update")
                                    if (!result.statusCode) {
                                        setResponse(branch)
                                        state1.loginLoader = false;
                                        setState(state1)
                                    }
                                    else {
                                        state1.loginLoader = false;
                                    }
                                }
                            })
                        } else {
                            return hubCreated(branch).then(async res => {
                                let result = res
                                if (result) {
                                    props.onChange(result)
                                    if (!result.statusCode) {
                                        setResponse(branch)
                                        state1.loginLoader = false;
                                        setState(state1)
                                    }
                                    else {
                                        state1.loginLoader = false;
                                    }
                                }
                            })
                        }
                    }
                } else {
                    setName(true)
                }
            } else {
                set_id(true)
                setName(true)
            }
        } else {
            set_id(true)
            setName(true)
            setlevelIdValidation(true)
        }
    }


    return (
        <>
            <div className='d-flex overflow-hidden pb-4'>
            </div>

            <div className='px-4 pb-2'>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <Select onEnter={() => { createHub() }} dropDownHeight="100px" name="type" label="Type*" defaultValue={{ value: branch.type }} onClick message={{ msg: "Please enter your hub name", status: !type }} options={typeList} onChange={(e) => onChangeHandler(e)} />
                    </div>
                </div>
                {!levelId || props.type === "direct" ?
                    <div className='row mt-5'>
                        <div className='col-12'>
                            <Select onEnter={() => createHub()} dropDownHeight="150px" name="levelId" label="Level Id*" defaultValue={{ value: branch.levelId }} onClick message={{ msg: "Please enter your level Id", status: !levelIdvalidation }} options={levelIdsList} onChange={(e) => onChangeHandler(e)} />
                        </div>
                    </div>
                    : null}
                <div className='row mt-5'>
                    <div className='col-12'>
                        <Input onEnter={() => { createHub() }} name="_id" label="Mac Address*" defaultValue={branch._id} message={{ msg: "Please enter your Mac Address", status: !_id }} onChange={(e) => onChangeHandler(e)} />
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-12'>
                        <Input onEnter={() => { createHub() }} name="name" label="Name*" defaultValue={branch.name} message={{ msg: "Please enter your hub name", status: !name }} onChange={(e) => onChangeHandler(e)} />
                    </div>
                </div>
                {state.loginLoader ?
                    <div className='d-flex justify-content-center' style={{ height: "80px" }}>
                        <InlineLoader />
                    </div> :
                    <ButtonContainer className="mt-5">
                        <Button buttonResult={() => createHub()} >Save</Button>
                    </ButtonContainer>
                }
            </div>


            {/* </div> */}

        </>
    )
}
const mapStateToProps = (state) => ({
    userInfo: state.userInfo,
    configration: state.configration,
    organization: state.organization,

});
export default connect(mapStateToProps, null)(AddHubForm)
