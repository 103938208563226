import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import "./connectionIdentifier.scss"

function ComponentIdentifier(props) {
    const [night, setNight] = useState(false)
    const [config, setConfig] = useState({
        value: 0,
    })
    const [events, setEvents] = useState({
        Status: {},
        Connection: {}
    })

    // useEffect(() => {
    //     if (props?.config) {
    //         let config1 = { ...config }
    //         if ("strengthLevel" in props.config && props.config?.strengthLevel) {
    //             config1.strengthLevel = [...props.config?.strengthLevel]
    //         }
    //         setConfig(config1)
    //     }
    // }, [props.config])

    useEffect(async () => {
        let events1 = { ...events }
        let hubId = props?.config?.hubId
        let deviceId = props?.config?.deviceId
        let Events = props?.lastEvent?.lastEvent?.devices
        for(const e of Events){
            if(e._id === deviceId){
                for(const eve of e.events){
                    if(eve.deviceId === hubId){
                        events1.Connection = eve
                        setEvents(events1)
                    }
                }
            }
        }
    }, [props.lastEvent, props.config.deviceId])

    useEffect(() => {
        if (events?.Connection) {
            let config1 = { ...config }
            if (events.Connection.value !== null) {
                config1.value = events.Connection.value
            } else {
                config1.value = 0
            }
            setConfig(config1)
        }
    }, [events])
  return (
    <div className={'w-100 h-100 my-wifi '+(config.value?"on":"off")}></div>
  )
}

const mapStateToProps = (state) => ({
    lastEvent: state.lastEvent,
});
export default connect(mapStateToProps, null)(ComponentIdentifier)