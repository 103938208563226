import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './machineATM.scss'

function MachineATM(props) {
  let [value, setValue] = useState(false)
  const [events, setEvents] = useState({
    Status: {},
    Connection: {}
  })
  useEffect(async () => {
    let events1 = { ...events }
    let hubId = props?.config?.deviceId?.split("_")
    if (hubId) {
      hubId = hubId[0] + "_" + hubId[1]
    }
    let Events = props?.eventReducer?.deviceEvents

    if (Events.deviceId === props?.config?.deviceId) {
      events1.Status = Events
      setEvents(events1)
    } else if (Events.deviceId === hubId) {
      events1.Connection = Events
      setEvents(events1)
    }
  }, [props.eventReducer.deviceEvents])

  useEffect(() => {
    if (props?.config?.param in events?.Status) {
      if (events.Status[props.config.param]) {
        setValue(true)
      } else {
        setValue(false)
      }
    }
  }, [events.Status])

  let nightMode = false
  if ("nightMode" in props) {
    nightMode = props.nightMode;
  }
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" version="1.1"
    //   viewBox="-500 0 2432.95 1426.05"
    //   xmlnsXlink="http://www.w3.org/1999/xlink"
    //   x={"location" in props ? props.location.x : "0%"}
    //   y={"location" in props ? props.location.y : "0%"}
    //   width={"size" in props ? props.size.w : "100%"}
    //   height={"size" in props ? props.size.h : "100%"}
    // >
    //   <g>
    //     <g className={value ? "motorVibrate" : ""}>
    //       <g className="rodeRotate1">
    //         <path className="motorAnime17 rodeRotate" style={{ animationPlayState: value ? "running" : "paused" }} d="M1306.64 1339.8l74.85 0c0,20.67 -16.75,37.42 -37.42,37.42 -20.67,0 -37.42,-16.76 -37.42,-37.42zm37.42 -49.12c-27.13,0 -49.12,21.99 -49.12,49.12 0,27.13 21.99,49.12 49.12,49.12 27.13,0 49.12,-21.99 49.12,-49.12 0,-27.13 -21.99,-49.12 -49.12,-49.12z" />
    //       </g>
    //       <path className="motorAnime3" d="M599.23 315.32c25.44,12.39 56.64,31.73 82.45,47.15 27,16.13 53.52,31.32 80.71,46.37 14.6,-4.74 185.88,-105.05 210.32,-120.27l-172.22 -99.12 -201.26 125.87z" />
    //       <rect className="motorAnime6 rodeCut" style={{ animationPlayState: value ? "running" : "paused" }} width="104.66" height="9.5" rx="4.75" ry="4.75" />
    //     </g>
    //   </g>
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" version="1.1" viewBox="0 0 12912.42 19648.75" xmlnsXlink="http://www.w3.org/1999/xlink">
     <g>
      <path className="machine_ATMfil0" d="M1561.4 18353.44l0 -369.11c-973.14,178.52 -1561.4,410.86 -1561.4,664.9 0,1332.7 12912.42,1332.7 12912.42,0 0,-249.95 -569.46,-478.89 -1514.65,-656.22l0 360.44c-1831.23,0 -6440.06,0 -9836.37,0z"/>
      <polygon className="machine_ATMfil1" points="11385.15,18507.46 11385.15,2604.75 11385.15,719.73 11385.15,0 1565.9,0 1565.9,18507.46 "/>
      <polygon className="machine_ATMfil2" points="10554.77,18434.15 10554.77,2531.45 2432.05,2531.45 2432.05,18434.15 "/>
      <polygon className="machine_ATMfil3" points="10541.03,729.5 2418.32,729.5 2418.32,2614.52 10541.03,2614.52 "/>
      <polygon className="machine_ATMfil4" points="10349.14,913.88 2594.83,913.88 2594.83,2439.04 10349.14,2439.04 "/>
      <path className="machine_ATMfil5" d="M4851.14 1633.62l-2082.09 0c-49.48,0 -89.97,40.48 -89.97,89.97 0,49.48 40.48,89.97 89.97,89.97l2082.09 0c49.48,0 89.97,-40.48 89.97,-89.97 0,-49.48 -40.48,-89.97 -89.97,-89.97z"/>
      <path className="machine_ATMfil5" d="M8117.86 1637.82c-49.48,0 -89.97,40.48 -89.97,89.97 0,49.48 40.48,89.97 89.97,89.97l2082.09 0c49.48,0 89.97,-40.48 89.97,-89.97 0,-49.48 -40.48,-89.97 -89.97,-89.97l-2082.09 0z"/>
      <text x="5484.01" y="2039.31"  className="machine_ATMfil6 machine_ATMfnt0">ATM</text>
      <polygon className="machine_ATMfil7" points="3444.63,8286.9 9521.15,8286.9 9521.15,3883.47 3444.63,3883.47 "/>
      <polygon className="machine_ATMfil5" points="8098.01,4547.18 8098.01,5061.28 9151.9,5061.28 9151.9,4547.18 "/>
      <path className="machine_ATMfil8" d="M4311.17 8867.94c-54.05,0 -107.87,-0 -161.68,-0.05 -53.93,-0.05 -107.74,0 -161.56,0 -1.2,0 -2.39,0.98 -2.76,2.23 -7.42,25.3 -14.93,50.67 -22.29,75.91 -7.37,25.26 -14.9,50.63 -22.29,75.85 -0.39,1.33 0.24,2.34 1.56,2.34 55.97,0 112.06,0.03 168.15,0.05 56.09,0.03 112.18,0.06 168.39,0.11 1.19,0 2.6,-0.96 2.87,-2.29 5.23,-25.23 10.49,-50.65 15.7,-75.91 5.21,-25.23 10.45,-50.63 15.7,-75.96 0.26,-1.27 -0.6,-2.29 -1.8,-2.29z"/>
      <polygon className="machine_ATMfil5" points="6652,9361.48 6356.39,8577.49 3815.89,8577.49 3522.43,9363.63 "/>
      <polygon className="machine_ATMfil8" points="5318.44,8824.36 6149.57,8824.36 6134.15,8676.56 5303.03,8676.56 "/>
      <path className="machine_ATMfil0" d="M2701.94 9721.71l7574.35 0 -932.46 -1572.81 -5709.44 0 -932.46 1572.81zm3660.68 -1149.22l295.6 784 -3129.57 2.14 293.46 -786.14 2540.5 0z"/>
      <polygon className="machine_ATMfil9" points="3634.4,8148.91 3634.4,4011.49 2701.94,3209.83 2701.94,9721.71 "/>
      <polygon className="machine_ATMfil10" points="3634.4,4011.49 9343.84,4011.49 10276.29,3209.83 2701.94,3209.83 "/>
      <polygon className="machine_ATMfil5" points="3794.4,6776.37 4017.17,6776.37 4017.17,6553.6 3794.4,6553.6 "/>
      <polygon className="machine_ATMfil5" points="4017.17,6829.92 3794.4,6829.92 3794.4,7052.7 4017.17,7052.7 "/>
      <polygon className="machine_ATMfil5" points="4019.31,7119.1 3796.54,7119.1 3796.54,7341.88 4019.31,7341.88 "/>
      <polygon className="machine_ATMfil5" points="4018.25,7409.39 3795.48,7409.39 3795.48,7632.17 4018.25,7632.17 "/>
      <polygon className="machine_ATMfil8" points="4050.62,8670.49 4003.28,8824.65 4324.83,8827.04 4359.11,8672.84 "/>
      <polygon className="machine_ATMfil8" points="4277.02,9057.48 3937.24,9057.31 3889.66,9211.36 4244.54,9213.91 "/>
      <polygon className="machine_ATMfil8" points="4337.42,9059.82 4308.17,9211.74 4666.16,9212.01 4682.22,9060.04 "/>
      <polygon className="machine_ATMfil8" points="4347.6,9019.44 4684.14,9019.61 4702.84,8865.35 4377.57,8863.01 "/>
      <polygon className="machine_ATMfil8" points="4384.4,8827.04 4704.64,8827.15 4723.21,8672.84 4414.6,8670.49 "/>
      <polygon className="machine_ATMfil8" points="4764.2,8827.15 5084.44,8827.2 5087.44,8672.84 4778.47,8670.49 "/>
      <polygon className="machine_ATMfil8" points="5083.12,9057.97 4740.71,9060.09 4726.93,9212.12 5082.4,9214.62 "/>
      <polygon className="machine_ATMfil8" points="5083.36,9019.82 5086.6,8865.46 4760.97,8863.12 4744.55,9017.37 "/>
      <polygon className="machine_ATMfil5" points="7667.26,6795.65 7890.03,6795.65 7890.03,6572.88 7667.26,6572.88 "/>
      <polygon className="machine_ATMfil5" points="7888.43,6847.06 7665.66,6847.06 7665.66,7069.83 7888.43,7069.83 "/>
      <polygon className="machine_ATMfil5" points="7887.89,7126.6 7665.12,7126.6 7665.12,7349.38 7887.89,7349.38 "/>
      <polygon className="machine_ATMfil5" points="7887.89,7413.63 7665.12,7413.63 7665.12,7636.41 7887.89,7636.41 "/>
      <polygon className="machine_ATMfil5" points="8095.67,6135.89 8095.67,6804.22 9158.13,6804.22 9158.13,6135.89 "/>
      <polygon className="machine_ATMfil5" points="9160.55,5639.56 8098.08,5639.56 8098.08,5853.77 9160.55,5853.77 "/>
      <polygon className="machine_ATMfil8" points="5341.42,9039.56 5356.84,9187.36 6187.96,9187.36 6172.55,9039.56 "/>
      <polygon className="machine_ATMfil8" points="5340.72,9002.07 6171.85,9002.07 6156.44,8854.27 5325.31,8854.27 "/>
      <rect className="machine_ATMfil5 machine_ATMstr0" x="4165.08" y="4704.89" width="3358.77" height="2964.63"/>
      <rect fill={value?"#AFE7F6":"#4d4d4d"} className="machine_ATMstr0" x="4247.65" y="4779.64" width="3193.64" height="2815.13"/>
      <polygon className="machine_ATMfil8" points="3993.54,8850.95 3943.41,9021.74 4283.92,9024.39 4320.21,8853.54 "/>
      <polygon className="machine_ATMfil4" points="8132.63,6178.76 8132.63,6761.35 9114.77,6761.35 9114.77,6178.76 "/>
      <polygon className="machine_ATMfil4" points="8124.06,4575.23 8124.06,5033.24 9123.34,5033.24 9123.34,4575.23 "/>
      <path className="machine_ATMfil12" d="M5477.6 8947.49l541.95 0c25.99,0 21.98,-39.63 -4.13,-39.63l-541.95 0c-25.98,0 -21.97,39.63 4.13,39.63z"/>
      <path className="machine_ATMfil13" d="M6033.95 9106l-541.95 0c-25.99,0 -21.98,39.63 4.13,39.63l541.95 0c25.99,0 21.98,-39.63 -4.13,-39.63z"/>
      <path className="machine_ATMfil14" d="M5462.39 8770.77l541.95 0c10.9,0 18.88,-8.91 17.75,-19.81 -1.14,-10.9 -10.98,-19.82 -21.88,-19.82l-541.95 0c-10.9,0 -18.88,8.91 -17.74,19.81 1.14,10.9 10.98,19.82 21.88,19.82z"/>
      <path className="machine_ATMfil1" d="M9688.32 18469.34l0 -6700.11 -6477.63 0 0 6700.11c2044.61,501.26 4195.38,538.22 6477.63,0z"/>
      <polygon className="machine_ATMfil5" points="4924.35,10659.25 8034.63,10659.25 8034.63,10008.06 4924.35,10008.06 "/>
      <polygon className="machine_ATMfil4" points="5091.43,10477.21 7867.55,10477.21 7867.55,10228.73 5091.43,10228.73 "/>
      {value && 
        <polygon className="machine_ATMfil13" points="9114.77,5689.1 8146.56,5689.1 8146.56,5800.49 9114.77,5800.49 "/>
      }
      <path className="machine_ATMfil5" d="M6303.84 12420.41c0,127.77 103.58,231.34 231.34,231.34 127.77,0 231.34,-103.58 231.34,-231.34 0,-127.77 -103.58,-231.34 -231.34,-231.34 -127.77,0 -231.34,103.58 -231.34,231.34z"/>
      <polygon className="machine_ATMfil9" points="9343.84,4011.49 9343.84,8148.91 10276.29,9721.71 10276.29,3209.83 "/>
      {value && <>
        <text x="36%" y="28%"  className="machine_ATMfil20">machine</text>
        <text x="36%" y="30%"  className="machine_ATMfil20">is ready...</text>
      </>
}
     </g>
    </svg>
  )
}
const mapStateToProps = (state) => ({
  eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(MachineATM)
