import React, { useEffect, useState } from 'react'
import { Button, ButtonContainer } from '../../arraybutton/arraybutton'

function Active(props) {
    const [active, setActive] = useState(false)
    useEffect(() => {
        if ('defaultValue' in props) {
            setActive(props.defaultValue)
        }
    }, [props.defaultValue])
    const onChangeHandler = (e) => {
        let value = e
        let name = 'live'
        setActive(value)
        if ("onChange" in props) {
            props.onChange({ name: name, target: { value: value } })
        }
    }
  return (
    <div className='d-flex justify-content-between align-items-center'>
        <div>Dashboard Live</div>
        <div className="my-3">
            <ButtonContainer>
                <Button active={active} buttonResult={()=>onChangeHandler(true)}>Active</Button>
                <Button active={!active} buttonResult={()=>onChangeHandler(false)}>Deactive</Button>
            </ButtonContainer>
        </div>
    </div>
  )
}

export default Active