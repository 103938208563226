// All rights to the entire source code, it's associated documentation, and design are the property of Linked Things (Pvt) Ltd.
// No part of the above can be copied, saved, or used, in any manner whatsoever by any entity or individual unless explicitly authorized to do so.
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import "./guage.scss"
import store from "../../../services/utilities/store";


function Guage(props) {
    const [state, setState] = useState({
        color: {
            green: "rgb(157, 212, 157)",
            yellow: "rgb(230, 230, 0)",
            orange: "rgb(220, 102, 0)",
            red: "rgb(200, 0, 0)",
            purple: "rgb(100, 0, 100)",
        },
        config: {
            colorScale: [{ color: 'yellow', position: 0 }, { color: 'green', position: 0 }, { color: 'red', position: 100 }],
            gradient: false,
            minValue: 0,
            maxValue: 100,
            value: 0,
            label: '',
        },
        finalConfig: { color: null },
        data: null,
    })
    const [events, setEvents] = useState({
        Status: {},
        Connection: {}
    })
    useEffect(() => {
        let state1 = { ...state }, conicColor = "";
        if ("config" in props) {
            if (props.config) {
                // if("colorScale" in props.config){
                //     state1.config.colorScale = props.config.colorScale;
                // }
                // if("gradient" in props.config){
                //     state1.config.gradient = props.config.gradient;
                // }
                if ("label" in props.config) {
                    state1.config.label = props.config.label;
                }
                if ("min" in props.config) {
                    state1.config.minValue = parseInt(props.config.min);
                }
                if ("max" in props.config) {
                    state1.config.maxValue = parseInt(props.config.max);
                }
                if ("lowest" in props.config) {
                    state1.config["lowestValue"] = parseInt(props.config.lowest);
                    state1.config.colorScale[1].position = state1.config["lowestValue"]
                }
                if ("highest" in props.config) {
                    state1.config["highestValue"] = parseInt(props.config.highest);
                    state1.config.colorScale[2].position = state1.config["highestValue"]
                } else {
                    state1.config.colorScale[2].position = state1.config.maxValue
                }
            }
        }
        if (state1.config.colorScale) {
            state1.config.colorScale.map((e, key) => {
                if (key > 0) {
                    if (!state1.config.gradient) {
                        conicColor += ", " + state1.color[state1.config.colorScale[key - 1].color] + " " + (((e.position / state1.config.maxValue) * 100) * 180) / 100 + "deg";
                    }
                    conicColor += ", "
                }
                if ("color" in e) {
                    // state1.config.colorScale[key].color = state1.color[e.color]
                    conicColor += state1.color[e.color];
                }
                if ("position" in e) {
                    // state1.config.colorScale[key].position = (((e.position / state1.config.maxValue) * 100) * 180) / 100
                    conicColor += " " + (((e.position / state1.config.maxValue) * 100) * 180) / 100 + "deg";

                }
            })
            conicColor = "conic-gradient(from -90deg at 50% 100%," + conicColor + ")"
            state1.finalConfig["color"] = conicColor;
        }
        setState(state1);
    }, [props.config])
    let nightMode = false
    if ("nightMode" in props) {
        nightMode = props.nightMode;
    }

    useEffect(() => {
        let Events = props?.lastEvent?.lastEvent?.devices
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")

        // Events?.map((item, index) => {
        //     if (item.events[0].deviceId === props.device._id) {
        //         events1.Status = item.events[0]
        //         setEvents(events1)
        //     }
        // })
        Events?.map((item, index) => {
            if (item._id === props?.config?.deviceId) {
                events1.Status = item.events[0]
                setEvents(events1)
            } else if (item._id === hubId) {
                events1.Connection = item.events[1]
                setEvents(events1)
            }
        })
    }, [props.lastEvent])

    // MQTT Broker
    // useEffect(async () => {
    //     let hubId = props?.config?.deviceId?.split("_")
    //     let Events = props?.eventReducer?.deviceEvents
    //     let events1 = { ...props.lastEvent.lastEvent }
    //     if (hubId) {
    //         hubId = hubId[0] + "_" + hubId[1]
    //     }
    //     events1?.devices?.map((item, index) => {
    //         if (Events.deviceId === hubId) {
    //             item.events[0] = Events
    //             return
    //         }
    //     })
    //     store.dispatch({
    //         type: "Get Events",
    //         payload: events1
    //     })
    // }, [props.eventReducer.deviceEvents])

    // useEffect(async () => {
    //     let events1 = { ...events }
    //     let hubId = props?.config?.deviceId?.split("_")
    //     if (hubId) {
    //         hubId = hubId[0] + "_" + hubId[1]
    //     }
    //     let Events = props?.eventReducer?.deviceEvents

    //     if (Events.deviceId === props?.config?.deviceId) {
    //         events1.Status = Events
    //         setEvents(events1)
    //     } else if (Events.deviceId === hubId) {
    //         events1.Connection = Events
    //         setEvents(events1)
    //     }
    // }, [props.eventReducer.deviceEvents])

    useEffect(() => {
        if (props?.config?.param in events?.Status) {
            let state1 = { ...state }
            if (events.Status[props.config.param] !== null) {
                state1.config.value = events.Status[props.config.param]
            } else {
                state1.config.value = 0
            }
            setState(state1)
        }
    }, [events.Status])

    return state.finalConfig ? (
        <div
            className="graph-guage"
            style={{
                backgroundImage: state.finalConfig.color,
                filter: nightMode ? "brightness(3)" : ""
            }}
        >
            <div className="mask"
                style={nightMode ? "maskBg" in props.config ? { backgroundColor: props.config.maskBg } : { backgroundColor: "rgb(3,3,3)" } : "maskBg" in props.config ? { backgroundColor: props.config.maskBg } : null}
            >
                <svg
                    className="guageArrow"
                    style={{ transform: "translateX(-50%) rotate(calc(" + ((((state.config.value > state.config.maxValue ? state.config.maxValue : state.config.value) / state.config.maxValue) * 100) * 180) / 100 + "deg - 90deg))" }}
                    xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" version="1.1"
                    viewBox="0 0 12.96 110.84"
                    xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g>
                        <polygon fill={nightMode ? "white" : "black"} style={{ transition: "all 0.3s" }} points="0,96.95 6.48,102.26 12.96,96.95 6.48,-0 " />
                        <circle fill="red" cx="6.48" cy="104.36" r="6.48" />
                    </g>
                </svg>
                <div className="label text-left" style={{ color: nightMode ? "white" : "black", left: "1%", right: "unset", top: "90%", }}>{state.config.minValue}</div>
                <div className="label text-right" style={{ color: nightMode ? "white" : "black", left: "unset", right: "1%", top: "90%", }}>{state.config.maxValue}</div>
                <div className="label" style={{ color: nightMode ? "white" : "black" }}>{state.config.label} {state.config.value.toFixed(2)} {"unit" in props.config ? props.config.unit : " "}</div>
            </div>
        </div>

    ) : null;
}
const mapStateToProps = (state) => ({
    eventReducer: state.eventReducer,
    lastEvent: state.lastEvent,

});
export default connect(mapStateToProps, null)(Guage)