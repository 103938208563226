import React, { useEffect, useState } from 'react'
import Card, { CardHeader, Heading, InnerCard } from '../../components/card/card'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import { allRulesGet, ruleDelete, updateRules } from '../../services/utilities/events'
import { hslToHex } from '../../functions/functions'
import CardLoader from '../../components/loader/cardLoader'
import { ResponseMessageLocal } from '../../services/config/generic'

import { update } from 'lodash'
import { Collapse } from '@material-ui/core'
import Mypopup from '../../components/popup/mypopup'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
const RuleEngineList = (props) => {
    let { pathname, search } = useLocation();
    // let levelId = window.location.hash
    let levelId = pathname
    levelId = levelId.split("/")
    levelId = levelId[levelId.length - 1]
    let orgId = levelId
    if (orgId.match("_")) {
        let orgIdList = orgId.split("_")
        orgId = orgIdList[0]
    }

    const [loader, setLoader] = useState(false)
    const [rulesList, setRulesList] = useState([])
    const [confirmPopup, setConfirmPopup] = useState(false)
    const [messagePopup, setMessagePopup] = useState(false)
    const [collapse, setCollapse] = useState({ key: 0, state: false })
    useEffect(async () => {
        setLoader(true)
        let result1 = []
        let response = await allRulesGet(orgId)
        let result = []
        if (response) {
            response.map(row => {
                result.push({ ...row, collapse: false })
            })
            result.map(res => {
                if (res.orgId === orgId) {
                    result1.push(res)
                }
            })
        }
        setRulesList(result1)
        setLoader(false)
    }, [])
    const onChangeHandler = async (e, enabled, key) => {
        let data = { ...e }
        let rulesList1 = [...rulesList]
        data.enabled = enabled
        return await updateRules(data._id, data).then(async res => {
            let result = res
            if (result) {
                if (!result.statusCode) {
                    rulesList1[key] = result
                    setRulesList(rulesList1)
                }
                else {
                    let message = res.message
                    setMessagePopup(["Failure!", message, "danger"])
                }
            }
        })
    }
    const updateRule = (data) => {
        props.history.push({ pathname: "/rule-engine/new/" + levelId, state: data })
    }
    const collapseHandler = (key) => {
        // let collapse1 = { ...collapse }
        // collapse1.key = key
        // collapse1.state = !collapse.state
        // setCollapse(collapse1)
        let data = [...rulesList]
        data.map((rowdata, index) => {
            if (index === key) {
                rowdata.collapse = !rowdata.collapse
            } else {
                rowdata.collapse = false
            }
        })
        setRulesList(data)
    }

    const deleteRule = async () => {
        let key = confirmPopup.key
        let id = confirmPopup.data
        let data1 = [...rulesList]
        let result = await ruleDelete(id)
        if (result.statusCode === 200) {
            data1.splice(key, 1)
        }
        setRulesList(data1)
        // setFilterList(data1)
        // setUserUpdate(true)
        setConfirmPopup(null)
    }

    return (
        <>
            <div className="p-3" style={{ display: "block" }}>
                {/* <ReactReducerAction update={userUpdate} onUserUpdate={(e) => getUsers(e)} /> */}
                <Card blur className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                    <CardHeader className="d-flex justify-content-between">
                        <Heading>
                            Rule Management
                        </Heading>
                        <ButtonContainer>
                            <Button buttonResult={() => props.history.push({ pathname: "/rule-engine/new/" + levelId })}>Add Rule</Button>
                        </ButtonContainer>
                    </CardHeader>
                </Card>

                {!loader ? rulesList.length ? rulesList.map((b, key) =>

                    <Card key={key} className={"mb-1"} style={{ backgroundColor: hslToHex(200, 100, ((100 / b.colorLevel))) }}>
                        <CardHeader className="row align-items-center justify-content-between">
                            <button title={"rules"} className='btn btn-light rounded-circle-px' onClick={() => collapseHandler(key)}>
                                <i className='fa fa-sm fa-angle-right' style={{ transform: "rotate(" + (b.collapse ? 270 : 90) + "deg)", transition: 'all 0.3s' }}></i>
                            </button>
                            <div className='col text-truncate'>
                                <Heading size={2}>
                                    {b.rule[0].name}
                                </Heading>
                                <Heading size={4}>{b.rule[0]?.description ? b.rule[0]?.description : "Null"}</Heading>
                            </div>
                            {/* <div className='col text-truncate'>
                        <span>Email:</span> <span>{b.email}</span>
                    </div> */}
                            {/* <div className='col text-truncate'>
                        <div className={"d-inline py-1 px-3 text-white rounded-circle-px " + (b.isCompleted ? " success-gradient" : " danger-gradient")}  > {b.isCompleted ? "compeleted" : "in progress"}</div>
                    </div> */}
                            {/* <ButtonContainer>
                        <Button active={b.registered ? true : false}>Activate</Button>
                        <Button active={!b.registered ? true : false}>Deactivate</Button>
                    </ButtonContainer> */}
                            {/* <ButtonContainer color="success" className="mr-1">
                        <Button buttonResult={() => userDetail(b)}>Detail</Button>
                    </ButtonContainer> */}
                            <ButtonContainer>
                                <Button active={b?.enabled} buttonResult={() => onChangeHandler(b, true, key)}>Enabled</Button>
                                <Button active={!b?.enabled} buttonResult={() => onChangeHandler(b, false, key)}>Disabled</Button>
                            </ButtonContainer>



                            <ButtonContainer flat="light" className="mr-2 p-2">
                                <Button color="text-primary" buttonResult={() => updateRule(b)} ><i className='fa fa-pen'></i></Button>
                                <Button color="text-danger" buttonResult={() => { setConfirmPopup({ data: b._id, key }) }} ><i className='fa fa-trash'></i></Button>
                            </ButtonContainer>
                        </CardHeader>
                        {/* {b.collapse && b?.child?.length ? <CardBody className="p-3">
                        {checkChild(b.child, [key])}
                    </CardBody> : null} */}
                        {/* {collapse.key === key && collapse.state ? */}
                        {b.collapse ?
                            <Card>
                                <div className='row m-3 px-3'>
                                    <div className='col text-truncate'>
                                        <Heading size={2}>
                                            Name
                                        </Heading>
                                    </div>
                                    <div className='col text-truncate'>
                                        <Heading size={2}>
                                            Device Type
                                        </Heading>
                                    </div>
                                    <div className='col text-truncate'>
                                        <Heading size={2}>
                                            Parameter Value
                                        </Heading>
                                    </div>
                                    <div className='col text-truncate'>
                                        <Heading size={2}>
                                            Start Date
                                        </Heading>
                                    </div>
                                    <div className='col text-truncate'>
                                        <Heading size={2}>
                                            End Date
                                        </Heading>
                                    </div>
                                </div>
                                {b.rule.map((rule, key) =>
                                    <InnerCard className="row m-3 px-3">
                                        <div className='col text-truncate'>
                                            <p size={2}>
                                                {rule.name}
                                            </p>
                                        </div>
                                        <div className='col text-truncate'>
                                            <p size={2}>
                                                {rule.deviceType}
                                            </p>
                                        </div>
                                        <div className='col text-truncate'>
                                            <p size={2}>
                                                {rule.param}
                                            </p>
                                        </div>
                                        <div className='col text-truncate'>
                                            <p size={2}>
                                                {rule.startDate}
                                            </p>
                                        </div>
                                        <div className='col text-truncate'>
                                            <p size={2}>
                                                {rule.endDate}
                                            </p>
                                        </div>
                                    </InnerCard>
                                )}
                            </Card>
                            : null}
                    </Card>
                ) : <Heading size={2}> Rules not found </Heading>
                    : <CardLoader />}
            </div>

            {
                confirmPopup ? <Mypopup title={"Confirm!"} onClose={() => { setConfirmPopup(null) }}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={(e) => deleteRule(confirmPopup)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => { setConfirmPopup(null) }}>No</button>
                    </div>
                </Mypopup> : null
            }
            {
                messagePopup ? <Mypopup title={messagePopup[0]} type={messagePopup[2]} onClose={(e) => {
                    setMessagePopup(null);

                }}>
                    <p className="p-3"> {messagePopup[1]}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            messagePopup(null)
                        }}>Ok</button>
                    </div>
                </Mypopup >
                    : null
            }
        </>
    )
}

export default RuleEngineList