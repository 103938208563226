import React, { useEffect, useState } from 'react'
import Input from '../../input/input'
import { Button, ButtonContainer } from '../../arraybutton/arraybutton'
import Select from '../../select/select'

const Size = (props) => {

    const [size, setSize] = useState({})
    const onChangeHandler = (e, type) => {
        let data = { ...size }
        data[e.name] = e.target.value
        setSize(data)
        if ("onChange" in props) {
            props.onChange({ name: type, target: { value: data[type] } })
        }
    }

    useEffect(() => {
        if (props?.defaultValue) {
            setSize(props.defaultValue)
        }
    }, [props.defaultValue])
    return (
        <>
            <div className='propertiesLabels'>Sizing</div>
            {
                props.styleTag.includes("fontSize") ?
                    <Select
                        className="mb-3"
                        dropDownHeight="100px"
                        name="fontSize"
                        label="Font Size"
                        options={[
                            {label:'14', value: '14'},
                            {label:'16', value: '16'},
                            {label:'20', value: '20'},
                            {label:'24', value: '24'},
                            {label:'28', value: '28'},
                            {label:'32', value: '32'},
                            {label:'38', value: '38'},
                            {label:'42', value: '42'},
                            {label:'50', value: '50'},
                            {label:'60', value: '60'},
                        ]}
                        defaultValue={{value: size?.fontSize?parseInt(size?.fontSize):""}}
                        onChange={(e) => onChangeHandler(e, "fontSize")}
                    />
                // <Input
                    //     className="mb-3"
                    //     name="fontSize"
                    //     label="Font Size"
                    //     type="number"
                    //     defaultValue={size?.fontSize?parseInt(size?.fontSize):""}
                    //     onChange={(e) => onChangeHandler(e, "fontSize")}
                    // />
                    : null
            }
            {
                props.styleTag.includes("width") || props.styleTag.includes("size") ?
                    <Input
                        className="mb-3"
                        name="width"
                        label={"Width"}
                        type="range"
                        defaultValue={size?.width?parseInt(size?.width):""}
                        onChange={(e) => onChangeHandler(e, "width")}
                    />
                    : null
            }
            {
                props.styleTag.includes("width") ?
                    <Input
                        className="mb-3"
                        name="height"
                        label="Height"
                        type="number"
                        defaultValue={size?.height?parseInt(size?.height):""}
                        onChange={(e) => onChangeHandler(e, "height")}
                    />
                    : null
            }
            {
                props.styleTag.includes("thickness") ?
                <div className='mb-3'>
                <small className='mr-1'>Thickness</small>
                    <ButtonContainer>
                        <Button buttonResult={() => onChangeHandler({name:"thickness",target:{value:1}},"thickness")} active={size?.thickness === "1"? true: false}>1x</Button>
                        <Button buttonResult={() => onChangeHandler({name:"thickness",target:{value:2}},"thickness")} active={size?.thickness === "2"? true: false}>2x</Button>
                        <Button buttonResult={() => onChangeHandler({name:"thickness",target:{value:3}},"thickness")} active={size?.thickness === "3"? true: false}>3x</Button>
                        <Button buttonResult={() => onChangeHandler({name:"thickness",target:{value:4}},"thickness")} active={size?.thickness === "4"? true: false}>4x</Button>
                    </ButtonContainer>
                </div>
                : null
            }
        </>
    )
}

export default Size