import { toJpeg } from "dom-to-image-more"

const GetThumbnail = async (element, orgId) => {
    let result = ""
    const dataUrl = await toJpeg(element, { quality: 0.1 })
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = dataUrl;
        const bodyElement = document.getElementsByClassName('dark-theme')
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const squareSize = 300

            const scaleFactor = Math.min(squareSize / img.width, squareSize / img.height);

            const newWidth = img.width * scaleFactor;
            const newHeight = img.height * scaleFactor;
            
            const maxSize = Math.max(newWidth, newHeight, squareSize);
            canvas.width = maxSize
            canvas.height = maxSize
            context.fillStyle = !bodyElement.length?'white':'';
            context.fillRect(0, 0, canvas.width, canvas.height);

            const offsetX = (squareSize - newWidth) / 2;
            const offsetY = (squareSize - newHeight) / 2;
            context.drawImage(img, offsetX, offsetY, newWidth, newHeight);
        
            result = canvas.toDataURL('image/webp');
            resolve(result)
        };
    })
}

export default GetThumbnail