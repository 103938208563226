export const configration = (state = null, action) => {

    switch (action.type) {
        case "Configration":
            return { ...state, configration: action.payload };
        default:
            return state
    }
}

