import { useEffect } from 'react'
import { GetChildUser } from '../utilities/events'
import { connect } from 'react-redux'
import store from '../utilities/store'

const ReactReducerAction = (props) => {
    useEffect(async () => {
        let result = null
        if (props.userList?.userList) {
            let res = props.userList?.userList
            result = res
        } else {
            result = await getUpdate()
        }
        if ("onUserUpdate" in props) {
            props.onUserUpdate(result)
        }
    }, [])
    useEffect(async () => {
        if (props.update) {
            let result = null
            result = await getUpdate()
            if ("onUserUpdate" in props) {
                props.onUserUpdate(result)
            }
        }
    }, [props.update])

    const getUpdate = async () => {
        let response = await GetChildUser()
        store.dispatch({
            type: "User_List",
            payload: response
        })
        return response
    }
    return null

}

const mapStateToProps = (state) => ({
    userList: state.userList,
});
export default connect(mapStateToProps, null)(ReactReducerAction)

