import "./card.scss"
import CardLoader from "../loader/cardLoader";
export default function Card(props) {
    let className = ("blur" in props ? "myCard-blur " : "myCard ") + (
        "theme" in props ? props.theme : "silver-gradient"
    );
    if ("className" in props) {
        className += (" " + props.className);
    }
    return (
        <div id={props?.id || null} className={className} style={props?.style}>
            {props.children}
        </div>
    );
}
export function CardHeader(props) {
    return (
        <>
            <div className={"className" in props ? "header " + props.className : "header"}>
                {props.children}
            </div>
            <div className="spleater"></div>
        </>
    );
}
export function Heading(props) {
    let color = ""
    if ("color" in props) {
        if (props.color) {
            color = "text-" + props.color
        }
    }
    return (
        <div style={"align" in props ? { textAlign: props.align } : null} className={"text-truncate " + color + ("size" in props ? " heading-" + props.size : " heading-1 ")}>
            {props.children}
        </div>
    );
}
export function InnerCard(props) {
    return (
        <div className={"className" in props ? "innerCard " + props.className : "innerCard"} style={"style" in props ? props.style : null}>
            {props.children}
        </div>
    );
}
export function CardBody(props) {
    let classes = "cardBody"
    let style = null
    if ('className' in props) {
        classes += " " + props.className;
    }
    if ("style" in props) {
        style = props.style;
    }
    if ('collapse' in props) {
        classes += props.collapse ? " collapse" : "";
    }
    const body = () => {
        return <div className={classes} style={style}>
            {props.children}
        </div>
    }
    if ("loader" in props) {
        if (props.loader) {
            return (
                <div className="split-t" style={'loaderHeight' in props ? { height: props.loaderHeight } : null}>
                    <CardLoader />
                </div>
            )

        }
        else {
            return body()

        }
    }
    else {
        return body()

    }
}