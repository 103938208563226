import React from 'react'
import "./analyzer.scss"

function Analyzer() {
  return (
      <svg  
      xmlns="http://www.w3.org/2000/svg"
      // preserveAspectRatio="none" 
      xmlSpace="preserve" 
      version="1.1"
      width="100%"
      height="100%"
      viewBox="0 0 307.28 307.28"
      xmlnsXlink="http://www.w3.org/1999/xlink">
          <g>
              <rect className="analyzer_fil0" width="307.28" height="307.28" rx="6.42" ry="6.42"/>
              <rect className="analyzer_fil1" x="4.75" y="17.63" width="297.79" height="272.01" rx="3.06" ry="2.79"/>
              <path className="analyzer_fil2" d="M10.02 53.99l130.36 0 0 5.51c0,0.79 0.65,1.44 1.44,1.44l23.65 0c0.79,0 1.44,-0.65 1.44,-1.44l0 -5.51 130.36 0 0 199.29 -287.25 0 0 -199.29z"/>
              <rect className="analyzer_fil3 analyzer_str0" x="34.61" y="64.89" width="238.07" height="177.49" rx="5.82" ry="4.34"/>
              <rect className="analyzer_fil2" x="143.23" y="37.26" width="20.83" height="20.83" rx="0.61" ry="0.61"/>
              <rect className="analyzer_fil4 analyzer_str0" x="40.33" y="260.16" width="52.64" height="21.85" rx="2.85" ry="1.18"/>
              <rect className="analyzer_fil4 analyzer_str0" x="98.32" y="260.16" width="52.64" height="21.85" rx="2.85" ry="1.18"/>
              <rect className="analyzer_fil4 analyzer_str0" x="156.31" y="260.16" width="52.64" height="21.85" rx="2.85" ry="1.18"/>
              <rect className="analyzer_fil4 analyzer_str0" x="214.31" y="260.16" width="52.64" height="21.85" rx="2.85" ry="1.18"/>
              <circle className="analyzer_fil5" cx="275.13" cy="262.99" r="2.83"/>
              <path className="analyzer_fil1" d="M146.42 40.38l1.18 0 5.36 0 0 3.31c-0.56,0.07 -1.06,0.32 -1.44,0.7 -0.44,0.44 -0.71,1.05 -0.71,1.72 0,0.67 0.27,1.28 0.71,1.72 0.38,0.38 0.88,0.63 1.44,0.7l-0 0.02 0 6.42 -6.55 0 0 -14.58zm7.12 0l1.44 0c3.23,0 5.88,2.64 5.88,5.88l0 0c0,3.23 -2.64,5.88 -5.88,5.88l-1.44 0 0 -3.59 -0 -0.02c0.56,-0.07 1.06,-0.32 1.44,-0.7 0.44,-0.44 0.71,-1.05 0.71,-1.72 0,-0.67 -0.27,-1.28 -0.71,-1.72 -0.38,-0.38 -0.88,-0.63 -1.44,-0.7l0 -3.31zm1.03 4.41c-0.34,-0.34 -0.8,-0.54 -1.32,-0.54 -0.51,0 -0.98,0.21 -1.32,0.54 -0.34,0.34 -0.54,0.8 -0.54,1.32 0,0.51 0.21,0.98 0.54,1.32 0.34,0.34 0.8,0.54 1.32,0.54 0.51,0 0.98,-0.21 1.32,-0.54 0.34,-0.34 0.54,-0.8 0.54,-1.32 0,-0.51 -0.21,-0.98 -0.54,-1.32z"/>
              <polygon className="analyzer_fil6" points="242.91,39.27 250.05,39.27 250.05,40.81 241.2,40.81 241.2,26.88 249.66,26.88 249.66,28.42 242.91,28.42 242.91,32.92 248.92,32.92 248.92,34.45 242.91,34.45 "/>
              <path className="analyzer_fil6" d="M260.35 40.81l-0.78 0 -3.51 -8.32c-0.48,-1.14 -0.97,-2.39 -1.46,-3.74l-0.08 0c0.1,1.94 0.15,3.71 0.15,5.32l0 6.74 -1.71 0 0 -13.93 2.45 0 3.28 7.88c0.37,0.9 0.79,1.99 1.24,3.26l0.05 0c0.5,-1.41 0.91,-2.5 1.24,-3.26l3.28 -7.88 2.45 0 0 13.93 -1.71 0 0 -6.74c0,-1.57 0.05,-3.35 0.15,-5.32l-0.08 0c-0.52,1.42 -1,2.67 -1.46,3.74l-3.51 8.32z"/>
              <path className="analyzer_fil6" d="M277.36 35.77c0,-0.58 -0.06,-1.09 -0.18,-1.55 -0.12,-0.45 -0.3,-0.85 -0.55,-1.17 -0.25,-0.32 -0.54,-0.57 -0.9,-0.75 -0.36,-0.17 -0.77,-0.26 -1.23,-0.26 -0.33,0 -0.64,0.05 -0.92,0.14 -0.27,0.09 -0.52,0.2 -0.75,0.34 -0.22,0.14 -0.42,0.29 -0.58,0.45 -0.16,0.16 -0.29,0.3 -0.39,0.42l0 5.18c0.34,0.35 0.73,0.63 1.17,0.83 0.44,0.2 0.91,0.31 1.41,0.31 0.31,0 0.63,-0.06 0.97,-0.17 0.34,-0.12 0.66,-0.32 0.95,-0.61 0.29,-0.3 0.53,-0.7 0.72,-1.21 0.19,-0.51 0.28,-1.15 0.28,-1.94zm1.69 -0.02c0,0.69 -0.07,1.29 -0.2,1.83 -0.14,0.53 -0.32,0.99 -0.54,1.39 -0.22,0.39 -0.48,0.73 -0.78,0.99 -0.3,0.27 -0.61,0.48 -0.93,0.65 -0.32,0.16 -0.64,0.28 -0.97,0.35 -0.33,0.07 -0.64,0.11 -0.93,0.11 -0.61,0 -1.13,-0.1 -1.58,-0.3 -0.44,-0.2 -0.85,-0.49 -1.2,-0.88l-0.03 0 0 5.76 -1.59 0 0 -14.74 1.22 0 0.34 1.12 0.03 0c0.09,-0.14 0.21,-0.27 0.35,-0.41 0.14,-0.13 0.33,-0.27 0.57,-0.42 0.24,-0.15 0.53,-0.28 0.86,-0.39 0.34,-0.11 0.7,-0.17 1.1,-0.17 0.56,0 1.09,0.09 1.61,0.28 0.52,0.19 0.97,0.49 1.37,0.9 0.39,0.41 0.71,0.93 0.95,1.57 0.24,0.64 0.36,1.42 0.36,2.34z"/>
              <path className="analyzer_fil6" d="M287.03 32.23l-0.12 0c-0.38,0 -0.76,0.04 -1.12,0.1 -0.37,0.07 -0.71,0.18 -1.04,0.32 -0.32,0.14 -0.61,0.32 -0.87,0.52 -0.26,0.21 -0.48,0.45 -0.65,0.74l0 6.89 -1.62 0 0 -9.9 1.26 0 0.31 1.57 0.03 0c0.16,-0.26 0.36,-0.49 0.59,-0.71 0.23,-0.22 0.49,-0.42 0.79,-0.58 0.29,-0.17 0.61,-0.3 0.96,-0.39 0.35,-0.09 0.71,-0.14 1.1,-0.14 0.07,0 0.14,0 0.21,0 0.07,0 0.14,0.01 0.19,0.02l0 1.56z"/>
              <path className="analyzer_fil6" d="M295.89 35.87c0,-0.61 -0.07,-1.16 -0.22,-1.63 -0.15,-0.48 -0.36,-0.88 -0.63,-1.21 -0.27,-0.33 -0.59,-0.58 -0.96,-0.75 -0.37,-0.17 -0.77,-0.26 -1.21,-0.26 -0.44,0 -0.84,0.09 -1.21,0.26 -0.37,0.17 -0.69,0.42 -0.96,0.75 -0.27,0.33 -0.48,0.74 -0.63,1.21 -0.15,0.48 -0.22,1.02 -0.22,1.63 0,0.6 0.07,1.14 0.22,1.62 0.15,0.48 0.36,0.88 0.63,1.21 0.27,0.33 0.59,0.58 0.96,0.75 0.37,0.17 0.78,0.26 1.21,0.26 0.44,0 0.84,-0.09 1.21,-0.26 0.37,-0.17 0.68,-0.42 0.95,-0.75 0.27,-0.33 0.48,-0.73 0.63,-1.21 0.15,-0.48 0.22,-1.02 0.22,-1.62zm1.69 0c0,0.78 -0.11,1.49 -0.33,2.12 -0.22,0.64 -0.54,1.19 -0.94,1.64 -0.41,0.46 -0.9,0.81 -1.48,1.06 -0.58,0.25 -1.23,0.37 -1.94,0.37 -0.74,0 -1.4,-0.12 -1.98,-0.37 -0.58,-0.25 -1.07,-0.6 -1.48,-1.06 -0.41,-0.45 -0.71,-1 -0.93,-1.64 -0.22,-0.64 -0.32,-1.34 -0.32,-2.12 0,-0.79 0.11,-1.5 0.33,-2.13 0.22,-0.64 0.54,-1.19 0.94,-1.65 0.41,-0.46 0.9,-0.81 1.48,-1.06 0.58,-0.25 1.23,-0.37 1.94,-0.37 0.73,0 1.39,0.12 1.97,0.37 0.59,0.25 1.08,0.6 1.48,1.06 0.4,0.46 0.71,1.01 0.93,1.65 0.22,0.64 0.32,1.35 0.32,2.13z"/>
              <path className="analyzer_fil6" d="M87.95 273.3l-1.01 0 0 -6.44c-0.24,0.23 -0.56,0.47 -0.96,0.7 -0.39,0.23 -0.75,0.41 -1.07,0.52l0 -0.98c0.57,-0.26 1.06,-0.59 1.48,-0.97 0.42,-0.38 0.72,-0.74 0.9,-1.1l0.65 0 0 8.27z"/>
              <path className="analyzer_fil6" d="M146.58 272.33l0 0.97 -5.45 0c-0.01,-0.24 0.03,-0.48 0.12,-0.7 0.14,-0.37 0.36,-0.74 0.67,-1.09 0.31,-0.36 0.75,-0.77 1.32,-1.24 0.89,-0.73 1.5,-1.31 1.81,-1.74 0.32,-0.43 0.48,-0.84 0.48,-1.22 0,-0.4 -0.14,-0.74 -0.43,-1.01 -0.29,-0.27 -0.66,-0.41 -1.13,-0.41 -0.49,0 -0.88,0.15 -1.17,0.44 -0.29,0.29 -0.44,0.69 -0.44,1.21l-1.03 -0.1c0.07,-0.77 0.34,-1.36 0.8,-1.77 0.47,-0.41 1.09,-0.61 1.87,-0.61 0.79,0 1.42,0.22 1.88,0.66 0.46,0.44 0.69,0.98 0.69,1.63 0,0.33 -0.07,0.65 -0.2,0.97 -0.13,0.32 -0.36,0.66 -0.67,1.01 -0.31,0.35 -0.83,0.84 -1.56,1.45 -0.61,0.51 -1,0.86 -1.17,1.04 -0.17,0.18 -0.31,0.36 -0.43,0.55l4.04 0z"/>
              <path className="analyzer_fil6" d="M199.09 271.11l1.01 -0.14c0.12,0.57 0.31,0.99 0.59,1.24 0.28,0.25 0.62,0.38 1.02,0.38 0.48,0 0.88,-0.16 1.21,-0.49 0.33,-0.33 0.49,-0.74 0.49,-1.22 0,-0.46 -0.15,-0.85 -0.46,-1.15 -0.3,-0.3 -0.69,-0.45 -1.16,-0.45 -0.19,0 -0.43,0.04 -0.71,0.11l0.11 -0.89c0.07,0.01 0.12,0.01 0.16,0.01 0.43,0 0.82,-0.11 1.17,-0.34 0.35,-0.22 0.52,-0.57 0.52,-1.03 0,-0.37 -0.13,-0.68 -0.38,-0.92 -0.25,-0.24 -0.58,-0.36 -0.97,-0.36 -0.39,0 -0.72,0.12 -0.99,0.37 -0.26,0.25 -0.43,0.62 -0.51,1.12l-1.01 -0.18c0.12,-0.68 0.4,-1.21 0.84,-1.58 0.44,-0.37 0.98,-0.56 1.63,-0.56 0.45,0 0.86,0.1 1.24,0.29 0.38,0.19 0.67,0.45 0.87,0.79 0.2,0.33 0.3,0.69 0.3,1.06 0,0.35 -0.1,0.68 -0.29,0.97 -0.19,0.29 -0.48,0.52 -0.85,0.7 0.49,0.11 0.86,0.35 1.13,0.7 0.27,0.35 0.4,0.8 0.4,1.33 0,0.72 -0.26,1.33 -0.79,1.83 -0.52,0.5 -1.19,0.75 -1.99,0.75 -0.72,0 -1.32,-0.22 -1.8,-0.65 -0.48,-0.43 -0.75,-0.99 -0.82,-1.67z"/>
              <path className="analyzer_fil6" d="M260.63 273.3l0 -1.97 -3.58 0 0 -0.93 3.76 -5.34 0.83 0 0 5.34 1.12 0 0 0.93 -1.12 0 0 1.97 -1.01 0zm0 -2.9l0 -3.7 -2.58 3.7 2.58 0z"/>
              <rect className="analyzer_fil7" x="40.12" y="70.55" width="227.04" height="166.19"/>
          </g>
      </svg>
  )
}

export default Analyzer