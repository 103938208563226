import React, { useEffect, useState } from 'react'
import { Button, ButtonContainer } from '../../arraybutton/arraybutton'

const DateFormat = (props) => {

    const [config, setConfig] = useState({})
    const onChangeHandler = (e, type) => {
        let data = { ...config }
        if(type === "dateFormat"){
            data[type] = e
        }
        setConfig(data)
        if ("onChange" in props) {
            props.onChange({ name: type, target: { value: data[type] } })
        }

    }

    useEffect(() => {
        if (props?.defaultValue) {
            setConfig(props.defaultValue)
        }
    }, [props.defaultValue])
    return (
        <>
            <div className='propertiesLabels mt-3'>Date Formate</div>
            {
                props.configTag.includes("dateFormat") ?
                    <ButtonContainer className="mb-3">
                        <Button buttonResult={() => onChangeHandler(0,"dateFormat")} active={config?.dateFormat === 0}>Short</Button>
                        <Button buttonResult={() => onChangeHandler(1,"dateFormat")} active={config?.dateFormat === 1}>Medium</Button>
                        <Button buttonResult={() => onChangeHandler(2,"dateFormat")} active={config?.dateFormat === 2}>Long</Button>
                    </ButtonContainer>
                : null
            }
        </>
    )
}

export default DateFormat