import Dashboard from './containers/dashboard/dashboard';
import Welcome from './containers/basicInformation/welcome';
import Devices from './containers/devices/devices';
import ChangePassword from './containers/changePassword/changePassword';
import ManageOverview from './containers/manageOverview/manageOverview';
import Overview from './containers/overview/overview';
import UserManagement from './containers/userManagement/UserManagement';
import RoleManagement from './containers/roleManagement/RoleManagement';
import UserDetail from './containers/userDetail/userDetail';
import RuleEngine from './containers/ruleEngine/RuleEngine';
import AddRuleEngine from './containers/ruleEngine/ruleEngineList';
import DeviceManager from './containers/deviceManager/DeviceManager';
import HubManager from './containers/hubManager/HubManager';
import RuleEngineList from './containers/ruleEngine/ruleEngineList';
import mapview from './containers/customDashboard/mapview/mapview';
import Report from './components/reports/reports';

export function setRoute(route) {
    routes = route
}
const routes = [
    { path: '/', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/welcome', exact: true, name: "welcome", component: Welcome },
    { path: '/organizations/:levelId', name: 'p :levelId', component: Dashboard },
    { path: '/branch/:levelId', name: 'p :levelId', component: Dashboard },
    { path: '/depart/:levelId', name: 'p :levelId', component: Dashboard },
    { path: '/room/:levelId', name: 'p :levelId', component: Dashboard },
    { path: '/level/:levelId', name: 'p :levelId', component: Dashboard },
    { path: "/change-password", exact: true, name: "change-password", component: ChangePassword },
    { path: "/hub/:levelId/:hubId", name: 'p :levelId', component: Devices },
    { path: "/alerts/:deviceId", name: "p: deviceId", component: Devices },
    { path: "/manage-dashboard/:organization", name: "manage-dashboard", exact: true, component: ManageOverview },
    { path: "/manage-dashboard/add/:organization", name: "add-overview", component: Overview },
    { path: "/manage-dashboard/edit/:organization", name: "edit-overview", component: Overview },
    { path: "/usermanagement/:organization", name: "user-management", component: UserManagement },
    { path: "/userDetail/:organization", name: "user-detail", component: UserDetail },
    { path: "/deviceManagement/:organization", name: "device-manager", component: DeviceManager },
    { path: "/hubManagement/:organization", name: "device-manager", component: HubManager },
    { path: "/userrole/:organization", name: "user-management", component: RoleManagement },
    { path: "/rule-engine/:organization", exact: true, name: "user-detail", component: RuleEngineList },
    { path: "/rule-engine/new/:organization", name: "add-rule-engine", component: RuleEngine },
    { path: "/mapview/:levelId", name: "map", component: mapview },
    { path: "/reports/:levelId", name: "report", component: Report }

]
export default routes
