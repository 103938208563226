import React, { useEffect, useState } from 'react'
import './aqiScale.scss'
import { getAQISetting, getRandomNumber, idGenerator } from '../../../functions/functions'
const compId = "aqiScale_"+idGenerator()
function AqiScale(props) {
    const [config, setConfig] = useState({
        value: 70,
        primary: {
            percentage: 0,
            color: 0,
            value: 0,
            message: "Good",
        },
        secondary: {
            percentage: 0,
            color: 0,
            value: 0,
            message: "Good",
        },
    })
    useEffect(() => {
        // let element = document.querySelector("#"+compId)
        // element.addEventListener('mousemove',(e)=>mouseMoveHandler(e,element))
        // if(props.config){
        //     let config1 = {...config}
        //     let aqi = props.value
        //     config1.primary.value = aqi
        //     let data = getAQISetting(aqi)
        //     config1.primary.percentage = data.percentage
        //     config1.primary.message = data.message
        //     config1.primary.color = data.color
        //     setConfig(config1)
        // }
        let intervel = setInterval(() => {
            let config1 = {...config}
            let aqi = getRandomNumber(70,201)
            config1.primary.value = aqi
            let data = getAQISetting(aqi)
            config1.primary.percentage = data.percentage
            config1.primary.message = data.message
            config1.primary.color = data.color
            setConfig(config1)
        }, 5000);
        return(()=> {
            clearInterval(intervel)
        })
    }, [props.config])
    const mouseMoveHandler = (e,elem) =>{
        const { left, width } = elem.getBoundingClientRect()
        // let containerX = e.offset().left;
        // let position = document.pageX - containerX;
        // let containerW = e.width() + 30;
        // let posInPer = (position / containerW) * 100;
        // let aqiPosition = (300 * posInPer) / 100;
        // loadAQI(aqiPosition, true);
    }
    
    // const loadAQI = (AQI, mouseMove) => {
    //     let labelColor = "",
    //     red = 0,
    //     green = 150,
    //     blue = 0;
    //     aqiValue = AQI;
    //     aqiMessage = "Good";
    //     if (aqiValue === "N/A") {
    //     red = 150;
    //     green = 150;
    //     blue = 150;
    //     aqiMessage = "Not Available";
    //     } else if (aqiValue > 25 && aqiValue <= 50) {
    //     var per = ((aqiValue - 25) / 25) * 100;
    //     red = (230 * per) / 100;
    //     green = 150 + (80 * per) / 100;
    //     blue = 0;
    //     aqiMessage = "Good";
    //     } else if (aqiValue > 50 && aqiValue <= 100) {
    //     var per = ((aqiValue - 50) / 50) * 100;
    //     red = 230;
    //     green = 230 - (128 * per) / 100;
    //     blue = 0;
    //     aqiMessage = "Moderate";
    //     } else if (aqiValue > 100 && aqiValue <= 200) {
    //     red = 200;
    //     green = 102 - (102 * (aqiValue - 100)) / 100;
    //     blue = 0;
    //     aqiMessage =
    //         "Unhealthy <br/> <span style='font-size: 10px; line-hight: 10px;'>for sensitive groups</span>";
    //     if (aqiValue > 150) {
    //         aqiMessage = "Unhealthy";
    //     }
    //     } else if (aqiValue > 200 && aqiValue <= 300) {
    //     red = 200 - (100 * (aqiValue - 200)) / 100;
    //     green = 0;
    //     blue = (100 * (aqiValue - 200)) / 100;
    //     aqiMessage = "Very Unhealthy";
    //     } else if (aqiValue > 300) {
    //     red = 100;
    //     green = 0;
    //     blue = 100;
    //     aqiMessage = "Hazardous";
    //     }
    //     labelColor = "rgb(" + red + "," + green + "," + blue + ")";
    //     if (aqiValue <= 300) {
    //     aqiArrow = (aqiValue / 300) * 100;
    //     } else {
    //     aqiArrow = (300 / 300) * 100;
    //     }
    //     if (aqiValue !== "N/A") {
    //     aqiValue = aqiValue.toFixed(0);
    //     }
    //     let arrowClass = ".aqi-arrow",
    //     msgClass = ".aqi-msg";
    //     if (mouseMove) {
    //     (arrowClass = ".aqi-arrow1"), (msgClass = ".aqi-msg1");
    //     }
    //     $(document).ready(function () {
    //     $(arrowClass).css("left", aqiArrow + "%");
    //     $(msgClass).css({
    //         left: aqiArrow + "%",
    //         transform: "translateX(-" + aqiArrow + "%)",
    //         "background-color": labelColor,
    //     });
    //     if (aqiValue === "N/A") {
    //         $(msgClass + ":eq(0)").html(aqiValue);
    //     } else {
    //         $(msgClass + ":eq(0)").html(aqiValue + " AQI");
    //     }
    //     $(msgClass + ":eq(1)").html(aqiMessage);
    //     if (mouseMove) {
    //         $(arrowClass).css("transition", "all 0s");
    //         $(msgClass).css({ transition: "all 0s" });
    //         if (aqiValue > 300) {
    //         $(msgClass + ":eq(0)").html("300+ AQI");
    //         }
    //     }
    //     });
    // };
        
    return (
        <div id={compId} className="scaleOnMouse p-3" style={{maxHeight: 135}}>
            <div className="position-relative ">
                <div
                    className="aqi-msg1 text-center px-3 py-1 mb-3 font-weight-bold rounded-1"
                    style={{
                    display: "inline-block",
                    position: "absolute",
                    color: "white",
                    opacity: 0,
                    fontSize: "24px",
                    width: "max-content",
                    ransition: "all 0.5s ease 0s",
                    left: "25.0141%",
                    transform: "translateX(-25.0141%)",
                    backgroundColor: "rgb(230, 166, 0)",
                    }}
                >80 AQI</div>
                <i
                    className="aqi-arrow1 fa fa-caret-down position-absolute"
                    style={{
                    bottom: "-8px",
                    opacity: 0,
                    fontSize: "30px",
                    transform: "translateX(-50%)",
                    }}
                ></i>
                <div
                    className={
                    config.value > 300
                        ? "dangerblink aqi-msg dropshadow-1 text-center px-2 mb-3 font-weight-bold rounded-1"
                        : "aqi-msg dropshadow-1 text-center px-2 mb-3 font-weight-bold rounded-1"
                    }
                    style={{
                    display: "inline-block",
                    position: "relative",
                    color: "white",
                    fontSize: "20px",
                    transition: "all 0.5s",
                    left: config.primary.percentage+"%",
                    transform: "translateX(-"+config.primary.percentage+"%)",
                    backgroundColor: config.primary.color,
                    }}
                >{config.primary.value} AQI</div>
                <i
                    className="aqi-arrow fa fa-caret-down position-absolute"
                    style={{
                    bottom: "-8px",
                    fontSize: "30px",
                    transform: "translateX(-50%)",
                    transition: "all 0.5s",
                    left: config.primary.percentage+"%",
                    }}
                ></i>
            </div>
            <div
            className="rounded-1 py-1"
            style={{
                backgroundImage:
                "linear-gradient(90deg, rgb(0, 150, 0) 0%, rgb(230, 230, 0) 16.7%, rgb(220, 102, 0) 33.4%, rgb(200, 0, 0) 66.7%, rgb(100, 0, 100) 100%)",
            }}
            ></div>
            <div className="position-relative">
                <i
                    className="aqi-arrow1 fa fa-caret-up position-absolute"
                    style={{
                    top: "-10px",
                    fontSize: "30px",
                    opacity: 0,
                    transform: "translateX(-50%)",
                    zIndex: 1,
                    }}
                ></i>
                <div
                    className="aqi-msg1 text-center px-2 py-1 mt-4 font-weight-bold rounded-1"
                    style={{
                    display: "inline-block",
                    position: "absolute",
                    color: "white",
                    opacity: 0,
                    zIndex: 1,
                    width: "max-content",
                    }}
                ></div>
                <i
                    className="aqi-arrow fa fa-caret-up position-absolute"
                    style={{
                    top: "-10px",
                    fontSize: "30px",
                    transform: "translateX(-50%)",
                    zIndex: 1,
                    transition: "all 0.5s",
                    left: config.primary.percentage+"%",
                    }}
                ></i>
                <div
                    className={
                    config.primary.value > 300
                        ? "dangerblink aqi-msg dropshadow-1 text-center p-1 font-weight-bold rounded-1"
                        : "aqi-msg dropshadow-1 text-center p-1 font-weight-bold rounded-1"
                    }
                    style={{
                    marginTop: "27px",
                    display: "inline-block",
                    position: "relative",
                    color: "white",
                    zIndex: 1,
                    transition: "all 0.5s",
                    left: config.primary.percentage+"%",
                    transform: "translateX(-"+config.primary.percentage+"%)",
                    backgroundColor: config.primary.color,
                    fontSize: "12px",
                    lineHeight: "12px",
                    }}
                >
                    {config.primary.message.match("_")?<div className='d-flex flex-column'>
                        {config.primary.message.split("_")[0]}
                        <span style={{fontSize: "10px", lineHight: "10px",}}>{config.primary.message.split("_")[1]}</span>
                    </div>:config.primary.message}
                </div>

                <div
                    className="scaleRange l px-2 py-1 mt-1 position-absolute"
                    style={{
                    left: "0%",
                    }}
                >
                    <div className="d-none">|</div>0
                </div>
                <div
                    className="scaleRange l px-2 py-1 mt-1 position-absolute"
                    style={{
                    left: "16.66%",
                    }}
                >
                    <div className="d-none">|</div>
                    50
                </div>
                <div
                    className="scaleRange l px-2 py-1 mt-1 position-absolute"
                    style={{
                    left: "33.33%",
                    }}
                >
                    <div className="d-none">|</div>
                    100
                </div>
                <div
                    className="scaleRange l px-2 py-1 mt-1 position-absolute"
                    style={{
                    left: "49.99%",
                    }}
                >
                    <div className="d-none">|</div>
                    150
                </div>
                <div
                    className="scaleRange l px-2 py-1 mt-1 position-absolute"
                    style={{
                    left: "66.66%",
                    }}
                >
                    <div className="d-none">|</div>
                    200
                </div>
                <div
                    className="scaleRange l px-2 py-1 mt-1 position-absolute"
                    style={{
                    left: "83.33%",
                    }}
                >
                    <div className="d-none">|</div>
                    250
                </div>
                <div
                    className="scaleRange r px-2 py-1 mt-1 position-absolute"
                    style={{
                    left: "100%",
                    }}
                >
                    <div className="d-none">|</div>
                    300
                </div>
            </div>
        </div>
    )
}

export default AqiScale