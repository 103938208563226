import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getDate, getTime } from '../../../functions/functions'
import moment from 'moment'

function DynamicLabel(props) {
    const [night, setNight] = useState(false)
    const [config, setConfig] = useState({
        value: null,
        prefix: "",
        label: "Label",
        suffix: "",
    })
    const [className, setClassName] = useState("")
    const [css, setCss] = useState({ color: "#0471C4", transition: "all 0.3s" })
    const [events, setEvents] = useState({
        Status: {},
        Connection: {}
    })

    useEffect(() => {
        setNight(props?.night)
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    let css1 = { ...css }
                    css1.color = props.config.color;
                    setCss(css1)
                }
            }
            if ("label" in props.config && props.config?.label) {
                config1.label = props.config.label
            }
            else {
                config1.label = "Label"
            }
            if ("prefix" in props.config) {
                config1.prefix = props.config.prefix
            }
            if ("suffix" in props.config) {
                config1.suffix = props.config.suffix
            }
            if ("suffix" in props.config) {
                config1.suffix = props.config.suffix
            }
            if ("param" in props.config) {
                config1.param = props.config.param
            }
            if ("dateFormat" in props.config) {
                if (props.config.dateFormat === 0) {
                    config1.dateFormat = "L LTS"
                } else
                    if (props.config.dateFormat === 1) {
                        config1.dateFormat = "LL LTS"
                    } else
                        if (props.config.dateFormat === 2) {
                            config1.dateFormat = "dddd, LL LTS"
                        }
            }
            setConfig(config1)
        }
    }, [props.config])
    useEffect(() => {
        if (props?.className) {
            setClassName(props.className)
        }
        if (props?.style) {
            if ("gap" in props.style) {
                let css1 = { ...css, gap: props.style.gap }
                setCss(css1)
            }
        }
    }, [props.className, props.style])
    useEffect(async () => {
        EventReducer();
    }, [props.eventReducer.deviceEvents])

    const EventReducer = () => {
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")
        if (hubId) {
            hubId = hubId[0] + "_" + hubId[1]
        }
        let Events = props?.eventReducer?.deviceEvents

        if (Events.deviceId === props?.config?.deviceId) {
            events1.Status = Events
            setEvents(events1)
        } else if (Events.deviceId === hubId) {
            events1.Connection = Events
            setEvents(events1)
        }
    }
    useEffect(() => {
        let Events = props?.lastEvent?.lastEvent?.devices
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")

        // Events?.map((item, index) => {
        //     if (item.events[0].deviceId === props.device._id) {
        //         events1.Status = item.events[0]
        //         setEvents(events1)
        //     }
        // })
        Events?.map((item, index) => {
            if (item._id === props?.config?.deviceId) {
                events1.Status = item.events[0]
                setEvents(events1)
            } else if (item._id === hubId) {
                events1.Connection = item.events[1]
                setEvents(events1)
            }
        })
    }, [])

    useEffect(() => {
        let Events = props?.lastEvent?.lastEvent?.devices
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")

        // Events?.map((item, index) => {
        //     if (item.events[0].deviceId === props.device._id) {
        //         events1.Status = item.events[0]
        //         setEvents(events1)
        //     }
        // })
        Events?.map((item, index) => {
            if (item._id === props?.config?.deviceId) {
                events1.Status = item.events[0]
                setEvents(events1)
            } else if (item._id === hubId) {
                events1.Connection = item.events[1]
                setEvents(events1)
            }
        })
    }, [props.lastEvent])

    useEffect(() => {
        if (props?.config?.param in events?.Status) {
            let config1 = { ...config }
            if (events.Status[props.config.param] !== null) {
                config1.value = events.Status[props.config.param]
            } else {
                config1.value = 0
            }
            setConfig(config1)
        }
    }, [events.Status])
    useEffect(() => {
        if (config?.param === "created" && config.value) {
            let config1 = { ...config }
            if ("dateFormat" in config1) {
                config1.value = moment(config1.value).format(config1.dateFormat)
            } else {
                config1.value = moment(config1.value).format("L LTS")
            }
            setConfig(config1)
        }
    }, [config.value, config.dateFormat])

    let a = moment(config.value).format(config.dateFormat)
    return (
        <div className={'d-flex h-100 w-100 ' + className} style={css}>
            <div>{config.label ? config.label : ""}&nbsp;</div>
            <div><b>{config.prefix}{config.value}{config.suffix}</b></div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    eventReducer: state.eventReducer,
    lastEvent: state.lastEvent,
});
export default connect(mapStateToProps, null)(DynamicLabel)