export const userInfo = (state = null, action) => {

    switch (action.type) {
        case "Verification":
            return { ...state, varification: action.payload };
        default:
            return state
    }
}
export const userToken = (state = null, action) => {

    switch (action.type) {
        case "Token":
            return { ...state, token: action.payload };
        default:
            return state
    }
}
export const themeMode = (state = null, action) => {

    switch (action.type) {
        case "themeMode":
            return { ...state, isLight: action.payload };
        default:
            return state
    }
}