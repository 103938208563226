import React, { useEffect, useState } from 'react'
import Input from '../../input/input'

const StrengthLevel = (props) => {

    const [strengthLevel, setStrengthLevel] = useState([1,21,41,61,81])
    const onChangeHandler = (e) => {
        let value = e.target.value, name = e.name, data = [...strengthLevel]
        name = name.split("_")[1]
        data[name] = parseFloat(value)
        setStrengthLevel(data)
        if ("onChange" in props) {
            props.onChange({ name: "strengthLevel", target: { value: data } })
        }

    }

    useEffect(() => {
        if (props?.defaultValue) {
            if ("strengthLevel" in props?.defaultValue) {
                if (props?.defaultValue.strengthLevel) {
                    setStrengthLevel(props.defaultValue.strengthLevel)
                }
            }
        }
    }, [props.defaultValue])
    return (
        <>
            <div className='propertiesLabels mt-2'>Strength Level</div>
            <div className='row w-100'>
                <div className='col-6 mb-3'>
                    <Input
                        name="strengthLevel_0"
                        label="Level 1"
                        type="number"
                        defaultValue={strengthLevel?strengthLevel[0]:""}
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
                <div className='col-6 mb-3'>
                    <Input
                        name="strengthLevel_1"
                        label="Level 2"
                        type="number"
                        defaultValue={strengthLevel?strengthLevel[1]:""}
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
                <div className='col-6 mb-3'>
                    <Input
                        name="strengthLevel_2"
                        label="Level 3"
                        type="number"
                        defaultValue={strengthLevel?strengthLevel[2]:""}
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
                <div className='col-6 mb-3'>
                    <Input
                        name="strengthLevel_3"
                        label="Level 4"
                        type="number"
                        defaultValue={strengthLevel?strengthLevel[3]:""}
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
                <div className='col-6 mb-3'>
                    <Input
                        name="strengthLevel_4"
                        label="Level 5"
                        type="number"
                        defaultValue={strengthLevel?strengthLevel[4]:""}
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
            </div>
        </>
    )
}

export default StrengthLevel