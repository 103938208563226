
const INITIAL_STATE = {
    deviceEvents: {
        deviceId: 0.0,
        levelId: 0.0,
        value: 0.0,
        type: 0.0,
        created: 0.0,
        eventId: 0.0,
        hubId: 0.0,
    }
}

const eventReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_EVENT":
            return { ...state, deviceEvents: action.payload };
        default:
            return state
    }
}
export default eventReducer
