import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import LT from "../../assets/svgs/LT.svg";
import MyDropDown, { MyDropDownBody } from "../../components/dropdown/dropdown";
import Mypopup from "../../components/popup/mypopup";
import Signal from "../../components/signals/signal";
import { signOut } from "../../services/utilities/users";
import { getImage, getNotification, updateNotification } from "../../services/utilities/events";
import Card, { CardBody, CardHeader, Heading, InnerCard } from "../../components/card/card";
import { ButtonContainer, Button } from "../../components/arraybutton/arraybutton";
import { connect } from "react-redux";
import LightDarkButton from "../../components/lightDarkButton/lightDarkButton";
import { flattenArray, getHubsList, getLevelIds, getLevelIdsAll, getLevelsAll } from "../../functions/functions";
import { organization } from "../../services/reducers/Org";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function DefaultHeader(props) {




  let history = useHistory()
  document.onclick = function (e) {
    if (hover1) {
      setAlertPopup(!alertPopup)
    }
    else {
      setAlertPopup(false)
    }
    if (hover2) {
      setUserPopup(!userPopup)
    }
    else {
      setUserPopup(false)
    }
    if (hover3) {
      setNotificationPopup(!notificationPopup)
    }
    else {
      setNotificationPopup(false)
    }
  };
  let [popup, setPopup] = useState(false)
  const showPopup = () => {
    setPopup(true)

  }


  const getCookie = (key) => {
    let name = key + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let decodedCookieParts = decodedCookie.split(';');


    for (let i = 0; i < decodedCookieParts.length; i++) {
      let c = decodedCookieParts[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
  const [userPopup, setUserPopup] = useState(false)
  const [orgLogo, setOrgLogo] = useState(null)
  const [alertPopup, setAlertPopup] = useState(false)
  const [notificationLength, setNotificationLength] = useState(0)
  const [notifications, setNotifications] = useState([])
  const [hover1, setHover1] = useState(false)
  const [hover2, setHover2] = useState(false)
  const [hover3, setHover3] = useState(false)
  const [searchHover, setSearchHover] = useState(false)
  const [notificationPopup, setNotificationPopup] = useState(false)
  const [flattenLevels, setFlattenLevels] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterList, setFilterList] = useState({ levels: [], hubs: [] });
  // const [searchInput, setSearchInput] = useState(false)
  const handleClick = () => {
    history.push("/change-password")
  }

  let { pathname } = useLocation();

  let levelId = pathname.split("/");
  levelId = levelId[levelId.length - 1];
  let orgId = levelId;
  if (orgId.match("_")) {
    let orgIdList = orgId.split("_");
    orgId = orgIdList[0];
  }
  // if (levelId.length > 5) {
  //   setSerch(true)
  // }
  // setTimeout(() => {
  // let data = [{
  //   _id: '1',
  //   msg: 'Your User update ',
  //   adminId: 'maaviyamansoori222@gmail.com',
  //   organizationId: 'AlluringSmile',
  //   belongTo: 'maaviyamansoori222@gmail.com',
  //   createdBy: '',
  //   acknowledge: false,
  //   created: ''
  // }, {
  //   _id: '2',
  //   msg: 'Your User update ',
  //   adminId: 'maaviyamansoori222@gmail.com',
  //   organizationId: 'AlluringSmile',
  //   belongTo: 'maaviyamansoori222@gmail.com',
  //   createdBy: '',
  //   acknowledge: false,
  //   created: ''
  // }, {
  //   _id: '2',
  //   msg: 'Your User update ',
  //   adminId: 'maaviyamansoori222@gmail.com',
  //   organizationId: 'AlluringSmile',
  //   belongTo: 'maaviyamansoori222@gmail.com',
  //   createdBy: '',
  //   acknowledge: false,
  //   created: ''
  // }, {
  //   _id: '3',
  //   msg: 'Your User update ',
  //   adminId: 'maaviyamansoori222@gmail.com',
  //   organizationId: 'AlluringSmile',
  //   belongTo: 'maaviyamansoori222@gmail.com',
  //   createdBy: '',
  //   acknowledge: false,
  //   created: ''
  // }]
  useEffect(() => {
    let newNotification = props.notifyReducer.notifyEvents
    let data1 = [...notifications]
    if (newNotification?._id) {
      data1.push(newNotification)
    }
    setNotifications(data1)
  }, [props.notifyReducer.notifyEvents])
  useEffect(async () => {
    let notification = await getNotification()
    let notificationLength = notification.length
    if (notificationLength > 9) {
      setNotificationLength("9+")
    } else {
      setNotificationLength(notificationLength)
    }
    setNotifications(notification)
  }, [])

  useEffect(() => {
    if (notifications.length > 9) {
      setNotificationLength("9+")
    } else {
      setNotificationLength(notifications.length)
    }
  }, [notifications])




  // const getNotifications = async () => {
  //   // let notification = await getNotification()
  //   let notification = data
  //   let notificationLength = notification.length
  //   if (notificationLength > 9) {
  //     setNotificationLength("9+")
  //   } else {
  //     setNotificationLength(notificationLength)
  //   }
  //   setNotifications(data)
  // }

  const acKnowledgeNotification = async (data, key) => {
    let data1 = [...notifications]
    data["acknowledge"] = true
    let response = await updateNotification(data._id, data)
    if (response.statusCode === "200") {
      data1.splice(key, 1)
    }
    data1.splice(key, 1)
    setNotifications(data1)
  }
  // let length = notificationLength + 1
  // setNotificationLength(length)
  // }, 1000)

  useEffect(async () => {
    if (props.organization) {
      if (props?.organization?.metadata?.logo) {
        const logo = await getImage(props?.organization?.metadata?.logo)
        setOrgLogo(logo)
      }
      if ('levels' in props?.organization) {
        const levels = flattenArray(props.organization?.levels)
        setFlattenLevels(levels)
      }
    }
  }, [props.organization])

  const onFilterHandler = (e) => {
    const search = e.target.value?.toLowerCase()?.split(" ") || []
    let levels = e.target.value ? getLevelsAll(props?.organization?.organization, props.userInfo.varification?.hierarchy) : []
    // console.log(getprops?.organization?.organization, props.userInfo.varification?.hierarchy)
    const allhubs = getHubsList(props.organization.organization?.allhubs, props.userInfo.varification?.hierarchy, false) || []
    // let levels = e.target.value ? flattenLevels : []
    let hubs = e.target.value ? allhubs : []
    // console.log(getHubsList(allhubs, props.userInfo.varification?.hierarchy, false))
    for (const querry of search) {
      levels = levels.length ? levels.filter(level => (level.locationName.toLowerCase().includes(querry) || level._id.toLowerCase().includes(querry) || level.type.toLowerCase().includes(querry)) ? level : null) : []
      hubs =
        hubs.length ?
          hubs.filter(hub => (hub.name.toLowerCase().includes(querry) || hub._id.toLowerCase().includes(querry) || hub.type.toLowerCase().includes(querry)) ? hub : null) : []
    }
    if (levels.length) {
      levels = levels.sort((a, b) => {
        if (a.locationName.length < b.locationName.length) {
          return -1
        } else {
          return 1
        }
      })
    }
    if (hubs.length) {
      hubs = hubs.sort((a, b) => {
        if (a.name.length < b.name.length) {
          return -1
        } else {
          return 1
        }

      })
    }
    setFilterList({ levels, hubs })
    setFilter(e.target.value)
  }

  return (
    <>
      <div className="topContainer">
        <div className="blurContainer"></div>
        <a href="#">
          <img src={orgLogo ? orgLogo : LT} alt="Linked-things Logo" />
        </a>
        {orgId && orgId !== "welcome" ?
          <MyDropDown openToggle={() => null} className="mainSearchContainer">
            <input onFocus={() => setSearchHover(true)} onBlur={() => setSearchHover(false)} className="mainSearch" value={filter} onChange={onFilterHandler} placeholder="Search by location | id | type" />
            <MyDropDownBody className="p-2 pb-3 mt-2" style={{ width: 'calc(100% - 10px)', maxHeight: 'calc(100vh - 120px)', overflow: "auto" }} open={(searchHover && (filterList.levels.length || filterList.hubs.length))} left>
              {filterList.levels.length ? <h5 className="m-0">Locations</h5> : null}
              {filterList.levels.length ? filterList.levels.map((level, key) => key < 5 ?
                <Link key={key} to={{ pathname: "/organizations/" + level._id, data: level }} onClick={() => setTimeout(() => { setSearchHover(false) }, 100)} className="searchItems text-truncate">
                  <span style={{ fontSize: '14px' }}>{level.locationName}</span>
                  <div style={{ fontSize: '12px' }}>
                    <small className="mr-2 p-1 px-2 rounded-3 bg-light">Type: <b>{level.type}</b></small>
                    <small className="p-1 px-2 rounded-3 bg-light">Id: <b>{level._id}</b></small>
                  </div>
                </Link>
                : null) : null}
              {filterList.hubs.length ? <h5 className="m-0 mt-2">Hubs</h5> : null}
              {filterList.hubs.length ? filterList.hubs.map((hub, key) => key < 5 ?
                <Link key={key} to={{ pathname: "/hub/" + hub.levelId + "/" + hub._id, data: hub }} onClick={() => setTimeout(() => { setSearchHover(false) }, 100)} className="searchItems text-truncate">
                  <span style={{ fontSize: '14px' }}>{hub.name}</span>
                  <div style={{ fontSize: '12px' }}>
                    <small className="mr-2 p-1 px-2 rounded-3 bg-light">Type: <b>{hub.type}</b></small>
                    <small className="p-1 px-2 rounded-3 bg-light">Id: <b>{hub._id}</b></small>
                  </div>
                </Link>
                : null) : null}
            </MyDropDownBody>
          </MyDropDown> : null}
        <nav className="d-flex align-items-center">
          <MyDropDown openToggle={(e) => setHover3(e)} >
            <div className={"mr-3 py-2 " + (notificationLength ? " bell-notification " : "")} current-count={notificationLength}><i className="far fa-bell" style={{ fontSize: "16px" }}></i></div>
            <MyDropDownBody className="px-3" style={{ width: '400px', maxHeight: "500px", overflow: "auto" }} open={notificationPopup} right>
              <h4><b>Notifications</b></h4>
              {notifications.length ?
                notifications.map((notify, key) =>
                  <Card className={"mb-1 d-flex"}>
                    <div className="col">{notify.msg}</div>
                    <div className="col">
                      <ButtonContainer>
                        <Button buttonResult={() => { acKnowledgeNotification(notify, key) }}>Acknowledge</Button>
                      </ButtonContainer>
                    </div>
                  </Card>
                ) :
                <div className="h-100 d-flex justify-content-center self-items-center">
                  <p className="m-0">No Notifications</p>
                </div>
              }
            </MyDropDownBody>
          </MyDropDown>

          <div style={{ height: "25px", width: "20px" }}><Signal connection={props.network} /></div>
          <MyDropDown openToggle={(e) => setHover2(e)}>
            <button className="btn p-0 pt-1 px-1 px-md-3 m-2 rounded-circle user-icon">
              <i className="far fa-lg fa-user-circle"></i>
            </button>
            <MyDropDownBody open={userPopup} right>
              {/* <div style={{height: '40px', padding: "0 10px"}}> */}
              {/* </div> */}
              <button className="userdropdown w-100 d-flex"><i className="mr-2 fa fa-user"></i> {getCookie('user')}</button>
              <button className="userdropdown w-100" onClick={() => handleClick()}><i className="mr-2 fa fa-lock"></i>Change Password</button>
              <LightDarkButton />
              <button className="userdropdown w-100" onClick={() => showPopup()}><i className="mr-2 fa fa-power-off"></i> Sign Out</button>
            </MyDropDownBody>
          </MyDropDown>
        </nav>
      </div>
      {
        popup ? <Mypopup title="Warning!" onClose={(e) => setPopup(false)}>
          <p className="p-3"> Are You Sure Sign Out?</p>
          <div className="d-flex justify-content-center p-3">
            <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={(e) => signOut()}>Yes</button>
            <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => { setPopup(false) }}>No</button>
          </div>
        </Mypopup>
          : null}
    </>
  );
}
const mapStateToProps = (state) => ({
  notifyReducer: state.notifyReducer,
  userInfo: state.userInfo,
  configration: state.configration,
  organization: state.organization,
});
export default connect(mapStateToProps, null)(DefaultHeader)
