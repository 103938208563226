import React, { useEffect, useState } from 'react'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../components/card/card'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import Input from '../../components/input/input'
import Select from '../../components/select/select'
import { hslToHex } from '../../functions/functions'
import AddUserPopup from '../../components/addUserPopup/addUserPopup'
import Mypopup from '../../components/popup/mypopup'
import { DeleteChildUser, GetChildUser } from '../../services/utilities/events'
import { getCookie } from '../../services/utilities/users'
import CardLoader from '../../components/loader/cardLoader'
import { ResponseMessageLocal } from '../../services/config/generic'
import { connect } from 'react-redux'
import store from '../../services/utilities/store'
import { userList } from '../../services/reducers/UserList'
import ReactReducerAction from '../../services/actions/ReactReducerAction'

const UserManagement = (props) => {
    let organization = props.organization.organization
    const [addUser, setAddUser] = useState(null)
    const [msgPopup, setMsgPopup] = useState(null)
    const [data, setData] = useState([])
    const [filterList, setFilterList] = useState([])
    const [loader, setLoader] = useState(true)
    const [confirmPopup, setConfirmPopup] = useState(false)
    const adminId = getCookie('email')
    const [userUpdate, setUserUpdate] = useState(false)
    const userDetail = (data) => {
        props.history.push({
            pathname: '/userDetail/' + organization._id,
            search: '?id=' + data._id,
            state: data
        })
    }
    useEffect(async () => {
        setUserUpdate(true)
    }, [])
    const rowArrangement = (data, id) => {
        let newData = []
        let leftData = []
        data.map((row, key) => {
            if (row.createdBy === id) {
                let row1 = { ...row, collapse: key === 0, colorLevel: 1, child: [] }
                newData.push(row1)
            }
            else {
                leftData.push(row)
            }
        })
        if (leftData.length) {
            newData.map((row, key) => {
                if (row.createdBy === id) {
                    row['child'] = rowArrangement(leftData, row.email)
                }
            })
        }
        return newData
    }
    const filterHandler = (data, search) => {
        // let matchuser = []
        // return data.include(row => {
        //     if ("child" in row && row.child.length) {
        //         filterHandler(row, search)
        //     } 
        //     return search.LowerCase().match(row.email) || search.LowerCase().match(row.firstName) || search.LowerCase().match(row.lastName)
        // })
        //  search.LowerCase().match(row.email) || search.LowerCase().match(row.firstName) || search.LowerCase().match(row.lastName) ? item : )
        // if (!matchuser.length) {
        //   data.map(row => {
        //       if ("child" in row) {
        //           filterHandler(row.child,search)
        //       }
        //   })  
        // }
        // return data.filter(item => {
        //     return search !== "" ?

        //         item.email.toLowerCase().includes(search.toLowerCase()) || (item.firstName + " " + item.lastName).toLowerCase().includes(search.toLowerCase())
        //         : item;
        // })
    }
    const onChangeHandler = (e) => {
        let filterUsers = []
        let search1 = e.target.value
        if (data.length) {
            data.map(row => {
                row.collapse = false
                let stringObj = JSON.stringify(row)
                if (stringObj.toLowerCase().match(search1)) {
                    filterUsers.push(row)
                }

            })
            // filterUsers = filterHandler(data, search1)
        }
        // let filterUsers1 = [...filterUsers]
        // function ChildUser(filterUsers1) {

        // }
        // if (filterUsers) {
        //     filterUsers.map(user => {
        //         if (user.child) {
        //             filterUsers1.push(user.child)
        //         }
        //     })
        // }
        // if (filterUsers.child) {
        //     let childFilter = [...filterUsers]

        // }
        filterUsers[0].collapse = true
        setFilterList(filterUsers)
    }

    const getUsers = (e) => {
        setUserUpdate(false)
        setLoader(true)
        let a1 = [...e]
        a1 = rowArrangement(a1, adminId)
        setData(a1)
        setFilterList(a1)
        setLoader(false)
    }

    const deleteUser = async () => {
        let key = confirmPopup.key
        let id = confirmPopup.data
        let data1 = [...data]
        let result = await DeleteChildUser(id)
        if (result.statusCode === "200") {
            data1.splice(key, 1)
        }
        setData(data1)
        // setFilterList(data1)
        setUserUpdate(true)
        setConfirmPopup(null)
    }

    const collapseHandler = (key) => {
        let a1 = [...filterList]
        let row = a1
        key.map((val, index) => {
            if (key.length - 1 > index) {
                if (row[val].collapse) {
                    row[val].colorLevel = 1 + ((key.length) - (index + 1))
                } else {
                    row[val].colorLevel = 1
                }
                row = row[val].child
            }
            else if (key.length - 1 === index) {
                if (row[val].collapse) {
                    row[val].colorLevel = 1 + ((key.length) - (index + 1))
                } else {
                    row[val].colorLevel = 1
                }
                row.map((row1, index1) => {
                    if (index1 === val) {
                        row1.collapse = !(row1.collapse)
                    } else {
                        row1.collapse = false
                    }

                })
            }
        })
        setFilterList(a1)
        // setFilterList(a1)

    }
    const checkChild = (data, index) => {
        // let row = [];
        return data.map((b, key) =>
            <InnerCard key={key} className={"mb-1"} style={{ backgroundColor: hslToHex(200, 100, (70 + (30 / b.colorLevel))) }}>
                <CardHeader className="row align-items-center justify-content-between">
                    {/* {b.child.length? */}
                    <button title={"Employees (" + b.child.length + ")"} className='btn btn-light rounded-circle-px py-1' onClick={() => collapseHandler([...index, key])}>
                        <i className='mr-2 fa fa-sm fa-angle-right' style={{ transform: "rotate(" + (b.collapse ? 90 : 0) + "deg)", transition: 'all 0.3s' }}></i>
                        <i className='mr-2 fa fa-sm fa-users'></i>
                        ({b.child.length})
                    </button>
                    {/* :null} */}
                    <div className='col text-truncate' style={{ maxWidth: "100px" }}>

                        <div className={"d-inline py-1 px-3 text-white rounded-circle-px "}> {b.isCompleted ? <i className="fas fa-check-circle" style={{ color: "#43a130" }}></i> : <i className="fas fa-info-circle text-warning" ></i>}</div>
                    </div>
                    <div className='col text-truncate'>
                        <Heading size={2}>
                            {b.firstName + " " + b.lastName}
                        </Heading>
                    </div>
                    <div className='col text-truncate'>
                        <span>Email:</span> <span>{b.email}</span>
                    </div>
                    <ButtonContainer>
                        <Button active={b.registered ? true : false}>Activate</Button>
                        <Button active={!b.registered ? true : false}>Deactivate</Button>
                    </ButtonContainer>
                    <ButtonContainer color="success" className="mr-1">
                        <Button buttonResult={() => userDetail(b)}>Detail</Button>
                    </ButtonContainer>
                    <ButtonContainer flat="light" className="mr-2 p-2">
                        <Button color="text-primary" buttonResult={() => setAddUser(b)}><i className='fa fa-pen'></i></Button>
                        <Button color="text-danger" buttonResult={() => { setConfirmPopup({ data: b._id, key }) }}><i className='fa fa-trash'></i></Button>
                    </ButtonContainer>
                </CardHeader>
                {b.collapse && b?.child?.length ? <CardBody className="p-3">
                    {checkChild(b.child, [...index, key])}
                </CardBody> : null}
            </InnerCard>
        )
        //  row
    }

    return (
        <div className='p-3'>
            <ReactReducerAction update={userUpdate} onUserUpdate={(e) => getUsers(e)} />
            <Card blur className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className="d-flex justify-content-between">
                    <Heading>
                        User Management
                    </Heading>
                    <ButtonContainer>
                        <Button buttonResult={() => setAddUser(true)}>Add User</Button>
                    </ButtonContainer>
                </CardHeader>
                {/* <CardBody className="row p-3">
                    <div className="col-md-6 mt-md-0 mt-3">
                        <Input label="User Search" onChange={(e) => { onChangeHandler(e) }} />
                    </div>
                    <div className='col-md-6'>
                        <div className='row justify-content-end'>
                            <div className='col-lg-4 col-md-6 mt-md-0 mt-3'>
                                <Select label="Role" />
                            </div>
                            <div className='col-lg-4 col-md-6 mt-md-0 mt-3'>
                                <Select label="Access" />
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                                <ButtonContainer>
                                    <Button active={true}>Online</Button>
                                    <Button>Offline</Button>
                                </ButtonContainer>
                            </div>
                </CardBody> */}
            </Card>
            {!loader ? filterList.length ? filterList.map((b, key) =>
                <Card key={key} className={"mb-1"} style={{ backgroundColor: hslToHex(200, 100, ((100 / b.colorLevel))) }}>
                    <CardHeader className="row align-items-center justify-content-between">
                        <button title={"Employees (" + b?.child?.length + ")"} className='btn btn-light rounded-circle-px py-1' onClick={() => collapseHandler([key])}>
                            <i className='mr-2 fa fa-sm fa-angle-right' style={{ transform: "rotate(" + (b.collapse ? 90 : 0) + "deg)", transition: 'all 0.3s' }}></i>
                            <i className='mr-2 fa fa-sm fa-users'></i>
                            ({b?.child?.length})
                        </button>
                        <div className='col text-truncate' style={{ maxWidth: "100px" }}>

                            <div className={"d-inline py-1 px-3 text-white rounded-circle-px "}> {b.isCompleted ? <i className="fas fa-check-circle" style={{ color: "#43a130" }}></i> : <i className="fas fa-info-circle text-warning"></i>}</div>
                        </div>
                        <div className='col text-truncate'>
                            <Heading size={2}>
                                {b.firstName + " " + b.lastName}
                            </Heading>
                        </div>
                        <div className='col text-truncate'>
                            <span>Email:</span> <span>{b.email}</span>
                        </div>
                        <ButtonContainer>
                            <Button active={b.registered ? true : false}>Activate</Button>
                            <Button active={!b.registered ? true : false}>Deactivate</Button>
                        </ButtonContainer>
                        <ButtonContainer color="success" className="mr-1">
                            <Button buttonResult={() => userDetail(b)}>Detail</Button>
                        </ButtonContainer>
                        <ButtonContainer flat="light" className="mr-2 p-2">
                            <Button color="text-primary" buttonResult={() => setAddUser(b)}><i className='fa fa-pen'></i></Button>
                            <Button color="text-danger" buttonResult={() => { setConfirmPopup({ data: b._id, key }) }}><i className='fa fa-trash'></i></Button>
                        </ButtonContainer>
                    </CardHeader>
                    {b.collapse && b?.child?.length ? <CardBody className="p-3">
                        {checkChild(b.child, [key])}
                    </CardBody> : null}
                </Card>
            ) : <Heading size={2}> User not found </Heading>
                : <CardLoader />}
            {addUser ? <Mypopup title={addUser?.id ? "Edit User" : "Add User"} onAddUser={() => setAddUser(null)} onClose={() => { setAddUser(null) }}>
                <AddUserPopup data={addUser} onResPopup={(e) => setMsgPopup(e)} onAddUser={() => setAddUser(null)} />
            </Mypopup> : null}
            {msgPopup ? <Mypopup title={msgPopup[0]} onAddUser={() => setAddUser(null)} onClose={() => { setAddUser(null) }}>
                <div className='text-center mt-3 '>
                    <p>{msgPopup[1]}</p>
                    <br />
                    <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                        setMsgPopup(null);
                        if (msgPopup[2] === "success") {
                            setUserUpdate(true)
                        }
                    }}>Ok</button>
                </div>
            </Mypopup> : null}
            {confirmPopup ? <Mypopup title={"Confirm!"} onClose={() => { setConfirmPopup(null) }}>
                <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                <div className="d-flex justify-content-center p-3">
                    <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={(e) => deleteUser(confirmPopup)}>Yes</button>
                    <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => { setConfirmPopup(null) }}>No</button>
                </div>
            </Mypopup> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    organization: state.organization,
    userInfo: state.userInfo,
    userList: state.userList
})
export default connect(mapStateToProps, null)(UserManagement)
