import React, { useEffect, useState } from 'react'
import { hexToHsl, hslToHex } from '../../../functions/functions'
import './wire.scss'
export function WireStraight(props) {
  const [night, setNight] = useState(false)
  const [joinColor, setJoinColor] = useState("#014070")
    const [config, setConfig] = useState({
        cssColor: "#ff0000",
        value: 70,
        label: "Label",
    })
    const [style, setStyle] = useState({
        rotateStep: 0,
        thickness: 4,
    })
    useEffect(() => {
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                    let hsl = hexToHsl(config1.cssColor)
                    if(hsl){
                        hsl = hslToHex(hsl.h,hsl.s,30);
                    }
                    setJoinColor(hsl)
                }
            }
            setConfig(config1)
        }
    }, [props.config])
    useEffect(() => {
        if (props?.style) {
            let style1 = { ...style }
            if ("rotateStep" in props.style) {
                  style1.rotateStep = parseInt(props.style.rotateStep);
            }
            if ("thickness" in props.style) {
                  style1.thickness = 2*parseInt(props.style.thickness);
            }
            setStyle(style1)
        }
    }, [props.style])
  return (
    <div className="position-relative h-100 w-100" style={{minheight: "50px"}}>
        <div className='position-absolute' style={
          style.rotateStep === 0 || style.rotateStep === 2 ?{
            backgroundColor: config.cssColor, width: style.thickness, height: "100%", top: "50%", left: "50%", transform: "translate(-50%,-50%)"
          }:{
            backgroundColor: config.cssColor, width: "100%", height: style.thickness, top: "50%", left: "50%", transform: "translate(-50%,-50%)"
          }
        }></div>
    </div >
  )
}
export function WireCurve(props) {
  const [night, setNight] = useState(false)
  const [joinColor, setJoinColor] = useState("#014070")
    const [config, setConfig] = useState({
        cssColor: "#ff0000",
        value: 70,
        label: "Label",
    })
    const [style, setStyle] = useState({
        rotateStep: 0,
        thickness: 4,
    })
    useEffect(() => {
        if (props?.config) {
            let config1 = { ...config }
            if ("color" in props.config && props.config?.color) {
                if (props.config.color.match("#")) {
                    config1.cssColor = props.config.color;
                    let hsl = hexToHsl(config1.cssColor)
                    if(hsl){
                        hsl = hslToHex(hsl.h,hsl.s,30);
                    }
                    setJoinColor(hsl)
                }
            }
            setConfig(config1)
        }
    }, [props.config])
    useEffect(() => {
        if (props?.style) {
            let style1 = { ...style }
            if ("rotateStep" in props.style) {
                  style1.rotateStep = parseInt(props.style.rotateStep);
            }
            if ("thickness" in props.style) {
                  style1.thickness = 2*parseInt(props.style.thickness);
            }
            setStyle(style1)
        }
    }, [props.style])
  return (
    <div minHeight style={{
        transition: "border-radius 1s, color 0.5s",
        minheight: '20px',
        borderStyle: 'solid',
        width: 'calc(100% - '+(style.thickness)+'px)',
        height: 'calc(100% - '+(style.thickness)+'px)',
        borderColor: (
          style.rotateStep === 0?
          "transparent transparent "+config.cssColor+" "+config.cssColor
          :style.rotateStep === 1?
          config.cssColor+" transparent transparent "+config.cssColor
          :style.rotateStep === 2?
          config.cssColor+" "+config.cssColor+" transparent transparent"
          :"transparent "+config.cssColor+" "+config.cssColor+" transparent"
        ),
        borderWidth: style.thickness+"px",
        boxSizing: "content-box",
        borderRadius: (
          style.rotateStep === 0?
          '0 0 0 20px':
          style.rotateStep === 1?
          '20px 0 0 0':
          style.rotateStep === 2?
          '0 20px 0 0':
          '0 0 20px 0'
        ),
    }}></div>
  )
}