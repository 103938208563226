export default function AQIMarker (props) {
  let state = {
    value: 0,
  }
  if("value" in props){
    state.value = props.value;
  }
  return(setMarker(state.value))
}

var setMarker = (value) => {
    let url = "";
    
    if (value !== -1) {
        let Colorvalue = parseInt(value),
        tableColor = "",
        red = 0,
        green = 255,
        blue = 0;
        if (Colorvalue > 25 && Colorvalue <= 50) {
        var per = ((Colorvalue - 25) / 25) * 100;
        red = (255 * per) / 100;
        green = 255;
        blue = 0;
        } else if (Colorvalue > 50 && Colorvalue <= 100) {
        var per = ((Colorvalue - 50) / 50) * 100;
        red = 255;
        green = 255 - (102 * per) / 100;
        blue = 0;
        } else if (Colorvalue > 100 && Colorvalue <= 200) {
        red = 255;
        green = 102 - (102 * (Colorvalue - 100)) / 100;
        blue = 0;
        } else if (Colorvalue > 200 && Colorvalue <= 300) {
        red = 200 - (55 * (Colorvalue - 200)) / 100;
        green = 0;
        blue = (255 * (Colorvalue - 225)) / 100;
        } else if (Colorvalue > 300) {
        red = 145;
        green = 0;
        blue = 191;
        }
        tableColor = "rgb(" + red + "," + green + "," + blue + ")";
        url = svgMarker(tableColor, value);
    } else {
        url = svgMarker("grey", "OFF");
    }
    return url;
}
var svgMarker = (color, value) => {
    let fontSize;
    if (value === "-1") {
      fontSize = "6500px";
    } else {
      fontSize = "5000px";
    }
    return ('<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="40px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 16746 24233" xmlns:xlink="http://www.w3.org/1999/xlink">' +
      "<defs>" +
      '<linearGradient id="marker_id0" gradientUnits="userSpaceOnUse" x1="8502.77" y1="874.807" x2="8502.77" y2="24687.1">' +
      '<stop offset="0" style="stop-opacity:0.588235; stop-color:white"/>' +
      '<stop offset="0.341176" style="stop-opacity:0; stop-color:white"/>' +
      '<stop offset="0.580392" style="stop-opacity:0; stop-color:white"/>' +
      '<stop offset="1" style="stop-opacity:0.54902; stop-color:black"/>' +
      "</linearGradient>" +
      '<radialGradient id="marker_id1" gradientUnits="userSpaceOnUse" cx="8658" cy="7859" r="7500" fx="8758" fy="7859">' +
      '<stop offset="0" style="stop-opacity:1; stop-color:white"/>' +
      '<stop offset="0.7" style="stop-opacity:1; stop-color:white"/>' +
      '<stop offset="1" style="stop-opacity:1; stop-color:#B3B3B3"/>' +
      "</radialGradient>" +
      "</defs>" +
      "<g>" +
      '<path fill="' +
      color +
      '" d="M8373 0c4624,0 8373,3749 8373,8373 0,3614 -2289,6693 -5497,7866l-2876 7994 -2876 -7994c-3208,-1173 -5497,-4252 -5497,-7866 0,-4624 3749,-8373 8373,-8373z"/>' +
      '<circle fill="url(#marker_id1)" cx="8373" cy="8373" r="6418"/>' +
      '<text dominant-baseline="middle" text-anchor="middle" x="50%" y="35%" fill="black" style="font-weight:bold;font-size:' +
      fontSize +
      ';font-family:Arial">' +
      value +
      "</text>" +
      // '<path fill="url(#marker_id0)" d="M8373 0c4624,0 8373,3749 8373,8373 0,3614 -2289,6693 -5497,7866l-2876 7994 -2876 -7994c-3208,-1173 -5497,-4252 -5497,-7866 0,-4624 3749,-8373 8373,-8373zm0 1956c3544,0 6418,2873 6418,6417 0,3544 -2874,6418 -6418,6418 -3544,0 -6417,-2874 -6417,-6418 0,-3544 2873,-6417 6417,-6417z"/>'+
      "</g>" +
      "</svg>"
    );
  };