import React, { useEffect, useState } from 'react'
import "./summary.scss"
import { useParams } from 'react-router-dom'
import Map from '../QuettaMap/map/map'
import OverviewMap from '../QSCP_OverviewMap/map/map'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../../components/card/card'
import { connect } from 'react-redux'
import { render } from '@testing-library/react'
import { getResource, summaryO, summaryOHistory } from '../../../services/utilities/events'
import { set } from 'lodash'
import moment, { duration } from 'moment'
import Mypopup from '../../../components/popup/mypopup'
import { Button, ButtonContainer } from '../../../components/arraybutton/arraybutton'
import { Table } from '@material-ui/core'
import MyDropDown, { MyDropDownBody } from '../../../components/dropdown/dropdown'
import { Sort } from '../../../functions/functions'
import { object } from '@amcharts/amcharts4/core'
import CardLoader from '../../../components/loader/cardLoader'
import InlineLoader from '../../../components/loader/inlineLoader'

const Summary = (props) => {
    let { levelId } = useParams()
    const [detail, setDetail] = useState(null)
    const [loader, setLoader] = useState(true)
    const [historyLoader, setHistoryLoader] = useState(false)
    const [historyArray, setHistoryArray] = useState([])
    const [dropdown, setDropdown] = useState(false)
    const [latest, setLatest] = useState(true)
    const [sortPopup, setSortPopup] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [acsending, setAcsending] = useState(true)
    const [sort, setSort] = useState("time")

    const [data, setData] = useState([])
    document.onclick = function (e) {
        if (hover2) {
            setSortPopup(!sortPopup)
        }
        else {
            setSortPopup(false)
        }
    };
    // "config": {
    //     "index": "822",
    //         "mapSetting": {
    //         "mapCenter": {
    //             "lat": "0",
    //                 "zoom": "13"
    //         }
    //     },
    //     "levelId": "QSCP_PS1",
    //         "hubId": [
    //             {
    //                 "hubId": "QSCP_Hub000000003",
    //                 "coordinates": {
    //                     "lat": "24° 51' 39.4776",
    //                     "lng": "66° 59' 25.8036"
    //                 },
    //                 "deviceId": "",
    //                 "isDynamic": false
    //             }
    //         ],
    //             "deviceId": [
    //                 "PoleClimb",
    //                 "all"
    //             ],
    //                 "param": null,
    //                     "label": "HEllo ",
    //                         "dataMode": {
    //         "filter": true
    //     }
    // },
    const [listArray, setListArray] = useState([])
    const [config, setConfig] = useState([
        {
            mapConfig: {
                label: "overview", type: "overview",
                "mapSetting": {
                    "mapCenter": {
                        "lat": "30.2229035",
                        "lng": "66.9239938",
                        "zoom": "9"
                    }
                },

            }
        },
        {
            mapConfig: {
                label: "Pole/Tower Climb", type: "PoleClimb", "mapSetting": {
                    "mapCenter": {
                        "lat": "30.2229035",
                        "lng": "66.9239938",
                        "zoom": "9"
                    }
                },
            }
        },
        {
            mapConfig: {
                label: "DB Door", type: "DoorDB", "mapSetting": {
                    "mapCenter": {
                        "lat": "30.2229035",
                        "lng": "66.9239938",
                        "zoom": "9"
                    }
                },
            }
        },
        {
            mapConfig: {
                label: "Temperature", type: "Temperature", "mapSetting": {
                    "mapCenter": {
                        "lat": "30.2229035",
                        "lng": "66.9239938",
                        "zoom": "9"
                    }
                },
            }
        },
        {
            mapConfig: {
                label: "Inverter", type: "Analyzer", "mapSetting": {
                    "mapCenter": {
                        "lat": "30.2229035",
                        "lng": "66.9239938",
                        "zoom": "9"
                    }
                },
            }
        },
        {
            mapConfig: {
                label: "Humidity", type: "Humidity", "mapSetting": {
                    "mapCenter": {
                        "lat": "30.2229035",
                        "lng": "66.9239938",
                        "zoom": "9"
                    }
                },
            }
        },
        {
            mapConfig: {
                label: "Fuel", type: "FuelTank", "mapSetting": {
                    "mapCenter": {
                        "lat": "30.1835814",
                        "lng": "66.978072",
                        "zoom": "9"
                    }
                },
            }
        },



    ])

    const configration = props.configration.configration.configuration
    const hubInfo = props.organization.organization.allhubs
    const deviceInfo = props.organization.organization.alldevices
    const levelInfo = props.organization.organization.levels
    useEffect(() => {
        let config1 = [...config]
        config1.map((configuration, key) => {
            deviceInfo.map(device => {
                if (configuration.mapConfig.type === device.type) {
                    let hub = {}
                    if (configuration.mapConfig.type === "Analyzer" && (device.name === "Output" || device.name === "Acc")) {
                        hub = hubInfo.find((hub) => hub._id === device.hubId)
                    }
                    if (configuration.mapConfig.type !== "Analyzer") {
                        hub = hubInfo.find((hub) => hub._id === device.hubId)
                    }
                    if (!Array.isArray(configuration.mapConfig.hubId)) {
                        configuration.mapConfig.hubId = [];
                    }
                    configuration.mapConfig.hubId.push({
                        "hubId": hub._id,
                        "coordinates": hub?.metadata?.coordinates,
                        "deviceId": "",
                        "isDynamic": false
                    });
                }



                // if (configuration.mapConfig.type === device.type) {
                //     configuration.mapConfig.deviceId = [device.type];
                //     let hub = hubInfo.find((hub) => hub._id === device.hubId)
                //     if (!Array.isArray(configuration.mapConfig.hubId)) {
                //         configuration.mapConfig.hubId = [];
                //     }
                //     configuration.mapConfig.hubId.push({
                //         "hubId": hub._id,
                //         "coordinates": hub?.metadata?.coordinates,
                //         "deviceId": "",
                //         "isDynamic": false
                //     });
                // }
            })


            if (configuration.mapConfig.type === "overview") {
                configuration.mapConfig.deviceId = "overview";
                if (!Array.isArray(configuration.mapConfig.levels)) {
                    configuration.mapConfig.levels = [];
                }
                levelInfo.map((level) => {
                    configuration.mapConfig.levels.push({
                        "level": level._id,
                        "coordinates": level?.metadata?.coordinates,
                        "deviceId": "",
                        "hubs": level.hubs,
                        "isDynamic": false
                    });
                }
                )
            }
        })
        setConfig(config1)
    }, [])

    const imgRenderFunc = async (src) => {
        let image = null
        if (src.endsWith(".svg")) {
            image = await getResource(src)
            image = { icon: image, type: 'svg' }
        }
        else if (src.endsWith(".js")) {
            let imagecheck = await getResource(src)
            image = eval("(" + imagecheck + ")")
            image = { icon: image({ value: 0 }), type: 'js' }
        }
        else {
            // image = await fetch("http://192.168.10.22:3000/api/v1/files/image/" + src)
            // image = await image.blob();
            // image = URL.createObjectURL(image);
            // image = { icon: image, type: null }
        }
        return image;
    }

    const liveUpdate = async () => {
        setLoader(true)
        let iconHeading = []
        await summaryO(levelId).then(async response => {
            await Promise.all(configration.Device.map(async device => {
                if (device.type === "PoleClimb") {
                    let aliceIcon = device.parameter[0].alias[1].icon
                    let image = await imgRenderFunc(aliceIcon)
                    iconHeading.push({ type: device.type, icon: image?.icon, keyName: "anti_pole", index: 0, heading: "Pole/Tower Climb", data: response.anti_pole, properties: [{ label: "Detected", value: "latestDetected" }] })
                } else if (device.type === "DoorDB") {
                    let image = await imgRenderFunc(device.icon)
                    iconHeading.push({ type: device.type, icon: image?.icon, keyName: "door", index: 1, heading: "DB Door", data: response.door, properties: [{ label: "Open", value: "latestOpen" }] })
                }
                else if (device.type === "Temperature") {
                    let image = await imgRenderFunc(device.icon)
                    iconHeading.push({ type: device.type, icon: image?.icon, index: 2, keyName: "temperature", heading: "Tempreture", data: response.temperature, properties: [{ label: "Crossed Threshold", value: "latestMax_Min" }] })
                }

                else if (device.type === "Analyzer") {
                    let image = await imgRenderFunc(device.icon)
                    iconHeading.push({ type: device.type, icon: image?.icon, keyName: "power", index: 3, heading: "Inverter", data: response.power, properties: [{ label: "Failure", value: "latestFailure" }] })
                }
                else if (device.type === "Meter") {
                    let image = await imgRenderFunc(device.icon)
                    iconHeading.push({ type: device.type, icon: image?.icon, keyName: "rectifier", index: 4, heading: "Rectifier", data: response.rectifier, properties: [{ label: "Failure", value: "latestFailure" }] })
                }
            }))
        })
        let sortArray = iconHeading.sort((a, b) => {
            if (a.index > b.index) {
                return 1;
            }
            if (a.index < b.index) {
                return -1;
            }
            return 0;
        });
        setListArray(sortArray)
        setLoader(false)
    }

    useEffect(() => {
        // setInterval(() => {
        liveUpdate()
        interval()
        // }, 100000);
    }, [])
    const interval = () => {
        setInterval(liveUpdate, 60000)
    }

    // useEffect(async () => {
    const History = async () => {
        setLatest(false)
        setHistoryLoader(true)
        let listArray1 = [...listArray]
        await summaryOHistory(levelId).then(response => {
            for (const key in response) {
                listArray1.map(array => {
                    if (array.keyName === key) {
                        array["data"] = Object.assign({}, array.data, { today: response[key].today })
                    }
                })
            }
        }
        )
        setListArray(listArray)
        setHistoryLoader(false)
    }
    // }, [])

    const imgReturnFunc = (data, value) => {
        if (data) {
            let img = data
            // if (img.type === "svg") {
            // console.log("in", img.icon.toString())
            return "data:image/svg+xml;base64," + btoa(img)
            // }
            // else {
            //     return data.icon
            // }
        }
    }

    useEffect(() => {
        let listArray1 = [...listArray]
        listArray1.map((array, key) => {
            array.data.today.map((data, key1) => {
                if (acsending) {
                    data.events?.sort((a, b) => (sort === "time" ? new Date(b?.[sort]) : b?.[sort]) - (sort === "time" ? new Date(a?.[sort]) : a?.[sort]))
                } else {
                    data.events?.sort((a, b) => (sort === "time" ? new Date(a?.[sort]) : a?.[sort]) - (sort === "time" ? new Date(b?.[sort]) : b?.[sort]))
                }
            })
        })
        setListArray(listArray1)
        // console.log("listArray1", listArray1)
    }, [sort, acsending])

    const todayDetail = (name, key = null, topKey = null) => {
        let listarray1 = { ...detail }
        listArray.map((detail, index) => {
            if (detail.index === topKey) {
                detail.data.today.map((rowdata, index) => {
                    if (index === key) {
                        if (!"collapse" in rowdata) {
                            rowdata["collapse"] = true
                        } else {
                            rowdata.collapse = !rowdata.collapse
                        }
                    } else {
                        rowdata["collapse"] = false
                    }
                })
            }
            // if (sort) {
            //     detail.data.today[0].events.sort((a, b) => a.duration - b.duration);
            // }

        })
        setDetail(listarray1)
    }
    return (
        <>
            <div className='row w-100 pl-2 m-0 mt-2 fade-in-right-onload ' style={{ display: detail ? "none" : "flex" }}>
                <div className='col-12 p-2'>
                    <Card>
                        <CardBody className='d-flex p-2 ' loader={loader && "loader"} >
                            {listArray.length ? listArray.map((device, key) =>
                                <div className='col px-1'>
                                    <InnerCard  >
                                        <div className='d-flex w-100 p-2' style={{ cursor: "pointer" }} onClick={() => setDetail({ data: device?.data, heading: device?.heading, index: key })}>
                                            <div className='col p-2 row m-0 align-items-center'>
                                                <div style={{ margin: (device?.type === "DoorDB" ? '-10%' : 0), height: device?.type === "DoorDB" ? "calc(90px + 20%)" : 90, width: device?.type === "DoorDB" ? "calc(90px + 20%)" : 90, overflow: 'hidden', }} >

                                                    <img style={{ width: '90%', height: '90%', objectFit: 'contain' }} src={imgReturnFunc(device.icon)} />
                                                </div>
                                                <div className='px-0 col'>
                                                    <div><Heading size={2}>{device.heading}</Heading></div>
                                                    <div>Alert</div>
                                                    {device.properties.length > 1 ?
                                                        <div><Heading size={1}>{device.properties[1].label + ": "}<span className='text-success'>{device.data.count[device.properties[1].value]}</span></Heading></div>
                                                        : <div>&nbsp;</div>
                                                    }
                                                </div>
                                                <div className="d-flex align-items-center" style={{ height: 100, overflow: 'hidden' }} >
                                                    <h1 style={{ fontSize: device.data.count[device.properties[0].value] > 9 ? "80px" : device.data.count[device.properties[0].value] > 99 ? "58px" : "90px", fontWeight: 'bolder' }} className={(device.data.count[device.properties[0].value] ? 'text-danger-0' : "text-success-1") + " text-center"} >{device.data.count[device.properties[0].value]}</h1>
                                                </div>
                                            </div>
                                            <i className="fas fa-angle-right p-2" style={{ color: "#2B70E9", fontSize: '24px' }}></i>
                                        </div>
                                    </InnerCard>
                                </div>
                            ) : null}

                        </CardBody>
                    </Card >
                </div >
                <div className='col-6 py-2 pl-0 pr-2' style={{ minHeight: '400px' }}>
                    <OverviewMap config={config[0].mapConfig} />
                </div>
                <div className='col-6 p-0' style={{ height: "calc(100vh - 280px)", minHeight: '400px' }}>
                    <div className='row h-100 m-0'>
                        {config.map((config1, key) =>
                            key > 0 &&
                            <div className='col-4 p-2' >
                                <Map config={config1.mapConfig} />
                            </div>
                        )}
                        {/* <div className='col-4 p-2'>
                            <Map config={config[2].mapConfig} />
                        </div>
                        <div className='col-4 p-2'>
                            <Map config={config[3].mapConfig} />
                        </div>
                        <div className='col-4 p-2'>
                            <Map config={config[4].mapConfig} />
                        </div>
                        <div className='col-4 p-2'>
                            <Map config={config[5].mapConfig} />
                        </div>
                        <div className='col-4 p-2'>
                            <Map config={config[6].mapConfig} />
                        </div> */}
                    </div>
                </div>
            </div >
            <div className='row w-100 pl-2 m-0 mt-2 fade-in-right-onload ' style={{ display: detail ? "flex" : "none" }}>
                <div className='col-12 p-2'>
                    <Card style={{ height: "calc(100vh - 180px" }}>
                        <CardHeader className='p-2 d-flex justify-content-between align-items-start'>
                            <button className='btn btn-light rounded-circle-px' onClick={() => setDetail(null)} ><i class="fas fa-arrow-left"></i></button>
                            <div className='d-flex col-2 p-0  justify-content-end'>
                                {!latest &&
                                    <>
                                        {
                                            historyLoader ? <InlineLoader width="40px" /> :
                                                <button onClick={() => History()} className='mr-1 btn btn-light rounded-circle-px'>
                                                    <i class="fas fa-sync-alt"></i>
                                                </button>
                                        }
                                        <MyDropDown MyDropDown openToggle={(e) => setHover2(e)} >
                                            <button className='mr-1 btn btn-light rounded-circle-px'>
                                                Sort <i class="mb-1 fas fa-angle-down"></i>
                                            </button>
                                            <MyDropDownBody open={sortPopup} right>

                                                <button className="userdropdown" onClick={() => setSort("time")}>
                                                    <div>
                                                        Last Update
                                                    </div>
                                                    {sort === "time" &&
                                                        <i class="p-1 fas fa-check"></i>}
                                                </button>
                                                <button className="userdropdown" onClick={() => setSort("duration")}>
                                                    <div>
                                                        Duration
                                                    </div>
                                                    {sort === "duration" &&
                                                        <i class="p-1 fas fa-check"></i>}
                                                </button>
                                                {/* <button className="userdropdown" onClick={() => setSort(2)}>
                                                     <div>
                                                         Location
                                                     </div>
                                                     {sort === 2 &&
                                                         <i class="p-1 fas fa-check"></i>}
                                                 </button> */}
                                                <div className='seprater'>
                                                    <button className="userdropdown" onClick={() => setAcsending(false)} >
                                                        <div>
                                                            <i class="mr-2 fas fa-sort-amount-down-alt"></i>Ascending
                                                        </div>
                                                        {!acsending &&
                                                            <i class="p-1 fas fa-check"></i>}
                                                    </button>
                                                </div>
                                                <button className="userdropdown" onClick={() => setAcsending(true)}>
                                                    <div>
                                                        <i class="mr-2 fas fa-sort-amount-up-alt"></i> Descending
                                                    </div>
                                                    {acsending &&
                                                        <i class="p-1 fas fa-check"></i>}
                                                </button>
                                            </MyDropDownBody>
                                        </MyDropDown>
                                    </>
                                }
                                <ButtonContainer>
                                    <Button buttonResult={(e) => { setLatest(true); }} active={latest ? true : false}>
                                        {/* <Button buttonResult={(e) => { setLatest(!latest); }} active={latest ? true : false}> */}
                                        Live
                                    </Button>
                                    <Button buttonResult={(e) => { History() }} active={!latest ? true : false}>
                                        History
                                    </Button>
                                </ButtonContainer>

                            </div>


                        </CardHeader>
                        <CardBody className='row m-0 px-1' style={{ height: "calc(100% - 80px)" }} loader={loader && "loader"} >
                            {listArray.length ? listArray.map((device, key) =>
                                <div className='col-4 mb-2 px-1 ' style={{ height: "50%" }}>
                                    <InnerCard className="overflow-auto" style={{ minHeight: 180, maxHeight: 400 }}>
                                        <div className='d-flex w-100 p-2' style={{ cursor: "pointer" }} onClick={() => setDetail({ data: device.data, heading: device.heading, index: key })}>
                                            <div className='col p-2 row m-0 align-items-center'>
                                                <div style={{ margin: (device.type === "DoorDB" ? '-5%' : 0), height: device.type === "DoorDB" ? "calc(70px + 10%)" : 50, width: device.type === "DoorDB" ? "calc(70px + 10%)" : 50, overflow: 'hidden', }} >

                                                    <img style={{ width: '80%', height: '80%', objectFit: 'contain' }} src={imgReturnFunc(device.icon)} />
                                                </div>
                                                <div className='px-0 col'>
                                                    <div><Heading size={2}>{device.heading}</Heading></div>
                                                    <div>Alert</div>
                                                </div>
                                                <div className="d-flex align-items-center" style={{ height: 50, overflow: 'hidden' }} >
                                                    <h1 style={{ fontSize: "40px" }} className={(device.data.count[device.properties[0].value] ? 'text-danger' : "text-success") + " text-center m-0"} >{device.data.count[device.properties[0].value]}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column align-items-end' >
                                            {latest ?
                                                <div className='col-12 p-2'>
                                                    {device.data.latest.length ?
                                                        <div className='d-flex mt-2'>
                                                            <table className='w-100' style={{ border: "solid rgb(200,200,200)", borderWidth: '1px 0 0 1px' }}>
                                                                <tr>
                                                                    <th style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                        Location                                                                    </th>
                                                                    <th style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }} >
                                                                        Duration
                                                                    </th>
                                                                    <th style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                        Last Update
                                                                    </th>
                                                                </tr>
                                                                {device.data.latest.map(name =>
                                                                    <tr style={{ width: 100 }}>
                                                                        <td style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                            <Heading size={2}>{name.hubName}</Heading>
                                                                        </td>
                                                                        <td style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                            {"duration" in name ? (moment.duration(name.duration, "milliseconds").months() ?
                                                                                moment.duration(name.duration, "milliseconds").months() +
                                                                                " months " : '') + (moment.duration(name.duration, "milliseconds").days() ?
                                                                                    moment.duration(name.duration, "milliseconds").days() +
                                                                                    " day " : '') +
                                                                                (moment.duration(name.duration, "milliseconds").hours() ?
                                                                                    moment.duration(name.duration, "milliseconds").hours() +
                                                                                    " hr " : '') +
                                                                                (moment.duration(name.duration, "milliseconds").minutes() ?
                                                                                    moment.duration(name.duration, "milliseconds").minutes() +
                                                                                    " min " : '') +
                                                                                (moment.duration(name.duration, "milliseconds").seconds() ?
                                                                                    moment.duration(name.duration, "milliseconds").seconds() +
                                                                                    " sec" : '')
                                                                                : "----"}
                                                                        </td>
                                                                        <td style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                            {moment(name.event.time).format("MMM D, YYYY LTS")}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </table>
                                                        </div> :
                                                        <div className='d-flex mt-2'>
                                                            No issues have been reported recently
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                // historyLoader ? <CardLoader /> :
                                                device?.data?.today?.length ?
                                                    device.data.today.map((name, key1) =>
                                                        <div className=' w-100 mb-1'>
                                                            <Card className="overflow-hidden">
                                                                <div className='d-flex justify-content-between align-items-center px-2' onClick={() => todayDetail(name, key1, key)}>
                                                                    <Heading size={2}>{name.locationName + "(" + name.hubName + ")"}</Heading>
                                                                    <div style={{ cursor: "pointer" }} >
                                                                        <i className={name.collapse ? "fas fa-angle-up " : "fas fa-angle-down " + " p-2"} style={{ color: "#2B70E9", fontSize: '24px' }}></i>
                                                                    </div>
                                                                </div>
                                                                {name.collapse ?
                                                                    <div className="h-100 row m-1" >
                                                                        <div className='col-12'>
                                                                            <div className='d-flex mt-2'>
                                                                                <table className='w-100' style={{ border: "solid rgb(200,200,200)", borderWidth: '1px 0 0 1px' }}>
                                                                                    <tr>
                                                                                        <th style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                                            Status
                                                                                        </th>
                                                                                        <th style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                                            Duration
                                                                                        </th>
                                                                                        <th style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                                            Last Update
                                                                                        </th>
                                                                                    </tr>
                                                                                    {name.events.length &&
                                                                                        name.events.map((data, key) =>
                                                                                            <tr >
                                                                                                <td style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                                                    <div style={{ width: "80px", fontSize: '14px' }} className={'text-light text-center px-1 rounded' + (data?.status === "normal" || data?.status === "close" || data?.status === "up" ? ' success-gradient' : ' danger-gradient')} >{data?.status}</div>
                                                                                                </td>
                                                                                                <td style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                                                    {data && "duration" in data ? (moment.duration(name.duration, "milliseconds").months() ?
                                                                                                        moment.duration(name.duration, "milliseconds").months() +
                                                                                                        " month " : '') +
                                                                                                        (moment.duration(data?.duration, "milliseconds").days() ?
                                                                                                            moment.duration(data?.duration, "milliseconds").days() +
                                                                                                            " day " : '') +
                                                                                                        (moment.duration(data?.duration, "milliseconds").hours() ?
                                                                                                            moment.duration(data?.duration, "milliseconds").hours() +
                                                                                                            " hr " : '') +
                                                                                                        (moment.duration(data?.duration, "milliseconds").minutes() ?
                                                                                                            moment.duration(data?.duration, "milliseconds").minutes() +
                                                                                                            " min " : '') +
                                                                                                        (moment.duration(data?.duration, "milliseconds").seconds() ?
                                                                                                            moment.duration(data?.duration, "milliseconds").seconds() +
                                                                                                            " sec" : '')
                                                                                                        : "----"}
                                                                                                </td>
                                                                                                <td style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                                                                    {moment(data?.time).format("MMM D, YYYY LTS")}
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div> : null}

                                                            </Card>
                                                        </div>
                                                    ) :
                                                    <div className='col-12'>
                                                        <div className='d-flex mt-2'>
                                                            No issues have been reported Today
                                                        </div>
                                                    </div>

                                            }
                                        </div>
                                    </InnerCard>
                                </div>
                            ) : null}

                        </CardBody>
                    </Card>
                </div>
            </div >



        </>
    )
}

const mapStateToProps = (state) => ({
    configration: state.configration,
    organization: state.organization

});
export default connect(mapStateToProps, null)(Summary)

