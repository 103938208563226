import React, { useEffect, useState } from 'react'
import DropContainer from '../drag&drop/dropContainer'
import './FreeHand.scss';
// import { idGenerator } from '../../functions/functions';
// import zoom from '../../assets/soundEffect/zoom.wav'
// import ComponenetMove from '../../assets/soundEffect/componenetMove.wav'
// import MapMove from '../../assets/soundEffect/mapHold.wav'
import MyDropDown, { MyDropDownBody } from '../dropdown/dropdown';
let isFocus = false
let changeHistory = []
function FreeHand(props) {
    const [loader, setLoader] = useState(false)
    const [tabSelection, setTabSelection] = useState(1)
    const [ComponentList, setComponentList] = useState([])
    const [pageConfig, setPageConfig] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [mouseDown, setMouseDown] = useState("")
    const [selectiveId, setSelectiveId] = useState(null)
    const [selectiveIndex, setSelectiveIndex] = useState(null)
    const [selectDetails, setSelectDetails] = useState([])
    
    // Sound Effects
    const [playSound, setPlaySound] = useState(false);
    const [moveSound, onMoveSound] = useState(false);
    const [mapMove, onMapMove] = useState(false);
    
    // contextMenu Uses
    const [contexMenuShow, setContexMenuShow] = useState(false)
    const [contextPosition, setContextPosition] = useState(null);
    const [copyComponent, setCopyComponent] = useState(null);
    // const [changeHistory, setChangeHistory] = useState([])
    // const [isFocus, setIsFocus] = useState(false)

    // Short key 
    const [shortKeys, setShortKeys] = useState(null)
    const [notMemorize, setNotMemorize] = useState(false)
    const contextMenuList = [
        {icon: "fa fa-copy", name: "Copy", keys: "Ctrl + C"},
        {icon: "fa fa-paste", name: "Paste", keys: "Ctrl + V"},
        {icon: "fa fa-copy", name: "Bring Forward", keys: "Shift + PgUp"},
        {icon: "fa fa-copy", name: "Bring To Front", keys: "Alt + PgUp"},
        {icon: "fa fa-copy", name: "Send Backward", keys: "Shift + PgDn"},
        {icon: "fa fa-copy", name: "Send To Back", keys: "Alt + PgDn"},
    ]

    useEffect(() => {
        setComponentList(props.ComponentList)
        window.addEventListener("mouseup", mouseUpHandler)
        return () => {
            window.removeEventListener("mouseup", mouseUpHandler)
        }
    }, [])

    useEffect(() => {
        setPageConfig(props.pageConfig)
        // if(!mouseDown){
            historyUpdateHandler("configUpdate")
        // }
    }, [props.pageConfig])
    useEffect(() => {
        setSelectiveIndex(props.selectiveIndex)
        if(props.selectiveIndex === null){
            setSelectDetails([])
        }
    }, [props.selectiveIndex])

    const historyUpdateHandler = (a) => {
        if(props.pageConfig && props.pageConfig.length && !notMemorize){
            let removeAfterIdx = null
            changeHistory.map((hist,idx) => {
                if(hist.active){
                    removeAfterIdx = idx
                }
                hist.active = false
            })
            if(removeAfterIdx !== null && removeAfterIdx < changeHistory.length-1){
                changeHistory.splice(removeAfterIdx+1, (changeHistory.length-1) - removeAfterIdx+1)
            }
            if(changeHistory.length === 100){
                changeHistory.splice(0,1)
            }
            changeHistory.push({active: true, data: JSON.stringify(props.pageConfig)})
        }else{
            setNotMemorize(false)
        }
    }

    useEffect(() => {
        setEditMode(props.editMode)
    }, [props.editMode])

    // Handle height,width,move and style and css  
    useEffect(() => {
        let moveWidth = 0, moveHeight = 0
        const element = document.getElementById("sketchBoard").childNodes[0]
        let componentWidth = 0
        let componentHeight = 0
        let prevHeight = 0
        let prevWidth = 0
        const handleMove = (event) => {
            setNotMemorize(true)
            if(mouseDown === "move"){
                const { top, left, width, height } = element.getBoundingClientRect()
                let { top:top1, left:left1, width:width1, height:height1 } =  event.target.getBoundingClientRect()
                if(!componentWidth || !componentHeight){
                    componentWidth = event.clientX - left1
                    componentHeight = event.clientY - top1
                }
                let newHeight = (event.clientY  - top - componentHeight) / height * 100
                let newWidth = (event.clientX - left - componentWidth) / width * 100
                newHeight = newHeight.toFixed(1)
                newHeight = parseFloat(newHeight)
                newWidth = newWidth.toFixed(1)
                newWidth = parseFloat(newWidth)
                if(newHeight%0.5 === 0 && prevHeight !== newHeight){
                    prevHeight = newHeight
                    onChangeHandler({
                        target: { value: newHeight },
                        name: "top"
                    }, "style")
                    // onMoveSound(true)
                }
                else if(newWidth%0.5 === 0 && prevWidth !== newWidth){
                    prevWidth = newWidth
                    onChangeHandler({
                        target: { value: newWidth },
                        name: "left"
                    }, "style")
                    // onMoveSound(true)
                }
            }
            if (mouseDown === "width" || mouseDown === "size" || mouseDown === "width-height") {
                // const sketchBoard = document.getElementById("sketchBoard")
                // const { width } = sketchBoard.getBoundingClientRect()
                const element = document.getElementById(selectiveId).parentNode
                const { left } = element.getBoundingClientRect()
                const sketchBoard = element.parentNode
                const { width } = sketchBoard.getBoundingClientRect()
                // let newWidth = (event.clientX - left)
                let newWidth = (event.clientX - left) / width * 100
                if (newWidth > 100) {
                    newWidth = 100
                } else if (newWidth < 0) {
                    newWidth = 0
                }
                newWidth = parseFloat(newWidth.toFixed(1))
                if (moveWidth !== newWidth && newWidth%0.5 === 0) {
                    moveWidth = newWidth
                    onChangeHandler({
                        target: { value: newWidth },
                        name: "width"
                    }, "style")
                }
            }
            if (mouseDown === "height" || mouseDown === "width-height") {
                const element = document.getElementById(selectiveId).parentNode
                const { top } = element.getBoundingClientRect()
                const sketchBoard = element.parentNode
                const { height } = sketchBoard.getBoundingClientRect()
                let newHeight = (event.clientY - top) / height * 100
                if (newHeight > 100) {
                    newHeight = 100
                } else if (newHeight < 0) {
                    newHeight = 0
                }
                newHeight = parseFloat(newHeight.toFixed(1))
                if (moveHeight !== newHeight && newHeight%0.5 === 0) {
                    moveHeight = newHeight
                    onChangeHandler({
                        target: { value: newHeight },
                        name: "height"
                    }, "style")
                }
            }
        }
        if (mouseDown) {
            window.addEventListener("mousemove", handleMove)
            if (mouseDown === "auto-height") {
                onChangeHandler({
                    target: { value: '' },
                    name: "height"
                }, "style")
            }
        } else {
            window.removeEventListener("mousemove", handleMove)
        }

        return () => {
            window.removeEventListener("mousemove", handleMove)
        }
    }, [mouseDown])

    //Mouse Up Stop Functions
    const mouseUpHandler = (e) => {
        setMouseDown(null)
        if(e.button === 0 && !shortKeys?.includes("space")){
            // historyUpdateHandler("mouseUp")
        }
    }
    // Mouse Down Functions
    const mouseDownHandler = (e, index, index2, type, id) => {
        if(e.button === 0){
            onChangeHandler({index,index2},"setIndex")
            setSelectiveId(id)
            setMouseDown(type)
        }else if(e.button === 2){
            onChangeHandler({index,index2},"setIndex")
        }
    }
    
    const componentContext = (e) => {
        setContexMenuShow(true)
        const element = document.getElementById("sketchBoard").childNodes[0]
        const { top, left, width, height} = element.getBoundingClientRect()
        const {clientX, clientY} = e
        let elem = document.getElementById("contextMenu")
        let x = (clientX - left)/width*100
        let y = (clientY - top)/height*100
        x = x.toFixed(1)
        x = parseFloat(x)
        y = y.toFixed(1)
        y = parseFloat(y)
        elem.style.left = x+"%"
        elem.style.top = y+"%"
        setContextPosition({x,y})
    }

    const contextMenuHandler = (name) => {
        setLoader(true)
        if(name === "Copy"){
            setCopyComponent(pageConfig[selectiveIndex])
        }else if(name === "Paste" && copyComponent){
            let style = {...copyComponent.style}
            let css = {...style.css}
            let contextPosition1 = null
            if(contextPosition){
                contextPosition1 = {x: contextPosition?.x+0.5, y: contextPosition?.y+0.5}
            }else{
                let x = (css?.left)? css?.left: "0%" 
                    x = x.split("%")[0]
                    x = parseInt(x)
                    x += 0.5
                    let y = (css?.top)? css?.top: "0%"
                    y = y.split("%")[0]
                    y = parseInt(y)
                    y += 0.5
                contextPosition1 = {x, y}
            }
            css.left = contextPosition1.x+"%"
            css.top = contextPosition1.y+"%"
            style.css = css
            let newData = {
                style,
                config: copyComponent.config,
                type: copyComponent.type,
            }
            newData.config.index = ""
            props.addComponent(newData, null, false)
            setContextPosition(contextPosition1)
        }else if(name === "Bring Forward" || name === "Send Backward" || name === "Bring To Front" || name === "Send To Back"){
            const pageConfig1 = [...pageConfig];
            const itemToMove = pageConfig1.splice(selectiveIndex, 1)[0];
            if(name === "Bring Forward" && pageConfig1.length > selectiveIndex){
                pageConfig1.splice((selectiveIndex + 1), 0, itemToMove);
                onChangeHandler(selectiveIndex +1, "setIndex");
                onChangeHandler(pageConfig1, "arrayUpdate")
            }else if(name === "Send Backward" && 0 < selectiveIndex){
                pageConfig1.splice((selectiveIndex - 1), 0, itemToMove);
                onChangeHandler(selectiveIndex-1, "setIndex");
                onChangeHandler(pageConfig1, "arrayUpdate")
            }else if(name === "Bring To Front" && pageConfig1.length > selectiveIndex){
                pageConfig1.push(itemToMove);
                onChangeHandler(pageConfig1.length-1, "setIndex");
                onChangeHandler(pageConfig1, "arrayUpdate")
            }else if(name === "Send To Back"&& 0 <= selectiveIndex){
                pageConfig1.unshift(itemToMove)
                onChangeHandler(0, "setIndex");
                onChangeHandler(pageConfig1, "arrayUpdate")
            }   
        }else if(name === "Undo" || name === "Redo"){
            setNotMemorize(true)
            let activeIndex = null
            // let changeHistory1 = [...changeHistory]
            let changeHistory2 = [...changeHistory]
            changeHistory2.map((rowData, ind) => {
                if(rowData.active){
                    if(name === "Undo" && ind > 0){
                        changeHistory[ind].active = false
                        activeIndex = ind-1
                    }else if(name === "Redo" && ind < changeHistory2.length-1){
                        changeHistory[ind].active = false
                        activeIndex = ind+1
                    }
                }
            })
            if(activeIndex !== null){
                changeHistory[activeIndex].active = true   
                let dataConvert = JSON.parse(changeHistory[activeIndex].data)
                onChangeHandler(dataConvert, "arrayUpdate")
            }
        }
        setTimeout(() => { setLoader(false) }, 10)
    }

    // UIMoveable
    useEffect(() => {
        const element = document.getElementById("sketchBoard")
        let dragStartPosition = null
        let mapStartPosition = null
        let spaceBarPress = false
        let mapControll = {
            zoom: 1,
            mousePosition: {x:0,y:0},
            mapPosition: {x:0,y:0}
        };
        let isCtrlPress = false
        const wheelHandler = (e) => {
            if(isCtrlPress){
                e.preventDefault()
                const element1 = document.getElementById("FreeHand")
                const { top:top1, left:left1, width:width1, height:height1 } = element1.getBoundingClientRect()
                const positionX = (e.clientX - left1)
                const positionY = (e.clientY - top1)
                mapControll.mousePosition = {
                    x: positionX,
                    y: positionY,
                }
                if(e.deltaY > 0){
                    if(mapControll.zoom > 0.2){
                        if(mapControll.zoom <= 1){
                            mapControll.zoom -= 0.05
                        }else{
                            mapControll.zoom -= 0.2
                        }
                        // setPlaySound(true);
                    }
                }else{
                    mapControll.zoom += 0.05
                    // setPlaySound(true);
                }
                let mapElem = document.getElementById("FreeHand")
                if(mapElem){
                    mapElem.style.transform = "scale("+mapControll.zoom+") translate("+mapControll.mapPosition.x+"px,"+mapControll.mapPosition.y+"px)"
                    mapElem.style.transformOrigin = mapControll.mousePosition.x+"px "+mapControll.mousePosition.y+"px"
                }
            }
        }
        const mapMouseDownHandler = (e) => {
            if(e.button === 1 || (e.button === 0 && spaceBarPress)){
                e.preventDefault()
                dragStartPosition = {x: e.clientX, y: e.clientY}
                mapStartPosition = {x: mapControll.mapPosition.x, y: mapControll.mapPosition.y}
                element.style.cursor = "grabbing";
                element.addEventListener("mousemove", mapMoveHandler)
            }
        }
        const mapMouseUpHandler = () => {
            dragStartPosition = null
            element.style.cursor = "default";
            element.removeEventListener("mousemove", mapMoveHandler)
        }
        const mapMoveHandler = (e) => {
            let mapControll1 = {...mapControll}
            let x =  mapStartPosition.x + (e.clientX - dragStartPosition.x)
            let y =  mapStartPosition.y + (e.clientY - dragStartPosition.y)
            // mapControll1.mousePosition = {x:0,y:0}
            mapControll1.mapPosition = {x,y}
            mapControll = mapControll1
            let mapElem = document.getElementById("FreeHand")
            if(mapElem){
                // onMapMove(true)
                mapElem.style.transform = "scale("+mapControll.zoom+") translate("+x+"px,"+y+"px)"
                mapElem.style.transformOrigin = mapControll.mousePosition.x+"px "+mapControll.mousePosition.y+"px"
            }
        }
        const keyPressHandler = (e) => {
            // e.preventDefault()
            let keys = []
            if (e.key == " " ||
            e.code == "Space" ||      
            e.keyCode == 32      
            ) {
                element.style.cursor = "grab";
                spaceBarPress = true
            }
            if(e.altKey){
                keys.push("alt")
            }
            if(e.ctrlKey){
                keys.push("ctrl")
                isCtrlPress = true
            }
            if(e.shiftKey){
                keys.push("shift")
            }
            if(isFocus){
                if(e.key === "c"){
                    keys.push("c")
                }else if(e.key === "v"){
                    keys.push("v")
                }else if(e.key === "z"){
                    keys.push("z")
                }else if(e.key === "y"){
                    keys.push("y")
                }else if(e.key === "PageUp"){
                    keys.push("pgup")
                }else if(e.key === "PageDown"){
                    keys.push("pgdn")
                }
            }
            setShortKeys(keys)
        }
        const keyUpHandler = (e) => {
            setShortKeys(null)
            spaceBarPress = false
            isCtrlPress = false
            element.style.cursor = "default";
        }
            element.addEventListener("mousedown", mapMouseDownHandler)
            element.addEventListener("mouseup", mapMouseUpHandler)
            element.addEventListener("mouseleave", mapMouseUpHandler)
            element.addEventListener("wheel", wheelHandler)
            window.addEventListener("keydown", keyPressHandler)
            window.addEventListener("keyup", keyUpHandler)
      return () => {
        element.removeEventListener("mousemove", mapMoveHandler)
        element.removeEventListener("mousedown", mapMouseDownHandler)
        element.removeEventListener("mouseup", mapMouseUpHandler)
        element.removeEventListener("mouseleave", mapMouseUpHandler)
        element.removeEventListener("wheel", wheelHandler)
        window.removeEventListener("keyup", keyUpHandler)
      }
    }, [])

    useEffect(() => {
        if(shortKeys){
                if(shortKeys.includes("c") && shortKeys.includes("ctrl")){
                    contextMenuHandler("Copy")
                    setContextPosition(null)
                }else if(shortKeys.includes("v") && shortKeys.includes("ctrl")){
                    contextMenuHandler("Paste")
                }else if(shortKeys.includes("z") && shortKeys.includes("ctrl")){
                    contextMenuHandler("Undo")
                }else if(shortKeys.includes("y") && shortKeys.includes("ctrl")){
                    contextMenuHandler("Redo")
                }else if(shortKeys.includes("pgup") && shortKeys.includes("alt")){
                    contextMenuHandler("Bring To Front")
                }else if(shortKeys.includes("pgdn") && shortKeys.includes("alt")){
                    contextMenuHandler("Send To Back")
                }else if(shortKeys.includes("pgup") && shortKeys.includes("shift")){
                    contextMenuHandler("Bring Forward")
                }else if(shortKeys.includes("pgdn") && shortKeys.includes("shift")){
                    contextMenuHandler("Send Backward")
                }else if(shortKeys.includes("z") && shortKeys.includes("ctrl") && shortKeys.includes("shift")){
                    contextMenuHandler("Redo")
                }
        }
    }, [shortKeys])

    const onChangeHandler = (e, type) => {
        if('onChange' in props){
            props.onChange(e, type)
        }
    }
    const onItemSelect = (e ,index, index2, compo, elem) => {
        props.selectComponents(index, index2);
        const element = e.target.parentNode
        const { height, width } = element.getBoundingClientRect()
        setSelectDetails([{
            e,
            index,
            index2,
            configIndex: compo?.config?.index,
            styleTag: elem.styleTag,
            style: compo?.style,
            overlayStyle: {
                height,
                width,
                top: compo?.style.css.top,
                left: compo?.style.css.left
            } 
        }]);
    }
  return (
    // !loader &&
    <div
    className={'p-2 w-100 h-100 position-relative overflow-hidden'+(editMode?' userNonSelect':'')}
    id={"sketchBoard"} 
    onClick={()=> setContexMenuShow(false)}
    >
        {/* {playSound &&
    <audio name="zoomInOut" autoPlay volume="0.2" onEnded={() => setPlaySound(false)}>
        <source src={zoom} type="audio/mpeg" />
    </audio>
}
        {moveSound &&
    <audio autoPlay volume="0.2" onEnded={() => onMoveSound(false)}>
        <source src={ComponenetMove} type="audio/mpeg" />
    </audio>
}
        {mapMove &&
    <audio autoPlay volume="0.2" onEnded={() => onMapMove(false)}>
        <source src={MapMove} type="audio/mpeg" />
    </audio>
} */}
        <div tabIndex={3} id='FreeHand' onFocus={()=>isFocus = true} onBlur={()=>isFocus = false} className={editMode?"active":""} onContextMenu={(e)=>{e.preventDefault(); componentContext(e)}}
        // onContextMenu={(e)=>}
        >
            {editMode?
                <DropContainer PageConfig={(e, target) => props.addComponent(e, target)}>
                    {pageConfig.map((compo, index) =>
                        ComponentList.map((elem, index2) => 
                        compo.type === elem.type &&
                        <div
                            key={index}
                            className={"FreeComponent editMode mt-3" + (selectiveIndex === index ? " active " : " ") + (elem.type !== "DynamicLabel" ? compo.style.class : "")}
                            style={compo.style.css}
                        >
                            <span className='ribbon'>
                                <i className="fas fa-pen" onClick={() => props.selectComponents(index, index2)}></i>
                                <i className='fa fa-trash' onClick={() => props.deleteComponent(index)}></i>
                            </span>
                            {elem.styleTag.includes("width") ?
                                <button className='stretch-width' onMouseDown={(e) => mouseDownHandler(e ,index, index2, "width", compo?.config?.index)}>
                                    <i className='fa fa-angle-right'></i>
                                </button>
                                : null}
                            {elem.styleTag.includes("height") ?
                                <>
                                    {"height" in compo.style.css ?
                                        <button className='stretch-auto-height' onMouseDown={(e) => mouseDownHandler(e ,index, index2, "auto-height", compo?.config?.index)}>
                                            Auto
                                        </button>
                                        : null}
                                    <button className='stretch-height' onMouseDown={(e) => mouseDownHandler(e ,index, index2, "height", compo?.config?.index)}>
                                        <i className='fa fa-angle-down'></i>
                                    </button>
                                </>
                                : null}
                            {elem.styleTag.includes("size") || (elem.styleTag.includes("width") && elem.styleTag.includes("height")) ?
                                <button className='stretch-size' onMouseDown={(e) => mouseDownHandler(e ,index, index2, elem.styleTag.includes("size") ? "size" : "width-height", compo?.config?.index)}>
                                    <i className='fa fa-angle-down'></i>
                                </button>
                                : null}
                            <div
                                className='position-relative w-100 h-100'
                                id={compo?.config?.index}
                                // onClick={((e) => e.button === 0 && onChangeHandler({index,index2}, "setIndex"))}
                                onDoubleClick={() => props.selectComponents(index, index2)}
                                // onMouseDown={(e) => mouseDownHandler(e ,index, index2, "move", compo?.config?.index)}
                            >
                                <elem.component config={compo.config} className={compo.style.class} style={compo.style.css} />
                                <div
                                className='selectArea position-absolute'
                                style={{top: 0, left: 0, right: 0, bottom: 0}}
                                onMouseDown={(e) => mouseDownHandler(e ,index, index2, "move", compo?.config?.index)}></div>
                            </div>
                        </div>
                        // <div
                        //     key={index}
                        //     className={"FreeComponent editMode mt-3" + (selectiveIndex === index ? " active " : " ") + (elem.type !== "DynamicLabel" ? compo.style.class : "")}
                        //     style={compo.style.css}

                        // >
                        //     <div
                        //         // tabindex={index}
                        //         className='position-relative w-100 h-100'
                        //         id={compo?.config?.index}
                        //         // onClick={((e) => e.button === 0 && onChangeHandler({index,index2}, "setIndex"))}
                        //         // onDoubleClick={() => props.selectComponents(index, index2)}
                        //         // onMouseDown={(e) => onItemSelect(e ,index, index2, compo, elem)}
                        //         // onFocus={(e) => onItemSelect(e ,index, index2, compo, elem)}
                        //     >
                        //         <elem.component config={compo.config} className={compo.style.class} style={compo.style.css} />
                        //         <div
                        //         className='position-absolute'
                        //         style={{top: 0, left: 0, right: 0, bottom: 0}}
                        //         onMouseDown={(e) => mouseDownHandler(e ,index, index2, "move", compo?.config?.index)}></div>
                        //     </div>
                        // </div>
                        )
                    )}
                    {/* {selectDetails.length? 
                        // <div className={'FreeComponent editMode mt-3 active '+ selectDetails[0].style.class} style={selectDetails[0].overlayStyle}>
                        <div className={'FreeComponent editMode mt-3 active '+ selectDetails[0].style.class} style={{top: selectDetails[0].style.css.top, left: selectDetails[0].style.css.left, width: selectDetails[0].e.target.parentNode.getBoundingClientRect().width, height: selectDetails[0].e.target.parentNode.getBoundingClientRect().height}}>
                            <span className='ribbon'>
                                <i className="fas fa-pen"
                                onClick={() => props.selectComponents(selectDetails[0].index, selectDetails[0].index2)}
                                ></i>
                                <i className='fa fa-trash'
                                onClick={() => props.deleteComponent(selectDetails[0].index)}
                                ></i>
                            </span>
                                
                            {selectDetails[0].styleTag.includes("width") ?
                                <button className='stretch-width' 
                                onMouseDown={(e) => mouseDownHandler(selectDetails[0].e ,selectDetails[0].index, selectDetails[0].index2, "width", selectDetails[0].configIndex)}
                                >
                                    <i className='fa fa-angle-right'></i>
                                </button>
                                : null}
                            {selectDetails[0].styleTag.includes("height") ?
                                <>
                                    {"height" in selectDetails[0].style.css ?
                                        <button className='stretch-auto-height' 
                                        onMouseDown={(e) => mouseDownHandler(selectDetails[0].e ,selectDetails[0].index, selectDetails[0].index2, "auto-height", selectDetails[0].configIndex)}
                                        >
                                            Auto
                                        </button>
                                    : null}
                                    <button className='stretch-height' 
                                    onMouseDown={(e) => mouseDownHandler(selectDetails[0].e ,selectDetails[0].index, selectDetails[0].index2, "height", selectDetails[0].configIndex)}
                                    >
                                        <i className='fa fa-angle-down'></i>
                                    </button>
                                </>
                                : null}
                            {selectDetails[0].styleTag.includes("size") || (selectDetails[0].styleTag.includes("width") && selectDetails[0].styleTag.includes("height")) ?
                                <button className='stretch-size'
                                onMouseDown={(e) => mouseDownHandler(selectDetails[0].e ,selectDetails[0].index, selectDetails[0].index2, selectDetails[0].styleTag.includes("size") ? "size" : "width-height", selectDetails[0].configIndex)}
                                >
                                    <i className='fa fa-angle-down'></i>
                                </button>
                                : null}
                            <div
                                className='selectArea position-absolute'
                                style={{top: 0, left: 0, right: 0, bottom: 0}}
                                onMouseDown={(e) => mouseDownHandler(selectDetails[0].e ,selectDetails[0].index, selectDetails[0].index2, "move", selectDetails[0].configIndex)}
                                ></div>
                        </div>
                    : null} */}
                    <div id="contextMenu">
                        <MyDropDown openToggle={()=>null}>
                            <div style={{width: '200px'}}></div>
                            <MyDropDownBody open={contexMenuShow} left className="w-100 p-0">
                                {contextMenuList.map((item,key) => 
                                    <div key={key} className='row m-0 context-items' onClick={()=>contextMenuHandler(item.name)}>
                                        <i className={'col-2 '+item.icon}></i>
                                        <div className='col d-flex justify-content-between'>
                                            <span>
                                                {item.name}
                                            </span>
                                            <span className="shortKyes">
                                                {item.keys}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </MyDropDownBody>
                        </MyDropDown>
                    </div>
                </DropContainer>:
                pageConfig.map((compo, index) =>
                    ComponentList.map((elem, index2) => 
                    compo.type === elem.type &&
                    <div
                        key={index}
                        className={"FreeComponent mt-3"+ (elem.type !== "DynamicLabel" ? compo.style.class : "")}
                        style={compo.style.css}
                    >
                            <elem.component config={compo.config} className={compo.style.class} style={compo.style.css} />
                    </div>
                    )
                )
            }
        </div>
    </div>
  )
}

export default FreeHand