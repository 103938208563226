import React, { useEffect, useState } from 'react'
import "./summary.scss"
import { useParams } from 'react-router-dom'
import OverviewMap from './map'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../../components/card/card'
import { connect } from 'react-redux'
import { render } from '@testing-library/react'
import { getResource, summaryO } from '../../../services/utilities/events'
import { set } from 'lodash'
import moment, { duration } from 'moment'
import Mypopup from '../../../components/popup/mypopup'
import { Button, ButtonContainer } from '../../../components/arraybutton/arraybutton'
import { Table } from '@material-ui/core'
import MyDropDown, { MyDropDownBody } from '../../../components/dropdown/dropdown'
import { Sort } from '../../../functions/functions'

const Summary = (props) => {
    let { levelId } = useParams()
    const [detail, setDetail] = useState(null)
    const [loader, setLoader] = useState(true)
    const [dropdown, setDropdown] = useState(false)
    const [latest, setLatest] = useState(true)
    const [sortPopup, setSortPopup] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [acsending, setAcsending] = useState(true)
    const [sort, setSort] = useState("time")

    const [data, setData] = useState([])
    document.onclick = function (e) {
        if (hover2) {
            setSortPopup(!sortPopup)
        }
        else {
            setSortPopup(false)
        }
    };
    const [listArray, setListArray] = useState([])
    const [config, setConfig] = useState([
        {
            mapConfig: {
                label: "Overview", type: "overview",
                "mapSetting": {
                    "mapCenter": {
                        "lat": "21.5159",
                        "lng": "57.0924",
                        "zoom": "6"
                    }
                },

            }
        },
        // {
        //     mapConfig: {
        //         label: "Temperature", type: "Temperature", "mapSetting": {
        //             "mapCenter": {
        //                 "lat": "30.2229035",
        //                 "lng": "66.9239938",
        //                 "zoom": "9"
        //             }
        //         },
        //     }
        // },
        // {
        //     mapConfig: {
        //         label: "DB Door", type: "DoorDB", "mapSetting": {
        //             "mapCenter": {
        //                 "lat": "30.2229035",
        //                 "lng": "66.9239938",
        //                 "zoom": "9"
        //             }
        //         },
        //     }
        // },
        // {
        //     mapConfig: {
        //         label: "Power", type: "Analyzer", "mapSetting": {
        //             "mapCenter": {
        //                 "lat": "30.2229035",
        //                 "lng": "66.9239938",
        //                 "zoom": "9"
        //             }
        //         },
        //     }
        // },
        // {
        //     mapConfig: {
        //         label: "Humidity", type: "Humidity", "mapSetting": {
        //             "mapCenter": {
        //                 "lat": "30.2229035",
        //                 "lng": "66.9239938",
        //                 "zoom": "9"
        //             }
        //         },
        //     }
        // }
    ])

    const configration = props.configration.configration.configuration
    const hubInfo = props.organization.organization.allhubs
    const deviceInfo = props.organization.organization.alldevices
    const levelInfo = props.organization.organization.levels
    useEffect(() => {
        let config1 = [...config]
        config1.map((configuration, key) => {
            deviceInfo.map(device => {
                if (configuration.mapConfig.type === device.type) {
                    configuration.mapConfig.deviceId = [device.type];
                    let hub = hubInfo.find((hub) => hub._id === device.hubId)
                    if (!Array.isArray(configuration.mapConfig.hubId)) {
                        configuration.mapConfig.hubId = [];
                    }
                    configuration.mapConfig.hubId.push({
                        "hubId": hub._id,
                        "coordinates": hub?.metadata?.coordinates,
                        "deviceId": "",
                        "isDynamic": false
                    });
                }
            })
            // ?asdfadf
            // asdfasdfas 
            // asdfas
            if (configuration.mapConfig.type === "overview") {
                configuration.mapConfig.deviceId = "overview";
                // if (!Array.isArray(configuration.mapConfig.levels)) {
                    configuration.mapConfig.levels = [];
                // }
                levelInfo.map((level) => {
                    configuration.mapConfig.levels.push({
                        "level": level._id,
                        "coordinates": level?.metadata?.coordinates,
                        "deviceId": "",
                        "hubs": level.hubs,
                        "isDynamic": false
                    });
                }
                )
            }
        })
        setConfig(config1)
    }, [])

    const imgRenderFunc = async (src) => {
        let image = null
        if (src.endsWith(".svg")) {
            image = await getResource(src)
            image = { icon: image, type: 'svg' }
        }
        else if (src.endsWith(".js")) {
            let imagecheck = await getResource(src)
            image = eval("(" + imagecheck + ")")
            image = { icon: image({ value: 0 }), type: 'js' }
        }
        else {
            // image = await fetch("http://192.168.10.22:3000/api/v1/files/image/" + src)
            // image = await image.blob();
            // image = URL.createObjectURL(image);
            // image = { icon: image, type: null }
        }
        return image;
    }
    // useEffect(async () => {

    // }, [])
    useEffect(async () => {
        setLoader(true)
        let iconHeading = []
        await summaryO(levelId).then(async response => {
            await Promise.all(configration.Device.map(async device => {
                if (device.type === "Temperature") {
                    let image = await imgRenderFunc(device.icon)
                    iconHeading.push({ type: device.type, icon: image?.icon, index: 2, heading: "Temperature", data: response.temperature, properties: [{ label: "Crossed Threshold", value: "latestMax_Min" }] })
                }
                else if (device.type === "Humidity") {
                    let image = await imgRenderFunc(device.icon)
                    iconHeading.push({ type: device.type, icon: image?.icon, index: 3, heading: "Humidity", data: response.humidity, properties: [{ label: "Crossed Threshold", value: "latestMax_Min" }] })
                }
                else if (device.type === "Meter") {
                    let image = await imgRenderFunc(device.icon)
                    iconHeading.push({ type: device.type, icon: image?.icon, index: 4, heading: "Battery", data: response.battery, properties: [{ label: "Failure", value: "latestFailure" }] })
                }
            }))
        })
        let sortArray = iconHeading.sort((a, b) => {
            if (a.index > b.index) {
                return 1;
            }
            if (a.index < b.index) {
                return -1;
            }
            return 0;
        });


        setListArray(sortArray)
        setLoader(false)
    }, [])

    const imgReturnFunc = (data, value) => {
        if (data) {
            let img = data
            return "data:image/svg+xml;base64," + btoa(img)
        }
    }

    useEffect(() => {
        let listArray1 = [...listArray]
        listArray1.map((array, key) => {
            array.data.today?.map((data, key1) => {
                if (acsending) {
                    data.events?.sort((a, b) => (sort === "time" ? new Date(b?.[sort]) : b?.[sort]) - (sort === "time" ? new Date(a?.[sort]) : a?.[sort]))
                } else {
                    data.events?.sort((a, b) => (sort === "time" ? new Date(a?.[sort]) : a?.[sort]) - (sort === "time" ? new Date(b?.[sort]) : b?.[sort]))
                }
            })
        })
        setListArray(listArray1)
    }, [sort, acsending])

    const todayDetail = (name, key = null, topKey = null) => {
        let listarray1 = { ...detail }
        listArray.map((detail, index) => {
            if (detail.index === topKey) {
                detail.data.today.map((rowdata, index) => {
                    if (index === key) {
                        if (!"collapse" in rowdata) {
                            rowdata["collapse"] = true
                        } else {
                            rowdata.collapse = !rowdata.collapse
                        }
                    } else {
                        rowdata["collapse"] = false
                    }
                })
            }

        })
        setDetail(listarray1)
    }
    return (
        <>
            <div className='row w-100 pl-2 m-0 mt-2 d-flex'>
                <div className='col-12 p-2'>
                    <Card>
                        <CardBody className='d-flex p-2 ' loader={loader && "loader"} >
                            {listArray.length ? listArray.map((device, key) =>
                                <div key={key} className='col px-1'>
                                    <InnerCard>
                                        <div className='d-flex w-100 p-2' 
                                        // style={{ cursor: "pointer" }}
                                        // onClick={() => setDetail({ data: device?.data, heading: device?.heading, index: key })}
                                        >
                                            <div className='col p-2 row m-0 align-items-center'>
                                                <div style={{ margin: (device?.type === "DoorDB" ? '-10%' : 0), height: device?.type === "DoorDB" ? "calc(90px + 20%)" : 90, width: device?.type === "DoorDB" ? "calc(90px + 20%)" : 90, overflow: 'hidden', }} >
                                                    <img style={{ width: '90%', height: '90%', objectFit: 'contain' }} src={imgReturnFunc(device.icon)} />
                                                </div>
                                                <div className='px-0 col'>
                                                    <div><Heading size={1}>{device.heading}</Heading></div>
                                                    <div>Alert</div>
                                                    {device.properties.length > 1 ?
                                                        <div><Heading size={1}>{device.properties[1].label + ": "}<span className='text-success'>{device.data.count[device.properties[1].value]}</span></Heading></div>
                                                        : <div>&nbsp;</div>
                                                    }
                                                </div>
                                                <div className="d-flex align-items-center" style={{ height: 100, overflow: 'hidden' }} >
                                                    <h1 style={{ fontSize: device.data.count[device.properties[0].value] > 9 ? "80px" : device.data.count[device.properties[0].value] > 99 ? "58px" : "90px", fontWeight: 'bolder' }} className={(device.data.count[device.properties[0].value] ? 'text-danger-0' : "text-success-1") + " text-center"} >{device.data.count[device.properties[0].value]}</h1>
                                                </div>
                                            </div>
                                            {/* <i className="fas fa-angle-right p-2" style={{ color: "#2B70E9", fontSize: '24px' }}></i> */}
                                        </div>
                                    </InnerCard>
                                </div>
                            ) : null}

                        </CardBody>
                    </Card >
                </div >
                <div className='col-8 py-2 pl-0 pr-2' style={{ height: 'calc(100vh - 300px)', minHeight: '400px' }}>
                    <OverviewMap config={config[0].mapConfig} />
                </div>
                <div className='col-4 p-2 pl-2'>
                    <Card className="h-100">
                        <CardHeader>
                            <Heading size={2}>Alerts</Heading>
                        </CardHeader>
                        <CardBody className="h-100" style={{maxHeight: "calc(100% - 50px)"}}>
                            <div className='overflow-auto h-100 p-2' style={{maxHeight: '100%'}}>
                                <table className='w-100' style={{ border: "solid rgb(200,200,200)", borderWidth: '1px 0 0 1px' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                Location                                                                    </th>
                                            <th style={{ width: "20%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }} >
                                                Type
                                            </th>
                                            <th style={{ width: "20%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }} >
                                                Duration
                                            </th>
                                            <th style={{ width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                Last Update
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listArray.map((device, key) =>
                                            device.data.latest.map(name =>
                                                <tr style={{ width: 100 }}>
                                                    <td style={{ width: "20%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                        <Heading size={2}>{name.hubName}</Heading>
                                                    </td>
                                                    <td style={{ width: "20%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                        <Heading size={2}>{device.type === "Meter"? "Battery" : device.type}</Heading>
                                                    </td>
                                                    <td style={{ width: "20%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                        {"duration" in name && name.duration ? (moment.duration(name.duration, "milliseconds").months() ?
                                                            moment.duration(name.duration, "milliseconds").months() +
                                                            " months " : '') + (moment.duration(name.duration, "milliseconds").days() ?
                                                                moment.duration(name.duration, "milliseconds").days() +
                                                                " day " : '') +
                                                            (moment.duration(name.duration, "milliseconds").hours() ?
                                                                moment.duration(name.duration, "milliseconds").hours() +
                                                                " hr " : '') +
                                                            (moment.duration(name.duration, "milliseconds").minutes() ?
                                                                moment.duration(name.duration, "milliseconds").minutes() +
                                                                " min " : '') +
                                                            (moment.duration(name.duration, "milliseconds").seconds() ?
                                                                moment.duration(name.duration, "milliseconds").seconds() +
                                                                " sec" : '')
                                                            : "----"}
                                                    </td>
                                                    <td style={{ fontSize: '12px', width: "30%", border: "solid rgb(200,200,200)", borderWidth: '0 1px 1px 0' }}>
                                                        {moment(name.event.time).format("MMM D, YYYY LTS")}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div >


        </>
    )
}

const mapStateToProps = (state) => ({
    configration: state.configration,
    organization: state.organization

});
export default connect(mapStateToProps, null)(Summary)

