import store from '../../services/utilities/store'
import { getHierarchy } from '../../services/utilities/events';


let response = null
export const orgAction = async (id) => {
    if (id) {
        response = await getHierarchy(id)
    }
    store.dispatch({
        type: "Get Hierarchy",
        payload: response
    });
};

// export const orgActionAdd = async (id, object = null) => {
//     let oldResponse = { ...response }
//     let idIndex = id.split("_")
//     let concatId = ''
//     let filteredData = oldResponse
//     idIndex.map((idx, key) => {
//         concatId += idx
//         if (key > 0 && filteredData) {
//             filteredData = levelMappingFunc(concatId, filteredData)
//         }
//         if ((idIndex.length - 1) > key) {
//             concatId += "_"
//         }
//     })
//     filter
//     edData.push(object)
//     // await orgAction(null, oldResponse)
// };
// const levelMappingFunc = (id, data) => {
//     let result = null
//     data.levels.map(row => {
//         if (row._id === id) {
//             result = row
//             return
//         }
//     })
//     return result
// }
// export const orgActionUpdate = async (id, object) => {
//     let response = null
//     if (id) {
//         response = await getHierarchy(id)
//     } else if (object) {
//         response = object
//     }
//     store.dispatch({
//         type: "Get Hierarchy",
//         payload: response
//     });
// };
// export const orgActionRemove = async (id) => {
//     let response = null
//     if (id) {
//         response = await getHierarchy(id)
//     } else if (object) {
//         response = object
//     }
//     store.dispatch({
//         type: "Get Hierarchy",
//         payload: response
//     });
// };


