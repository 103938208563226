import React, { useEffect, useState } from 'react'
import { Button, ButtonContainer } from '../arraybutton/arraybutton'
import InlineLoader from '../loader/inlineLoader'
import Mypopup from '../popup/mypopup'
import { getCookie } from '../../services/utilities/users'
import AccessValidation from '../accessValidation/accessValidation'

const HubInfoPopup = (props) => {
    let res1 = props.res
    let organizationname = res1.levelId.split("_")[0]
    const [password, setPassword] = useState()
    const [btnloader, setBtnLoader] = useState(false)
    const [tab, setTab] = useState("mqtt")
    const [access, setAccess] = useState(null)

    useEffect(() => {
        if (res1._id.split("_").length === 2) {
            setPassword(res1._id.split("_")[1])
        } else {
            setPassword(res1._id)
        }
    }, [])
    const downloadFolder = () => {
        setBtnLoader(true)
        const url = 'https://ottomatically.com/inventory-server/images/ottomatically.com.zip';
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', res1._id + ".zip");
                document.body.appendChild(link);
                link.click();
                setBtnLoader(false)
            });
    }
    const accessHandler = (e) => {
        console.log(e)
        setAccess(e)
    }
    return (
        <Mypopup style={{ maxHeight: "300px" }} title={"Device Credentials"} onClose={() => { props.onClose() }} headerContent={

            btnloader ? <InlineLoader width={"41px"} />
                :
                <ButtonContainer>
                    <Button buttonResult={() => downloadFolder()}>Download</Button>
                </ButtonContainer>
        }>
            <AccessValidation levelId={res1.levelId} onAccessHandler={(e) => accessHandler(e)} />
            <div className='row m-0 pb-4'>
                <div className={"col d-flex"}>
                    <div className={'tabButton d-flex ' + (tab === "mqtt" ? "active" : "null")}>
                        <div className='inner-btn' onClick={() => setTab("mqtt")} >MQTT</div>
                    </div>
                    {access?.includes("1010") || access?.includes("admin") ?
                        <>
                            <div className={'tabButton d-flex ' + (tab === "http" ? "active" : "null")}>
                                <div className='inner-btn' onClick={() => setTab("http")} >HTTP</div>
                            </div>
                            <div className={'tabButton d-flex ' + (tab === "websocket" ? "active" : "null")}>
                                <div className='inner-btn' onClick={() => setTab("websocket")}>WebSocket</div>
                            </div>
                        </>
                        : null}
                </div>

            </div>
            <div className='w-100' >
                {tab === "mqtt" ?
                    <code>
                        <div className='m-2'>
                            1. Server Address: broker2.s5.ottomatically.com<br />
                            2. Server Port: 8884<br />
                            3. ClientID: device-{res1._id}-{res1.type}<br />
                            4. UserName: {organizationname}<br />
                            5. Password: {password}<br />
                            6. Publish Topic: lt/evt/{res1.type}Events/{res1._id}<br />
                            7. Subscribe Topic: lt/cmd/AppEvents/{res1._id}
                        </div>
                    </code>
                    : null}
                {tab === "http" ?
                    <code>
                        <div className='m-2'>
                            <b>Basic</b><br />
                            1. Server Address: https://gateway2.s5.ottomatically.com/api/v1/hubs/emit <br />
                            2. UserName: {organizationname} <br />
                            3. Password: {password} <br />
                            4. Authorization: Basic {btoa(organizationname + ":" + password)}
                            {/* 2. token: {getCookie("token")} */}
                        </div>
                    </code>
                    : null}
                {tab === "websocket" ?
                    <code>
                        <div className='m-2'>
                            .....
                        </div>
                    </code>
                    : null}
            </div>
        </Mypopup>
    )
}

export default HubInfoPopup