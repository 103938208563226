import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import AccessValidation from "../../components/accessValidation/accessValidation";
import Card, { CardBody, InnerCard } from "../../components/card/card";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Input from "../../components/input/input";
// import { useParams } from "react-router-dom";

function Sidebar(props) {
  // let levelId = window.location.hash
  let levelId = "";
  let { pathname, search } = useLocation();
  // const searchParams = new URLSearchParams(search);
  // const param1 = searchParams.get('id');
  const [dashboardDesign, setDashboardDesign] = useState(false);
  const [userManagement, setUserManagment] = useState(false);
  const [deviceManagement, setDeviceManagement] = useState(false);
  const [roleManagement, setRoleManagement] = useState(false);
  const [ruleEngine, setRuleEngine] = useState(false);
  const [usersHidden, setUsersHidden] = useState(true);
  const [deviceHidden, setDeviceHidden] = useState(true);
  const [levelIds, setLevelIds] = useState(
    props?.sidebarReducer?.sidebarReducer
  );
  const [access, setAccess] = useState([]);
  const [filter, setFilter] = useState("");
  const [innerLevelData, setinnerLevelData] = useState([]);
  const [animationType, setAnimationType] = useState('fade-in-left-onload');

  levelId = pathname.split("/");
  levelId = levelId[levelId.length - 1];
  let orgId = levelId;
  if (orgId.match("_")) {
    let orgIdList = orgId.split("_");
    orgId = orgIdList[0];
  }
  useEffect(() => {
    checkUser();
  }, [access]);
  // useEffect(() => {
  //   setinnerLevelData([props.levelList])
  // }, [props.levelList]);
  const checkUser = () => {
    if (access) {
      if (access?.length) {
        if (access.includes("1001") || access.includes("admin")) {
          setDashboardDesign(true);
        } else {
          setDashboardDesign(false);
        }
        if (access.includes("1002") || access.includes("admin")) {
          setUserManagment(true);
        } else {
          setUserManagment(false);
        }
        if (access.includes("1003") || access.includes("admin")) {
          setRuleEngine(true);
        } else {
          setRuleEngine(false);
        }
        if (access.includes("1008") || access.includes("admin")) {
          setDeviceManagement(true);
        } else {
          setDeviceManagement(false);
        }
        if (access.includes("admin")) {
          setRoleManagement(true);
        } else {
          setRoleManagement(false);
        }
      } else {
        setDashboardDesign(false);
        setUserManagment(false);
        setRoleManagement(false);
        setRuleEngine(false);
        setDeviceManagement(false);
      }
    }
  };

  useEffect(() => {
    setLevelIds(props?.sidebarReducer?.sidebarReducer);
  }, [props.sidebarReducer]);

  const accessHandler = (e) => {
    setAccess(e);
  };

  useEffect(() => {
    if ("onCloseBtn" in props) {
      if (props.onCloseBtn) {
        setinnerLevelData([])
        setFilter("")
        setUsersHidden(props.onCloseBtn);
        setDeviceHidden(props.onCloseBtn);
      }
    }
  }, [props.onCloseBtn]);

  const onNextClick = (level) => {
    setAnimationType('fade-out-left-onload1 overflow-hidden');
    setTimeout(() => {
      setFilter("")
      setAnimationType('fade-in-right-onload1');
      setinnerLevelData([...innerLevelData, level])
    }, 350)
  }
  const onBackClick = (level) => {
    setAnimationType('fade-out-right-onload1 overflow-hidden');
    setTimeout(() => {
      setFilter("")
      setAnimationType('fade-in-left-onload1');
      const innerLevelData1 = [...innerLevelData];
      innerLevelData1.pop();
      setinnerLevelData(innerLevelData1)
    }, 350)
  }

  return (
    <div className={animationType}>
      {levelIds ? (
        <AccessValidation levelId={levelIds} onAccessHandler={accessHandler} />
      ) : levelId ? (
        <AccessValidation levelId={levelId} onAccessHandler={accessHandler} />
      ) : null}
      {!innerLevelData.length ?
        <ul className="nav row">
          <li className="col-12 nav-item" title="Dashboard">
            <a
              id="Dashboard"
              className={
                "navbtn text-truncate" +
                (pathname === "/" || pathname.match("/organizations")
                  ? " active"
                  : "")
              }
              href={"#/"}
            >
              <i className="navIcon fa fa-tachometer-alt"></i> Dashboard
            </a>
          </li>
          {orgId && orgId !== "welcome" ? (
            <>
              <li className="col-12 nav-item" title="Locations">
                <a onClick={() => { onNextClick(props.levelList); props?.onOpenSideBar(true); }} id="UserManagement"
                  className={
                    "navbtn d-flex justify-content-between text-truncate"
                    // + (pathname.match("/user") ? " active" : "")
                  }
                >
                  <div>
                    <i className="navIcon fa fa-stream"></i> <span>Locations</span>
                  </div>
                  <i
                    className={
                      "fas fa-angle-right m-1"
                    }
                  ></i>
                </a>
              </li>
              {dashboardDesign ? (
                <li className="col-12 nav-item" title="Dashboard Management">
                  <a
                    id="ManageOverview"
                    className={
                      "navbtn text-truncate" +
                      (pathname.match("manage-dashboard") ? " active" : "")
                    }
                    href={"#/manage-dashboard/" + levelId}
                  >
                    <i className="navIcon fas fa-wrench"></i> <span>Dashboard Management</span>
                  </a>
                </li>
              ) : null}

              {userManagement ? (
                <li className="col-12 nav-item" title="User Management">
                  <a
                    onClick={() => {
                      setUsersHidden(!usersHidden);
                      props?.onOpenSideBar(true);
                    }}
                    id="UserManagement"
                    className={
                      "navbtn d-flex justify-content-between text-truncate" +
                      (pathname.match("/user") ? " active" : "")
                    }
                  // href={"#/usermanagement/" + levelId}
                  // href={"#/usermanagement/" + orgId}
                  >
                    <div>
                      <i className="navIcon fas fa-users-cog"></i><span>User Management</span>
                    </div>
                    <i
                      className={
                        "fas fa-angle-" +
                        (usersHidden ? "right" : "down") +
                        " m-1"
                      }
                    ></i>
                  </a>
                  <Card
                    className="sub-navbtn mb-1 overflow-hidden"
                    style={{ display: usersHidden ? "none" : "block" }}
                  >
                    <CardBody className="d-flex flex-column ">
                      {roleManagement ? (
                        <a
                          id="UserRole"
                          className="text-truncate"
                          href={"#/userrole/" + levelId}
                        >
                          <i
                            className="navIcon fa fa-circle"
                            style={{ fontSize: "8px" }}
                          ></i>{" "}
                          User Role
                        </a>
                      ) : null}
                      <a
                        id="UserManagement"
                        className="text-truncate"
                        href={"#/usermanagement/" + levelId}
                      // href={"#/usermanagement/" + orgId}
                      >
                        <i
                          className="navIcon fa fa-circle"
                          style={{ fontSize: "8px" }}
                        ></i>{" "}
                        User List
                      </a>
                    </CardBody>
                  </Card>
                </li>
              ) : null}

              {deviceManagement ? (
                <li className="col-12 nav-item" title="Device Management">
                  <a
                    onClick={() => {
                      setDeviceHidden(!deviceHidden);
                      props?.onOpenSideBar(true);
                    }}
                    id="DeviceManagement"
                    className={
                      "navbtn d-flex justify-content-between text-truncate" +
                      (pathname.match("Management/") ? " active" : "")
                    }
                  >
                    <div>
                      <i className="navIcon fab fa-hubspot"></i> <span>Device Management</span>
                    </div>
                    <i
                      className={
                        "fas fa-angle-" +
                        (deviceHidden ? "right" : "down") +
                        " m-1"
                      }
                    ></i>
                  </a>
                  <Card
                    className="sub-navbtn mb-1 overflow-hidden"
                    style={{ display: deviceHidden ? "none" : "block" }}
                  >
                    <CardBody className="d-flex flex-column">
                      <a
                        id="UserManagement"
                        className="text-truncate"
                        href={"#/deviceManagement/" + levelId}
                      >
                        <i
                          className="navIcon fa fa-circle"
                          style={{ fontSize: "8px" }}
                        ></i>{" "}
                        Device List
                      </a>
                      <a
                        id="UserManagement"
                        className="text-truncate"
                        href={"#/hubManagement/" + levelId}
                      >
                        <i
                          className="navIcon fa fa-circle"
                          style={{ fontSize: "8px" }}
                        ></i>{" "}
                        Hub List
                      </a>
                    </CardBody>
                  </Card>
                </li>
              ) : null}
              {ruleEngine ? (
                <li className="col-12 nav-item" title="Rule Engine">
                  <a
                    id="ManageOverview"
                    className={
                      "navbtn text-truncate" +
                      (pathname.match("rule-engine") ? " active" : "")
                    }
                    href={"#/rule-engine/" + levelId}
                  >
                    <i className="navIcon fas fa-cogs"></i><span>Rule Engine</span>
                  </a>
                </li>
              ) : null}
            </>
          ) : null}
        </ul>
        :
        <>
          <div className="d-flex mb-1">
            <button className="mr-1 btn btn-light rounded-circle" onClick={() => onBackClick()}><i className="fa fa-arrow-left"></i></button>
            <input className="sideSearch" value={filter} onChange={(e) => setFilter((e.target.value).toLowerCase())} placeholder="Search" />
          </div>
          <div className="overflow-auto" style={{ height: 'calc(100vh - 115px)' }}>
            <ul className="nav m-0 row">
              {innerLevelData[innerLevelData?.length - 1]?.map((level, key) => !filter || level.locationName.toLowerCase().includes(filter) || level._id.toLowerCase().includes(filter) ?
                <li key={key} className="col-12 p-0 nav-item" title={level.locationName}>
                  <div className={"navbtn py-0 w-100 d-flex justify-content-between"
                    // + (pathname.match("/user") ? " active" : "")
                  }
                  >
                    <Link to={{ pathname: "/organizations/" + level._id, data: level }} className="col px-0 d-flex align-items-center py-2">
                      <i className="navIcon fa fa-circle" style={{ fontSize: "8px" }} ></i>
                      <span style={{ maxWidth: "160px" }} className="text-truncate">{level.locationName}</span>
                    </Link>
                    {level?.levels?.length &&
                      <button onClick={() => onNextClick(level.levels)} className="btn btn-light px-2 py-0 rounded-circle-px">
                        <i className={"fas fa-angle-right fa-sm text-dark"} ></i>
                      </button>
                    }
                  </div>
                </li>
                : null)}
            </ul>
          </div>
        </>
      }
    </div>
  );
}
const mapStateToProps = (state) => ({
  sidebarReducer: state.sidebarReducer,
});
export default connect(mapStateToProps, null)(Sidebar);
