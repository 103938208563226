import React, { useEffect, useState } from 'react'
import Select from '../../select/select'
import { connect } from 'react-redux';
import { Button, ButtonContainer } from '../../arraybutton/arraybutton';
import Input from '../../input/input';
import { getLevelIds, get_Devices_Selected_HubIds_WithoutMatchType } from '../../../functions/functions';
import { CardHeader, Heading } from '../../card/card';


const MapSetting = (props) => {
    const [data, setData] = useState({})




    useEffect(() => {
        if (props?.defaultValue) {
            setData(props.defaultValue)
        }
    }, [props.defaultValue])




    const onChangeHandler = (e, type, index = null) => {
        let data1 = { ...data }
        if (type !== "addParam" && type !== "deleteParam" && type !== "params" && type !== "addHub" && type !== "coordinates" && type !== "deviceSelection" && type !== "live" && type !== "history" && type !== "filter") {
            data1[type] = e.target.value
        }
        if ("onChange" in props) {
            if (type === "autoTrack" || type === "lat" || type === "lng" || type === "zoom") {
                let map = { ...data["mapSetting"] }
                data1["mapSetting"] = map
                if (data1.mapSetting) {
                    if (type === "autoTrack") {
                        if (data?.mapSetting?.autoTrack) {
                            data1["mapSetting"][type] = !data1?.mapSetting?.autoTrack
                        } else {
                            data1.mapSetting[type] = true
                        }
                        props.onChange({ name: 'mapSetting', target: { value: map } })
                    } else if (type === "lat" || type === "lng" || type === "zoom") {
                        let mapCenter = { ...map["mapCenter"] }
                        data1.mapSetting["mapCenter"] = mapCenter
                        if (data1.mapSetting.mapCenter) {
                            if (type === "lat") {
                                data1.mapSetting["mapCenter"][type] = e.target.value
                            }
                            else if (type === "lng") {
                                data1.mapSetting["mapCenter"][type] = e.target.value
                            }
                            else if (type === "zoom") {
                                data1.mapSetting["mapCenter"][type] = e.target.value
                            }
                        }
                        props.onChange({ name: 'mapSetting', target: { value: map } })
                    }
                    else {
                        props.onChange({ name: type, target: { value: data1[type] } })
                    }
                }
                setData(data1)
            }
        }
    }

    return (
        <>
            <div className='propertiesLabels mt-2'>Map Setting</div>
            <div>
                <div className='mt-1'>
                    <small size={3}> Map Center</small>
                    <div className='row m-0 my-2' style={{ gap: 10 }}>
                        <div className='col p-0'>
                            <Input type="number" name="lat" label="lat" onChange={(e) => { onChangeHandler(e, "lat") }} defaultValue={data?.mapSetting?.mapCenter?.lat ? data.mapSetting.mapCenter.lat : ""} />
                        </div>
                        <div className='col p-0'>
                            <Input type="number" name="lng" label="lng" onChange={(e) => { onChangeHandler(e, "lng") }} defaultValue={data?.mapSetting?.mapCenter?.lng ? data.mapSetting.mapCenter.lng : ""} />
                        </div>
                    </div>
                    <div className='row m-0 my-2' style={{ gap: 10 }}>
                        <div className='col p-0'>
                            <Input type="range" min="3" max="20" name="zoom" label="zoom" onChange={(e) => { onChangeHandler(e, "zoom") }} defaultValue={data?.mapSetting?.mapCenter?.zoom ? data.mapSetting.mapCenter.zoom : 12} />
                        </div>
                    </div>
                </div>

                {/* <label className='d-flex m-0' style={{ gap: 10 }}>
                    <input type="checkbox" checked={data?.mapSetting?.autoTrack} name='autoTrack' onChange={(e) => onChangeHandler(e, "autoTrack")} />
                    <div size={3}> Auto Track </div>
                </label> */}
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    organization: state.organization,
    configration: state.configration,
    userInfo: state.userInfo

});
export default connect(mapStateToProps, null)(MapSetting)
