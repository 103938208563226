//import libraries
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
//import local resources 
import AddDeviceForm from '../../components/addDeviceForm/addDeviceForm'
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton'
import Card, { Heading, CardHeader } from '../../components/card/card'
import FullLoader from '../../components/loader/fullPageLoader'
import Mypopup from '../../components/popup/mypopup'
import { getDevice, getResource, singleHubGet } from '../../services/utilities/events';
import DeviceCard from '../../components/deviceCard/deviceCard'
import SetAlert from '../../components/setAlertForm/setAlert';
import AccessValidation from '../../components/accessValidation/accessValidation';
import moment from 'moment';
import MyDropDown, { MyDropDownBody } from '../../components/dropdown/dropdown';
import HubInfoPopup from '../../components/hubInfoPopup/HubInfoPopup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


function Device(props) {
    const hubId = useParams()
    const [backword, setBackword] = useState("")
    const [forword, setForword] = useState("")
    const [deviceId, setDeviceId] = useState(null)
    const [image, setImage] = useState("")
    const [showInfo, setShowInfo] = useState(null)
    const [getSingleHub, setGetSingleHUb] = useState()
    const [fullLoader, setFullLoader] = useState(false)
    const history = useHistory()


    // let location = window.location.hash.startsWith("#/alerts")
    // if (location) {
    //     if (hubId?.deviceId === props.device._id) {
    //         setAlert(true)
    //     }
    // }

    const getSpecificHubData = async () => {
        setLoader(true)
        return singleHubGet(hubId.hubId).then(res => {
            setGetSingleHUb(res)
            setLoader(false)
        })
    }

    //All States
    const [loader, setLoader] = useState(true)
    const [devices, setDevices] = useState([])
    const [access, setAccess] = useState(null)
    const [manageDevice, setManageDevice] = useState(false)
    const [res1, setRes1] = useState({ created: '', hover: false })

    //Add Device Form State
    const [form, setForm] = useState(false)
    // const [form, setForm] = useState(null)
    const accessHandler = (e) => {
        setAccess(e)
    }
    //
    useEffect(() => {
        if (access) {
            if (access?.length) {
                if (access.includes("1008") || access.includes("admin")) {
                    setManageDevice(true)
                }
            } else {
                setManageDevice(false)
            }
        }
    }, [access])

    useEffect(async () => {
        setFullLoader(true)
        let sameLevelsHub = []
        if (props.organization?.organization?.allhubs) {
            props.organization.organization.allhubs.map((hub, key) => {
                if (hub.levelId === hubId.levelId) {
                    sameLevelsHub.push(hub)
                }
            })
            if (sameLevelsHub.length > 1) {
                sameLevelsHub.map((singleHub, key) => {
                    if (singleHub._id === hubId.hubId) {
                        if (sameLevelsHub.length > key + 1) {
                            setForword(sameLevelsHub[key + 1]._id)
                        } else {
                            setForword("")
                        }
                        if (0 < key) {
                            setBackword(sameLevelsHub[key - 1]._id)
                        } else {
                            setBackword("")
                        }
                    }
                })
            }
        }
        randonImg()
        DeviceGet()
        getSpecificHubData()
        eventHandler(true)
        setFullLoader(false)
    }, [props.location])
    const eventHandler = (isNew) => {
        if (!isNew) {
            if (devices.length > 0) {
                let Events = props?.lastEvent?.lastEvent?.devices
                let res = { ...res1 }
                for (const item of devices) {
                    for (const events of Events) {
                        if (events?.events[1]?.hubId === item.hubId && res1.created !== events?.events[1]?.created) {
                            res = events.events[1]
                        }
                    }
                }
                setRes1(res)
            }
        } else {
            setRes1({ created: '', hover: false })
        }
    }

    useEffect(() => {
        eventHandler()
    }, [props.lastEvent, devices])

    const randonImg = () => {
        let Img = getResource("/files/image/77c3c28613e1ad7291e89d4a58813751.svg")
        setImage(Img)
    }

    const DeviceGet = async () => {
        //Configrations
        // if (access.length) {
        setDevices([])
        setForm(false)
        let deviceId = null
        if (hubId?.deviceId) {
            deviceId = hubId.deviceId
            setDeviceId(deviceId)
        }
        const configration = props.configration.configration.configuration
        let levelId = null
        let hubIds = null
        if (deviceId) {
            levelId = deviceId.split("_")[0]
            hubIds = deviceId.split("_")
            hubIds = hubIds[0] + "_" + hubIds[1]
        } else {
            levelId = hubId.hubId.split("_")[0]
            hubIds = hubId.hubId
        }

        let res = await getDevice(hubIds)
        let events = props.lastEvent.lastEvent
        let devicesArray = []
        Promise.all(res.map(async (device, key) => {
            let deviceObj = {}
            deviceObj = configration.Device.find(config => config.type === device.type)
            let events1 = {
                Status: null,
                Connection: null
            }
            events?.devices?.map(eve => {
                if (eve._id === device._id) {
                    eve.events.map(data => {
                        let deviceId = data?.deviceId.split("_")
                        if (deviceId.length === 3) {
                            events1.Status = data
                        } else if (deviceId.length === 2) {
                            events1.Connection = data
                        }
                    })

                }
            }
            )
            deviceObj = { ...device, config: deviceObj, events: events1 }
            devicesArray.push(deviceObj)
        })).then(() => {
            setDevices(devicesArray)
            setLoader(false)
        })
        // }
    }

    return (
        <>
            <AccessValidation levelId={hubId.levelId} onAccessHandler={(e) => accessHandler(e)} />
            {/* {access ? */}

            <div className='m-3'>
                {!deviceId ?
                    <Card>
                        <div className="p-0">
                            <div className='d-flex align-items-center' style={{ gap: 5 }}>
                                {backword ?
                                    <button onClick={() => history.push({ pathname: "/hub/" + hubId.levelId + "/" + backword })} className='btn btn-dark border-0 rounded-circle-px primary-gradient px-3 py-2'>
                                        <i className="fas fa-chevron-left"></i>
                                    </button>
                                    : null}
                                <div className='col d-flex align-items-center'>
                                    {/* <ButtonContainer> */}
                                    {/* </ButtonContainer> */}
                                    <Heading size={1} className="mr-3" >{getSingleHub?.name}</Heading>
                                </div>
                                {manageDevice ?
                                    <ButtonContainer>
                                        <Button buttonResult={() => setForm("hello")}>Add Device</Button>
                                    </ButtonContainer>
                                    : null}
                                <MyDropDown openToggle={(e) => { let res = { ...res1 }; res.hover = e; setRes1(res) }}>
                                    <button className='btn rounded-circle-pxl deviceBtn1' style={{ height: 40, borderRadius: "60%" }}><i className={'my-wifi  ' + (res1 ? res1.value ? 'on' : 'off' : 'off')}></i></button>
                                    <MyDropDownBody open={res1.hover} right style={{ width: "230px", padding: "5px" }}>
                                        {res1 ? res1.value ? ("Connected Since: " + moment(res1.created).format("LL LTS")) : ("Disconnected at: " + moment(res1.created).format("LL LTS")) : "Disconnected at:  00:00:00 AM 00-00-0000"}
                                    </MyDropDownBody>
                                </MyDropDown>
                                <button onClick={() => { setShowInfo(getSingleHub) }} className='btn deviceBtn1 rounded-circle-px text-info'>
                                    <i className="fa fa-info px-1"> </i>
                                </button>
                                {forword ?
                                    <button onClick={() => history.push({ pathname: "/hub/" + hubId.levelId + "/" + forword })} className='btn btn-dark border-0 rounded-circle-px primary-gradient px-3 py-2'>
                                        <i className="fas fa-chevron-right"></i>
                                    </button>
                                    : null}
                            </div>
                        </div>
                    </Card>
                    : null}
                <div className='row m-0 justify-content-center'>
                    {loader ? <FullLoader /> :
                        devices.length ? devices.map((device, key) =>
                            deviceId ?
                                deviceId === device._id ?
                                    <SetAlert device={device} />
                                    : null
                                : <DeviceCard
                                    randomImage={image}
                                    key={key}
                                    device={device}
                                    access={access}
                                />
                        )
                            : <p>There is no devices</p>
                    }
                </div>
            </div >
            {/* : null} */}
            {
                form ? <Mypopup title={"Add Device"} onClose={() => { setForm(null) }}>
                    <AddDeviceForm hubId={hubId.hubId} levelId={hubId.levelId} reloadDevices={() => DeviceGet()} />
                </Mypopup> : null
            }
            {showInfo !== null ?
                <HubInfoPopup res={getSingleHub} onClose={() => setShowInfo(null)} />
                : null}
        </>
    )
}
const mapStateToProps = (state) => ({
    configration: state.configration,
    lastEvent: state.lastEvent,
    organization: state.organization,

});
export default connect(mapStateToProps, null)(Device)
