import React, { useState, useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import routes from '../../routes';
import { connect } from "react-redux";
import './defaultLayout.scss';
import LT from '../../assets/svgs/LT.svg'
import DefaultHeader from './defaultHeader';
import Sidebar from './sidebar'
import DefaultFooter from './defaultFooter';
import { NotificationAction, SocketAction } from '../../services/actions/socketAction';
import FullLoader from '../../components/loader/fullPageLoader';
import { userInfoAction } from '../../services/actions/userInfoAction'
import { getCookie, refreshToken, signOut } from '../../services/utilities/users';
import store from '../../services/utilities/store';
import { configrationAction } from '../../services/actions/configrationAction';
import { orgAction } from '../../services/actions/orgAction';
import { LastEventAction } from '../../services/actions/lastEventAction';
import ConnectionStatus from '../../components/connection/connection';
import UnAuthorize from '../../components/unAuthorizaPage.js/unAuthorize';
import AccessValidation from '../../components/accessValidation/accessValidation';
let tamtoken = getCookie("token")
let expire = getCookie("exp")
let timeout = expire - Math.abs(new Date())
function DefaultLayout(props) {
  // let tamtoken = getCookie("token")
  // let expire = Cookies.get("expireTime")
  // let timeout = expire - Math.abs(new Date())
  const [isLight, setIsLight] = useState(true)
  const [sideToggle, setSideToggle] = useState(false)
  const [network, setNetwork] = useState()
  const [loader, setLoader] = useState(true);
  const [dashboardDesign, setDashboardDesign] = useState(false)
  const [userManagement, setUserManagment] = useState(false)
  const [roleManagement, setRoleManagement] = useState(false)
  const [ruleEngine, setRuleEngine] = useState(true)
  const [access, setAccess] = useState(null)
  const [levelIds, setLevelIds] = useState(null)
  const [levelId, setLevelId] = useState(null)
  const [getReadyForId, setGetReadyForId] = useState(false)
  const [closeAllBtn, setCloseAllBtn] = useState(true)
  // This UseEffect get Token and Time 
  useEffect(async () => {
    store.dispatch({
      type: "Token",
      payload: tamtoken
    })
    timeInterval()
    await userInfoAction()
    SocketAction()
    // NotificationAction()
    await configrationAction()
    setGetReadyForId(true)
    await dummy()
  }, [])
  // useEffect(() => {
  //   // setIsLight(props.themeMode.isLight || false)
  //   let element = document.getElementsByTagName('body')[0]
  //   if(props?.themeMode?.isLight){
  //     element.setAttribute("class", "")
  //   }else{
  //     element.setAttribute("class", "dark-theme")
  //   }
  // }, [props.themeMode])

  // useEffect(() => {
  //   if (props?.userInfo?.varification) {
  //     let access = props?.userInfo?.varification.access
  //     if (access?.length) {
  //       access.map(acc => {
  //         if (acc === "DD") {
  //           setDashboardDesign(true)
  //         }
  //         if (acc === "RE") {
  //           setRuleEngine(true)
  //         }
  //       })
  //     } else {
  //       // setDashboardDesign(false)
  //       // setRuleEngine(false)
  //     }
  //   }
  // }, [props?.userInfo])
  useEffect(() => {
    checkUser()
  }, [access])
  const checkUser = () => {
    if (access) {
      if (access?.length) {
        if (access.includes("1001") || access.includes("admin")) {
          setDashboardDesign(true)
        } else {
          setDashboardDesign(false)
        }
        if (access.includes("1002") || access.includes("admin")) {
          setUserManagment(true)
        } else {
          setUserManagment(false)
        }
        if (access.includes("1003") || access.includes("admin")) {
          setRuleEngine(true)
        } else {
          setRuleEngine(false)
        }
        if (access.includes("admin")) {
          setRoleManagement(true)
        } else {
          setRoleManagement(false)
        }
      } else {
        setDashboardDesign(false)
        setUserManagment(false)
        setRoleManagement(false)
        setRuleEngine(false)
      }
    }
  }
  useEffect(() => {
    setLevelIds(props?.sidebarReducer?.sidebarReducer)
  }, [props.sidebarReducer])

  const accessHandler = (e) => {
    setAccess(e)
  }

  //NetWork Connection 
  const ConnectionsStatus = (e) => {
    if (e) {
      setNetwork(1)
    } else {
      setNetwork(0)
    }
  }


  useEffect(async () => {
    if (getReadyForId) {
      await dummy()
    }
  }, [window.location.href])

  //This Function call hierarchy with all conditions
  const dummy = async () => {
    setLoader(true)
    let levelId = window.location.href
    if (levelId) {
      levelId = levelId.split("/")
      if (levelId.length > 5) {
        levelId = levelId[levelId.length - 1]
        // if (levelId.match("?")) {
        levelId = levelId.split("?")
        levelId = levelId[0]
        // }
      } else {
        levelId = ""
      }
      let org_id = levelId
      if (org_id.match("_")) {
        org_id = org_id.split("_")
        org_id = org_id[0]
      }
      if (org_id.match("=")) {
        org_id = org_id.split("=")
        org_id = org_id[1]
      }
      if (org_id) {
        if (props?.organization?.organization) {
          let existId = props.organization.organization._id
          if (existId !== org_id) {
            await orgAction(org_id)
            await LastEventAction(org_id)
            // let hierarchy2 = await getHierarchy(org_id)
            // store.dispatch({
            //   type: "Get Hierarchy",
            //   payload: hierarchy2
            // })
          }
        } else {
          await orgAction(org_id)
          await LastEventAction(org_id)
          // let hierarchy2 = await getHierarchy(org_id)
          // store.dispatch({
          //   type: "Get Hierarchy",
          //   payload: hierarchy2
          // })
        }
      } else {
        await orgAction()
      }
    }
    setLevelId(levelId)
    setLoader(false)
  }


  // This UseEffect save Token and expToken in localStorage
  useEffect(() => {
    if (props.userToken) {
      tamtoken = props.userToken.token

      let jwtToken = JSON.parse(window.atob(tamtoken.split('.')[1]));
      let exp = parseInt(jwtToken.exp) * 1000;
      timeout = exp - Math.abs(new Date());
    }
  }, [props.userToken])

  // This function call refresh Token 
  const refreshTokens = () => {
    return refreshToken({ "token": tamtoken }).then(async res => {
      store.dispatch({
        type: "Token",
        payload: res.token
      })
      let result = res
      if (result) {
        timeInterval()
      }
    }
    )
  }
  // timeInterval function after one Hour This function call refresh Token Function
  const timeInterval = () => {
    if (timeout > 0) {
      setTimeout(() => {
        if (timeout > 0) {
          refreshTokens()
        } else {
          signOut()
        }
      }, (timeout - 600000))
    } else {
      signOut()
    }
  }
  //Loader
  if (loader) {
    return (
      <FullLoader />
    )
  }
  // Layout
  else {
    return (
      <div className={!isLight ? "dark-theme" : ""}>
        {levelIds ?
          <AccessValidation levelId={levelIds} onAccessHandler={accessHandler} />
          : levelId ? <AccessValidation levelId={levelId} onAccessHandler={accessHandler} />
            : null}
        <div onClick={() => { setSideToggle(false); setCloseAllBtn(true) }}>
          <DefaultHeader organization={props.organization?.organization} network={network} />
        </div>
        <div>
          <div className={sideToggle ? "open menubtn btn-lg" : "menubtn btn-lg"}
            onClick={() => {
              setSideToggle(a => a = !sideToggle);
              setCloseAllBtn(true)
            }}
          ><i className={"mt-2 fa fa-bars"}></i></div>
          <div className="position-relative">
            {/* <div id="sideContainer" className={"silver-gradient" + (sideToggle ? " open" : "")}> */}
            <div id="sideContainer" className={(sideToggle ? " open" : "")}>
              <Sidebar levelList={props.organization?.organization?.levels} onOpenSideBar={(e) => { setSideToggle(e); setCloseAllBtn(false); }} onCloseBtn={closeAllBtn} />
            </div>
          </div>
          <main
            onClick={() => { setSideToggle(false); setCloseAllBtn(true) }}
          >

            <Switch>

              {routes.map((route, idx) => {
                return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                  route.path.match("/manage-dashboard/edit/")
                    || route.path.match("/manage-dashboard/add/")
                    || route.path.match("/manage-dashboard/")
                    ?
                    !dashboardDesign ?
                      <>
                        <UnAuthorize />
                      </>
                      :
                      <>
                        <route.component {...props} />
                      </>
                    :
                    route.path.match("/alerts/") ?
                      !ruleEngine ?
                        <UnAuthorize />
                        :
                        <route.component {...props} />
                      : route.path.match("/usermanagement") ?
                        !userManagement ?
                          <UnAuthorize />
                          :
                          <route.component {...props} />
                        :
                        route.path.match("/userrole") ?
                          !roleManagement ?
                            <UnAuthorize />
                            :
                            <route.component {...props} />
                          : <route.component {...props} />
                )} />)
                  : (null);
              },
              )}
            </Switch>
          </main>
          <ConnectionStatus ConnectionStatus={(e) => ConnectionsStatus(e)} />
        </div>
        <DefaultFooter />
      </div>
    );
  }
}



const mapStateToProps = (state) => ({
  themeMode: state.themeMode,
  userInfo: state.userInfo,
  userToken: state.userToken,
  organization: state.organization,
  sidebarReducer: state.sidebarReducer
});
export default connect(mapStateToProps, null)(DefaultLayout)
