import React, { useState, useEffect } from 'react'
import Input from '../../input/input'

const ColorPanel = (props) => {
  const [color, setColor] = useState("")
  const onChangeHandler = (e) => {
    let color1 = e.target.value
    setColor(color1)
    if ("onChange" in props) {
      props.onChange({ name: "color", target: { value: color1 } })
    }
  }
  useEffect(() => {
    setColor(props.defaultValue)
  }, [props.defaultValue])

  return (
    <>
      <div className='propertiesLabels mt-3'>Color</div>
      {
        props.color ?
          <div className='row m-0 mb-3 justify-content-end' style={{ gap: 10 }}>
            <div className='selectiveColors primary-gradient' onClick={() => onChangeHandler({ target: { value: "primary-gradient" }, name: "color" }, "config")}></div>
            <div className='selectiveColors success-gradient' onClick={() => onChangeHandler({ target: { value: "success-gradient" }, name: "color" }, "config")}></div>
            <div className='selectiveColors danger-gradient' onClick={() => onChangeHandler({ target: { value: "danger-gradient" }, name: "color" }, "config")}></div>
            <div className='selectiveColors warning-gradient' onClick={() => onChangeHandler({ target: { value: "warning-gradient" }, name: "color" }, "config")}></div>
          </div>
          : null
      }
      {
        props.costumColor ?
          <Input
            className="w-100"
            name="color"
            label="Custom"
            type="color"
            defaultValue={color}
            onChange={(e) => onChangeHandler(e, "config")}
          />
          : null
      }
    </>
  )
}

export default ColorPanel