import React, { useEffect, useState } from 'react'
import Mypopup from '../popup/mypopup'
import { Button, ButtonContainer } from '../arraybutton/arraybutton'
import { event } from 'jquery'
import { publishDeviceData } from '../../services/utilities/socket'
import InlineLoader from '../loader/inlineLoader'
import { Hidden } from '@material-ui/core'

const DeviceInfoPopup = (props) => {
    let res1 = props.res
    let [editable, setEditable] = useState(false)
    let [publish, setPublish] = useState("")
    let [publishLoader, setPublishLoader] = useState(false)
    let [json, setJson] = useState(true)

    const editPayload = (event) => {
        let payload = event.target.textContent
        try {
            JSON.parse(payload);
            setJson(true)
        } catch (error) {
            setJson(false)
        }
        setPublish(payload)
    }
    const sendData = () => {
        // setPublishLoader(true)
        publishDeviceData(JSON.parse(publish))
        props.onClose()
    }
    return (
        <Mypopup title={"Device Payload and Topic"} onClose={() => { props.onClose() }} headerContent={
            <ButtonContainer>
                {editable ?
                    <Button buttonResult={() => setEditable(false)}>Cancel</Button>
                    :
                    <Button buttonResult={() => setEditable(true)}>Edit</Button>
                }
            </ButtonContainer>
        }>

            <div className='w-100' style={{ maxHeight: "300px", overflow: "auto" }}>
                {/* <small> */}
                <code contenteditable={editable ? "true" : "false"} onInput={(e) => { editPayload(e) }}>
                    <div className='m-2'>
                        {!editable ?
                            "Publish Topic: lt/evt/" + res1.hub[0].type + "Events/" + res1.hubId + '\n' +
                            "Payload:" : null}  {"{"}<br />
                        <div className='ml-3'>
                            "deviceId": "{res1._id}", <br />
                            {!editable ?
                                res1.parameter.map((param, key) =>
                                    <>
                                        {`"${param.value}": ${param.types} Value` + (key + 1 !== res1.parameter.length ? `,` : ``)} <br />
                                    </>
                                ) :
                                res1.parameter.map((param, key) =>
                                    props.events !== null ?
                                        Object.keys(props.events)[key] === param.value ?
                                            <>
                                                {`"${param.value}": ${typeof Object.values(props.events)[key] === "string" ? '"' + Object.values(props.events)[key] + '"' : Object.values(props.events)[key]}` + (key + 1 !== res1.parameter.length ? `,` : ``)}
                                            </>
                                            :
                                            <>
                                                {`"${param.value}": N/A ` + (key + 1 !== res1.parameter.length ? `,` : ``)}
                                            </>
                                        :
                                        <>
                                            {`"${param.value}": N/A` + (key + 1 !== res1.parameter.length ? `,` : ``)}
                                        </>
                                )
                            }

                        </div>
                        {"}"}


                    </div>
                </code>
                {editable ?
                    publishLoader ? <InlineLoader /> :
                        publish !== "" ?
                            json ?
                                <ButtonContainer>
                                    <Button buttonResult={() => { sendData() }}>Publish</Button>
                                </ButtonContainer> : null : null : null}
                {/* </small> */}
            </div>
        </Mypopup >
    )
}

export default DeviceInfoPopup