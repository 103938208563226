import React, { useEffect, useState } from 'react'
import Input from '../../input/input'
import { titleCase } from '../../../functions/functions'
const Label = (props) => {
    const [label, setLabel] = useState("")
    const [lebelType, setType] = useState("label")
    const onChangeHandler = (e, type) => {
        let data = e.target.value
        setLabel(data)
        if ("onChange" in props) {
            props.onChange({ name: lebelType, target: { value: data } })
        }
    }
    useEffect(() => {
        if (props?.defaultValue) {
            setLabel(props.defaultValue)
        } else {
            setLabel("")
        }
    }, [props.defaultValue])
    useEffect(() => {
        if (props?.type) {
            setType(props.type)
        }
    }, [props.type])
    return (
        <>
            {
                props.type ?
                    <Input
                        className="mb-3"
                        name={lebelType}
                        label={titleCase(lebelType)}
                        type={props.type === "min" || props.type === "max" || props.type === "lowest" || props.type === "highest"? "number":"text"}
                        defaultValue={(label ? label : "")}
                        onChange={(e) => onChangeHandler(e)}
                    />
                    : null
            }
        </>
    )
}

export default Label