import { set } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { CardHeader, Heading } from '../../card/card'
import GraphCard from '../../graphCard/graphCard'
import GraphIMG from '../../../assets/componentThumbnails/LineGraph.png'
import { idGenerator } from '../../../functions/functions'
import ChartConfig from '../../graph/chartConfig'
import { DeviceLastEvents } from '../../../services/utilities/events'
import InlineLoader from '../../loader/inlineLoader'

const MiniLineGraph = (props) => {
    const chartAddNode = useRef(0);
    const [events, setEvents] = useState({
        Status: null,
        Connection: null
    })

    const [device, setDevice] = useState(null)
    const [param, setParam] = useState("")
    const [parameter, setParameter] = useState(null)
    const [graphLoader, setGraphLoader] = useState(true)
    const [response, setResponse] = useState(null)
    const [lastUpdate, setLastUpdate] = useState('')
    const [chartConfig, setChartConfig] = useState(null)
    const [graphData, setGraphData] = useState([])

    useEffect(() => {
        let deviceId = props?.config?.deviceId
        let param1 = props?.config?.param
        let range1 = props?.config?.range || []
        if (deviceId) {
            props?.organization?.organization?.alldevices.map(device => {
                if (deviceId === device._id) {
                    device["events"] = events
                    device["parameter"][0]["alias"] = []
                    device.parameter.map(params => {
                        if (params.value === param1) {
                            setParameter(params)
                            let config = {
                                id: "miniGraph_" + idGenerator(),
                                axisX: "date",
                                axisY: "value",
                                scrollbar: false,
                                legend: false,
                                label_X: false,
                                label_Y: false,
                                tooltip: false,
                                zoom: false,
                                animation: true,
                                grid: null,
                                series:[
                                    {
                                        type: 'line',
                                        name: 'a',
                                        color: props?.config?.color || "#008df2",
                                        x: "Date",
                                        y: params.types,
                                    },
                                ]
                            }
                            if(range1.length){
                                config.series[0].seriesRange = range1
                            }
                            setChartConfig(config)
                        }
                    })
                    setDevice(device)
                }
            })
        }
        setParam(param1)
        Events(deviceId, 10)
    }, [props.config])

    useEffect(async () => {
        let events1 = { ...events }
        let hubId = props?.config?.deviceId?.split("_")
        if (hubId) {
            hubId = hubId[0] + "_" + hubId[1]
        }
        let Events = props?.eventReducer?.deviceEvents
        if (Events.deviceId === props?.config?.deviceId) {
            events1.Status = Events
            setEvents(events1)
        } else if (Events.deviceId === hubId) {
            events1.Connection = Events
            setEvents(events1)
        }
    }, [props.eventReducer.deviceEvents])

    
    useEffect(() => {
        if (events && chartAddNode.current) {
            if (events.Status) {
                let Status = events.Status
                if (lastUpdate !== Status.created) {
                    chartAddNode.current.check({ Date: new Date(Status.created), [parameter.types]: Status[parameter.value] })
                    setLastUpdate(Status.created)
                }
            }
        }
    }, [events])

    const Events = async (id, limit, startDate = null, endDate2 = null) => {
        let response = []
        setGraphLoader(true)
        response = await DeviceLastEvents(id, limit, null, null)
        setResponse(response)
        setGraphLoader(false)
    }
    useEffect(() => {
        setGraphLoader(true)
        if (response) {
            filterData(parameter)
        }
        setTimeout(() => {
            setGraphLoader(false)
        }, 1000)
    }, [response])

    const filterData = (parameters) => {
        let graphData1 = []
        if (response.length && parameters) {
            response.map(row => {
                if (parameters.value in row) {
                    graphData1.push({
                        [parameters.types]: typeof row[parameters.value] === "number" ? parseFloat(row[parameters.value].toFixed(2)) : row[parameters.value],
                        Date: new Date(row.created)
                    })
                }
            })
        }
        graphData1.reverse()
        setGraphData(graphData1)
    }

    return (
        device && param?
            !graphLoader?
                <>
                    {/* <GraphCard events={events} device={device} config={{ parameter, height: "100%" }} headerDisable={true} type={"line"} /> */}
                    <ChartConfig 
                    height="100%"
                    chart={chartConfig}
                    data={graphData}
                    ref={chartAddNode}
                    // graphcolor={props?.config?.color || "#008df2"}
                    />
                </>
            : <InlineLoader/>
        :
        <div className='position-relative h-100 w-100'>
            <img src={GraphIMG} style={{ width: "100%", height: "100%", filter: "brightness(0.3)" }} />
            <h4 className='text-center position-absolute text-light w-100' style={{ top: "50%", left: '50%', transform: "translate(-50%,-50%)" }}>Select your device first to Live Preview</h4>
        </div>

    )
}


const mapStateToProps = (state) => ({
    organization: state.organization,
    eventReducer: state.eventReducer
});
export default connect(mapStateToProps, null)(MiniLineGraph)
