import store from '../../services/utilities/store'
import { getSignedInUser } from '../../services/utilities/users';


export const userInfoAction = async () => {

    let response = await getSignedInUser()
    store.dispatch({
        type: "Verification",
        payload: response
    });
};
